import Vue from 'vue';

import './styles/quasar.sass';
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css';
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Loading from 'quasar/src/plugins/Loading.js';;

Vue.use(Quasar, {
  config: {
    brand: {
      //primary: '#5e64ff',
      primary: '#0b0d6f',
      secondary: '#98d85b',
      //secondary: '#7cd6fd',
      //accent: '#ff5858',
      accent: '#743ee2',
      test: '#303f9f',
      //accent: '#9c27b0',
      //accent: '#ff5858',

      dark: '#1d1d1d',

      positive: '#28a745',
      negative: '#C10015',
      info: '#31CCEC',
      warning: '#ffa00a'
    }
  },
  plugins: {
    Dialog,
    Loading,
    Notify
  }
});
