<template>
  <div>
    <q-dialog v-model="displayMapReport" persistent>
      <q-layout
        id="advanced-edit"
        view="Lhh lpr fff"
        container
        class="bg-white outer"
      >
        <q-header class="bg-grey-3">
          <div
            class="row items-center full-width bg-grey-3 q-pl-lg q-pr-sm q-py-md"
          >
            <div class="q-ml-sm">
              <q-btn
                color="primary"
                icon="image"
                padding="xs md"
                @click="displayAttachments"
              />
            </div>

            <q-space />
            <q-btn
              flat
              round
              icon="clear"
              color="grey-3"
              text-color="black"
              class="q-mr-sm"
              padding="sm"
              @click="cancel"
            />
          </div>
        </q-header>

        <q-scroll-area
          :thumb-style="thumbStyle"
          :bar-style="barStyle"
          :style="scrollHeight"
          style="margin-top: 72px"
        >
          <q-card
            class="q-mb-lg"
            :class="$q.screen.gt.xs ? 'q-ma-lg' : 'q-ma-md'"
          >
            <MapDestReport
              v-if="reportType === 'destination'"
              :destinationRaw="reportRaw"
            />

            <MapSourceReport
              v-if="reportType === 'source'"
              :sourceRaw="reportRaw"
            />
          </q-card>
        </q-scroll-area>
      </q-layout>
    </q-dialog>
  </div>
</template>

<script>
import MapDestReport from '@/components/destinations/DestinationReport.vue';
import MapSourceReport from '@/components/sources/SourceReport.vue';

export default {
  name: 'MapReport',
  components: {
    MapDestReport,
    MapSourceReport
  },
  props: {
    reportRaw: {
      type: Object,
      required: true
    },
    reportType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      barStyle: {
        right: '0px',
        borderRadius: '3px',
        backgroundColor: '#bdbdbd',
        width: '6px',
        opacity: 0.2
      },
      displayMapReport: true,
      scrollHeight: 'height: 600px',
      thumbStyle: {
        right: '0px',
        borderRadius: '3px',
        backgroundColor: '#9e9e9e',
        width: '6px',
        opacity: 0.75
      }
    };
  },
  mounted() {
    this.setScrollHeight();
  },
  methods: {
    cancel() {
      this.$emit('onCancel');
    },
    displayAttachments() {
      this.$emit('onDisplayAttachments');
    },
    setScrollHeight() {
      this.$nextTick(() => {
        let containerHeight =
          document.getElementById('advanced-edit').offsetHeight;
        this.scrollHeight = `height: ${containerHeight - 72}px`;
      });
    }
  },
  computed: {
    customerList() {
      return JSON.parse(JSON.stringify(this.$store.state.customers));
    },
    masterDestinations() {
      return JSON.parse(JSON.stringify(this.$store.state.masterDestinations));
    },
    masterSources() {
      return JSON.parse(JSON.stringify(this.$store.state.masterSources));
    },
    userList() {
      return JSON.parse(JSON.stringify(this.$store.state.userList));
    }
  }
};
</script>

<style scoped>
.outer {
  max-width: 480px;
}

@media all and (max-width: 440px) {
  .outer {
    min-width: 310px;
  }
}
</style>
