<template>
  <div>
    <ReportContainer
      v-if="detailedReport.display"
      :reportId="detailedReport.reportId"
      :reportType="detailedReport.reportType"
      @onCancel="detailedReport.display = false"
    />

    <AddSelect
      v-if="addSelect"
      :addDestOptions="addDestOptions"
      :addSourceOptions="addSourceOptions"
      :destAs="destAs"
      :type="type"
      @onCancel="addSelect = false"
      @onAddSelected="onAddSelected"
      @onRemoveSelected="onRemoveSelected"
    />

    <DateRange
      v-if="displayDateRange"
      :datesProvided="false"
      @onSelectDates="onSelectDates"
      @onCancel="displayDateRange = false"
    />

    <Print
      v-if="print"
      id="print"
      :selectedOptions="selectedOptions"
      :notes="notes"
      :address="address"
      :grandTotal="grandTotal"
      :grandTotalRaw="grandTotalRaw"
      :date="date"
      :sReportNumber="sReportNumber"
      :sReportType="sReportType"
    />

    <ListSReports
      v-if="recent.showListSReports"
      :recent="recentMod"
      :customer="customer"
      :edit="modes.allowEdit"
      :showAll="true"
      @onEditSReport="onEditSReport"
      @onDeleteSReport="onDeleteSReport"
      @onDeleteStart="onDeleteStart"
      @onCancel="recent.showListSReports = false"
    />

    <q-layout view="lhh LpR lff" container style="height: calc(100vh - 32px)">
      <q-header reveal class="bg-grey-3">
        <q-toolbar>
          <q-btn
            outline
            size="sm"
            label="cancel"
            color="primary"
            padding="xs md"
            @click="cancel"
          />
          <q-space />
          <div
            class="gt-sm text-primary text-bold text-no-wrap ellipsis q-mx-sm"
          >
            <div class="ellipsis">
              {{ customer }}
            </div>
          </div>

          <q-space />
          <div class="gt-xs text-black text-bold q-mx-sm text-no-wrap">
            <span
              v-if="grandTotalRaw >= 0"
              class="bg-grey-3 text-black q-px-sm rounded-borders"
            >
              Balance Due:
            </span>
            <span
              v-if="grandTotalRaw < 0"
              class="bg-grey-3 text-custom-red q-px-sm rounded-borders"
            >
              Credit:
            </span>
            {{ grandTotal }}
          </div>

          <q-space />
          <q-btn
            outline
            size="sm"
            color="primary"
            label="List / Preview SR"
            class="text-no-wrap"
            padding="xs md"
            @click="showListSReports(false, true)"
          />
        </q-toolbar>

        <q-bar class="bg-grey-3 lt-sm">
          <q-space />
          <div class="text-black text-bold">
            <span
              v-if="grandTotalRaw >= 0"
              class="bg-grey-3 q-px-sm rounded-borders"
            >
              Balance Due:
            </span>
            <span
              v-if="grandTotalRaw < 0"
              class="bg-grey-3 text-custom-red q-px-sm rounded-borders"
            >
              Credit:
            </span>
            {{ grandTotal }}
          </div>
          <q-space />
        </q-bar>

        <q-bar class="bg-grey-3 lt-md">
          <q-space />
          <div class="text-primary text-bold no-wrap ellipsis">
            {{ customer }}
          </div>
          <q-space />
        </q-bar>
      </q-header>

      <q-page-container>
        <q-page>
          <div>
            <q-stepper
              flat
              v-model="step"
              vertical
              done-color="primary"
              active-color="custom-orange"
              inactive-color="grey-6"
              animated
            >
              <div v-if="modes.loading" class="q-px-lg">
                <q-linear-progress indeterminate color="primary" size="sm" />
              </div>
              <q-step :name="1" title="Add/Edit" icon="add" :done="step > 1">
                <q-stepper-navigation>
                  <div class="full-width q-mb-md">
                    <q-btn
                      color="custom-artichoke"
                      label="Create New"
                      padding="xs md"
                      style="width: 200px"
                      @click="createNew"
                    />
                  </div>
                  <div class="full-width">
                    <q-btn
                      v-if="hasSReport"
                      color="custom-orange"
                      label="Edit Existing"
                      padding="xs md"
                      style="width: 200px"
                      @click="showListSReports(true, true)"
                    />
                  </div>
                </q-stepper-navigation>
              </q-step>

              <q-step
                :name="2"
                title="Add Pricing Info"
                icon="attach_money"
                :done="step > 2"
              >
                <q-stepper-navigation>
                  <div v-if="!modes.edit" class="full-width q-mb-md">
                    <q-btn
                      color="custom-dark"
                      icon="add"
                      label="Fields / Sources"
                      padding="xs md"
                      style="width: 200px"
                      @click="addFieldSource"
                    />
                  </div>
                  <div
                    v-if="!filterByDate && !modes.edit"
                    class="full-width q-mb-md"
                  >
                    <q-btn
                      color="custom-medium"
                      icon="event_available"
                      label="Filter By Date"
                      padding="xs md"
                      style="width: 200px"
                      @click="setDateFilter"
                    />
                  </div>
                  <div
                    v-if="filterByDate && !modes.edit"
                    class="full-width q-mb-md"
                  >
                    <q-btn
                      color="custom-orange"
                      icon="event_available"
                      label="Remove Filter"
                      padding="xs md"
                      style="width: 200px"
                      @click="removeDateFilter"
                    />
                  </div>
                  <div class="full-width q-mb-md">
                    <q-btn
                      color="custom-artichoke"
                      icon="arrow_forward"
                      label="Continue"
                      padding="xs md"
                      style="width: 200px"
                      @click="(step = 3), setReportType()"
                    />
                  </div>
                  <div class="full-width">
                    <q-btn
                      outline
                      color="custom-artichoke"
                      label="Back"
                      icon="arrow_back"
                      padding="xs md"
                      style="width: 200px"
                      @click="(step = 1), (sReportTS = null)"
                    />
                  </div>
                </q-stepper-navigation>

                <q-card
                  flat
                  :bordered="$q.screen.gt.xs"
                  class="row justify-center q-mt-md"
                >
                  <q-card
                    flat
                    v-if="filterByDate"
                    class="bg-custom-light full-width q-py-md"
                  >
                    <div
                      class="row justify-center text-body1 text-bold text-primary"
                    >
                      Settlement For Haul Dates
                    </div>
                    <div class="row justify-center">
                      {{ this.haulDates.from }} -
                      {{ this.haulDates.to }}
                    </div>
                  </q-card>

                  <div
                    v-for="(option, optionIndex) in selectedOptions"
                    :key="option.locationId"
                    :name="option.locationId"
                    class="option"
                  >
                    <div>
                      <q-card flat bordered class="q-mt-sm">
                        <div class="row justify-center items-center q-py-sm">
                          <q-icon
                            v-if="option.type === 'source'"
                            name="outbound"
                            color="custom-green"
                            size="sm"
                            class="q-ma-xs"
                          />
                          <q-icon
                            v-if="option.type === 'destination'"
                            name="outbound"
                            color="custom-red"
                            style="transform: rotate(180deg)"
                            size="sm"
                            class="q-ma-xs"
                          />
                          <span class="text-subtitle1 text-primary q-ml-sm">
                            {{ locationName(option) }}
                          </span>
                        </div>

                        <div v-if="modes.edit">
                          <div
                            class="row justify-center items-center bg-custom-orange"
                          >
                            <q-icon
                              name="edit"
                              color="black"
                              size="sm"
                              class="q-ma-xs"
                            />
                            <span
                              class="text-body1 text-bold text-black q-ml-sm"
                            >
                              Editing
                            </span>
                          </div>
                        </div>

                        <q-separator />

                        <div v-if="!modes.edit">
                          <div
                            v-if="
                              option.allReportsTotal === option.genericTotal
                            "
                            class="row justify-center q-py-sm q-px-md text-center"
                          >
                            <q-icon name="task_alt" color="primary" size="sm" />
                            <q-icon name="task_alt" color="primary" size="sm" />
                            <q-icon name="task_alt" color="primary" size="sm" />
                            <div class="q-py-xs">
                              All Commodities and/or Services are all included
                              in a Settlement Report
                            </div>
                          </div>

                          <div
                            v-if="
                              option.type === 'source' &&
                              option.allReportsTotal < option.genericTotal
                            "
                            class="row justify-center q-py-sm q-px-md text-center"
                          >
                            <div class="row justify-center full-width">
                              <q-icon name="add" color="custom-red" size="sm" />
                              <q-icon
                                name="paid"
                                color="custom-red"
                                size="sm"
                              />
                              <q-icon name="add" color="custom-red" size="sm" />
                            </div>
                            <div class="row q-py-xs">
                              Some Commodity credits may be duplicated or
                              <br />
                              Some Service charges may not be included
                            </div>
                          </div>

                          <div
                            v-if="
                              option.type === 'source' &&
                              option.allReportsTotal > option.genericTotal
                            "
                            class="row justify-center q-py-sm q-px-md text-center"
                          >
                            <div class="row justify-center full-width">
                              <q-icon
                                name="remove"
                                color="custom-red"
                                size="sm"
                              />
                              <q-icon
                                name="paid"
                                color="custom-red"
                                size="sm"
                              />
                              <q-icon
                                name="remove"
                                color="custom-red"
                                size="sm"
                              />
                            </div>
                            <div class="row q-py-xs">
                              Some Commodity credits may not be included
                              <br />
                              Some Service charges may be duplicated
                            </div>
                          </div>

                          <div
                            v-if="
                              option.type === 'destination' &&
                              option.allReportsTotal < option.genericTotal
                            "
                            class="row justify-center q-py-sm q-px-md text-center"
                          >
                            <q-icon
                              name="remove"
                              color="custom-red"
                              size="sm"
                            />
                            <q-icon name="paid" color="custom-red" size="sm" />
                            <q-icon
                              name="remove"
                              color="custom-red"
                              size="sm"
                            />
                            <div class="q-py-xs">
                              Some Commodities and/or Services may not be
                              included in a Settlement Report
                            </div>
                          </div>

                          <div
                            v-if="
                              option.type === 'destination' &&
                              option.allReportsTotal > option.genericTotal
                            "
                            class="row justify-center q-py-sm q-px-md text-center"
                          >
                            <q-icon name="add" color="custom-red" size="sm" />
                            <q-icon name="paid" color="custom-red" size="sm" />
                            <q-icon name="add" color="custom-red" size="sm" />
                            <div class="q-py-xs">
                              Some Commodities and/or Services may be duplicated
                              in a Settlement Report
                            </div>
                          </div>

                          <q-separator />
                        </div>

                        <div class="q-pa-md">
                          <div class="row items-center text-bold q-mb-xs">
                            <div class="text-bold" style="width: 65%">
                              {{ option.commodity }}
                            </div>
                            <div style="text-align: end; width: 35%">
                              <span v-if="!filterByDate">
                                {{ option.hauled }}
                              </span>
                              <span v-if="filterByDate">
                                {{ option.hauledFiltered }}
                              </span>
                              {{ option.MU }}
                            </div>
                          </div>
                          <q-separator />

                          <div>
                            <div v-show="chargeOrPurchaseCommodity(option)">
                              <div class="row items-center q-pt-sm">
                                <div style="width: 19%">
                                  <q-btn
                                    icon="clear"
                                    size="xs"
                                    padding="xs sm"
                                    color="custom-red"
                                    @click="
                                      removeItem('pricePerUnit', optionIndex)
                                    "
                                  />
                                </div>
                                <div style="width: 54%">
                                  Price Per {{ option.MU.slice(0, -1) }}
                                </div>
                                <div
                                  style="text-align: end; width: 27%"
                                  class="text-custom-medium"
                                >
                                  {{ formatPrice(option.pricePerUnit) }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <q-separator />

                        <div class="q-pa-md">
                          <div class="row items-center text-bold q-mb-sm">
                            <span> Services </span>
                            <span class="text-caption q-ml-sm">
                              ( Per {{ option.MU.slice(0, -1) }} )
                            </span>
                            <q-space />
                            <q-btn
                              icon="add"
                              color="primary"
                              size="xs"
                              padding="xs sm"
                              @click="
                                option.customServices.push({
                                  name: '',
                                  price: 0
                                })
                              "
                            />
                          </div>
                          <q-separator class="q-mb-xs" />

                          <div v-if="filterByDate" class="row justify-center">
                            <q-checkbox
                              v-model="option.filterServicesByDate"
                              label="Filter Services By Date"
                              color="primary"
                              @input="calcSelectedTotal(), setReportType()"
                            />
                          </div>
                          <q-separator v-if="filterByDate" class="q-mb-xs" />

                          <div
                            v-if="filterByDate"
                            class="row items-center text-bold q-mb-xs"
                          >
                            <div style="text-align: end; width: 100%">
                              <span v-if="!option.filterServicesByDate">
                                {{ option.hauled }}
                              </span>
                              <span v-if="option.filterServicesByDate">
                                {{ option.hauledFiltered }}
                              </span>
                              {{ option.MU }}
                            </div>
                          </div>
                          <q-separator v-if="filterByDate" />

                          <div
                            v-for="(service, index) in option.services"
                            :key="service.name + 'infoB' + index"
                          >
                            <div
                              v-if="!service.useInfoBox"
                              class="row items-center q-py-xs"
                            >
                              <div style="width: 19%">
                                <q-btn
                                  icon="clear"
                                  size="xs"
                                  padding="xs sm"
                                  color="custom-red"
                                  @click="
                                    removeItem('service', optionIndex, index)
                                  "
                                />
                              </div>
                              <div style="width: 54%">
                                {{ service.name }}
                                <div
                                  v-if="!service.complete"
                                  class="bg-custom-orange rounded-borders q-pa-xs q-pl-sm"
                                >
                                  SERVICE NOT COMPLETE!
                                </div>
                              </div>
                              <div
                                style="text-align: end; width: 27%"
                                class="text-custom-medium"
                              >
                                {{ formatPrice(service.price) }}
                              </div>
                            </div>
                          </div>

                          <div
                            v-for="(service, index) in option.customServices"
                            :key="index + 50"
                          >
                            <div class="row items-center q-py-xs">
                              <div style="width: 19%">
                                <q-btn
                                  icon="clear"
                                  size="xs"
                                  padding="xs sm"
                                  color="custom-red"
                                  @click="
                                    removeItem(
                                      'customService',
                                      optionIndex,
                                      index
                                    )
                                  "
                                />
                              </div>
                              <div style="width: 49%">
                                <q-input
                                  outlined
                                  v-model="service.name"
                                  label="Service Name"
                                  label-color="grey-7"
                                  color="grey-6"
                                  maxlength="80"
                                  class="q-mr-xs"
                                  dense
                                />
                              </div>
                              <div
                                style="text-align: end; width: 32%"
                                class="text-grey-7"
                              >
                                <q-input
                                  outlined
                                  v-model.number="service.price"
                                  label="Price"
                                  mask="#"
                                  prefix="$"
                                  type="number"
                                  maxlength="20"
                                  dense
                                  color="grey-6"
                                  label-color="grey-7"
                                  @input="calcSelectedTotal"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <q-separator />

                        <div
                          v-if="hasPairedWithInfoBox(option.services)"
                          class="q-pa-md"
                        >
                          <div class="row items-center text-bold q-mb-sm">
                            <span> Services </span>
                            <span class="text-caption q-ml-sm">
                              ( Paired With Info Box )
                            </span>
                            <q-space />
                          </div>
                          <q-separator class="q-mb-xs" />

                          <div
                            v-for="(service, index) in option.services"
                            :key="index"
                          >
                            <div
                              v-if="service.useInfoBox"
                              class="row items-center q-py-xs"
                            >
                              <div style="width: 19%">
                                <q-btn
                                  icon="clear"
                                  size="xs"
                                  padding="xs sm"
                                  color="custom-red"
                                  @click="
                                    removeItem('service', optionIndex, index)
                                  "
                                />
                              </div>
                              <div style="width: 54%">
                                {{ service.name }}
                                <div
                                  v-if="!service.complete"
                                  class="bg-custom-orange rounded-borders q-pa-xs q-pl-sm"
                                >
                                  SERVICE NOT COMPLETE!
                                </div>
                              </div>
                              <div
                                style="text-align: end; width: 27%"
                                class="text-custom-medium"
                              >
                                {{ formatPrice(service.price) }}
                              </div>

                              <div class="row full-width q-pt-xs">
                                <div style="width: 75%">
                                  {{ service.infoBoxPairedWith }}
                                </div>
                                <div style="text-align: end; width: 25%">
                                  {{ service.infoBoxVal }}
                                  <div
                                    v-if="
                                      service.infoBoxVal === null ||
                                      service.infoBoxVal === ''
                                    "
                                    class="bg-custom-orange rounded-borders q-pa-xs"
                                  >
                                    Not Set!
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <q-separator
                          v-if="hasPairedWithInfoBox(option.services)"
                        />

                        <div v-if="option.showMileage" class="q-pa-md">
                          <div class="row items-center text-bold q-mb-sm">
                            <span> Mileage </span>
                            <q-space />

                            {{ option.totalMileage }} Miles
                          </div>
                          <q-separator class="q-mb-xs" />

                          <div v-show="option.showMiles">
                            <div class="row items-center q-py-xs">
                              <div style="width: 19%">
                                <q-btn
                                  icon="clear"
                                  size="xs"
                                  padding="xs sm"
                                  color="custom-red"
                                  @click="
                                    removeItem('pricePerMile', optionIndex)
                                  "
                                />
                              </div>
                              <div style="width: 54%">Price Per Mile</div>
                              <div
                                style="text-align: end; width: 27%"
                                class="text-custom-medium"
                              >
                                {{ formatPrice(option.mileageRate) }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <q-separator v-if="option.showMileage" />

                        <div class="q-pa-md">
                          <div class="row items-center text-bold q-mb-sm">
                            <span> Custom Charges </span>
                            <q-space />
                            <q-btn
                              icon="add"
                              color="primary"
                              size="xs"
                              padding="xs sm"
                              @click="
                                option.customCharges.push({
                                  name: '',
                                  price: 0
                                })
                              "
                            />
                          </div>
                          <q-separator class="q-mb-xs" />

                          <div
                            v-for="(charge, index) in option.customCharges"
                            :key="index + 100"
                          >
                            <div class="row items-center q-py-xs">
                              <div style="width: 19%">
                                <q-btn
                                  icon="clear"
                                  size="xs"
                                  padding="xs sm"
                                  color="custom-red"
                                  @click="
                                    removeItem(
                                      'customCharge',
                                      optionIndex,
                                      index
                                    )
                                  "
                                />
                              </div>
                              <div style="width: 49%">
                                <q-input
                                  outlined
                                  v-model="charge.name"
                                  label="Charge Name"
                                  label-color="grey-7"
                                  color="grey-6"
                                  maxlength="80"
                                  class="q-mr-xs"
                                  dense
                                />
                              </div>
                              <div
                                style="text-align: end; width: 32%"
                                class="text-custom-medium"
                              >
                                <q-input
                                  outlined
                                  v-model.number="charge.price"
                                  mask="#"
                                  prefix="$"
                                  type="number"
                                  maxlength="20"
                                  dense
                                  label="Price"
                                  color="grey-6"
                                  label-color="grey-7"
                                  @input="calcSelectedTotal"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <q-separator />

                        <div class="q-pa-md">
                          <div class="row items-center text-bold q-mb-sm">
                            <span> Custom Credits </span>
                            <q-space />
                            <q-btn
                              icon="add"
                              color="primary"
                              size="xs"
                              padding="xs sm"
                              @click="
                                option.customCredits.push({
                                  name: '',
                                  price: 0
                                })
                              "
                            />
                          </div>
                          <q-separator class="q-mb-xs" />

                          <div
                            v-for="(credit, index) in option.customCredits"
                            :key="index + 150"
                          >
                            <div class="row items-center q-py-xs">
                              <div style="width: 19%">
                                <q-btn
                                  icon="clear"
                                  size="xs"
                                  padding="xs sm"
                                  color="custom-red"
                                  @click="
                                    removeItem(
                                      'customCredit',
                                      optionIndex,
                                      index
                                    )
                                  "
                                />
                              </div>
                              <div style="width: 49%">
                                <q-input
                                  outlined
                                  v-model="credit.name"
                                  label="Credit Name"
                                  label-color="grey-7"
                                  color="grey-6"
                                  class="q-mr-xs"
                                  maxlength="80"
                                  dense
                                />
                              </div>
                              <div
                                style="text-align: end; width: 32%"
                                class="text-custom-medium"
                              >
                                <q-input
                                  outlined
                                  v-model.number="credit.price"
                                  mask="#"
                                  prefix="$"
                                  type="number"
                                  maxlength="20"
                                  dense
                                  label="Price"
                                  color="grey-6"
                                  label-color="grey-7"
                                  @input="calcSelectedTotal"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <q-separator />

                        <div
                          class="row items-center text-body1 text-bold q-pa-md"
                        >
                          <span
                            v-if="option.totalRaw >= 0"
                            class="bg-grey-3 text-primary q-px-sm rounded-borders"
                          >
                            Total Charge
                          </span>
                          <span
                            v-if="option.totalRaw < 0"
                            class="bg-grey-3 text-custom-red q-px-sm rounded-borders"
                          >
                            Total Credit
                          </span>
                          <q-space />
                          <span class="q-text-bold">
                            {{ option.totalPrice }}
                          </span>
                        </div>
                        <div class="row q-px-md q-mb-md">
                          <q-space />
                          <q-btn
                            outline
                            color="primary"
                            label="info"
                            icon="info"
                            padding="xs sm"
                            size="sm"
                            class="full-width q-mb-md"
                            @click="showReport(option)"
                          />
                          <q-btn
                            outline
                            color="primary"
                            label="Reset"
                            icon="restart_alt"
                            padding="xs sm"
                            size="sm"
                            class="full-width"
                            @click="
                              init(
                                option,
                                option.type,
                                false,
                                true,
                                false,
                                false
                              )
                            "
                          />
                        </div>
                      </q-card>
                    </div>
                  </div>
                </q-card>
              </q-step>

              <q-step
                :name="3"
                title="Additional Info"
                icon="note_add"
                :done="step > 2"
              >
                <q-stepper-navigation>
                  <div v-if="!modes.edit" class="full-width q-mb-md">
                    <q-btn
                      color="custom-artichoke"
                      label="Save & Continue"
                      icon="arrow_forward"
                      padding="xs md"
                      style="width: 200px"
                      @click="saveSReport"
                    />
                  </div>
                  <div v-if="modes.edit" class="full-width q-mb-md">
                    <q-btn
                      color="custom-artichoke"
                      label="Continue Without Saving"
                      padding="xs md"
                      style="width: 200px"
                      @click="step = 4"
                    />
                  </div>
                  <div v-if="modes.edit" class="full-width q-mb-md">
                    <q-btn
                      color="custom-orange"
                      label="Save Changes & Continue"
                      padding="xs md"
                      style="width: 200px"
                      @click="saveSReport"
                    />
                  </div>
                  <div class="full-width">
                    <q-btn
                      outline
                      color="custom-artichoke"
                      icon="arrow_back"
                      label="Back"
                      padding="xs md"
                      style="width: 200px"
                      @click="step = 2"
                    />
                  </div>
                </q-stepper-navigation>

                <q-card flat bordered class="q-py-lg q-mt-md">
                  <div class="row justify-center full-width">
                    <div class="q-mx-md q-mb-md" style="width: 270px">
                      <div class="text-bold q-ml-sm q-pl-xs">Customer</div>
                      <q-input
                        outlined
                        v-model="address.name"
                        label-color="grey-7"
                        color="grey-6"
                        maxlength="80"
                        dense
                        hide-bottom-space
                        lazy-rules
                        :rules="[
                          val =>
                            (val && val.length > 0) || 'Please enter a name'
                        ]"
                      />
                      <div class="text-bold q-ml-sm q-mt-md q-pl-xs">
                        Address
                      </div>
                      <q-input
                        outlined
                        v-model="address.streetAddress"
                        label-color="grey-7"
                        color="grey-6"
                        maxlength="80"
                        dense
                        hide-bottom-space
                        lazy-rules
                        :rules="[
                          val =>
                            (val && val.length > 0) || 'Please enter an address'
                        ]"
                      />
                      <div class="text-bold q-ml-sm q-mt-md q-pl-xs">
                        City, St Zip
                      </div>
                      <q-input
                        outlined
                        v-model="address.cityStZip"
                        label-color="grey-7"
                        color="grey-6"
                        maxlength="80"
                        dense
                        hide-bottom-space
                        lazy-rules
                        :rules="[
                          val =>
                            (val && val.length > 0) ||
                            'Please enter City, St Zip'
                        ]"
                      />
                      <div class="text-bold q-ml-sm q-mt-md q-pl-xs">Date</div>
                      <q-input
                        outlined
                        dense
                        color="primary"
                        v-model="date"
                        mask="date"
                        :rules="['date']"
                        hide-bottom-space
                      >
                        <template v-slot:append>
                          <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy
                              ref="qDateProxy"
                              transition-show="scale"
                              transition-hide="scale"
                            >
                              <q-date v-model="date" color="primary">
                                <div class="row items-center justify-end">
                                  <q-btn
                                    v-close-popup
                                    label="Close"
                                    color="primary"
                                    flat
                                  />
                                </div>
                              </q-date>
                            </q-popup-proxy>
                          </q-icon>
                        </template>
                      </q-input>

                      <div class="text-bold q-ml-sm q-mt-md q-pl-xs">
                        Settlement Report Number
                      </div>
                      <q-input
                        outlined
                        v-model="sReportNumber"
                        mask="#######"
                        dense
                      />
                    </div>

                    <div class="q-mx-md" style="width: 270px">
                      <div class="text-bold q-ml-sm q-pl-xs">
                        Settlement Report Note
                      </div>
                      <q-input
                        outlined
                        dense
                        type="textarea"
                        v-model="notes.sReportNote"
                        label-color="grey-7"
                        color="grey-6"
                      />
                      <div class="q-ml-sm q-mt-md q-pl-xs">
                        <span class="text-bold"> Admin Settlement Note </span>
                        (private)
                      </div>
                      <q-input
                        outlined
                        dense
                        autogrow
                        v-model="notes.adminNote"
                        label-color="grey-7"
                        color="grey-6"
                      />
                      <div class="bg-grey-2 q-mt-lg rounded-borders">
                        <q-radio
                          v-model="sReportType"
                          color="primary"
                          val="itemized"
                          label="Itemized SReport"
                        />
                        <q-radio
                          v-model="sReportType"
                          :disable="!condensedAvailable"
                          color="primary"
                          val="condensed"
                          label="Condensed SReport"
                        />
                        <div
                          v-if="!condensedAvailable"
                          class="text-custom-medium"
                          style="margin-left: 10px"
                        >
                          Condensed Not Available
                        </div>
                      </div>
                    </div>
                  </div>
                </q-card>
              </q-step>

              <q-step :name="4" title="Print & Finish" icon="print">
                <q-stepper-navigation>
                  <div class="full-width q-mb-md">
                    <q-btn
                      color="custom-medium"
                      icon="print"
                      label="Print"
                      padding="xs md"
                      style="width: 200px"
                      @click="printSReport"
                    />
                  </div>
                  <div v-if="modes.edit" class="full-width q-mb-md">
                    <q-btn
                      color="custom-artichoke"
                      label="Finish"
                      icon="done"
                      padding="xs md"
                      style="width: 200px"
                      @click="cancel"
                    />
                  </div>
                  <div v-if="modes.edit" class="full-width q-mb-md">
                    <q-btn
                      outline
                      color="custom-artichoke"
                      label="Back To Start"
                      icon="fast_rewind"
                      padding="xs md"
                      style="width: 200px"
                      @click="(step = 1), (sReportTS = null)"
                    />
                  </div>
                  <div class="full-width">
                    <q-btn
                      outline
                      color="custom-artichoke"
                      label="Back"
                      icon="arrow_back"
                      padding="xs md"
                      style="width: 200px"
                      @click="step = 3"
                    />
                  </div>
                </q-stepper-navigation>
              </q-step>
            </q-stepper>
          </div>
        </q-page>
      </q-page-container>
    </q-layout>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import ReportContainer from '@/components/ReportContainer.vue';
import AddSelect from '@/components/settlement/AddSelect.vue';
import DateRange from '@/components/DateRange.vue';
import Print from '@/components/settlement/Print.vue';
import ListSReports from '@/components/settlement/ListSReports.vue';
import { sourceReport, destinationReport } from '@/utils/ReportHelpers';
import { formatPrice } from '@/utils/UtilHelpers';
import { date } from 'quasar';
import { Printd } from 'printd';

const db = firebase.firestore();

export default {
  name: 'WorkTypeSettings',
  components: {
    AddSelect,
    DateRange,
    ListSReports,
    Print,
    ReportContainer
  },
  props: {
    selected: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      addDestOptions: [],
      addSourceOptions: [],
      address: {
        name: '',
        streetAddress: '',
        cityStZip: ''
      },
      addSelect: false,
      allSelected: [],
      customer: '',
      customerId: '',
      currIndex: 0,
      date: null,
      displayDateRange: false,
      displayReport: false,
      detailedReport: {
        display: false,
        reportId: '',
        reportType: ''
      },
      filterByDate: false,
      filterServicesByDate: false,
      formatPrice,
      haulDates: {
        from: '',
        to: '',
        fromTS: '',
        toTS: ''
      },
      grandTotal: '',
      grandTotalRaw: 0,
      hasSReport: false,
      modes: {
        loading: false,
        allowEdit: false,
        edit: false
      },
      notes: {
        sReportNote: '',
        adminNote: ''
      },
      print: false,
      report: {
        show: false,
        destinationInfo: [],
        sourceInfo: []
      },
      recent: {
        showListSReports: false,
        customerSReports: [],
        allSReports: []
      },
      recentMod: {
        showListSReports: false,
        customerSReports: [],
        allSReports: []
      },
      selectedOptions: [],
      sReportDate: null,
      sReportForEdit: null,
      sReportId: '',
      sReportNumber: null,
      sReportNumberOld: null,
      sReportTS: null,
      sReportType: 'itemized',
      step: 0
    };
  },
  created() {
    this.$store.dispatch('setHeaderBtnsDisplay', false);
    this.setGeneralInfo();
    this.init(this.selected, this.type, true, false, false, false);
  },
  methods: {
    addFieldSource() {
      const customerId = this.selected.customerId;

      const addSourceOptions = [];
      const activeSources = JSON.parse(
        JSON.stringify(this.$store.state.activeSources)
      );

      for (let i = 0; i < activeSources.length; i++) {
        let sourceRaw = activeSources[i];
        let isSelected = false;

        if (sourceRaw.customerId === customerId) {
          for (let j = 0; j < this.selectedOptions.length; j++) {
            const option = this.selectedOptions[j];
            if (sourceRaw.sourceLocationId === option.locationId) {
              isSelected = true;
            }
          }

          const source = sourceReport(sourceRaw.id);

          this.allSelected.push(source);

          addSourceOptions.push({
            ...source,
            ...{ locationId: source.locationId },
            ...{ isSelected: isSelected }
          });
        }
      }
      const sortByAlpha = (a, b) => {
        if (
          this.masterSources[a.locationId].sourceName <
          this.masterSources[b.locationId].sourceName
        ) {
          return -1;
        }
        if (
          this.masterSources[a.locationId].sourceName >
          this.masterSources[b.locationId].sourceName
        ) {
          return 1;
        }
        return 0;
      };

      addSourceOptions.sort(sortByAlpha);
      this.addSourceOptions = addSourceOptions;

      const addDestOptions = [];
      const activeDestinations = JSON.parse(
        JSON.stringify(this.$store.state.activeDestinations)
      );

      for (let i = 0; i < activeDestinations.length; i++) {
        let destinationRaw = activeDestinations[i];
        let isSelected = false;

        if (destinationRaw.customerId === customerId) {
          for (let j = 0; j < this.selectedOptions.length; j++) {
            const option = this.selectedOptions[j];
            if (destinationRaw.destinationLocationId === option.locationId) {
              isSelected = true;
            }
          }

          const destination = destinationReport(destinationRaw.id);

          this.allSelected.push(destination);

          addDestOptions.push({
            ...destination,
            ...{ locationId: destination.locationId },
            ...{ isSelected: isSelected }
          });
        }
      }

      const sortByDest = (a, b) => {
        if (
          this.masterDestinations[a.locationId].destinationName <
          this.masterDestinations[b.locationId].destinationName
        ) {
          return -1;
        }
        if (
          this.masterDestinations[a.locationId].destinationName >
          this.masterDestinations[b.locationId].destinationName
        ) {
          return 1;
        }
        return 0;
      };

      addDestOptions.sort(sortByDest);
      this.addDestOptions = addDestOptions;
      this.addSelect = true;
    },
    calcSelectedTotal() {
      let grandTotal = 0;

      const hauledKey = this.filterByDate ? 'hauledFiltered' : 'hauled';

      for (let i = 0; i < this.selectedOptions.length; i++) {
        let total = 0;

        const option = this.selectedOptions[i];

        if (option.showPricePerUnit) {
          if (option.type === 'destination' && option.chargeCommodity) {
            total += option.pricePerUnit * option[hauledKey];
          } else if (option.type === 'source' && option.purchaseCommodity) {
            total -= option.pricePerUnit * option[hauledKey];
          }
        }

        const servicesHauledKey = option.filterServicesByDate
          ? 'hauledFiltered'
          : 'hauled';

        for (let j = 0; j < option.services.length; j++) {
          const service = option.services[j];

          if (Object.hasOwnProperty.call(service, 'useInfoBox')) {
            if (service.useInfoBox) {
              const infoBoxPairedWith = service.infoBoxPairedWith;
              const infoBoxVal = option.infoBoxes.find(infoBox => {
                if (infoBox.name === infoBoxPairedWith) {
                  return true;
                }
              });

              service.infoBoxVal = infoBoxVal ? infoBoxVal.model : 0;
              total += service.price * service.infoBoxVal;
            } else {
              total += service.price * option[servicesHauledKey];
            }
          } else {
            total += service.price * option[servicesHauledKey];
          }
        }

        if (option.showMiles) {
          total += option.mileageRate * option.totalMileage;
        }

        // Here we check to see if anything has been duplicated
        // or left out of all settlement reports for this
        // customer/location

        if (!option.totalsReviewed) {
          option.genericTotal = total;
          option.totalsReviewed = true;
        }

        const sReports = this.recent.customerSReports;
        let allReportsTotal = total;

        // Loop thru recent customer sReports
        for (let j = 0; j < sReports.length; j++) {
          const selectedOptions = sReports[j].selectedOptions;
          for (let k = 0; k < selectedOptions.length; k++) {
            const recentOption = selectedOptions[k];
            if (option.destOrSourceId === recentOption.destOrSourceId) {
              allReportsTotal += recentOption.totalRaw;

              recentOption.customCharges.forEach(charge => {
                allReportsTotal -= charge.price;
              });

              recentOption.customCredits.forEach(credit => {
                allReportsTotal += credit.price;
              });

              const key = recentOption.filterServicesByDate
                ? 'hauledFiltered'
                : 'hauled';

              recentOption.customServices.forEach(service => {
                allReportsTotal -= service.price * recentOption[key];
              });
            }
          }
        }

        option.allReportsTotal = allReportsTotal;

        for (let j = 0; j < option.customServices.length; j++) {
          const service = option.customServices[j];

          total += service.price * option[servicesHauledKey];
        }

        for (let j = 0; j < option.customCharges.length; j++) {
          const charge = option.customCharges[j];

          total += charge.price;
        }

        for (let j = 0; j < option.customCredits.length; j++) {
          const credit = option.customCredits[j];

          total -= credit.price;
        }

        this.selectedOptions[i].totalRaw = total;
        this.selectedOptions[i].totalPrice = formatPrice(Math.abs(total));

        grandTotal += total;
      }

      this.grandTotalRaw = grandTotal;
      this.grandTotal = formatPrice(Math.abs(grandTotal));
    },
    cancel() {
      this.$emit('onCancel');
    },
    chargeOrPurchaseCommodity(option) {
      if (option.showPricePerUnit) {
        if (option.type === 'source') {
          return option.purchaseCommodity;
        } else {
          return option.chargeCommodity;
        }
      } else {
        return false;
      }
    },
    createNew() {
      this.modes.edit = false;
      this.selectedOptions = [];
      this.init(this.selected, this.type, true, false, false, true);
      this.setGeneralInfo();
      this.removeDateFilter();
      this.step = 2;
    },
    hasPairedWithInfoBox(services) {
      let hasPair = false;

      for (let i = 0; i < services.length; i++) {
        const service = services[i];
        if (Object.hasOwnProperty.call(service, 'useInfoBox')) {
          if (service.useInfoBox) {
            hasPair = true;
            break;
          }
        }
      }

      return hasPair;
    },
    async init(selected, type, stepStart, reset, onSave, onCreate) {
      selected = JSON.parse(JSON.stringify(selected));

      if (!onSave) {
        let currIndex;
        if (reset) {
          currIndex = this.selectedOptions.findIndex(
            x => x.locationId === selected.locationId
          );
        }

        if (!this.modes.edit) {
          if (!stepStart) {
            const allIndex = this.allSelected.findIndex(
              x => x.locationId === selected.locationId
            );

            selected = this.allSelected[allIndex];
          }

          this.customerId = selected.customerId;

          const purchaseCommodity =
            type === 'source' ? selected.purchaseCommodity : false;
          const chargeCommodity =
            type === 'destination' ? selected.chargeCommodity : false;

          let measurementUnit = '';
          for (const key in this.commodities) {
            if (selected.commodity === key) {
              measurementUnit = this.commodities[key].measurementUnit;
            }
          }

          const selectedObj = {
            type: type,
            avatarColor: this.avatarColor,
            destOrSourceId: selected.id,
            totalPrice: 0,
            hauled: selected.hauled,
            hauledFiltered: 0,
            hauls: selected.hauls,
            projected: selected.projected || null,
            pricePerUnit: selected.pricePerUnit,
            showPricePerUnit: true,
            services: selected.services.slice(),
            customServices: [],
            showMileage: selected.trackMileage,
            showMiles: true,
            totalMileage: selected.totalMileage,
            mileageRate: selected.mileageRate,
            customCharges: [],
            customCredits: [],
            locationId: selected.locationId,
            customerId: selected.customerId,
            commodity: selected.commodity,
            purchaseCommodity: purchaseCommodity,
            chargeCommodity: chargeCommodity,
            infoBoxes: selected.infoBoxes,
            sReports: [],
            MU: measurementUnit,
            filterByDate: false,
            filterServicesByDate: false,
            haulDates: {
              fromTS: '',
              toTS: '',
              totalsReviewed: false,
              genericTotal: 0
            }
          };
          if (reset) {
            this.selectedOptions[currIndex] = selectedObj;

            // For Reactivity
            this.selectedOptions = this.selectedOptions.slice();
          } else {
            this.selectedOptions.push(selectedObj);
          }
        } else {
          for (let i = 0; i < this.sReportForEdit.selectedOptions.length; i++) {
            let option = this.sReportForEdit.selectedOptions[i];
            if (option.locationId === selected.locationId) {
              option = JSON.parse(JSON.stringify(option));

              // Set selectedOptions this way to maintain reactivity
              for (const key in option) {
                this.selectedOptions[currIndex][key] = option[key];
              }

              break;
            }
          }
        }
      }

      if (stepStart) {
        this.modes.loading = true;

        const lookUpCustomer = () => {
          const customerDoc = db
            .collection('customers')
            .doc(selected.customerId);

          const getCustomerInfo = customerDoc.get().then(doc => {
            const customerInfo = doc.data();
            this.customerInfo = customerInfo;

            if (!onSave) {
              let cityStZip =
                (customerInfo.city || '') +
                ' ' +
                (customerInfo.state || '') +
                ', ' +
                customerInfo.zipcode;

              cityStZip = cityStZip.length === 3 ? '' : cityStZip;

              this.address = {
                name: selected.customer,
                streetAddress: customerInfo.address,
                cityStZip: cityStZip
              };
            }

            this.customer = selected.customer;

            return true;
          });
          return getCustomerInfo;
        };

        const promiseOne = lookUpCustomer();

        const customerSReports = [];
        const lookUpCustomerSReports = () => {
          const sReportCol = db
            .collection('settlement_reports')
            .where('customerId', '==', selected.customerId)
            .orderBy('sReportTS', 'desc')
            .limit(20);

          const getCustomerSReports = sReportCol.get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
              customerSReports.push({
                ...doc.data(),
                ...{ sReportId: doc.id }
              });
            });
            return true;
          });
          return getCustomerSReports;
        };

        const promiseTwo = lookUpCustomerSReports();

        const allSReports = [];
        const lookUpAllSReports = () => {
          const sReportCol = db
            .collection('settlement_reports')
            .orderBy('sReportTS', 'desc')
            .limit(30);

          const getAllSReports = sReportCol.get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
              allSReports.push({
                ...doc.data(),
                ...{ sReportId: doc.id }
              });
            });
            return true;
          });
          return getAllSReports;
        };

        const promiseThree = lookUpAllSReports();

        await promiseOne;
        await promiseTwo;
        await promiseThree;

        this.recent.customerSReports = customerSReports;
        this.recent.allSReports = allSReports;

        this.calcSelectedTotal();

        this.showListSReports(false, false);

        this.modes.loading = false;
        if (!onSave && !onCreate) {
          this.step = 1;
        }
      } else {
        this.calcSelectedTotal();
        this.step = 2;
      }
    },
    locationName(option) {
      if (option.type === 'destination') {
        return this.masterDestinations[option.locationId].destinationName;
      } else {
        return this.masterSources[option.locationId].sourceName;
      }
    },
    onAddSelected(option, type) {
      this.addSelect = false;
      this.init(option, type, false, false, false, false);
      if (this.filterByDate) {
        this.onSelectDates(this.haulDates.fromTS, this.haulDates.toTS);
      }
    },
    onDeleteStart() {
      this.step = 0;
      this.loading = true;
    },
    onDeleteSReport() {
      this.recent.showListSReports = false;
      this.selectedOptions = [];
      this.modes.edit = false;
      this.init(this.selected, this.type, true, false, false, false);
      this.setGeneralInfo();
    },
    onRemoveSelected(option) {
      this.addSelect = false;
      let optionIndex;
      for (let i = 0; i < this.selectedOptions.length; i++) {
        const selOption = this.selectedOptions[i];
        if (selOption.locationId === option.locationId) {
          optionIndex = i;
          break;
        }
      }

      this.selectedOptions.splice(optionIndex, 1);
    },
    onEditSReport(sReport, step) {
      // Make copy of sReport!!
      this.modes.edit = true;
      this.sReportForEdit = JSON.parse(JSON.stringify(sReport));
      sReport = JSON.parse(JSON.stringify(sReport));

      this.address = sReport.address;
      this.grandTotal = sReport.grandTotal;
      this.sReportTS = sReport.sReportTS;
      this.sReportDate = sReport.sReportDate;
      this.sReportNumber = sReport.sReportNumber;
      this.notes = sReport.notes;
      this.selectedOptions = sReport.selectedOptions.slice();
      this.sReportId = sReport.sReportId;
      this.sReportType = sReport.sReportType;

      this.filterByDate = this.selectedOptions[0].filterByDate;
      this.haulDates = this.selectedOptions[0].haulDates;

      this.haulDates.from = new Date(this.haulDates.fromTS).toDateString();
      const to = date.subtractFromDate(new Date(this.haulDates.toTS), {
        days: 1
      });
      this.haulDates.to = to.toDateString();

      this.calcSelectedTotal();

      this.recent.showListSReports = false;
      this.step = step;
    },
    onSelectDates(from, to) {
      this.filterByDate = true;
      this.haulDates = {
        from: new Date(from).toDateString('en-US'),
        to: new Date(to).toDateString('en-US'),
        fromTS: from,
        toTS: to
      };
      this.displayDateRange = false;

      for (let i = 0; i < this.selectedOptions.length; i++) {
        this.selectedOptions[i].filterByDate = true;
        this.selectedOptions[i].haulDates = {
          fromTS: from,
          toTS: to
        };
        let hauledFiltered = 0;
        for (let j = 0; j < this.selectedOptions[i].hauls.length; j++) {
          const haul = this.selectedOptions[i].hauls[j];
          if (haul.dateTime > from && haul.dateTime < to) {
            hauledFiltered += haul.haulAmount;
          }
        }
        this.selectedOptions[i].hauledFiltered = hauledFiltered;
      }

      this.setReportType();
      this.calcSelectedTotal();
    },
    printSReport() {
      this.print = true;
      this.$nextTick(() => {
        const css = `
          .text-sm {
            font-size: 13px;
          }
          .text-md {
            font-size: 15px;
          }
          .right {
            text-align: right;
            padding-right: 10px;
          }
          .left {
            text-align: left;
            padding-left: 10px;
          }
          .top {
            border-top: none;
          }
          .wider {
            width: 200px;
          }
          th, td {
            padding-top: 3px;
            padding-bottom: 3px;
          }
          table,
          th,
          td {
            border-bottom: 1px solid #c0c0c0;
            border-top: 1px solid #c0c0c0;
            border-collapse: collapse;
          }
          @media print {
            div.no-break {
                page-break-inside: avoid;
            }
          }
          @page {
            margin-top: 65px;
            margin-left: 100px;
            margin-right: 100px;
            margin-bottom: 60px;
          }
        `;

        const printMe = document.getElementById('print');

        const d = new Printd();
        d.print(printMe, [css]);

        this.print = false;
      });
    },
    removeDateFilter() {
      this.filterByDate = false;
      this.filterServicesByDate = false;
      this.haulDates = {
        from: '',
        to: '',
        fromTS: '',
        toTS: ''
      };

      for (let i = 0; i < this.selectedOptions.length; i++) {
        this.selectedOptions[i].filterByDate = false;
        this.selectedOptions[i].filterServicesByDate = false;
        this.selectedOptions[i].haulDates = {
          fromTS: '',
          toTS: ''
        };
      }

      this.calcSelectedTotal();
    },
    removeItem(type, optionIndex, index) {
      if (type === 'pricePerUnit') {
        this.selectedOptions[optionIndex].showPricePerUnit = false;
      }

      if (type === 'service') {
        this.selectedOptions[optionIndex].services.splice(index, 1);
      }

      if (type === 'customService') {
        this.selectedOptions[optionIndex].customServices.splice(index, 1);
      }

      if (type === 'pricePerMile') {
        this.selectedOptions[optionIndex].showMiles = false;
      }

      if (type === 'customCharge') {
        this.selectedOptions[optionIndex].customCharges.splice(index, 1);
      }

      if (type === 'customCredit') {
        this.selectedOptions[optionIndex].customCredits.splice(index, 1);
      }

      this.calcSelectedTotal();
    },
    saveSReport() {
      this.modes.loading = true;
      this.step = 0;

      this.setSRNumbers();

      const selectedOptions = [];
      // Upercase & format price
      for (let i = 0; i < this.selectedOptions.length; i++) {
        for (
          let j = 0;
          j < this.selectedOptions[i].customServices.length;
          j++
        ) {
          let name = this.selectedOptions[i].customServices[j].name;
          name = name.trim();
          // removing this at Calvins request
          //name = name.replaceAll('.', '');
          name = name.replace(/(^\w{1})|(\s+\w{1})/g, letter =>
            letter.toUpperCase()
          );
          this.selectedOptions[i].customServices[j].name = name;
        }

        for (let j = 0; j < this.selectedOptions[i].customCharges.length; j++) {
          let name = this.selectedOptions[i].customCharges[j].name;
          name = name.trim();
          //name = name.replaceAll('.', '');
          name = name.replace(/(^\w{1})|(\s+\w{1})/g, letter =>
            letter.toUpperCase()
          );
          this.selectedOptions[i].customCharges[j].name = name;
        }

        for (let j = 0; j < this.selectedOptions[i].customCredits.length; j++) {
          let name = this.selectedOptions[i].customCredits[j].name;
          name = name.trim();
          //name = name.replaceAll('.', '');
          name = name.replace(/(^\w{1})|(\s+\w{1})/g, letter =>
            letter.toUpperCase()
          );
          this.selectedOptions[i].customCredits[j].name = name;
        }

        // Magic to filter out some data
        const option = this.selectedOptions[i];
        /* eslint-disable no-unused-vars */
        const {
          sReports,
          customerId,
          avatarColor,
          hauls,
          totalsReviewed,
          genericTotal,
          ...filteredObj
        } = option;
        /* eslint-enable no-unused-vars */
        selectedOptions.push(filteredObj);
      }

      const QB = {
        qbInvoice: false
      };

      const sReportObj = {
        customerId: this.customerId,
        sReportDate: this.date,
        sReportTS: +new Date(),
        selectedOptions: selectedOptions,
        address: this.address,
        notes: this.notes,
        sReportNumber: this.sReportNumber,
        grandTotal: this.grandTotal,
        grandTotalRaw: this.grandTotalRaw,
        sReportType: this.sReportType,
        QB
      };

      const batch = db.batch();

      let sReportDoc = db.collection('settlement_reports').doc();

      if (this.modes.edit) {
        sReportDoc = db.collection('settlement_reports').doc(this.sReportId);
        sReportObj.sReportTS = this.sReportTS;
      }

      const sReportNumber = parseInt(this.sReportNumber);

      const settingsDoc = db.collection('settings').doc('settings');
      const settingsObj = {
        'general.sReportNumber': sReportNumber
      };

      if (!this.modes.edit) {
        for (let i = 0; i < this.selectedOptions.length; i++) {
          const option = this.selectedOptions[i];
          const sReports = option.sReports;
          sReports.push(sReportDoc.id);

          const sReportsObj = {
            settlementReports: option.sReports
          };

          if (option.type === 'destination') {
            const destDoc = db
              .collection('destinations_active')
              .doc(option.destOrSourceId);

            batch.update(destDoc, sReportsObj);
          } else {
            const sourceDoc = db
              .collection('sources_active')
              .doc(option.destOrSourceId);

            batch.update(sourceDoc, sReportsObj);
          }
        }
      }

      if (!this.modes.edit) {
        batch.update(settingsDoc, settingsObj);
      }

      batch.set(sReportDoc, sReportObj);

      batch
        .commit()
        .then(() => {
          sReportObj.sReportId = sReportDoc.id;
          this.onEditSReport(sReportObj, 4);
          this.init(this.selected, this.type, true, false, true, false);
        })
        .catch(err => {
          console.log(err);
        });
    },
    setGeneralInfo(fromWatcher = false) {
      this.customer = this.selected.customer;
      this.allSelected.push(this.selected);
      this.avatarColor = this.selected.avatarColor;

      const generalSettings = this.$store.state.generalSettings;
      this.sReportType = generalSettings.defaultSReportType;
      this.sReportNumber = generalSettings.sReportNumber + 1;
      this.sReportNumberOld = generalSettings.sReportNumber;

      // Prevent note from getting overwritten by default
      if (!fromWatcher) {
        this.notes.sReportNote = generalSettings.defaultSReportNote;
      }
      this.date = date.formatDate(new Date(), 'YYYY/MM/DD');
    },
    setDateFilter() {
      this.displayDateRange = true;
    },
    setReportType() {
      const generalSettings = this.$store.state.generalSettings;
      this.sReportType = generalSettings.defaultSReportType;

      this.sReportType = this.condensedAvailable
        ? this.sReportType
        : 'itemized';
    },
    setSRNumbers() {
      const activeSources = this.$store.state.activeSources;
      for (let i = 0; i < activeSources.length; i++) {
        const source = activeSources[i];
        for (let j = 0; j < this.selectedOptions.length; j++) {
          const option = this.selectedOptions[j];
          if (source.id === option.destOrSourceId) {
            this.selectedOptions[j].sReports = source.settlementReports;
          }
        }
      }
      const activeDestinations = this.$store.state.activeDestinations;
      for (let i = 0; i < activeDestinations.length; i++) {
        const destination = activeDestinations[i];
        for (let j = 0; j < this.selectedOptions.length; j++) {
          const option = this.selectedOptions[j];
          if (destination.id === option.destOrSourceId) {
            this.selectedOptions[j].sReports = destination.settlementReports;
          }
        }
      }
    },
    showInfo() {
      const destinationInfo = [];
      const sourceInfo = [];

      const activeDestinations = JSON.parse(
        JSON.stringify(this.$store.state.activeDestinations)
      );
      const activeDestinationsUsers = JSON.parse(
        JSON.stringify(this.$store.state.activeDestinationsUsers)
      );

      for (let i = 0; i < this.selectedOptions.length; i++) {
        const option = this.selectedOptions[i];

        for (let j = 0; j < activeDestinations.length; j++) {
          let destinationRaw = activeDestinations[j];

          for (let k = 0; k < activeDestinationsUsers[j].services.length; k++) {
            activeDestinationsUsers[j].services[k] = {
              ...activeDestinationsUsers[j].services[k],
              ...activeDestinations[j].services[k]
            };
          }

          if (option.locationId === destinationRaw.destinationLocationId) {
            destinationRaw = {
              ...activeDestinations[j],
              ...activeDestinationsUsers[j],
              ...{ avatarColor: option.avatarColor }
            };

            destinationInfo.push(destinationRaw);
          }
        }
      }

      const activeSources = JSON.parse(
        JSON.stringify(this.$store.state.activeSources)
      );
      const activeSourcesUsers = JSON.parse(
        JSON.stringify(this.$store.state.activeSourcesUsers)
      );

      for (let i = 0; i < this.selectedOptions.length; i++) {
        const option = this.selectedOptions[i];

        for (let j = 0; j < activeSources.length; j++) {
          let sourceRaw = activeSources[j];

          for (let k = 0; k < activeSourcesUsers[j].services.length; k++) {
            activeSourcesUsers[j].services[k] = {
              ...activeSourcesUsers[j].services[k],
              ...activeSources[j].services[k]
            };
          }

          if (option.locationId === sourceRaw.sourceLocationId) {
            sourceRaw = {
              ...activeSources[j],
              ...activeSourcesUsers[j],
              ...{ avatarColor: option.avatarColor }
            };

            sourceInfo.push(sourceRaw);
          }
        }
      }

      this.report.destinationInfo = destinationInfo;
      this.report.sourceInfo = sourceInfo;

      this.report.show = true;
    },
    showReport(option) {
      this.detailedReport.reportId = option.destOrSourceId;
      this.detailedReport.reportType = option.type;
      this.detailedReport.display = true;
    },
    showListSReports(edit, show) {
      this.modes.allowEdit = edit;

      this.recentMod = JSON.parse(JSON.stringify(this.recent));
      this.hasSReport = false;

      // Determine if sReport can be edited & is current sReport
      const activeDestinations = this.$store.state.activeDestinations;
      const activeSources = this.$store.state.activeSources;
      for (let i = 0; i < this.recentMod.customerSReports.length; i++) {
        const sReport = this.recentMod.customerSReports[i];
        let canEdit = true;

        for (let j = 0; j < sReport.selectedOptions.length; j++) {
          const option = sReport.selectedOptions[j];
          let optionIsArchived = true;

          for (let k = 0; k < activeDestinations.length; k++) {
            const dest = activeDestinations[k];
            if (dest.id === option.destOrSourceId) {
              optionIsArchived = false;
              if (dest.destinationLocationId === this.selected.locationId) {
                if (dest.settlementReports.length > 0) {
                  this.hasSReport = true;
                  break;
                }
              }
            }
          }

          for (let k = 0; k < activeSources.length; k++) {
            const source = activeSources[k];
            if (source.id === option.destOrSourceId) {
              optionIsArchived = false;
              if (source.sourceLocationId === this.selected.locationId) {
                if (source.settlementReports.length > 0) {
                  this.hasSReport = true;
                  break;
                }
              }
            }
          }

          if (optionIsArchived) {
            canEdit = false;
          }
        }
        this.recentMod.customerSReports[i].canEdit = canEdit;
        this.recentMod.customerSReports[i].isCurrent = false;
      }
      if (edit) {
        this.modes.edit = true;
      } else {
        for (let i = 0; i < this.recentMod.customerSReports.length; i++) {
          const sReport = this.recentMod.customerSReports[i];

          if (sReport.sReportTS === this.sReportTS) {
            this.recentMod.customerSReports[i].isCurrent = true;
          }
        }

        for (let i = 0; i < this.recentMod.allSReports.length; i++) {
          const sReport = this.recentMod.allSReports[i];

          if (sReport.sReportTS === this.sReportTS) {
            this.recentMod.allSReports[i].isCurrent = true;
          }
        }

        const selectedOptions = [];
        // Upercase & format price
        for (let i = 0; i < this.selectedOptions.length; i++) {
          for (
            let j = 0;
            j < this.selectedOptions[i].customServices.length;
            j++
          ) {
            let name = this.selectedOptions[i].customServices[j].name;
            name = name.trim();
            //name = name.replaceAll('.', '');
            name = name.replace(/(^\w{1})|(\s+\w{1})/g, letter =>
              letter.toUpperCase()
            );
            this.selectedOptions[i].customServices[j].name = name;
          }

          for (
            let j = 0;
            j < this.selectedOptions[i].customCharges.length;
            j++
          ) {
            let name = this.selectedOptions[i].customCharges[j].name;
            name = name.trim();
            //name = name.replaceAll('.', '');
            name = name.replace(/(^\w{1})|(\s+\w{1})/g, letter =>
              letter.toUpperCase()
            );
            this.selectedOptions[i].customCharges[j].name = name;
          }

          for (
            let j = 0;
            j < this.selectedOptions[i].customCredits.length;
            j++
          ) {
            let name = this.selectedOptions[i].customCredits[j].name;
            name = name.trim();
            //name = name.replaceAll('.', '');
            name = name.replace(/(^\w{1})|(\s+\w{1})/g, letter =>
              letter.toUpperCase()
            );
            this.selectedOptions[i].customCredits[j].name = name;
          }

          // Filter out some data
          const option = this.selectedOptions[i];
          /* eslint-disable no-unused-vars */
          const { sReports, customerId, avatarColor, ...filteredObj } = option;
          /* eslint-enable no-unused-vars */
          selectedOptions.push(filteredObj);
        }

        const currSReport = {
          customerId: this.customerId,
          sReportDate: this.date,
          sReportTS: +new Date(),
          selectedOptions: selectedOptions,
          address: this.address,
          notes: this.notes,
          sReportNumber: this.sReportNumber + 1,
          grandTotal: this.grandTotal,
          grandTotalRaw: this.grandTotalRaw,
          sReportType: this.sReportType,
          canEdit: false,
          isCurrent: true
        };

        this.recentMod.customerSReports.unshift(currSReport);
        this.recentMod.allSReports.unshift(currSReport);
      }
      if (show) {
        this.recent.showListSReports = true;
      }
    }
  },
  computed: {
    commodities() {
      return this.$store.state.settingsUsers.commodities;
    },
    condensedAvailable() {
      if (this.filterByDate) {
        for (let i = 0; i < this.selectedOptions.length; i++) {
          if (!this.selectedOptions[i].filterServicesByDate) {
            return false;
          }
        }
      }
      return true;
    },
    customers() {
      return this.$store.state.customers;
    },
    destAs() {
      return this.$store.state.settingsUsers.destAs;
    },
    destinationInputs() {
      return this.$store.state.filterLists.destinationInputs;
    },
    masterDestinations() {
      return this.$store.state.masterDestinations;
    },
    masterSources() {
      return this.$store.state.masterSources;
    },
    sourceInputs() {
      return this.$store.state.filterLists.sourceInputs;
    }
  },
  watch: {
    '$store.state.generalSettings'() {
      this.setGeneralInfo(true);
    }
  }
};
</script>

<style scoped>
.option {
  width: 420px;
  padding: 30px 10px;
}

.outer-option-div {
  border: 1px solid grey;
  padding: 30px;
}

@media all and (max-width: 599px) {
  .option {
    width: 100%;
    padding: 0px 0px 30px 0px;
  }

  .outer-option-div {
    border: none;
    padding: 0px;
  }
}

@media all and (max-width: 450px) {
  .option {
    width: 100%;
    padding: 0px 0px 30px 0px;
  }

  .outer-option-div {
    border: none;
    padding: 0px;
  }
}
</style>
