<template>
  <q-dialog persistent v-model="okDisplay">
    <q-card class="q-pa-lg outer-card">
      <div class="q-pa-sm">
        <q-card
          flat
          bordered
          class="q-pa-md text-grey-9 row items-center rounded-borders"
          style="min-width: 250px"
        >
          <div class="row full-width">
            <q-card
              flat
              class="row justify-center items-center full-width bg-grey-3 q-pa-sm"
            >
              <q-icon
                :name="icon"
                :color="iconColor"
                :style="iconStyle"
                size="md"
              />
              <div class="text-bold text-primary q-ml-sm">
                {{ headerMessage }}
              </div>
            </q-card>
          </div>
          <div class="q-py-md full-width text-center">
            <div v-html="message" />
          </div>
        </q-card>
        <div align="right" class="q-mt-md">
          <q-btn label="Ok" color="primary" padding="xs lg" @click="ok" />
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'OkDialog',
  props: {
    headerMessage: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    iconColor: {
      type: String,
      default: ''
    },
    iconStyle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      okDisplay: true,
      messageArr: []
    };
  },
  methods: {
    ok() {
      this.okDisplay = false;
      this.$emit('okConfirmed');
    }
  }
};
</script>

<style scoped>
.outer-card {
  min-width: 280px;
}
</style>
