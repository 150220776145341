import { render, staticRenderFns } from "./Reports.vue?vue&type=template&id=ef680d04&scoped=true&"
import script from "./Reports.vue?vue&type=script&lang=js&"
export * from "./Reports.vue?vue&type=script&lang=js&"
import style0 from "./Reports.vue?vue&type=style&index=0&lang=css&"
import style1 from "./Reports.vue?vue&type=style&index=1&id=ef680d04&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef680d04",
  null
  
)

export default component.exports
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QLayout,QHeader,QToolbar,QBtn,QSpace,QPageContainer,QPage,QLinearProgress,QCard,QCheckbox,QOptionGroup});
