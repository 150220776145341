<template>
  <div class="row justify-center full-width">
    <DestinationLocations
      v-if="manageLocations"
      ref="destinationLocations"
      :customerId="destination.customerId"
      :destinationLocations="destinationLocations"
      :mapView="mapView"
      @managePin="managePin"
      @reloadLocations="setDestinationLocations"
      @onSelectLocation="onSelectLocation"
      @layerThreeAttachments="layerThreeAttachments"
    />

    <LoadingGif v-if="displayLoadingGif" />

    <OkDialog
      v-if="ok.display"
      :icon="ok.icon"
      :iconColor="ok.iconColor"
      :iconStyle="ok.iconStyle"
      :headerMessage="ok.headerMessage"
      :message="ok.message"
      @okConfirmed="okCancel"
    />

    <div
      v-show="!manageLocations && !displayLoadingGif"
      class="row full-width q-pa-md"
    >
      <div class="half-width half-width-mr">
        <q-card flat bordered class="q-mb-md">
          <div class="row justify-center items-center q-py-xs">
            <q-icon name="account_circle" color="primary" size="md" />
            <span class="text-body1 text-primary word-wrap q-ml-sm">
              {{ destination.customer }}
            </span>
          </div>
          <q-separator class="full-width" />

          <div class="q-px-md q-py-md text-body1 text-primary">
            <div class="row items-center">
              <q-icon
                name="location_on"
                color="primary"
                size="md"
                class="q-mr-xs"
              />

              <div v-if="location.customerId">
                {{ location.destinationName }}
              </div>
              <q-space />
              <div class="q-py-xs">
                <q-btn
                  v-if="!location.customerId"
                  label="Set Location"
                  no-caps
                  color="custom-red"
                  padding="2px md"
                  @click="manageDestinations"
                />
                <q-btn
                  v-if="
                    location.customerId &&
                    destination.hauls.length < 1 &&
                    isSuper &&
                    !mapView
                  "
                  label="Edit Location"
                  no-caps
                  color="custom-orange"
                  padding="2px md"
                  @click="manageDestinations"
                />
                <q-btn
                  v-if="
                    (location.customerId && !(destination.hauls.length < 1)) ||
                    !isSuper
                  "
                  label="Edit Location"
                  no-caps
                  color="custom-light"
                  padding="2px md"
                />
              </div>
            </div>
          </div>
          <q-separator />

          <div class="row items-center q-pa-md">
            <div
              class="row"
              :style="
                $q.screen.lt.sm ? 'width: 100%' : 'width: 49%; margin-right: 2%'
              "
            >
              <div class="row items-center full-width no-wrap ellipsis">
                <div
                  class="row justify-center items-center bg-custom-light rounded-borders text-h5"
                  style="width: 44px; height: 44px; position: relative"
                >
                  <span style="z-index: 2">
                    {{ destination.customer.charAt(0) }}
                  </span>
                  <div
                    class="row items-center justify-center bg-custom-light-blue"
                    :style="`position: absolute; bottom: 0; width: 44px;
                  border-bottom-left-radius: 4px;
                  border-bottom-right-radius: 4px;
                  border-top-right-radius: ${
                    percentHauled === '100%' ? 4 : 0
                  }px;
                  border-top-left-radius: ${percentHauled === '100%' ? 4 : 0}px;
                  border-bottom-top-radius: 4px;
                  height: ${percentHauled}`"
                  ></div>
                </div>

                <div class="ellipsis q-ml-md">
                  {{ Math.round(destination.hauled) }} /
                  {{ Math.round(destination.projected) || '--' }}
                  {{ destination.measurementUnit }}
                </div>
              </div>
            </div>
          </div>
          <q-separator />

          <div class="row items-center q-pa-md">
            <div
              class="row justify-center items-center full-width text-bold text-body1 text-custom-orange"
            >
              Note
              <q-icon
                v-if="!importantNote"
                name="edit_note"
                size="28px"
                class="q-ml-sm"
              />
              <q-icon
                v-if="importantNote"
                name="announcement"
                size="28px"
                color="custom-red"
                class="q-ml-sm"
              />
            </div>
          </div>

          <div class="row q-px-md">
            <div class="full-width q-mb-md">
              <q-input
                outlined
                v-model="note"
                label="Note"
                label-color="custom-orange"
                color="custom-orange"
                autogrow
                dense
              />
            </div>
            <div class="q-px-sm q-py-xs"></div>
          </div>
        </q-card>
      </div>

      <div class="half-width">
        <q-card flat bordered class="q-pb-sm q-mb-md">
          <div class="row q-pa-md">
            <div
              class="row justify-center full-width rounded-borders text-primary text-bold text-body1 q-py-xs q-mb-lg"
              style="border: 1px solid #0b0d6f"
            >
              Services
            </div>

            <div
              v-for="(service, index) in destination.services"
              :key="index + 100"
              class="row justify-center full-width"
            >
              <div>
                <div
                  class="row justify-center text-subtitle1 text-primary full-width"
                >
                  {{ service.name }}
                </div>

                <div class="row items-center full-width q-py-sm">
                  <q-checkbox
                    v-model="serviceCheckboxes[index]"
                    dense
                    :label="service.name + ' Complete'"
                    color="custom-red"
                    size="lg"
                  />
                </div>
              </div>
              <q-separator
                v-if="index !== serviceCheckboxes.length - 1"
                color="grey-4"
                class="q-my-md full-width"
              />
            </div>
          </div>
        </q-card>
      </div>
    </div>
  </div>
</template>

<script>
import { date } from 'quasar';
import firebase from 'firebase/compat/app';
import DestinationLocations from '@/components/destinations/DestinationLocations.vue';
import LoadingGif from '@/components/LoadingGif.vue';
import OkDialog from '@/components/OkDialog.vue';
import { round2Dec } from '@/utils/UtilHelpers.js';

const db = firebase.firestore();

export default {
  name: 'EditDestinationUsers',
  components: {
    DestinationLocations,
    LoadingGif,
    OkDialog
  },
  props: {
    destination: {
      type: Object,
      required: true
    },
    mapView: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      customSelectors: [],
      destinationLocations: [],
      destinationLocationsIndex: 0,
      displayLoadingGif: false,
      importantNote: false,
      location: {
        customerId: null,
        lat: null,
        lng: null
      },
      manageLocations: false,
      note: '',
      ok: {
        display: false,
        icon: 'outbound',
        iconColor: 'custom-red',
        iconStyle: 'transform: rotate(180deg)',
        headerMessage: '',
        message: ''
      },
      serviceCheckboxes: []
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.setDestinationLocations();
    this.setDestinationInfo();
    this.importantMessage();
  },
  methods: {
    cancel() {
      if (!this.destination.id) {
        this.$emit('onSetId', 'no_history');
        this.$emit('onSubmit');
      } else {
        this.$emit('onSetId', this.destination.id);
        this.$emit('onSubmit');
      }
    },
    cancelLayerTwoInputs() {
      if (this.destination.action === 'edit') {
        this.location = this.masterDestinations[this.destination.locationId];
      }
      this.manageLocations = false;
    },
    cancelLayerThreeAttachment() {
      this.$refs.destinationLocations.cancelLayerThreeAttachment();
    },
    importantMessage() {
      if (this.importantNote) {
        const importantNoteIds = this.$store.state.importantNoteIds;
        let spamUser = true;
        for (let i = 0; i < importantNoteIds.length; i++) {
          if (importantNoteIds[i].id === this.destination.id) {
            const tsFiveMinAgo = +new Date() - 300000;
            if (tsFiveMinAgo < importantNoteIds[i].ts) {
              spamUser = false;
            }
          }
        }
        if (spamUser && this.destination.note.length !== 0) {
          this.$store.dispatch('setImportantNoteIds', {
            id: this.destination.id,
            ts: +new Date()
          });

          this.ok = {
            display: true,
            icon: 'announcement',
            iconColor: 'custom-red',
            iconStyle: '',
            headerMessage: 'Important Note',
            message: this.note
          };
        }
      }
    },
    layerThreeAttachments() {
      this.$emit('layerThreeAttachments');
    },
    manageDestinations() {
      this.manageLocations = true;
      if (this.mapView) {
        this.$emit('layerThreeLocations', true);
      } else {
        this.$emit('layerTwoLocations');
      }
    },
    okCancel() {
      this.ok = {
        display: false,
        icon: 'outbound',
        iconColor: 'custom-red',
        iconStyle: 'transform: rotate(180deg)',
        headerMessage: '',
        message: ''
      };
    },
    onBackToField() {
      if (this.destination.action === 'edit') {
        this.location = this.masterDestinations[this.destination.locationId];
      }
      this.manageLocations = false;
    },
    onEnterDestination() {
      let id;
      if (this.destination.action === 'edit') {
        id = this.destination.id;
      } else {
        id = 'alpha';
      }

      const updateComplete = (message, id) => {
        this.$q.notify({
          color: 'primary',
          textColor: 'white',
          message: message
        });
        this.$emit('onSubmit');
        this.$emit('onSetId', id);
      };

      const userServices = [];
      for (let i = 0; i < this.destination.services.length; i++) {
        const service = this.destination.services[i];

        let completeTs = null;
        if (service.complete && this.serviceCheckboxes[i]) {
          completeTs = service.completeTs;
        } else if (!service.complete && this.serviceCheckboxes[i]) {
          completeTs = +new Date();
        } else if (!this.serviceCheckboxes[i]) {
          completeTs = null;
        }

        const obj = {
          name: this.destination.services[i].name,
          complete: this.serviceCheckboxes[i],
          completeTs: completeTs
        };
        userServices.push(obj);
      }

      if (this.destination.action === 'add') {
        this.displayLoadingGif = true;

        const destObj = {
          id: null,
          customerId: this.destination.customerId,
          destinationLocationId: this.location.id,
          startDate: +new Date(),
          services: null,
          note: this.note,
          edit: false
        };

        const openField = firebase
          .functions()
          .httpsCallable('openDestForSupers');
        openField(destObj).then(confirmation => {
          if (confirmation.data[0] === 'Field Successfully Added/Opened') {
            console.log(confirmation.data);
            this.displayLoadingGif = false;
            updateComplete(
              this.location.destinationName + ' Added',
              confirmation.data[1]
            );
          }
        });
      } else if (this.location.id !== this.destination.locationId) {
        this.displayLoadingGif = true;

        const destObj = {
          id: this.destination.id,
          customerId: null,
          destinationLocationId: this.location.id,
          startDate: null,
          services: userServices,
          note: this.note,
          edit: true
        };

        const openField = firebase
          .functions()
          .httpsCallable('openDestForSupers');
        openField(destObj).then(confirmation => {
          console.log(confirmation.data);
          if (confirmation.data === 'Field Successfully Added/Opened') {
            this.displayLoadingGif = false;
            updateComplete(
              this.location.destinationName + ' Updated',
              confirmation.data[1]
            );
          }
        });
      } else {
        const destinationUsers = {
          services: userServices,
          note: this.note
        };

        db.collection('destinations_active_users')
          .doc(id)
          .update(destinationUsers);

        updateComplete(this.location.destinationName + ' Updated', id);
      }
    },
    onSelectLocation(index) {
      this.location = this.destinationLocations[index];
      this.manageLocations = false;
      this.$emit('setLocation');
    },
    setDestinationInfo() {
      const destination = JSON.parse(JSON.stringify(this.destination));

      if (destination.action === 'edit') {
        this.note = destination.note;
        this.importantNote = destination.importantNote;
        this.location = this.masterDestinations[destination.locationId];
        this.location.id = destination.locationId;

        for (let i = 0; i < destination.services.length; i++) {
          this.serviceCheckboxes.push(destination.services[i].complete);
        }

        this.customSelectors = [];
        for (
          let i = 0;
          i < this.userDestinationSettings.customSelectors.length;
          i++
        ) {
          const customSelector =
            this.userDestinationSettings.customSelectors[i];
          const options = [];

          for (let j = 0; j < customSelector.options.length; j++) {
            const option = customSelector.options[j];

            options.push({
              label: option.option,
              value: option.option,
              color: option.optionColor
            });
          }

          let model = customSelector.options[0].option;

          for (let j = 0; j < destination.userCustomSelectors.length; j++) {
            const selector = destination.userCustomSelectors[j];
            if (selector.name === customSelector.name) {
              model = selector.model;
            }
          }

          this.customSelectors.push({
            name: customSelector.name,
            options: options,
            model: model,
            userAuth: customSelector.userAuth
          });
        }
      }
    },
    setDestinationLocations() {
      const activeDestinationsUsers = JSON.parse(
        JSON.stringify(this.$store.state.activeDestinationsUsers)
      );

      this.destinationLocations = [];

      for (const key in this.masterDestinations) {
        if (
          this.masterDestinations[key].customerId ===
          this.destination.customerId
        ) {
          let isActive = false;
          for (let i = 0; i < activeDestinationsUsers.length; i++) {
            const dest = activeDestinationsUsers[i];
            if (
              dest.customerId === this.destination.customerId &&
              dest.destinationLocationId === key
            ) {
              isActive = true;
              break;
            }
          }

          this.destinationLocations.push({
            ...this.masterDestinations[key],
            id: key,
            isActive: isActive
          });
        }
      }

      const sortByAlpha = (a, b) => {
        if (a.destinationName < b.destinationName) {
          return -1;
        }
        if (a.destinationName > b.destinationName) {
          return 1;
        }
        return 0;
      };
      this.destinationLocations.sort(sortByAlpha);
    }
  },
  computed: {
    isSuper() {
      let isSuper = false;
      if (this.$store.state.user.superUser === true) {
        isSuper = true;
      }
      return isSuper;
    },
    masterDestinations() {
      return JSON.parse(JSON.stringify(this.$store.state.masterDestinations));
    },
    percentHauled() {
      const pH = !this.destination.projected
        ? 0
        : round2Dec(this.destination.hauled / this.destination.projected) * 100;
      return pH > 100 ? '100%' : pH + '%';
    },
    startDate() {
      return date.formatDate(this.destination.startDate, 'ddd, MMM DD YYYY');
    },
    userDestinationSettings() {
      return JSON.parse(
        JSON.stringify(this.$store.state.settingsUsers.destinationSettings)
      );
    }
  },
  watch: {
    '$store.state.masterDestinations'() {
      this.setDestinationLocations();
      this.setDestinationInfo();
    },
    destination() {
      this.setDestinationInfo();
    }
  }
};
</script>

<style scoped></style>
