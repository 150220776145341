<template>
  <div>
    <q-dialog v-model="confirmDisplay" persistent>
      <q-layout
        view="Lhh lpr fff"
        container
        :style="contentHeight"
        class="bg-white outer"
      >
        <q-header class="bg-grey-3">
          <q-toolbar class="q-px-md q-pt-md">
            <div class="text-h5 text-primary q-ml-xs">
              {{ timeChunk.day }}
            </div>
          </q-toolbar>
          <q-toolbar class="q-px-md q-py-md">
            <q-icon name="schedule" size="md" color="primary" />
            <div class="q-ml-md text-body1 text-primary">
              {{ timeChunk.clockInTime }} - {{ timeChunk.clockOutTime }}
            </div>
          </q-toolbar>
        </q-header>

        <div class="q-pa-lg" style="margin-top: 115px">
          <div v-if="edit.err" class="row justify-center q-mb-md">
            <q-chip
              icon="date_range"
              color="custom-red"
              text-color="white"
              class="q-px-md q-mb-sm"
            >
              <div class="text-white">
                {{ edit.errMessage }}
              </div>
            </q-chip>
          </div>
          <div v-if="timeChunk.dbTimeChunk" class="q-mb-md">
            <span class="text-bold text-body1 text-grey-7"> Total Hours: </span>
            <span class="text-bold text-body1 q-ml-xs" :class="totalNegative">
              {{ total }}
            </span>
          </div>
          <div class="text-bold">Start Time {{ edit.startTime }}</div>
          <q-input
            outlined
            v-model="edit.startTime"
            color="primary"
            @input="calcTotal"
          >
            <template v-slot:prepend>
              <q-icon name="event" class="cursor-pointer" color="custom-green">
                <q-popup-proxy transition-show="scale" transition-hide="scale">
                  <q-date
                    v-model="edit.startTime"
                    mask="MMMM Do, YYYY - h:mm A"
                    color="custom-green"
                    @input="calcTotal"
                  >
                    <div class="row items-center justify-end">
                      <q-btn
                        v-close-popup
                        label="Close"
                        color="custom-green"
                        flat
                      />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>

            <template v-slot:append>
              <q-icon
                name="access_time"
                class="cursor-pointer"
                color="custom-green"
              >
                <q-popup-proxy transition-show="scale" transition-hide="scale">
                  <q-time
                    v-model="edit.startTime"
                    mask="MMMM Do, YYYY - h:mm A"
                    format12h
                    color="custom-green"
                    @input="calcTotal"
                  >
                    <div class="row items-center justify-end">
                      <q-btn
                        v-close-popup
                        label="Close"
                        color="custom-green"
                        flat
                      />
                    </div>
                  </q-time>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
          <div v-if="timeChunk.dbTimeChunk">
            <div class="text-bold q-mt-md">End Time</div>
            <q-input
              outlined
              v-model="edit.endTime"
              color="primary"
              @input="calcTotal"
            >
              <template v-slot:prepend>
                <q-icon name="event" class="cursor-pointer" color="custom-red">
                  <q-popup-proxy
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      v-model="edit.endTime"
                      mask="MMMM Do, YYYY - h:mm A"
                      color="custom-red"
                      @input="calcTotal"
                    >
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="custom-red"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>

              <template v-slot:append>
                <q-icon
                  name="access_time"
                  class="cursor-pointer"
                  color="custom-red"
                >
                  <q-popup-proxy
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-time
                      v-model="edit.endTime"
                      mask="MMMM Do, YYYY - h:mm A"
                      format12h
                      color="custom-red"
                      @input="calcTotal"
                    >
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="custom-red"
                          flat
                        />
                      </div>
                    </q-time>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
            <div class="text-bold q-mt-md">Lunch</div>
            <q-input
              outlined
              v-model.number="edit.breakMin"
              type="tel"
              mask="###"
              label="Auto Deduct Lunch - In Minutes"
              color="primary"
              label-color="primary"
              @input="calcTotal"
            />
            <div class="text-bold q-mt-md">Work Types</div>
            <q-select
              outlined
              v-model="edit.workType"
              :options="workTypes"
              color="primary"
              label-color="primary"
              label="Work Type"
            />
          </div>
          <div align="right" class="q-mt-lg">
            <q-btn
              flat
              color="primary"
              label="Cancel"
              icon="clear"
              class="q-mt-sm q-mr-sm"
              @click="backToTime"
            />
            <q-btn
              color="custom-orange"
              label="Submit"
              icon="done"
              class="q-mt-sm"
              @click="changeTime"
            />
          </div>
        </div>
      </q-layout>
    </q-dialog>
  </div>
</template>

<script>
import { date } from 'quasar';

export default {
  name: 'EditTime',
  props: {
    timeChunk: {
      type: Object,
      required: true
    },
    workTypes: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      confirmDisplay: true,
      contentHeight: 'height: 630px;',
      edit: {
        startTime: '',
        endTime: '',
        breakMin: 0,
        workType: '',
        err: false,
        errMessage: ''
      },
      total: '--:--',
      totalNegative: 'text-black'
    };
  },
  mounted() {
    this.setDates();
    this.calcTotal();
  },
  methods: {
    backToTime() {
      this.$emit('backToTime');
    },
    calcTotal() {
      this.totalNegative = 'text-black';
      const formatDiff = duration => {
        let negativeHelper = '';
        if (duration < 0) {
          duration = Math.abs(duration);
          negativeHelper = '- ';
          this.totalNegative = 'text-custom-red';
        }
        let minutes = parseInt(Math.round(duration / 60000) % 60);
        let hours = parseInt(duration / (1000 * 60 * 60));

        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        return negativeHelper + hours + ':' + minutes;
      };

      let startDate = this.edit.startTime;
      let endDate = this.edit.endTime;
      let total = '--:--';
      startDate = date.extractDate(startDate, 'MMMM Do, YYYY - h:mm A');
      endDate = date.extractDate(endDate, 'MMMM Do, YYYY - h:mm A');
      const twentyTwenty = new Date('01/01/2020').getTime();

      if (startDate < twentyTwenty || endDate < twentyTwenty) {
        this.total = total;
        this.totalNegative = 'text-custom-red';
        return false;
      }
      startDate = date.formatDate(startDate, 'x');
      endDate = date.formatDate(endDate, 'x');

      this.edit.breakMin = this.edit.breakMin === '' ? 0 : this.edit.breakMin;

      const diff = endDate - startDate - this.edit.breakMin * 60 * 1000;

      if (endDate - startDate > 86400000) {
        this.totalNegative = 'text-custom-red';
      }

      total = formatDiff(diff);
      this.total = total;
    },
    changeTime() {
      let startDate = this.edit.startTime;
      let endDate = this.edit.endTime;

      startDate = date.extractDate(startDate, 'MMMM Do, YYYY - h:mm A');
      endDate = date.extractDate(endDate, 'MMMM Do, YYYY - h:mm A');

      if (this.timeChunk.dbTimeChunk) {
        // Extract date reverts to 1899 on invalid format, so we do this arbitrary check..
        const twentyTwenty = new Date('01/01/2020').getTime();

        if (startDate < twentyTwenty || endDate < twentyTwenty) {
          this.edit.err = true;
          this.edit.errMessage = 'Date/Times are not valid';
          this.contentHeight = 'height: 690px;';
          return false;
        }
        startDate = date.formatDate(startDate, 'x');
        endDate = date.formatDate(endDate, 'x');

        if (startDate > endDate && this.timeChunk.dbTimeChunk) {
          this.edit.err = true;
          this.edit.errMessage = 'Start Time must be less than End Time';
          this.contentHeight = 'height: 690px;';
          return false;
        }

        if (endDate - startDate > 86400000) {
          this.edit.err = true;
          this.edit.errMessage = 'Cannot enter time greater than 24 hours';
          this.contentHeight = 'height: 690px;';
          return false;
        }

        const startToday = new Date().setHours(0, 0, 0, 0);
        let dayOfWeek = date.getDayOfWeek(startToday);
        dayOfWeek = dayOfWeek === 7 ? 0 : dayOfWeek;

        const fourWeeksAgo = date.formatDate(
          date.subtractFromDate(startToday, { days: 28 + dayOfWeek }),
          'x'
        );

        const fourWeeksAgoFormatted = date.formatDate(
          date.subtractFromDate(startToday, { days: 28 + dayOfWeek }),
          'MMM Do'
        );

        if (startDate < fourWeeksAgo) {
          this.edit.err = true;
          this.edit.errMessage = `Cannot set date before ${fourWeeksAgoFormatted}`;
          this.contentHeight = 'height: 690px;';
          return false;
        }

        const endOfWeek = date.formatDate(
          date.addToDate(startToday, { days: 7 - dayOfWeek }),
          'x'
        );
        const endOfWeekFormatted = date.formatDate(
          date.addToDate(startToday, { days: 7 - dayOfWeek - 1 }),
          'MMM Do'
        );

        if (startDate > endOfWeek) {
          this.edit.err = true;
          this.edit.errMessage = `Cannot set start date after ${endOfWeekFormatted}`;
          this.contentHeight = 'height: 690px;';
          return false;
        }
      } else {
        const twentyTwenty = new Date('01/01/2020').getTime();

        if (startDate < twentyTwenty) {
          this.edit.err = true;
          this.edit.errMessage = 'Date/Time is not valid';
          this.contentHeight = 'height: 380px;';
          return false;
        }

        startDate = date.formatDate(startDate, 'x');

        const now = +new Date();
        if (now - startDate > 86400000) {
          this.edit.err = true;
          this.edit.errMessage = 'Cannot set start date more than 24 hours ago';
          this.contentHeight = 'height: 380px;';
          return false;
        }
      }

      this.edit.breakMin = this.edit.breakMin === '' ? 0 : this.edit.breakMin;

      const editObj = {
        clockInTs: startDate,
        clockOutTs: endDate,
        breakMin: this.edit.breakMin,
        workType: this.edit.workType
      };

      this.$emit('editTime', editObj);
    },
    setDates() {
      if (this.timeChunk.dbTimeChunk) {
        this.edit.startTime = date.formatDate(
          this.timeChunk.dbTimeChunk.clockInTs,
          'MMMM Do, YYYY - h:mm A'
        );
        this.edit.endTime = date.formatDate(
          this.timeChunk.dbTimeChunk.clockOutTs,
          'MMMM Do, YYYY - h:mm A'
        );
      } else {
        this.edit.startTime = date.formatDate(
          this.timeChunk.clockInTs,
          'MMMM Do, YYYY - h:mm A'
        );
        this.contentHeight = 'height: 325px;';
      }
      this.edit.breakMin = this.timeChunk.breakMin;
      this.edit.workType = this.timeChunk.workType;
    }
  },
  computed: {},
  watch: {}
};
</script>

<style scoped>
.outer {
  min-width: 340px;
  max-width: 400px;
}

@media all and (max-width: 350px) {
  .outer {
    min-width: 310px;
  }
}
</style>
