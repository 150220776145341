import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'; // for authentication
import 'firebase/compat/firestore'; // for cloud firestore
import 'firebase/compat/functions'; // for cloud firestore
import 'firebase/compat/storage'; // for cloud firestore
import store from '@/store';

// Sandy Ridge Farm
const firebaseConfig = {
  apiKey: 'AIzaSyCrBYrqkMC8vHwq1Fizx5mR9Zo1IP_Y59o',
  authDomain: 'sandyridgefarminc-8e8cf.firebaseapp.com',
  projectId: 'sandyridgefarminc-8e8cf',
  storageBucket: 'sandyridgefarminc-8e8cf.appspot.com',
  messagingSenderId: '621689484606',
  appId: '1:621689484606:web:8ce7b2e9c0fd92f655e86f',
  measurementId: 'G-ZBVHLNE93H'
};

// Trolli demo
// const firebaseConfig = {
//   apiKey: 'AIzaSyByJBTL4Ks30BcjjvVaU7fT2aAcmplD8nM',
//   authDomain: 'trollibitz-bfc05.firebaseapp.com',
//   projectId: 'trollibitz-bfc05',
//   storageBucket: 'trollibitz-bfc05.appspot.com',
//   messagingSenderId: '87856586648',
//   appId: '1:87856586648:web:0e750315dcdede3835d5ed',
//   measurementId: 'G-3EQ6D6D8V7'
// }

// Initialize Firebase
export default firebase.initializeApp(firebaseConfig);

/*eslint-disable */
export const db = firebase
  .firestore()
  .enablePersistence()
  .catch(function (err) {
    console.log(err);
    if (err.code == 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code == 'unimplemented') {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });
/* eslint-enable */

firebase.auth().onAuthStateChanged(() => {
  store.dispatch('setUser');
});
