import { render, staticRenderFns } from "./ListSReports.vue?vue&type=template&id=165c7028&scoped=true&"
import script from "./ListSReports.vue?vue&type=script&lang=js&"
export * from "./ListSReports.vue?vue&type=script&lang=js&"
import style0 from "./ListSReports.vue?vue&type=style&index=0&id=165c7028&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "165c7028",
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QLayout,QHeader,QToolbar,QIcon,QSpace,QBtn,QBtnToggle,QScrollArea,QCard,QChip});
