import { render, staticRenderFns } from "./ArchivedReport.vue?vue&type=template&id=77fb3c05&scoped=true&"
import script from "./ArchivedReport.vue?vue&type=script&lang=js&"
export * from "./ArchivedReport.vue?vue&type=script&lang=js&"
import style0 from "./ArchivedReport.vue?vue&type=style&index=0&id=77fb3c05&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77fb3c05",
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QCard from 'quasar/src/components/card/QCard.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QLayout,QHeader,QBtn,QSpace,QScrollArea,QCard});
