<template>
  <div class="row justify-center full-width">
    <ArchivedList
      v-if="displayArchivedList"
      @setActionArchived="setActionArchived"
    />

    <SettlementReports
      v-if="displaySReports"
      :recent="sReports"
      :customer="sReports.customer"
      :edit="false"
      :showAll="false"
      @onEditSReport="sReports.doNothing"
      @onDeleteSReport="sReports.doNothing"
      @onDeleteStart="sReports.doNothing"
      @onCancel="displaySReports = false"
    />
  </div>
</template>

<script>
import ArchivedList from '@/components/settlement/ArchivedList.vue';
import SettlementReports from '@/components/settlement/ListSReports.vue';

export default {
  name: 'Archived-Main',
  components: {
    ArchivedList,
    SettlementReports
  },
  data() {
    return {
      displaySReports: false,
      displayArchivedList: true,
      sReports: {
        customerSReports: [],
        customer: '',
        doNothing() {}
      }
    };
  },
  methods: {
    setActionArchived() {
      this.$emit('onSetActionArchived');
    }
  }
};
</script>

<style scoped></style>
