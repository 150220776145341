<template>
  <q-dialog v-model="displaySReports" persistent>
    <ConfirmDialog
      v-if="confirm.display"
      icon="calculate"
      iconColor="primary"
      :btn="confirm.btn"
      :message="confirm.message"
      :headerMessage="customer"
      @canceled="confirm.display = false"
      @confirmed="confirmed"
    />

    <Print
      v-if="print"
      id="print"
      :selectedOptions="sReport.selectedOptions"
      :notes="sReport.notes"
      :address="sReport.address"
      :grandTotal="sReport.grandTotal"
      :grandTotalRaw="sReport.grandTotalRaw"
      :date="sReport.sReportDate"
      :sReportNumber="sReport.sReportNumber"
      :sReportType="sReport.sReportType"
    />

    <q-layout
      v-if="!this.hideAll"
      id="list-sreports"
      view="Lhh lpr fff"
      container
      class="bg-white outer"
    >
      <q-header class="bg-grey-2">
        <q-toolbar class="q-pl-lg q-py-md">
          <q-icon name="calculate" size="md" color="primary" />
          <div
            :class="$q.screen.gt.xs ? 'text-h6' : 'text-body1 text-bold'"
            class="q-ml-sm text-h6 text-primary"
          >
            Settlement Reports
          </div>
          <q-space />
          <q-btn
            flat
            round
            icon="clear"
            color="bg-grey-2"
            text-color="black"
            class="q-mr-sm"
            @click="cancel"
          />
        </q-toolbar>
        <q-toolbar v-if="showAll" class="q-px-lg q-pb-lg">
          <q-btn-toggle
            v-model="customerOrAll"
            spread
            no-caps
            toggle-color="custom-light-blue"
            color="white"
            text-color="custom-light-blue"
            class="full-width"
            :options="[
              { value: 'customer', slot: 'one' },
              { value: 'all', slot: 'two' }
            ]"
            @click="setCustOrAll"
          >
            <template v-slot:one>
              <div class="row items-center">
                <div class="text-center text-bold">Customer</div>
              </div>
            </template>
            <template v-slot:two>
              <div class="row items-center">
                <div class="text-center text-bold">Recent</div>
              </div>
            </template>
          </q-btn-toggle>
        </q-toolbar>
      </q-header>

      <q-scroll-area
        :thumb-style="thumbStyle"
        :bar-style="barStyle"
        :style="scrollHeight"
        style="margin-top: 134px"
      >
        <div style="min-width: 500px; overflow-x: auto" class="q-pa-md">
          <div
            class="bg-grey-3 q-pl-sm q-mb-lg row items-center rounded-borders"
          >
            <q-icon
              v-if="customerOrAll === 'customer'"
              name="person"
              color="primary"
              size="md"
              class="q-ma-sm"
            />
            <q-icon
              v-if="customerOrAll === 'all'"
              name="history"
              color="primary"
              size="md"
              class="q-ma-sm"
            />
            <div class="text-body1 text-bold text-primary q-ml-sm">
              <span v-if="customerOrAll === 'all'">
                Recent Settlement Reports
              </span>
              <span v-if="customerOrAll === 'customer'">
                {{ customer }}
              </span>
            </div>
          </div>

          <div
            v-if="sReportsFormatted.length < 1"
            class="row justify-center text-body1 text-bold text-grey-6 q-mt-xl"
          >
            No settlement reports for {{ customer }}
            <span v-if="SRLocation !== ''" class="q-ml-xs">
              - {{ SRLocation }}
            </span>
          </div>

          <q-card
            v-for="(sReport, index) in sReportsFormatted"
            :key="index"
            class="q-pa-sm q-mb-lg"
          >
            <div class="full-width">
              <div v-if="sReport.isCurrent && index === 0">
                <q-chip
                  color="grey-3"
                  text-color="custom-light-blue"
                  class="text-bold"
                >
                  Current Settlement Report
                </q-chip>
              </div>
              <div v-if="sReport.isCurrent && index > 0">
                <q-chip
                  color="grey-3"
                  text-color="custom-light-blue"
                  class="text-bold"
                >
                  Current Settlement Report Before Edit
                </q-chip>
              </div>

              <div class="row q-mt-lg">
                <div class="col-6 left" style="font-size: 16px">
                  <div>
                    {{ sReport.address.name }}
                  </div>
                  <div>
                    {{ sReport.address.streetAddress }}
                  </div>
                  <div>
                    {{ sReport.address.cityStZip }}
                  </div>
                </div>
                <div class="col-6 left" style="font-size: 16px">
                  <div>Date: {{ sReport.sReportDate }}</div>
                  <div>
                    Settlement Report
                    <span class="q-pl-md"> # {{ sReport.sReportNumber }}</span>
                  </div>
                </div>
              </div>
              <q-card
                flat
                v-if="sReport.filterByDate"
                class="bg-grey-3 full-width q-mt-lg q-py-md"
              >
                <div
                  class="row justify-center text-body1 text-bold text-primary"
                >
                  Settlement For Dates
                </div>
                <div class="row justify-center">
                  {{ sReport.haulDates.from }} -
                  {{ sReport.haulDates.to }}
                </div>
              </q-card>

              <div class="full-width">
                <div v-if="sReport.sReportType === 'itemized'">
                  <div
                    v-for="option in sReport.selectedOptions"
                    :key="option.locationName"
                    class="no-break"
                    style="
                      border: 1px solid #c0c0c0;
                      border-radius: 5px;
                      margin-top: 35px;
                      padding: 20px 10px 20px 10px;
                    "
                  >
                    <div
                      style="
                        font-size: 17px;
                        font-weight: bold;
                        text-align: center;
                        padding-bottom: 3px;
                        margin-bottom: 20px;
                        border-bottom: 1px solid #c0c0c0;
                      "
                    >
                      {{ locationId(option.locationId, option.type) }}
                    </div>
                    <table style="width: 100%; border-bottom: none" class="top">
                      <tr class="top">
                        <th colspan="1" class="left top" style="width: 38%">
                          Description
                        </th>
                        <th colspan="1" class="right top" style="width: 30%">
                          <span>Qty</span>
                        </th>
                        <th colspan="1" class="right top" style="width: 15%">
                          <span>Rate</span>
                        </th>
                        <th class="right top" style="width: 17%">Total</th>
                      </tr>

                      <tr v-if="checkIfCommodity(option)" class="text-sm pd-sm">
                        <td class="left" style="width: 38%">
                          {{ option.commodity }}
                        </td>
                        <td class="right" style="width: 30%">
                          {{ hauled(option) }} {{ option.MU }}
                        </td>
                        <td class="right" style="width: 15%">
                          {{ formatPrice(option.pricePerUnit) }}
                        </td>
                        <td class="right" style="width: 17%; font-weight: bold">
                          {{
                            formatPrice(option.pricePerUnit * hauled(option))
                          }}
                        </td>
                      </tr>

                      <tr
                        v-for="(service, index) in option.servicesNotPaired"
                        :key="index"
                        class="text-sm pd-sm"
                      >
                        <td class="left" style="width: 38%">
                          {{ service.name }}
                        </td>
                        <td class="right" style="width: 30%">
                          {{ servicesHauled(option) }} {{ option.MU }}
                        </td>
                        <td class="right" style="width: 15%">
                          {{ formatPrice(service.price) }}
                        </td>
                        <td
                          class="right text-no-wrap"
                          style="width: 17%; font-weight: bold"
                        >
                          <span v-if="option.type === 'source'">-</span>
                          {{
                            formatPrice(service.price * servicesHauled(option))
                          }}
                        </td>
                      </tr>

                      <tr
                        v-for="(service, index) in option.servicesPaired"
                        :key="index + 50"
                        class="text-sm pd-sm"
                      >
                        <td class="left" style="width: 38%">
                          {{ service.serviceName }}
                        </td>
                        <td class="right" style="width: 30%">
                          {{ service.serviceVal }}
                          {{ service.infoBoxMU }}
                        </td>
                        <td class="right" style="width: 15%">
                          {{ formatPrice(service.servicePrice) }}
                        </td>
                        <td class="right" style="width: 17%; font-weight: bold">
                          <span v-if="option.type === 'source'">-</span>
                          {{ formatPrice(service.serviceTotal) }}
                        </td>
                      </tr>

                      <tr
                        v-for="(service, index) in option.customServices"
                        :key="index + 50"
                        class="text-sm pd-sm"
                      >
                        <td class="left" style="width: 38%">
                          {{ service.name }}
                        </td>
                        <td class="right" style="width: 30%">Price Per Ton</td>
                        <td class="right" style="width: 15%">
                          {{ formatPrice(service.price) }}
                        </td>
                        <td class="right" style="width: 17%; font-weight: bold">
                          <span v-if="option.type === 'source'">-</span>
                          {{ formatPrice(service.price * hauled(option)) }}
                        </td>
                      </tr>

                      <tr
                        v-if="option.showMileage && option.showMiles"
                        class="text-sm pd-sm"
                      >
                        <td class="left" style="width: 38%">Mileage</td>
                        <td class="right" style="width: 30%">
                          {{ option.totalMileage }} Miles
                        </td>
                        <td class="right" style="width: 15%">
                          {{ formatPrice(option.mileageRate) }}
                        </td>
                        <td class="right" style="width: 17%; font-weight: bold">
                          <span v-if="option.type === 'source'">-</span>
                          {{
                            formatPrice(
                              option.mileageRate * option.totalMileage
                            )
                          }}
                        </td>
                      </tr>

                      <tr
                        v-for="(charge, index) in option.customCharges"
                        :key="index + 100"
                        class="text-sm pd-sm"
                      >
                        <td class="left" style="width: 38%"></td>
                        <td class="right" style="width: 30%">
                          {{ charge.name }}
                        </td>
                        <td class="right" style="width: 15%">
                          {{ formatPrice(charge.price) }}
                        </td>
                        <td class="right" style="width: 17%; font-weight: bold">
                          <span v-if="option.type === 'source'">-</span>
                          {{ formatPrice(charge.price) }}
                        </td>
                      </tr>

                      <tr
                        v-for="(credit, index) in option.customCredits"
                        :key="index + 150"
                        class="text-sm pd-sm"
                      >
                        <td class="left" style="width: 40%"></td>
                        <td class="right" style="width: 25%">
                          {{ credit.name }}
                        </td>
                        <td class="right" style="width: 15%">
                          {{ formatPrice(credit.price) }}
                        </td>
                        <td class="right" style="width: 20%; font-weight: bold">
                          <span v-if="option.type === 'destination'">+</span>
                          {{ formatPrice(credit.price) }}
                        </td>
                      </tr>

                      <tr class="text-sm pd-sm">
                        <td
                          class="left"
                          style="width: 40%; border-bottom: none"
                        ></td>
                        <td
                          colspan="2"
                          class="right"
                          style="
                            width: 30%;
                            font-weight: bold;
                            border-bottom: none;
                          "
                        >
                          <span
                            v-if="option.totalRaw >= 0"
                            class="bg-grey-3 text-primary q-px-sm rounded-borders"
                          >
                            Balance Due
                          </span>
                          <span
                            v-if="option.totalRaw < 0"
                            class="bg-grey-3 text-custom-red q-px-sm rounded-borders"
                          >
                            Credit
                          </span>
                        </td>
                        <td
                          class="right"
                          style="
                            width: 15%;
                            font-weight: bold;
                            border-bottom: none;
                          "
                        >
                          {{ option.totalPrice }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div v-if="sReport.sReportType === 'condensed'">
                  <div
                    v-for="option in sReport.selectedOptions"
                    :key="option.locationName"
                    class="no-break"
                    style="
                      border: 1px solid #c0c0c0;
                      border-radius: 5px;
                      margin-top: 35px;
                      padding: 20px 10px 20px 10px;
                    "
                  >
                    <div
                      style="
                        font-size: 17px;
                        font-weight: bold;
                        text-align: center;
                        padding-bottom: 3px;
                        margin-bottom: 20px;
                        border-bottom: 1px solid #c0c0c0;
                      "
                    >
                      {{ locationId(option.locationId, option.type) }}
                    </div>
                    <table
                      style="width: 100%; border-bottom: none"
                      class="top no-break"
                    >
                      <tr class="top">
                        <th colspan="1" class="left top" style="width: 38%">
                          Description
                        </th>
                        <th colspan="1" class="right top" style="width: 30%">
                          <span>Qty</span>
                        </th>
                        <th colspan="1" class="right top" style="width: 15%">
                          <span>Rate</span>
                        </th>
                        <th class="right top" style="width: 17%">Total</th>
                      </tr>

                      <tr v-if="option.serviceStr !== ''" class="text-sm pd-sm">
                        <td class="left" style="width: 38%">
                          {{ option.serviceStr }}
                        </td>
                        <td class="right" style="width: 30%">
                          {{ hauled(option) }} {{ option.MU }}
                        </td>
                        <td class="right" style="width: 15%">
                          {{ formatPrice(option.condensedPrice) }}
                        </td>
                        <td class="right" style="width: 17%; font-weight: bold">
                          {{ formatPrice(option.condensedTotal) }}
                        </td>
                      </tr>

                      <tr
                        v-for="(service, index) in option.servicesPaired"
                        :key="index"
                        class="text-sm pd-sm"
                      >
                        <td class="left" style="width: 38%">
                          {{ service.serviceName }}
                        </td>
                        <td class="right" style="width: 30%">
                          {{ service.serviceVal }}
                          {{ service.infoBoxMU }}
                        </td>
                        <td class="right" style="width: 15%">
                          {{ formatPrice(service.servicePrice) }}
                        </td>
                        <td class="right" style="width: 17%; font-weight: bold">
                          <span v-if="option.type === 'source'">-</span>
                          {{ formatPrice(service.serviceTotal) }}
                        </td>
                      </tr>

                      <tr
                        v-for="(service, index) in option.customServices"
                        :key="index + 50"
                        class="text-sm pd-sm"
                      >
                        <td class="left" style="width: 38%">
                          {{ service.name }}
                        </td>
                        <td class="right" style="width: 30%">Price Per Ton</td>
                        <td class="right" style="width: 15%">
                          {{ formatPrice(service.price) }}
                        </td>
                        <td class="right" style="width: 17%; font-weight: bold">
                          <span v-if="option.type === 'source'">-</span>
                          {{ formatPrice(service.price * hauled(option)) }}
                        </td>
                      </tr>

                      <tr
                        v-if="option.showMileage && option.showMiles"
                        class="text-sm pd-sm"
                      >
                        <td class="left" style="width: 38%">Mileage</td>
                        <td class="right" style="width: 30%">
                          {{ option.totalMileage }} Miles
                        </td>
                        <td class="right" style="width: 15%">
                          {{ formatPrice(option.mileageRate) }}
                        </td>
                        <td class="right" style="width: 17%; font-weight: bold">
                          <span v-if="option.type === 'source'">-</span>
                          {{
                            formatPrice(
                              option.mileageRate * option.totalMileage
                            )
                          }}
                        </td>
                      </tr>

                      <tr
                        v-for="(charge, index) in option.customCharges"
                        :key="index + 100"
                        class="text-sm pd-sm"
                      >
                        <td class="left" style="width: 38%"></td>
                        <td class="right" style="width: 30%">
                          {{ charge.name }}
                        </td>
                        <td class="right" style="width: 15%">
                          {{ formatPrice(charge.price) }}
                        </td>
                        <td
                          class="right text-no-wrap"
                          style="width: 17%; font-weight: bold"
                        >
                          <span v-if="option.type === 'source'">-</span>
                          {{ formatPrice(charge.price) }}
                        </td>
                      </tr>

                      <tr
                        v-for="(credit, index) in option.customCredits"
                        :key="index + 150"
                        class="text-sm pd-sm"
                      >
                        <td class="left" style="width: 40%"></td>
                        <td class="right" style="width: 25%">
                          {{ credit.name }}
                        </td>
                        <td class="right" style="width: 15%">
                          {{ formatPrice(credit.price) }}
                        </td>
                        <td
                          class="right text-no-wrap"
                          style="width: 20%; font-weight: bold"
                        >
                          <span v-if="option.type === 'destination'">+</span>
                          {{ formatPrice(credit.price) }}
                        </td>
                      </tr>

                      <tr class="text-sm pd-sm">
                        <td
                          class="left"
                          style="
                            width: 40%;
                            border-bottom: none;
                            padding-bottom: 15px;
                          "
                        ></td>
                        <td
                          colspan="2"
                          class="right"
                          style="
                            width: 30%;
                            font-weight: bold;
                            border-bottom: none;
                            padding-bottom: 15px;
                          "
                        >
                          <span
                            v-if="option.totalRaw >= 0"
                            class="bg-grey-3 text-primary q-px-sm rounded-borders"
                          >
                            Balance Due
                          </span>
                          <span
                            v-if="option.totalRaw < 0"
                            class="bg-grey-3 text-custom-red q-px-sm rounded-borders"
                          >
                            Credit
                          </span>
                        </td>
                        <td
                          class="right"
                          style="
                            width: 15%;
                            font-weight: bold;
                            border-bottom: none;
                            padding-bottom: 15px;
                          "
                        >
                          {{ option.totalPrice }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div>
                  <table
                    style="width: 100%; margin-top: 50px; margin-bottom: 10px"
                    class="top"
                  >
                    <tr class="top text-md">
                      <th colspan="1" class="left top" style="width: 50%">
                        <span
                          v-if="sReport.grandTotalRaw >= 0"
                          class="bg-grey-3 text-primary q-px-sm rounded-borders"
                        >
                          Total Balance Due:
                        </span>
                        <span
                          v-if="sReport.grandTotalRaw < 0"
                          class="bg-grey-3 text-custom-red q-px-sm rounded-borders"
                        >
                          Total Credit:
                        </span>
                      </th>
                      <th colspan="1" class="right top" style="width: 35%"></th>
                      <th colspan="1" class="right top" style="width: 15%">
                        {{ sReport.grandTotal }}
                      </th>
                    </tr>
                  </table>
                </div>
                <div
                  class="q-mb-lg"
                  style="
                    padding-left: 10px;
                    font-size: 14px;
                    white-space: pre-line;
                  "
                >
                  {{ sReport.notes.sReportNote }}
                </div>

                <div v-if="customerOrAll === 'customer'">
                  <div class="row q-mb-sm">
                    <q-space />
                    <q-btn
                      color="primary"
                      label="print"
                      class="q-mr-sm"
                      padding="xs md"
                      @click="printSReport(sReport)"
                    />
                    <div v-if="edit && !sReport.canEdit">
                      <q-btn
                        color="grey-6"
                        label="Delete"
                        class="q-mr-sm"
                        padding="xs md"
                      />
                      <q-btn color="grey-6" label="edit" padding="xs md" />
                    </div>
                    <div v-if="edit && sReport.canEdit">
                      <q-space />
                      <q-btn
                        color="custom-red"
                        label="Delete"
                        class="q-mr-sm"
                        padding="xs md"
                        @click="deleteSReport(sReport)"
                      />
                      <q-btn
                        color="custom-orange"
                        label="edit"
                        padding="xs md"
                        @click="editSReport(sReport)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </q-card>
        </div>
      </q-scroll-area>
    </q-layout>
  </q-dialog>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import firebase from 'firebase/compat/app';
import Print from '@/components/settlement/Print.vue';
import { formatPrice } from '@/utils/UtilHelpers';
import { Printd } from 'printd';
import { date } from 'quasar';

const db = firebase.firestore();

export default {
  name: 'WorkTypeSettings',
  components: {
    ConfirmDialog,
    Print
  },
  props: {
    customer: {
      type: String,
      required: true
    },
    edit: {
      type: Boolean,
      required: true
    },
    recent: {
      type: Object,
      required: true
    },
    showAll: {
      type: Boolean,
      required: true
    },
    SRLocation: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      barStyle: {
        right: '0px',
        borderRadius: '3px',
        backgroundColor: '#bdbdbd',
        width: '7px',
        opacity: 0.2
      },
      confirm: {
        btn: {
          label: 'Delete',
          icon: 'delete',
          color: 'custom-red'
        },
        display: false,
        message: 'Are you sure you want to delete settlement report?'
      },
      customerOrAll: 'customer',
      displaySReports: true,
      formatPrice,
      hideAll: false,
      print: false,
      scrollHeight: 'height: 600px',
      sReport: null,
      sReportsFormatted: [],
      thumbStyle: {
        right: '0px',
        borderRadius: '3px',
        backgroundColor: '#9e9e9e',
        width: '7px',
        opacity: 0.75
      }
    };
  },
  created() {
    this.setCustOrAll();
  },
  mounted() {
    this.setScrollHeight();
  },
  methods: {
    cancel() {
      this.$emit('onCancel');
    },
    checkIfCommodity(option) {
      if (option.type === 'source') {
        return option.purchaseCommodity && option.showPricePerUnit
          ? true
          : false;
      } else {
        return option.chargeCommodity && option.showPricePerUnit ? true : false;
      }
    },
    confirmed() {
      this.$emit('onDeleteStart');
      this.confirm.display = false;

      const sReport = this.sReport;
      this.hideAll = true;

      const sReportDoc = db
        .collection('settlement_reports')
        .doc(sReport.sReportId);

      const batch = db.batch();

      for (let i = 0; i < sReport.selectedOptions.length; i++) {
        const option = sReport.selectedOptions[i];

        const sReportsObj = {
          settlementReports: firebase.firestore.FieldValue.arrayRemove(
            sReport.sReportId
          )
        };

        if (option.type === 'destination') {
          const destDoc = db
            .collection('destinations_active')
            .doc(option.destOrSourceId);

          batch.update(destDoc, sReportsObj);
        } else {
          const sourceDoc = db
            .collection('sources_active')
            .doc(option.destOrSourceId);

          batch.update(sourceDoc, sReportsObj);
        }
      }

      batch.delete(sReportDoc);
      batch
        .commit()
        .then(() => {
          this.$emit('onDeleteSReport');
        })
        .catch(err => {
          console.log(err);
        });
    },
    deleteSReport(sReport) {
      this.sReport = sReport;
      this.confirm.display = true;
    },
    editSReport(sReport) {
      this.$emit('onEditSReport', sReport, 2);
    },
    hauled(option) {
      if (option.filterByDate) {
        return option.hauledFiltered;
      }

      return option.hauled;
    },
    locationId(id, type) {
      if (type === 'destination') {
        return this.masterDestinations[id].destinationName;
      } else {
        return this.masterSources[id].sourceName;
      }
    },
    pairedWithInfoBox(service) {
      if (Object.hasOwnProperty.call(service, 'useInfoBox')) {
        if (service.useInfoBox) {
          return true;
        }
      }

      return false;
    },
    printSReport(sReport) {
      this.sReport = sReport;
      this.print = true;

      this.$nextTick(() => {
        const css = `
          .text-sm {
            font-size: 13px;
          }
          .text-md {
            font-size: 15px;
          }
          .right {
            text-align: right;
            padding-right: 10px;
          }
          .left {
            text-align: left;
            padding-left: 10px;
          }
          .top {
            border-top: none;
          }
          .wider {
            width: 200px;
          }
          th, td {
            padding-top: 3px;
            padding-bottom: 3px;
          }
          table,
          th,
          td {
            border-bottom: 1px solid #c0c0c0;
            border-top: 1px solid #c0c0c0;
            border-collapse: collapse;
          }
          @media print {
            div.no-break {
                page-break-inside: avoid;
            }
          }
          @page {
            margin-top: 65px;
            margin-left: 100px;
            margin-right: 100px;
            margin-bottom: 60px;
          }
        `;

        const printMe = document.getElementById('print');

        const d = new Printd();
        d.print(printMe, [css]);

        this.print = false;
      });
    },
    servicesHauled(option) {
      if (option.filterServicesByDate) {
        return option.hauledFiltered;
      }

      return option.hauled;
    },
    setCustOrAll() {
      this.sReportsFormatted = [];
      if (this.customerOrAll === 'customer') {
        this.sReportsFormatted = JSON.parse(
          JSON.stringify(this.recent.customerSReports)
        );
      } else {
        this.sReportsFormatted = JSON.parse(
          JSON.stringify(this.recent.allSReports)
        );
      }

      for (let i = 0; i < this.sReportsFormatted.length; i++) {
        const sReport = this.sReportsFormatted[i];

        const filterByDate = sReport.selectedOptions[0].filterByDate;
        this.sReportsFormatted[i].filterByDate = filterByDate;

        if (filterByDate) {
          const haulDates = sReport.selectedOptions[0].haulDates;
          const to = date.subtractFromDate(new Date(haulDates.toTS), {
            days: 1
          });

          this.sReportsFormatted[i].haulDates = {
            from: new Date(haulDates.fromTS).toDateString(),
            to: to.toDateString(),
            fromTS: haulDates.fromTS,
            toTS: haulDates.toTS
          };
        } else {
          this.sReportsFormatted[i].haulDates = {
            to: '',
            from: '',
            toTS: '',
            fromTS: ''
          };
        }

        for (let j = 0; j < sReport.selectedOptions.length; j++) {
          const option = sReport.selectedOptions[j];

          let condensedPrice = 0;
          let serviceStr = '';
          let condensedTotal = 0;
          const hauledKey = option.filterByDate ? 'hauledFiltered' : 'hauled';

          if (option.type === 'destination') {
            if (option.chargeCommodity && option.showPricePerUnit) {
              condensedPrice = option.pricePerUnit;
              condensedTotal = option[hauledKey] * option.pricePerUnit;
              serviceStr += option.commodity + ' - ';
            }
          } else if (option.type === 'source') {
            if (option.purchaseCommodity && option.showPricePerUnit) {
              condensedPrice = option.pricePerUnit;
              condensedTotal = option[hauledKey] * option.pricePerUnit;
              serviceStr += option.commodity + ' - ';
            }
          }

          const servicesHauledKey = option.filterServicesByDate
            ? 'hauledFiltered'
            : 'hauled';

          const servicesNotPaired = [];
          const servicesPaired = [];
          const services = option.services.slice();
          if (option.services.length) {
            const haulIndex = services.findIndex(x => x.name === 'Haul');

            // Magic splice to put 'Haul' before other services
            if (haulIndex) {
              services.splice(0, 0, services.splice(haulIndex, 1)[0]);
            }

            for (let k = 0; k < services.length; k++) {
              const service = services[k];

              let useInfoBox = false;
              if (Object.hasOwnProperty.call(service, 'useInfoBox')) {
                if (service.useInfoBox) {
                  useInfoBox = true;

                  const infoBoxPairedWith = service.infoBoxPairedWith;
                  const infoBoxVal = option.infoBoxes.find(infoBox => {
                    if (infoBox.name === infoBoxPairedWith) {
                      return true;
                    }
                  });

                  let infoBoxMU = '';
                  if (useInfoBox) {
                    for (
                      let i = 0;
                      i < this.settings.destinationSettings.services.length;
                      i++
                    ) {
                      const destService =
                        this.settings.destinationSettings.services[i];
                      if (service.name === destService.name) {
                        infoBoxMU = destService.infoBoxMU;
                      }
                    }

                    for (
                      let i = 0;
                      i < this.settings.sourceSettings.services.length;
                      i++
                    ) {
                      const sourceService =
                        this.settings.sourceSettings.services[i];
                      if (service.name === sourceService.name) {
                        infoBoxMU = sourceService.infoBoxMU;
                      }
                    }
                  }

                  const serviceVal = infoBoxVal ? infoBoxVal.model : 0;
                  const serviceTotal = service.price * serviceVal;
                  const serviceName = service.name;
                  const servicePrice = service.price;
                  servicesPaired.push({
                    serviceName,
                    serviceVal,
                    servicePrice,
                    serviceTotal,
                    infoBoxPairedWith,
                    infoBoxMU
                  });
                }
              }

              if (!useInfoBox) {
                servicesNotPaired.push(service);
                serviceStr += service.name + ' - ';
                if (option.type === 'destination') {
                  condensedTotal += service.price * option[servicesHauledKey];
                  condensedPrice += service.price;
                } else if (option.type === 'source') {
                  condensedTotal -= service.price * option[servicesHauledKey];
                  condensedPrice -= service.price;
                }
              }
            }
          }

          this.sReportsFormatted[i].selectedOptions[j].servicesNotPaired =
            servicesNotPaired;
          this.sReportsFormatted[i].selectedOptions[j].servicesPaired =
            servicesPaired;

          // Remove last ' - '
          serviceStr = serviceStr.slice(0, -3);

          this.sReportsFormatted[i].selectedOptions[j].serviceStr = serviceStr;
          this.sReportsFormatted[i].selectedOptions[j].condensedPrice =
            condensedPrice;
          this.sReportsFormatted[i].selectedOptions[j].condensedTotal =
            condensedTotal;
        }
      }
    },
    setScrollHeight() {
      this.$nextTick(() => {
        let containerHeight =
          document.getElementById('list-sreports').offsetHeight;
        this.scrollHeight = `height: ${containerHeight - 134}px`;
      });
    }
  },
  computed: {
    masterDestinations() {
      return this.$store.state.masterDestinations;
    },
    masterSources() {
      return this.$store.state.masterSources;
    },
    settings() {
      return {
        destinationSettings: this.$store.state.destinationSettings,
        sourceSettings: this.$store.state.sourceSettings
      };
    }
  }
};
</script>

<style scoped>
.text-sm {
  font-size: 14px;
}
.text-md {
  font-size: 16px;
}
.right {
  text-align: right;
  padding-right: 10px;
}
.left {
  text-align: left;
  padding-left: 10px;
}
.top {
  border-top: none;
}
.wider {
  width: 200px;
}

th,
td {
  padding-top: 3px;
  padding-bottom: 3px;
}
table,
th,
td {
  border-bottom: 1px solid silver;
  border-top: 1px solid silver;
  border-collapse: collapse;
}

.outer {
  max-width: 550px;
}

@media all and (max-width: 440px) {
  .outer {
    min-width: 310px;
  }
}
</style>
