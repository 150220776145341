import { render, staticRenderFns } from "./LoadingGif.vue?vue&type=template&id=7c6c4b00&scoped=true&"
import script from "./LoadingGif.vue?vue&type=script&lang=js&"
export * from "./LoadingGif.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c6c4b00",
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QSpinnerGears});
