<template>
  <div>
    <div>
      <div class="row">
        <div class="half-width half-width-mr">
          <q-card flat bordered>
            <div class="row">
              <div class="row full-width">
                <div
                  class="row justify-center items-center bg-custom-light rounded-borders text-h5 q-ml-md q-mt-md"
                  style="width: 44px; height: 44px; position: relative"
                >
                  <span style="z-index: 2">
                    {{ destination.customer.charAt(0) }}
                  </span>
                  <div
                    class="row items-center justify-center bg-custom-light-blue"
                    :style="`position: absolute; bottom: 0; width: 44px;
                  border-bottom-left-radius: 4px;
                  border-bottom-right-radius: 4px;
                  border-top-right-radius: ${
                    destination.percentHauled === '100%' ? 4 : 0
                  }px;
                  border-top-left-radius: ${
                    destination.percentHauled === '100%' ? 4 : 0
                  }px;
                  border-bottom-top-radius: 4px;
                  height: ${destination.percentHauled}`"
                  ></div>
                </div>

                <div
                  class="row q-mt-md q-ml-md"
                  style="width: calc(100% - 76px); margin-top: 22px"
                >
                  <div class="q-ml-sm">Hauled</div>
                  <q-space />
                  <div class="info-font q-pr-md q-mr-xs">
                    {{ round(destination.hauled).toLocaleString('en-US') }}
                    {{ destination.measurementUnit }}
                  </div>
                  <q-separator color="grey-3" class="full-width q-ml-md" />
                </div>
              </div>
            </div>

            <div>
              <div class="row items-center q-ml-md q-mr-md q-py-xs">
                <span class="q-my-xs">Balance</span>
                <q-space />
                <span class="info-font text q-pr-xs">
                  {{ round(destination.balance).toLocaleString('en-US') }}
                  {{ destination.measurementUnit }}
                </span>
              </div>
              <q-separator color="grey-3" />
            </div>

            <div>
              <div class="row items-center q-ml-md q-mr-md q-pt-sm q-pb-sm">
                <span class="q-my-xs">Projected</span>
                <q-space />
                <span
                  v-if="destination.projected !== ''"
                  class="info-font q-pr-xs"
                >
                  {{ round(destination.projected).toLocaleString('en-US') }}
                  {{ destination.measurementUnit }}
                </span>
                <span
                  v-if="destination.projected === ''"
                  class="info-font text-custom-red q-pr-xs"
                >
                  Not Set
                </span>
              </div>
            </div>
          </q-card>

          <q-card flat bordered class="q-mt-md">
            <div>
              <div class="row items-center q-ml-md q-mr-md q-py-xs">
                <span class="q-my-xs">Start Date</span>
                <q-space />
                <span class="q-pr-xs" style="font-size: 12px">
                  {{ destination.startDateFormatted }}
                </span>
              </div>
              <q-separator color="grey-3" />
            </div>

            <div>
              <div class="row items-center q-ml-md q-mr-md q-py-xs">
                <span class="q-my-xs">Commodity</span>
                <q-space />
                <span class="info-font q-pr-xs">
                  {{ destination.commodity }}
                </span>
              </div>
              <q-separator color="grey-3" />
            </div>

            <div v-if="modules.dispatch">
              <div class="row items-center q-ml-md q-mr-md q-py-xs">
                <span class="q-my-xs">Dispatched To</span>
                <q-space />
                <div style="max-width: calc(100% - 120px)">
                  <div class="info-font text-custom-red q-mr-xs">
                    {{ destination.dispatchedToStr || '- - -' }}
                  </div>
                </div>
              </div>
              <q-separator color="grey-3" />
            </div>

            <div>
              <div class="row items-center q-ml-md q-mr-md q-py-xs">
                <span class="q-my-xs"
                  >Price Per
                  {{ destination.measurementUnit.slice(0, -1) }}</span
                >
                <q-space />
                <span class="info-font q-pr-xs">
                  ${{ destination.pricePerUnit.toFixed(2) }}
                </span>
              </div>
              <q-separator v-if="modules.driverRate" color="grey-3" />
            </div>

            <div v-if="modules.driverRate">
              <div class="row items-center q-ml-md q-mr-md q-py-xs">
                <span class="q-my-xs">Driver Rate</span>
                <q-space />
                <span class="info-font q-pr-xs">
                  ${{ destination.driverRate.toFixed(2) }}
                </span>
              </div>
            </div>
          </q-card>

          <q-card flat bordered class="q-mt-md q-py-xs">
            <div class="row justify-center">
              <div>
                <div class="row items-center col-12 q-py-xs">
                  <q-avatar
                    rounded
                    v-if="destination.readyForSettlement"
                    icon="task_alt"
                    color="custom-green"
                    size="sm"
                    text-color="white"
                  />
                  <q-avatar
                    rounded
                    v-if="!destination.readyForSettlement"
                    icon="task_alt"
                    color="custom-light"
                    size="sm"
                    font-size="14px"
                    text-color="white"
                  />
                  <div class="q-ml-md">Ready For Settlement</div>
                </div>

                <div class="row items-center col-12 q-py-xs">
                  <q-avatar
                    rounded
                    v-if="destination.location.lat"
                    icon="place"
                    color="custom-light-blue"
                    size="sm"
                    text-color="white"
                  />
                  <q-avatar
                    rounded
                    v-if="!destination.location.lat"
                    icon="place"
                    color="custom-light"
                    size="sm"
                    font-size="14px"
                    text-color="white"
                  />
                  <div class="q-ml-md">Map Pin</div>
                </div>

                <div class="row items-center col-12 q-py-xs">
                  <q-avatar
                    rounded
                    v-if="destination.location.attachmentId"
                    icon="image"
                    color="custom-orange"
                    size="sm"
                    text-color="white"
                  />
                  <q-avatar
                    rounded
                    v-if="!destination.location.attachmentId"
                    icon="image"
                    color="custom-light"
                    size="sm"
                    font-size="14px"
                    text-color="white"
                  />
                  <div class="q-ml-md">Attachments</div>
                </div>

                <div class="row items-center col-12 q-py-xs">
                  <q-avatar
                    rounded
                    v-if="destination.chargeCommodity"
                    icon="attach_money"
                    color="custom-red"
                    size="sm"
                    text-color="white"
                  />
                  <q-avatar
                    rounded
                    v-if="!destination.chargeCommodity"
                    icon="attach_money"
                    color="custom-light"
                    size="sm"
                    font-size="14px"
                    text-color="white"
                  />
                  <div class="q-ml-md">Charge Commodity</div>
                </div>

                <div class="row items-center col-12 q-py-xs">
                  <q-avatar
                    rounded
                    v-if="destination.destinationStatus === 'Open'"
                    icon="double_arrow"
                    color="custom-green"
                    size="sm"
                    font-size="14px"
                    text-color="white"
                  />
                  <q-avatar
                    rounded
                    v-if="destination.destinationStatus === 'Waiting'"
                    icon="more_time"
                    color="custom-red"
                    size="sm"
                    font-size="14px"
                    text-color="white"
                  />
                  <q-avatar
                    rounded
                    v-if="destination.destinationStatus === 'Estimate'"
                    icon="feed"
                    color="custom-light-blue"
                    size="sm"
                    font-size="14px"
                    text-color="white"
                  />
                  <div class="q-ml-md">Status</div>
                </div>

                <div
                  v-if="modules.dispatch"
                  class="row items-center col-12 q-py-xs"
                >
                  <q-avatar
                    rounded
                    v-if="destination.isDispatched"
                    icon="person"
                    color="custom-red"
                    size="sm"
                    text-color="white"
                  />
                  <q-avatar
                    rounded
                    v-if="!destination.isDispatched"
                    icon="person"
                    color="custom-light"
                    size="sm"
                    font-size="14px"
                    text-color="white"
                  />
                  <div class="q-ml-md">Dispatched</div>
                </div>
              </div>
            </div>
          </q-card>

          <q-card v-if="modules.guestUsers" flat bordered class="q-mt-md">
            <div class="row justify-center q-mt-xs">
              <div>
                <div class="row items-center col-12 q-py-xs">
                  <q-avatar
                    rounded
                    v-if="destination.allowGuestUsers"
                    icon="person"
                    color="custom-light-blue"
                    size="sm"
                    text-color="white"
                  />
                  <q-avatar
                    rounded
                    v-if="!destination.allowGuestUsers"
                    icon="person"
                    color="custom-light"
                    size="sm"
                    font-size="14px"
                    text-color="white"
                  />
                  <div class="q-ml-md">Guest Users Allowed</div>
                </div>
              </div>
            </div>
            <q-separator color="grey-3" />

            <div>
              <div class="row items-center q-ml-md q-mr-md q-py-xs">
                <span class="q-my-xs">Guest Users</span>
                <q-space />
                <div style="max-width: calc(100% - 120px)">
                  <div class="info-font text-custom-red q-mr-xs">
                    {{ destination.guestUserStr || '- - -' }}
                  </div>
                </div>
              </div>
            </div>
          </q-card>

          <q-card flat bordered class="q-my-md">
            <div class="row items-center q-ml-md q-mr-md q-pr-xs q-py-xs">
              <span class="q-my-xs">Settlement Reports</span>
              <q-space />
              <q-avatar
                rounded
                v-if="destination.settlementReports.length"
                icon="calculate"
                color="custom-light-blue"
                size="sm"
                text-color="white"
                class="q-mr-sm"
              />
              <q-avatar
                rounded
                v-if="!destination.settlementReports.length"
                icon="calculate"
                color="custom-light"
                size="sm"
                font-size="14px"
                text-color="white"
                class="q-mr-sm"
              />
              ( {{ destination.settlementReports.length }} )
            </div>
          </q-card>
        </div>

        <div class="half-width">
          <q-card flat bordered>
            <div class="row items-center q-mx-md q-py-xs">
              <q-icon
                v-if="destination.importantNote"
                name="announcement"
                size="28px"
                color="custom-red"
                class="q-mr-sm"
              />
              <div>
                <span class="text-bold text-primary q-pr-sm">Note:</span>
                {{ destination.note }}
              </div>
            </div>

            <div class="row items-center q-mx-md q-py-xs">
              <div>
                <span class="text-bold text-primary q-pr-sm">Admin Note:</span>
                {{ destination.adminNote }}
              </div>
            </div>

            <div class="row items-center q-mx-md q-py-xs">
              <div>
                <q-icon
                  name="account_circle"
                  color="custom-orange"
                  size="sm"
                  class="q-mr-xs"
                />
                <span class="text-bold text-primary q-pr-sm"
                  >Customer Note:</span
                >
                {{ destination.customerNote }}
              </div>
            </div>
          </q-card>

          <q-card
            v-if="destination.infoBoxes.length"
            flat
            bordered
            class="q-mt-md"
          >
            <div v-for="(infoBox, index) in destination.infoBoxes" :key="index">
              <div class="row items-center q-ml-md q-mr-md q-py-xs">
                <span class="q-my-xs">{{ infoBox.name }}</span>
                <q-space />
                <span class="info-font q-pr-xs">
                  {{ infoBox.model || 'Not Set' }}
                </span>
              </div>
              <q-separator
                v-if="index !== destination.infoBoxes.length - 1"
                color="grey-3"
              />
            </div>
          </q-card>

          <q-card
            v-if="destination.customSelectors.length"
            flat
            bordered
            class="q-mt-md"
          >
            <div
              v-for="(customSelector, index) in destination.customSelectors"
              :key="index + 50"
            >
              <div class="row items-center q-ml-md q-mr-md q-py-xs">
                <span class="q-my-xs">{{ customSelector.name }}</span>
                <q-space />
                <div style="max-width: calc(100% - 165px)">
                  <q-chip color="grey-3" size="sm">
                    <q-avatar
                      :color="customSelector.color"
                      text-color="white"
                      size="sm"
                      class="text-bold"
                    >
                      {{ customSelector.model.charAt(0) }}
                    </q-avatar>
                    <span class="info-font q-pr-xs ellipsis">
                      {{ customSelector.model }}
                    </span>
                  </q-chip>
                </div>
              </div>
              <q-separator
                v-if="index !== destination.customSelectors.length - 1"
                color="grey-3"
              />
            </div>
          </q-card>

          <q-card flat bordered class="q-mt-md">
            <div class="row items-center q-ml-md q-mr-md q-py-xs">
              <span class="q-my-xs">Haul Trackers</span>
              <q-space />
              <div style="max-width: calc(100% - 170px)">
                <div class="info-font q-mr-xs ellipsis">
                  {{ destination.trackerStr || '- - -' }}
                </div>
              </div>
            </div>
          </q-card>

          <q-card
            v-if="!destination.services.length"
            flat
            bordered
            class="q-mt-md"
          >
            <div class="row items-center q-ml-md q-mr-md q-py-xs">
              <span class="q-my-xs">Services</span>
              <q-space />
              <div style="max-width: calc(100% - 170px)">
                <div class="info-font q-mr-xs">- - -</div>
              </div>
            </div>
          </q-card>

          <q-card
            v-if="destination.services.length"
            flat
            bordered
            class="q-mt-md"
          >
            <div
              v-for="(service, index) in destination.services"
              :key="index + 50"
            >
              <div class="row items-center q-ml-md q-mr-md q-pt-xs">
                <span class="q-my-xs">{{ service.name }}</span>
                <q-space />
                <q-avatar
                  v-if="!service.complete"
                  rounded
                  icon="done_outline"
                  color="custom-light"
                  size="sm"
                  font-size="14px"
                  text-color="white"
                  class="q-mr-sm"
                />
                <q-avatar
                  v-if="service.complete"
                  rounded
                  icon="done_outline"
                  color="custom-red"
                  size="sm"
                  font-size="14px"
                  text-color="white"
                  class="q-mr-sm"
                />
                Complete
              </div>
              <div
                class="row items-center text-caption text-custom-medium q-ml-md q-mr-md q-py-xs"
              >
                Price
                <q-space />
                <span class="q-mb-xs">${{ service.price.toFixed(2) }}</span>
              </div>
              <div
                v-if="service.serviceTs"
                class="row items-center text-caption text-custom-medium q-ml-md q-mr-md q-py-xs"
              >
                Date
                <q-space />
                <q-chip
                  class="q-mb-xs q-ma-none"
                  :color="dateTripColor(service).color"
                  :text-color="dateTripColor(service).textColor"
                  icon="today"
                >
                  {{ new Date(service.serviceTs).toDateString() }}
                </q-chip>
              </div>
              <q-separator
                v-if="index !== destination.services.length - 1"
                color="grey-3"
              />
            </div>
          </q-card>

          <q-card v-if="modules.trackMileage" flat bordered class="q-mt-md">
            <div class="row justify-center q-mt-xs">
              <div>
                <div class="row items-center col-12 q-py-xs">
                  <q-avatar
                    rounded
                    v-if="destination.trackMileage"
                    icon="edit_road"
                    color="custom-light-blue"
                    size="sm"
                    text-color="white"
                  />
                  <q-avatar
                    rounded
                    v-if="!destination.trackMileage"
                    icon="edit_road"
                    color="custom-light"
                    size="sm"
                    font-size="14px"
                    text-color="white"
                  />
                  <div class="q-ml-md">Track Mileage</div>
                </div>
              </div>
            </div>
            <q-separator color="grey-3" />

            <div>
              <div class="row items-center q-ml-md q-mr-md q-py-xs">
                <span class="q-my-xs">Mileage Rate</span>
                <q-space />
                <span class="info-font q-pr-xs">
                  ${{ destination.mileageRate.toFixed(2) }}
                </span>
              </div>
            </div>
          </q-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { round2Dec, dateTripColor } from '@/utils/UtilHelpers.js';

export default {
  name: 'destinationReport',
  props: {
    destination: {
      type: Object,
      required: true
    }
  },
  methods: {
    dateTripColor(service) {
      if (service.complete) {
        return { color: 'custom-light', textColor: 'black' };
      }
      return dateTripColor(this.serviceTripDates, service.serviceTs);
    },
    round(num) {
      return round2Dec(num);
    }
  },
  computed: {
    modules() {
      return JSON.parse(
        JSON.stringify(
          this.$store.state.settingsUsers.destinationSettings.modules
        )
      );
    },
    serviceTripDates() {
      const tripDates = JSON.parse(
        JSON.stringify(
          this.$store.state.settingsUsers.destinationSettings.serviceTripDates
        )
      );

      const now = +new Date();
      const tripA = tripDates.tripDateA * 86400000;
      const tripB = tripDates.tripDateB * 86400000;

      return { now, tripA, tripB };
    }
  }
};
</script>

<style scoped></style>
