<template>
  <div>
    <div class="flex flex-center">
      <LoadingGif v-if="displayLoadingGif" />

      <ListHauls v-if="currentDisplay === 'list'" @onEditHaul="onEditHaul" />

      <EnterHaul
        v-if="currentDisplay === 'enterHaul'"
        :edit="edit"
        @onShowList="onShowList"
      />
    </div>
  </div>
</template>

<script>
// Breaking convention - data is handled in components

import EnterHaul from '@/components/hauls/EnterHaul.vue';
import ListHauls from '@/components/hauls/ListHauls.vue';
import LoadingGif from '@/components/LoadingGif.vue';

export default {
  name: 'Hauls',
  components: {
    EnterHaul,
    ListHauls,
    LoadingGif
  },
  data() {
    return {
      btn: {
        label: 'Open Field',
        icon: 'check',
        color: 'primary'
      },
      currentDisplay: 'enterHaul',
      edit: { edit: false, haul: {} },
      headerMessage: '',
      locationIndex: null,
      confirmMessage: '',
      okMessage: ''
    };
  },
  created() {
    this.$store.dispatch('setHeaderBtnsDisplay', true);
    this.setAction('enterHaul');
  },
  methods: {
    onCancelEdit() {
      this.edit = { edit: false, haul: {} };
      this.currentDisplay = 'list';
    },
    onEditHaul(edit) {
      this.edit = edit;
      this.setAction('editHaul');
    },
    onShowList() {
      this.edit = { edit: false, haul: {} };
      this.setAction('list');
    },
    setAction(action) {
      if (action === 'list') {
        this.currentDisplay = 'list';

        const headerBtns = [
          {
            action: this.setAction,
            args: 'enterHaul',
            label: 'Enter Haul',
            color: 'primary',
            flat: false
          }
        ];

        this.$store.dispatch('setHeaderBtns', headerBtns);
      } else if (action === 'enterHaul') {
        this.currentDisplay = 'enterHaul';

        const headerBtns = [
          {
            action: this.onShowList,
            args: '',
            label: 'List',
            color: 'primary',
            flat: false
          }
        ];

        this.$store.dispatch('setHeaderBtns', headerBtns);
      } else if (action === 'editHaul') {
        this.currentDisplay = 'enterHaul';

        const headerBtns = [
          {
            action: this.onShowList,
            args: '',
            label: 'Cancel',
            color: 'primary',
            flat: false
          }
        ];

        this.$store.dispatch('setHeaderBtns', headerBtns);
      }
    }
  },
  computed: {
    customers() {
      return JSON.parse(JSON.stringify(this.$store.state.customers));
    },
    displayLoadingGif() {
      if (this.currentDisplay === 'loadingGif') {
        return true;
      } else {
        return false;
      }
    },
    masterDestinations() {
      return JSON.parse(JSON.stringify(this.$store.state.masterDestinations));
    }
  }
};
</script>

<style scoped></style>
