<template>
  <div>
    <CreateSReport
      v-if="displayCreate"
      :type="type"
      :selected="selected"
      @onCancel="setAction('activeList')"
    />

    <Archived
      v-if="currentDisplay === 'archived'"
      @onSetActionArchived="setAction('archived')"
    />

    <ActiveList
      v-if="currentDisplay === 'activeList'"
      @onCreateSReport="onCreateSReport"
    />
  </div>
</template>

<script>
import Archived from '@/components/settlement/Archived.vue';
import ActiveList from '@/components/settlement/ActiveList.vue';
import CreateSReport from '@/components/settlement/CreateSReport.vue';

export default {
  name: 'SettlementMain',
  components: {
    Archived,
    CreateSReport,
    ActiveList
  },
  data() {
    return {
      currentDisplay: 'dest',
      selected: {},
      type: ''
    };
  },
  created() {
    this.$store.dispatch('setHeaderBtnsDisplay', true);
    this.setAction('activeList');
  },
  methods: {
    onCreateSReport(selected, type) {
      this.selected = selected;
      this.type = type;
      this.currentDisplay = 'create';
    },
    setAction(action) {
      if (action === 'activeList') {
        this.currentDisplay = 'activeList';

        const headerBtns = [
          {
            action: this.setAction,
            args: 'archived',
            label: 'Archived',
            color: 'primary',
            flat: false
          }
        ];

        this.$store.dispatch('setHeaderBtns', headerBtns);
      } else if (action === 'archived') {
        this.currentDisplay = 'archived';

        const headerBtns = [
          {
            action: this.setAction,
            args: 'activeList',
            label: 'Active',
            color: 'primary',
            flat: false
          }
        ];

        this.$store.dispatch('setHeaderBtns', headerBtns);
      } else if (action === 'loggedIn') {
        this.currentDisplay = 'register';

        const headerBtns = [
          {
            action: this.setAction,
            args: 'login',
            label: 'Login',
            color: 'primary',
            flat: true
          }
        ];

        this.$store.dispatch('setHeaderBtns', headerBtns);
      }
    }
  },
  computed: {
    destAs() {
      return this.$store.state.settingsUsers.destAs;
    },
    displayArchived() {
      if (this.currentDisplay === 'archived') {
        return true;
      } else {
        return false;
      }
    },
    displayCreate() {
      if (this.currentDisplay === 'create') {
        return true;
      } else {
        return false;
      }
    },
    displayDest() {
      if (this.currentDisplay === 'dest') {
        return true;
      } else {
        return false;
      }
    },
    displaySources() {
      if (this.currentDisplay === 'sources') {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style scoped></style>
