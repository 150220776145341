import { render, staticRenderFns } from "./TimeClock.vue?vue&type=template&id=35e28d86&scoped=true&"
import script from "./TimeClock.vue?vue&type=script&lang=js&"
export * from "./TimeClock.vue?vue&type=script&lang=js&"
import style1 from "./TimeClock.vue?vue&type=style&index=1&module=true&lang=css&"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "35e28d86",
  null
  
)

export default component.exports
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSpace,QBtn,QIcon,QLinearProgress,QInput,QSelect,QChip,QCard,QSeparator});
