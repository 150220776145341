import { render, staticRenderFns } from "./CustomSelectors.vue?vue&type=template&id=65be72b5&scoped=true&"
import script from "./CustomSelectors.vue?vue&type=script&lang=js&"
export * from "./CustomSelectors.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65be72b5",
  null
  
)

export default component.exports
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSpace,QBtn,QIcon,QCard,QList,QItem,QItemSection,QAvatar,QForm,QInput,QOptionGroup,QRadio});
