<template>
  <q-page class="flex flex-top">
    <div class="full-width">
      <div class="row justify-center text-h5 text-grey-9 q-py-lg full-width">
        Sandy Ridge Farm Inc
      </div>
      <div class="row justify-center text-grey-8 full-width">
        Phone 662-889-1126
      </div>
    </div>
  </q-page>
</template>

<style></style>

<script>
export default {
  name: 'Home'
};
</script>

<style scoped></style>
