import { render, staticRenderFns } from "./ReportContainer.vue?vue&type=template&id=2382bfa4&scoped=true&"
import script from "./ReportContainer.vue?vue&type=script&lang=js&"
export * from "./ReportContainer.vue?vue&type=script&lang=js&"
import style0 from "./ReportContainer.vue?vue&type=style&index=0&id=2382bfa4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2382bfa4",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QSpace,QIcon,QScrollArea});
