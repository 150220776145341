<template>
  <q-dialog persistent v-model="displayChartSorting">
    <q-card>
      <div class="row bg-grey-3">
        <div class="row items-center">
          <q-icon name="sort" size="md" color="custom-orange" class="q-ml-md" />
          <div class="q-py-md q-ml-sm text-black text-h6">Sort Charts</div>
        </div>
        <q-space />
        <div class="row items-center">
          <q-btn
            square
            unelevated
            color="grey-4"
            text-color="black"
            icon="clear"
            size="md"
            class="q-mr-sm q-ml-md"
            padding="sm"
            @click="cancel"
          />
        </div>
      </div>
      <q-card-section>
        <div
          v-if="!$q.screen.xs"
          class="row justify-center text-bold bg-grey-3 q-mb-md"
        >
          First Column
        </div>
        <div
          v-for="(chart, i) in chartsArr"
          :key="chart.id"
          style="width: 300px"
        >
          <div class="row items-center q-py-sm">
            {{ chart.label }}
            <q-space />
            <q-btn
              v-if="i !== chartsArr.length - 1"
              square
              unelevated
              color="custom-artichoke"
              text-color="black"
              icon="south"
              size="xs"
              class="q-ml-md"
              :class="i !== 0 && 'q-mr-sm'"
              padding="xs"
              @click="moveDown(i)"
            />
            <q-btn
              v-if="i !== 0"
              square
              unelevated
              color="custom-light-blue"
              text-color="black"
              icon="north"
              size="xs"
              padding="xs"
              @click="moveUp(i)"
            />
          </div>
          <q-separator v-if="i !== chartsArr.length - 1" class="full-width" />
          <div
            v-if="i === sepNum && !$q.screen.xs"
            class="row justify-center text-bold bg-grey-3 q-my-md"
          >
            Second Column
          </div>
          <q-separator v-if="i === sepNum && !$q.screen.xs" />
        </div>
      </q-card-section>
      <q-card-section>
        <div align="right">
          <q-btn
            square
            unelevated
            color="custom-red"
            label="Apply"
            icon="check"
            size="md"
            padding="sm md"
            @click="applySorting"
          />
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'SortCharts',
  props: {
    chartOrder: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      chartsArr: [],
      displayChartSorting: true,
      sepNum: 5
    };
  },
  mounted() {
    this.setChartOrder();
    if (!this.isAdmin) {
      this.sepNum = 4;

      if (this.isCustomer) {
        this.sepNum = 2;
      }
    }
  },
  methods: {
    applySorting() {
      const chartOrder = [];
      for (let i = 0; i < this.chartsArr.length; i++) {
        chartOrder.push(this.chartsArr[i].id);
      }

      this.$emit('onSortCharts', false, chartOrder);
    },
    cancel() {
      this.$emit('onCancel');
    },
    moveDown(index) {
      // Using tempArr & slice keeps it reactive
      const tempArr = this.chartsArr.slice();
      tempArr[index] = this.chartsArr[index + 1];
      tempArr[index + 1] = this.chartsArr[index];

      this.chartsArr = tempArr.slice();
    },
    moveUp(index) {
      // Using tempArr & slice keeps it reactive
      const tempArr = this.chartsArr.slice();
      tempArr[index] = this.chartsArr[index - 1];
      tempArr[index - 1] = this.chartsArr[index];

      this.chartsArr = tempArr.slice();
    },
    setChartOrder() {
      let chartsArr = [
        { id: 'haul-data', label: 'Hauls' },
        { id: 'haul-source-data', label: 'Hauls / Sources' },
        { id: 'haul-dest-data', label: 'Hauls / ' + this.destAs },
        { id: 'haul-vals', label: 'Haul Values - Commodity Price' },
        { id: 'sources-list', label: 'Sources - Haul Amounts' },
        { id: 'dest-list', label: this.destAs + ' - Haul Amounts' },
        { id: 'services-chart', label: 'Services' },
        { id: 'service-vals-chart', label: 'Service Values' },
        { id: 'currently-booked', label: 'Currently Booked' },
        { id: 'currently-booked-vals', label: 'Currently Booked Values' },
        { id: 'haul-tracker-chart', label: 'Haul Trackers' },
        { id: 'drivers-chart', label: 'Drivers Charts' }
      ];

      this.chartsArr = chartsArr
        .slice()
        .filter(x => {
          return this.chartOrder.includes(x.id);
        })
        .sort(
          (a, b) =>
            this.chartOrder.indexOf(a.id) - this.chartOrder.indexOf(b.id)
        );
    }
  },
  computed: {
    destAs() {
      return this.$store.state.settingsUsers.destAs;
    },
    isAdmin() {
      return this.$store.state.user.superAdmin || this.$store.state.user.admin;
    },
    isCustomer() {
      return this.$store.state.user.customer;
    }
  }
};
</script>

<style scoped></style>
