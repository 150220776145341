<template>
  <div class="div-container q-pt-xl">
    <div class="gt-sm q-py-lg"></div>
    <q-card flat bordered class="card-padding q-pa-lg shadow-1">
      <q-form @submit="register">
        <q-card-section class="q-gutter-md">
          <q-input
            square
            filled
            clearable
            v-model="form.displayName"
            label="display name *"
            label-color="primary"
            color="primary"
            maxlength="80"
            hide-bottom-space
            lazy-rules
            :rules="[
              val => (val && val.length > 0) || 'Please enter a display name'
            ]"
          />
          <q-input
            square
            filled
            clearable
            v-model="form.registerEmail"
            type="email"
            label="email"
            label-color="primary"
            color="primary"
          />
          <q-card-section class="text-center q-pa-none">
            <p v-if="form.verifyEmailError" class="text-custom-red q-pa-none">
              Please enter valid email
            </p>
          </q-card-section>
          <q-input
            square
            filled
            clearable
            v-model="form.registerPassword"
            type="password"
            label="password"
            label-color="primary"
            color="primary"
          />
          <q-input
            square
            filled
            clearable
            v-model="form.verifyPassword"
            type="password"
            label="verify password"
            label-color="primary"
            color="primary"
          />
        </q-card-section>
        <q-card-section class="q-ml-md q-pa-none">
          <p class="text-grey-7 q-pa-none">
            <span v-if="form.pwMissmatch" class="text-custom-red">
              Passwords do not match
            </span>
            <br v-if="form.pwMissmatch" />
            <span :class="form.pwLengthClass">
              Password must be at least 8 Characters
            </span>
            <br />
            <span :class="form.pwLowercaseClass">
              Password must contain at least one Lowercase letter
            </span>
            <br />
            <span :class="form.pwUppercaseClass">
              Password must contain at least one Uppercase letter
            </span>
            <br />
            <span :class="form.pwNumberClass">
              Password must contain at least one Number
            </span>
          </p>
        </q-card-section>
        <q-card-actions class="q-px-md" align="right">
          <q-btn
            color="primary"
            icon="account_box"
            label="Register"
            class="full-width"
            type="submit"
          />
        </q-card-actions>
      </q-form>
    </q-card>
  </div>
</template>

<script>
export default {
  name: 'RegisterForm',
  data() {
    return {
      form: {
        displayName: '',
        email: '',
        password: '',
        pwLengthClass: 'text-grey-6',
        pwLowercaseClass: 'text-grey-6',
        pwUppercaseClass: 'text-grey-6',
        pwNumberClass: 'text-grey-6',
        pwMissmatch: false,
        registerEmail: '',
        registerPassword: '',
        verifyEmailError: false,
        verifyPassword: ''
      }
    };
  },
  mounted() {
    this.clearRegisterForm();
  },
  methods: {
    clearRegisterForm() {
      this.form.displayName = '';
      this.form.email = '';
      this.form.password = '';
      this.form.registerEmail = '';
      this.form.registerPassword = '';
      this.form.pwLengthClass = 'text-grey-6';
      this.form.pwLowercaseClass = 'text-grey-6';
      this.form.pwUppercaseClass = 'text-grey-6';
      this.form.pwNumberClass = 'text-grey-6';
      this.form.pwMissmatch = false;
      this.form.verifyEmailError = false;
    },
    register() {
      let valid = true;

      if (!this.validateEmail(this.form.registerEmail)) {
        this.form.verifyEmailError = true;
        valid = false;
      }

      if (this.form.registerPassword.length < 8) {
        this.form.pwLengthClass = 'text-custom-red';
        valid = false;
      } else {
        this.form.pwLengthClass = 'text-grey-6';
      }

      if (this.form.registerPassword.search(/[a-z]/) < 0) {
        this.form.pwLowercaseClass = 'text-custom-red';
        valid = false;
      } else {
        this.form.pwLowercaseClass = 'text-grey-6';
      }

      if (this.form.registerPassword.search(/[A-Z]/) < 0) {
        this.form.pwUppercaseClass = 'text-custom-red';
        valid = false;
      } else {
        this.form.pwUppercaseClass = 'text-grey-6';
      }

      if (this.form.registerPassword.search(/[0-9]/) < 0) {
        this.form.pwNumberClass = 'text-custom-red';
        valid = false;
      } else {
        this.form.pwNumberClass = 'text-grey-6';
      }

      if (valid && this.form.registerPassword === this.form.verifyPassword) {
        this.$emit('registerMethod', this.form);
      } else {
        this.form.pwMissmatch = true;
      }
    },
    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
  }
};
</script>

<style scoped>
/* some global css is applied */

@media all and (max-width: 550px) {
  .card-padding {
    padding: 5px;
  }
}
</style>
