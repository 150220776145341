<template>
  <q-dialog v-model="dateRangeDisplay">
    <q-card
      class="q-pa-lg"
      :style="$q.screen.xs ? 'min-width: 300px' : 'min-width: 400px'"
    >
      <div>
        <div
          class="row justify-center items-center bg-grey-3 q-py-sm q-mb-md rounded-borders"
        >
          <q-icon
            name="outbound"
            color="custom-green"
            size="md"
            class="q-mr-sm"
          />
          <span class="text-body1 text-bold text-primary"> Add Source </span>
        </div>
        <div
          v-if="addSourceOptions.length < 1"
          class="row justify-center text-body1 text-bold text-grey-5 q-pa-sm"
          style="text-align: center"
        >
          No Sources Available To Add
        </div>

        <q-card flat bordered>
          <div
            v-for="(option, index) in addSourceOptions"
            :key="option.startTs"
          >
            <div class="row items-center q-px-sm q-py-sm">
              <div>
                <q-btn
                  v-if="!option.isSelected && keepOne()"
                  icon="clear"
                  size="sm"
                  padding="xs sm"
                  color="grey-6"
                  class="q-mr-sm"
                />
                <q-btn
                  v-if="option.isSelected && keepOne()"
                  icon="clear"
                  size="sm"
                  padding="xs sm"
                  color="custom-red"
                  class="q-mr-sm"
                  @click="removeSelected(option)"
                />
                <q-btn
                  v-if="option.isSelected"
                  icon="add"
                  size="sm"
                  padding="xs sm"
                  color="grey-6"
                />
                <q-btn
                  v-if="!option.isSelected"
                  icon="add"
                  size="sm"
                  padding="xs sm"
                  color="primary"
                  @click="addSelected(option, 'source')"
                />
              </div>
              <div class="q-ml-lg">
                {{ masterSources[option.locationId].sourceName }}
              </div>
            </div>
            <q-separator v-if="index !== addSourceOptions.length - 1" />
          </div>
        </q-card>

        <div
          class="row justify-center items-center bg-grey-3 q-py-sm q-mt-lg q-mb-md rounded-borders"
        >
          <q-icon
            name="outbound"
            style="transform: rotate(180deg)"
            color="custom-red"
            size="md"
            class="q-mr-sm"
          />
          <span class="text-body1 text-bold text-primary">
            Add {{ destAs.slice(0, -1) }}
          </span>
        </div>

        <div
          v-if="addDestOptions.length < 1"
          class="row justify-center text-body1 text-bold text-grey-5"
        >
          No {{ destAs }} Available To Add
        </div>

        <q-card flat bordered>
          <div v-for="(option, index) in addDestOptions" :key="option.startTs">
            <div class="row items-center q-px-sm q-py-sm">
              <div>
                <q-btn
                  v-if="!option.isSelected && keepOne()"
                  icon="clear"
                  size="sm"
                  padding="xs sm"
                  color="grey-6"
                  class="q-mr-sm"
                />
                <q-btn
                  v-if="option.isSelected && keepOne()"
                  icon="clear"
                  size="sm"
                  padding="xs sm"
                  color="custom-red"
                  class="q-mr-sm"
                  @click="removeSelected(option)"
                />
                <q-btn
                  v-if="option.isSelected"
                  icon="add"
                  size="sm"
                  padding="xs sm"
                  color="grey-6"
                />
                <q-btn
                  v-if="!option.isSelected"
                  icon="add"
                  size="sm"
                  padding="xs sm"
                  color="primary"
                  @click="addSelected(option, 'destination')"
                />
              </div>
              <div class="q-ml-lg">
                {{ masterDestinations[option.locationId].destinationName }}
              </div>
            </div>
            <q-separator v-if="index !== addDestOptions.length - 1" />
          </div>
        </q-card>
      </div>

      <div class="row q-mt-lg">
        <q-space />
        <q-btn
          icon="clear"
          label="Cancel"
          color="primary"
          padding="xs md"
          @click="cancel"
        />
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'DateRange',
  props: {
    addSourceOptions: {
      type: Array,
      required: true
    },
    addDestOptions: {
      type: Array,
      required: true
    },
    destAs: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dateRange: {},
      dateRangeDisplay: true,
      selectNotice: false
    };
  },
  methods: {
    cancel() {
      this.$emit('onCancel');
    },
    addSelected(option, type) {
      this.$emit('onAddSelected', option, type);
    },
    keepOne() {
      let numIsSelected = 0;
      for (let i = 0; i < this.addDestOptions.length; i++) {
        const option = this.addDestOptions[i];
        if (option.isSelected) {
          numIsSelected++;
        }
      }
      for (let i = 0; i < this.addSourceOptions.length; i++) {
        const option = this.addSourceOptions[i];
        if (option.isSelected) {
          numIsSelected++;
        }
      }
      if (numIsSelected > 1) {
        return true;
      } else {
        return false;
      }
    },
    removeSelected(option) {
      this.$emit('onRemoveSelected', option);
    }
  },
  computed: {
    masterDestinations() {
      return this.$store.state.masterDestinations;
    },
    masterSources() {
      return this.$store.state.masterSources;
    }
  }
};
</script>

<style scoped></style>
