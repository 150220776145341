<template>
  <q-dialog v-model="confirmDisplay" persistent>
    <q-card class="q-pa-lg outer-card">
      <q-card-section
        class="row justify-center items-center bg-grey-3 rounded-borders"
      >
        <q-icon name="warning" size="md" color="custom-red" class="q-mr-md" />
        <div class="text-body1">This will permanently delete haul!</div>
      </q-card-section>

      <div class="row items-center q-px-sm q-py-md">
        <div class="q-ml-sm word-wrap" style="width: 44%">
          <div class="text-primary text-bold">
            {{ customers[haul.sourceCustomerId].name }}
          </div>
          <div class="text-custom-medium text-bold">
            {{ masterSources[haul.sourceLocationId].sourceName }}
          </div>
        </div>
        <q-space />
        <q-icon class="self-center" name="forward" color="primary" size="sm" />
        <q-space />
        <div class="q-mr-sm word-wrap" style="width: 44%">
          <div class="text-primary text-right text-bold">
            {{ customers[haul.destinationCustomerId].name }}
          </div>
          <div class="text-custom-medium text-bold text-right">
            {{ setDestination(haul) }}
          </div>
        </div>
      </div>
      <q-separator />

      <div class="row items-center q-py-md q-px-md">
        <div class="row full-width items-center text-body1 text-bold">
          Amount
          <q-space />
          <div class="q-ml-sm">
            <div class="text-custom-light-blue">
              {{ haul.haulAmount }} {{ haul.measurementUnit }}
            </div>
          </div>
        </div>
      </div>
      <q-separator />

      <div v-for="(tracker, index) in haul.sourceHaulTrackers" :key="index">
        <q-card-section class="row items-center q-pt-md">
          <div class="row items-center form text-bold">
            <div>
              {{ tracker.type }}
            </div>
            <q-space />
            <div class="text-grey-7">
              {{ tracker.option }}
            </div>
          </div>
        </q-card-section>
        <q-separator />
      </div>

      <div
        v-for="(tracker, index) in haul.destinationHaulTrackers"
        :key="index + 50"
      >
        <q-card-section class="row items-center q-pt-md">
          <div class="row items-center form text-bold">
            <div>
              {{ trackerLabel(tracker.type) }}
            </div>
            <q-space />
            <div class="text-custom-medium">
              {{ tracker.option }}
            </div>
          </div>
        </q-card-section>
        <q-separator />
      </div>

      <q-card-actions class="q-mt-lg q-mb-sm" align="right">
        <q-btn
          flat
          label="Cancel"
          icon="clear"
          color="primary"
          @click="cancelClicked"
        />
        <q-btn
          label="Delete"
          icon="delete"
          color="custom-red"
          class="q-px-lg"
          @click="confirmClicked"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'ConfirmDelete',
  props: {
    haul: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      confirmDisplay: true,
      confirmUpdate: false,
      displayName: '',
      email: ''
    };
  },
  methods: {
    cancelClicked() {
      this.$emit('canceled');
    },
    confirmClicked() {
      this.$emit('confirmed');
    },
    setDestination(haul) {
      // This is for hauls TO sources that track running balance
      if (!haul.sourceAsDestination) {
        return this.masterDestinations[haul.destinationLocationId]
          .destinationName;
      } else {
        return this.masterSources[haul.destinationLocationId].sourceName;
      }
    },
    trackerLabel(label) {
      if (label.startsWith('***&&&')) {
        return label.substring(9);
      }

      return label;
    }
  },
  computed: {
    customers() {
      return JSON.parse(JSON.stringify(this.$store.state.customers));
    },
    masterDestinations() {
      return JSON.parse(JSON.stringify(this.$store.state.masterDestinations));
    },
    masterSources() {
      return JSON.parse(JSON.stringify(this.$store.state.masterSources));
    }
  }
};
</script>

<style scoped>
.outer-card {
  width: 453px;
}

@media all and (max-width: 400px) {
  .outer-card {
    width: 100%;
  }
}
</style>
