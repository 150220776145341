<template>
  <div class="row justify-center full-width">
    <ConfirmDialog
      v-if="confirm.display"
      icon="outbound"
      iconColor="custom-green"
      iconStyle=""
      :btn="confirm.btn"
      :message="confirm.message"
      :headerMessage="confirm.headerMessage"
      @canceled="confirm.display = false"
      @confirmed="confirmed"
    />

    <OkDialog
      v-if="ok.display"
      :icon="ok.icon"
      :iconColor="ok.iconColor"
      :iconStyle="ok.iconStyle"
      :headerMessage="ok.headerMessage"
      :message="ok.message"
      @okConfirmed="okCancel"
    />

    <SourceLocations
      v-if="manageLocations"
      ref="sourceLocations"
      :customerId="source.customerId"
      :sourceLocations="sourceLocations"
      :editView="true"
      @managePin="managePin"
      @reloadLocations="setSourceLocations"
      @onSelectLocation="onSelectLocation"
      @layerThreeAttachments="layerThreeAttachments"
    />

    <PrintableHaulReport
      v-if="displayPrintable"
      :reportData="source"
      :isSource="isSource"
      @layerThreeAttachments="layerThreeAttachments"
    />

    <div
      v-show="!manageLocations && !displayPrintable"
      class="row full-width q-pa-md"
    >
      <div class="half-width half-width-mr">
        <q-card flat bordered class="q-mb-md">
          <div class="row justify-center items-center q-py-xs">
            <q-icon name="account_circle" color="primary" size="md" />
            <span class="text-body1 text-primary word-wrap q-ml-sm">
              {{ source.customer }}
            </span>
          </div>
          <q-separator class="full-width" />

          <div class="q-px-md q-py-md text-body1 text-primary">
            <div class="row items-center">
              <q-icon
                name="location_on"
                color="primary"
                size="md"
                class="q-mr-xs"
              />

              <div v-if="location.customerId">
                {{ location.sourceName }}
              </div>
              <q-space />
              <div class="q-py-xs">
                <q-btn
                  v-if="!location.customerId"
                  label="Set Location"
                  no-caps
                  color="custom-red"
                  padding="2px md"
                  @click="manageSources"
                />
                <q-btn
                  v-if="location.customerId && !hasHauls && !mapView"
                  label="Edit Location"
                  no-caps
                  color="custom-orange"
                  padding="2px md"
                  @click="manageSources"
                />
                <q-btn
                  v-if="location.customerId && hasHauls"
                  label="Edit Location"
                  no-caps
                  color="custom-light"
                  padding="2px md"
                />
              </div>
            </div>
          </div>
          <q-separator />

          <div class="row items-center q-pa-md">
            <div
              class="row"
              :style="
                $q.screen.lt.sm ? 'width: 100%' : 'width: 49%; margin-right: 2%'
              "
            >
              <div class="row items-center full-width no-wrap ellipsis">
                <div
                  class="row justify-center items-center bg-custom-light rounded-borders text-h5"
                  style="width: 44px; height: 44px; position: relative"
                >
                  <span style="z-index: 2">
                    {{ source.customer.charAt(0) }}
                  </span>
                  <div
                    class="row items-center justify-center bg-custom-light-blue"
                    :style="`position: absolute; bottom: 0; width: 44px;
                  border-bottom-left-radius: 4px;
                  border-bottom-right-radius: 4px;
                  border-top-right-radius: ${
                    percentHauled === '100%' ? 4 : 0
                  }px;
                  border-top-left-radius: ${percentHauled === '100%' ? 4 : 0}px;
                  border-bottom-top-radius: 4px;
                  height: ${percentHauled}`"
                  ></div>
                </div>

                <div class="ellipsis q-ml-md">
                  {{ Math.round(source.hauled) || 0 }} /
                  {{ Math.round(projected) || '--' }}
                  {{ source.measurementUnit }}
                </div>
              </div>
            </div>

            <div
              class="row"
              :class="$q.screen.lt.sm ? 'q-mt-md' : ''"
              :style="$q.screen.lt.sm ? 'width: 100%' : 'width: 49%;'"
            >
              <div class="full-width">
                <q-input
                  outlined
                  v-model.number="projected"
                  label="projected"
                  label-color="custom-light-blue"
                  color="custom-light-blue"
                  type="number"
                  step="any"
                  dense
                />
              </div>
            </div>
          </div>
          <q-separator />

          <div class="row items-center q-pa-md">
            <div
              class="row"
              :class="$q.screen.lt.sm ? 'justify-center' : ''"
              :style="
                $q.screen.lt.sm ? 'width: 100%' : 'width: 49%; margin-right: 2%'
              "
            >
              <q-checkbox
                v-model="purchaseCommodity"
                dense
                label="Purchase Commodity"
                color="custom-green"
                size="lg"
              />
            </div>
            <div
              class="row half-width"
              :class="$q.screen.lt.sm ? 'q-mt-md' : ''"
              :style="$q.screen.lt.sm ? 'width: 100%' : 'width: 49%;'"
            >
              <div class="full-width">
                <q-input
                  v-model.number="pricePerUnit"
                  outlined
                  dense
                  :label="'Price Per ' + measurementUnit.slice(0, -1)"
                  color="custom-green"
                  label-color="custom-green"
                  mask="#.##"
                  fill-mask="0"
                  reverse-fill-mask
                  type="tel"
                  prefix="$"
                  maxlength="20"
                />
              </div>
            </div>
          </div>
        </q-card>

        <q-card flat class="q-mb-md" style="border: 2px solid #fe9e20">
          <div class="row items-center q-pa-md">
            <div
              class="row justify-center"
              style="width: 49%; margin-right: 2%"
            >
              <div
                class="row justify-center items-center full-width text-bold text-body1 text-custom-orange"
              >
                Notes
                <q-icon
                  v-if="!importantNote"
                  name="edit_note"
                  size="28px"
                  class="q-ml-sm"
                />
                <q-icon
                  v-if="importantNote"
                  name="announcement"
                  size="28px"
                  color="custom-red"
                  class="q-ml-sm"
                />
              </div>
            </div>
            <div class="row" style="width: 49%">
              <div class="row justify-center full-width">
                <q-checkbox
                  v-model="importantNote"
                  dense
                  label="Important Note"
                  color="custom-red"
                  size="lg"
                />
              </div>
            </div>
          </div>

          <div class="row q-px-md">
            <div class="full-width q-mb-md">
              <q-input
                outlined
                v-model="note"
                label="Note"
                label-color="custom-orange"
                color="custom-orange"
                autogrow
                dense
              />
            </div>
            <div class="full-width q-mb-md">
              <q-input
                outlined
                v-model="adminNote"
                label="Admin Note"
                label-color="custom-orange"
                color="custom-orange"
                autogrow
                dense
              />
            </div>
            <div class="row items-center full-width q-mb-sm">
              <q-icon
                name="account_circle"
                color="custom-orange"
                size="sm"
                class="q-mr-xs"
              />
              <span class="text-bold text-custom-orange q-mr-sm">
                Customer Note:
              </span>
              {{ customerNote }}
              <q-separator class="full-width" />
            </div>
            <div class="q-px-sm q-py-xs"></div>
          </div>
        </q-card>

        <q-card flat bordered class="q-mb-md">
          <div class="row justify-center text-body1 q-pa-md q-my-sm">
            <q-toggle
              v-model="readyForSettlement"
              dense
              label="Ready For Settlement"
              color="custom-green"
              size="xl"
              icon="task_alt"
            />
            <q-btn
              round
              flat
              icon="info"
              no-caps
              color="primary"
              size="10px"
              padding="xs"
              class="q-mb-xs q-ml-xs"
              @click="readyForSettlementInfo"
            />
          </div>
          <q-separator />

          <div class="row full-width">
            <div class="half-width-b text-subtitle1">
              <div class="text-center q-pt-sm">
                Status
                <q-btn
                  round
                  flat
                  icon="info"
                  no-caps
                  color="primary"
                  size="10px"
                  padding="xs"
                  class="q-mb-xs q-ml-xs"
                  @click="statusInfo"
                />
              </div>
              <div class="q-px-sm">
                <q-option-group
                  size="sm"
                  :options="sourceStatus.options"
                  type="radio"
                  v-model="sourceStatus.model"
                />
              </div>
            </div>
            <div class="half-width-c text-subtitle1">
              <div class="text-center q-pt-sm">Commodity</div>
              <div class="q-px-sm">
                <q-option-group
                  size="sm"
                  :options="commodities"
                  type="radio"
                  v-model="commoditySelected"
                  color="custom-light-blue"
                />
              </div>
            </div>
          </div>
        </q-card>

        <!--this needs to be if so inputs work correctly - not gt class-->
        <div v-if="topModule" class="row half-width-container">
          <q-card
            flat
            bordered
            v-if="modules.dispatch"
            class="half-width-qc q-mb-sm"
          >
            <div class="row items-center q-py-sm">
              <div class="row full-width justify-center text-subtitle1 q-mb-xs">
                Dispatched To
              </div>
              <div class="q-pl-md">
                <q-option-group
                  dense
                  :options="dispatchedToOptions"
                  v-model="dispatchedTo"
                  type="checkbox"
                  color="custom-light-blue"
                  size="md"
                />
              </div>
            </div>
          </q-card>

          <q-card
            flat
            bordered
            v-if="modules.guestUsers"
            class="half-width-qc q-mb-sm"
          >
            <div class="row items-center q-py-sm">
              <div class="row full-width justify-center text-subtitle1 q-mb-xs">
                <q-checkbox
                  v-model="allowGuestUsers"
                  dense
                  color="primary"
                  label="Allow Guest Users"
                  size="md"
                />
              </div>
              <div class="q-pl-md">
                <q-option-group
                  v-if="allowGuestUsers"
                  dense
                  :options="guestUserOptions"
                  v-model="guestUsersAllowed"
                  type="checkbox"
                  color="custom-red"
                  size="md"
                />
              </div>
            </div>
          </q-card>

          <q-card
            flat
            bordered
            v-if="modules.driverRate"
            class="half-width-qc q-mb-sm"
          >
            <div class="row justify-center items-center q-pt-sm q-pb-md">
              <div class="row full-width justify-center text-subtitle1 q-mb-sm">
                Driver Rate
              </div>
              <div class="full-width q-px-md">
                <q-input
                  v-model.number="driverRate"
                  mask="#.##"
                  fill-mask="0"
                  reverse-fill-mask
                  type="tel"
                  prefix="$"
                  maxlength="20"
                  outlined
                  dense
                  color="primary"
                />
              </div>
            </div>
          </q-card>

          <q-card
            flat
            bordered
            v-if="modules.trackMileage"
            class="half-width-qc q-mb-sm"
          >
            <div class="row justify-center items-center q-pt-xs q-pb-md">
              <div class="row full-width justify-center text-subtitle1">
                <q-checkbox
                  v-model="trackMileage"
                  label="Track Mileage"
                  color="primary"
                  size="md"
                />
              </div>
              <div class="full-width q-px-md">
                <q-input
                  v-model.number="mileageRate"
                  outlined
                  dense
                  label="Rate Per Mile"
                  mask="#.##"
                  fill-mask="0"
                  reverse-fill-mask
                  type="tel"
                  prefix="$"
                  maxlength="20"
                  color="primary"
                />
              </div>
            </div>
          </q-card>
        </div>
      </div>

      <div class="half-width">
        <div class="row half-width-container">
          <q-card
            flat
            bordered
            class="half-width-qc q-px-md q-pt-md q-pb-xs q-mb-md"
          >
            <div>
              <div
                class="row justify-center full-width rounded-borders text-bold text-body1 text-custom-light q-py-xs q-mb-lg"
                style="
                  background: radial-gradient(circle, #3336eb 0%, #0b0d6f 100%);
                "
              >
                Haul Trackers
              </div>

              <div class="row justify-center full-width q-mb-md">
                <div>
                  <div
                    v-for="(tracker, index) in haulTrackers"
                    :key="index + 150"
                  >
                    <q-checkbox
                      v-model="trackerCheckboxes[index]"
                      dense
                      :label="tracker"
                      color="custom-orange"
                      size="lg"
                      class="q-pt-xs"
                    />
                  </div>
                </div>
              </div>
            </div>
          </q-card>

          <q-card flat bordered class="half-width-qc q-pa-md q-mb-md">
            <div>
              <div
                class="row justify-center full-width rounded-borders text-bold text-body1 text-custom-light q-py-xs q-mb-lg"
                style="
                  background: radial-gradient(circle, #3336eb 0%, #0b0d6f 100%);
                "
              >
                Info Boxes
              </div>

              <div
                v-for="(infoBox, index) in infoBoxes"
                :key="index"
                class="q-mb-md"
              >
                <q-input
                  outlined
                  v-model.number="infoBox.model"
                  type="number"
                  step="any"
                  :label="infoBox.name"
                  dense
                  color="primary"
                />
              </div>
            </div>
          </q-card>
        </div>

        <div class="row half-width-container">
          <q-card flat bordered class="half-width-qc q-pa-md q-mb-md">
            <div>
              <div
                class="row justify-center full-width rounded-borders text-bold text-body1 text-custom-light q-py-xs q-mb-lg"
                style="
                  background: radial-gradient(circle, #3336eb 0%, #0b0d6f 100%);
                "
              >
                Services
              </div>
              <div
                v-for="(service, index) in services"
                :key="index + 100"
                class="row justify-center full-width"
              >
                <div class="row items-center full-width q-pb-xs">
                  <q-checkbox
                    v-model="serviceCheckboxes[index]"
                    dense
                    :label="service.name"
                    color="primary"
                    size="lg"
                  />
                </div>

                <div
                  v-if="serviceCheckboxes[index]"
                  class="row items-center full-width q-py-xs"
                >
                  <q-checkbox
                    v-model="services[index].complete"
                    dense
                    :label="services[index].name + ' Complete'"
                    color="custom-red"
                    size="lg"
                    @input="serviceDateChange(services[index], index)"
                  />
                </div>

                <div
                  v-if="
                    serviceCheckboxes[index] && services[index].priceXInfoBox
                  "
                  class="row items-center full-width q-py-xs"
                >
                  <q-checkbox
                    v-model="services[index].useInfoBox"
                    dense
                    :label="
                      'Price Multiplied By - ' +
                      services[index].infoBoxPairedWith
                    "
                    color="custom-orange"
                    size="lg"
                  />
                </div>

                <div v-if="serviceCheckboxes[index]" class="full-width q-mt-xs">
                  <q-input
                    square
                    outlined
                    dense
                    v-model.number="services[index].price"
                    :label="
                      !services[index].priceXInfoBox ||
                      !services[index].useInfoBox
                        ? services[index].name +
                          ' Price Per ' +
                          measurementUnit.slice(0, -1)
                        : services[index].name +
                          ' Price - Multiplied By - ' +
                          services[index].infoBoxPairedWith
                    "
                    mask="#.##"
                    fill-mask="0"
                    reverse-fill-mask
                    type="tel"
                    prefix="$"
                    maxlength="20"
                    color="primary"
                  />
                </div>
                <div v-if="serviceCheckboxes[index]" class="full-width q-mt-sm">
                  <div class="row items-center">
                    <div style="width: 35%">
                      <q-checkbox
                        v-model="services[index].hasDate"
                        dense
                        label="Date"
                        color="custom-light-blue"
                        size="lg"
                      />
                    </div>
                    <div v-if="services[index].hasDate" style="width: 65%">
                      <q-input
                        square
                        outlined
                        dense
                        v-model="services[index].serviceDate"
                        mask="date"
                        :rules="['date']"
                        class="q-ma-none q-pa-none"
                        :class="'bg-' + services[index].dateColor"
                        color="custom-light-blue"
                      >
                        <template v-slot:append>
                          <q-icon
                            name="event"
                            class="cursor-pointer"
                            color="custom-light-blue"
                          >
                            <q-popup-proxy
                              ref="qDateProxy"
                              transition-show="scale"
                              transition-hide="scale"
                            >
                              <q-date
                                v-model="services[index].serviceDate"
                                color="custom-light-blue"
                                @input="
                                  serviceDateChange(services[index], index)
                                "
                              >
                                <div class="row items-center justify-end">
                                  <q-btn
                                    v-close-popup
                                    label="Close"
                                    color="custom-light-blue"
                                    flat
                                  />
                                </div>
                              </q-date>
                            </q-popup-proxy>
                          </q-icon>
                        </template>
                      </q-input>
                    </div>
                  </div>
                </div>
                <q-separator
                  v-if="index !== serviceCheckboxes.length - 1"
                  color="primary"
                  class="q-my-md full-width"
                />
              </div>
            </div>
          </q-card>

          <q-card flat bordered class="half-width-qc q-pa-md q-mb-md">
            <div>
              <div
                class="row justify-center full-width rounded-borders text-bold text-body1 text-custom-light q-py-xs q-mb-md"
                style="
                  background: radial-gradient(circle, #3336eb 0%, #0b0d6f 100%);
                "
              >
                Custom Selectors
              </div>

              <div
                v-for="(customSelector, index) in customSelectors"
                :key="index + 50"
                class="q-pb-xs"
              >
                <div class="row justify-center text-subtitle1 q-pb-xs">
                  {{ customSelector.name }}
                </div>
                <q-option-group
                  size="sm"
                  :options="customSelector.options"
                  type="radio"
                  v-model="customSelector.model"
                  style="padding-left: 9px"
                />
              </div>
            </div>
          </q-card>
        </div>

        <!--this needs to be if so inputs work correctly - not lt class-->
        <div v-if="!topModule" class="row half-width-container">
          <q-card
            flat
            bordered
            v-if="modules.dispatch"
            class="half-width-qc q-mb-sm"
          >
            <div class="row items-center q-py-sm">
              <div class="row full-width justify-center text-subtitle1 q-mb-xs">
                Dispatched To
              </div>
              <div class="q-pl-md">
                <q-option-group
                  dense
                  :options="dispatchedToOptions"
                  v-model="dispatchedTo"
                  type="checkbox"
                  color="custom-light-blue"
                  size="md"
                />
              </div>
            </div>
          </q-card>

          <q-card
            flat
            bordered
            v-if="modules.guestUsers"
            class="half-width-qc q-mb-sm"
          >
            <div class="row items-center q-py-sm">
              <div class="row full-width justify-center text-subtitle1 q-mb-xs">
                <q-checkbox
                  v-model="allowGuestUsers"
                  dense
                  color="primary"
                  label="Allow Guest Users"
                  size="md"
                />
              </div>
              <div class="q-pl-md">
                <q-option-group
                  v-if="allowGuestUsers"
                  dense
                  :options="guestUserOptions"
                  v-model="guestUsersAllowed"
                  type="checkbox"
                  color="custom-red"
                  size="md"
                />
              </div>
            </div>
          </q-card>

          <q-card
            flat
            bordered
            v-if="modules.driverRate"
            class="half-width-qc q-mb-sm"
          >
            <div class="row justify-center items-center q-pt-sm q-pb-md">
              <div class="row full-width justify-center text-subtitle1 q-mb-sm">
                Driver Rate
              </div>
              <div class="full-width q-px-md">
                <q-input
                  v-model.number="driverRate"
                  mask="#.##"
                  fill-mask="0"
                  reverse-fill-mask
                  type="tel"
                  prefix="$"
                  maxlength="20"
                  outlined
                  dense
                  color="primary"
                />
              </div>
            </div>
          </q-card>

          <q-card
            flat
            bordered
            v-if="modules.trackMileage"
            class="half-width-qc q-mb-sm"
          >
            <div class="row justify-center items-center q-pt-xs q-pb-md">
              <div class="row full-width justify-center text-subtitle1">
                <q-checkbox
                  v-model="trackMileage"
                  label="Track Mileage"
                  color="primary"
                  size="md"
                />
              </div>
              <div class="full-width q-px-md">
                <q-input
                  v-model.number="mileageRate"
                  outlined
                  dense
                  label="Rate Per Mile"
                  mask="#.##"
                  fill-mask="0"
                  reverse-fill-mask
                  type="tel"
                  prefix="$"
                  maxlength="20"
                  color="primary"
                />
              </div>
            </div>
          </q-card>
        </div>

        <q-card flat bordered class="q-pa-md q-py-xs q-mb-md">
          <div
            class="row q-py-xs"
            :class="$q.screen.lt.sm ? 'row justify-center full-width' : ''"
          >
            <q-checkbox
              v-model="alwaysAvailable"
              dense
              color="custom-red"
              label="Long Running Source"
              class="text-bold q-mb-xs q-mt-xs"
              size="lg"
            />
            <q-btn
              round
              flat
              icon="info"
              no-caps
              color="primary"
              size="10px"
              padding="xs"
              class="q-mb-xs"
              @click="alwaysOpenMessage"
            />
          </div>
          <q-separator />

          <div
            v-if="alwaysAvailable"
            class="row q-py-sm"
            :class="$q.screen.lt.sm ? 'row justify-center full-width' : ''"
          >
            <q-checkbox
              v-model="trackRunningBalance"
              dense
              color="custom-orange"
              label="Available For Incoming"
              class="text-bold q-mb-xs q-mt-xs"
              size="lg"
            />
            <q-btn
              round
              flat
              icon="info"
              no-caps
              color="primary"
              size="10px"
              padding="xs"
              class="q-mb-xs"
              @click="availableForIncomingInfo"
            />
          </div>
          <q-separator v-if="alwaysAvailable" />

          <div
            v-if="trackRunningBalance && alwaysAvailable"
            class="row items-center q-py-sm"
            :class="$q.screen.lt.sm ? 'row justify-center full-width' : ''"
          >
            <q-input
              outlined
              dense
              v-model.number="runningBalance"
              label="Running Balance"
              reverse-fill-mask
              type="tel"
              maxlength="20"
              class="full-width"
              color="frappe-green"
            />
          </div>
          <q-separator v-if="trackRunningBalance && alwaysAvailable" />

          <div align="right" class="text-bold q-py-sm">
            <span class="q-mr-lg">Total Charge</span>
            {{ formatPrice(Math.abs(totalPrice)) }}
          </div>
          <q-separator />

          <div v-if="!mapView" class="row q-mt-md">
            <q-space />
            <q-btn
              v-if="location.customerId && source.hauls"
              label="Printable Report"
              no-caps
              color="primary"
              padding="2px md"
              @click="onManagePrintable"
            />
            <q-btn
              v-if="!location.customerId || !source.hauls"
              label="Printable Report"
              no-caps
              color="grey-5"
              padding="2px md"
            />
          </div>
        </q-card>
      </div>
    </div>
  </div>
</template>

<script>
import { date } from 'quasar';
import { formatPrice } from '@/utils/UtilHelpers';
import firebase from 'firebase/compat/app';
import SourceLocations from '@/components/sources/SourceLocations.vue';
import PrintableHaulReport from '@/components/PrintableHaulReport.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import OkDialog from '@/components/OkDialog.vue';
import { round2Dec, dateTripColor } from '@/utils/UtilHelpers.js';

const db = firebase.firestore();

export default {
  name: 'EditCustomer',
  components: {
    SourceLocations,
    PrintableHaulReport,
    ConfirmDialog,
    OkDialog
  },
  props: {
    mapView: {
      type: Boolean,
      default: false,
      required: false
    },
    settings: {
      type: Object,
      required: true
    },
    source: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      allowGuestUsers: false,
      actionBtnLabel: 'Add',
      actionBtnIcon: 'add',
      adminNote: '',
      alwaysAvailable: false,
      commoditySelected: null,
      confirm: {
        action: '',
        btn: {
          label: '',
          icon: '',
          color: ''
        },
        display: false,
        message: '',
        headerMessage: ''
      },
      customer: '',
      customerNote: '',
      customSelectors: [],
      dataOnMount: {},
      date: date,
      dispatchedTo: [],
      dispatchedToOptions: [],
      displayPrintable: false,
      edit: false,
      formatPrice,
      guestUserOptions: [],
      guestUsersAllowed: [],
      hasHauls: true,
      hasPin: false,
      hauled: 0,
      importantNote: false,
      infoBoxes: [],
      isSource: true,
      location: {
        customerId: null,
        lat: null,
        lng: null
      },
      manageLocations: false,
      mileageRate: 0,
      note: '',
      ok: {
        display: false,
        icon: 'outbound',
        iconColor: 'custom-green',
        iconStyle: '',
        headerMessage: '',
        message: ''
      },
      pricePerUnit: 0,
      projected: 0,
      purchaseCommodity: true,
      readyForSettlement: false,
      readyForSettlementOnMount: false,
      runningBalance: 0,
      services: [],
      serviceCheckboxes: [],
      setPinAfterSave: false,
      sourceLocations: [],
      sourceLocationsMapPin: {},
      sourceLocationsIndex: 0,
      sourceStatus: {
        options: [],
        model: 'Open'
      },
      startDate: null,
      statusOnMount: 'Later',
      haulTrackers: [],
      trackerCheckboxes: [],
      trackRunningBalance: false,
      trackMileage: false,
      topModule: true,
      settlementReports: [],
      sortedHaulTrackers: [],
      updateId: 0,
      useSourceLocationsMapPin: false
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.setSourceLocations();
    this.setSourceSettings();
    this.importantMessage();

    if (!this.location.customerId) {
      this.manageSources();
    }

    this.$nextTick(() => {
      window.addEventListener('resize', this.setModulePosition);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setModulePosition);
  },
  methods: {
    alwaysOpenMessage() {
      this.ok = {
        display: true,
        icon: 'info',
        iconColor: 'primary',
        iconStyle: '',
        headerMessage: 'Long Running Source',
        message: `<li>This setting is recommended for Sources that will be left open
          for months, especially if many hauls will be hauled from this source. </li><br>
          <li>Sources set to this setting will be automatically archived at the end of the month,
          preserving all of the hauls.
          A new matching source will be opened with a new Start Date and no hauls recorded. </li><br>
          <li>A source could eventually run out of space to hold
          more hauls. This setting will prevent any issues with running out of cloud space.
          Using this setting will also keep your haul list shorter on the hauls page. </li><br>
          <li>This setting also opens up the option to  make a source available for incoming hauls and
          track the running balance.</li>`
      };
    },
    availableForIncomingInfo() {
      this.ok = {
        display: true,
        icon: 'info',
        iconColor: 'primary',
        iconStyle: '',
        headerMessage: 'Available For Incoming',
        message: `<li>This setting will allow drivers to make a haul TO this source. </li><br>
          <li>The primary use case for this setting is when the user is making hauls both to and from
          a location. </li><br>
          <li>This setting also allows the user to keep track of the running balance for a location.
          The running balance will be added to on incoming hauls, and subtracted from on outbound hauls.</li>`
      };
    },
    cancel() {
      this.$emit('onSubmit');
    },
    cancelLayerThreeAttachment() {
      this.$refs.sourceLocations.cancelLayerThreeAttachment();
    },
    cancelLayerTwoInputs() {
      if (this.source.action === 'edit') {
        this.location = this.masterSources[this.source.locationId];
      }
      this.manageLocations = false;
      this.displayPrintable = false;
    },
    completed() {
      this.readyForSettlement = true;

      for (let i = 0; i < this.services.length; i++) {
        this.services[i].complete = true;
      }

      this.$q.notify({
        color: 'custom-green',
        textColor: 'white',
        message: 'Ready For Settlement Checked',
        caption: 'All Services Marked Complete'
      });
    },
    confirmAction(action) {
      if (action === 'delete') {
        this.confirm.action = 'delete';
        this.confirm.btn = {
          label: 'Delete',
          icon: 'delete',
          color: 'custom-red'
        };
        this.confirm.message = `Delete ${this.source.sourceName}?`;
        this.confirm.headerMessage = this.source.customer;
        this.confirm.display = true;
      } else if (action === 'haulTrackersChanged') {
        this.confirm.action = 'haulTrackersChanged';
        this.confirm.btn = {
          label: 'Ok',
          icon: 'check',
          color: 'primary'
        };
        this.confirm.message = `Haul Tracker change after haul entered! This could affect
          your Haul Tracker totals for this source.`;
        this.confirm.headerMessage = this.source.customer;
        this.confirm.display = true;
      } else if (action === 'trackMileageChanged') {
        this.confirm.action = 'trackMileageChanged';
        this.confirm.btn = {
          label: 'Ok',
          icon: 'check',
          color: 'primary'
        };
        this.confirm.message =
          'Track Mileage change after haul entered! This could affect your Mileage totals for this source.';
        this.confirm.headerMessage = this.source.customer;
        this.confirm.display = true;
      } else if (action === 'laterHasHauls') {
        this.confirm.action = 'laterHasHauls';
        this.confirm.btn = {
          label: 'Set To Later',
          icon: 'check',
          color: 'primary'
        };
        this.confirm.message = `This source already has hauls! Setting Status to Later will prevent
          hauls from showing up in haul lists or reports!`;
        this.confirm.headerMessage = this.source.customer;
        this.confirm.display = true;
      } else if (action === 'saveBeforeSettingPin') {
        this.confirm.action = 'saveBeforeSettingPin';
        this.confirm.btn = {
          label: 'Save',
          icon: 'check',
          color: 'primary'
        };
        this.confirm.message = `Save changes before transfer to map page?`;
        this.confirm.headerMessage = this.source.customer;
        this.confirm.display = true;
      }
    },
    confirmed() {
      if (this.confirm.action === 'delete') {
        this.onDelete();
      } else if (this.confirm.action === 'haulTrackersChanged') {
        this.onVerifySecond();
      } else if (this.confirm.action === 'trackMileageChanged') {
        this.onEnterSource();
      } else if (this.confirm.action === 'laterHasHauls') {
        this.onEnterSource();
      } else if (this.confirm.action === 'saveBeforeSettingPin') {
        this.setPinAfterSave = true;
        this.onVerifyFirst();
      }
    },
    importantMessage() {
      if (this.importantNote) {
        const importantNoteIds = this.$store.state.importantNoteIds;
        let spamUser = true;
        for (let i = 0; i < importantNoteIds.length; i++) {
          if (importantNoteIds[i].id === this.source.id) {
            const tsFiveMinAgo = +new Date() - 300000;
            if (tsFiveMinAgo < importantNoteIds[i].ts) {
              spamUser = false;
            }
          }
        }
        if (spamUser && this.source.note.length !== 0) {
          this.$store.dispatch('setImportantNoteIds', {
            id: this.source.id,
            ts: +new Date()
          });

          this.ok = {
            display: true,
            icon: 'announcement',
            iconColor: 'custom-red',
            iconStyle: '',
            headerMessage: 'Important Note',
            message: this.note
          };
        }
      }

      // this is the last onMount function so we need to set dataOnMount
      this.$nextTick(() => {
        this.dataOnMount = JSON.parse(JSON.stringify(this.$data));
      });
    },
    layerThreeAttachments() {
      this.$emit('layerThreeAttachments');
    },
    managePin(mapPin = {}) {
      if (
        !this.source.hauls &&
        !this.setPinAfterSave &&
        !mapPin.fromSourceLocations
      ) {
        this.confirmAction('saveBeforeSettingPin');
        return;
      } else {
        for (const key in this.dataOnMount) {
          if (
            key !== 'dataOnMount' &&
            key !== 'ok' &&
            key !== 'confirm' &&
            key !== 'manageLocations'
          ) {
            if (
              JSON.stringify(this.dataOnMount[key]) !==
              JSON.stringify(this.$data[key])
            ) {
              if (mapPin.fromSourceLocations) {
                this.useSourceLocationsMapPin = true;
                this.sourceLocationsMapPin = mapPin;
              }

              this.confirmAction('saveBeforeSettingPin');

              return;
            }
          }
        }
      }

      const id = this.location.id;

      if (!mapPin.fromSourceLocations) {
        mapPin = {
          type: 'source',
          id: id
        };
      }

      if (this.useSourceLocationsMapPin) {
        mapPin = this.sourceLocationsMapPin;
      }

      this.$store.dispatch('setMapPin', mapPin);
      this.$router.push({ path: 'map' });
    },
    manageSources() {
      this.manageLocations = true;
      if (this.mapView) {
        this.$emit('layerThreeLocations', true);
      } else {
        this.$emit('layerTwoLocations');
      }
    },
    okCancel() {
      this.ok = {
        display: false,
        icon: 'outbound',
        iconColor: 'custom-green',
        iconStyle: '',
        headerMessage: '',
        message: ''
      };
    },
    onDelete() {
      const id = this.source.id;

      const batch = db.batch();

      const sourceDoc = db.collection('sources_active').doc(id);
      const sourceUsersDoc = db.collection('sources_active_users').doc(id);

      batch.delete(sourceDoc);
      batch.delete(sourceUsersDoc);

      batch.commit().catch(err => {
        console.log(err);
      });

      this.confirmDisplay = false;
      this.$q.notify({
        color: 'custom-red',
        textColor: 'white',
        message: this.source.sourceName + ' Deleted'
      });

      this.$emit('onSubmit');
    },
    onEnterSource() {
      if (!this.location.customerId) {
        this.ok.message = 'Please Set Location!';
        this.ok.display = true;
        return;
      }

      if (
        (this.sourceStatus.model === 'Open' ||
          this.sourceStatus.model === 'Waiting') &&
        !this.mapView &&
        this.statusOnMount === 'Estimate'
      ) {
        for (let i = 0; i < this.activeSources.length; i++) {
          const source = this.activeSources[i];
          if (
            source.customerId === this.location.customerId &&
            source.sourceLocationId === this.location.id
          ) {
            this.ok.message = `${this.location.sourceName} is already open!
              Cannot Open two instances of the same source.
              (multiple instances can be opened if status is set to Later or Estimate)`;
            this.ok.display = true;
            return;
          }
        }
      }

      if (this.commoditySelected === 'None') {
        this.ok.message = 'Please Select Commodity';
        this.ok.display = true;
        return false;
      }

      let hauls = {};

      if (this.source.action === 'edit') {
        hauls = this.source.haulsObj;
      }

      // set end date if haul complete checked on this edit
      let endDate = null;
      if (
        this.source.action === 'edit' &&
        this.readyForSettlementOnMount === false &&
        this.readyForSettlement === true
      ) {
        endDate = +new Date();
      } else if (
        this.source.action === 'edit' &&
        this.readyForSettlementOnMount === true &&
        this.readyForSettlement === true
      ) {
        endDate = this.source.endDate;
      }

      let updateId = 0;
      if (this.source.action === 'edit') {
        updateId = this.updateId + 1;
      }

      const userInfoBoxes = [];
      for (let i = 0; i < this.infoBoxes.length; i++) {
        const infoBox = this.infoBoxes[i];
        if (infoBox.userAuth) {
          userInfoBoxes.push(infoBox);
        }
      }

      const userCustomSelectors = [];
      const customSelectors = [];
      for (let i = 0; i < this.customSelectors.length; i++) {
        const customSelector = this.customSelectors[i];

        customSelectors.push({
          name: customSelector.name,
          model: customSelector.model
        });

        if (customSelector.userAuth) {
          userCustomSelectors.push({
            name: customSelector.name,
            model: customSelector.model
          });
        }
      }

      const services = [];
      const userServices = [];
      for (let i = 0; i < this.services.length; i++) {
        if (this.serviceCheckboxes[i] === true) {
          const serviceObj = {
            name: this.services[i].name,
            price: parseFloat(this.services[i].price)
          };
          if (this.services[i].priceXInfoBox) {
            serviceObj.infoBoxPairedWith = this.services[i].infoBoxPairedWith;
            serviceObj.useInfoBox = this.services[i].useInfoBox;
          }
          services.push(serviceObj);

          let completeTs = null;
          if (this.services[i].completeOnMount && this.services[i].complete) {
            completeTs = this.services[i].completeTs;
          } else if (
            !this.services[i].completeOnMount &&
            this.services[i].complete
          ) {
            completeTs = +new Date();
          } else if (!this.services[i].complete) {
            completeTs = null;
          }

          const userServiceObj = {
            name: this.services[i].name,
            complete: this.services[i].complete,
            completeTs: completeTs
          };
          if (this.services[i].hasDate) {
            if (!/^\d{4}\/\d{2}\/\d{2}$/.test(this.services[i].serviceDate)) {
              this.ok.message = 'Invalid Service Date';
              this.ok.icon = 'today';
              this.ok.iconColor = 'custom-red';
              this.ok.display = true;
              return false;
            }

            userServiceObj.hasDate = this.services[i].hasDate;
            userServiceObj.serviceTs = +new Date(this.services[i].serviceDate);
          }
          userServices.push(userServiceObj);
        }
      }

      const guestUsers = [];
      for (let i = 0; i < this.guestUsersAllowed.length; i++) {
        guestUsers.push(
          this.guestUserList.guestUsers[this.guestUsersAllowed[i]]
        );
      }

      let sourceDoc;
      if (this.source.action === 'edit') {
        sourceDoc = db.collection('sources_active').doc(this.source.id);
      } else {
        sourceDoc = db.collection('sources_active').doc();
      }

      const sourceUsersDoc = db
        .collection('sources_active_users')
        .doc(sourceDoc.id);

      const source = {
        id: sourceDoc.id,
        customerId: this.source.customerId,
        updateId: updateId,
        sourceLocationId: this.location.id,
        startDate: this.startDate,
        endDate,
        settlementDate: null,
        purchaseCommodity: this.purchaseCommodity,
        pricePerUnit: parseFloat(this.pricePerUnit),
        mileageRate: parseFloat(this.mileageRate),
        services: services,
        haulTrackers: this.sortedHaulTrackers,
        adminNote: this.adminNote,
        settlementReports: this.settlementReports,
        infoBoxes: this.infoBoxes,
        customSelectors: customSelectors,
        sourceStatus: this.sourceStatus.model
      };

      const sourceUsers = {
        id: sourceDoc.id,
        updateId,
        customerId: this.source.customerId,
        sourceLocationId: this.location.id,
        startDate: this.startDate,
        endDate,
        commodity: this.commoditySelected,
        settlementDate: null,
        trackMileage: this.trackMileage,
        alwaysAvailable: this.alwaysAvailable,
        trackRunningBalance: this.trackRunningBalance,
        runningBalance: this.runningBalance,
        services: userServices,
        haulTrackers: this.sortedHaulTrackers,
        note: this.note,
        hauls,
        userInfoBoxes,
        userCustomSelectors,
        sourceStatus: this.sourceStatus.model,
        projected: this.projected,
        allowGuestUsers: this.allowGuestUsers,
        guestUsers,
        importantNote: this.importantNote,
        dispatchedTo: this.dispatchedTo
      };

      const notify = message => {
        this.$q.notify({
          color: 'primary',
          textColor: 'white',
          message: message
        });
      };

      const batch = db.batch();

      batch.set(sourceDoc, source);
      batch.set(sourceUsersDoc, sourceUsers);

      batch.commit().catch(err => {
        console.log(err);
      });

      if (this.source.action === 'edit') {
        notify(`${this.location.sourceName} Updated`);
      } else {
        notify(`${this.location.sourceName} Added`);
      }

      if (this.setPinAfterSave) {
        this.dataOnMount = JSON.parse(JSON.stringify(this.$data));
        this.managePin();
      } else {
        if (this.source.action === 'add') {
          this.$emit('onAddSubmit', source);
        } else {
          this.$emit('onSubmit');
        }
      }
    },
    onManagePrintable() {
      this.displayPrintable = true;
      this.$emit('layerTwoAttachments');
    },
    onSelectLocation(index) {
      this.location = this.sourceLocations[index];
      this.manageLocations = false;
      this.$emit('setLocation');
    },
    onVerifyFirst() {
      // Do this regardless
      const haulTrackers = [];
      for (let i = 0; i < this.haulTrackers.length; i++) {
        if (this.trackerCheckboxes[i] === true) {
          haulTrackers.push(this.haulTrackers[i]);
        }
      }
      haulTrackers.sort();
      this.sortedHaulTrackers = haulTrackers;

      if (this.source.action === 'add') {
        this.onEnterSource();
        return;
      }

      // If edit do the checks
      if (this.source.hauls.length && this.sourceStatus.model === 'Later') {
        this.confirmAction('laterHasHauls');
        return;
      }

      const beforeEditTrackers = JSON.parse(
        JSON.stringify(this.source.haulTrackerArr)
      );

      if (this.source.hauls.length > 0) {
        beforeEditTrackers.sort();

        let haulTrackersChanged = false;
        if (haulTrackers.length !== beforeEditTrackers.length) {
          haulTrackersChanged = true;
        } else {
          for (let i = 0; i < haulTrackers.length; i++) {
            if (haulTrackers[i] !== beforeEditTrackers[i]) {
              haulTrackersChanged = true;
              break;
            }
          }
        }
        if (haulTrackersChanged) {
          this.confirmAction('haulTrackersChanged');
        } else {
          this.onVerifySecond();
        }
      } else {
        this.onEnterSource();
      }
    },
    onVerifySecond() {
      if (this.trackMileage !== this.source.trackMileage) {
        this.confirmAction('trackMileageChanged');
      } else {
        this.onEnterSource();
      }
    },
    readyForSettlementInfo() {
      this.ok = {
        display: true,
        icon: 'info',
        iconColor: 'primary',
        iconStyle: '',
        headerMessage: 'Ready For Settlement Info',
        message: `<li>This optional setting is recommended for Sources that are
          complete and ready to invoice. </li><br>
          <li>Sources marked Ready For Settlement will be removed from the Hauls Page /
          Enter Haul options.</li><br>
          <li><b>Hauls cannot be edited when the source is marked Ready For Settlement!</b> To
          edit a haul turn this setting off.  </li>`
      };
    },
    round(num) {
      return round2Dec(num);
    },
    serviceDateChange(service, index) {
      const serviceTs = +new Date(service.serviceDate);
      const newVal = JSON.parse(JSON.stringify(this.services[index]));
      newVal.dateColor = service.complete ? '' : this.setDateColor(serviceTs);

      this.services.splice(index, 1, newVal);
    },
    setDateColor(serviceTs) {
      return !serviceTs
        ? ''
        : dateTripColor(this.serviceTripDates, serviceTs).color;
    },
    setModulePosition() {
      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      const topModule =
        width > 1260 ? true : width > 1008 ? false : width > 964 ? true : false;
      this.topModule = topModule;
    },
    setSourceLocations() {
      const allSourceLocations = JSON.parse(
        JSON.stringify(this.$store.state.masterSources)
      );
      this.sourceLocations = [];

      for (const key in allSourceLocations) {
        if (allSourceLocations[key].customerId === this.source.customerId) {
          let isActive = false;
          for (let i = 0; i < this.activeSources.length; i++) {
            const source = this.activeSources[i];
            if (
              source.customerId === this.source.customerId &&
              source.sourceLocationId === key
            ) {
              isActive = true;
              break;
            }
          }

          this.sourceLocations.push({
            ...allSourceLocations[key],
            id: key,
            isActive: isActive
          });
        }
      }

      const sortByAlpha = (a, b) => {
        if (a.sourceName < b.sourceName) {
          return -1;
        }
        if (a.sourceName > b.sourceName) {
          return 1;
        }
        return 0;
      };
      this.sourceLocations.sort(sortByAlpha);
    },
    setSourceSettings() {
      if (!this.source) {
        return;
      }
      const source = JSON.parse(JSON.stringify(this.source));
      const settings = JSON.parse(JSON.stringify(this.settings));
      const userSettings = this.userSourceSettings;

      const userList = JSON.parse(JSON.stringify(this.$store.state.userList));

      for (let [key, value] of Object.entries(userList.users)) {
        this.dispatchedToOptions.push({ label: key, value });
      }

      for (const user in this.guestUserList.guestUsers) {
        this.guestUserOptions.push({ label: user, value: user });
      }

      this.sourceStatus.options = [];
      this.sourceStatus.options.push(
        { label: 'Open', value: 'Open', color: 'custom-green' },
        { label: 'Waiting', value: 'Waiting', color: 'custom-red' }
      );

      if (source.action === 'edit') {
        if (!this.source.hauls.length) {
          this.sourceStatus.options.push({
            label: 'Estimate',
            value: 'Estimate',
            color: 'custom-light-blue'
          });
        }

        this.location = this.masterSources[source.locationId];
        this.location.id = source.locationId;
        this.updateId = source.updateId;
        this.sourceStatus.model = source.sourceStatus;
        this.startDate = source.startDate;
        this.alwaysAvailable = source.alwaysAvailable;
        this.trackRunningBalance = source.trackRunningBalance;
        this.runningBalance = source.runningBalance;
        this.purchaseCommodity = source.purchaseCommodity;
        this.commoditySelected = source.commodity;
        this.pricePerUnit = Math.round(source.pricePerUnit * 100);
        this.trackMileage = source.trackMileage;
        this.mileageRate = Math.round(source.mileageRate * 100);
        this.note = source.note;
        this.importantNote = source.importantNote;
        this.adminNote = source.adminNote;
        this.settlementReports = source.settlementReports;
        this.customerNote = source.customerNote;
        this.edit = true;
        this.actionBtnLabel = 'Update';
        this.actionBtnIcon = 'check';
        this.readyForSettlement = source.readyForSettlement;
        this.readyForSettlementOnMount = source.readyForSettlement;
        this.allowGuestUsers = source.allowGuestUsers;
        this.projected = source.projected;
        this.hauled = source.hauled;
        this.dispatchedTo = source.dispatchedTo;

        this.statusOnMount = source.sourceStatus;

        if (!source.hauls.length > 0) {
          this.hasHauls = false;
        }

        this.infoBoxes = [];
        for (let i = 0; i < userSettings.infoBoxes.length; i++) {
          const infoBox = userSettings.infoBoxes[i];

          let model = null;
          for (let j = 0; j < source.infoBoxes.length; j++) {
            const sourceInfoBox = source.infoBoxes[j];

            if (infoBox.name === sourceInfoBox.name) {
              model = sourceInfoBox.model;
            }
          }

          this.infoBoxes.push({
            name: infoBox.name,
            model: model,
            userAuth: infoBox.userAuth
          });
        }

        this.customSelectors = [];
        for (let i = 0; i < userSettings.customSelectors.length; i++) {
          const customSelector = userSettings.customSelectors[i];
          const options = [];

          for (let j = 0; j < customSelector.options.length; j++) {
            const option = customSelector.options[j];

            options.push({
              label: option.option,
              value: option.option,
              color: option.optionColor
            });
          }

          let model = customSelector.options[0].option;

          for (let j = 0; j < source.customSelectors.length; j++) {
            const selector = source.customSelectors[j];
            if (selector.name === customSelector.name) {
              model = selector.model;
            }
          }

          this.customSelectors.push({
            name: customSelector.name,
            options: options,
            model: model,
            userAuth: customSelector.userAuth
          });
        }

        this.services = [];
        for (let i = 0; i < settings.services.length; i++) {
          let serviceIncluded = false;
          for (let j = 0; j < source.services.length; j++) {
            if (settings.services[i].name === source.services[j].name) {
              serviceIncluded = true;

              let priceXInfoBox = false;
              let useInfoBox = false;
              let infoBoxPairedWith = '';
              let hasDate = false;
              let serviceTs = null;
              let serviceDate = null;

              // If pair with info box
              if (
                Object.prototype.hasOwnProperty.call(
                  source.services[j],
                  'infoBoxPairedWith'
                )
              ) {
                priceXInfoBox = true;
                useInfoBox = source.services[j].useInfoBox;
                infoBoxPairedWith = source.services[j].infoBoxPairedWith;
              }

              if (
                Object.prototype.hasOwnProperty.call(
                  source.services[j],
                  'hasDate'
                )
              ) {
                hasDate = true;
                serviceTs = source.services[j].serviceTs;
                serviceDate = date.formatDate(
                  source.services[j].serviceTs,
                  'YYYY/MM/DD'
                );
              }

              // If Settings have been updated and pair with info box has been added
              // the option to pair with info box needs to be added here
              if (!priceXInfoBox && settings.services[i].priceXInfoBox) {
                priceXInfoBox = true;
                useInfoBox = false;
                infoBoxPairedWith = settings.services[i].infoBoxPairedWith;
              }

              this.serviceCheckboxes.push(true);
              this.services.push({
                name: source.services[j].name,
                price: source.services[j].price.toFixed(2),
                complete: source.services[j].complete,
                completeOnMount: source.services[j].complete,
                completeTs: source.services[j].completeTs,
                priceXInfoBox,
                useInfoBox,
                infoBoxPairedWith,
                hasDate,
                serviceTs,
                serviceDate,
                dateColor: this.setDateColor(serviceTs)
              });
            }
          }
          if (serviceIncluded === false) {
            this.serviceCheckboxes.push(false);
            this.services.push({
              name: settings.services[i].name,
              price: settings.services[i].defaultPrice.toFixed(2),
              complete: false,
              completeOnMount: false,
              completeTs: null,
              priceXInfoBox: settings.services[i].priceXInfoBox,
              useInfoBox: false,
              infoBoxPairedWith: settings.services[i].infoBoxPairedWith,
              hasDate: false,
              serviceTs: null,
              serviceDate: null,
              dateColor: ''
            });
          }
        }

        // If service has been deleted from settings, we still want to add it here
        for (let i = 0; i < source.services.length; i++) {
          let match = false;
          for (let j = 0; j < this.services.length; j++) {
            if (source.services[i].name === this.services[j].name) {
              match = true;
            }
          }
          if (!match) {
            let priceXInfoBox = false;
            let useInfoBox = false;
            let infoBoxPairedWith = '';

            let hasDate = false;
            let serviceTs = null;
            let serviceDate = null;

            // If pair with info box
            if (
              Object.prototype.hasOwnProperty.call(
                source.services[i],
                'infoBoxPairedWith'
              )
            ) {
              priceXInfoBox = true;
              useInfoBox = source.services[i].useInfoBox;
              infoBoxPairedWith = source.services[i].infoBoxPairedWith;
            }

            if (
              Object.prototype.hasOwnProperty.call(
                source.services[i],
                'hasDate'
              )
            ) {
              hasDate = true;
              serviceTs = source.services[i].serviceTs;
              serviceDate = date.formatDate(
                source.services[i].serviceTs,
                'YYYY/MM/DD'
              );
            }

            this.serviceCheckboxes.push(true);
            this.services.push({
              name: source.services[i].name,
              price: source.services[i].price.toFixed(2),
              complete: source.services[i].complete,
              completeOnMount: source.services[i].complete,
              completeTs: source.services[i].completeTs,
              priceXInfoBox,
              useInfoBox,
              infoBoxPairedWith,
              hasDate,
              serviceTs,
              serviceDate,
              dateColor: this.setDateColor(serviceTs)
            });
          }
        }

        this.haulTrackers = [];
        for (let i = 0; i < userSettings.haulTrackers.length; i++) {
          let trackerIncluded = false;
          for (let j = 0; j < source.haulTrackerArr.length; j++) {
            if (
              userSettings.haulTrackers[i].name === source.haulTrackerArr[j]
            ) {
              trackerIncluded = true;
              this.trackerCheckboxes.push(true);
              this.haulTrackers.push(userSettings.haulTrackers[i].name);
            }
          }
          if (trackerIncluded === false) {
            this.trackerCheckboxes.push(false);
            this.haulTrackers.push(userSettings.haulTrackers[i].name);
          }
        }

        for (let i = 0; i < source.guestUsers.length; i++) {
          if (
            Object.prototype.hasOwnProperty.call(
              this.guestUserList.guestUserIds,
              source.guestUsers[i]
            )
          ) {
            this.guestUsersAllowed.push(
              this.guestUserList.guestUserIds[source.guestUsers[i]]
            );
          }
        }
      } else if (source.action === 'add') {
        this.sourceStatus.options.push({
          label: 'Estimate',
          value: 'Estimate',
          color: 'purple-13'
        });

        this.hasHauls = false;
        this.purchaseCommodity = settings.defaultPurchaseCommodity;
        this.commoditySelected = settings.defaultCommodity;
        this.pricePerUnit = settings.defaultPricePerUnit.toFixed(2);
        this.trackMileage = settings.defaultTrackMileage;
        this.mileageRate = settings.defaultMileageRate.toFixed(2);

        this.startDate = +new Date(new Date().setHours(0, 0, 0, 0));

        this.infoBoxes = [];

        for (let i = 0; i < this.userSourceSettings.infoBoxes.length; i++) {
          const infoBox = this.userSourceSettings.infoBoxes[i];
          this.infoBoxes.push({
            name: infoBox.name,
            model: null,
            userAuth: infoBox.userAuth
          });
        }

        this.customSelectors = [];
        // Custom selector templates are stored in userSourceSettings
        for (
          let i = 0;
          i < this.userSourceSettings.customSelectors.length;
          i++
        ) {
          const customSelector = this.userSourceSettings.customSelectors[i];
          const options = [];

          for (let j = 0; j < customSelector.options.length; j++) {
            const option = customSelector.options[j];

            options.push({
              label: option.option,
              value: option.option,
              color: option.optionColor
            });
          }

          this.customSelectors.push({
            name: customSelector.name,
            options: options,
            model: customSelector.options[0].option,
            userAuth: customSelector.userAuth
          });
        }

        this.services = [];
        for (let i = 0; i < settings.services.length; i++) {
          if (settings.services[i].defaultChecked === true) {
            this.serviceCheckboxes.push(true);
          } else {
            this.serviceCheckboxes.push(false);
          }

          this.services.push({
            name: settings.services[i].name,
            price: settings.services[i].defaultPrice.toFixed(2),
            complete: false,
            completeOnMount: false,
            completeTs: null,
            priceXInfoBox: settings.services[i].priceXInfoBox,
            useInfoBox: false,
            infoBoxPairedWith: settings.services[i].infoBoxPairedWith,
            hasDate: false,
            serviceTs: null,
            serviceDate: null
          });
        }

        this.haulTrackers = [];
        for (let i = 0; i < userSettings.haulTrackers.length; i++) {
          if (userSettings.haulTrackers[i].defaultChecked === true) {
            this.trackerCheckboxes.push(true);
          } else {
            this.trackerCheckboxes.push(false);
          }
          this.haulTrackers.push(userSettings.haulTrackers[i].name);
        }
      }
    },
    statusInfo() {
      this.ok = {
        display: true,
        icon: 'info',
        iconColor: 'primary',
        iconStyle: '',
        headerMessage: 'Status Info',
        message: `<li>Open - Source is currently active. </li><br>
          <li>Waiting - Source is waiting to become active. A good way to keep the source out of the
          Hauls Page / Enter Haul options. Will not show on map page (or anywhere else) for any of the User level users.</li><br>
          <li>Estimate - A good way to create an estimate that can be set to active later.</li>
          `
      };
    }
  },
  computed: {
    activeSources() {
      return JSON.parse(JSON.stringify(this.$store.state.activeSources));
    },
    commodities() {
      const commodities = this.$store.state.settingsUsers.commodities;
      const objArr = [];
      for (const key in commodities) {
        objArr.push({
          label: commodities[key].name,
          value: commodities[key].name
        });
      }

      return objArr;
    },
    filterLists() {
      return this.$store.state.filterLists;
    },
    guestUserList() {
      return JSON.parse(JSON.stringify(this.$store.state.guestUserList));
    },
    masterSources() {
      return JSON.parse(JSON.stringify(this.$store.state.masterSources));
    },
    measurementUnit() {
      let mU = '';
      const commodities = this.$store.state.settingsUsers.commodities;
      for (const key in commodities) {
        if (this.commoditySelected === commodities[key].name) {
          mU = commodities[key].measurementUnit;
        }
      }
      return mU;
    },
    modules() {
      return JSON.parse(
        JSON.stringify(
          // this needs to be changed when source modules are set up!!!
          this.$store.state.settingsUsers.sourceSettings.modules
        )
      );
    },
    percentHauled() {
      const pH = !this.projected
        ? 0
        : this.round(this.source.hauled / this.projected) * 100;
      return pH > 100 ? '100%' : pH + '%';
    },
    totalPrice() {
      let total = 0;

      if (this.purchaseCommodity) {
        total += this.pricePerUnit * this.hauled;
      }

      for (let i = 0; i < this.services.length; i++) {
        const service = this.services[i];
        if (!this.serviceCheckboxes[i]) {
          continue;
        }

        if (service.useInfoBox) {
          const infoBoxPairedWith = service.infoBoxPairedWith;
          const infoBoxVal = this.infoBoxes.find(infoBox => {
            if (infoBox.name === infoBoxPairedWith) {
              return true;
            }
          });

          service.infoBoxVal = infoBoxVal ? infoBoxVal.model : 0;
          total -= service.price * service.infoBoxVal;
        } else {
          total -= service.price * this.source.hauled;
        }
      }

      return total;
    },
    serviceTripDates() {
      const tripDates = JSON.parse(
        JSON.stringify(
          this.$store.state.settingsUsers.sourceSettings.serviceTripDates
        )
      );

      const now = +new Date();
      const tripA = tripDates.tripDateA * 86400000;
      const tripB = tripDates.tripDateB * 86400000;

      return { now, tripA, tripB };
    },
    userSourceSettings() {
      return JSON.parse(
        JSON.stringify(this.$store.state.settingsUsers.sourceSettings)
      );
    }
  },
  watch: {
    '$store.state.masterSources'() {
      this.setSourceLocations();
      this.setSourceSettings();
    },
    source() {
      this.setSourceSettings();
    }
  }
};
</script>

<style scoped></style>
