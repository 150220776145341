<template>
  <div class="outer-table-div">
    <div class="row justify-center items-center full-width q-mt-md">
      <div class="customer-row">
        <div class="row justify-center text-h5 q-mb-sm">Select Customer</div>
        <q-card bordered flat>
          <div class="row full-width q-pa-md q-mb-sm">
            <q-space />
            <q-input
              v-model="searchStr"
              outlined
              dense
              style="width: 300px"
              @input="searchByStr"
            >
              <template v-slot:append>
                <q-icon name="search" />
              </template>
            </q-input>
          </div>

          <div
            v-if="customerListFiltered.length < 1 && customers.length"
            class="row justify-center items-center full-width"
          >
            <div
              class="text-grey-4 row justify-center col-12"
              style="font-size: 50px; margin-top: 50px"
            >
              ( ^ _ ^ )
            </div>
            <div
              class="text-center text-grey-5 q-pa-lg"
              style="font-size: 30px; margin-top: 20px; margin-bottom: 50px"
            >
              No customers match your search
            </div>
          </div>

          <div
            v-if="customers.length < 1"
            class="row justify-center items-center full-width"
          >
            <div
              class="text-grey-4 row justify-center col-12"
              style="font-size: 50px; margin-top: 50px"
            >
              ( ^ _ ^ )
            </div>
            <div
              class="text-center text-grey-5 q-pa-lg"
              style="font-size: 30px; margin-top: 20px; margin-bottom: 50px"
            >
              No customers to display
            </div>
          </div>

          <q-virtual-scroll
            class="q-pb-lg"
            :style="containerHeight"
            :items="customerListFiltered"
            separator
          >
            <template v-slot="{ item, index }">
              <q-item
                :key="index"
                dense
                clickable
                v-ripple
                @click="selectCustomer(item)"
              >
                <q-item-section avatar>
                  <q-avatar
                    :color="item.color"
                    size="sm"
                    text-color="white text-bold"
                  >
                    {{ item.name.charAt(0) }}
                  </q-avatar>
                </q-item-section>

                <q-item-section>
                  {{ item.name }}
                </q-item-section>
              </q-item>
            </template>
          </q-virtual-scroll>
        </q-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomerList',
  props: {
    customers: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      customerListFiltered: [],
      containerHeight: 'max-height: 300px',
      searchStr: ''
    };
  },
  mounted() {
    this.setCustomers();
    this.setContainerHeight();
  },
  methods: {
    async setContainerHeight() {
      await this.$nextTick();

      let height =
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 250;

      this.containerHeight = `max-height: ${height}px`;
    },
    cancel() {
      this.$emit('onCancel');
    },
    searchByStr(searchStr) {
      this.customerListFiltered = [];

      if (searchStr === '') {
        this.setCustomers();
      } else {
        for (let i = 0; i < this.customers.length; i++) {
          if (
            this.customers[i].name
              .toUpperCase()
              .includes(searchStr.toUpperCase())
          ) {
            this.customerListFiltered.push(this.customers[i]);
          }
        }
      }
    },
    selectCustomer(index) {
      this.$emit('onSelectCustomer', index);
    },
    setCustomers() {
      this.search = '';
      this.searchStr = '';
      this.customerListFiltered = this.customers.slice();
    }
  },
  watch: {
    customers() {
      this.setCustomers();
    }
  }
};
</script>

<style scoped>
.customer-row {
  width: 470px;
}

@media all and (max-width: 1405px) {
  .customer-row {
    width: 370px;
  }
}

@media all and (max-width: 410px) {
  .customer-row {
    width: 94%;
  }
}
</style>
