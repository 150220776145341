<template>
  <div class="outer-table-div">
    <ReportContainer
      v-if="currentDisplay === 'sourceReport'"
      :reportId="reportId"
      :reportType="reportType"
      @onCancel="onCancelView"
    />

    <q-linear-progress v-if="sourceRendering" query color="primary" />
    <div
      v-if="!allSources.length && !sourceRendering && !emptyListDelay"
      class="row justify-center q-pb-sm"
    >
      <div class="row justify-center full-width q-py-lg text-h4 text-primary">
        Source List Is Empty
      </div>
    </div>

    <div
      v-if="allSources.length >= 36 && !sourceRendering"
      class="row full-width q-pt-md"
    >
      <q-space />
      <div class="q-mr-md" style="width: 270px">
        <q-input
          v-model="searchStr"
          outlined
          dense
          label="Search Customers"
          label-color="primary"
          @input="searchByStr"
        >
        </q-input>
      </div>
    </div>

    <div class="column q-ma-sm" :style="sourceHeight">
      <q-card
        v-for="(source, index) in activeSources"
        :key="index"
        :id="source.id + '-card'"
        flat
        bordered
        class="q-ma-sm"
        :style="`width: ${itemWidth + 198}px;`"
      >
        <div
          :id="source.id"
          style="position: absolute; top: -100px; left: 0"
        ></div>

        <div class="row q-pa-sm">
          <div class="row justify-center items-center q-pr-sm">
            <div
              class="row justify-center items-center bg-custom-light rounded-borders text-h5"
              style="width: 44px; height: 44px; position: relative"
            >
              <q-badge
                v-if="source.minServiceTs"
                floating
                rounded
                style="z-index: 7; border: 1px solid black"
                :color="dateTripColor(source.minServiceTs).color"
              >
                <q-icon name="today" color="black" />
              </q-badge>
              <span style="z-index: 2">
                {{ source.customer.charAt(0) }}
              </span>
              <div
                class="row items-center justify-center bg-custom-light-blue"
                :style="`position: absolute; bottom: 0; width: 44px;
                  border-bottom-left-radius: 4px;
                  border-bottom-right-radius: 4px;
                  border-top-right-radius: ${
                    source.percentHauled === '100%' ? 4 : 0
                  }px;
                  border-top-left-radius: ${
                    source.percentHauled === '100%' ? 4 : 0
                  }px;
                  border-bottom-top-radius: 4px;
                  height: ${source.percentHauled}`"
              ></div>
            </div>
          </div>

          <div :style="`width: ${itemWidth}px`">
            <div style="height: 27px; padding-top: 0px" class="row ellipsis">
              <div class="ellipsis">
                {{ source.customer }}
              </div>
            </div>
            <div
              style="height: 18px"
              class="row items-center text-caption text-custom-medium no-wrap ellipsis"
            >
              <q-icon
                v-if="
                  source.isDispatched &&
                  modules.dispatch &&
                  !source.servicesComplete
                "
                name="person"
                color="custom-orange"
                size="26px"
                class="q-mr-sm"
              />
              <q-avatar
                rounded
                size="xs"
                :color="source.servicesComplete ? 'custom-red' : 'custom-light'"
                text-color="white"
                icon="done_outline"
                class="q-mr-xs"
              />
              {{ source.sourceName }}
            </div>
          </div>

          <div
            class="row items-center justify-end q-pl-sm"
            style="width: 128px; height: 44px"
          >
            <div>
              <q-btn
                unelevated
                :icon="source.location.lat ? 'place' : 'location_off'"
                color="primary"
                :text-color="source.location.lat ? 'white' : 'custom-red'"
                size="sm"
                class="q-mx-xs"
                padding="xs sm"
                @click="mapPin(source)"
              />
              <q-btn
                unelevated
                icon="view_list"
                color="primary"
                text-color="white"
                size="sm"
                class="q-mx-xs"
                padding="xs sm"
                @click="viewSource(index, source)"
              />
              <q-btn
                v-if="!isGuestCustomer"
                unelevated
                icon="edit"
                color="primary"
                text-color="white"
                size="sm"
                class="q-ml-xs"
                padding="xs sm"
                @click="editSource(index, source)"
              />
            </div>
            <div class="row full-width text-caption text-custom-medium q-pt-xs">
              <q-space />
              <div class="ellipsis">
                {{ Math.round(source.hauled) }} /
                {{ Math.round(source.projected) || '--' }}
                {{ source.measurementUnit }}
              </div>
            </div>
          </div>

          <div v-if="sourceInputSelected !== ''" class="full-width q-mt-sm">
            <q-separator />

            <div
              v-if="sourceInputSelected === 'note'"
              class="row full-width text-custom-medium ellipsis no-wrap q-pt-xs"
            >
              Note
              <q-space />
              <span class="ellipsis q-ml-md">
                {{ source.note }}
              </span>
            </div>

            <div
              v-if="sourceInputSelected === 'startDate'"
              class="row full-width text-custom-medium ellipsis no-wrap q-pt-xs"
            >
              Start Date
              <q-space />
              <span class="ellipsis q-ml-md">
                {{ source.startDateFormatted }}
              </span>
            </div>

            <div
              v-if="sourceInputSelected === 'scheduled'"
              class="row full-width text-custom-medium ellipsis no-wrap q-pt-xs"
            >
              Scheduled
              <q-space />
              <span class="text-custom-red ellipsis q-ml-md">
                {{ source.scheduledFormatted }}
              </span>
            </div>

            <div
              v-if="sourceInputSelected === 'dispatchedTo'"
              class="row full-width text-custom-medium ellipsis no-wrap q-pt-xs"
            >
              Dispatched To
              <q-space />
              <span class="text-custom-red ellipsis q-ml-md">
                {{ source.dispatchedToStr }}
              </span>
            </div>

            <div
              v-if="sourceInputSelected === 'attachment'"
              class="row full-width text-custom-medium q-pt-xs"
            >
              Attachments
              <q-space />
              <q-icon
                v-if="source.location.attachmentId"
                name="image"
                size="20px"
                color="custom-orange"
                text-color="white"
              />
            </div>

            <div
              v-if="sourceInputSelected === 'commodity'"
              class="row full-width text-custom-medium ellipsis no-wrap q-pt-xs"
            >
              Commodity
              <q-space />
              <span class="text-custom-red ellipsis q-ml-md">
                {{ source.commodity }}
              </span>
            </div>

            <div
              v-if="sourceInputSelected === 'haulTrackers'"
              class="row full-width text-custom-medium ellipsis no-wrap q-pt-xs"
            >
              Haul Trackers
              <q-space />
              <span class="text-custom-red ellipsis q-ml-md">
                {{ source.trackerStr }}
              </span>
            </div>

            <div
              v-if="sourceInputSelected === 'trackMileage'"
              class="row full-width text-custom-medium q-pt-xs"
            >
              Track Mileage
              <q-space />
              <q-icon
                v-if="source.trackMileage"
                name="add_road"
                size="20px"
                color="custom-orange"
                text-color="white"
              />
            </div>

            <div
              v-if="sourceInputSelected === 'services'"
              class="row full-width text-custom-medium ellipsis no-wrap q-pt-xs"
            >
              Services
              <q-space />
              <span class="text-custom-red ellipsis q-ml-md">
                {{ source.serviceStr }}
              </span>
            </div>

            <div
              v-for="(infoBox, index) in source.infoBoxes"
              :key="index + infoBox.name"
            >
              <div
                v-if="sourceInputSelected === infoBox.name"
                class="row full-width text-custom-medium ellipsis no-wrap q-pt-xs"
              >
                {{ infoBox.name }}
                <q-space />
                <span class="text-custom-red ellipsis q-ml-md">
                  {{ infoBox.model }}
                </span>
              </div>
            </div>

            <div
              v-for="(customSelector, index) in source.customSelectors"
              :key="index + customSelector.name"
            >
              <div
                v-if="sourceInputSelected === customSelector.name"
                class="row full-width text-custom-medium ellipsis no-wrap q-pt-xs"
              >
                {{ customSelector.name }}
                <q-space />
                <q-icon
                  name="radio_button_checked"
                  size="20px"
                  :color="customSelector.color"
                  text-color="white"
                />
                <span class="ellipsis q-ml-sm">
                  {{ customSelector.model }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </q-card>

      <!--layout fix when only 2 show-->
      <div
        v-if="activeSources.length === 2 || activeSources.length === 4"
        class="q-pa-md q-ma-sm"
        :style="`width: ${itemWidth + 196}px;`"
      ></div>
    </div>
  </div>
</template>

<script>
import ReportContainer from '@/components/ReportContainer.vue';
import { sourceReportUsers } from '@/utils/ReportHelpers';
import { dateTripColor } from '@/utils/UtilHelpers.js';
import { customRoutes } from '@/custom/Routes.js';

export default {
  name: 'SourceTableUsers',
  components: {
    ReportContainer
  },
  props: {
    sourceInputs: {
      type: Object,
      required: true
    },
    sourceInputSelected: {
      type: String,
      required: true
    },
    sourceSortBy: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activeSources: [],
      allSources: [],
      currentDisplay: 'overview',
      emptyListDelay: true,
      headerWidth: 0,
      itemWidth: 200,
      reportRaw: {},
      reportType: 'source',
      sourceRendering: true,
      searchStr: '',
      updater: {
        index: 0,
        source: {}
      }
    };
  },
  mounted() {
    window.scrollTo(0, 0);

    // For some reason this allows the page to load immediately with
    // rendering loader. nextTick does not work for this
    setTimeout(() => {
      this.setSources();
    }, 3);

    setTimeout(() => {
      this.emptyListDelay = false;
    }, 1000);

    this.$nextTick(() => {
      this.headerWidth = document.getElementById('main-header').offsetWidth;

      window.addEventListener('resize', this.setItemWidth);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setItemWidth);
  },
  methods: {
    addId(id) {
      this.updater.source.id = id;
    },
    dateTripColor(serviceTs) {
      return dateTripColor(this.serviceTripDates, serviceTs);
    },
    highlightSource() {
      if (this.updater.action === 'add') {
        // Need to wait until this has updated in the DB
        setTimeout(() => {
          const index = this.activeSources.findIndex(source => {
            return source.id === this.updater.source.id;
          });

          this.updater.index = index;
        }, 300);
      }

      setTimeout(() => {
        const activeSourcesUsers = JSON.parse(
          JSON.stringify(this.$store.state.activeSourcesUsers)
        );

        const source = activeSourcesUsers.find(source => {
          return source.id === this.updater.source.id;
        });

        if (!source || this.updater.action === 'delete') {
          return;
        }

        let highLightTime = 1000;
        const length = activeSourcesUsers.length;

        if (this.$q.screen.lt.sm) {
          if (this.updater.index > 20) {
            highLightTime = 1900;
          }
        } else if (this.$q.screen.lt.lg) {
          const addOne = length % 2 > 0 ? 1 : 0;
          let sectionLength = Math.floor(length / 2) + addOne;
          if (this.updater.index % sectionLength > 20) {
            highLightTime = 1900;
          }
        } else {
          const addOne = length % 3 > 0 ? 1 : 0;
          let sectionLength = Math.floor(length / 2) + addOne;
          if (this.updater.index % sectionLength > 20) {
            highLightTime = 1900;
          }
        }

        this.activeSources[this.updater.index].sourceLocationId =
          source.sourceLocationId;
        this.activeSources[this.updater.index].sourceName =
          this.masterSources[source.sourceLocationId].sourceName;
        this.activeSources[this.updater.index].projected = source.projected;

        // Don't scroll if action is view
        if (this.updater.action === 'edit') {
          document
            .getElementById(source.id)
            .scrollIntoView({ behavior: 'smooth' });
        }

        const el = document.getElementById(source.id + '-card');
        if (el) {
          el.style.background = '#8b9474';
        }

        setTimeout(() => {
          const el = document.getElementById(source.id + '-card');
          if (el) {
            el.style.background = 'white';
          }
        }, highLightTime);
      }, 300);
    },
    searchByStr() {
      this.sources = [];

      if (this.searchStr === '') {
        this.activeSources = this.allSources.slice();
      } else {
        this.activeSources = [];
        for (let i = 0; i < this.allSources.length; i++) {
          if (
            this.customers[this.allSources[i].customerId].name
              .toUpperCase()
              .includes(this.searchStr.toUpperCase())
          ) {
            this.activeSources.push(this.allSources[i]);
          }
        }
      }

      this.$nextTick(() => {
        this.setItemWidth();
      });
    },
    setSources() {
      const sources = [];
      const activeSourcesUsers = JSON.parse(
        JSON.stringify(this.$store.state.activeSourcesUsers)
      );

      for (let i = 0; i < activeSourcesUsers.length; i++) {
        const sourceInfo = sourceReportUsers(activeSourcesUsers[i].id);

        sources.push(sourceInfo);
      }

      this.sortSources(sources);

      this.allSources = [];
      this.activeSources = [];
      this.activeSources = sources.slice();
      this.allSources = sources.slice();
      this.sourceRendering = false;

      this.$nextTick(() => {
        this.setItemWidth();
      });
    },
    setItemWidth() {
      const headerWidth = document.getElementById('main-header').offsetWidth;

      let cols = 3;
      let gutterAdjustment = 0;
      if (this.$q.screen.lt.sm) {
        cols = 1;
        gutterAdjustment = 8;
      } else if (this.$q.screen.lt.lg) {
        cols = 2;
        gutterAdjustment = 4;
      }

      this.itemWidth = headerWidth / cols - 219 - gutterAdjustment;
    },
    sortSources(sources) {
      let sortA = -1;
      let sortB = 1;

      let sortBy = this.sourceSortBy.value;

      if (this.sourceSortBy.reverseSort && sortBy === 'alpha') {
        sortA = 1;
        sortB = -1;
      }

      const sortByCustomer = (a, b) => {
        const sortByA = this.customers[a.customerId].name + a.sourceName;
        const sortByB = this.customers[b.customerId].name + b.sourceName;
        if (sortByA < sortByB) {
          return sortA;
        }
        if (sortByA > sortByB) {
          return sortB;
        }
        return 0;
      };

      sources.sort(sortByCustomer);

      sortA = this.sourceSortBy.reverseSort ? 1 : -1;
      sortB = this.sourceSortBy.reverseSort ? -1 : 1;

      const sortByInfoBoxes = (a, b) => {
        const indexA = a.infoBoxes.findIndex(x => x.name === sortBy);
        const indexB = b.infoBoxes.findIndex(x => x.name === sortBy);

        if (!a.infoBoxes[indexA].model && !b.infoBoxes[indexB].model) {
          return 0;
        } else if (!a.infoBoxes[indexA].model) {
          return sortA;
        } else if (!b.infoBoxes[indexB].model) {
          return sortB;
        } else if (a.infoBoxes[indexA].model < b.infoBoxes[indexB].model) {
          return sortA;
        } else if (a.infoBoxes[indexA].model > b.infoBoxes[indexB].model) {
          return sortB;
        }
        return 0;
      };

      const sortByCustomSelectors = (a, b) => {
        const indexA = a.customSelectors.findIndex(x => x.name === sortBy);
        const indexB = b.customSelectors.findIndex(x => x.name === sortBy);

        if (indexA < 0 && indexB < 0) {
          return 0;
        } else if (indexA < 0) {
          return sortB;
        } else if (indexB < 0) {
          return sortA;
        }

        if (!a.customSelectors[indexA].model) {
          return sortB;
        } else if (!b.customSelectors[indexB].model) {
          return sortA;
        } else if (
          a.customSelectors[indexA].model > b.customSelectors[indexB].model
        ) {
          return sortB;
        } else if (
          a.customSelectors[indexA].model < b.customSelectors[indexB].model
        ) {
          return sortA;
        }
        return 0;
      };

      const sortBySelectorOption = (a, b) => {
        const indexA = a.customSelectors.findIndex(x => x.model === sortBy);
        const indexB = b.customSelectors.findIndex(x => x.model === sortBy);

        if (indexA < 0 && indexB < 0) {
          return 0;
        } else if (indexA < 0) {
          return sortB;
        } else if (indexB < 0) {
          return sortA;
        }

        if (
          a.customSelectors[indexA].model === b.customSelectors[indexB].model
        ) {
          return 0;
        } else if (a.customSelectors[indexA].model === sortBy) {
          return sortA;
        }
        return 0;
      };

      const sortByNum = (a, b) => {
        // Have to deal with projected set to ''
        if (a[sortBy] === '' && b[sortBy] !== '') {
          return sortA;
        } else if (a[sortBy] !== '' && b[sortBy] === '') {
          return sortB;
        } else if (a[sortBy] < b[sortBy]) {
          return sortA;
        } else if (a[sortBy] > b[sortBy]) {
          return sortB;
        }
        return 0;
      };

      const sortByScheduled = (a, b) => {
        if (!a[sortBy] && !b[sortBy]) {
          return 0;
        }
        if (a[sortBy] && !b[sortBy]) {
          return sortA;
        }
        if (!a[sortBy] && b[sortBy]) {
          return sortB;
        }
        if (a[sortBy] > b[sortBy]) {
          return sortB;
        }
        if (a[sortBy] < b[sortBy]) {
          return sortA;
        }
        return 0;
      };

      const sortByBoolean = (a, b) => {
        if (a[sortBy] && !b[sortBy]) {
          return sortA;
        } else if (!a[sortBy] && b[sortBy]) {
          return sortB;
        }
        return 0;
      };

      if (this.sourceSortBy.type === 'number') {
        sources.sort(sortByNum);
      } else if (this.sourceSortBy.type === 'boolean') {
        sources.sort(sortByBoolean);
      } else if (this.sourceSortBy.type === 'scheduled') {
        sources.sort(sortByScheduled);
      } else if (this.sourceSortBy.type === 'infoBox') {
        sources.sort(sortByInfoBoxes);
      } else if (this.sourceSortBy.type.startsWith('customSelector')) {
        const selectorArr = this.sourceSortBy.type.split('###');

        const temp = sortBy;

        sortBy = selectorArr[1];

        // Sort by customSelector name
        sources.sort(sortByCustomSelectors);
        sortBy = temp;

        // Then sort by customSelector option
        sources.sort(sortBySelectorOption);
      }
    },
    editSource(index, source) {
      this.updater = {
        index,
        source,
        action: 'edit'
      };

      this.$emit('onEdit', source);
    },
    mapPin(source) {
      if (source.location.lat) {
        const mapPin = {
          type: 'source',
          id: source.locationId
        };

        this.$store.dispatch('setMapPin', mapPin);

        const custMap = customRoutes.find(route => route.name === 'Map');
        if (custMap) {
          this.$router.push({ path: custMap.path });
        } else {
          this.$router.push({ path: 'map' });
        }
      }
    },
    onCancelView() {
      document.body.style.overflow = 'auto';
      document.body.style.height = '';

      this.currentDisplay = 'sourceTable';
      this.highlightSource();
    },
    viewSource(index, source) {
      this.reportId = source.id;
      this.currentDisplay = 'sourceReport';

      document.body.style.overflow = 'hidden';
      document.body.style.height = '100vh';

      this.updater = {
        index,
        source,
        action: 'view'
      };
    }
  },
  computed: {
    customers() {
      return this.$store.state.customers;
    },
    isGuestCustomer() {
      return (
        this.$store.state.user.superCustomer || this.$store.state.user.customer
      );
    },
    masterSources() {
      return this.$store.state.masterSources;
    },
    modules() {
      return JSON.parse(
        JSON.stringify(this.$store.state.settingsUsers.sourceSettings.modules)
      );
    },
    sourceHeight() {
      const sourceLength = this.activeSources.length;
      const cardHeight = this.sourceInputSelected === '' ? 80 : 113;
      if (this.$q.screen.lt.sm) {
        return `height: ${sourceLength * cardHeight}px`;
      } else if (this.$q.screen.lt.lg) {
        const addOne = sourceLength % 2 > 0 ? cardHeight : 0;
        return `height: ${
          Math.floor(sourceLength / 2) * cardHeight + addOne
        }px`;
      } else {
        const addOne = sourceLength % 3 > 0 ? cardHeight : 0;
        return `height: ${
          Math.floor(sourceLength / 3) * cardHeight + addOne
        }px`;
      }
    },
    serviceTripDates() {
      const tripDates = JSON.parse(
        JSON.stringify(
          this.$store.state.settingsUsers.sourceSettings.serviceTripDates
        )
      );

      const now = +new Date();
      const tripA = tripDates.tripDateA * 86400000;
      const tripB = tripDates.tripDateB * 86400000;

      return { now, tripA, tripB };
    }
  },
  watch: {
    '$store.state.activeSourcesUsers'() {
      this.setSources();
    }
  }
};
</script>

<style scoped></style>
