<template>
  <div class="outer-table-div q-mt-md q-mb-xl">
    <q-linear-progress v-if="loading" query color="primary" />

    <OkDialog
      v-if="okDialog"
      icon="warning"
      iconColor="custom-red"
      iconStyle=""
      :message="okMessage"
      @okConfirmed="okDialog = false"
    />

    <ConfirmDelete
      v-if="confirmDelete"
      :haul="haul"
      @canceled="confirmDelete = false"
      @confirmed="deleteHaul"
    />

    <div v-for="day in haulList" :key="day.date">
      <div
        class="row items-center rounded-borders text-bold text-body1 text-primary cursor-pointer q-mx-md q-pa-xs"
        style="border: 1px solid #0b0d6f"
        @click="day.expand = !day.expand"
      >
        <div class="q-mr-md q-ml-sm">
          <q-icon name="event" size="sm" />
        </div>
        {{ day.date }}
        <q-space />
        <div class="q-mr-sm">
          <q-icon v-if="!day.expand" name="expand_more" size="sm" />
          <q-icon v-if="day.expand" name="expand_less" size="sm" />
        </div>
      </div>

      <div v-if="!day.expand" class="q-mb-md"></div>

      <div
        v-if="day.expand && !day.hauls.length"
        class="row justify-center text-h6 text-primary q-mt-lg q-mb-md"
      >
        No Hauls for {{ day.date }}
      </div>

      <div v-if="day.expand" class="outer-table-div row q-pa-sm">
        <div
          v-for="(haul, index) in day.hauls"
          :key="haul.dateTime + index"
          class="q-pa-sm col-xs-12 col-sm-6 col-md-6 col-lg-4"
        >
          <q-card class="q-pa-md">
            <div class="row">
              <div class="q-ml-sm word-wrap" style="width: 41%">
                <div class="text-primary text-bold">
                  {{ customers[haul.sourceCustomerId].name }}
                </div>
                <div class="text-custom-medium text-bold">
                  {{ masterSources[haul.sourceLocationId].sourceName }}
                </div>
              </div>
              <q-space />
              <q-icon
                class="self-center"
                name="forward"
                color="primary"
                size="sm"
              />
              <q-space />
              <div class="q-mr-sm word-wrap" style="width: 41%">
                <div class="text-primary text-right text-bold">
                  {{ customers[haul.destinationCustomerId].name }}
                </div>
                <div class="text-custom-medium text-bold text-right">
                  {{ setDestination(haul) }}
                </div>
              </div>
            </div>
            <q-separator class="q-mx-sm" />

            <div :style="`height: ${day.height}px`">
              <div class="row q-mt-sm">
                <span class="q-ml-md">Amount</span>
                <q-space />
                <span class="q-mr-sm text-black text-bold">
                  {{ haul.haulAmount.toLocaleString('en-US') }}
                  {{ haul.measurementUnit }}
                </span>
              </div>
              <div v-if="haul.mileage" class="row">
                <span class="q-ml-md">Mileage</span>
                <q-space />
                <span class="q-mr-sm text-custom-medium">
                  {{ haul.mileage.toLocaleString('en-US') }} Miles
                </span>
              </div>
              <div class="row">
                <span class="q-ml-md">Date</span>
                <q-space />
                <span class="q-mr-sm text-custom-medium">
                  {{ new Date(haul.dateTime).toDateString() }}
                </span>
              </div>
              <div class="row">
                <span class="q-ml-md">Time</span>
                <q-space />
                <span class="q-mr-sm text-custom-medium">
                  {{ new Date(haul.dateTime).toLocaleTimeString() }}
                </span>
              </div>
              <div class="row">
                <span class="q-ml-md">Driver</span>
                <q-space />
                <span class="q-mr-sm text-custom-medium">
                  {{ userList.userIds[haul.driver] || '(Deleted)' }}
                </span>
              </div>
              <div
                v-for="tracker in haul.sourceHaulTrackers"
                :key="tracker.type"
                class="row"
              >
                <span class="q-ml-md">
                  {{ tracker.type }}
                </span>
                <q-space />
                <span class="q-mr-sm text-custom-medium">
                  {{ tracker.option }}
                </span>
              </div>
              <div
                v-for="tracker in haul.destinationHaulTrackers"
                :key="tracker.type"
                class="row"
              >
                <span class="q-ml-md">
                  {{ trackerLabel(tracker.type) }}
                </span>
                <q-space />
                <span class="q-mr-sm text-custom-medium">
                  {{ tracker.option }}
                </span>
              </div>
              <div v-if="haul.haulNote" class="row q-ml-md">
                <div>Haul Note</div>
                <q-space />
                <div class="text-custom-medium q-ml-md q-mr-sm">
                  {{ haul.haulNote }}
                </div>
              </div>
            </div>
            <div class="row">
              <q-space />
              <div v-if="haul.canEdit" class="q-mr-sm q-mt-sm">
                <q-btn
                  color="custom-orange"
                  size="sm"
                  icon="edit"
                  class="cursor-pointer"
                  @click="editHaul(haul)"
                />
                <q-btn
                  color="custom-red"
                  size="sm"
                  icon="delete"
                  class="q-ml-sm cursor-pointer"
                  @click="deleteConfirm(haul)"
                />
              </div>
              <div v-if="!haul.canEdit" class="q-mr-sm q-mt-sm">
                <q-btn
                  color="custom-light"
                  size="sm"
                  icon="edit"
                  class="cursor-pointer"
                />
                <q-btn
                  color="custom-light"
                  size="sm"
                  icon="delete"
                  class="q-ml-sm cursor-pointer"
                />
              </div>
            </div>
          </q-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { date } from 'quasar';
import ConfirmDelete from '@/components/hauls/ConfirmDelete.vue';
import OkDialog from '@/components/OkDialog.vue';

const db = firebase.firestore();

export default {
  name: 'ListHauls',
  components: {
    ConfirmDelete,
    OkDialog
  },
  data() {
    return {
      confirmDelete: false,
      displayEnterHaul: true,
      haulList: [],
      haul: {},
      loading: false,
      laterHauls: [],
      laterHaulsAdded: false,
      okDialog: false,
      okMessage: '',
      sixDA: '',
      todayHauls: {},
      todayHaulsDate: {},
      yesterdayHauls: {},
      yesterdayHaulsDate: {},
      user: null
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.setHaulList();
    this.setUser();
  },
  methods: {
    deleteConfirm(haul) {
      this.confirmDelete = true;
      this.haul = haul;
    },
    deleteHaul() {
      this.confirmDelete = false;

      const haulId = this.haul.haulId;

      const haulCopy = JSON.parse(JSON.stringify(this.haul));

      let haul = {
        haulId: haulCopy.haulId,
        dateTime: haulCopy.dateTime,
        destinationId: haulCopy.destinationId,
        destinationCustomerId: haulCopy.destinationCustomerId,
        destinationLocationId: haulCopy.destinationLocationId,
        sourceAsDestination: haulCopy.sourceAsDestination,
        destinationHaulTrackers: haulCopy.destinationHaulTrackers,
        driver: haulCopy.driver,
        runningBalance: haulCopy.runningBalance,
        haulNote: haulCopy.haulNote,
        sourceId: haulCopy.sourceId,
        sourceCustomerId: haulCopy.sourceCustomerId,
        sourceLocationId: haulCopy.sourceLocationId,
        sourceHaulTrackers: haulCopy.sourceHaulTrackers,
        haulAmount: haulCopy.haulAmount,
        mileage: haulCopy.mileage
      };

      const batch = db.batch();

      const sourceDoc = db
        .collection('sources_active_users')
        .doc(haul.sourceId);
      const destDoc = db
        .collection('destinations_active_users')
        .doc(haul.destinationId);
      const destRunningDoc = db
        .collection('sources_active_users')
        .doc(haul.destinationId);

      if (haulCopy.runningBalance.sourceRunning) {
        batch.update(sourceDoc, {
          [`hauls.${haulId}`]: firebase.firestore.FieldValue.delete(),
          runningBalance: firebase.firestore.FieldValue.increment(
            haulCopy.haulAmount
          )
        });
      } else {
        batch.update(sourceDoc, {
          [`hauls.${haulId}`]: firebase.firestore.FieldValue.delete()
        });
      }

      if (haulCopy.runningBalance.destinationRunning) {
        // convert to negative then increment
        batch.update(destRunningDoc, {
          [`hauls.${haulId}`]: firebase.firestore.FieldValue.delete(),
          runningBalance: firebase.firestore.FieldValue.increment(
            -Math.abs(haulCopy.haulAmount)
          )
        });
      } else {
        batch.update(destDoc, {
          [`hauls.${haulId}`]: firebase.firestore.FieldValue.delete()
        });
      }

      batch
        .commit()
        .then(() => {})
        .catch(err => {
          console.log(err);
        });

      this.laterHaulsAdded = false;

      this.$q.notify({
        color: 'custom-red',
        textColor: 'white',
        icon: 'local_shipping',
        message: 'Haul Deleted'
      });
    },
    editHaul(haul) {
      this.$emit('onEditHaul', { edit: true, haul: haul });
    },
    setDestination(haul) {
      // This is for hauls TO sources that track running balance
      if (!haul.sourceAsDestination) {
        return this.masterDestinations[haul.destinationLocationId]
          .destinationName;
      } else {
        return this.masterSources[haul.destinationLocationId].sourceName;
      }
    },
    async setHaulList() {
      if (!this.user) {
        return;
      }

      this.haulList = [];
      const activeSourcesUsers = JSON.parse(
        JSON.stringify(this.$store.state.activeSourcesUsers)
      );
      let activeDestinationsUsers = JSON.parse(
        JSON.stringify(this.$store.state.activeDestinationsUsers)
      );
      let archivedSourcesUsers = JSON.parse(
        JSON.stringify(this.$store.state.archivedSourcesUsers)
      );

      const destHauls = [];
      for (let i = 0; i < activeDestinationsUsers.length; i++) {
        for (const key in activeDestinationsUsers[i].hauls) {
          const commodity = activeDestinationsUsers[i].commodity;

          let measurementUnit = '(deleted)';
          for (const key in this.settingsUsers.commodities) {
            if (commodity === key) {
              measurementUnit =
                this.settingsUsers.commodities[key].measurementUnit;
            }
          }
          destHauls.push({
            ...activeDestinationsUsers[i].hauls[key],
            measurementUnit,
            readyForSettlement: activeDestinationsUsers[i].endDate
              ? true
              : false
          });
        }
      }

      const sourceHauls = [];
      for (let i = 0; i < activeSourcesUsers.length; i++) {
        for (const key in activeSourcesUsers[i].hauls) {
          const customerId = activeSourcesUsers[i].customerId;
          const sourceLocationId = activeSourcesUsers[i].sourceLocationId;
          // Do not add haul to list if trackRunning balance was used and destination was entered as a source
          if (
            customerId === activeSourcesUsers[i].hauls[key].sourceCustomerId &&
            sourceLocationId ===
              activeSourcesUsers[i].hauls[key].sourceLocationId
          ) {
            const commodity = activeSourcesUsers[i].commodity;

            let measurementUnit = '(deleted)';
            for (const key in this.settingsUsers.commodities) {
              if (commodity === key) {
                measurementUnit =
                  this.settingsUsers.commodities[key].measurementUnit;
              }
            }

            sourceHauls.push({
              ...activeSourcesUsers[i].hauls[key],
              measurementUnit,
              readyForSettlement: activeSourcesUsers[i].endDate ? true : false
            });
          }
        }
      }

      let hauls = [];
      for (let i = 0; i < sourceHauls.length; i++) {
        const haul = sourceHauls[i];
        let canEdit = false;
        if (!haul.sourceAsDestination) {
          for (let j = 0; j < destHauls.length; j++) {
            if (destHauls[j].dateTime !== haul.dateTime) {
              continue;
            }

            if (
              destHauls[j].destinationCustomerId ===
                haul.destinationCustomerId &&
              destHauls[j].destinationLocationId ===
                haul.destinationLocationId &&
              destHauls[j].sourceCustomerId === haul.sourceCustomerId &&
              destHauls[j].sourceName === haul.sourceName &&
              destHauls[j].dateTime === haul.dateTime &&
              destHauls[j].driver === haul.driver
            ) {
              if (
                this.user.displayName === this.userList.userIds[haul.driver]
              ) {
                canEdit = true;
              } else if (
                this.user.superAdmin === true ||
                this.user.admin === true
              ) {
                canEdit = true;
              }
              if (sourceHauls[i].readyForSettlement) {
                canEdit = false;
              }
              if (destHauls[j].readyForSettlement) {
                canEdit = false;
              }
              break;
            }
          }
        } else {
          for (let j = 0; j < sourceHauls.length; j++) {
            if (
              sourceHauls[j].destinationCustomerId ===
                haul.destinationCustomerId &&
              sourceHauls[j].destinationLocationId ===
                haul.destinationLocationId &&
              sourceHauls[j].sourceCustomerId === haul.sourceCustomerId &&
              sourceHauls[j].sourceName === haul.sourceName &&
              sourceHauls[j].dateTime === haul.dateTime &&
              sourceHauls[j].driver === haul.driver
            ) {
              if (
                this.user.displayName === this.userList.userIds[haul.driver]
              ) {
                canEdit = true;
              } else if (
                this.user.superAdmin === true ||
                this.user.admin === true
              ) {
                canEdit = true;
              }
              if (sourceHauls[i].readyForSettlement) {
                canEdit = false;
              }
              if (sourceHauls[j].readyForSettlement) {
                canEdit = false;
              }
              break;
            }
          }
        }
        haul.canEdit = canEdit;
        hauls.push(haul);
      }

      for (let i = 0; i < archivedSourcesUsers.length; i++) {
        for (const key in archivedSourcesUsers[i].hauls) {
          const commodity = archivedSourcesUsers[i].commodity;

          let measurementUnit = '(deleted)';
          for (const key in this.settingsUsers.commodities) {
            if (commodity === key) {
              measurementUnit =
                this.settingsUsers.commodities[key].measurementUnit;
            }
          }

          const haul = {
            ...archivedSourcesUsers[i].hauls[key],
            measurementUnit
          };
          const customerId = archivedSourcesUsers[i].customerId;
          haul.canEdit = false;
          // Do not add haul to list if trackRunning balance was used and destination was entered as a source
          if (customerId === haul.sourceCustomerId) {
            hauls.push(haul);
          }
        }
      }

      for (let i = 0; i < destHauls.length; i++) {
        const included = hauls.find(haul => {
          return (
            destHauls[i].destinationCustomerId === haul.destinationCustomerId &&
            destHauls[i].destinationLocationId === haul.destinationLocationId &&
            destHauls[i].sourceCustomerId === haul.sourceCustomerId &&
            destHauls[i].sourceName === haul.sourceName &&
            destHauls[i].dateTime === haul.dateTime &&
            destHauls[i].driver === haul.driver
          );
        });

        if (!included) {
          hauls.push({
            canEdit: false,
            ...destHauls[i]
          });
        }
      }

      const filteredHauls = [];
      const destLocations = [];
      const sourceLocations = [];
      for (let i = 0; i < activeDestinationsUsers.length; i++) {
        destLocations.push(activeDestinationsUsers[i].destinationLocationId);
      }
      for (let i = 0; i < activeSourcesUsers.length; i++) {
        sourceLocations.push(activeSourcesUsers[i].sourceLocationId);
      }
      for (let i = 0; i < archivedSourcesUsers.length; i++) {
        sourceLocations.push(archivedSourcesUsers[i].sourceLocationId);
      }
      for (let i = 0; i < hauls.length; i++) {
        if (hauls[i].sourceAsDestination) {
          if (
            sourceLocations.includes(hauls[i].destinationLocationId) &&
            sourceLocations.includes(hauls[i].sourceLocationId)
          ) {
            filteredHauls.push(hauls[i]);
          }
        }
        if (
          destLocations.includes(hauls[i].destinationLocationId) &&
          sourceLocations.includes(hauls[i].sourceLocationId)
        ) {
          filteredHauls.push(hauls[i]);
        }
      }
      hauls = filteredHauls.slice();

      const sortByDate = (a, b) => {
        if (a.dateTime > b.dateTime) {
          return -1;
        }
        if (a.dateTime < b.dateTime) {
          return 1;
        }
        return 0;
      };
      hauls = hauls.sort(sortByDate);

      const dateNow = new Date();
      const startToday = new Date().setHours(0, 0, 0, 0);
      let yesterday = date.subtractFromDate(dateNow, { days: 1 });
      const startYesterday = new Date(yesterday).setHours(0, 0, 0, 0);
      yesterday = new Date(yesterday).toLocaleTimeString();

      const todayHauls = [];
      const yesterdayHauls = [];
      const restOfHauls = [];

      for (let i = 0; i < hauls.length; i++) {
        if (hauls[i].dateTime >= startToday) {
          todayHauls.push(hauls[i]);
        } else if (
          hauls[i].dateTime >= startYesterday &&
          hauls[i].dateTime < startToday
        ) {
          yesterdayHauls.push(hauls[i]);
        } else {
          const index = restOfHauls.findIndex(day => {
            return (
              hauls[i].dateTime > day.startTS &&
              hauls[i].dateTime < day.startNextTS
            );
          });

          if (index < 0) {
            restOfHauls.push({
              hauls: [hauls[i]],
              expand: false,
              height: 0,
              date: date.formatDate(hauls[i].dateTime, 'ddd, MMM DD'),
              startTS: new Date(hauls[i].dateTime).setHours(0, 0, 0, 0),
              startNextTS: date
                .addToDate(new Date(hauls[i].dateTime), {
                  days: 1
                })
                .setHours(0, 0, 0, 0)
            });
          } else {
            restOfHauls[index].hauls.push(hauls[i]);
          }
        }
      }

      const haulList = [
        { date: 'Today', hauls: todayHauls, expand: true, height: 0 },
        { date: 'Yesterday', hauls: yesterdayHauls, expand: false, height: 0 },
        ...restOfHauls
      ];

      for (let i = 0; i < haulList.length; i++) {
        for (let j = 0; j < haulList[i].hauls.length; j++) {
          const haul = haulList[i].hauls[j];
          let height = 84;
          if (haul.mileage > 0) {
            height += 21;
          }

          height += haul.sourceHaulTrackers.length * 21;
          height += haul.destinationHaulTrackers.length * 21;

          if (haul.haulNote !== '') {
            let numRows = Math.ceil(haul.haulNote.length / 35);

            height += numRows * 21;
          }

          haulList[i].height =
            haulList[i].height < height ? height : haulList[i].height;
        }
      }

      this.haulList = haulList.slice();
    },
    setUser() {
      this.user = JSON.parse(JSON.stringify(this.$store.state.user));
      if (this.haulList.length < 1) {
        this.setHaulList();
      }
    },
    trackerLabel(label) {
      if (label.startsWith('***&&&')) {
        return label.substring(9);
      }

      return label;
    }
  },
  computed: {
    customers() {
      return JSON.parse(JSON.stringify(this.$store.state.customers));
    },
    isGuestUser() {
      return this.$store.state.user.guestUser;
    },
    masterDestinations() {
      return JSON.parse(JSON.stringify(this.$store.state.masterDestinations));
    },
    masterSources() {
      return JSON.parse(JSON.stringify(this.$store.state.masterSources));
    },
    settingsUsers() {
      return JSON.parse(JSON.stringify(this.$store.state.settingsUsers));
    },
    userList() {
      return JSON.parse(JSON.stringify(this.$store.state.userList));
    }
  },
  watch: {
    '$store.state.user'() {
      this.setUser();
    },
    '$store.state.activeSourcesUsers'() {
      this.setHaulList();
    },
    '$store.state.archivedSourcesUsers'() {
      this.setHaulList();
    },
    '$store.state.activeDestinationsUsers'() {
      this.setHaulList();
    }
  }
};
</script>

<style scoped></style>
