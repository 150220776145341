<template>
  <div class="q-pa-sm customer-list">
    <q-dialog v-model="showLevelInfo">
      <q-card class="q-pa-lg outer-card">
        <div class="q-pa-sm">
          <q-card
            flat
            bordered
            class="q-pa-md text-grey-9 row items-center rounded-borders"
            style="min-width: 250px"
          >
            <div class="row full-width">
              <q-card
                flat
                class="row justify-center items-center full-width bg-grey-3 q-pa-sm"
              >
                <q-icon name="info" color="primary" size="md" />
                <div class="text-bold text-primary q-ml-sm">
                  Level Permissions
                </div>
              </q-card>
            </div>
            <div class="q-py-md">
              <q-list bordered separator>
                <q-item v-ripple>
                  <q-item-section>
                    <q-item-label class="text-subtitle1 text-bold text-primary"
                      >Super Admin</q-item-label
                    >
                    <q-item-label>
                      <li class="q-pt-sm">
                        Has access to all parts of the app
                      </li>
                    </q-item-label>
                  </q-item-section>
                </q-item>

                <q-item v-ripple>
                  <q-item-section>
                    <q-item-label class="text-subtitle1 text-bold text-primary"
                      >Admin</q-item-label
                    >
                    <q-item-label>
                      <li class="q-pt-sm">
                        Same as Super Admin except cannot change or set user
                        levels in accounts
                      </li>
                    </q-item-label>
                  </q-item-section>
                </q-item>

                <q-item v-ripple>
                  <q-item-section>
                    <q-item-label
                      class="text-subtitle1 text-bold text-primary q-pb-sm"
                      >Super User</q-item-label
                    >
                    <q-item-label>
                      Same as User except:
                      <li class="q-pt-md">
                        Can set map pins (but no shortcut to set pin)
                      </li>
                      <li class="q-pt-sm">
                        Can create new Field/Destination with the default
                        pricing (cannot view/set pricing)
                      </li>
                      <li class="q-pt-sm">
                        Can view pins for all Fields/Destinations and Sources
                        (even if Field/Destination or Source is not currently
                        active)
                      </li>
                    </q-item-label>
                  </q-item-section>
                </q-item>

                <q-item v-ripple>
                  <q-item-section>
                    <q-item-label class="text-subtitle1 text-bold text-primary"
                      >User</q-item-label
                    >
                    <q-item-label>
                      <li class="q-pt-xs">
                        Cannot view ANY pricing info except driver rate - AND
                        THEN only allowed to view driver rate in the driver
                        tables - AND THEN only allowed to view driver tables if
                        given permision
                      </li>
                      <li class="q-pt-sm">
                        Cannot view anything set to 'Waiting' or 'Estimate'
                      </li>
                      <li class="q-pt-sm">
                        Cannot view Admin note or Customer Note
                      </li>
                      <li class="q-pt-sm">Cannot access customer page</li>
                      <li class="q-pt-sm">
                        Very limited editing on Fields/Destinations and Sources
                      </li>
                      <li class="q-pt-sm">
                        Cannot view Info Boxes or Custom Selectors that are set
                        to admin only
                      </li>
                      <li class="q-pt-sm">Cannot set map pins</li>
                      <li class="q-pt-sm">Cannot edit another user's hauls</li>
                      <li class="q-pt-sm">
                        Cannot view other user's Time Clock info
                      </li>
                    </q-item-label>
                  </q-item-section>
                </q-item>

                <q-item v-ripple>
                  <q-item-section>
                    <q-item-label
                      class="text-subtitle1 text-bold text-primary q-pb-sm"
                      >Super Customer</q-item-label
                    >
                    <q-item-label>
                      Same as User except:
                      <li class="q-pt-md">
                        Can ONLY view Fields/Destinations or Sources that match
                        the customer selected in accounts
                      </li>
                      <li class="q-pt-sm">
                        View only - cannot edit or change anything
                      </li>
                      <li class="q-pt-sm">Cannot view attachments</li>
                      <li class="q-pt-sm">Cannot make hauls</li>
                      <li class="q-pt-sm">No Time Clock</li>
                      <li class="q-pt-sm">
                        Customer cannot view archived, so customer is shown
                        reports for Fields/Destinations and Sources which break
                        it down to each individual location. This effectively
                        allows them to view past history for each location.
                      </li>
                    </q-item-label>
                  </q-item-section>
                </q-item>

                <q-item v-ripple>
                  <q-item-section>
                    <q-item-label
                      class="text-subtitle1 text-bold text-primary q-pb-sm"
                      >Customer</q-item-label
                    >
                    <q-item-label>
                      Same as User except:
                      <li class="q-pt-md">
                        Can ONLY view Fields/Destinations or Sources that match
                        the customer selected in accounts
                      </li>
                      <li class="q-pt-sm">
                        View only - cannot edit or change anything
                      </li>
                      <li class="q-pt-sm">
                        Cannot view notes, attachments, or insights
                      </li>
                      <li class="q-pt-sm">
                        If Source - cannot view Field/Destination info
                      </li>
                      <li class="q-pt-sm">
                        If Field/Destination - cannot view Source info
                      </li>
                      <li class="q-pt-sm">Cannot make hauls</li>
                      <li class="q-pt-sm">No Time Clock</li>
                      <li class="q-pt-sm">
                        Customer cannot view archived, so customer is shown
                        reports for Fields/Destinations and Sources which break
                        it down to each individual location. This effectively
                        allows them to view past history for each location.
                      </li>
                    </q-item-label>
                  </q-item-section>
                </q-item>

                <q-item v-ripple>
                  <q-item-section>
                    <q-item-label class="text-subtitle1 text-bold text-primary"
                      >Visitor</q-item-label
                    >
                    <q-item-label>
                      <li class="q-pt-sm">
                        Has NO access to ANY part of the app except home page
                        and sign in
                      </li>
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </div>
          </q-card>
          <div align="right" class="q-mt-md">
            <q-btn
              label="Ok"
              color="primary"
              padding="xs lg"
              @click="showLevelInfo = false"
            />
          </div>
        </div>
      </q-card>
    </q-dialog>

    <div class="gt-xs q-pa-lg"></div>
    <div
      class="row items-center justify-center rounded-borders text-custom-light q-mb-md"
      style="background: radial-gradient(circle, #3336eb 0%, #0b0d6f 100%)"
    >
      <q-avatar icon="account_circle" font-size="35px" />
      <span class="text-body1 text-bold q-ml-xs"> User List </span>
    </div>

    <div class="row q-mb-md">
      <q-btn
        color="primary"
        outline
        label="Level Permissions"
        icon="info"
        class="full-width"
        padding="xs"
        @click="showLevelInfo = true"
      />
    </div>

    <q-card v-if="skeleton" flat bordered>
      <div class="full-width">
        <div v-for="i in 7" :key="i">
          <q-item>
            <q-item-section avatar>
              <q-skeleton type="QAvatar" />
            </q-item-section>
            <q-item-section>
              <q-item-label>
                <q-skeleton type="text" />
              </q-item-label>
              <q-item-label caption>
                <q-skeleton type="text" width="65%" />
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-separator v-if="i !== 7" />
        </div>
      </div>
    </q-card>

    <q-card v-if="!skeleton" flat bordered class="q-mb-xl">
      <q-list separator>
        <q-item
          clickable
          v-ripple
          v-for="(user, index) in sortedUsers"
          :key="index"
          @click="editUser(user)"
        >
          <q-item-section avatar>
            <q-avatar :color="user.color" size="md" text-color="white">
              {{ user.displayName.charAt(0) }}
            </q-avatar>
          </q-item-section>

          <q-item-section>
            {{ user.displayName }}
            <span :class="`text-${user.color}`">{{
              user.role
            }}</span></q-item-section
          >

          <q-item-section side>
            <q-icon name="create" color="grey-5" class="edit-icon"> </q-icon>
          </q-item-section>
        </q-item>
      </q-list>
    </q-card>
  </div>
</template>

<script>
export default {
  name: 'UserList',
  props: {
    skeleton: {
      type: Boolean,
      required: true
    },
    users: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      showLevelInfo: false
    };
  },
  methods: {
    editUser(user) {
      this.$emit('editUser', user);
    }
  },
  computed: {
    sortedUsers() {
      const superAdmins = [];
      const admins = [];
      const superUsers = [];
      const users = [];
      const guestUsers = [];
      const superCustomers = [];
      const customers = [];
      const visitors = [];
      const userList = this.users.slice();
      const colors = JSON.parse(JSON.stringify(this.$store.state.colors));
      let x;
      for (x in userList) {
        if (userList[x].displayName !== 'Trollibitz33') {
          if (userList[x].customClaims.superAdmin === true) {
            userList[x].role = 'Super Admin';
            userList[x].color = colors[0];
            superAdmins.push(userList[x]);
          } else if (userList[x].customClaims.admin === true) {
            userList[x].role = 'Admin';
            userList[x].color = colors[6];
            admins.push(userList[x]);
          } else if (userList[x].customClaims.superUser === true) {
            userList[x].role = 'Super User';
            userList[x].color = colors[2];
            superUsers.push(userList[x]);
          } else if (userList[x].customClaims.user === true) {
            userList[x].role = 'User';
            userList[x].color = colors[4];
            users.push(userList[x]);
          } else if (userList[x].customClaims.guestUser === true) {
            userList[x].role = 'Guest User';
            userList[x].color = colors[5];
            guestUsers.push(userList[x]);
          } else if (userList[x].customClaims.superCustomer === true) {
            userList[x].role = 'Super Customer';
            userList[x].color = colors[1];
            superCustomers.push(userList[x]);
          } else if (userList[x].customClaims.customer === true) {
            userList[x].role = 'Customer';
            userList[x].color = colors[1];
            customers.push(userList[x]);
          } else if (userList[x].customClaims.visitor === true) {
            userList[x].role = 'Visitor';
            userList[x].color = colors[3];
            visitors.push(userList[x]);
          }
        }
      }

      const sortByAlpha = (a, b) => {
        if (a.displayName < b.displayName) {
          return -1;
        }
        if (a.displayName > b.displayName) {
          return 1;
        }
        return 0;
      };
      superAdmins.sort(sortByAlpha);
      admins.sort(sortByAlpha);
      superUsers.sort(sortByAlpha);
      users.sort(sortByAlpha);
      guestUsers.sort(sortByAlpha);
      superCustomers.sort(sortByAlpha);
      customers.sort(sortByAlpha);
      visitors.sort(sortByAlpha);

      const sorted = [
        ...superAdmins,
        ...admins,
        ...superUsers,
        ...users,
        ...guestUsers,
        ...superCustomers,
        ...customers,
        ...visitors
      ];

      return sorted;
    }
  }
};
</script>

<style scoped>
.customer-list {
  width: 450px;
}

.customer-row {
  width: 400px;
}

.edit-icon {
  font-size: 1.5em;
}

@media all and (max-width: 450px) {
  .customer-list {
    width: 100%;
  }
  .customer-row {
    width: 100%;
  }
}
</style>
