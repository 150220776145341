<template>
  <div class="row q-pa-md justify-center">
    <ConfirmDialog
      v-if="confirm.display"
      icon="schedule"
      iconColor="primary"
      :btn="confirm.btn"
      :message="confirm.message"
      :headerMessage="confirm.headerMessage"
      @canceled="confirm.display = false"
      @confirmed="deleteTime"
    />

    <EditTime
      v-if="editDisplay"
      :timeChunk="timeChunk"
      :workTypes="workTypes.options"
      @backToTime="editDisplay = false"
      @editTime="editTime"
    />

    <WorkTypeSettings
      v-if="workTypeSettings.show"
      :workTypes="workTypes.options"
      @onCancel="workTypeSettings.show = false"
    />

    <Insights
      v-if="insights.show"
      :insights="insights"
      :isSuperAdmin="isSuperAdmin"
      :user="user"
      @onCancel="insights.show = false"
    />

    <PrintTime v-if="print" id="print" :weeks="printWeeks" :user="user" />

    <DateRange
      v-if="displayDateRange"
      :datesProvided="false"
      @onSelectDates="setDates"
      @onCancel="displayDateRange = false"
    />

    <div class="q-mt-lg div-container-3">
      <div class="row justify-center q-mt-md q-px-sm">
        <q-space />
        <div>
          <q-btn
            v-if="!clocked.in"
            unelevated
            label="Clock In"
            icon-right="update"
            color="custom-orange"
            padding="xs sm"
            class="q-mr-sm"
            @click="clockIn"
          />
          <q-btn
            v-if="clocked.in"
            unelevated
            label="Clock In"
            icon-right="update"
            color="custom-light"
            padding="xs sm"
            class="q-mr-sm"
          />
          <q-btn
            v-if="clocked.in"
            unelevated
            label="Clock Out"
            icon-right="history"
            color="custom-red"
            padding="xs sm"
            @click="clockOut"
          />
          <q-btn
            v-if="!clocked.in"
            unelevated
            label="Clock Out"
            icon-right="history"
            color="custom-light"
            padding="xs sm"
          />
        </div>
      </div>
      <div class="div-container-3">
        <div class="q-mb-xl q-px-sm">
          <div
            class="q-pl-sm q-mt-md row items-center justify-center rounded-borders text-custom-light"
            style="
              background: radial-gradient(circle, #3336eb 0%, #0b0d6f 100%);
            "
          >
            <q-icon
              name="schedule"
              size="lg"
              class="q-ma-sm"
              font-size="35px"
            />
            <span class="text-h5 q-ml-sm">
              <span v-if="currentTime < 0">-</span>
              {{ formatTime(hours) }}:{{ formatTime(minutes) }}:{{
                formatTime(seconds)
              }}
            </span>
          </div>

          <div v-if="modes.loading" class="q-py-xs">
            <q-linear-progress indeterminate color="primary" size="sm" />
          </div>

          <q-input
            outlined
            v-model="clocked.breakMin"
            type="tel"
            mask="###"
            label="Auto Deduct Lunch - In Minutes"
            color="primary"
            label-color="primary"
            class="q-mt-md"
            @input="calcBreak"
          />
          <div v-if="clocked.err" class="text-custom-red q-ml-sm">
            <span class="q-ml-xs">
              Please adjust so your time is a positive number!
            </span>
          </div>

          <q-select
            outlined
            v-model="workTypes.model"
            :options="workTypes.options"
            color="primary"
            label-color="primary"
            label="Work Type"
            class="q-mt-md"
          />
          <div v-if="workTypes.err" class="text-custom-red q-ml-sm">
            <span class="q-ml-xs"> Select Work Type! </span>
          </div>

          <div v-if="clocked.currentTimeChunk" class="q-mt-lg q-pt-md">
            <q-chip
              icon="schedule"
              color="custom-orange"
              text-color="black"
              class="q-mb-sm"
            >
              Clocked In
            </q-chip>
            <q-card flat bordered>
              <div
                class="row bg-grey-2 text-subtitle1 text-grey-8 q-py-xs q-px-sm"
              >
                <span>
                  {{ clocked.currentTimeChunk.day }}
                </span>
                <q-space />
                <span>
                  {{ clocked.currentTimeChunk.workType }}
                </span>
              </div>
              <q-separator />
              <div class="row items-center q-my-xs q-py-xs q-px-sm">
                <span class="text-body2 text-grey-8">
                  {{ clocked.currentTimeChunk.clockInTime }} -
                </span>
                <q-space />
                <span class="text-grey-8">
                  Lunch - {{ clocked.currentTimeChunk.breakMin }} Min.
                </span>
              </div>
              <div class="row items-center q-pb-sm q-px-sm">
                <span class="text-bold text-custom-light-blue text-body1">
                  Current:
                </span>
                <span class="text-bold text-body1 q-ml-sm">
                  {{ formatTime(hours) }}:{{ formatTime(minutes) }}
                </span>
                <q-space />
                <q-btn
                  outline
                  color="custom-orange"
                  padding="xs xs"
                  size="12px"
                  class="q-mr-sm"
                  icon="edit"
                  @click="editTimeDialog(clocked.currentTimeChunk)"
                />
                <q-btn
                  outline
                  color="custom-red"
                  padding="xs xs"
                  size="12px"
                  icon="delete"
                  @click="confirmDelete(clocked.currentTimeChunk)"
                />
              </div>
            </q-card>
          </div>
        </div>

        <div align="right" class="q-ma-sm">
          <q-btn
            icon="date_range"
            color="custom-red"
            class="q-mr-sm"
            padding="xs sm"
            @click="setDatePicker"
          />
          <q-btn
            icon="date_range"
            icon-right="restart_alt"
            color="custom-red"
            class="gt-xs q-mr-sm"
            padding="xs sm"
            @click="setCurrentDates"
          />
          <q-btn
            icon="print"
            color="custom-medium"
            class="gt-xs q-mr-sm"
            padding="xs sm"
            @click="printTime"
          />
          <q-btn
            v-if="isSuperAdmin"
            label="Work Types"
            color="custom-artichoke"
            class="q-mr-sm"
            padding="xs sm"
            @click="setWorkTypes"
          />
          <q-btn
            label="Insights"
            color="custom-orange"
            padding="xs sm"
            @click="calcInsights"
          />
        </div>

        <div class="q-px-sm">
          <div
            class="row justify-center items-center rounded-borders text-custom-light q-pl-sm q-py-xs"
            style="
              background: radial-gradient(circle, #3336eb 0%, #0b0d6f 100%);
            "
          >
            <q-icon name="history" size="sm" class="q-mx-xs" />
            <span class="text-body1 text-bold q-ml-sm">
              {{ user.name }}
            </span>
          </div>
          <div v-if="modes.loading" class="q-py-xs">
            <q-linear-progress indeterminate color="primary" size="sm" />
          </div>
          <q-select
            v-if="isSuperAdmin"
            outlined
            v-model="userSelect.model"
            :options="userSelect.options"
            color="primary"
            label-color="primary"
            label="User"
            class="q-mt-md"
            @input="selectUser"
          />

          <div v-if="!modes.loading" class="q-pt-lg q-mt-xl">
            <div v-for="week in weeks" :key="week.start" class="q-mb-lg">
              <div
                class="row items-center rounded-borders text-bold text-body1 text-primary cursor-pointer q-py-xs q-px-xs q-mb-md"
                style="border: 1px solid #0b0d6f"
                @click="week.expand = !week.expand"
              >
                <q-icon name="date_range" size="sm" class="q-mx-xs" />
                <div class="q-ml-sm text-bold text-body1">
                  {{ week.startDate }} - {{ week.endDate }}
                </div>
                <q-space />
                <div>
                  <span class="text-body1 text-bold"> Week Total: </span>
                  <span class="text-body1 text-bold q-mx-xs">
                    {{ week.total }}
                  </span>
                </div>
              </div>

              <div
                v-for="(timeChunk, index) in week.timeChunks"
                :key="timeChunk.day + index"
                class="q-mb-md"
              >
                <q-card flat bordered>
                  <div
                    class="row bg-grey-1 text-subtitle1 text-custom-medium q-py-xs q-px-sm"
                  >
                    {{ timeChunk.day }}
                    <q-space />
                    {{ timeChunk.workType }}
                  </div>
                  <q-separator />
                  <div class="row items-center q-my-xs q-py-xs q-px-sm">
                    <span class="text-body2 text-custom-medium">
                      {{ timeChunk.clockInTime }} - {{ timeChunk.clockOutTime }}
                    </span>
                    <q-space />
                    <span class="text-grey-8">
                      Lunch - {{ timeChunk.breakMin }} Min.
                    </span>
                  </div>
                  <div class="row items-center q-pb-sm q-px-sm">
                    <span class="text-bold text-custom-light-blue text-body1">
                      Total:
                    </span>
                    <span class="text-bold text-body1 q-ml-sm">
                      {{ timeChunk.diff }}
                    </span>
                    <q-space />
                    <q-btn
                      outline
                      color="custom-orange"
                      padding="xs xs"
                      size="12px"
                      class="q-mr-sm"
                      icon="edit"
                      @click="editTimeDialog(timeChunk)"
                    />
                    <q-btn
                      outline
                      color="custom-red"
                      padding="xs xs"
                      size="12px"
                      icon="delete"
                      @click="confirmDelete(timeChunk)"
                    />
                  </div>
                </q-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditTime from '@/components/timeclock/EditTime.vue';
import PrintTime from '@/components/timeclock/PrintTime.vue';
import WorkTypeSettings from '@/components/timeclock/WorkTypeSettings.vue';
import Insights from '@/components/timeclock/Insights.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import DateRange from '@/components/DateRange.vue';
import firebase from 'firebase/compat/app';
import { date } from 'quasar';
import { Printd } from 'printd';

const db = firebase.firestore();

export default {
  name: 'TimeClock',
  components: {
    ConfirmDialog,
    DateRange,
    EditTime,
    Insights,
    PrintTime,
    WorkTypeSettings
  },
  data() {
    return {
      clocked: {
        clockInTs: null,
        in: false,
        breakMin: 0,
        err: false,
        currentTimeChunk: null
      },
      confirm: {
        btn: {
          label: 'Delete',
          icon: 'delete',
          color: 'custom-red'
        },
        display: false,
        headerMessage: '',
        message: ''
      },
      currentTime: 0,
      customTime: true,
      dates: {
        startTS: null,
        startDate: null,
        endTs: null,
        endDate: null
      },
      displayDateRange: false,
      editDisplay: false,
      insights: {
        show: false
      },
      modes: {
        loading: false
      },
      print: false,
      printWeeks: [],
      selectedClockinData: {},
      timer: null,
      timeChunk: {},
      weeks: [],
      workTypes: {
        model: null,
        options: ['Haul', 'Spread', 'Shop Work'],
        err: false
      },
      workTypeSettings: {
        show: false
      },
      user: {
        name: '',
        id: '',
        set: false
      },
      userSelect: {
        model: {
          label: '',
          id: ''
        },
        options: []
      }
    };
  },
  mounted() {
    this.setWorkTypeList();
    this.setUser();

    window.addEventListener('visibilitychange', this.checkTimer);
  },
  beforeDestroy() {
    this.clearTimer();
    window.removeEventListener('visibilitychange', this.checkTimer);
  },
  methods: {
    calcBreak() {
      this.$nextTick(() => {
        let current = this.clockinData.currentClockins.current;

        if (!this.isLoggedInUser) {
          current = this.clocked.currentTimeChunk;
        }

        const currTs = +new Date();

        const breakMin = parseInt(this.clocked.breakMin) || 0;

        if (this.clocked.in) {
          this.currentTime = currTs - current.clockInTs - breakMin * 60 * 1000;
          this.clocked.currentTimeChunk.breakMin = breakMin;
        }
        this.clocked.err = false;
      });
    },
    calcHistory(historyArr) {
      const weeks = [];

      let dayOfWeek = date.getDayOfWeek(this.dates.startTs);
      dayOfWeek = dayOfWeek === 7 ? 0 : dayOfWeek;
      const addDays = 7 - dayOfWeek;

      let endTs = parseInt(
        date.formatDate(
          date.addToDate(this.dates.startTs, { days: addDays }),
          'x'
        )
      );

      const endFirstDate = date.formatDate(
        date.subtractFromDate(endTs, { days: 1 }),
        'MMM D'
      );
      const startDate = date.formatDate(this.dates.startTs, 'MMM D');

      weeks.push({
        startTs: this.dates.startTs,
        startDate: startDate,
        endTs: endTs,
        endDate: endFirstDate
      });

      const formatMoDay = ts => {
        return date.formatDate(ts, 'MMM D');
      };

      while (endTs < this.dates.endTs) {
        const weekObj = {
          startTs: endTs,
          startDate: formatMoDay(endTs)
        };

        endTs = parseInt(
          date.formatDate(date.addToDate(endTs, { days: 7 }), 'x')
        );

        weekObj.endTs = endTs;
        weekObj.endDate = formatMoDay(
          date.subtractFromDate(endTs, { days: 1 })
        );

        weeks.push(weekObj);
      }

      weeks[weeks.length - 1].endTs = this.dates.endTs;
      weeks[weeks.length - 1].endDate = this.dates.endDate;

      for (let i = 0; i < weeks.length; i++) {
        const week = weeks[i];

        let weekTotal = 0;
        const weekTimeChunks = [];

        for (let j = 0; j < historyArr.length; j++) {
          const timeChunk = historyArr[j];

          if (
            timeChunk.dbTimeChunk.clockInTs >= week.startTs &&
            timeChunk.dbTimeChunk.clockInTs < week.endTs
          ) {
            weekTimeChunks.push(timeChunk);
            weekTotal += timeChunk.msDiff;
          }
        }

        weeks[i].timeChunks = weekTimeChunks;
        weeks[i].total = this.formatDiff(weekTotal);
      }

      return weeks.reverse();
    },
    async calcInsights() {
      let clockinData = this.clockinData;
      const currUser = firebase.auth().currentUser;
      if (this.user.name !== currUser.displayName) {
        clockinData = this.selectedClockinData;
      }

      const superInsights = {};

      const getSuperInsights = async () => {
        if (this.isSuperAdmin) {
          this.modes.loading = true;

          const historyArr = [];

          const currentClockinsCol = db.collection('current_clockins');

          const archivedClockinsCol = db
            .collection('clockin_history')
            .where('latestTs', '>=', this.dates.startTs);

          const getCurrentHistory = currentClockinsCol.get().then(clockins => {
            clockins.forEach(doc => {
              const historyChunk = doc.data();
              for (let i = 0; i < historyChunk.history.length; i++) {
                const timeChunk = historyChunk.history[i];
                if (
                  timeChunk.clockInTs > this.dates.startTs &&
                  timeChunk.clockInTs < this.dates.endTs
                ) {
                  historyArr.push(timeChunk);
                }
              }
            });
            return true;
          });
          await getCurrentHistory;

          const getArchivedHistory = archivedClockinsCol
            .get()
            .then(clockins => {
              clockins.forEach(doc => {
                const historyChunk = doc.data();
                for (let i = 0; i < historyChunk.history.length; i++) {
                  const timeChunk = historyChunk.history[i];
                  if (
                    timeChunk.clockInTs > this.dates.startTs &&
                    timeChunk.clockInTs < this.dates.endTs
                  ) {
                    historyArr.push(timeChunk);
                  }
                }
              });
              return true;
            });
          await getArchivedHistory;
          this.modes.loading = false;

          const workTypes = {};
          let index = 0;
          for (let i = 0; i < historyArr.length; i++) {
            const timeChunk = historyArr[i];

            const msDiff =
              timeChunk.clockOutTs -
              timeChunk.clockInTs -
              timeChunk.breakMin * 60 * 1000;

            if (!(timeChunk.workType in workTypes)) {
              workTypes[timeChunk.workType] = {
                time: msDiff,
                name: timeChunk.workType,
                index: index
              };
              index++;
            } else {
              workTypes[timeChunk.workType].time += msDiff;
            }
          }

          for (const key in workTypes) {
            workTypes[key].time = this.formatDiff(workTypes[key].time);
          }
          superInsights.workTypes = workTypes;

          return true;
        }
      };

      await getSuperInsights();

      if (clockinData.clockinDocExists) {
        const startDate = this.weeks[this.weeks.length - 1].startDate;
        const endDate = this.weeks[0].endDate;

        const workTypes = {};
        let totalHours = 0;
        let totalOvertime = 0;
        let netOvertime = 0;

        for (let i = 0; i < this.weeks.length; i++) {
          const week = this.weeks[i];
          let weekTotal = 0;

          for (let j = 0; j < week.timeChunks.length; j++) {
            const timeChunk = week.timeChunks[j];

            totalHours += timeChunk.msDiff;
            weekTotal += timeChunk.msDiff;

            if (!(timeChunk.workType in workTypes)) {
              workTypes[timeChunk.workType] = {
                time: timeChunk.msDiff,
                name: timeChunk.workType
              };
            } else {
              workTypes[timeChunk.workType].time += timeChunk.msDiff;
            }
          }

          const overTime = weekTotal - 40 * 3600000;
          if (overTime > 0) {
            totalOvertime += overTime;
          }

          // The following only adds overtime (may be minus) if greater than zero or -
          // not this week or -
          // is Saturday (end of week and can (hopefully) safely add)
          // actual dates for acurate calculations should always start on -
          // Sunday & end on Saturday
          if (i === 0) {
            if (overTime > 0) {
              netOvertime += overTime;
            } else {
              let weekStart = new Date();
              // SO paste https://stackoverflow.com/questions/35088088/javascript-for-getting-the-previous-monday
              weekStart.setDate(
                weekStart.getDate() -
                  (weekStart.getDay() === 0
                    ? 7
                    : (weekStart.getDay() + (7 - 0)) % 7)
              );
              weekStart = new Date(weekStart);
              weekStart = +new Date(
                weekStart.getFullYear(),
                weekStart.getMonth(),
                weekStart.getDate()
              );
              if (week.endTs < weekStart) {
                netOvertime += overTime;
              } else {
                const now = new Date();
                if (now.getDay() === 6) {
                  netOvertime += overTime;
                }
              }
            }
          } else {
            netOvertime += overTime;
          }
        }
        for (const key in workTypes) {
          workTypes[key].time = this.formatDiff(workTypes[key].time);
        }
        totalHours = this.formatDiff(totalHours);
        totalOvertime = this.formatDiff(totalOvertime);
        if (netOvertime < 0) {
          netOvertime = Math.abs(netOvertime);
          netOvertime = this.formatDiff(netOvertime, true);
          netOvertime = '- ' + netOvertime;
        } else {
          netOvertime = this.formatDiff(netOvertime);
        }

        this.insights = {
          show: true,
          startDate: startDate,
          endDate: endDate,
          totalHours: totalHours,
          totalOvertime: totalOvertime,
          netOvertime: netOvertime,
          workTypes: workTypes,
          superInsights: superInsights
        };
      }
    },
    checkTimer() {
      if (!this.clocked.in) {
        return;
      }

      if (!document.hidden) {
        this.setCurrentClockin();
      }
    },
    clearTimer() {
      clearInterval(this.timer);
    },
    confirmDelete(timeChunk) {
      this.timeChunk = timeChunk.dbTimeChunk;
      this.confirm.display = true;
      this.confirm.message = 'Are you sure you want to delete time?';
      this.confirm.headerMessage = `${timeChunk.clockInTime} - ${timeChunk.clockOutTime}`;
    },
    countUp() {
      this.currentTime = this.currentTime + 1000;
    },
    clockIn() {
      if (!this.workTypes.model) {
        this.workTypes.err = true;
        return false;
      }

      this.workTypes.err = false;
      this.clocked.in = true;

      const userId = this.user.id;

      const ts = +new Date();

      const clockinDoc = db.collection('current_clockins').doc(userId);

      this.clocked.breakMin =
        this.clocked.breakMin === '' ? 0 : this.clocked.breakMin;

      let clockinData = this.clockinData;
      const currUser = firebase.auth().currentUser;
      if (this.user.name !== currUser.displayName) {
        clockinData = this.selectedClockinData;
        this.modes.loading = true;
      }

      const batch = db.batch();

      if (clockinData.clockinDocExists) {
        batch.update(clockinDoc, {
          current: {
            clockInTs: ts,
            breakMin: this.clocked.breakMin,
            clockedIn: true,
            workType: this.workTypes.model
          }
        });
      } else {
        batch.set(clockinDoc, {
          current: {
            clockInTs: ts,
            breakMin: this.clocked.breakMin,
            clockedIn: true,
            workType: this.workTypes.model
          },
          history: []
        });
      }

      batch
        .commit()
        .then(() => {
          if (this.user.name !== currUser.displayName) {
            this.setCurrentClockin();
          }
        })
        .catch(err => {
          console.log(err);
        });
      this.startTimer();
    },
    clockOut() {
      if (this.currentTime < 0) {
        this.clocked.err = true;
        return false;
      }

      this.clocked.err = false;
      this.clocked.in = false;
      this.clocked.currentTimeChunk = null;
      this.currentTime = 0;

      const userId = this.user.id;

      let clockInTs = this.clockinData.currentClockins.current.clockInTs;
      const currUser = firebase.auth().currentUser;
      if (this.user.name !== currUser.displayName) {
        clockInTs = this.selectedClockinData.currentClockins.current.clockInTs;
        this.modes.loading = true;
      }

      const clockOutTs = +new Date();
      const breakMin = parseInt(this.clocked.breakMin) || 0;

      const batch = db.batch();

      const currentObj = {
        current: {
          clockInTs: null,
          breakMin: breakMin,
          clockedIn: false,
          workType: this.workTypes.model
        }
      };
      const newTimeObj = {
        clockInTs: clockInTs,
        clockOutTs: clockOutTs,
        breakMin: breakMin,
        workType: this.workTypes.model
      };
      const pastObj = {
        history: firebase.firestore.FieldValue.arrayUnion(newTimeObj)
      };

      const clockinsDoc = db.collection('current_clockins').doc(userId);

      batch.update(clockinsDoc, currentObj);
      batch.update(clockinsDoc, pastObj);

      batch
        .commit()
        .then(() => {
          if (this.user.name !== currUser.displayName) {
            this.setCurrentClockin();
          }
        })
        .catch(err => {
          console.log(err);
        });

      this.stopTimer();
    },
    deleteTime() {
      if (!this.modes.loading) {
        const userId = this.user.id;

        const currUser = firebase.auth().currentUser;
        if (this.user.name !== currUser.displayName) {
          this.modes.loading = true;
        }

        this.clocked.breakMin =
          this.clocked.breakMin === '' ? 0 : this.clocked.breakMin;

        const batch = db.batch();

        const pastObj = {
          history: firebase.firestore.FieldValue.arrayRemove(this.timeChunk)
        };

        const currentObj = {
          current: {
            clockInTs: null,
            breakMin: this.clocked.breakMin,
            clockedIn: false,
            workType: this.workTypes.model
          }
        };

        const clockinsDoc = db.collection('current_clockins').doc(userId);

        if (this.timeChunk) {
          batch.update(clockinsDoc, pastObj);
        } else {
          batch.update(clockinsDoc, currentObj);
        }

        batch
          .commit()
          .then(() => {
            if (this.user.name !== currUser.displayName) {
              this.setCurrentClockin();
            }
          })
          .catch(err => {
            console.log(err);
          });

        this.clearTimer();
        this.confirm.display = false;
      }
    },
    editTime(editObj) {
      if (!this.modes.loading) {
        this.editDisplay = false;

        const userId = this.user.id;

        const currUser = firebase.auth().currentUser;
        if (this.user.name !== currUser.displayName) {
          this.modes.loading = true;
        }

        const batch = db.batch();

        const pastObj = {
          history: firebase.firestore.FieldValue.arrayRemove(
            this.timeChunk.dbTimeChunk
          )
        };

        const newTimeObj = {
          clockInTs: parseInt(editObj.clockInTs),
          clockOutTs: parseInt(editObj.clockOutTs),
          breakMin: parseInt(editObj.breakMin) || 0,
          workType: editObj.workType
        };
        const newPastObj = {
          history: firebase.firestore.FieldValue.arrayUnion(newTimeObj)
        };

        const currentObj = {
          'current.clockInTs': parseInt(editObj.clockInTs)
        };

        const clockinsDoc = db.collection('current_clockins').doc(userId);

        if (this.timeChunk.dbTimeChunk) {
          batch.update(clockinsDoc, pastObj);
          batch.update(clockinsDoc, newPastObj);
        } else {
          batch.update(clockinsDoc, currentObj);
        }

        batch
          .commit()
          .then(() => {
            if (this.user.name !== currUser.displayName) {
              this.setCurrentClockin();
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    editTimeDialog(timeChunk) {
      this.timeChunk = timeChunk;
      this.editDisplay = true;
    },
    formatDiff(duration, negative = false) {
      let minutes = parseInt(Math.round(duration / 60000) % 60);
      let hours = parseInt(duration / (1000 * 60 * 60));

      if (negative && hours > 0) {
        hours -= 1;
      }

      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      return hours + ':' + minutes;
    },
    formatTime(value) {
      if (value < 10) {
        return '0' + value;
      }
      return value;
    },
    printTime() {
      this.print = true;
      this.$nextTick(() => {
        this.printWeeks = this.weeks;

        const css = `
          #name {
            font-size: 30px;
            font-weight: bold;
            margin-bottom: 10px;
          }
          #table {
            margin-bottom: 30px;
          }
          .right {
            text-align: right;
            padding-right: 10px;
          }
          .left {
            text-align: left;
            padding-left: 10px;
          }
          .top {
            border-top: none;
          }
          .wider {
            width: 200px;
          }
          th {
            padding-top: 5px;
            padding-bottom: 5px;
          }
          table,
          th,
          td {
            border-bottom: 1px solid silver;
            border-top: 1px solid silver;
            border-collapse: collapse;
          }
        `;

        const printMe = document.getElementById('print');

        const d = new Printd();
        d.print(printMe, [css]);

        this.print = false;
      });
    },
    selectUser() {
      this.insights.show = false;
      this.user = {
        name: this.userSelect.model.label,
        id: this.userSelect.model.id,
        set: true
      };

      this.clearTimer();
      this.setCurrentClockin();
    },
    async setCurrentClockin() {
      const currUser = firebase.auth().currentUser;

      let clockinData = {};
      if (this.user.name === currUser.displayName) {
        clockinData = this.clockinData;
      } else {
        this.modes.loading = true;

        const docId = this.userList.users[this.user.name];
        const dbLookup = db
          .collection('current_clockins')
          .doc(docId)
          .get()
          .then(doc => {
            clockinData.clockinDocExists = doc.exists;
            clockinData.currentClockins = doc.data();
            this.selectedClockinData = clockinData;
            return true;
          })
          .catch(err => {
            console.log(err);
          });

        await dbLookup;
      }

      if (clockinData.clockinDocExists) {
        const current = clockinData.currentClockins.current;

        if (current.clockedIn) {
          const currentTimeChunk = {
            dbTimeChunk: null,
            day: date.formatDate(current.clockInTs, 'dddd, MMM D'),
            breakMin: current.breakMin,
            clockInTs: current.clockInTs,
            clockInTime: date.formatDate(current.clockInTs, 'h:mm A'),
            clockOutTime: '',
            diff: null,
            workType: current.workType,
            clockedIn: true
          };

          this.clocked.currentTimeChunk = currentTimeChunk;
        } else {
          this.clocked.currentTimeChunk = null;
        }

        if (current.clockedIn) {
          this.clocked.clockInTs = current.clockInTs;
          this.clocked.breakMin = current.breakMin;
          this.workTypes.model = current.workType;
          this.clocked.in = true;

          const currTs = +new Date();
          this.currentTime =
            currTs - current.clockInTs - current.breakMin * 60 * 1000;
          this.startTimer();
        } else {
          this.clocked.currentTimeChunk = null;
          this.clocked.clockInTs = null;
          this.clocked.breakMin = current.breakMin;
          this.workTypes.model = current.workType;
          this.clocked.in = false;
          this.currentTime = 0;
        }

        const formatTimeChunk = timeChunk => {
          let breakMin = timeChunk.breakMin;
          if (breakMin === '') {
            breakMin = 0;
          }

          let diff =
            timeChunk.clockOutTs - timeChunk.clockInTs - breakMin * 60 * 1000;
          diff = this.formatDiff(diff);
          const msDiff =
            timeChunk.clockOutTs - timeChunk.clockInTs - breakMin * 60 * 1000;

          const timeChunkObj = {
            dbTimeChunk: timeChunk,
            day: date.formatDate(timeChunk.clockInTs, 'dddd, MMM D'),
            breakMin: breakMin,
            clockInTime: date.formatDate(timeChunk.clockInTs, 'h:mm A'),
            clockOutTime: date.formatDate(timeChunk.clockOutTs, 'h:mm A'),
            diff: diff,
            msDiff: msDiff,
            workType: timeChunk.workType,
            clockedIn: false
          };
          return timeChunkObj;
        };

        // Format History
        const historyArr = [];
        if (clockinData.currentClockins.history) {
          const history = clockinData.currentClockins.history;
          for (let i = 0; i < history.length; i++) {
            const timeChunkObj = formatTimeChunk(history[i]);
            historyArr.push(timeChunkObj);
          }

          // Now lookup
          const startToday = new Date().setHours(0, 0, 0, 0);
          let dayOfWeek = new Date(startToday).getDay();
          // If Sunday
          dayOfWeek = dayOfWeek === 7 ? 0 : dayOfWeek;
          const startCurrentTs = startToday - 86400000 * (28 + dayOfWeek);

          const lookUpHistory = () => {
            const dates = this.dates;

            if (dates.startTs < startCurrentTs) {
              this.modes.loading = true;

              const clockinsDoc = db
                .collection('clockin_history')
                .where('latestTs', '>=', dates.startTs);

              const getFromDb = clockinsDoc.get().then(clockins => {
                clockins.forEach(doc => {
                  const historyChunk = doc.data();
                  if (historyChunk.userId === this.user.id) {
                    for (let i = 0; i < historyChunk.history.length; i++) {
                      const timeChunk = historyChunk.history[i];
                      if (
                        timeChunk.clockInTs > dates.startTs &&
                        timeChunk.clockInTs < dates.endTs
                      ) {
                        const timeChunkObj = formatTimeChunk(timeChunk);
                        historyArr.push(timeChunkObj);
                      }
                    }
                  }
                });
                return true;
              });

              return getFromDb;
            } else {
              return false;
            }
          };

          await lookUpHistory();

          const sortByClockIn = (a, b) => {
            if (a.dbTimeChunk.clockInTs > b.dbTimeChunk.clockInTs) {
              return -1;
            }
            if (a.dbTimeChunk.clockInTs < b.dbTimeChunk.clockInTs) {
              return 1;
            }
            return 0;
          };
          historyArr.sort(sortByClockIn);

          this.setHistory(historyArr);
        }
      } else {
        this.clocked.in = false;
        this.clocked.currentTimeChunk = null;
        this.currentTime = 0;
        this.clocked.breakMin = 0;
        this.workTypes.model = '';
        this.weeks = [];
      }

      this.modes.loading = false;
    },
    setCurrentDates() {
      this.insights.show = false;
      const startToday = new Date().setHours(0, 0, 0, 0);
      let dayOfWeek = date.getDayOfWeek(startToday);
      // If Sunday
      dayOfWeek = dayOfWeek === 7 ? 0 : dayOfWeek;

      let startTs = date.subtractFromDate(startToday, {
        days: dayOfWeek + 28
      });
      startTs = new Date(startTs).getTime();

      const startDate = date.formatDate(startTs, 'MMM D');
      const endTs = parseInt(
        date.formatDate(date.addToDate(startTs, { days: 35 }), 'x')
      );
      const endDate = date.formatDate(
        date.subtractFromDate(endTs, { days: 1 }),
        'MMM D'
      );

      this.dates = {
        startTs: startTs,
        startDate: startDate,
        endTs: endTs,
        endDate: endDate
      };

      this.setCurrentClockin();
    },
    setDatePicker() {
      this.displayDateRange = true;
    },
    setDates(from, to) {
      this.insights.show = false;
      this.displayDateRange = false;

      const startDate = date.formatDate(from, 'MMM D');
      const endDate = date.formatDate(
        date.subtractFromDate(to, { days: 1 }),
        'MMM D'
      );

      this.dates = {
        startTs: from,
        startDate: startDate,
        endTs: to,
        endDate: endDate
      };

      this.setCurrentClockin();
    },
    setHistory(historyArr) {
      const weeks = this.calcHistory(historyArr);
      this.weeks = weeks;
      this.printWeeks = weeks;
    },
    setWorkTypeList() {
      this.workTypes.options = this.$store.state.workTypes;
    },
    setWorkTypes() {
      this.workTypeSettings.show = true;
    },
    setUser() {
      const user = this.$store.state.user;
      if (user && this.userList) {
        if (!this.user.set) {
          this.user = {
            name: user.displayName,
            id: this.userList.users[user.displayName],
            set: true
          };

          for (const key in this.userList.users) {
            if (key === user.displayName) {
              this.userSelect.model = {
                label: key,
                id: this.userList.users[key]
              };
            }
            this.userSelect.options.push({
              label: key,
              id: this.userList.users[key]
            });
          }
        }
        this.setCurrentDates();
      }
    },
    startTimer() {
      if (this.timer) {
        this.clearTimer();
      }
      this.timer = setInterval(this.countUp, 1000);
    },
    stopTimer() {
      this.clearTimer();
      this.currentTime = 0;
    }
  },
  computed: {
    clockinData() {
      return JSON.parse(JSON.stringify(this.$store.state.clockinData));
    },
    isLoggedInUser() {
      return this.user.name === this.$store.state.user.displayName;
    },
    isSuperAdmin() {
      if (this.$store.state.user) {
        if (this.$store.state.user.superAdmin === true) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    seconds() {
      return Math.floor((Math.abs(this.currentTime) / 1000) % 60);
    },
    minutes() {
      return Math.floor((Math.abs(this.currentTime) / 1000 / 60) % 60);
    },
    hours() {
      return Math.floor(Math.abs(this.currentTime) / (1000 * 60 * 60));
    },
    userList() {
      return JSON.parse(JSON.stringify(this.$store.state.userList));
    }
  },
  watch: {
    '$store.state.clockinData'() {
      this.setUser();
    },
    '$store.state.user'() {
      this.setUser();
    },
    '$store.state.workTypes'() {
      this.setWorkTypeList();
    }
  }
};
</script>

<style scoped></style>

<style module>
.match {
  color: red;
}
table,
th,
td {
  border-bottom: 1px solid silver;
  border-top: 1px solid silver;
  border-collapse: collapse;
}
</style>
