<template>
  <div class="form-container">
    <OkDialog
      v-if="ok.show"
      :icon="ok.icon"
      :message="ok.message"
      @okConfirmed="ok.show = false"
    />

    <ConfirmHaul
      v-if="confirmHaulDisplay"
      :haulAmount="haulAmount"
      :measurementUnit="measurementUnit"
      :mileage="mileageObj"
      :source="source"
      :destination="destination"
      :sourceHaulTrackers="sourceHaulTrackers"
      :destHaulTrackers="destHaulTrackers"
      @canceled="confirmHaulDisplay = false"
      @confirmed="enterHaul"
    />

    <div class="q-pa-md q-mt-sm form">
      <div class="gt-xs q-py-md"></div>
      <q-form @submit="confirmHaul" class="q-gutter-md">
        <div
          v-if="edit.edit"
          class="row justify-center items-center rounded-borders q-py-xs q-px-sm q-mb-md"
          style="background: radial-gradient(circle, #3336eb 0%, #0b0d6f 100%)"
        >
          <q-icon
            color="custom-orange"
            name="local_shipping"
            size="lg"
            class="q-mr-sm"
          />
          <span class="text-body1 text-bold text-custom-orange q-ml-sm">
            Editing Haul
          </span>
        </div>

        <div
          v-if="!edit.edit"
          class="row justify-center items-center rounded-borders text-custom-light q-py-xs q-px-sm q-mb-md"
          style="background: radial-gradient(circle, #3336eb 0%, #0b0d6f 100%)"
        >
          <q-icon
            name="local_shipping"
            size="lg"
            color="custom-light"
            class="q-mr-sm"
          />
          <span class="text-body1 text-bold q-ml-sm"> Haul Info </span>
        </div>

        <div class="bg-grey-2 rounded-borders">
          <q-input
            outlined
            v-model.number="haulAmount"
            type="number"
            step="any"
            min="0"
            :label="`Enter ${measurementUnit}`"
            dense
            color="grey-9"
            @input="setMU"
          />
          <div v-if="validation.haulAmountErr" class="text-custom-red q-ml-sm">
            <span class="q-ml-xs"> Enter {{ measurementUnit }}</span>
            <span v-if="exclamation"> !!! </span>
          </div>
        </div>

        <div v-if="mileageRequired.require">
          <div class="bg-grey-2 rounded-borders">
            <q-input
              outlined
              v-model.number="mileage"
              type="tel"
              mask="##########"
              label="Enter Mileage"
              dense
              color="grey-9"
              @input="setMileage"
            />
            <div v-if="validation.mileageErr" class="text-custom-red q-ml-sm">
              <span class="q-ml-xs"> Enter Mileage </span>
              <span v-if="exclamation"> !!! </span>
            </div>
          </div>
        </div>

        <div>
          <div class="bg-grey-2 rounded-borders">
            <q-select
              outlined
              v-model="sourceModel"
              :options="sourceOptions"
              label="Select Source"
              color="grey-9"
              dense
              options-selected-class="text-primary"
              @input="setSource(false)"
            >
              <template v-slot:selected>
                <q-item>
                  <q-item-section avatar>
                    <q-icon color="custom-green" :name="sourceModel.icon" />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label v-html="sourceModel.label" />
                    <q-item-label class="text-primary text-bold">{{
                      sourceModel.description
                    }}</q-item-label>
                  </q-item-section>
                </q-item>
              </template>
              <template v-slot:option="source">
                <q-item v-bind="source.itemProps" v-on="source.itemEvents">
                  <q-item-section avatar>
                    <q-avatar
                      color="primary"
                      size="md"
                      text-color="white text-bold"
                    >
                      {{ source.opt.label.charAt(0) }}
                    </q-avatar>
                  </q-item-section>
                  <q-item-section>
                    <q-item-label v-html="source.opt.label" />
                    <q-item-label caption>{{
                      source.opt.description
                    }}</q-item-label>
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
            <div
              v-if="validation.sourceSelectedErr"
              class="text-custom-red q-ml-sm"
            >
              <span class="q-ml-xs"> Select Source </span>
              <span v-if="exclamation"> !!! </span>
            </div>
          </div>
        </div>

        <div
          v-if="!commoditiesMatch"
          class="row justify-center items-center text-custom-red bg-grey-2 q-pa-xs"
          style="border: 1px solid #9e9e9e; border-radius: 4px"
        >
          <q-icon color="custom-red" name="arrow_upward" size="sm" />
          <q-icon
            color="custom-red"
            name="arrow_downward"
            size="sm"
            class="q-ml-xs q-mr-sm"
          />
          Commodities do not match!!
          <q-icon
            color="custom-red"
            name="do_not_disturb_on"
            size="sm"
            class="q-ml-sm"
          />
        </div>

        <div>
          <div class="bg-grey-2 rounded-borders">
            <q-select
              outlined
              v-model="destinationModel"
              :options="destinationOptions"
              :label="'Select ' + destAs.slice(0, -1)"
              color="grey-9"
              dense
              options-selected-class="text-primary"
              @input="setDestination(false)"
            >
              <template v-slot:selected>
                <q-item>
                  <q-item-section avatar>
                    <q-icon
                      color="custom-red"
                      style="transform: rotate(180deg)"
                      :name="destinationModel.icon"
                    />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>
                      {{ destinationModel.label }}
                    </q-item-label>
                    <q-item-label class="text-primary text-bold">
                      {{ destinationModel.description }}
                    </q-item-label>
                    <q-item-label
                      v-if="destinationModel.projected !== 'Not Set'"
                      caption
                    >
                      <span v-if="destinationModel.projected !== ''">
                        Projected:
                      </span>
                      {{ destinationModel.projected.toLocaleString('en-US') }}
                    </q-item-label>
                    <q-item-label
                      v-if="destinationModel.projected === 'Not Set'"
                      caption
                    >
                      Projected:
                      <span class="text-red">
                        {{ destinationModel.projected }}
                      </span>
                    </q-item-label>
                    <q-item-label
                      v-if="destinationModel.projected !== ''"
                      caption
                    >
                      <span> Hauled: </span>
                      {{ destinationModel.hauled.toLocaleString('en-US') }}
                    </q-item-label>
                    <div
                      v-if="
                        destinationModel.projected !== 'Not Set' &&
                        destinationModel.projected !== ''
                      "
                      class="q-mt-xs"
                    >
                      <q-item-label caption class="text-custom-light-blue">
                        <span> Balance: </span>
                        {{ destinationModel.balance.toLocaleString('en-US') }}
                      </q-item-label>
                      <div class="q-my-sm" style="width: 100%">
                        <div
                          class="bg-indigo-2 rounded-borders"
                          style="width: 100%; height: 8px"
                        >
                          <div
                            class="bg-primary rounded-borders"
                            :style="{
                              width: destinationModel.percentHauled,
                              height: '8px'
                            }"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </q-item-section>
                </q-item>
              </template>
              <template v-slot:option="scope">
                <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                  <q-item-section avatar>
                    <q-avatar
                      color="primary"
                      size="md"
                      text-color="white text-bold"
                    >
                      {{ scope.opt.label.charAt(0) }}
                    </q-avatar>
                  </q-item-section>
                  <q-item-section>
                    <q-item-label v-html="scope.opt.labelHtml" />
                    <q-item-label caption>{{
                      scope.opt.description
                    }}</q-item-label>
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
            <div
              v-if="validation.destinationSelectedErr"
              class="text-custom-red q-ml-sm"
            >
              <span class="q-ml-xs"> Select Field </span>
              <span v-if="exclamation"> !!! </span>
            </div>
          </div>
        </div>

        <div
          v-for="(tracker, index) in sourceHaulTrackers"
          :key="'source' + tracker.label"
          class="bg-grey-2 rounded-borders"
        >
          <div v-if="!tracker.driverInput">
            <q-select
              outlined
              dense
              color="grey-9"
              v-model="tracker.model"
              :options="tracker.options"
              :label="'Select ' + tracker.label"
              @input="sourceTrackerInput(index, tracker.model)"
            />
            <div
              v-if="validation.sourceHaulTrackers[index].err"
              class="text-custom-red q-ml-sm"
            >
              <span class="q-ml-xs"> Select {{ tracker.label }} </span>
              <span v-if="exclamation"> !!! </span>
            </div>
          </div>
          <div v-if="tracker.driverInput">
            <q-input
              v-if="!tracker.numberInput"
              outlined
              dense
              color="grey-9"
              v-model="tracker.model"
              :label="tracker.label"
              @input="sourceTrackerInput(index, tracker.model)"
            />
            <q-input
              v-if="tracker.numberInput"
              outlined
              v-model.number="tracker.model"
              type="number"
              step="any"
              :label="tracker.label"
              dense
              color="grey-9"
              @input="sourceTrackerInput(index, tracker.model)"
            />
            <div
              v-if="validation.sourceHaulTrackers[index].err"
              class="text-custom-red q-ml-sm"
            >
              <span class="q-ml-xs"> Enter {{ tracker.label }} </span>
              <span v-if="exclamation"> !!! </span>
            </div>
          </div>
        </div>

        <div
          v-for="(tracker, index) in destHaulTrackers"
          :key="'dest' + tracker.label"
          class="bg-grey-2 rounded-borders"
        >
          <div v-if="!tracker.driverInput && !tracker.calcInfo.runCalc">
            <q-select
              outlined
              dense
              color="grey-9"
              v-model="tracker.model"
              :options="tracker.options"
              :label="'Select ' + tracker.label"
              @input="destinationTrackerInput(index, tracker.model)"
            />
            <div
              v-if="validation.destHaulTrackers[index].err"
              class="text-custom-red q-ml-sm"
            >
              <span class="q-ml-xs"> Select {{ tracker.label }} </span>
              <span v-if="exclamation"> !!! </span>
            </div>
          </div>

          <div v-if="tracker.driverInput && !tracker.calcInfo.runCalc">
            <q-input
              v-if="!tracker.numberInput"
              outlined
              dense
              color="grey-9"
              v-model="tracker.model"
              :label="tracker.label"
              @input="destinationTrackerInput(index, tracker.model)"
            />
            <q-input
              v-if="tracker.numberInput"
              outlined
              v-model.number="tracker.model"
              type="number"
              step="any"
              :label="tracker.label"
              dense
              color="grey-9"
              @input="destinationTrackerInput(index, tracker.model)"
            />
            <div
              v-if="validation.destHaulTrackers[index].err"
              class="text-custom-red q-ml-sm"
            >
              <span class="q-ml-xs"> Enter {{ tracker.label }} </span>
              <span v-if="exclamation"> !!! </span>
            </div>
          </div>

          <div
            v-if="tracker.calcInfo.requireNumInput && tracker.calcInfo.runCalc"
          >
            <q-input
              outlined
              v-model.number="tracker.model"
              type="number"
              step="any"
              :label="tracker.label"
              dense
              color="grey-9"
              @input="destinationTrackerInput(index, tracker.model)"
            />
            <div
              v-if="validation.destHaulTrackers[index].err"
              class="text-custom-red q-ml-sm"
            >
              <span class="q-ml-xs"> Enter {{ tracker.label }} </span>
              <span v-if="exclamation"> !!! </span>
            </div>
          </div>
        </div>

        <div class="bg-grey-2 rounded-borders">
          <q-input
            outlined
            v-model="haulNote"
            label="Haul Note"
            color="grey-9"
            autogrow
            dense
          />
        </div>
        <div v-if="sourceNote.length !== 0" class="bg-grey-2 rounded-borders">
          <div class="q-pa-sm">
            <span class="text-primary text-bold q-ml-xs"> Source Note: </span>
            {{ sourceNote }}
          </div>
        </div>
        <div
          v-if="destinationNote.length !== 0"
          class="bg-grey-2 rounded-borders"
        >
          <div class="q-pa-sm">
            <span class="text-primary text-bold q-ml-xs"> Field Note: </span>
            {{ destinationNote }}
          </div>
        </div>

        <div>
          <div class="text-bold text-primary">Haul Time</div>
          <q-input
            outlined
            dense
            v-model="dateTime"
            color="grey-9"
            class="bg-grey-2"
          >
            <template v-slot:prepend>
              <q-icon name="event" class="cursor-pointer" color="primary">
                <q-popup-proxy transition-show="scale" transition-hide="scale">
                  <q-date
                    v-model="dateTime"
                    mask="MMM Do, YYYY  -  h:mm:ss A"
                    color="primary"
                  >
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>

            <template v-slot:append>
              <q-icon name="access_time" class="cursor-pointer" color="primary">
                <q-popup-proxy transition-show="scale" transition-hide="scale">
                  <q-time
                    v-model="dateTime"
                    mask="MMM Do, YYYY  -  h:mm:ss A"
                    format12h
                    color="primary"
                  >
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-time>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </div>

        <div v-if="chooseDriver">
          <div class="bg-grey-2 rounded-borders">
            <q-select
              outlined
              v-model="driverModel"
              :options="driverOptions"
              label="Select Driver"
              color="grey-9"
              dense
              options-selected-class="text-primary"
            >
              <template v-slot:selected-item="driverModel">
                <q-item
                  v-bind="driverModel.itemProps"
                  v-on="driverModel.itemEvents"
                >
                  <q-item-section avatar>
                    <q-icon color="primary" :name="driverModel.opt.icon" />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label v-html="driverModel.opt.label" />
                    <q-item-label class="text-primary text-bold">{{
                      driverModel.opt.description
                    }}</q-item-label>
                  </q-item-section>
                </q-item>
              </template>
              <template v-slot:option="driver">
                <q-item v-bind="driver.itemProps" v-on="driver.itemEvents">
                  <q-item-section avatar>
                    <q-avatar
                      color="primary"
                      size="md"
                      text-color="white text-bold"
                    >
                      {{ driver.opt.label.charAt(0) }}
                    </q-avatar>
                  </q-item-section>
                  <q-item-section>
                    <q-item-label v-html="driver.opt.label" />
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
        </div>

        <div align="right" class="q-mb-xl">
          <q-btn
            v-if="!chooseDriver && isAdmin"
            flat
            icon="people"
            color="primary"
            padding="xs md"
            @click="chooseDriver = true"
          />
          <q-btn
            v-if="chooseDriver && isAdmin"
            flat
            icon="people"
            label="Default"
            color="primary"
            padding="xs md"
            @click="chooseDriver = false"
          />
          <q-btn
            v-if="edit.edit"
            :flat="!validation.readyToEnter"
            label="Edit Haul"
            icon="edit"
            type="submit"
            color="primary"
            padding="xs md"
            class="q-ml-sm"
          />
          <q-btn
            v-if="!edit.edit"
            :flat="!validation.readyToEnter"
            label="Enter Haul"
            type="submit"
            color="primary"
            padding="xs md"
            class="q-ml-sm"
          />
          <div class="lt-sm q-py-lg"></div>
        </div>
      </q-form>
    </div>
  </div>
</template>

<script>
//import firebase from 'firebase/compat/app';
import firebaseApp from '@/firebase.js';
import {
  doc,
  deleteField,
  setDoc,
  getFirestore,
  getDoc,
  getDocs,
  increment,
  updateDoc,
  collection,
  writeBatch,
  where,
  query,
  orderBy,
  limit,
  onSnapshot
} from 'firebase/firestore';
import ConfirmHaul from '@/components/hauls/ConfirmHaul.vue';
import OkDialog from '@/components/OkDialog.vue';
import { date } from 'quasar';

const db = getFirestore(firebaseApp);
//const db = firebase.firestore();

export default {
  name: 'EnterHaul',
  components: {
    ConfirmHaul,
    OkDialog
  },
  props: {
    edit: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activeSources: [],
      activeDestinations: [],
      callLimit: 0,
      chooseDriver: false,
      commoditiesMatch: true,
      confirmHaulDisplay: false,
      dateTime: '',
      destHaulTrackers: [],
      destination: null,
      destinationModel: { icon: 'outbound', projected: '' },
      destinationNote: '',
      destinationOptions: [],
      driver: '',
      driverModel: { icon: 'person' },
      driverOptions: [],
      exclamation: false,
      haulNote: '',
      isAdmin: false,
      lastHaulSet: false,
      masterDestHaulTrackers: [],
      masterSourceHaulTrackers: [],
      mileage: '',
      mileageObj: {},
      mileageRequired: {
        dest: false,
        source: false,
        require: false
      },
      ok: {
        show: false,
        icon: '',
        message: ''
      },
      source: null,
      sourceHaulTrackers: [],
      sourceModel: { icon: 'outbound' },
      sourceNote: '',
      sourceOptions: [],
      haulAmount: '',
      user: null,
      validation: {
        readyToEnter: false,
        destinationSelected: false,
        destinationSelectedErr: true,
        sourceSelected: false,
        sourceSelectedErr: true,
        haulAmount: false,
        haulAmountErr: true,
        mileage: false,
        mileageErr: true,
        destHaulTrackers: [],
        sourceHaulTrackers: []
      }
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.setUser();
    this.setDestinationOptions();
    this.setSourceOptions();
    this.setLastHaul();
    this.dateTime = new Date();
    this.dateTime = date.formatDate(
      this.dateTime,
      'MMM Do, YYYY  -  h:mm:ss A'
    );
    if (this.edit.edit) {
      this.dateTime = date.formatDate(
        this.edit.haul.dateTime,
        'MMM Do, YYYY  -  h:mm:ss A'
      );
      this.validation.haulAmount = true;
      this.validation.haulAmountErr = false;
      this.checkReadyToEnter();
    }
  },
  methods: {
    clearForm() {
      this.haulAmount = '';
      this.validation.haulAmountErr = true;
      this.validation.haulAmount = false;
      this.mileage = '';
      this.validation.mileageErr = true;
      this.validation.mileage = false;
      this.checkReadyToEnter();
    },
    checkReadyToEnter() {
      this.exclamation = false;

      let readyToEnter = true;
      if (!this.commoditiesMatch) {
        readyToEnter = false;
      }
      if (this.validation.haulAmount === false) {
        readyToEnter = false;
      }
      if (this.validation.mileage === false && this.mileageRequired.require) {
        readyToEnter = false;
      }
      if (this.validation.sourceSelected === false) {
        readyToEnter = false;
      }
      if (this.validation.destinationSelected === false) {
        readyToEnter = false;
      }
      for (let i = 0; i < this.validation.sourceHaulTrackers.length; i++) {
        if (this.validation.sourceHaulTrackers[i].valSelected === false) {
          readyToEnter = false;
        }
      }
      for (let i = 0; i < this.validation.destHaulTrackers.length; i++) {
        if (this.validation.destHaulTrackers[i].valSelected === false) {
          readyToEnter = false;
        }
      }

      if (readyToEnter === true) {
        this.validation.readyToEnter = true;
      } else {
        this.validation.readyToEnter = false;
      }
    },
    confirmHaul() {
      let ready = true;
      if (this.validation.readyToEnter === false) {
        if (this.validation.haulAmount === false) {
          this.validation.haulAmountErr = true;
          ready = false;
        }
        if (this.validation.mileage === false) {
          this.validation.mileageErr = true;
          ready = false;
        }
        if (this.validation.sourceSelected === false) {
          this.validation.sourceSelectedErr = true;
          ready = false;
        }
        if (this.validation.destinationSelected === false) {
          this.validation.destinationSelectedErr = true;
          ready = false;
        }
        for (let i = 0; i < this.validation.sourceHaulTrackers.length; i++) {
          if (this.validation.sourceHaulTrackers[i].valSelected === false) {
            this.validation.sourceHaulTrackers[i].err = true;
            ready = false;
          }
        }
        for (let i = 0; i < this.validation.destHaulTrackers.length; i++) {
          if (this.validation.destHaulTrackers[i].valSelected === false) {
            this.validation.destHaulTrackers[i].err = true;
            ready = false;
          }
        }
      }

      if (ready === false) {
        this.exclamation = true;
        return;
      }

      this.exclamation = false;

      this.mileageObj = {
        mileage: this.mileage,
        mileageRequired: this.mileageRequired.require
      };

      this.confirmHaulDisplay = true;
    },
    destinationTrackerInput(index, model) {
      if (model) {
        this.validation.destHaulTrackers[index].valSelected = true;
        this.validation.destHaulTrackers[index].err = false;
      } else {
        this.validation.destHaulTrackers[index].valSelected = false;
        this.validation.destHaulTrackers[index].err = true;
      }
      this.checkReadyToEnter();
    },
    editHaul() {
      const edit = JSON.parse(JSON.stringify(this.edit));

      let haul = {
        haulId: edit.haul.haulId,
        dateTime: edit.haul.dateTime,
        destinationId: edit.haul.destinationId,
        destinationCustomerId: edit.haul.destinationCustomerId,
        sourceAsDestination: edit.haul.sourceAsDestination,
        destinationLocationId: edit.haul.destinationLocationId,
        destinationHaulTrackers: edit.haul.destinationHaulTrackers,
        driver: edit.haul.driver,
        runningBalance: edit.haul.runningBalance,
        sourceId: edit.haul.sourceId,
        sourceCustomerId: edit.haul.sourceCustomerId,
        sourceLocationId: edit.haul.sourceLocationId,
        sourceHaulTrackers: edit.haul.sourceHaulTrackers,
        haulAmount: edit.haul.haulAmount,
        mileage: edit.haul.mileage,
        haulNote: edit.haul.haulNote
      };

      haul = JSON.parse(JSON.stringify(haul));

      const destId = haul.destinationId;
      const sourceId = haul.sourceId;

      const sourceHaulTrackers = [];
      for (let i = 0; i < this.sourceHaulTrackers.length; i++) {
        sourceHaulTrackers.push({
          type: this.sourceHaulTrackers[i].label,
          option: this.sourceHaulTrackers[i].model
        });
      }
      const destHaulTrackers = this.runCalculations();

      let dateTime = +new Date(
        date.extractDate(this.dateTime, 'MMM Do, YYYY  -  h:mm:ss A')
      );

      // Need to make sure dateTime has unique val (only adjusting milliseconds)
      if (dateTime.toString().endsWith('000')) {
        const rand = Math.floor(Math.random() * (999 - 100) + 100);
        dateTime += rand;
      }

      let sourceRunning = false;
      if (this.source.trackRunningBalance) {
        sourceRunning = true;
      }

      let destinationRunning = false;
      if (this.destination.trackRunningBalance) {
        destinationRunning = true;
      }

      let driver = this.driver;
      if (this.chooseDriver) {
        driver = this.driverModel.value;
      }

      // if track mileage has been removed and user is editing hauls
      let mileage = this.mileage;
      if (!this.mileageRequired.require) {
        mileage = 0;
      }

      //const haulId = db.collection('last_hauls').doc();
      const haulId = doc(collection(db, 'last_hauls'));
      console.log(haulId.id);

      const haulObj = {
        haulId: haulId.id,
        driver: driver,
        runningBalance: {
          sourceRunning: sourceRunning,
          destinationRunning: destinationRunning
        },
        sourceAsDestination: this.destination.trackRunningBalance,
        dateTime: dateTime,
        haulAmount: this.haulAmount,
        mileage: mileage,
        sourceId: this.source.id,
        sourceCustomerId: this.source.customerId,
        sourceLocationId: this.source.sourceLocationId,
        destinationId: this.destination.id,
        destinationCustomerId: this.destination.customerId,
        destinationLocationId: this.destination.destinationLocationId,
        sourceHaulTrackers: sourceHaulTrackers,
        destinationHaulTrackers: destHaulTrackers,
        haulNote: this.haulNote
      };

      let newHaul = JSON.parse(JSON.stringify(haulObj));

      //const batch = db.batch();
      const batch = writeBatch(db);

      const sourceDeleteRef = doc(db, 'sources_active_users', sourceId);
      //const sourceDeleteDoc = db
      //.collection('sources_active_users')
      //.doc(sourceId);

      const destDeleteRef = doc(db, 'destinations_active_users', destId);
      //const destDeleteDoc = db
      //.collection('destinations_active_users')
      //.doc(destId);
      const destRunningDeleteRef = doc(db, 'sources_active_users', destId);
      //const destRunningDeleteDoc = db
      //.collection('sources_active_users')
      //.doc(destId);

      const sourceRef = doc(db, 'sources_active_users', this.source.id);
      //const sourceDoc = db
      //.collection('sources_active_users')
      //.doc(this.source.id);

      const destRef = doc(db, 'destinations_active_users', this.destination.id);
      //const destDoc = db
      //.collection('destinations_active_users')
      //.doc(this.destination.id);
      const destRunningRef = doc(
        db,
        'sources_active_users',
        this.destination.id
      );
      //const destRunningDoc = db
      //.collection('sources_active_users')
      //.doc(this.destination.id);

      if (haul.runningBalance.sourceRunning) {
        batch.update(sourceDeleteRef, {
          [`hauls.${edit.haul.haulId}`]: deleteField(),
          runningBalance: increment(haul.haulAmount)
        });
      } else {
        batch.update(sourceDeleteRef, {
          [`hauls.${edit.haul.haulId}`]: deleteField()
        });
      }

      // running balance logic torture!!!

      if (haul.runningBalance.destinationRunning) {
        // convert to negative then increment
        batch.update(destRunningDeleteRef, {
          [`hauls.${edit.haul.haulId}`]: deleteField(),
          runningBalance: increment(-Math.abs(haul.haulAmount))
        });
      } else {
        batch.update(destDeleteRef, {
          [`hauls.${edit.haul.haulId}`]: deleteField()
        });
      }

      if (sourceRunning) {
        batch.update(sourceRef, {
          [`hauls.${haulId.id}`]: newHaul,
          runningBalance: increment(-Math.abs(this.haulAmount))
        });
      } else {
        batch.update(sourceRef, {
          [`hauls.${haulId.id}`]: newHaul
        });
      }

      if (destinationRunning) {
        batch.update(destRunningRef, {
          [`hauls.${haulId.id}`]: newHaul,
          runningBalance: increment(this.haulAmount)
        });
      } else {
        batch.update(destRef, {
          [`hauls.${haulId.id}`]: newHaul
        });
      }

      batch.commit().catch(err => {
        console.log(err);
      });

      this.clearForm();
      this.notify('Haul Edited', 'custom-orange');
      this.$emit('onShowList');
    },
    async enterHaul() {
      this.confirmHaulDisplay = false;

      if (
        this.source.customer === this.destination.customer &&
        this.source.sourceLocationId === this.destination.destinationLocationId
      ) {
        // Let User Know
        this.ok.show = true;
        this.ok.icon = 'warning_amber';
        this.ok.message = 'Source and Destination are the same!';
        return;
      }

      if (this.edit.edit) {
        this.editHaul();
        return;
      }

      let sourceRunning = false;
      if (this.source.trackRunningBalance) {
        sourceRunning = true;
      }

      let destinationRunning = false;
      if (this.destination.trackRunningBalance) {
        destinationRunning = true;
      }

      const sourceHaulTrackers = [];
      for (let i = 0; i < this.sourceHaulTrackers.length; i++) {
        sourceHaulTrackers.push({
          type: this.sourceHaulTrackers[i].label,
          option: this.sourceHaulTrackers[i].model
        });
      }

      const destHaulTrackers = this.runCalculations();

      let haulAmount = this.haulAmount;
      const mileage = this.mileage === '' ? 0 : this.mileage;

      let dateTime = +new Date(
        date.extractDate(this.dateTime, 'MMM Do, YYYY  -  h:mm:ss A')
      );

      // Need to make sure dateTime has unique val (only adjusting milliseconds)
      if (dateTime.toString().endsWith('000')) {
        const rand = Math.floor(Math.random() * (999 - 100) + 100);
        dateTime += rand;
      }

      let driver = this.driver;
      if (this.chooseDriver) {
        driver = this.driverModel.value;
      }

      //const haulId = db.collection('last_hauls').doc();
      const haulId = doc(collection(db, 'last_hauls'));

      const haul = {
        haulId: haulId.id,
        driver: driver,
        sourceAsDestination: this.destination.trackRunningBalance,
        runningBalance: {
          sourceRunning: sourceRunning,
          destinationRunning: destinationRunning
        },
        dateTime: dateTime,
        haulAmount: haulAmount,
        mileage,
        sourceId: this.source.id,
        sourceCustomerId: this.source.customerId,
        sourceLocationId: this.source.sourceLocationId,
        destinationId: this.destination.id,
        destinationCustomerId: this.destination.customerId,
        destinationLocationId: this.destination.destinationLocationId,
        sourceHaulTrackers: sourceHaulTrackers,
        destinationHaulTrackers: destHaulTrackers,
        haulNote: this.haulNote
      };

      const newHaul = JSON.parse(JSON.stringify(haul));

      //const batch = db.batch();
      const batch = writeBatch(db);

      const lastHaulRef = doc(db, 'settings_users_writable', 'last_hauls');
      //const lastHaulDoc = db
      //.collection('settings_users_writable')
      //.doc('last_hauls');

      const sourceRef = doc(db, 'sources_active_users', this.source.id);
      //const sourcesDoc = db
      //.collection('sources_active_users')
      //.doc(this.source.id);
      const destRef = doc(db, 'destinations_active_users', this.destination.id);
      //const destinationsDoc = db
      //.collection('destinations_active_users')
      //.doc(this.destination.id);
      const destRunningRef = doc(
        db,
        'sources_active_users',
        this.destination.id
      );
      //const destRunning = db
      //.collection('sources_active_users')
      //.doc(this.destination.id);

      const userObj = {
        [this.user.displayName]: newHaul
      };

      batch.update(lastHaulRef, userObj);

      if (sourceRunning) {
        batch.update(sourceRef, {
          [`hauls.${haulId.id}`]: newHaul,
          runningBalance: increment(-Math.abs(this.haulAmount))
        });
      } else {
        batch.update(sourceRef, {
          [`hauls.${haulId.id}`]: newHaul
        });
      }

      if (destinationRunning) {
        batch.update(destRunningRef, {
          [`hauls.${haulId.id}`]: newHaul,
          runningBalance: increment(this.haulAmount)
        });
      } else {
        batch.update(destRef, {
          [`hauls.${haulId.id}`]: newHaul
        });
      }

      batch.commit().catch(err => {
        console.log(err);
      });

      // This setTimeout attempts to catch rogue haul errors
      setTimeout(() => {
        let sourceMatch = false;
        let destMatch = false;
        const activeSourcesUsers = JSON.parse(
          JSON.stringify(this.$store.state.activeSourcesUsers)
        );
        for (let i = 0; i < activeSourcesUsers.length; i++) {
          const source = activeSourcesUsers[i];
          if (source.id === haul.sourceId) {
            for (const key in source.hauls) {
              if (key === haul.haulId) {
                sourceMatch = true;
              }
            }
          }
        }
        if (haul.sourceAsDestination) {
          for (let i = 0; i < activeSourcesUsers.length; i++) {
            const source = activeSourcesUsers[i];
            if (source.id === haul.destinationId) {
              for (const key in source.hauls) {
                if (key === haul.haulId) {
                  destMatch = true;
                }
              }
            }
          }
        }

        const activeDestinationsUsers = JSON.parse(
          JSON.stringify(this.$store.state.activeDestinationsUsers)
        );
        for (let i = 0; i < activeDestinationsUsers.length; i++) {
          const dest = activeDestinationsUsers[i];
          if (dest.id === haul.destinationId) {
            for (const key in dest.hauls) {
              if (key === haul.haulId) {
                destMatch = true;
              }
            }
          }
        }
        if (!sourceMatch || !destMatch) {
          haul.dateTimeReadable = new Date(haul.dateTime);
          setDoc(doc(db, 'errors', haul.haulId), haul);

          //db.collection('errors').doc(haul.haulId).set(haul);

          //this.$q
          //.dialog({
          //title: 'Alert',
          //message: `An error occured with your haul! Please let Phil know he has a problem!`
          //})
          //.onOk(() => {});
        }
      }, 700);

      await new Promise(resolve => setTimeout(resolve, 200));

      //console.log(await test);

      this.clearForm();
      this.notify('Haul Entered', 'primary');
      this.$emit('onShowList');
    },
    notify(message, color) {
      this.$q.notify({
        color: color,
        textColor: 'white',
        icon: 'local_shipping',
        message: message
      });
    },
    round(num) {
      return Math.round((num + Number.EPSILON) * 100) / 100;
    },
    runCalculations() {
      let haulAmount = this.haulAmount;

      const checkValidNum = n => {
        const num = +n;

        const isValidNum =
          (Number(num) === num && num % 1 === 0) ||
          (Number(num) === num && num % 1 !== 0) ||
          n === 'Haul_Amount' ||
          n === 'Driver_Input';

        return isValidNum;
      };

      const checkValidOperator = sym => {
        return sym === '+' || sym === '-' || sym === '*' || sym === '/';
      };

      // Run Calculators
      let total = 0;
      const destHaulTrackers = [];
      for (let i = 0; i < this.destHaulTrackers.length; i++) {
        const tracker = this.destHaulTrackers[i];

        if (tracker.calcInfo.runCalc) {
          const driverInput = tracker.model;
          let calcArr = tracker.calcInfo.calcStr.split('|');

          if (calcArr[0] === 'if') {
            const endFirstCalcStr = calcArr.findIndex(el => el === '}');
            const firstArr = calcArr.slice(7).slice(0, endFirstCalcStr - 7);
            const secondArr = calcArr.slice(endFirstCalcStr + 3).slice(0, -1);

            const compare = calcArr[3];
            let compareLeft = calcArr[2];
            compareLeft =
              compareLeft === 'Driver_Input' ? driverInput : compareLeft;
            compareLeft =
              compareLeft === 'Haul_Amount' ? haulAmount : compareLeft;

            let compareRight = calcArr[4];
            compareRight =
              compareRight === 'Driver_Input' ? driverInput : compareRight;
            compareRight =
              compareRight === 'Haul_Amount' ? haulAmount : compareRight;

            if (compare === '<') {
              if (compareLeft < compareRight) {
                calcArr = firstArr.slice();
              } else {
                calcArr = secondArr.slice();
              }
            } else if (compare === '>') {
              if (compareLeft > compareRight) {
                calcArr = firstArr.slice();
              } else {
                calcArr = secondArr.slice();
              }
            }
          }

          let parens = true;
          let skipParens = 0;
          while (parens) {
            let hasParens = false;
            for (let j = 0; j < calcArr.length; j++) {
              if (calcArr[j] === '(') {
                hasParens = true;
                if (skipParens === 0) {
                  total = 0;
                  let startCalc = true;
                  let savedOperator = null;
                  let count = j + 1;
                  const startSplice = j;
                  let spliceCounter = 1;
                  while (count <= calcArr.length && calcArr[count] !== ')') {
                    let x = calcArr[count];
                    if (calcArr[count] === '(') {
                      skipParens++;
                      break;
                    } else if (checkValidNum(x)) {
                      x = x === 'Driver_Input' ? driverInput : x;
                      x = x === 'Haul_Amount' ? haulAmount : x;
                      if (startCalc) {
                        total = x;
                        startCalc = false;
                      } else {
                        if (savedOperator === '+') {
                          total += x;
                        } else if (savedOperator === '-') {
                          total -= x;
                        } else if (savedOperator === '*') {
                          total *= x;
                        } else if (savedOperator === '/') {
                          total /= x;
                        }
                      }
                    } else if (checkValidOperator(x)) {
                      savedOperator = x;
                    }
                    spliceCounter++;
                    count++;
                  }
                  if (skipParens === 0) {
                    calcArr.splice(startSplice, spliceCounter + 1, total);
                  }
                  break;
                } else {
                  skipParens--;
                }
              }
            }
            if (!hasParens) {
              total = 0;
              let startCalc = true;
              let savedOperator = null;
              for (let k = 0; k < calcArr.length; k++) {
                let x = calcArr[k];
                if (checkValidNum(x)) {
                  x = x === 'Driver_Input' ? driverInput : x;
                  x = x === 'Haul_Amount' ? haulAmount : x;
                  if (startCalc) {
                    total = x;
                    startCalc = false;
                  } else {
                    if (savedOperator === '+') {
                      total += x;
                    } else if (savedOperator === '-') {
                      total -= x;
                    } else if (savedOperator === '*') {
                      total *= x;
                    } else if (savedOperator === '/') {
                      total /= x;
                    }
                  }
                } else if (checkValidOperator(x)) {
                  savedOperator = x;
                }
              }
              parens = false;
            }
          }
        }

        total = this.round(total);

        if (tracker.calcInfo.runCalc) {
          if (tracker.calcInfo.calcTotalAsHaulAmount) {
            destHaulTrackers.push({
              type: '***&&&^^^' + tracker.calcInfo.haulAmountLabel,
              option: this.haulAmount
            });

            this.haulAmount = total;

            if (tracker.calcInfo.requireNumInput) {
              destHaulTrackers.push({
                type: '***&&&###' + tracker.label,
                option: tracker.model
              });
            }
          } else {
            if (tracker.calcInfo.requireNumInput) {
              destHaulTrackers.push({
                type: '***&&&###' + tracker.label,
                option: tracker.model
              });

              destHaulTrackers.push({
                type: '***&&&***' + tracker.calcInfo.calcAmountLabel,
                option: total
              });
            } else {
              destHaulTrackers.push({
                type: '***&&&***' + tracker.label,
                option: total
              });
            }
          }
        } else {
          destHaulTrackers.push({
            type: tracker.label,
            option: tracker.model
          });
        }
      }

      return destHaulTrackers;
    },
    setDestination(preserveMileage) {
      this.validation.destinationSelected = true;
      this.validation.destinationSelectedErr = false;

      this.destination =
        this.activeDestinations[this.destinationModel.optionIndex];

      this.commoditiesMatch = true;
      if (this.destination.commodity) {
        if (this.source) {
          if (this.source.commodity !== this.destination.commodity) {
            this.commoditiesMatch = false;
          }
        }
      }

      this.mileageRequired.dest = this.destination.trackMileage;
      this.setMileageRequired();
      if (!preserveMileage) {
        this.setMileageBlank();
      }
      this.destinationNote = this.destination.note;
      this.destHaulTrackers = [];
      this.validation.destHaulTrackers = [];
      for (let i = 0; i < this.masterDestHaulTrackers.length; i++) {
        for (let j = 0; j < this.destination.haulTrackers.length; j++) {
          if (
            this.masterDestHaulTrackers[i].name ===
            this.destination.haulTrackers[j]
          ) {
            const selectObj = {
              model: '',
              options: this.masterDestHaulTrackers[i].options,
              label: this.masterDestHaulTrackers[i].name,
              driverInput: this.masterDestHaulTrackers[i].driverInput,
              numberInput: this.masterDestHaulTrackers[i].numberInput,
              calcInfo: this.masterDestHaulTrackers[i].calcInfo
            };
            this.destHaulTrackers.push(selectObj);
            if (
              !selectObj.calcInfo.requireNumInput &&
              selectObj.calcInfo.runCalc
            ) {
              this.validation.destHaulTrackers.push({
                valSelected: true,
                err: false
              });
            } else {
              this.validation.destHaulTrackers.push({
                valSelected: false,
                err: true
              });
            }
          }
        }
      }
      this.checkReadyToEnter();
    },
    setDestinationOptions() {
      const destinations = JSON.parse(
        JSON.stringify(this.$store.state.activeDestinationsUsers)
      );
      const sources = JSON.parse(
        JSON.stringify(this.$store.state.activeSourcesUsers)
      );

      for (let i = 0; i < destinations.length; i++) {
        destinations[i].trackRunningBalance = false;
      }

      for (let i = 0; i < sources.length; i++) {
        if (sources[i].trackRunningBalance && sources[i].endDate === null) {
          const convertToDest = {
            id: sources[i].id,
            customerId: sources[i].customerId,
            destinationStatus: sources[i].sourceStatus,
            projected: '',
            destinationLocationId: sources[i].sourceLocationId,
            trackRunningBalance: true,
            runningBalance: sources[i].runningBalance,
            haulTrackers: sources[i].haulTrackers,
            note: sources[i].note,
            endDate: null,
            hauls: sources[i].hauls,
            commodity: sources[i].commodity
          };

          destinations.push(convertToDest);
        }
      }

      const sortByCustomer = (a, b) => {
        let sortByA;
        if (!a.trackRunningBalance) {
          sortByA =
            this.customers[a.customerId].name +
            this.masterDestinations[a.destinationLocationId].destinationName;
        } else {
          sortByA =
            this.customers[a.customerId].name +
            this.masterSources[a.destinationLocationId].sourceName;
        }
        let sortByB;
        if (!b.trackRunningBalance) {
          sortByB =
            this.customers[b.customerId].name +
            this.masterDestinations[b.destinationLocationId].destinationName;
        } else {
          sortByB =
            this.customers[b.customerId].name +
            this.masterSources[b.destinationLocationId].sourceName;
        }
        if (sortByA < sortByB) {
          return -1;
        }
        if (sortByA > sortByB) {
          return 1;
        }
        return 0;
      };

      destinations.sort(sortByCustomer);

      this.destinationOptions = [];
      this.activeDestinations = [];
      let optionIndex = 0;
      for (let i = 0; i < destinations.length; i++) {
        if (
          destinations[i].endDate !== null ||
          destinations[i].destinationStatus !== 'Open'
        ) {
          continue;
        }

        let projected = 'Not Set';
        let label =
          '<span style="color: #2176ff;"> - Source/' +
          this.destAs.slice(0, -1) +
          '</span>';
        if (!destinations[i].trackRunningBalance) {
          destinations[i].trackRunningBalance = false;
          label = '';
        }
        let hauled = 0;
        let balance;
        let percentHauled;
        for (const key in destinations[i].hauls) {
          const haul = destinations[i].hauls[key];
          hauled += haul.haulAmount;
        }

        if (destinations[i].projected) {
          projected = destinations[i].projected;

          balance = projected - hauled;
          balance = balance < 0 ? 0 : balance;

          percentHauled = this.round((hauled / projected) * 100);

          if (
            balance <= this.settingsUsers.destinationSettings.projectedTolerance
          ) {
            percentHauled = 100;
          }

          percentHauled = percentHauled > 100 ? 100 : percentHauled;

          percentHauled = percentHauled + '%';
        }
        const option = {
          labelHtml: this.customers[destinations[i].customerId].name + label,
          label: this.customers[destinations[i].customerId].name,
          optionIndex: optionIndex,
          icon: 'outbound',
          projected: projected,
          balance: this.round(balance),
          hauled: this.round(hauled),
          percentHauled: percentHauled
        };
        if (!destinations[i].trackRunningBalance) {
          option.description =
            this.masterDestinations[
              destinations[i].destinationLocationId
            ].destinationName;
          option.trackRunningBalance = false;
        } else {
          option.description =
            this.masterSources[
              destinations[i].destinationLocationId
            ].sourceName;
          option.trackRunningBalance = true;
        }
        optionIndex++;
        this.destinationOptions.push(option);
        this.activeDestinations.push(destinations[i]);
      }

      this.setLastHaul();
    },
    setDriverOptions() {
      const usersArr = [];
      for (const key in this.userList.users) {
        usersArr.push({ id: this.userList.users[key], name: key });
      }

      const sortByUser = (a, b) => {
        if (a.name > b.name) {
          return -1;
        }
        if (a.name < b.name) {
          return 1;
        }
        return 0;
      };

      usersArr.sort(sortByUser);

      this.driverOptions = [];
      for (let i = 0; i < usersArr.length; i++) {
        const selectObj = {
          value: usersArr[i].id,
          label: usersArr[i].name,
          icon: 'person'
        };
        this.driverOptions.push(selectObj);
        if (this.driver === usersArr[i].id) {
          this.driverModel = this.driverOptions[i];
        }
      }
    },
    setLastHaul() {
      if (!this.lastHaulSet) {
        if (
          this.user &&
          this.activeDestinations.length &&
          this.activeSources.length
        ) {
          const user = this.user.displayName;
          this.masterDestHaulTrackers =
            this.settingsUsers.destinationSettings.haulTrackers;
          this.masterSourceHaulTrackers =
            this.settingsUsers.sourceSettings.haulTrackers;

          let lastHaul = this.settingsUsers.lastHauls[user];
          const shortcut = this.$store.state.haulShortcut;

          if (lastHaul) {
            const edit = JSON.parse(JSON.stringify(this.edit));

            this.mileage = lastHaul.mileage;
            this.setMileage();
            const preserveMileage = true;
            if (edit.edit) {
              lastHaul = edit.haul;
              this.driver = edit.haul.driver;
              this.driverModel = {
                icon: 'person',
                value: edit.haul.driver,
                label: this.userList.userIds[edit.haul.driver]
              };

              this.haulAmount = edit.haul.haulAmount;
              this.mileage = edit.haul.mileage;
              this.haulNote = edit.haul.haulNote;
              this.setMileage();
            }
            if (!shortcut) {
              for (let i = 0; i < this.destinationOptions.length; i++) {
                // Account for hauls where source is entered as destination
                let destName;
                if (!lastHaul.sourceAsDestination) {
                  destName =
                    this.masterDestinations[lastHaul.destinationLocationId]
                      .destinationName;
                } else {
                  destName =
                    this.masterSources[lastHaul.destinationLocationId]
                      .sourceName;
                }

                if (
                  this.destinationOptions[i].label ===
                    this.customers[lastHaul.destinationCustomerId].name &&
                  this.destinationOptions[i].description === destName
                ) {
                  this.destinationModel = this.destinationOptions[i];
                  this.setDestination(preserveMileage);
                  for (let j = 0; j < this.destHaulTrackers.length; j++) {
                    for (
                      let k = 0;
                      k < lastHaul.destinationHaulTrackers.length;
                      k++
                    ) {
                      const trackerLabel = this.trackerLabel(
                        lastHaul.destinationHaulTrackers[k].type
                      );

                      if (this.destHaulTrackers[j].label === trackerLabel) {
                        if (
                          !this.destHaulTrackers[j].driverInput &&
                          !this.destHaulTrackers[j].calcInfo.runCalc
                        ) {
                          this.destHaulTrackers[j].model =
                            lastHaul.destinationHaulTrackers[k].option;
                          this.destinationTrackerInput(
                            j,
                            lastHaul.destinationHaulTrackers[k].option
                          );
                        } else {
                          if (this.edit.edit) {
                            this.destHaulTrackers[j].model =
                              lastHaul.destinationHaulTrackers[k].option;
                            this.destinationTrackerInput(
                              j,
                              lastHaul.destinationHaulTrackers[k].option
                            );

                            // If calcTotalAsHaulAmount & we're editing we need to do
                            // this loop & set haul amount
                            if (
                              this.destHaulTrackers[j].calcInfo
                                .calcTotalAsHaulAmount
                            ) {
                              const haulAmountLabel =
                                this.destHaulTrackers[j].calcInfo
                                  .haulAmountLabel;
                              for (
                                let l = 0;
                                l < lastHaul.destinationHaulTrackers.length;
                                l++
                              ) {
                                const trackerLabel = this.trackerLabel(
                                  lastHaul.destinationHaulTrackers[l].type
                                );
                                if (haulAmountLabel === trackerLabel) {
                                  this.haulAmount =
                                    lastHaul.destinationHaulTrackers[l].option;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            } else {
              for (let i = 0; i < this.destinationOptions.length; i++) {
                // Account for hauls where source is entered as destination
                let destName;
                destName =
                  this.masterDestinations[shortcut.locationId].destinationName;

                if (this.destinationOptions[i].description === destName) {
                  this.destinationModel = this.destinationOptions[i];
                  this.setDestination(preserveMileage);
                  for (let i = 0; i < this.destHaulTrackers.length; i++) {
                    for (
                      let j = 0;
                      j < lastHaul.destinationHaulTrackers.length;
                      j++
                    ) {
                      if (
                        this.destHaulTrackers[i].label ===
                        lastHaul.destinationHaulTrackers[j].type
                      ) {
                        if (!this.destHaulTrackers[i].driverInput) {
                          this.destHaulTrackers[i].model =
                            lastHaul.destinationHaulTrackers[j].option;
                          this.destinationTrackerInput(
                            i,
                            lastHaul.destinationHaulTrackers[j].option
                          );
                        }
                      }
                    }
                  }
                }
              }
              this.$store.dispatch('setHaulShortcut', null);
            }

            for (let i = 0; i < this.sourceOptions.length; i++) {
              if (
                this.sourceOptions[i].label ===
                  this.customers[lastHaul.sourceCustomerId].name &&
                this.sourceOptions[i].description ===
                  this.masterSources[lastHaul.sourceLocationId].sourceName
              ) {
                this.sourceModel = this.sourceOptions[i];
                this.setSource(preserveMileage);
                for (let j = 0; j < this.sourceHaulTrackers.length; j++) {
                  for (let k = 0; k < lastHaul.sourceHaulTrackers.length; k++) {
                    if (
                      this.sourceHaulTrackers[j].label ===
                      lastHaul.sourceHaulTrackers[k].type
                    ) {
                      if (!this.sourceHaulTrackers[j].driverInput) {
                        this.sourceHaulTrackers[j].model =
                          lastHaul.sourceHaulTrackers[k].option;
                        this.sourceTrackerInput(
                          j,
                          lastHaul.sourceHaulTrackers[k].option
                        );
                      } else {
                        if (this.edit.edit) {
                          this.sourceHaulTrackers[j].model =
                            lastHaul.sourceHaulTrackers[k].option;
                          this.sourceTrackerInput(
                            j,
                            lastHaul.sourceHaulTrackers[k].option
                          );
                        }
                      }
                    }
                  }
                }
              }
            }
            if (
              this.sourceModel.description &&
              this.destinationModel.description
            ) {
              this.lastHaulSet = true;
            }
          }
        }
      }
    },
    setSource(preserveMileage) {
      this.validation.sourceSelected = true;
      this.validation.sourceSelectedErr = false;

      this.source = this.activeSources[this.sourceModel.optionIndex];

      this.commoditiesMatch = true;
      if (this.source.commodity) {
        if (this.destination) {
          if (this.source.commodity !== this.destination.commodity) {
            this.commoditiesMatch = false;
          }
        }
      }

      this.mileageRequired.source = this.source.trackMileage;
      this.setMileageRequired();
      if (!preserveMileage) {
        this.setMileageBlank();
      }
      this.sourceNote = this.source.note;
      this.sourceHaulTrackers = [];
      this.validation.sourceHaulTrackers = [];
      for (let i = 0; i < this.masterSourceHaulTrackers.length; i++) {
        for (let j = 0; j < this.source.haulTrackers.length; j++) {
          if (
            this.masterSourceHaulTrackers[i].name ===
            this.source.haulTrackers[j]
          ) {
            const selectObj = {
              model: '',
              driverInput: this.masterSourceHaulTrackers[i].driverInput,
              numberInput: this.masterSourceHaulTrackers[i].numberInput,
              options: this.masterSourceHaulTrackers[i].options,
              label: this.masterSourceHaulTrackers[i].name
            };
            this.sourceHaulTrackers.push(selectObj);
            this.validation.sourceHaulTrackers.push({
              valSelected: false,
              err: true
            });
          }
        }
      }
      this.checkReadyToEnter();
    },
    setSourceOptions() {
      this.activeSources = [];
      const sources = JSON.parse(
        JSON.stringify(this.$store.state.activeSourcesUsers)
      );

      const sortByCustomer = (a, b) => {
        let sortByA;
        sortByA =
          this.customers[a.customerId].name +
          this.masterSources[a.sourceLocationId].sourceName;
        let sortByB;
        sortByB =
          this.customers[b.customerId].name +
          this.masterSources[b.sourceLocationId].sourceName;

        if (sortByA < sortByB) {
          return -1;
        }
        if (sortByA > sortByB) {
          return 1;
        }
        return 0;
      };

      sources.sort(sortByCustomer);

      this.sourceOptions = [];
      this.activeSources = [];
      let optionIndex = 0;
      for (let i = 0; i < sources.length; i++) {
        if (sources[i].endDate !== null || sources[i].sourceStatus !== 'Open') {
          continue;
        }

        const option = {
          label: this.customers[sources[i].customerId].name,
          description:
            this.masterSources[sources[i].sourceLocationId].sourceName,
          optionIndex: optionIndex,
          icon: 'outbound'
        };
        optionIndex++;
        this.sourceOptions.push(option);
        this.activeSources.push(sources[i]);
      }

      this.setLastHaul();
    },
    setMileage() {
      if (this.mileage) {
        this.validation.mileage = true;
      } else {
        this.validation.mileage = false;
      }
      this.validation.mileageErr = false;
      this.checkReadyToEnter();
    },
    setMileageBlank() {
      this.mileage = '';
      this.validation.mileage = false;
      this.validation.mileageErr = true;
    },
    setMileageRequired() {
      let mileageRequired = false;

      if (this.mileageRequired.dest || this.mileageRequired.source) {
        mileageRequired = true;
      }

      this.mileageRequired.require = mileageRequired;
    },
    setMU() {
      if (this.haulAmount) {
        this.validation.haulAmount = true;
      } else {
        this.validation.haulAmount = false;
      }
      this.validation.haulAmountErr = false;
      this.checkReadyToEnter();
    },
    setUser() {
      this.user = JSON.parse(JSON.stringify(this.$store.state.user));

      if (this.userList) {
        this.driver = this.userList.users[this.user.displayName];
        this.isAdmin = false;
        if (this.user.admin === true || this.user.superAdmin === true) {
          this.isAdmin = true;
          this.setDriverOptions();
        }
      } else {
        this.callLimit++;
        if (this.callLimit < 30) {
          setTimeout(() => {
            this.setUser();
          }, 700);
        }
      }
    },
    sourceTrackerInput(index, model) {
      if (model) {
        this.validation.sourceHaulTrackers[index].valSelected = true;
        this.validation.sourceHaulTrackers[index].err = false;
      } else {
        this.validation.sourceHaulTrackers[index].valSelected = false;
        this.validation.sourceHaulTrackers[index].err = true;
      }
      this.checkReadyToEnter();
    },
    trackerLabel(label) {
      if (label.startsWith('***&&&')) {
        return label.substring(9);
      }

      return label;
    }
  },
  computed: {
    customers() {
      return JSON.parse(JSON.stringify(this.$store.state.customers));
    },
    destAs() {
      return this.$store.state.settingsUsers.destAs;
    },
    masterDestinations() {
      return JSON.parse(JSON.stringify(this.$store.state.masterDestinations));
    },
    masterSources() {
      return JSON.parse(JSON.stringify(this.$store.state.masterSources));
    },
    settingsUsers() {
      return JSON.parse(JSON.stringify(this.$store.state.settingsUsers));
    },
    measurementUnit() {
      let measurementUnit = '';

      if (this.destination) {
        for (const key in this.settingsUsers.commodities) {
          if (this.destination.commodity === key) {
            measurementUnit =
              this.settingsUsers.commodities[key].measurementUnit;
          }
        }
      }

      if (this.destHaulTrackers) {
        for (let i = 0; i < this.destHaulTrackers.length; i++) {
          const tracker = this.destHaulTrackers[i];
          if (tracker.calcInfo.calcTotalAsHaulAmount) {
            measurementUnit = tracker.calcInfo.haulAmountLabel;
          }
        }
      }
      return measurementUnit;
    },
    userList() {
      return JSON.parse(JSON.stringify(this.$store.state.userList));
    }
  },
  watch: {
    userList() {
      this.setUser();
    },
    '$store.state.user'() {
      this.setUser();
      this.setLastHaul();
    },
    '$store.state.settingsUsers.lastHauls'() {
      this.setUser();
      this.setLastHaul();
    },
    '$store.state.activeDestinationsUsers'() {
      this.setDestinationOptions();
      this.setLastHaul();
    },
    '$store.state.activeSourcesUsers'() {
      this.setSourceOptions();
      this.setDestinationOptions();
      this.setLastHaul();
    }
  }
};
</script>

<style scoped></style>
