<template>
  <div class="row justify-center full-width">
    <InfoBoxes v-if="manageInfoBoxesDisplay" :infoBoxes="infoBoxes" />

    <CustomSelectors
      v-if="manageCustomSelectorsDisplay"
      :customSelectors="customSelectors"
    />

    <DestinationServices v-if="manageServicesDisplay" :services="services" />

    <DestinationHaulTrackers
      v-if="manageHaulTrackersDisplay"
      :haulTrackers="haulTrackers"
    />

    <div
      v-show="
        !manageServicesDisplay &&
        !manageHaulTrackersDisplay &&
        !manageInfoBoxesDisplay &&
        !manageCustomSelectorsDisplay
      "
      class="row justify-center full-width q-pa-md"
    >
      <q-card flat class="q-mb-md full-width" style="border: solid #0b0d6f 1px">
        <div class="row items-center justify-center q-py-sm q-ml-sm">
          <q-icon
            name="settings"
            color="custom-red"
            size="lg"
            class="q-ml-xs"
          />
          <span class="text-body1 text-primary word-wrap q-ml-sm">
            {{ destAs.slice(0, -1) }} Settings
          </span>
        </div>
      </q-card>

      <q-card flat bordered class="q-mb-md half-width half-width-mr">
        <div class="row items-center justify-center q-py-sm q-ml-sm">
          <q-icon name="trending_up" color="custom-red" size="md" />
          <span class="text-body1 text-primary q-ml-sm">Projected</span>
        </div>
        <q-separator class="full-width" />

        <div class="row q-px-md q-pt-md">
          <div style="width: 49%; margin-right: 2%">
            <div class="q-mb-md">
              <q-input
                outlined
                dense
                v-model.number="projectedPlaceholder"
                type="number"
                label="Projected Placeholder"
                color="primary"
                maxlength="20"
              />
              <div class="q-pl-xs text-caption">
                Used to Calculate % hauled if projected is not set
              </div>
            </div>
          </div>

          <div style="width: 49%">
            <q-input
              outlined
              dense
              v-model.number="projectedTolerance"
              type="number"
              label="Projected Tolerance"
              color="primary"
              maxlength="20"
            />
            <div class="q-pl-xs text-caption">
              Considered hauled if within tolerance
            </div>
          </div>
        </div>
      </q-card>

      <q-card flat bordered class="q-mb-md half-width">
        <div class="row items-center justify-center q-py-sm q-ml-sm">
          <q-icon name="check" color="custom-red" size="md" />
          <span class="text-body1 text-primary q-ml-sm"
            >Defaults For New {{ destAs }}</span
          >
        </div>
        <q-separator class="full-width" />

        <div class="row q-px-md q-pb-md">
          <div style="width: 49%; margin-right: 2%">
            <div class="row justify-center">
              <q-checkbox
                v-model="defaultChargeCommodity"
                label="Charge Commodity"
                color="primary"
                size="md"
              />
            </div>
          </div>

          <div style="width: 49%">
            <div class="row justify-center">
              <q-checkbox
                v-model="defaultTrackMileage"
                label="Track Mileage"
                color="primary"
                size="md"
              />
            </div>
          </div>
          <q-input
            outlined
            dense
            v-model.number="defaultPricePerUnit"
            label="Default Price Per Unit"
            mask="#.##"
            fill-mask="0"
            reverse-fill-mask
            prefix="$"
            type="tel"
            maxlength="20"
            color="primary"
            style="width: 49%; margin-right: 2%"
          />
          <q-input
            outlined
            dense
            v-model.number="defaultMileageRate"
            label="Default Rate Per Mile"
            mask="#.##"
            fill-mask="0"
            reverse-fill-mask
            prefix="$"
            type="tel"
            maxlength="20"
            color="primary"
            style="width: 49%"
          />
        </div>
      </q-card>

      <q-card flat bordered class="q-mb-md half-width half-width-mr">
        <div class="row q-px-md q-py-md">
          <div style="width: 49%; margin-right: 2%">
            <div class="row justify-between items-center q-pb-sm q-px-sm">
              <div class="text-bold">Services</div>
              <q-btn
                label="Edit"
                icon="edit"
                color="primary"
                size="sm"
                padding="xs md"
                @click="manageServices"
              />
            </div>
            <q-separator class="q-mb-lg" />

            <div
              v-for="(service, index) in services"
              :key="index + 150"
              class="row justify-center full-width"
            >
              <div class="row items-center full-width q-pb-xs">
                <q-checkbox
                  dense
                  v-model="serviceCheckboxes[index]"
                  :label="service.name + ' - Checked By Default'"
                  color="custom-orange"
                  size="lg"
                  class="text-bold"
                />
              </div>

              <div class="full-width q-mt-xs q-mb-lg">
                <q-input
                  outlined
                  dense
                  v-model.number="services[index].defaultPrice"
                  :label="'Default ' + services[index].name + ' Price Per Ton'"
                  mask="#.##"
                  fill-mask="0"
                  reverse-fill-mask
                  prefix="$"
                  type="tel"
                  maxlength="20"
                  color="primary"
                />
              </div>
            </div>
          </div>

          <div style="width: 49%">
            <div class="row justify-between items-center q-pb-sm q-px-sm">
              <div class="text-bold">Haul Trackers</div>
              <q-btn
                label="Edit"
                icon="edit"
                color="primary"
                size="sm"
                padding="xs md"
                @click="manageHaulTrackers"
              />
            </div>
            <q-separator class="q-mb-lg" />

            <div v-for="(tracker, index) in haulTrackers" :key="index + 200">
              <q-checkbox
                v-model="trackerCheckboxes[index]"
                :label="tracker.name + ' - Checked By Default'"
                color="primary"
                size="sm"
                class="q-ml-sm"
              />
              <div
                v-for="(option, index) in tracker.options"
                :key="index"
                class="q-ml-md"
              >
                {{ option }}
              </div>
              <q-separator class="q-my-sm" />
            </div>
          </div>
        </div>
      </q-card>

      <div class="q-mb-md half-width">
        <q-card flat bordered class="q-mb-md">
          <div class="row q-px-md q-py-md">
            <div style="width: 49%; margin-right: 2%">
              <div class="row justify-between items-center q-pb-sm q-px-sm">
                <div class="text-bold">Info Boxes</div>
                <q-btn
                  label="Edit"
                  icon="edit"
                  color="primary"
                  size="sm"
                  padding="xs md"
                  @click="manageInfoBoxes"
                />
              </div>
              <q-separator class="q-mb-lg" />

              <div v-for="(infoBox, index) in infoBoxes" :key="index">
                <div class="row justify-center">
                  {{ infoBox.name }}
                </div>
              </div>
            </div>

            <div style="width: 49%">
              <div class="row justify-between items-center q-pb-sm q-px-sm">
                <div class="text-bold">Custom Selectors</div>
                <q-btn
                  label="Edit"
                  icon="edit"
                  color="primary"
                  size="sm"
                  padding="xs md"
                  @click="manageCustomSelectors"
                />
              </div>
              <q-separator class="q-mb-lg" />

              <div
                v-for="(customSelector, index) in customSelectors"
                :key="index + 50"
              >
                <div class="row justify-center">
                  {{ customSelector.name }}
                </div>
              </div>
            </div>
          </div>
        </q-card>

        <q-card flat bordered class="q-mb-md">
          <div class="row items-center justify-center q-py-sm">
            <q-icon name="today" color="custom-red" size="md" />
            <span class="text-body1 text-primary q-ml-sm"
              >Service Trip Dates</span
            >
          </div>
          <q-separator class="full-width" />

          <div class="row justify-center q-pa-md">
            <div
              style="width: 49%; margin-right: 1%"
              class="bg-custom-yellow q-pa-sm"
            >
              <q-input
                dense
                v-model.number="tripDateA"
                type="number"
                label="Days Before Service"
                label-color="black"
                color="primary"
                maxlength="20"
              />
              <span> (green to yellow) </span>
            </div>
            <div
              style="width: 49%; margin-left: 1%"
              class="bg-custom-orange q-pa-sm"
            >
              <q-input
                dense
                v-model.number="tripDateB"
                type="number"
                label="Days Before Service"
                label-color="black"
                color="primary"
                maxlength="20"
              />
              <span> (yellow to orange) </span>
            </div>
            <div class="text-primary q-pt-sm">
              (green before first trip date - red after current date is reached)
            </div>
          </div>
        </q-card>

        <q-card flat bordered class="q-mb-md">
          <div class="row items-center justify-center q-py-sm">
            <q-icon name="grain" color="custom-red" size="md" />
            <span class="text-body1 text-primary q-ml-sm"
              >Default Commodity</span
            >
          </div>
          <q-separator class="full-width" />

          <div class="row justify-center q-py-sm">
            <q-option-group
              :options="commodities"
              type="radio"
              size="xs"
              v-model="commoditySelected"
              color="custom-red"
            />
          </div>
        </q-card>

        <q-card flat bordered>
          <div class="row items-center justify-center q-py-sm">
            <q-icon name="view_module" color="custom-red" size="md" />
            <span class="text-body1 text-primary q-ml-sm">Modules</span>
          </div>
          <q-separator class="full-width" />

          <div class="row justify-center q-py-sm">
            <q-option-group
              :options="modules.moduleArr"
              v-model="modules.activeModules"
              type="checkbox"
              size="xs"
              color="custom-red"
            />
          </div>
          <div class="row justify-center text-primary q-pt-md">
            Uncheck unused Modules to clean up forms
          </div>
        </q-card>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import InfoBoxes from '@/components/destinations/InfoBoxes.vue';
import CustomSelectors from '@/components/destinations/CustomSelectors.vue';
import DestinationServices from '@/components/destinations/DestinationServices.vue';
import DestinationHaulTrackers from '@/components/destinations/DestinationHaulTrackers.vue';

const db = firebase.firestore();

export default {
  name: 'DestinationSettings',
  components: {
    DestinationHaulTrackers,
    DestinationServices,
    InfoBoxes,
    CustomSelectors
  },
  data() {
    return {
      commoditySelected: null,
      customSelectors: [],
      defaultChargeCommodity: true,
      defaultMileageRate: 0,
      defaultPricePerUnit: 0,
      defaultTrackMileage: false,
      infoBoxes: [],
      manageCustomSelectorsDisplay: false,
      manageHaulTrackersDisplay: false,
      manageInfoBoxesDisplay: false,
      manageServicesDisplay: false,
      modules: {
        moduleArr: [],
        activeModules: []
      },
      projectedPlaceholder: 500,
      projectedTolerance: 5,
      services: [],
      serviceCheckboxes: [],
      haulTrackers: [],
      trackerCheckboxes: [],
      tripDateA: 7,
      tripDateB: 14
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.setSettings();
    this.setModules();
  },
  methods: {
    cancelProgrammables() {
      this.manageInfoBoxesDisplay = false;
      this.manageCustomSelectorsDisplay = false;
      this.manageServicesDisplay = false;
      this.manageHaulTrackersDisplay = false;
    },
    editSettings() {
      const updatedInfoBoxes = [];
      for (let i = 0; i < this.infoBoxes.length; i++) {
        const infoBoxObj = {
          name: this.infoBoxes[i].name,
          userAuth: this.infoBoxes[i].userAuth
        };
        updatedInfoBoxes.push(infoBoxObj);
      }

      const updatedCustomSelectors = [];
      for (let i = 0; i < this.customSelectors.length; i++) {
        const customSelectorObj = {
          name: this.customSelectors[i].name,
          userAuth: this.customSelectors[i].userAuth,
          options: this.customSelectors[i].options
        };
        updatedCustomSelectors.push(customSelectorObj);
      }

      const updatedServices = [];
      const updatedServicesUsers = [];
      for (let i = 0; i < this.services.length; i++) {
        const serviceObj = {
          name: this.services[i].name,
          defaultChecked: this.serviceCheckboxes[i],
          defaultPrice: parseFloat(this.services[i].defaultPrice),
          priceXInfoBox: this.services[i].priceXInfoBox,
          infoBoxPairedWith: this.services[i].infoBoxPairedWith,
          infoBoxMU: this.services[i].infoBoxMU
        };
        const serviceObjUsers = {
          name: this.services[i].name
        };
        updatedServices.push(serviceObj);
        updatedServicesUsers.push(serviceObjUsers);
      }

      const updatedHaulTrackers = [];
      for (let i = 0; i < this.haulTrackers.length; i++) {
        const trackerObj = {
          name: this.haulTrackers[i].name,
          defaultChecked: this.trackerCheckboxes[i],
          driverInput: this.haulTrackers[i].driverInput,
          options: this.haulTrackers[i].options,
          calcInfo: this.haulTrackers[i].calcInfo
        };
        updatedHaulTrackers.push(trackerObj);
      }

      const modules = {};
      for (let i = 0; i < this.modules.moduleArr.length; i++) {
        const moduleObj = this.modules.moduleArr[i];
        modules[moduleObj.value] = this.modules.activeModules.includes(
          moduleObj.value
        );
      }

      const destinationSettings = {
        defaultChargeCommodity: this.defaultChargeCommodity,
        defaultPricePerUnit: parseFloat(this.defaultPricePerUnit),
        defaultTrackMileage: this.defaultTrackMileage,
        defaultMileageRate: parseFloat(this.defaultMileageRate),
        services: updatedServices,
        defaultCommodity: this.commoditySelected,
        projectedPlaceholder: this.projectedPlaceholder,
        projectedTolerance: this.projectedTolerance
      };

      const destinationSettingsUsers = {
        defaultTrackMileage: this.defaultTrackMileage,
        infoBoxes: updatedInfoBoxes,
        customSelectors: updatedCustomSelectors,
        services: updatedServicesUsers,
        haulTrackers: updatedHaulTrackers,
        projectedPlaceholder: this.projectedPlaceholder,
        projectedTolerance: this.projectedTolerance,
        modules,
        serviceTripDates: {
          tripDateA: this.tripDateA,
          tripDateB: this.tripDateB
        }
      };

      const batch = db.batch();

      const settingsObj = {
        destinationSettings: destinationSettings
      };
      const settingsUsersObj = {
        destinationSettings: destinationSettingsUsers
      };

      const settingsDoc = db.collection('settings').doc('settings');
      const settingsUsersDoc = db.collection('settings_users').doc('settings');

      batch.update(settingsDoc, settingsObj);
      batch.update(settingsUsersDoc, settingsUsersObj);

      batch.commit().catch(err => {
        console.log(err);
      });

      this.$q.notify({
        color: 'primary',
        textColor: 'white',
        icon: 'settings',
        message: 'Settings Updated'
      });
      this.$emit('onSubmit');
    },
    manageInfoBoxes() {
      this.$emit('onManageProgrammables');
      this.manageInfoBoxesDisplay = true;
    },
    manageCustomSelectors() {
      this.$emit('onManageProgrammables');
      this.manageCustomSelectorsDisplay = true;
    },
    manageHaulTrackers() {
      this.$emit('onManageProgrammables');
      this.manageHaulTrackersDisplay = true;
    },
    manageServices() {
      this.$emit('onManageProgrammables');
      this.manageServicesDisplay = true;
    },
    setModules() {
      const camel2Words = camelCase =>
        camelCase
          .replace(/([A-Z])/g, match => ` ${match}`)
          .replace(/^./, match => match.toUpperCase())
          .trim();

      const modules = this.settingsUsers.modules;
      const moduleArr = [];
      const activeModules = [];
      for (const key in modules) {
        moduleArr.push({
          label: camel2Words(key),
          value: key
        });

        if (modules[key]) {
          activeModules.push(key);
        }
      }

      this.modules = { moduleArr, activeModules };
    },
    setSettings() {
      const settings = this.settings;
      const settingsUsers = this.settingsUsers;

      this.defaultChargeCommodity = settings.defaultChargeCommodity;
      this.defaultPricePerUnit = settings.defaultPricePerUnit.toFixed(2);
      this.defaultTrackMileage = settings.defaultTrackMileage;
      this.defaultMileageRate = settings.defaultMileageRate.toFixed(2);
      this.projectedPlaceholder = settings.projectedPlaceholder;
      this.projectedTolerance = settings.projectedTolerance;
      this.commoditySelected = settings.defaultCommodity;
      this.tripDateA = settingsUsers.serviceTripDates.tripDateA;
      this.tripDateB = settingsUsers.serviceTripDates.tripDateB;

      this.services = [];
      this.serviceCheckboxes = [];
      this.trackerCheckboxes = [];

      this.infoBoxes = [];
      if (this.settingsUsers.infoBoxes) {
        for (let i = 0; i < this.settingsUsers.infoBoxes.length; i++) {
          const infoBox = {};
          infoBox.name = this.settingsUsers.infoBoxes[i].name;
          infoBox.userAuth = this.settingsUsers.infoBoxes[i].userAuth;

          this.infoBoxes.push(infoBox);
        }
      }

      this.customSelectors = [];
      if (this.settingsUsers.customSelectors) {
        for (let i = 0; i < this.settingsUsers.customSelectors.length; i++) {
          const customSelector = {};
          customSelector.name = this.settingsUsers.customSelectors[i].name;
          customSelector.options =
            this.settingsUsers.customSelectors[i].options;
          customSelector.userAuth =
            this.settingsUsers.customSelectors[i].userAuth;

          this.customSelectors.push(customSelector);
        }
      }

      if (settings.services) {
        for (let i = 0; i < settings.services.length; i++) {
          const service = {
            name: settings.services[i].name,
            defaultPrice: settings.services[i].defaultPrice.toFixed(2),
            defaultChecked: settings.services[i].defaultChecked,
            priceXInfoBox: settings.services[i].priceXInfoBox,
            infoBoxPairedWith: settings.services[i].infoBoxPairedWith,
            infoBoxMU: settings.services[i].infoBoxMU
          };
          this.services.push(service);
          this.serviceCheckboxes.push(settings.services[i].defaultChecked);
        }
      }

      if (this.settingsUsers.haulTrackers) {
        this.haulTrackers = this.settingsUsers.haulTrackers.slice();
        for (let i = 0; i < this.settingsUsers.haulTrackers.length; i++) {
          this.trackerCheckboxes.push(
            this.settingsUsers.haulTrackers[i].defaultChecked
          );
        }
      }
    }
  },
  computed: {
    commodities() {
      const commodities = this.$store.state.settingsUsers.commodities;
      const objArr = [{ label: 'None', value: 'None' }];
      for (const key in commodities) {
        objArr.push({
          label: commodities[key].name,
          value: commodities[key].name
        });
      }

      return objArr;
    },
    destAs() {
      return this.$store.state.settingsUsers.destAs;
    },
    settings() {
      return JSON.parse(JSON.stringify(this.$store.state.destinationSettings));
    },
    settingsUsers() {
      return JSON.parse(
        JSON.stringify(this.$store.state.settingsUsers.destinationSettings)
      );
    }
  },
  watch: {
    settings() {
      this.setSettings();
    },
    settingsUsers() {
      this.setSettings();
    }
  }
};
</script>

<style scoped></style>
