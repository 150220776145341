import date from 'quasar/src/utils/date.js';;

const round2Dec = num => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

const formatPrice = raw => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  return isNaN(raw) ? '$0.00' : formatter.format(raw);
};

const lastWeekDates = () => {
  let start = new Date();
  let end = new Date(start.getTime());
  start = date.subtractFromDate(start, { days: 7 });
  // set to start/end of day
  start = date.startOfDate(start, 'day');
  end = date.endOfDate(end, 'day');

  start = parseInt(date.formatDate(start, 'x'));
  end = parseInt(date.formatDate(end, 'x'));

  return { start: start, end: end };
};

const dateTripColor = (tripDates, serviceTs) => {
  let color = '';
  let textColor = '';
  if (serviceTs) {
    color = 'custom-green';
    textColor = 'white';

    if (serviceTs - tripDates.tripA < tripDates.now) {
      color = 'custom-yellow';
      textColor = 'black';
    }
    if (serviceTs - tripDates.tripB < tripDates.now) {
      color = 'custom-orange';
      textColor = 'black';
    }
    if (serviceTs <= tripDates.now) {
      color = 'custom-red';
      textColor = 'white';
    }
  }

  return { color, textColor };
};

export { round2Dec, formatPrice, lastWeekDates, dateTripColor };
