<template>
  <div id="locations">
    <q-dialog v-model="confirmDisplay" id="dialog-height" persistent>
      <q-layout view="Lhh lpr fff" container class="bg-white outer">
        <q-header class="bg-grey-3">
          <q-toolbar class="q-pa-md">
            <q-btn
              color="white"
              text-color="black"
              icon="gps_fixed"
              label="Current Location"
              padding="xs md"
              @click="currentLocation"
            />
            <q-space />
            <q-btn
              flat
              color="grey-2"
              text-color="black"
              icon="close"
              padding="xs md"
              @click="backToMap"
            />
          </q-toolbar>
          <q-toolbar class="q-px-md q-py-md">
            <q-btn-toggle
              v-model="destOrSource"
              spread
              no-caps
              toggle-color="primary"
              color="white"
              text-color="primary"
              class="full-width"
              :options="[
                { value: 'destination', slot: 'one' },
                { value: 'source', slot: 'two' }
              ]"
              @click="setDestOrSource"
            >
              <template v-slot:one>
                <div class="row items-center">
                  <div class="text-center text-bold">{{ destAs }}</div>
                </div>
              </template>
              <template v-slot:two>
                <div class="row items-center">
                  <div class="text-center text-bold">Sources</div>
                </div>
              </template>
            </q-btn-toggle>
          </q-toolbar>
        </q-header>

        <q-page-container>
          <q-page class="q-pa-md">
            <div>
              <div
                id="scroll-to"
                style="position: absolute; top: -120px; left: 0"
              ></div>
            </div>

            <div v-show="destOrSource === 'destination'" class="scroll">
              <div
                class="row items-center justify-center rounded-borders text-custom-light q-py-xs q-mb-md"
                style="
                  background: radial-gradient(circle, #3336eb 0%, #0b0d6f 100%);
                "
              >
                <q-icon
                  name="outbound"
                  style="transform: rotate(180deg)"
                  size="md"
                  color="custom-red"
                  class="q-mr-sm"
                />
                {{ destAs }}
              </div>

              <q-card flat bordered class="">
                <q-list separator>
                  <q-scroll-area
                    :thumb-style="thumbStyle"
                    :bar-style="barStyle"
                    :style="dialogHeight"
                    id="scroll-area-1"
                  >
                    <div class="q-pb-sm q-pt-sm q-pl-md">
                      <div class="text-body1 text-bold">Custom Selectors</div>
                      <div class="q-mt-sm q-pt-xs">
                        <q-toggle
                          v-model="customSelectorModels.dest"
                          dense
                          label="Display"
                          color="primary"
                          @input="customSelectorsToggled('dest')"
                        />
                      </div>
                    </div>
                    <q-separator />
                    <div class="q-pb-sm q-pl-md">
                      <div class="q-mt-sm q-pt-xs">
                        <q-toggle
                          v-model="destWaiting"
                          dense
                          label="Waiting"
                          color="primary"
                          @input="destFilterToggled(true, 'onDestWaiting')"
                        />
                      </div>
                      <div class="q-mt-sm q-pt-xs">
                        <q-toggle
                          v-model="notHauled"
                          dense
                          label="Not Hauled"
                          color="custom-red"
                          @input="destFilterToggled(true, 'onNotHauled')"
                        />
                      </div>
                      <div class="q-mt-sm q-pt-xs">
                        <q-toggle
                          v-model="haulInProgress"
                          dense
                          label="Haul In Progress"
                          color="custom-orange"
                          @input="destFilterToggled(true, 'onHaulInProgress')"
                        />
                      </div>
                      <div class="q-mt-sm q-pt-xs">
                        <q-toggle
                          v-model="servicesNotComplete"
                          dense
                          label="Services Not Complete"
                          color="custom-light-blue"
                          @input="
                            destFilterToggled(true, 'onServicesNotComplete')
                          "
                        />
                      </div>
                      <div class="q-mt-sm q-pt-xs">
                        <q-toggle
                          v-model="servicesComplete"
                          dense
                          label="Services Complete"
                          color="black"
                          @input="destFilterToggled(true, 'onServicesComplete')"
                        />
                      </div>
                      <div v-if="isAdmin || isSuper" class="q-mt-sm q-py-xs">
                        <q-toggle
                          v-model="allDestinations"
                          dense
                          :label="`All ${destAs}`"
                          color="custom-light"
                          @input="destFilterToggled(true, 'onAllDestinations')"
                        />
                      </div>
                    </div>
                    <q-separator />
                    <div
                      class="row justify-around q-pt-sm q-px-xs q-pb-sm filter-alpha-maps"
                    >
                      <q-avatar
                        v-for="letter in alphabet"
                        :key="letter"
                        color="custom-artichoke"
                        text-color="white"
                        round
                        class="q-ma-xs text-bold cursor-pointer"
                        size="sm"
                        @click="searchCustomers(letter)"
                      >
                        {{ letter }}
                      </q-avatar>
                      <q-avatar
                        color="custom-red"
                        text-color="white"
                        rounded
                        class="q-ma-xs cursor-pointer"
                        size="sm"
                        @click="setCustomers()"
                      >
                        <q-icon name="list" size="sm"></q-icon>
                      </q-avatar>
                    </div>
                    <q-separator />
                    <q-virtual-scroll
                      scroll-target="#scroll-area-1 > .scroll"
                      separator
                      :items="filteredDestLocations"
                      class="q-pb-lg"
                    >
                      <template v-slot="{ item, index }">
                        <q-item :key="index" dense>
                          <q-item-section>
                            <div class="text-bold q-py-sm">
                              {{ item.customer }}
                            </div>
                            <q-separator class="q-mb-sm" />
                            <div
                              v-for="(location, i) in item.locations"
                              :key="i"
                              class="row q-ml-sm q-pb-sm"
                            >
                              <span class="ellipsis" style="max-width: 190px">{{
                                location.locationName
                              }}</span>
                              <q-space />
                              <q-btn
                                v-show="isAdmin || isSuper"
                                icon="edit"
                                color="primary"
                                outline
                                size="sm"
                                padding="xs xs"
                                @click="editPin('destination', location)"
                              />
                              <q-btn
                                v-show="location.lat !== null"
                                icon="place"
                                color="custom-red"
                                outline
                                size="sm"
                                padding="xs xs"
                                class="q-ml-xs"
                                @click="goTo(location)"
                              />
                              <q-btn
                                v-show="location.lat === null"
                                icon="place"
                                color="custom-light"
                                outline
                                size="sm"
                                padding="xs xs"
                                class="q-ml-xs"
                              />
                            </div>
                          </q-item-section>
                        </q-item>
                      </template>
                    </q-virtual-scroll>
                  </q-scroll-area>
                </q-list>
              </q-card>
            </div>

            <div v-show="destOrSource === 'source'" class="scroll">
              <div
                class="row items-center justify-center rounded-borders text-custom-light q-py-xs q-mb-md"
                style="
                  background: radial-gradient(circle, #3336eb 0%, #0b0d6f 100%);
                "
              >
                <q-icon
                  name="outbound"
                  size="md"
                  color="custom-green"
                  class="q-mr-sm"
                />
                Sources
              </div>

              <q-card flat bordered>
                <q-list separator>
                  <q-scroll-area
                    :thumb-style="thumbStyle"
                    :bar-style="barStyle"
                    :style="dialogHeight"
                    id="scroll-area-2"
                  >
                    <div class="q-pb-sm q-pt-sm q-pl-md">
                      <div class="text-body1 text-bold">Custom Selectors</div>
                      <div class="q-mt-sm q-pt-xs">
                        <q-toggle
                          dense
                          v-model="customSelectorModels.source"
                          label="Display"
                          color="primary"
                          @input="customSelectorsToggled('source')"
                        />
                      </div>
                    </div>
                    <q-separator />
                    <div class="q-pl-md q-pb-md">
                      <div class="q-mt-sm q-pt-xs">
                        <q-toggle
                          dense
                          v-model="sourceWaiting"
                          label="Waiting"
                          color="custom-green"
                          @input="sourceFilterToggled(true, 'onSourceWaiting')"
                        />
                      </div>
                      <div class="q-mt-sm q-pt-xs">
                        <q-toggle
                          dense
                          v-model="activeSources"
                          label="Active Sources"
                          color="custom-green"
                          @input="sourceFilterToggled(true, 'onActiveSources')"
                        />
                      </div>
                      <div v-if="isAdmin || isSuper" class="q-mt-sm q-py-xs">
                        <q-toggle
                          dense
                          v-model="allSources"
                          label="All Sources"
                          color="custom-artichoke"
                          @input="sourceFilterToggled(true, 'onAllSources')"
                        />
                      </div>
                    </div>
                    <q-separator />
                    <div
                      class="row justify-around q-pt-sm q-px-xs q-pb-sm filter-alpha-maps"
                    >
                      <q-avatar
                        v-for="letter in alphabet"
                        :key="letter"
                        color="custom-artichoke"
                        text-color="white"
                        round
                        class="q-ma-xs text-bold cursor-pointer"
                        size="sm"
                        @click="searchCustomers(letter)"
                      >
                        {{ letter }}
                      </q-avatar>
                      <q-avatar
                        color="custom-green"
                        text-color="white"
                        rounded
                        class="q-ma-xs cursor-pointer"
                        size="sm"
                        @click="setCustomers()"
                      >
                        <q-icon name="list" size="sm"></q-icon>
                      </q-avatar>
                    </div>
                    <q-separator />
                    <q-virtual-scroll
                      scroll-target="#scroll-area-1 > .scroll"
                      separator
                      :items="filteredSourceLocations"
                      class="q-pb-lg"
                    >
                      <template v-slot="{ item, index }">
                        <q-item :key="index" dense>
                          <q-item-section>
                            <div class="text-bold q-py-sm">
                              {{ item.customer }}
                            </div>
                            <q-separator class="q-mb-sm" />
                            <div
                              v-for="(location, i) in item.locations"
                              :key="i"
                              class="row q-ml-sm q-pb-sm"
                            >
                              <span class="ellipsis" style="max-width: 190px">{{
                                location.locationName
                              }}</span>
                              <q-space />
                              <q-btn
                                v-show="isAdmin || isSuper"
                                icon="edit"
                                color="primary"
                                outline
                                size="sm"
                                padding="xs xs"
                                @click="editPin('source', location)"
                              />
                              <q-btn
                                v-show="location.lat !== null"
                                icon="place"
                                color="custom-green"
                                outline
                                size="sm"
                                padding="xs xs"
                                class="q-ml-xs"
                                @click="goTo(location)"
                              />
                              <q-btn
                                v-show="location.lat === null"
                                icon="place"
                                color="grey-5"
                                outline
                                size="sm"
                                padding="xs xs"
                                class="q-ml-xs"
                              />
                            </div>
                          </q-item-section>
                        </q-item>
                      </template>
                    </q-virtual-scroll>
                  </q-scroll-area>
                </q-list>
              </q-card>
            </div>
          </q-page>
        </q-page-container>
      </q-layout>
    </q-dialog>
  </div>
</template>

<script>
export default {
  name: 'LocationsList',
  props: {
    isAdmin: {
      type: Boolean,
      required: true
    },
    isSuper: {
      type: Boolean,
      required: true
    },
    isUser: {
      type: Boolean,
      required: true
    },
    markerFilter: {
      type: Object,
      required: true
    },
    customSelectors: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activeSources: false,
      allDestinations: false,
      allSources: false,
      alphabet: [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z'
      ],
      activeDestLocations: [],
      activeSourceLocations: [],
      barStyle: {
        right: '0px',
        borderRadius: '3px',
        backgroundColor: '#bdbdbd',
        width: '3px',
        opacity: 0.2
      },
      confirmDisplay: true,
      customSelectorModels: {
        dest: false,
        source: false
      },
      destOrSource: 'destination',
      destWaiting: false,
      dialogHeight: '500px',
      filteredDestLocations: [],
      filteredAllDestLocations: [],
      filteredSourceLocations: [],
      filteredAllSourceLocations: [],
      haulInProgress: false,
      notHauled: false,
      haulIncompleteLocations: [],
      servicesComplete: false,
      servicesNotComplete: false,
      sourceWaiting: false,
      thumbStyle: {
        right: '0px',
        borderRadius: '3px',
        backgroundColor: '#9e9e9e',
        width: '3px',
        opacity: 0.75
      }
    };
  },
  mounted() {
    this.loadLocations();
    this.setFilters();
    this.setDialogHeight();
    this.customSelectorModels.dest = this.customSelectors.dest;
    this.customSelectorModels.source = this.customSelectors.source;
  },
  methods: {
    async setDialogHeight() {
      await this.$nextTick();

      const height =
        document.getElementById('dialog-height').clientHeight - 275;
      this.dialogHeight = `height: ${height}px`;
    },
    backToMap() {
      this.$emit('onBackToMap');
    },
    customSelectorsToggled(type) {
      this.$emit('onToggleSelectorWindows', type);
    },
    currentLocation() {
      this.$emit('onCurrentLocation');
    },
    destFilterToggled(emit, emitStr) {
      if (emit) {
        this.$emit(emitStr);
        this.loadLocations();
      }

      if (this.markerFilter.allDestinations) {
        // Set all filters to true
        if (!this.markerFilter.destWaiting) {
          this.$emit('onDestWaiting');
          this.destWaiting = true;
        }
        if (!this.markerFilter.notHauled) {
          this.$emit('onNotHauled');
          this.notHauled = true;
        }
        if (!this.markerFilter.haulInProgress) {
          this.$emit('onHaulInProgress');
          this.haulInProgress = true;
        }
        if (!this.markerFilter.servicesNotComplete) {
          this.$emit('onServicesNotComplete');
          this.servicesNotComplete = true;
        }
        if (!this.markerFilter.servicesComplete) {
          this.$emit('onServicesComplete');
          this.servicesComplete = true;
        }

        this.filteredDestLocations = [];
        this.filteredDestLocations = this.filteredAllDestLocations.slice();
      } else {
        this.filteredDestLocations = [];
        this.filteredDestLocations = this.filteredActiveDestLocations.slice();
      }
    },
    editPin(type, location) {
      this.$emit('onEditPin', type, location);
    },
    goTo(location) {
      this.$emit('onGoTo', location);
    },
    loadLocations() {
      const activeSources = JSON.parse(
        JSON.stringify(this.$store.state.activeSourcesUsers)
      );
      const activeDestinations = JSON.parse(
        JSON.stringify(this.$store.state.activeDestinationsUsers)
      );
      const projectedTolerance = JSON.parse(
        JSON.stringify(
          this.$store.state.settingsUsers.destinationSettings.projectedTolerance
        )
      );

      // Load Destinations
      const activeDestinationsArr = [];
      for (let i = 0; i < activeDestinations.length; i++) {
        const dest = activeDestinations[i];

        if (dest.endDate && (this.isUser || this.isSuper)) {
          continue;
        }

        const customer = this.customers[dest.customerId].name;
        const location = this.masterDestinations[dest.destinationLocationId];

        let hauled = 0;
        for (let j = 0; j < dest.hauls.length; j++) {
          hauled += dest.hauls[j].haulAmount;
        }

        let notHauled = false;
        if (dest.projected) {
          if (dest.projected > hauled + projectedTolerance) {
            notHauled = true;
          }
        }

        const projectedForCalc = dest.projected || 0;

        let icon = 'redIcon';

        let balance = projectedForCalc - hauled;
        if (balance < 0) {
          balance = 0;
        }

        icon = hauled > 0 ? 'purpleIcon' : 'redIcon';

        const projected = dest.projected;

        if (projected !== '') {
          if (dest.projected <= hauled + projectedTolerance) {
            icon = 'blueIcon';
          }
        }

        let servicesComplete = true;
        for (let k = 0; k < dest.services.length; k++) {
          if (dest.services[k].complete === false) {
            servicesComplete = false;
          }
        }

        icon = servicesComplete === true ? 'blackIcon' : icon;

        const destObj = {
          id: dest.destinationLocationId,
          type: 'destination',
          customer: customer,
          locationName: location.destinationName,
          lat: location.lat,
          lng: location.lng,
          notHauled: notHauled
        };

        switch (icon) {
          case 'redIcon':
            if (this.markerFilter.notHauled) {
              activeDestinationsArr.push(destObj);
            }
            break;
          case 'purpleIcon':
            if (this.markerFilter.haulInProgress) {
              activeDestinationsArr.push(destObj);
            }
            break;
          case 'blueIcon':
            if (this.markerFilter.servicesNotComplete) {
              activeDestinationsArr.push(destObj);
            }
            break;
          case 'blackIcon':
            if (this.markerFilter.servicesComplete) {
              activeDestinationsArr.push(destObj);
            }
            break;
        }
      }

      const sortActiveDestinations = (a, b) => {
        const sortByA = a.customer + a.locationName;
        const sortByB = b.customer + b.locationName;
        if (sortByA < sortByB) {
          return -1;
        }
        if (sortByA > sortByB) {
          return 1;
        }
        return 0;
      };

      activeDestinationsArr.sort(sortActiveDestinations);

      const activeDestCustomers = [];
      for (let i = 0; i < activeDestinationsArr.length; i++) {
        const dest = activeDestinationsArr[i];

        const includes = activeDestCustomers.includes(dest.customer);

        if (!includes) {
          activeDestCustomers.push(dest.customer);
        }
      }

      const destLocations = [];
      for (let i = 0; i < activeDestCustomers.length; i++) {
        const customer = activeDestCustomers[i];
        const custLocations = activeDestinationsArr.filter(location => {
          return location.customer === customer;
        });

        if (custLocations.length) {
          destLocations.push({
            locations: custLocations,
            customer: customer
          });
        }
      }
      this.activeDestLocations = destLocations.slice();
      this.filteredActiveDestLocations = destLocations.slice();

      const allDestArr = [];
      if (this.isAdmin || this.isSuper) {
        for (const key in this.masterDestinations) {
          const dest = this.masterDestinations[key];

          // If super user customer is inactive or any other reason customer is not in list
          if (
            !Object.prototype.hasOwnProperty.call(
              this.customers,
              dest.customerId
            )
          ) {
            continue;
          }

          const destObj = {
            id: key,
            type: 'destination',
            customer: this.customers[dest.customerId].name,
            locationName: dest.destinationName,
            lat: dest.lat,
            lng: dest.lng
          };
          allDestArr.push(destObj);
        }

        const sortAllDestinations = (a, b) => {
          const sortByA = a.customer + a.locationName;
          const sortByB = b.customer + b.locationName;
          if (sortByA < sortByB) {
            return -1;
          }
          if (sortByA > sortByB) {
            return 1;
          }
          return 0;
        };

        allDestArr.sort(sortAllDestinations);

        const allCustomers = [];
        for (let i = 0; i < allDestArr.length; i++) {
          const dest = allDestArr[i];

          const includes = allCustomers.includes(dest.customer);

          if (!includes) {
            allCustomers.push(dest.customer);
          }
        }

        const allDestLocations = [];
        for (let i = 0; i < allCustomers.length; i++) {
          const customer = allCustomers[i];
          const custLocations = allDestArr.filter(location => {
            return location.customer === customer;
          });

          if (custLocations.length) {
            allDestLocations.push({
              locations: custLocations,
              customer: customer
            });
          }
        }
        this.allDestLocations = allDestLocations.slice();
        this.filteredAllDestLocations = allDestLocations.slice();
      }

      // Load Sources
      const activeSourcesArr = [];
      if (this.markerFilter.activeSources) {
        for (let i = 0; i < activeSources.length; i++) {
          const source = activeSources[i];
          const customer = this.customers[source.customerId].name;
          const location = this.masterSources[source.sourceLocationId];

          if (source.endDate && (this.isUser || this.isSuper)) {
            continue;
          }

          const sourceObj = {
            id: source.sourceLocationId,
            type: 'source',
            customer: customer,
            locationName: location.sourceName,
            lat: location.lat,
            lng: location.lng
          };

          activeSourcesArr.push(sourceObj);
        }
      }

      const sortActiveSources = (a, b) => {
        const sortByA = a.customer + a.locationName;
        const sortByB = b.customer + b.locationName;
        if (sortByA < sortByB) {
          return -1;
        }
        if (sortByA > sortByB) {
          return 1;
        }
        return 0;
      };

      activeSourcesArr.sort(sortActiveSources);

      const activeSourceCustomers = [];
      for (let i = 0; i < activeSourcesArr.length; i++) {
        const source = activeSourcesArr[i];

        const includes = activeSourceCustomers.includes(source.customer);

        if (!includes) {
          activeSourceCustomers.push(source.customer);
        }
      }

      const activeSourceLocations = [];
      for (let i = 0; i < activeSourceCustomers.length; i++) {
        const customer = activeSourceCustomers[i];
        const custLocations = activeSourcesArr.filter(location => {
          return location.customer === customer;
        });

        if (custLocations.length) {
          activeSourceLocations.push({
            locations: custLocations,
            customer: customer
          });
        }
      }
      this.activeSourceLocations = activeSourceLocations.slice();
      this.filteredActiveSourceLocations = activeSourceLocations.slice();

      const allSourcesArr = [];
      if (this.isAdmin || this.isSuper) {
        for (const key in this.masterSources) {
          const source = this.masterSources[key];

          // If super user customer is inactive or any other reason customer is not in list
          if (
            !Object.prototype.hasOwnProperty.call(
              this.customers,
              source.customerId
            )
          ) {
            continue;
          }

          const sourceObj = {
            id: key,
            type: 'source',
            customer: this.customers[source.customerId].name,
            locationName: source.sourceName,
            lat: source.lat,
            lng: source.lng
          };
          allSourcesArr.push(sourceObj);
        }

        const sortAllSources = (a, b) => {
          const sortByA = a.customer + a.locationName;
          const sortByB = b.customer + b.locationName;
          if (sortByA < sortByB) {
            return -1;
          }
          if (sortByA > sortByB) {
            return 1;
          }
          return 0;
        };

        allSourcesArr.sort(sortAllSources);

        const allCustomers = [];
        for (let i = 0; i < allSourcesArr.length; i++) {
          const source = allSourcesArr[i];

          const includes = allCustomers.includes(source.customer);

          if (!includes) {
            allCustomers.push(source.customer);
          }
        }

        const allSourceLocations = [];
        for (let i = 0; i < allCustomers.length; i++) {
          const customer = allCustomers[i];
          const custLocations = allSourcesArr.filter(location => {
            return location.customer === customer;
          });

          if (custLocations.length) {
            allSourceLocations.push({
              locations: custLocations,
              customer: customer
            });
          }
        }
        this.allSourceLocations = allSourceLocations.slice();
        this.filteredAllSourceLocations = allSourceLocations.slice();
      }
    },
    searchCustomers(letter) {
      if (this.destOrSource === 'destination') {
        if (this.markerFilter.allDestinations) {
          this.filteredDestLocations = this.allDestLocations.filter(el => {
            return el.customer.toLowerCase().startsWith(letter.toLowerCase());
          });
        } else {
          this.filteredDestLocations = this.activeDestLocations.filter(el => {
            return el.customer.toLowerCase().startsWith(letter.toLowerCase());
          });
        }
      } else {
        if (this.markerFilter.allSources) {
          this.filteredSourceLocations = this.filteredAllSourceLocations.filter(
            el => {
              return el.customer.toLowerCase().startsWith(letter.toLowerCase());
            }
          );
        } else {
          this.filteredSourceLocations =
            this.filteredActiveSourceLocations.filter(el => {
              return el.customer.toLowerCase().startsWith(letter.toLowerCase());
            });
        }
      }
    },
    setCustomers() {
      if (this.destOrSource === 'destination') {
        if (this.markerFilter.allDestinations) {
          this.filteredDestLocations = this.allDestLocations.slice();
        } else {
          this.filteredDestLocations = this.activeDestLocations.slice();
        }
      } else {
        if (this.markerFilter.allSources) {
          this.filteredSourceLocations = this.filteredAllSourceLocations;
        } else {
          this.filteredSourceLocations = this.filteredActiveSourceLocations;
        }
      }
    },
    setDestOrSource() {
      const dialogParent = document.getElementById('scroll-to');
      if (dialogParent) {
        dialogParent.scrollIntoView();
      }

      if (this.destOrSource === 'destination') {
        this.$emit('onSetDestOrSource', 'destination');
      } else {
        this.$emit('onSetDestOrSource', 'source');
      }
    },
    setFilters() {
      this.destWaiting = this.markerFilter.destWaiting;
      this.notHauled = this.markerFilter.notHauled;
      this.haulInProgress = this.markerFilter.haulInProgress;
      this.servicesNotComplete = this.markerFilter.servicesNotComplete;
      this.servicesComplete = this.markerFilter.servicesComplete;
      this.allDestinations = this.markerFilter.allDestinations;
      this.sourceWaiting = this.markerFilter.sourceWaiting;
      this.activeSources = this.markerFilter.activeSources;
      this.allSources = this.markerFilter.allSources;

      this.destFilterToggled(false);
      this.sourceFilterToggled(false);

      if (this.markerFilter.destOrSource === 'destination') {
        this.destOrSource = 'destination';
      } else {
        this.destOrSource = 'source';
      }
    },
    sourceFilterToggled(emit, emitStr) {
      if (emit) {
        this.$emit(emitStr);
        this.loadLocations();
      }

      if (this.markerFilter.allSources) {
        // Set all filters to true
        if (!this.markerFilter.sourceWaiting) {
          this.$emit('onSourceWaiting');
          this.sourceWaiting = true;
        }
        if (!this.markerFilter.activeSources) {
          this.$emit('onActiveSources');
          this.activeSources = true;
        }

        this.filteredSourceLocations = [];
        this.filteredSourceLocations = this.filteredAllSourceLocations.slice();
      } else {
        this.filteredSourceLocations = [];
        this.filteredSourceLocations =
          this.filteredActiveSourceLocations.slice();
      }
    }
  },
  computed: {
    customers() {
      return JSON.parse(JSON.stringify(this.$store.state.customers));
    },
    destAs() {
      return this.$store.state.settingsUsers.destAs;
    },
    masterDestinations() {
      return JSON.parse(JSON.stringify(this.$store.state.masterDestinations));
    },
    masterSources() {
      return JSON.parse(JSON.stringify(this.$store.state.masterSources));
    }
  },
  watch: {
    markerFilter() {
      this.setFilters();
    }
  }
};
</script>

<style scoped>
#locations {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

.filter-alpha-maps {
  width: 300px;
  margin: auto;
}

.outer {
  min-width: 360px;
  max-width: 380px;
}

@media all and (max-width: 350px) {
  .filter-alpha-maps {
    width: 260px;
    margin: auto;
  }

  .outer {
    min-width: 310px;
  }
}
</style>
