<template>
  <q-dialog v-model="confirmDisplay" persistent>
    <q-card class="q-pa-lg outer-card">
      <q-card-section class="row items-center q-px-sm q-pt-lg">
        <div class="row items-center form">
          <div class="q-ml-sm word-wrap" style="width: 44%">
            <div class="text-primary text-bold">
              {{ customers[source.customerId].name }}
            </div>
            <div class="text-custom-medium text-bold">
              {{ masterSources[source.sourceLocationId].sourceName }}
            </div>
          </div>
          <q-space />
          <q-icon
            class="self-center"
            name="forward"
            color="primary"
            size="sm"
          />
          <q-space />
          <div class="q-mr-sm word-wrap" style="width: 44%">
            <div class="text-primary text-right text-bold">
              {{ customers[destination.customerId].name }}
            </div>
            <div class="text-custom-medium text-bold text-right">
              {{ setDestination(destination) }}
            </div>
          </div>
        </div>
      </q-card-section>
      <q-separator />

      <q-card-section class="row items-center">
        <div class="row items-center form">
          <div class="text-bold text-body1">
            Amount
            <!--<q-icon name="local_shipping" color="primary" size="sm" />-->
          </div>
          <q-space />
          <div class="q-ml-md">
            <div class="text-body1 text-bold text-custom-light-blue">
              {{ haulAmount }} {{ measurementUnit }}
            </div>
          </div>
        </div>
      </q-card-section>
      <q-separator />

      <q-card-section
        v-if="mileage.mileageRequired"
        class="row items-center q-pt-lg"
      >
        <div class="row items-center form">
          <div>
            <q-icon name="edit_road" color="primary" size="sm" />
          </div>
          <q-space />
          <div class="q-ml-md">
            <div class="text-body1 text-bold">{{ mileage.mileage }} Miles</div>
          </div>
        </div>
      </q-card-section>
      <q-separator v-if="mileage.mileageRequired" />

      <div
        v-for="tracker in sourceHaulTrackers"
        :key="'source' + tracker.label"
      >
        <q-card-section class="row items-center q-pt-lg">
          <div class="row items-center form text-bold">
            <div>
              {{ tracker.label }}
            </div>
            <q-space></q-space>
            <div class="text-grey-7">
              {{ tracker.model }}
            </div>
          </div>
        </q-card-section>
        <q-separator />
      </div>

      <div v-for="tracker in destHaulTrackers" :key="'dest' + tracker.label">
        <div v-if="tracker.calcInfo.requireNumInput">
          <q-card-section class="row items-center q-pt-lg">
            <div class="row items-center form text-bold">
              <div>
                {{ tracker.label }}
              </div>
              <q-space></q-space>
              <div class="text-grey-7">
                {{ tracker.model }}
              </div>
            </div>
          </q-card-section>
          <q-separator />
        </div>
      </div>

      <q-card-actions class="q-mt-xl q-pt-lg q-mb-md" align="right">
        <q-btn
          flat
          icon="clear"
          label="Cancel"
          color="primary"
          @click="cancelClicked"
        />
        <q-btn
          unelevated
          icon="local_shipping"
          label="Enter"
          color="primary"
          class="q-px-lg"
          @click="confirmClicked"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'ConfirmHaul',
  props: {
    haulAmount: {
      type: Number,
      required: true
    },
    measurementUnit: {
      type: String,
      required: true
    },
    mileage: {
      type: Object,
      required: true
    },
    source: {
      type: Object,
      required: true
    },
    sourceHaulTrackers: {
      type: Array,
      required: true
    },
    destination: {
      type: Object,
      required: true
    },
    destHaulTrackers: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      confirmDisplay: true,
      confirmUpdate: false,
      displayName: '',
      email: ''
    };
  },
  methods: {
    cancelClicked() {
      this.$emit('canceled');
    },
    confirmClicked() {
      this.$emit('confirmed');
    },
    setDestination(destination) {
      // This is for hauls TO sources that track running balance
      if (!destination.trackRunningBalance) {
        return this.masterDestinations[destination.destinationLocationId]
          .destinationName;
      } else {
        return this.masterSources[destination.destinationLocationId].sourceName;
      }
    },
    trackerLabel(label) {
      if (label.startsWith('***&&&')) {
        return label.substring(9);
      }

      return label;
    }
  },
  computed: {
    customers() {
      return JSON.parse(JSON.stringify(this.$store.state.customers));
    },
    masterDestinations() {
      return JSON.parse(JSON.stringify(this.$store.state.masterDestinations));
    },
    masterSources() {
      return JSON.parse(JSON.stringify(this.$store.state.masterSources));
    }
  }
};
</script>

<style scoped>
.outer-card {
  width: 453px;
}

@media all and (max-width: 400px) {
  .outer-card {
    width: 100%;
  }
}
</style>
