import { render, staticRenderFns } from "./DestinationTable.vue?vue&type=template&id=efc58abc&scoped=true&"
import script from "./DestinationTable.vue?vue&type=script&lang=js&"
export * from "./DestinationTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "efc58abc",
  null
  
)

export default component.exports
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QLinearProgress,QSeparator,QSpace,QInput,QCard,QBadge,QIcon,QAvatar,QBtn});
