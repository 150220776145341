<template>
  <div>
    <LoadingGif v-if="displayLoadingGif" />

    <ConfirmDialog
      v-if="confirm.display"
      :icon="confirm.icon"
      :iconColor="confirm.iconColor"
      :iconStyle="confirm.iconStyle"
      :btn="confirm.btn"
      :message="confirm.message"
      :headerMessage="confirm.headerMessage"
      @canceled="confirm.display = false"
      @confirmed="onArchive"
    />

    <ReportContainer
      v-if="currentDisplay === 'report'"
      :reportId="reportRaw.id"
      :reportType="reportType"
      :canEdit="true"
      @onCancel="currentDisplay = 'map'"
    />

    <div class="q-pa-md">
      <q-card flat bordered ref="sourceCard" class="q-mb-md">
        <div
          class="row justify-center items-center q-py-sm bg-custom-light text-h6"
        >
          <q-icon
            name="outbound"
            color="custom-green"
            size="sm"
            class="q-mr-sm"
          />
          Sources
        </div>

        <q-linear-progress v-if="sourceRendering" query color="primary" />

        <div
          v-if="!activeSources.length && !sourceRendering"
          class="row justify-center q-pb-sm"
        >
          <div
            class="row justify-center full-width q-py-lg text-h4 text-primary"
          >
            Source List Is Empty
          </div>
        </div>

        <div class="column" :style="sourceHeight">
          <div
            v-for="(source, index) in activeSources"
            :key="index"
            :id="source.id"
          >
            <div :style="`width: ${itemWidth + 204}px;`" class="row q-px-md">
              <div class="row justify-center items-center q-pr-md">
                <q-avatar
                  v-if="source.readyForSettlement"
                  icon="task_alt"
                  color="custom-green"
                  size="sm"
                  text-color="white"
                />
                <q-avatar
                  v-if="!source.readyForSettlement"
                  icon="task_alt"
                  color="custom-light"
                  size="sm"
                  font-size="14px"
                  text-color="black"
                />
              </div>
              <div :style="`width: ${itemWidth}px`">
                <div style="height: 26px; padding-top: 8px" class="ellipsis">
                  {{ source.customer }}
                </div>
                <div
                  style="height: 28px"
                  class="text-caption text-custom-medium no-wrap ellipsis"
                >
                  {{ source.sourceName }}
                </div>
              </div>

              <div
                class="row items-center q-pl-sm"
                style="width: 132px; height: 54px"
              >
                <div class="q-py-sm">
                  <q-btn
                    unelevated
                    icon="calculate"
                    color="custom-artichoke"
                    text-color="white"
                    size="sm"
                    class="q-mx-xs"
                    padding="xs sm"
                    @click="createSReport(source, 'source')"
                  />
                  <q-btn
                    unelevated
                    icon="view_list"
                    color="primary"
                    text-color="white"
                    size="sm"
                    class="q-mx-xs"
                    padding="xs sm"
                    @click="edit(source, 'source')"
                  />
                  <q-btn
                    unelevated
                    icon="archive"
                    color="custom-medium"
                    text-color="white"
                    size="sm"
                    class="q-mx-xs"
                    padding="xs sm"
                    @click="onConfirmArchive(source, 'source')"
                  />
                </div>
              </div>
              <q-separator class="full-width" color="custom-light" />
            </div>
          </div>
        </div>
      </q-card>

      <q-card flat bordered class="q-mb-xl">
        <div
          class="row justify-center items-center q-py-sm bg-custom-light text-h6"
        >
          <q-icon
            name="outbound"
            color="custom-red"
            size="sm"
            class="q-mr-sm"
            style="transform: rotate(180deg)"
          />
          {{ destAs }}
        </div>

        <q-linear-progress v-if="destRendering" query color="primary" />

        <div
          v-if="!activeDestinations.length && !destRendering"
          class="row justify-center q-pb-sm"
        >
          <div
            class="row justify-center full-width q-py-lg text-h4 text-primary"
          >
            {{ destAs.slice(0, -1) }} List Is Empty
          </div>
        </div>

        <div class="column" :style="destHeight">
          <div
            v-for="(dest, index) in activeDestinations"
            :key="index"
            :id="dest.id"
          >
            <div :style="`width: ${itemWidth + 204}px;`" class="row q-px-md">
              <div class="row justify-center items-center q-pr-md">
                <q-avatar
                  v-if="dest.readyForSettlement"
                  icon="task_alt"
                  color="custom-red"
                  size="sm"
                  text-color="white"
                />
                <q-avatar
                  v-if="!dest.readyForSettlement"
                  icon="task_alt"
                  color="custom-light"
                  size="sm"
                  font-size="14px"
                  text-color="black"
                />
              </div>
              <div :style="`width: ${itemWidth}px`">
                <div style="height: 26px; padding-top: 8px" class="ellipsis">
                  {{ dest.customer }}
                </div>
                <div
                  style="height: 28px"
                  class="text-caption text-custom-medium no-wrap ellipsis"
                >
                  {{ dest.destinationName }}
                </div>
              </div>

              <div
                class="row items-center q-pl-sm"
                style="width: 132px; height: 54px"
              >
                <div class="q-py-sm">
                  <q-btn
                    unelevated
                    icon="calculate"
                    color="custom-artichoke"
                    text-color="white"
                    size="sm"
                    class="q-mx-xs"
                    padding="xs sm"
                    @click="createSReport(dest, 'destination')"
                  />
                  <q-btn
                    unelevated
                    icon="view_list"
                    color="primary"
                    text-color="white"
                    size="sm"
                    class="q-mx-xs"
                    padding="xs sm"
                    @click="edit(dest, 'destination')"
                  />
                  <q-btn
                    unelevated
                    icon="archive"
                    color="custom-medium"
                    text-color="white"
                    size="sm"
                    class="q-mx-xs"
                    padding="xs sm"
                    @click="onConfirmArchive(dest, 'destination')"
                  />
                </div>
              </div>
              <q-separator class="full-width" color="custom-light" />
            </div>
          </div>
        </div>
      </q-card>
    </div>
  </div>
</template>

<script>
import { destinationReport, sourceReport } from '@/utils/ReportHelpers';
import firebase from 'firebase/compat/app';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import LoadingGif from '@/components/LoadingGif.vue';
import ReportContainer from '@/components/ReportContainer.vue';

const db = firebase.firestore();

export default {
  name: 'destinationTable',
  components: {
    ConfirmDialog,
    LoadingGif,
    ReportContainer
  },
  data() {
    return {
      alwaysAvailableConfirmed: false,
      archiveType: '',
      confirm: {
        display: false,
        icon: '',
        iconColor: '',
        iconStyle: '',
        btn: {
          label: 'Archive',
          icon: 'archive',
          color: ''
        },
        message: '',
        headerMessage: ''
      },
      customers: [],
      currentDisplay: 'list',
      dest: {},
      destinations: [],
      destRendering: true,
      destUsers: {},
      displayLoadingGif: false,
      itemWidth: 200,
      activeDestinations: [],
      activeSources: [],
      reportRaw: {},
      reportType: '',
      showAllDestBtn: false,
      source: {},
      sourceRendering: true,
      sourceUsers: {}
    };
  },
  mounted() {
    this.$store.dispatch('setHeaderBtnsDisplay', true);
    window.scrollTo(0, 0);

    // For some reason this allows the page to load immediately with
    // rendering loader. nextTick does not work for this
    setTimeout(() => {
      this.setDestinations();
      this.setSources();
    }, 300);

    this.$nextTick(() => {
      window.addEventListener('resize', this.setItemWidth);

      //Init
      this.setItemWidth();
    });
  },
  methods: {
    createSReport(selected, type) {
      // Report Helpers needs this as an array
      selected.haulTrackers = selected.haulTrackerArr.slice();
      this.$emit('onCreateSReport', selected, type);
    },
    edit(selected, type) {
      selected.action = 'edit';
      this.reportRaw = selected;
      this.reportType = type;
      this.currentDisplay = 'report';
    },
    onArchive() {
      this.confirm.display = false;

      if (this.archiveType === 'destination') {
        this.displayLoadingGif = true;

        const dest = this.dest;
        const destUsers = this.destUsers;

        // Very important that both destination and destination users have the same settlement date
        const settlementDate = +Date.now();

        const destination = {
          id: dest.id,
          customer: this.customerList[dest.customerId].name,
          customerId: dest.customerId,
          updateId: dest.updateId,
          destinationName:
            this.masterDestinations[dest.destinationLocationId].destinationName,
          destinationLocationId: dest.destinationLocationId,
          startDate: destUsers.startDate,
          endDate: destUsers.endDate,
          settlementDate: settlementDate,
          chargeCommodity: dest.chargeCommodity,
          pricePerUnit: dest.pricePerUnit,
          mileageRate: dest.mileageRate,
          services: dest.services,
          haulTrackers: dest.haulTrackers,
          adminNote: dest.adminNote,
          settlementReports: dest.settlementReports,
          infoBoxes: dest.infoBoxes,
          customSelectors: dest.customSelectors,
          destinationStatus: dest.destinationStatus
        };

        const destinationUsers = {
          id: destUsers.id,
          customer: this.customerList[dest.customerId].name,
          customerId: dest.customerId,
          updateId: dest.updateId,
          destinationName:
            this.masterDestinations[dest.destinationLocationId].destinationName,
          destinationLocationId: dest.destinationLocationId,
          startDate: destUsers.startDate,
          endDate: destUsers.endDate,
          commodity: destUsers.commodity,
          projected: destUsers.projected,
          trackMileage: destUsers.trackMileage,
          services: destUsers.services,
          haulTrackers: destUsers.haulTrackers,
          note: destUsers.note,
          hauls: destUsers.hauls,
          settlementDate: settlementDate,
          userInfoBoxes: destUsers.userInfoBoxes,
          userCustomSelectors: destUsers.userCustomSelectors,
          destinationStatus: dest.destinationStatus,
          allowGuestUsers: destUsers.allowGuestUsers,
          guestUsers: destUsers.guestUsers,
          driverRate: destUsers.driverRate,
          alwaysAvailable: destUsers.alwaysAvailable,
          importantNote: destUsers.importantNote,
          dispatchedTo: destUsers.dispatchedTo
        };

        const archiveComplete = () => {
          this.displayLoadingGif = false;

          this.$q.notify({
            color: 'primary',
            textColor: 'white',
            message:
              this.masterDestinations[dest.destinationLocationId]
                .destinationName + ' Archived'
          });
        };

        const batch = db.batch();

        const destinationsDoc = db
          .collection('destinations_active')
          .doc(dest.id);
        const destinationsUsersDoc = db
          .collection('destinations_active_users')
          .doc(destUsers.id);
        const destinationsArchivedDoc = db
          .collection('destinations_archived')
          .doc();
        const destinationsArchivedUsersDoc = db
          .collection('destinations_archived_users')
          .doc();

        batch.delete(destinationsDoc);
        batch.delete(destinationsUsersDoc);

        batch.set(destinationsArchivedDoc, destination);
        batch.set(destinationsArchivedUsersDoc, destinationUsers);

        batch
          .commit()
          .then(() => {
            archiveComplete();
          })
          .catch(err => {
            console.log(err);
          });
      } else if (this.archiveType === 'source') {
        const source = this.source;
        const sourceUsers = this.sourceUsers;

        if (
          this.sourceUsers.alwaysAvailable &&
          !this.alwaysAvailableConfirmed
        ) {
          this.confirm.display = true;
          this.confirm.icon = 'outbound';
          this.confirm.iconColor = 'custom-green';
          this.confirm.iconStyle = '';
          this.confirm.message =
            "Source is set to 'Always Available'. Are you sure you want to archive source?";
          this.confirm.headerMessage =
            this.masterSources[source.sourceLocationId].sourceName;
          this.alwaysAvailableConfirmed = true;
          return;
        }

        // Reset for next time
        this.alwaysAvailableConfirmed = false;
        this.displayLoadingGif = true;

        // Very important that both destination and destination users have the same settlement date
        const settlementDate = +Date.now();

        const sourceArchive = {
          id: source.id,
          customer: this.customerList[source.customerId].name,
          customerId: source.customerId,
          updateId: source.updateId,
          sourceName: this.masterSources[source.sourceLocationId].sourceName,
          sourceLocationId: source.sourceLocationId,
          startDate: sourceUsers.startDate,
          endDate: sourceUsers.endDate,
          settlementDate: settlementDate,
          purchaseCommodity: source.purchaseCommodity,
          pricePerUnit: source.pricePerUnit,
          mileageRate: source.mileageRate,
          services: source.services,
          haulTrackers: source.haulTrackers,
          adminNote: source.adminNote,
          settlementReports: source.settlementReports,
          infoBoxes: source.infoBoxes,
          customSelectors: source.customSelectors,
          sourceStatus: source.sourceStatus
        };

        const sourceArchiveUsers = {
          id: sourceUsers.id,
          customer: this.customerList[source.customerId].name,
          customerId: source.customerId,
          updateId: source.updateId,
          sourceName: this.masterSources[source.sourceLocationId].sourceName,
          sourceLocationId: source.sourceLocationId,
          startDate: sourceUsers.startDate,
          endDate: sourceUsers.endDate,
          commodity: sourceUsers.commodity,
          settlementDate: settlementDate,
          trackMileage: sourceUsers.trackMileage,
          alwaysAvailable: sourceUsers.alwaysAvailable,
          trackRunningBalance: sourceUsers.trackRunningBalance,
          runningBalance: sourceUsers.runningBalance,
          services: sourceUsers.services,
          haulTrackers: sourceUsers.haulTrackers,
          note: sourceUsers.note,
          hauls: sourceUsers.hauls,
          userInfoBoxes: sourceUsers.userInfoBoxes,
          userCustomSelectors: sourceUsers.userCustomSelectors,
          sourceStatus: source.sourceStatus,
          projected: sourceUsers.projected,
          allowGuestUsers: sourceUsers.allowGuestUsers,
          guestUsers: sourceUsers.guestUsers,
          importantNote: sourceUsers.importantNote,
          dispatchedTo: sourceUsers.dispatchedTo
        };

        const archiveComplete = () => {
          this.displayLoadingGif = false;

          this.$q.notify({
            color: 'primary',
            textColor: 'white',
            icon: 'outbound',
            message:
              this.masterSources[source.sourceLocationId].sourceName +
              ' Archived'
          });
        };

        const batch = db.batch();

        const sourcesDoc = db.collection('sources_active').doc(source.id);
        const sourcesUsersDoc = db
          .collection('sources_active_users')
          .doc(sourceUsers.id);
        const sourcesArchivedDoc = db.collection('sources_archived').doc();
        const sourcesArchivedUsersDoc = db
          .collection('sources_archived_users')
          .doc();

        batch.delete(sourcesDoc);
        batch.delete(sourcesUsersDoc);

        batch.set(sourcesArchivedDoc, sourceArchive);
        batch.set(sourcesArchivedUsersDoc, sourceArchiveUsers);

        batch
          .commit()
          .then(() => {
            archiveComplete();
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    onConfirmArchive(data, type) {
      if (type === 'destination') {
        this.dest = data.destOriginal;
        this.destUsers = data.destUsersOriginal;
        this.archiveType = type;
        this.confirm.iconColor = 'custom-red';
        this.confirm.iconStyle = 'transform: rotate(180deg)';
        this.confirm.btn.color = 'custom-medium';
        this.confirm.message = `Archive ${data.destinationName}?`;
        this.confirm.headerMessage = data.customer;
      } else if (type === 'source') {
        this.source = data.sourceOriginal;
        this.sourceUsers = data.sourceUsersOriginal;
        this.archiveType = type;
        this.confirm.iconColor = 'custom-green';
        this.confirm.iconStyle = '';
        this.confirm.btn.color = 'custom-medium';
        this.confirm.message = `Archive ${data.sourceName}?`;
        this.confirm.headerMessage = data.customer;
      }
      this.confirm.display = true;
      this.confirm.icon = 'outbound';
    },
    setDestinations() {
      const destinations = [];
      const activeDestinations = JSON.parse(
        JSON.stringify(this.$store.state.activeDestinations)
      );
      const activeDestinationsUsers = JSON.parse(
        JSON.stringify(this.$store.state.activeDestinationsUsers)
      );

      // prevent from running until all info is here
      if (
        activeDestinations.length < 1 ||
        activeDestinationsUsers.length < 1 ||
        activeDestinations.length !== activeDestinationsUsers.length
      ) {
        return [];
      }

      const parseDestinations = (dest, destUsers) => {
        for (let i = 0; i < destUsers.length; i++) {
          // reject merge of dest & destUsers if..
          if (
            typeof dest[i] === 'undefined' ||
            typeof destUsers[i] === 'undefined'
          ) {
            return;
          }

          if (
            dest[i].updateId !== destUsers[i].updateId ||
            dest[i].id !== destUsers[i].id
          ) {
            return;
          }

          const destOriginal = JSON.parse(JSON.stringify(dest[i]));
          const destUsersOriginal = JSON.parse(JSON.stringify(destUsers[i]));

          const destInfo = destinationReport(dest[i].id);

          destinations.push({
            ...destInfo,
            ...{ destOriginal: destOriginal },
            ...{ destUsersOriginal: destUsersOriginal }
          });
        }
      };

      parseDestinations(activeDestinations, activeDestinationsUsers);

      destinations.sort(this.sortByAlpha);

      this.destRendering = false;
      this.activeDestinations = destinations.slice();
    },
    setItemWidth() {
      const cardWidth = this.$refs.sourceCard.$el.clientWidth;

      let cols = 3;
      if (this.$q.screen.lt.sm) {
        cols = 1;
      } else if (this.$q.screen.lt.lg) {
        cols = 2;
      }

      // 214 is fixed amount (padding, buttons, etc)
      this.itemWidth = cardWidth / cols - 204;
    },
    setSources() {
      const activeSources = JSON.parse(
        JSON.stringify(this.$store.state.activeSources)
      );
      const activeSourcesUsers = JSON.parse(
        JSON.stringify(this.$store.state.activeSourcesUsers)
      );

      const sourcesArr = [];

      if (
        activeSources.length < 1 ||
        activeSourcesUsers.length < 1 ||
        activeSources.length !== activeSourcesUsers.length
      ) {
        return [];
      }

      const parseSources = (sources, sourcesUsers) => {
        for (let i = 0; i < sourcesUsers.length; i++) {
          // Reject merge of source & sourceUsers if
          if (
            typeof sources[i] === 'undefined' ||
            typeof sourcesUsers[i] === 'undefined'
          ) {
            return [];
          }

          if (
            sources[i].updateId !== sourcesUsers[i].updateId ||
            sources[i].id !== sourcesUsers[i].id
          ) {
            return [];
          }

          const sourceOriginal = JSON.parse(JSON.stringify(sources[i]));
          const sourceUsersOriginal = JSON.parse(
            JSON.stringify(sourcesUsers[i])
          );

          const sourceInfo = sourceReport(sources[i].id);

          sourcesArr.push({
            ...sourceInfo,
            ...{ sourceOriginal: sourceOriginal },
            ...{ sourceUsersOriginal: sourceUsersOriginal }
          });
        }
      };

      parseSources(activeSources, activeSourcesUsers);

      sourcesArr.sort(this.sortByAlpha);

      this.activeSources = sourcesArr.slice();
      this.sourceRendering = false;
    },
    sortByAlpha(a, b) {
      if (a.customer + a.location < b.customer + b.location) {
        return -1;
      }
      if (a.customer + a.location > b.customer + b.location) {
        return 1;
      }
      return 0;
    }
  },
  computed: {
    customerList() {
      return JSON.parse(JSON.stringify(this.$store.state.customers));
    },
    destAs() {
      return this.$store.state.settingsUsers.destAs;
    },
    destHeight() {
      const destLength = this.activeDestinations.length;
      if (this.$q.screen.lt.sm) {
        return `height: ${destLength * 55 + 5}px`;
      } else if (this.$q.screen.lt.lg) {
        const addOne = destLength % 2 > 0 ? 55 : 0;
        return `height: ${Math.floor(destLength / 2) * 55 + addOne + 5}px`;
      } else {
        const addOne = destLength % 3 > 0 ? 55 : 0;
        return `height: ${Math.floor(destLength / 3) * 55 + addOne + 5}px`;
      }
    },
    masterDestinations() {
      return JSON.parse(JSON.stringify(this.$store.state.masterDestinations));
    },
    masterSources() {
      return JSON.parse(JSON.stringify(this.$store.state.masterSources));
    },
    sourceHeight() {
      const sourceLength = this.activeSources.length;

      if (this.$q.screen.lt.sm) {
        return `height: ${sourceLength * 55 + 5}px`;
      } else if (this.$q.screen.lt.lg) {
        const addOne = sourceLength % 2 > 0 ? 55 : 0;
        return `height: ${Math.floor(sourceLength / 2) * 55 + addOne + 5}px`;
      } else {
        const addOne = sourceLength % 3 > 0 ? 55 : 0;
        return `height: ${Math.floor(sourceLength / 3) * 55 + addOne + 5}px`;
      }
    },
    sourceInputs() {
      return this.$store.state.filterLists.sourceInputs;
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setItemWidth);
  },
  watch: {
    '$store.state.activeDestinations'() {
      this.setDestinations();
    },
    '$store.state.activeDestinationsUsers'() {
      this.setDestinations();
    },
    '$store.state.activeSources'() {
      this.setSources();
    },
    '$store.state.activeSourcesUsers'() {
      this.setSources();
    }
  }
};
</script>

<style scoped></style>
