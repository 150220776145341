<template>
  <div class="div-container-2">
    <ConfirmDialog
      v-if="confirmDelete"
      icon="info"
      iconColor="primary"
      :btn="btn"
      :message="confirmMessage"
      :headerMessage="confirmHeaderMessage"
      @canceled="confirmDelete = false"
      @confirmed="deleteInfoBox"
    />

    <OkDialog
      v-if="okDialog"
      icon="info"
      :message="okMessage"
      @okConfirmed="okDialog = false"
    />

    <div class="row q-pa-sm q-mt-xl">
      <q-space />
      <q-btn
        outline
        v-if="displayCreateNew"
        icon-right="list"
        label="List"
        color="primary"
        padding="xs md"
        @click="selectInfoBox"
      />
      <q-btn
        v-if="!displayCreateNew"
        outline
        icon-right="add"
        label="Add"
        color="primary"
        padding="xs md"
        @click="createNew"
      />
    </div>

    <div class="row q-mx-sm q-mt-xs">
      <div
        class="row justify-center items-center full-width rounded-borders text-custom-light q-mx-sm q-py-xs q-mb-md"
        style="background: radial-gradient(circle, #3336eb 0%, #0b0d6f 100%)"
      >
        <q-icon name="info" size="md" />
        <span class="text-body1 q-ml-sm"> Info Boxes </span>
      </div>
    </div>

    <q-card v-if="!displayCreateNew" flat bordered class="q-ma-sm q-mb-md">
      <q-list separator>
        <q-item
          v-for="(infoBox, index) in infoBoxes"
          :key="index"
          class="q-pa-md"
        >
          <q-item-section avatar>
            <q-avatar color="primary" size="md" text-color="white">
              {{ infoBox.name.charAt(0) }}
            </q-avatar>
          </q-item-section>
          <q-item-section>
            {{ infoBox.name }}
          </q-item-section>
          <q-item-section side>
            <div class="q-my-sm">
              <q-btn
                outline
                size="md"
                padding="xs"
                color="custom-orange"
                icon="edit"
                class="q-mr-sm"
                @click="editInfoBox(index)"
              />
              <q-btn
                outline
                size="md"
                padding="xs"
                color="custom-red"
                icon="delete"
                @click="deleteDialog(index)"
              />
            </div>
          </q-item-section>
        </q-item>
      </q-list>
    </q-card>

    <q-form v-if="displayCreateNew" @submit="onSubmit">
      <q-card flat bordered class="q-pa-md q-ma-sm q-mb-md">
        <q-input
          v-if="displayCreateNew"
          v-model="newInfoBox"
          outlined
          label="Enter Info Box Name"
          label-color="primary"
          color="primary"
          lazy-rules
          hide-bottom-space
          :rules="[val => (val && val.trim().length > 0) || '']"
        />
        <div v-if="!addInfoBox" class="q-mx-sm text-caption">
          <span class="text-bold">Note:</span> Changing Info Box name will not
          affect Info Boxes already attached to a Source.
        </div>

        <div>
          <q-card flat bordered class="q-mt-md">
            <div>
              <q-radio
                v-model="userAuth"
                color="primary"
                val="admins"
                label="Display To Admins Only"
              />
            </div>
            <div>
              <q-radio
                v-model="userAuth"
                color="primary"
                val="users"
                label="Display Info To Admins & Users"
              />
            </div>
          </q-card>
        </div>
      </q-card>

      <div class="q-mt-sm q-mb-xl q-mr-sm" align="right">
        <q-btn
          v-if="addInfoBox"
          label="Add"
          icon="add"
          padding="xs md"
          color="primary"
          type="submit"
        />
        <q-btn
          v-if="!addInfoBox"
          label="Edit"
          icon="edit"
          padding="xs md"
          color="primary"
          type="submit"
        />
      </div>
    </q-form>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import OkDialog from '@/components/OkDialog.vue';

const db = firebase.firestore();

export default {
  name: 'SourceInfoBoxes',
  components: {
    ConfirmDialog,
    OkDialog
  },
  props: {
    infoBoxes: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      addInfoBox: true,
      btn: {
        label: 'Delete',
        icon: 'delete',
        color: 'custom-red'
      },
      confirmDelete: false,
      confirmMessage: '',
      confirmHeaderMessage: '',
      displayCreateNew: false,
      newInfoBox: '',
      okDialog: false,
      okMessage: '',
      selectedInfoBox: null,
      userAuth: 'admins'
    };
  },
  methods: {
    createNew() {
      this.addInfoBox = true;
      this.newInfoBox = '';
      this.userAuth = 'admins';
      this.displayCreateNew = true;
    },
    deleteDialog(index) {
      this.confirmMessage = 'Are you sure you want to delete info box?';
      this.confirmHeaderMessage = this.infoBoxes[index].name;
      this.confirmDelete = true;
      this.selectedInfoBox = index;
    },
    deleteInfoBox() {
      this.confirmDelete = false;
      const index = this.selectedInfoBox;

      const infoBox = {
        name: this.infoBoxes[index].name,
        userAuth: this.infoBoxes[index].userAuth
      };

      const batch = db.batch();

      const servicesUsersDoc = db.collection('settings_users').doc('settings');

      batch.update(servicesUsersDoc, {
        'sourceSettings.infoBoxes':
          firebase.firestore.FieldValue.arrayRemove(infoBox)
      });

      batch.commit().catch(err => {
        console.log(err);
      });

      this.notify('Info Box Deleted', 'custom-red');
    },
    editInfoBox(index) {
      this.selectedInfoBox = index;
      this.addInfoBox = false;

      this.newInfoBox = this.infoBoxes[index].name;
      this.userAuth = this.infoBoxes[index].userAuth ? 'users' : 'admins';
      this.displayCreateNew = true;
    },
    notify(message, color) {
      this.$q.notify({
        color: color,
        textColor: 'white',
        icon: 'info',
        message: message
      });
    },
    onActivateInfoBox(infoBox) {
      if (this.addInfoBox) {
        for (let i = 0; i < this.infoBoxes.length; i++) {
          if (this.infoBoxes[i].name === infoBox) {
            this.okMessage = `${infoBox} already exists!`;
            this.okDialog = true;
            return;
          }
        }
      }

      const userAuth = this.userAuth === 'admins' ? false : true;

      const newInfoBox = {
        name: infoBox,
        userAuth: userAuth
      };

      const infoBoxes = this.infoBoxes.slice();

      if (this.addInfoBox) {
        infoBoxes.push(newInfoBox);
      } else {
        infoBoxes[this.selectedInfoBox] = newInfoBox;
      }

      const batch = db.batch();

      const serviceUsersDoc = db.collection('settings_users').doc('settings');

      batch.update(serviceUsersDoc, {
        'sourceSettings.infoBoxes': infoBoxes
      });

      batch.commit().catch(err => {
        console.log(err);
      });

      this.notify('Info Box Added', 'primary');
      this.selectInfoBox();
    },
    onSubmit() {
      let infoBox = this.newInfoBox;
      infoBox = infoBox.trim();
      infoBox = infoBox.replaceAll('.', '');
      infoBox = infoBox.replace(/(^\w{1})|(\s+\w{1})/g, letter =>
        letter.toUpperCase()
      );

      this.onActivateInfoBox(infoBox);
    },
    selectInfoBox() {
      this.displayCreateNew = false;
    }
  }
};
</script>

<style scoped></style>
