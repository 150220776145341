<template>
  <div class="row justify-center full-width">
    <OkDialog
      v-if="ok.display"
      :icon="ok.icon"
      :iconColor="ok.iconColor"
      :iconStyle="ok.iconStyle"
      :headerMessage="ok.headerMessage"
      :message="ok.message"
      @okConfirmed="okCancel"
    />

    <div class="row full-width q-pa-md">
      <div class="half-width half-width-mr">
        <q-card flat bordered class="q-mb-md">
          <div class="row justify-center items-center q-py-xs">
            <q-icon name="account_circle" color="primary" size="md" />
            <span class="text-body1 text-primary word-wrap q-ml-sm">
              {{ source.customer }}
            </span>
          </div>
          <q-separator class="full-width" />

          <div class="q-px-md q-py-md text-body1 text-primary">
            <div class="row items-center">
              <q-icon
                name="location_on"
                color="primary"
                size="md"
                class="q-mr-xs"
              />

              <div v-if="location.customerId">
                {{ location.sourceName }}
              </div>
              <q-space />
              <div class="q-py-xs">
                <q-btn
                  label="Edit Location"
                  no-caps
                  color="custom-light"
                  padding="2px md"
                />
              </div>
            </div>
          </div>
          <q-separator />

          <div class="row items-center q-pa-md">
            <div
              class="row"
              :style="
                $q.screen.lt.sm ? 'width: 100%' : 'width: 49%; margin-right: 2%'
              "
            >
              <div class="row items-center full-width no-wrap ellipsis">
                <div
                  class="row justify-center items-center bg-custom-light rounded-borders text-h5"
                  style="width: 44px; height: 44px; position: relative"
                >
                  <span style="z-index: 2">
                    {{ source.customer.charAt(0) }}
                  </span>
                  <div
                    class="row items-center justify-center bg-custom-light-blue"
                    :style="`position: absolute; bottom: 0; width: 44px;
                  border-bottom-left-radius: 4px;
                  border-bottom-right-radius: 4px;
                  border-top-right-radius: ${
                    percentHauled === '100%' ? 4 : 0
                  }px;
                  border-top-left-radius: ${percentHauled === '100%' ? 4 : 0}px;
                  border-bottom-top-radius: 4px;
                  height: ${percentHauled}`"
                  ></div>
                </div>

                <div class="ellipsis q-ml-md">
                  {{ Math.round(source.hauled) }} /
                  {{ Math.round(projected) || '--' }}
                  {{ source.measurementUnit }}
                </div>
              </div>
            </div>
          </div>
          <q-separator />

          <div class="row items-center q-pa-md">
            <div
              class="row justify-center items-center full-width text-bold text-body1 text-custom-orange"
            >
              Note
              <q-icon
                v-if="!importantNote"
                name="edit_note"
                size="28px"
                class="q-ml-sm"
              />
              <q-icon
                v-if="importantNote"
                name="announcement"
                size="28px"
                color="custom-red"
                class="q-ml-sm"
              />
            </div>
          </div>

          <div class="row q-px-md">
            <div class="full-width q-mb-md">
              <q-input
                outlined
                v-model="note"
                label="Note"
                label-color="custom-orange"
                color="custom-orange"
                autogrow
                dense
              />
            </div>
            <div class="q-px-sm q-py-xs"></div>
          </div>
        </q-card>
      </div>

      <div class="half-width">
        <q-card flat bordered class="q-pb-sm q-mb-md">
          <div class="row q-pa-md">
            <div
              class="row justify-center full-width rounded-borders text-primary text-bold text-body1 q-py-xs q-mb-lg"
              style="border: 1px solid #0b0d6f"
            >
              Services
            </div>

            <div
              v-for="(service, index) in source.services"
              :key="index + 100"
              class="row justify-center full-width"
            >
              <div>
                <div
                  class="row justify-center text-subtitle1 text-primary full-width"
                >
                  {{ service.name }}
                </div>

                <div class="row items-center full-width q-py-sm">
                  <q-checkbox
                    v-model="serviceCheckboxes[index]"
                    dense
                    :label="service.name + ' Complete'"
                    color="custom-red"
                    size="lg"
                  />
                </div>
              </div>
              <q-separator
                v-if="index !== serviceCheckboxes.length - 1"
                color="grey-4"
                class="q-my-md full-width"
              />
            </div>
          </div>
        </q-card>
      </div>
    </div>
  </div>
</template>

<script>
import { date } from 'quasar';
import firebase from 'firebase/compat/app';
import OkDialog from '@/components/OkDialog.vue';
import { round2Dec } from '@/utils/UtilHelpers.js';

const db = firebase.firestore();

export default {
  name: 'EditSourceUsers',
  components: {
    OkDialog
  },
  props: {
    source: {
      type: Object,
      required: true
    },
    mapView: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      customSelectors: [],
      importantNote: false,
      location: {
        customerId: null,
        lat: null,
        lng: null
      },
      note: '',
      ok: {
        display: false,
        icon: 'outbound',
        iconColor: 'custom-green',
        iconStyle: '',
        headerMessage: '',
        message: ''
      },
      projected: 0,
      serviceCheckboxes: []
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.setSourceInfo();
    this.importantMessage();
  },
  methods: {
    cancel() {
      if (!this.source.id) {
        this.$emit('onSetId', 'no_history');
        this.$emit('onSubmit');
      } else {
        this.$emit('onSetId', this.source.id);
        this.$emit('onSubmit');
      }
    },
    cancelLayerTwoInputs() {
      if (this.source.action === 'edit') {
        this.location = this.masterSources[this.source.locationId];
      }
      this.manageAttachments = false;
    },
    importantMessage() {
      if (this.importantNote) {
        const importantNoteIds = this.$store.state.importantNoteIds;
        let spamUser = true;
        for (let i = 0; i < importantNoteIds.length; i++) {
          if (importantNoteIds[i].id === this.source.id) {
            const tsFiveMinAgo = +new Date() - 300000;
            if (tsFiveMinAgo < importantNoteIds[i].ts) {
              spamUser = false;
            }
          }
        }
        if (spamUser && this.source.note.length !== 0) {
          this.$store.dispatch('setImportantNoteIds', {
            id: this.source.id,
            ts: +new Date()
          });

          this.ok = {
            display: true,
            icon: 'announcement',
            iconColor: 'custom-red',
            iconStyle: '',
            headerMessage: 'Important Note',
            message: this.note
          };
        }
      }
    },
    okCancel() {
      this.ok = {
        display: false,
        icon: 'outbound',
        iconColor: 'custom-green',
        iconStyle: '',
        headerMessage: '',
        message: ''
      };
    },
    onEnterSource() {
      const id = this.source.id;

      const userServices = [];
      for (let i = 0; i < this.source.services.length; i++) {
        const service = this.source.services[i];

        let completeTs = null;
        if (service.complete && this.serviceCheckboxes[i]) {
          completeTs = service.completeTs;
        } else if (!service.complete && this.serviceCheckboxes[i]) {
          completeTs = +new Date();
        } else if (!this.serviceCheckboxes[i]) {
          completeTs = null;
        }

        const obj = {
          name: this.source.services[i].name,
          complete: this.serviceCheckboxes[i],
          completeTs: completeTs
        };
        userServices.push(obj);
      }

      const sourceUsers = {
        services: userServices,
        note: this.note
      };

      const notify = message => {
        this.$q.notify({
          color: 'primary',
          textColor: 'white',
          icon: 'outbound',
          message: message
        });
      };

      db.collection('sources_active_users').doc(id).update(sourceUsers);

      notify('Source Updated');
      this.$emit('onSubmit');
      this.$emit('onSetId', id);
    },
    round(num) {
      return round2Dec(num);
    },
    setSourceInfo() {
      const source = JSON.parse(JSON.stringify(this.source));
      this.note = source.note;
      this.importantNote = source.importantNote;
      this.location = this.masterSources[source.locationId];
      this.location.id = source.locationId;
      this.projected = source.projected;

      for (let i = 0; i < source.services.length; i++) {
        this.serviceCheckboxes.push(source.services[i].complete);
      }

      this.customSelectors = [];
      for (let i = 0; i < this.userSourceSettings.customSelectors.length; i++) {
        const customSelector = this.userSourceSettings.customSelectors[i];
        const options = [];

        for (let j = 0; j < customSelector.options.length; j++) {
          const option = customSelector.options[j];

          options.push({
            label: option.option,
            value: option.option,
            color: option.optionColor
          });
        }

        let model = customSelector.options[0].option;

        for (let j = 0; j < source.userCustomSelectors.length; j++) {
          const selector = source.userCustomSelectors[j];
          if (selector.name === customSelector.name) {
            model = selector.model;
          }
        }

        this.customSelectors.push({
          name: customSelector.name,
          options: options,
          model: model,
          userAuth: customSelector.userAuth
        });
      }
    }
  },
  computed: {
    isSuper() {
      let isSuper = false;
      if (this.$store.state.user.superUser === true) {
        isSuper = true;
      }
      return isSuper;
    },
    masterSources() {
      return JSON.parse(JSON.stringify(this.$store.state.masterSources));
    },
    percentHauled() {
      const pH = !this.projected
        ? this.round(
            this.source.hauled / this.userSourceSettings.projectedPlaceholder
          ) * 100
        : this.round(this.source.hauled / this.projected) * 100;
      return pH > 100 ? '100%' : pH + '%';
    },
    startDate() {
      return date.formatDate(this.source.startDate, 'ddd, MMM DD YYYY');
    },
    userSourceSettings() {
      return JSON.parse(
        JSON.stringify(this.$store.state.settingsUsers.sourceSettings)
      );
    }
  },
  watch: {
    source() {
      this.setSourceInfo();
    }
  }
};
</script>

<style scoped></style>
