<template>
  <div class="row justify-center q-pb-xl">
    <q-card
      class="q-mb-lg"
      :class="$q.screen.gt.xs ? 'q-ma-lg' : 'q-ma-md'"
      style="max-width: 450px"
      :style="$q.screen.gt.xs ? 'min-width: 380px;' : 'min-width: 320px;'"
    >
      <div class="row justify-center bg-grey-1 q-px-md q-py-xs">
        <span class="text-h6 text-custom-red">Services</span>
      </div>
      <q-separator v-if="source.services.length" />

      <div
        v-for="(service, index) in source.services"
        :key="index + 50 + service.name"
        class="q-pt-sm q-pl-md"
      >
        <div>
          <span class="q-pl-sm text-primary text-bold">
            {{ service.name }}
          </span>
        </div>

        <div v-if="isAdmin" class="row q-mr-md">
          <span class="q-ml-lg q-pl-sm q-py-xs">Price</span>
          <q-space></q-space>
          <span class="info-font q-pr-xs">
            ${{ service.price.toFixed(2) }}
          </span>
        </div>

        <div class="row q-mr-md q-mb-xs">
          <span class="q-ml-lg q-pl-sm q-py-xs">Complete</span>
          <q-space />
          <q-avatar
            v-if="service.complete"
            rounded
            icon="done_outline"
            color="custom-red"
            size="sm"
            font-size="14px"
            text-color="white"
            class="q-mr-xs"
          />
          <q-avatar
            v-if="!service.complete"
            rounded
            icon="done_outline"
            color="grey-5"
            size="sm"
            font-size="14px"
            text-color="white"
            class="q-mr-xs"
          />
        </div>
        <q-separator
          v-if="index + 1 !== source.services.length"
          class="q-ml-sm q-mr-md"
        />
      </div>

      <q-separator color="black" />
      <div class="row justify-center bg-grey-1 q-py-xs q-px-md">
        <span class="text-h6 text-custom-red">Haul Trackers</span>
      </div>
      <q-separator v-if="Object.keys(source.haulTrackers).length" />

      <div
        v-for="(tracker, trackerOption, index) in source.haulTrackers"
        :key="index + trackerOption"
        class="q-pt-sm q-pl-md"
      >
        <div
          v-if="
            trackerOption.startsWith('***&&&***') ||
            trackerOption.startsWith('***&&&^^^')
          "
        >
          <div>
            <span class="q-ml-sm text-primary text-bold">
              {{ trackerLabel(trackerOption) }}
            </span>
          </div>
          <div
            v-for="(obj, trackerType, i) in tracker"
            :key="i + trackerType"
            class="row"
          >
            <div v-if="trackerType !== 'Total'" class="row full-width">
              <span class="q-ml-lg q-pl-sm text-bold"> {{ trackerType }}</span>
              <q-space></q-space>
              <span class="info-font q-pr-md">
                {{ tracker[trackerType].haulAmount.toLocaleString('en-US') }}
                {{ measurementUnit }}
              </span>
            </div>
            <div v-if="trackerType === 'Total'" class="row full-width">
              <span class="q-ml-lg q-pl-sm text-bold"> Total</span>
              <q-space></q-space>
              <span class="info-font q-pr-md">
                {{ tracker[trackerType].totalMU }}
              </span>
            </div>
          </div>
        </div>
        <div
          v-if="
            !trackerOption.startsWith('***&&&***') &&
            !trackerOption.startsWith('***&&&^^^')
          "
        >
          <div>
            <span class="q-ml-sm text-primary text-bold">
              {{ trackerLabel(trackerOption) }}
            </span>
          </div>
          <div
            v-for="(obj, trackerType, i) in tracker"
            :key="i + trackerType"
            class="row"
          >
            <div class="row full-width">
              <span class="q-ml-lg q-pl-sm text-bold"> {{ trackerType }}</span>
              <q-space></q-space>
              <span class="info-font q-pr-md">
                {{ tracker[trackerType].haulAmount.toLocaleString('en-US') }}
                {{ measurementUnit }}
              </span>
            </div>

            <div class="row full-width">
              <span v-if="source.trackMileage" class="q-ml-lg q-pl-sm">
                Mileage</span
              >
              <q-space v-if="source.trackMileage"></q-space>
              <span v-if="source.trackMileage" class="q-mr-md info-font">
                {{ tracker[trackerType].mileage }} Miles</span
              >
            </div>
          </div>
        </div>
        <q-separator
          v-if="index !== Object.keys(source.haulTrackers).length - 1"
          class="q-ml-sm q-mr-md"
        />
      </div>

      <div v-if="source.trackMileage">
        <q-separator />
        <div class="row items-center q-ml-lg q-mr-md">
          <span class="q-my-xs">Total Mileage</span>
          <q-space></q-space>
          <span class="info-font">
            {{ source.totalMileage.toLocazleString('en-US') }} Miles
          </span>
        </div>
      </div>
      <q-separator color="black" />

      <div class="row justify-center bg-grey-1 q-py-xs q-px-md">
        <span class="text-h6 text-custom-red">Destinations</span>
      </div>
      <q-separator v-if="Object.keys(source.destinations).length > 0" />

      <div
        v-for="(source, destinationCustomerId, index) in source.destinations"
        :key="index + destinationCustomerId"
        class="q-pb-sm q-pt-xs"
      >
        <div>
          <span class="q-pl-lg text-primary text-bold">
            {{ customerList[destinationCustomerId].name }}
          </span>
        </div>
        <div
          v-for="(obj, destinationLocationId, i) in source"
          :key="i + destinationLocationId"
          class="row"
        >
          <span class="q-pl-lg q-ml-lg">
            {{
              setDestination({
                destinationLocationId: destinationLocationId,
                sourceAsDestination: obj.sourceAsDestination
              })
            }}</span
          >
          <q-space></q-space>
          <span class="q-mr-md info-font">
            {{
              source[destinationLocationId].haulAmount.toLocaleString('en-US')
            }}
            {{ measurementUnit }}</span
          >
        </div>
      </div>
      <q-separator color="black" />

      <div class="row justify-center items-center bg-grey-1 q-py-xs q-px-md">
        <span class="text-h6 text-custom-red">Hauls </span>
      </div>

      <div>
        <q-separator v-if="source.hauls.length > 0" />
        <div
          v-for="(haul, index) in source.hauls"
          :key="haul.dateTime + index"
          class="q-pt-xs"
        >
          <div class="row q-px-sm">
            <div class="word-wrap" style="width: 44%">
              <div class="text-primary text-bold">
                {{ customerList[haul.sourceCustomerId].name }}
              </div>
              <div class="text-grey-7 text-bold">
                {{ masterSources[haul.sourceLocationId].sourceName }}
              </div>
            </div>
            <q-space />
            <q-icon
              class="self-center"
              name="forward"
              color="primary"
              size="sm"
            />
            <q-space />
            <div class="word-wrap" style="width: 44%">
              <div class="text-primary text-right text-bold">
                {{ customerList[haul.destinationCustomerId].name }}
              </div>
              <div class="text-grey-7 text-bold text-right">
                {{ setDestination(haul) }}
              </div>
            </div>
          </div>
          <q-separator class="q-mx-sm" />

          <div class="row">
            <span class="q-pl-lg">Amount</span>
            <q-space></q-space>
            <span class="q-mr-sm text-bold">
              {{ haul.haulAmount.toLocaleString('en-US') }}
              {{ measurementUnit }}
            </span>
          </div>
          <div class="row">
            <span class="q-pl-lg">Date</span>
            <q-space></q-space>
            <span class="q-mr-sm text-grey-7">
              {{ new Date(haul.dateTime).toDateString() }}
            </span>
          </div>
          <div class="row">
            <span class="q-pl-lg">Time</span>
            <q-space></q-space>
            <span class="q-mr-sm text-grey-7">
              {{ new Date(haul.dateTime).toLocaleTimeString() }}
            </span>
          </div>
          <div class="row">
            <span class="q-pl-lg">Driver</span>
            <q-space></q-space>
            <span class="q-mr-sm text-grey-7">
              {{ userList.userIds[haul.driver] || '(Deleted)' }}
            </span>
          </div>
          <div
            v-for="tracker in haul.sourceHaulTrackers"
            :key="tracker.type"
            class="row"
          >
            <span class="q-pl-lg">
              {{ tracker.type }}
            </span>
            <q-space></q-space>
            <span class="q-mr-sm text-grey-7">
              {{ tracker.option }}
            </span>
          </div>
          <div
            v-for="tracker in haul.destinationHaulTrackers"
            :key="tracker.type"
            class="row"
          >
            <span class="q-pl-lg">
              {{ trackerLabel(tracker.type) }}
            </span>
            <q-space></q-space>
            <span class="q-mr-sm text-grey-7">
              {{ tracker.option }}
            </span>
          </div>
          <div class="row no-wrap">
            <span class="q-pl-lg">Haul Note</span>
            <q-space></q-space>
            <span class="q-mr-sm text-grey-7 ellipsis" style="max-width: 60%">
              {{ haul.haulNote }}
            </span>
          </div>
          <q-separator v-if="index !== source.hauls.length - 1" />
        </div>
      </div>
    </q-card>
  </div>
</template>

<script>
export default {
  name: 'SourceInsights',
  props: {
    source: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      barStyle: {
        right: '0px',
        borderRadius: '3px',
        backgroundColor: '#bdbdbd',
        width: '7px',
        opacity: 0.2
      },
      displayInsights: true,
      scrollHeight: 'height: 600px',
      thumbStyle: {
        right: '0px',
        borderRadius: '3px',
        backgroundColor: '#9e9e9e',
        width: '7px',
        opacity: 0.75
      }
    };
  },
  methods: {
    setDestination(obj) {
      if (!obj.sourceAsDestination) {
        return this.masterDestinations[obj.destinationLocationId]
          .destinationName;
      } else {
        return this.masterSources[obj.destinationLocationId].sourceName;
      }
    },
    trackerLabel(label) {
      if (label.startsWith('***&&&')) {
        return label.substring(9);
      }

      return label;
    }
  },
  computed: {
    customerList() {
      return JSON.parse(JSON.stringify(this.$store.state.customers));
    },
    isAdmin() {
      return this.$store.state.user.superAdmin || this.$store.state.user.admin;
    },
    masterDestinations() {
      return JSON.parse(JSON.stringify(this.$store.state.masterDestinations));
    },
    masterSources() {
      return JSON.parse(JSON.stringify(this.$store.state.masterSources));
    },
    measurementUnit() {
      return this.source.measurementUnit;
    },
    userList() {
      return JSON.parse(JSON.stringify(this.$store.state.userList));
    }
  }
};
</script>

<style scoped></style>
