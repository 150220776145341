<template>
  <div class="div-container-2">
    <ConfirmDialog
      v-if="confirmDelete"
      icon="radio_button_checked"
      iconColor="primary"
      :btn="btn"
      :message="confirmMessage"
      :headerMessage="confirmHeaderMessage"
      @canceled="confirmDelete = false"
      @confirmed="deleteCustomSelector"
    />

    <OkDialog
      v-if="okDialog"
      icon="info"
      :message="okMessage"
      @okConfirmed="okDialog = false"
    />

    <div class="row q-pa-sm q-mt-xl">
      <q-space />
      <q-btn
        v-if="displayCreateNew"
        outline
        icon-right="list"
        label="List"
        color="primary"
        padding="xs md"
        @click="selectCustomSelector"
      />
      <q-btn
        v-if="!displayCreateNew"
        outline
        icon-right="add"
        label="Add"
        color="primary"
        padding="xs md"
        @click="createNew"
      />
    </div>

    <div class="row q-mx-sm q-mt-xs">
      <div
        class="row justify-center items-center full-width rounded-borders text-custom-light q-mx-sm q-py-xs q-mb-md"
        style="background: radial-gradient(circle, #3336eb 0%, #0b0d6f 100%)"
      >
        <q-icon name="radio_button_checked" size="md" />
        <span class="text-body1 q-ml-sm"> Custom Selectors </span>
      </div>
    </div>

    <q-card v-if="!displayCreateNew" flat bordered class="q-ma-sm q-mb-md">
      <q-list separator>
        <q-item
          v-for="(customSelector, index) in customSelectors"
          :key="index"
          class="q-pa-md"
        >
          <q-item-section avatar>
            <q-avatar color="primary" size="md" text-color="white">
              {{ customSelector.name.charAt(0) }}
            </q-avatar>
          </q-item-section>
          <q-item-section>
            {{ customSelector.name }}
          </q-item-section>
          <q-item-section side class="cursor-pointer">
            <div class="q-my-sm">
              <q-btn
                outline
                size="md"
                padding="xs"
                color="custom-orange"
                icon="edit"
                class="q-mr-sm"
                @click="editCustomSelector(index)"
              />
              <q-btn
                outline
                size="md"
                padding="xs"
                color="custom-red"
                icon="delete"
                @click="deleteDialog(index)"
              />
            </div>
          </q-item-section>
        </q-item>
      </q-list>
    </q-card>

    <q-form v-if="displayCreateNew" @submit="onSubmit">
      <q-card flat bordered class="q-pa-md q-ma-sm q-mb-md">
        <q-input
          v-model="newCustomSelector"
          outlined
          label="Enter Custom Selector Name"
          label-color="primary"
          color="primary"
          lazy-rules
          hide-bottom-space
          :rules="[val => (val && val.trim().length > 0) || '']"
        />
        <div class="q-mt-lg q-ml-md">
          <span class="text-bold text-primary"
            >First option will be default</span
          >
        </div>

        <div>
          <q-card flat bordered class="q-px-md q-pt-md q-mb-md">
            <div
              v-for="(option, index) in options"
              :key="index"
              class="q-mb-lg"
            >
              <q-input
                v-model="option.option"
                dense
                outlined
                label="Enter Option Name"
                label-color="primary"
                color="primary"
                class="q-mb-sm"
                lazy-rules
                hide-bottom-space
                :rules="[val => (val && val.trim().length > 0) || '']"
              >
                <template v-slot:append>
                  <q-icon
                    name="clear"
                    color="custom-red"
                    class="cursor-pointer"
                    @click="deleteOption(index)"
                  >
                  </q-icon>
                </template>
              </q-input>
              <div class="text-body1 text-bold">Option Color</div>
              <q-option-group
                :options="option.colorOptions"
                type="radio"
                size="sm"
                v-model="option.colorModel"
              />
            </div>
          </q-card>
        </div>

        <div>
          <q-card flat bordered>
            <div>
              <q-radio
                v-model="userAuth"
                color="primary"
                val="admins"
                label="Display To Admins Only"
              />
            </div>
            <div>
              <q-radio
                v-model="userAuth"
                color="primary"
                val="users"
                label="Display Info To Admins & Users"
              />
            </div>
          </q-card>
        </div>
      </q-card>

      <div align="right" class="q-mr-sm q-mb-xl">
        <q-btn
          flat
          label="Option"
          icon="add"
          color="primary"
          padding="xs md"
          @click="addOption"
        />
        <q-btn
          label="Submit"
          icon="check"
          type="submit"
          color="primary"
          padding="xs md"
          class="q-ml-sm"
        />
      </div>
    </q-form>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import OkDialog from '@/components/OkDialog.vue';

const db = firebase.firestore();

export default {
  name: 'SourceCustomSelectors',
  components: {
    ConfirmDialog,
    OkDialog
  },
  props: {
    customSelectors: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      addCustomSelector: true,
      btn: {
        label: 'Delete',
        icon: 'delete',
        color: 'custom-red'
      },
      confirmDelete: false,
      confirmMessage: '',
      confirmHeaderMessage: '',
      displayCreateNew: false,
      newCustomSelector: '',
      okDialog: false,
      okMessage: '',
      options: [],
      selectedCustomSelector: null,
      userAuth: 'admins'
    };
  },
  methods: {
    addOption() {
      const option = {
        option: '',
        colorModel: 'custom-red',
        colorOptions: [
          { label: 'Red', value: 'custom-red', color: 'custom-red' },
          { label: 'Orange', value: 'custom-orange', color: 'custom-orange' },
          {
            label: 'Green',
            value: 'custom-green',
            color: 'custom-green'
          },
          {
            label: 'Artichoke',
            value: 'custom-artichoke',
            color: 'custom-artichoke'
          },
          {
            label: 'Blue',
            value: 'custom-light-blue',
            color: 'custom-light-blue'
          },
          { label: 'Black', value: 'black', color: 'black' }
        ]
      };
      this.options.push(option);
    },
    createNew() {
      this.newCustomSelector = '';
      this.options = [];
      this.userAuth = 'admins';
      this.displayCreateNew = true;
      this.addOption();
    },
    deleteDialog(index) {
      this.confirmMessage = 'Are you sure you want to delete custom selector?';
      this.confirmHeaderMessage = this.customSelectors[index].name;
      this.confirmDelete = true;
      this.selectedCustomSelector = index;
    },
    deleteCustomSelector() {
      this.confirmDelete = false;
      const index = this.selectedCustomSelector;

      const customSelector = this.customSelectors[index];

      const batch = db.batch();

      const servicesUsersDoc = db.collection('settings_users').doc('settings');

      batch.update(servicesUsersDoc, {
        'sourceSettings.customSelectors':
          firebase.firestore.FieldValue.arrayRemove(customSelector)
      });

      batch.commit().catch(err => {
        console.log(err);
      });

      this.notify('Info Selector Deleted', 'custom-red');
    },
    deleteOption(index) {
      this.options.splice(index, 1);
    },
    editCustomSelector(index) {
      this.selectedCustomSelector = index;
      this.addCustomSelector = false;
      this.newCustomSelector = this.customSelectors[index].name;
      this.displayCreateNew = true;
      this.userAuth =
        this.customSelectors[index].userAuth === true ? 'users' : 'admins';

      this.options = [];
      for (let i = 0; i < this.customSelectors[index].options.length; i++) {
        const option = this.customSelectors[index].options[i];

        const optionObj = {
          option: option.option,
          colorModel: option.optionColor,
          colorOptions: [
            { label: 'Red', value: 'custom-red', color: 'custom-red' },
            { label: 'Orange', value: 'custom-orange', color: 'custom-orange' },
            {
              label: 'Green',
              value: 'custom-green',
              color: 'custom-green'
            },
            {
              label: 'Artichoke',
              value: 'custom-artichoke',
              color: 'custom-artichoke'
            },
            {
              label: 'Blue',
              value: 'custom-light-blue',
              color: 'custom-light-blue'
            },
            { label: 'Black', value: 'black', color: 'black' }
          ]
        };

        this.options.push(optionObj);
      }
    },
    notify(message, color) {
      this.$q.notify({
        color: color,
        textColor: 'white',
        icon: 'info',
        message: message
      });
    },
    onActivateCustomSelector(customSelector) {
      for (let i = 0; i < this.customSelectors.length; i++) {
        if (
          this.customSelectors[i].name === customSelector &&
          this.addCustomSelector
        ) {
          this.okMessage = `${customSelector} already exists!`;
          this.okDialog = true;
          return;
        }
      }

      const uniqueOptions = new Set(this.options.map(option => option.option));

      if (uniqueOptions.size < this.options.length) {
        this.okMessage = `All options must be unique!`;
        this.okDialog = true;
        return;
      }

      const options = [];
      for (let i = 0; i < this.options.length; i++) {
        const option = this.options[i];
        option.option = option.option.trim();
        option.option = option.option.replaceAll('.', '');
        option.option = option.option.replace(/(^\w{1})|(\s+\w{1})/g, letter =>
          letter.toUpperCase()
        );

        options.push({ option: option.option, optionColor: option.colorModel });
      }

      if (this.options.length < 1) {
        this.okMessage = `Please enter an option`;
        this.okDialog = true;
        return;
      }

      const userAuth = this.userAuth === 'admins' ? false : true;

      const newCustomSelector = {
        name: customSelector,
        userAuth: userAuth,
        options: options
      };

      const customSelectors = [];
      for (let i = 0; i < this.customSelectors.length; i++) {
        if (i !== this.selectedCustomSelector) {
          customSelectors.push(this.customSelectors[i]);
        } else {
          customSelectors.push(newCustomSelector);
        }
      }

      if (this.addCustomSelector === true) {
        customSelectors.push(newCustomSelector);
      }

      const batch = db.batch();

      const serviceUsersDoc = db.collection('settings_users').doc('settings');

      batch.update(serviceUsersDoc, {
        'sourceSettings.customSelectors': customSelectors
      });

      batch.commit().catch(err => {
        console.log(err);
      });

      this.notify('Custom Selector Added', 'primary');
      this.selectCustomSelector();
    },
    onSubmit() {
      let customSelector = this.newCustomSelector;
      customSelector = customSelector.trim();
      customSelector = customSelector.replaceAll('.', '');
      customSelector = customSelector.replace(/(^\w{1})|(\s+\w{1})/g, letter =>
        letter.toUpperCase()
      );

      this.onActivateCustomSelector(customSelector);
    },
    selectCustomSelector() {
      this.displayCreateNew = false;
    }
  }
};
</script>

<style scoped></style>
