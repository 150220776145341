<template>
  <div class="row full-width">
    <q-linear-progress v-if="loader" query color="primary" />

    <div class="row justify-center full-width q-px-md">
      <q-card flat bordered v-if="!isUser" class="q-mt-md q-mx-md full-width">
        <div class="row items-center justify-center q-py-sm q-ml-sm">
          <q-icon name="filter_alt" color="primary" size="lg" class="q-ml-xs" />
          <span class="text-body1 text-primary word-wrap q-ml-sm">Filter</span>
        </div>
        <q-separator />
        <div class="row justify-center">
          <div class="row justify-center">
            <div>
              <q-radio
                v-model="status"
                val="openWaiting"
                label="Open & Waiting"
                color="custom-green"
                size="lg"
                @input="statusChange"
              />
              <br />
              <q-radio
                v-model="status"
                val="estimate"
                label="Estimates"
                color="custom-red"
                size="lg"
                @input="statusChange"
              />
            </div>
          </div>
        </div>
      </q-card>

      <q-card flat bordered class="q-mt-md full-width">
        <div class="row items-center justify-center q-py-sm q-mr-sm">
          <q-icon
            name="local_shipping"
            color="primary"
            size="lg"
            class="q-ml-xs"
          />
          <span class="text-body1 text-primary q-ml-sm q-mr-xl">Haul Info</span>
        </div>
        <q-separator />
        <div class="row justify-center">
          <div class="row justify-center">
            <div>
              <q-radio
                v-model="balOrHauled"
                val="haul_pro"
                label="Hauled / Projected"
                color="custom-green"
                size="lg"
                @input="balOrHaulChange"
              />
              <br />
              <q-radio
                v-model="balOrHauled"
                val="bal_pro"
                label="Balance / Projected"
                color="custom-red"
                size="lg"
                @input="balOrHaulChange"
              />
            </div>
          </div>
        </div>
        <div class="text-center text-caption text-grey-8 q-pt-md">
          Toggles whether balance or hauled will be displayed in information
          cards. Blue indicators will always show balance/projected.
        </div>
      </q-card>

      <q-card flat bordered class="q-mt-md full-width">
        <div class="row items-center justify-center q-py-sm q-mr-sm">
          <q-icon name="add_chart" color="primary" size="lg" class="q-ml-xs" />
          <span class="text-body1 text-primary q-ml-sm q-mr-xl"
            >Extra Info</span
          >
          <q-checkbox
            v-model="extraInfo"
            label="None"
            color="custom-red"
            size="lg"
            @input="extraChange"
          />
        </div>
        <q-separator />

        <div class="column q-py-sm" :style="filterHeight">
          <div
            v-for="(input, index) in inputsSorted"
            :key="index"
            :class="$q.screen.xs ? '' : 'q-ml-md'"
          >
            <q-radio
              v-model="inputModel"
              :val="input.value"
              :label="truncateLabel(input.label)"
              color="custom-red"
              :size="$q.screen.xs ? 'xs' : 'sm'"
              @input="inputChange"
            />
          </div>
        </div>
      </q-card>

      <q-card flat bordered class="full-width q-mt-md q-mb-lg">
        <div class="row items-center justify-center q-py-sm q-ml-md">
          <q-icon name="sort" color="primary" size="lg" class="q-ml-xs" />
          <span class="text-body1 text-primary q-ml-sm q-mr-xl"> Sort </span>
          <q-checkbox
            v-model="reverseSort"
            label="Reverse Sort"
            color="custom-orange"
            size="lg"
          />
        </div>
        <q-separator />

        <div class="column q-py-sm" :style="sortHeight">
          <div
            v-for="(option, index) in sortOptions"
            :key="index + option"
            :class="$q.screen.xs ? '' : 'q-ml-md'"
          >
            <q-radio
              v-model="sortModel"
              :val="option.value"
              :label="truncateLabel(option.label)"
              color="custom-red"
              :size="$q.screen.xs ? 'xs' : 'sm'"
            />
          </div>
        </div>
      </q-card>
    </div>
  </div>
</template>

<script>
import { FilterLists, UserFilterLists } from '@/utils/FilterLists';

export default {
  name: 'DestinationFilterData',
  props: {
    destinationInputs: {
      type: Object,
      required: true
    },
    destinationSortBy: {
      type: Object,
      required: true
    },
    destinationStatusFilter: {
      type: String,
      required: false,
      default: 'openWaiting'
    },
    destinationInputSelected: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      extraInfo: true,
      inputs: {},
      inputsSorted: [],
      inputModel: '',
      loader: false,
      sortModel: null,
      sortOptions: [],
      reverseSort: false,
      status: 'openWaiting',
      balOrHauled: 'haul_pro'
    };
  },
  mounted() {
    this.setInputs();
    this.setSorting();
    this.setStatus();
    this.setBalOrHauled();
  },
  methods: {
    balOrHaulChange() {
      this.$store.dispatch('setBalOrHauled', {
        balOrHauled: this.balOrHauled,
        init: false
      });
    },
    extraChange() {
      if (this.extraInfo) {
        this.inputModel = '';
      }
    },
    inputChange() {
      this.extraInfo = false;
    },
    setBalOrHauled() {
      this.balOrHauled = this.$store.state.balOrHauled;
    },
    setInputs() {
      this.inputs = JSON.parse(JSON.stringify(this.destinationInputs));

      // Use this to determine if infoBox or customSelector
      // has been deleted - if so, clear out of filters
      const validFilters = [];

      for (let i = 0; i < this.settingsUsers.infoBoxes.length; i++) {
        const infoBox = this.settingsUsers.infoBoxes[i];
        validFilters.push(infoBox.name);
        if (!Object.prototype.hasOwnProperty.call(this.inputs, infoBox.name)) {
          if (this.isUser) {
            if (infoBox.userAuth) {
              this.inputs[infoBox.name] = {
                label: infoBox.name
              };
            }
          } else {
            this.inputs[infoBox.name] = {
              label: infoBox.name
            };
          }
        }
      }

      for (let i = 0; i < this.settingsUsers.customSelectors.length; i++) {
        const customSelector = this.settingsUsers.customSelectors[i];
        validFilters.push(customSelector.name);
        if (
          !Object.prototype.hasOwnProperty.call(
            this.inputs,
            customSelector.name
          )
        ) {
          if (this.isUser) {
            if (customSelector.userAuth) {
              this.inputs[customSelector.name] = {
                label: customSelector.name
              };
            }
          } else {
            this.inputs[customSelector.name] = {
              label: customSelector.name
            };
          }
        }
      }

      if (this.isUser) {
        for (const key in UserFilterLists.destinationInputs) {
          validFilters.push(UserFilterLists.destinationInputs[key].label);
        }
      } else {
        for (const key in FilterLists.destinationInputs) {
          validFilters.push(FilterLists.destinationInputs[key].label);
        }
      }

      // Intentionally passing by reference
      const inputsSorted = [];
      for (const key in this.inputs) {
        if (validFilters.includes(this.inputs[key].label)) {
          inputsSorted.push({ ...this.inputs[key], ...{ value: key } });
        }
      }

      const sortByAlpha = (a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      };

      inputsSorted.sort(sortByAlpha);

      this.inputsSorted = inputsSorted.slice();

      this.extraInfo = this.destinationInputSelected === '' ? true : false;
      this.inputModel = this.destinationInputSelected;
    },
    setSorting() {
      let sortOptions = [
        { label: 'Alphabetically', value: 'alpha&&&alpha' },
        { label: 'Scheduled', value: 'minServiceTs&&&scheduled' },
        { label: 'Dispatched', value: 'isDispatched&&&boolean' },
        { label: 'Hauled', value: 'hauled&&&number' },
        { label: 'Balance', value: 'balance&&&number' },
        { label: 'Projected', value: 'projected&&&number' },
        {
          label: 'Ready For Settlement',
          value: 'readyForSettlement&&&boolean'
        },
        { label: 'Start Date', value: 'startDate&&&number' },
        { label: 'Commodity', value: 'commodity&&&number' },
        { label: 'Price Per Unit', value: 'pricePerUnit&&&number' },
        { label: 'Mileage Rate', value: 'mileageRate&&&number' },
        { label: 'Allow Guest Users', value: 'allowGuestUsers&&&boolean' },
        { label: 'Services Complete', value: 'servicesComplete&&&boolean' }
      ];

      const userSortOptions = [
        { label: 'Alphabetically', value: 'alpha&&&alpha' },
        { label: 'Scheduled', value: 'minServiceTs&&&scheduled' },
        { label: 'Dispatched', value: 'isDispatched&&&boolean' },
        { label: 'Hauled', value: 'hauled&&&number' },
        { label: 'Balance', value: 'balance&&&number' },
        { label: 'Projected', value: 'projected&&&number' },
        { label: 'Start Date', value: 'startDate&&&number' },
        { label: 'Commodity', value: 'commodity&&&number' },
        { label: 'Services Complete', value: 'servicesComplete&&&boolean' }
      ];

      if (this.isUser) {
        sortOptions = [];
        sortOptions = userSortOptions;
      }

      for (let i = 0; i < this.settingsUsers.infoBoxes.length; i++) {
        const infoBox = this.settingsUsers.infoBoxes[i];

        if (this.isUser) {
          if (infoBox.userAuth) {
            sortOptions.push({
              label: infoBox.name,
              value: infoBox.name + '&&&infoBox',
              color: 'primary'
            });
          }
        } else {
          sortOptions.push({
            label: infoBox.name,
            value: infoBox.name + '&&&infoBox',
            color: 'primary'
          });
        }
      }

      for (let i = 0; i < this.settingsUsers.customSelectors.length; i++) {
        const customSelector = this.settingsUsers.customSelectors[i];
        for (let j = 0; j < customSelector.options.length; j++) {
          const option = customSelector.options[j].option;
          if (this.isUser) {
            if (customSelector.userAuth) {
              sortOptions.push({
                label: option,
                value: option + '&&&customSelector###' + customSelector.name,
                color: 'primary'
              });
            }
          } else {
            sortOptions.push({
              label: option,
              value: option + '&&&customSelector###' + customSelector.name,
              color: 'primary'
            });
          }
        }
      }

      this.sortOptions = sortOptions;
      this.sortModel = this.destinationSortBy.model;
      this.reverseSort = this.destinationSortBy.reverseSort;
    },
    setStatus() {
      this.status = this.destinationStatusFilter;
    },
    statusChange() {
      this.$emit('fetchEstimates', this.status);
      this.submitFilter();
    },
    submitFilter() {
      this.loader = true;
      const radioArr = this.sortModel.split('&&&');

      const sortBy = {
        type: radioArr[1],
        value: radioArr[0],
        model: this.sortModel,
        reverseSort: this.reverseSort
      };

      const dataObj = {
        type: 'destinationInputs',
        inputs: this.inputs,
        inputSelected: this.inputModel,
        status: this.status,
        sortBy
      };
      this.$store.dispatch('setUserData', dataObj);
      setTimeout(() => {
        this.$emit('onSubmit');
      }, 100);
    },
    truncateLabel(label) {
      let labelLength = 25;
      if (screen.width < 340) {
        labelLength = 12;
      } else if (screen.width < 360) {
        labelLength = 15;
      } else if (screen.width < 500) {
        labelLength = 20;
      }

      return label.length < labelLength + 1
        ? label
        : label.substring(0, labelLength) + '...';
    }
  },
  computed: {
    filterHeight() {
      const numFilters = this.inputsSorted.length;
      let maxHeight = 30;
      let numColumns = 4;
      let filterHeight = 35;

      if (screen.width < 600) {
        filterHeight = 30;
        numColumns = 2;
      } else if (screen.width < 800) {
        numColumns = 2;
      } else if (screen.width < 1400) {
        numColumns = 3;
      }

      let radiosPerCol = Math.ceil(numFilters / numColumns);
      maxHeight += radiosPerCol * filterHeight;
      return `max-height: ${maxHeight}px;`;
    },
    isUser() {
      return (
        this.$store.state.user.user ||
        this.$store.state.user.superUser ||
        this.$store.state.user.guestUser
      );
    },
    sortHeight() {
      const numRadios = this.sortOptions.length;
      let maxHeight = 30;
      let numColumns = 4;
      let sortHeight = 35;

      if (screen.width < 600) {
        sortHeight = 30;
        numColumns = 2;
      } else if (screen.width < 800) {
        numColumns = 2;
      } else if (screen.width < 1400) {
        numColumns = 3;
      }

      let radiosPerCol = Math.ceil(numRadios / numColumns);
      maxHeight += radiosPerCol * sortHeight;
      return `max-height: ${maxHeight}px;`;
    },
    settingsUsers() {
      return JSON.parse(
        JSON.stringify(this.$store.state.settingsUsers.destinationSettings)
      );
    }
  },
  watch: {
    destinationInputs() {
      this.setInputs();
    }
  }
};
</script>

<style scoped></style>
