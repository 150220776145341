<template>
  <div class="row justify-center full-width">
    <q-linear-progress v-if="waiting" query color="primary" />

    <div class="div-container">
      <ConfirmDialog
        v-if="confirmUpdate"
        icon="person"
        iconColor="primary"
        :btn="btn"
        :message="confirmMessage"
        :headerMessage="confirmHeaderMessage"
        @canceled="confirmUpdate = false"
        @confirmed="updateUserSettings"
      />

      <div class="q-pt-xl"></div>
      <div class="gt-xs q-mt-xl"></div>
      <div
        class="row items-center justify-center rounded-borders text-custom-light q-ma-sm q-mb-md"
        style="background: radial-gradient(circle, #3336eb 0%, #0b0d6f 100%)"
      >
        <q-avatar icon="account_circle" font-size="35px" />
        <span class="text-body1 text-bold q-ml-xs">
          {{ user.displayName }}
        </span>
      </div>

      <q-form @submit="update">
        <q-card flat bordered class="q-mx-sm q-mb-md">
          <q-list bordered class="full-width">
            <q-item class="q-my-sm">
              <q-item-section avatar>
                <q-avatar color="custom-orange" text-color="white" size="md">
                  {{ user.displayName.charAt(0) }}
                </q-avatar>
              </q-item-section>
              <q-item-section>
                <q-item-label>Current Display Name</q-item-label>
                <q-item-label caption lines="1">{{
                  user.displayName
                }}</q-item-label>
              </q-item-section>
            </q-item>
            <q-separator />

            <q-item v-if="!guestCustomer && !guestUser" class="q-my-sm">
              <q-item-section>
                <q-input
                  filled
                  v-model="displayName"
                  label="Edit Display Name"
                  label-color="primary"
                  color="custom-orange"
                  maxlength="80"
                  hide-bottom-space
                  lazy-rules
                  :rules="[
                    val =>
                      (val && val.trim().length > 0) || 'Please enter a name'
                  ]"
                />
              </q-item-section>
            </q-item>
            <q-separator v-if="!guestCustomer && !guestUser" />

            <q-item class="q-my-sm" clickable v-ripple>
              <q-item-section avatar>
                <q-icon
                  color="custom-orange"
                  name="email"
                  text-color="white"
                  size="md"
                />
              </q-item-section>
              <q-item-section>
                <q-item-label>Current Email</q-item-label>
                <q-item-label caption lines="1">{{ user.email }}</q-item-label>
              </q-item-section>
            </q-item>
            <q-separator />

            <q-item class="q-my-sm">
              <q-item-section>
                <q-input
                  filled
                  v-model="email"
                  type="email"
                  label="Edit Email"
                  label-color="primary"
                  color="custom-orange"
                  hide-bottom-space
                  lazy-rules
                  :rules="[isValidEmail]"
                />
              </q-item-section>
            </q-item>
          </q-list>
        </q-card>

        <div align="right" class="lt-sm q-mr-sm">
          <q-btn
            flat
            label="Reset Password"
            color="primary"
            size="md"
            padding="xs md"
            @click="passwordReset"
          />
          <q-btn
            color="primary"
            size="md"
            padding="xs md"
            label="Update"
            class="q-ml-xs"
            type="submit"
          />
        </div>
        <div v-if="displayBtns" align="right" class="gt-xs q-mr-sm">
          <q-btn
            flat
            label="Reset Password"
            color="primary"
            padding="xs md"
            @click="passwordReset"
          />
          <q-btn
            color="primary"
            label="Update"
            class="q-ml-md"
            padding="xs md"
            type="submit"
          />
        </div>
      </q-form>
    </div>
  </div>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog.vue';

export default {
  name: 'LoggedIn',
  components: {
    ConfirmDialog
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      btn: {
        label: 'Update',
        icon: 'check',
        color: 'primary'
      },
      confirmMessage: 'Are you sure you want to update user?',
      confirmHeaderMessage: this.user.displayName,
      confirmUpdate: false,
      displayBtns: true,
      displayName: '',
      email: '',
      waiting: false
    };
  },
  mounted() {
    const user = JSON.parse(JSON.stringify(this.user));
    this.displayName = user.displayName;
    this.email = user.email;
  },
  methods: {
    isValidEmail(val) {
      const emailPattern = /\S+@\S+\.\S+/;
      return emailPattern.test(val) || 'Invalid email';
    },
    passwordReset() {
      this.waiting = true;
      this.displayBtns = false;
      this.$emit('onPasswordReset', this.email);
    },
    update() {
      this.confirmUpdate = true;
    },
    updateUserSettings() {
      this.waiting = true;
      this.$emit('onAccountSettingsUpdate', this.email, this.displayName);
    }
  },
  computed: {
    guestCustomer() {
      return (
        this.$store.state.user.superCustomer || this.$store.state.user.customer
      );
    },
    guestUser() {
      return this.$store.state.user.guestUser;
    }
  }
};
</script>

<style scoped>
/* some global css is applied */
</style>
