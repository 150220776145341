<template>
  <div class="div-container q-mt-xl">
    <div class="gt-xs q-mt-xl"></div>
    <div
      class="row items-center justify-center rounded-borders text-custom-light q-ma-sm q-mb-md"
      style="background: radial-gradient(circle, #3336eb 0%, #0b0d6f 100%)"
    >
      <q-avatar icon="account_circle" font-size="35px" />
      <span class="text-body1 text-bold q-ml-xs">
        {{ user.displayName }}
      </span>
    </div>

    <q-card
      v-if="user.displayName.length"
      flat
      bordered
      class="q-ma-sm q-mb-md"
    >
      <q-list separator>
        <q-item class="q-pa-md">
          <q-item-section avatar>
            <q-avatar color="primary" text-color="white" size="md">
              {{ user.displayName.charAt(0) }}
            </q-avatar>
          </q-item-section>
          <q-item-section>
            <q-item-label>Display Name</q-item-label>
            <q-item-label caption lines="1">{{
              user.displayName
            }}</q-item-label>
          </q-item-section>
        </q-item>
        <q-separator />

        <q-item class="q-my-sm">
          <q-item-section avatar>
            <q-icon color="primary" name="email" text-color="white" size="md" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Email</q-item-label>
            <q-item-label caption lines="1">{{ user.email }}</q-item-label>
          </q-item-section>
        </q-item>
        <q-separator />

        <q-item class="q-my-sm">
          <q-item-section avatar>
            <q-icon
              color="primary"
              name="how_to_reg"
              text-color="white"
              size="md"
            />
          </q-item-section>
          <q-item-section>
            <q-item-label>Currently Logged In</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-card>

    <div align="right" class="q-mt-md q-mr-sm">
      <q-btn
        color="primary"
        label="Logout"
        icon="logout"
        padding="xs md"
        @click="logout"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoggedIn',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      confirmDelete: false,
      displayName: ''
    };
  },
  methods: {
    accountSettings() {
      this.$emit('onAccountSettings');
    },
    logout() {
      this.$emit('onLogout');
    }
  }
};
</script>

<style scoped>
/* some global css is applied */

@media all and (max-width: 370px) {
  .card-padding {
    padding: 0px;
  }

  .div-container {
    width: 95%;
  }
}
</style>
