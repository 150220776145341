<template>
  <div class="flex flex-center">
    <CustomerList
      v-if="currentDisplay === 'add'"
      :customers="customerList"
      @onSelectCustomer="onSelectCustomer"
    />

    <EditDestination
      v-if="currentDisplay === 'editDestination'"
      ref="editDestination"
      :destination="destination"
      :settings="settings"
      @onSubmit="setAction('list')"
      @onAddSubmit="addDest"
      @layerTwoLocations="setAction('layerTwoLocations')"
      @layerTwoAttachments="setAction('layerTwoAttachments')"
      @layerThreeAttachments="setAction('layerThreeAttachments')"
      @setLocation="setAction('editDestination')"
      @fetchEstimates="fetchEstimates"
    />

    <FilterData
      v-if="currentDisplay === 'filter'"
      ref="filter"
      :destinationInputs="destinationInputs"
      :destinationSortBy="destinationSortBy"
      :destinationStatusFilter="destinationStatusFilter"
      :destinationInputSelected="destinationInputSelected"
      @onSubmit="filterSubmitted"
      @fetchEstimates="fetchEstimates"
    />

    <DestinationSettings
      v-if="currentDisplay === 'settings'"
      ref="settings"
      :settings="settings"
      @onManageProgrammables="onManageProgrammables"
      @onSubmit="setAction('list')"
    />

    <DestinationTable
      v-show="currentDisplay === 'list'"
      ref="destinationTable"
      key="234234destinationTable"
      :destinationInputs="destinationInputs"
      :destinationSortBy="destinationSortBy"
      :destinationStatusFilter="destinationStatusFilter"
      :destinationInputSelected="destinationInputSelected"
      :settings="settings"
      :estimates="estimates"
      :loader="loader"
      @onEdit="onEdit"
    />
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
const db = firebase.firestore();

import CustomerList from '@/components/CustomerList.vue';
import EditDestination from '@/components/destinations/EditDestination.vue';
import DestinationSettings from '@/components/destinations/DestinationSettings.vue';
import DestinationTable from '@/components/destinations/DestinationTable.vue';
import FilterData from '@/components/destinations/FilterData.vue';

export default {
  name: 'Destinations-Main',
  components: {
    CustomerList,
    EditDestination,
    FilterData,
    DestinationSettings,
    DestinationTable
  },
  data() {
    return {
      addMode: false,
      customer: '',
      currentDisplay: 'list',
      customerList: [],
      destination: {},
      estimates: {
        estimates: [],
        userEstimates: []
      },
      loader: false,
      snapshotListener: () => {}
    };
  },
  created() {
    this.$store.dispatch('setHeaderBtnsDisplay', true);

    this.setFilter();
    this.setCustomers();
    this.setAction('list');
  },
  methods: {
    addDest(dest) {
      this.$refs.destinationTable.addId(dest.id);
      this.setAction('list');
    },
    cancelLayerThreeAttachment() {
      this.$refs.editDestination.cancelLayerThreeAttachment();
      this.setAction('layerTwoLocations');
    },
    cancelLayerTwoInputs() {
      this.$refs.editDestination.cancelLayerTwoInputs();

      if (!this.destination.hauls) {
        this.setAction('editDestination');
        return;
      }

      if (this.destination.hauls.length) {
        this.setAction('editDestination');
      } else {
        this.setAction('editDestinationDelete');
      }
    },
    cancelProgrammables() {
      this.$refs.settings.cancelProgrammables();
      this.setAction('settings');
    },
    deleteDest() {
      this.$refs.destinationTable.setUpdaterAction('delete');
      this.$refs.editDestination.confirmAction('delete');
    },
    fetchEstimates(status) {
      if (!this.estimates.estimates.length && status === 'estimate') {
        const wrapperFunc = async () => {
          this.loader = true;
          const estimates = [];
          const getEstimates = () => {
            const destEstQuery = db
              .collection('destinations_active')
              .where('destinationStatus', 'in', ['Later', 'Estimate']);

            const destEst = destEstQuery.get().then(querySnapshot => {
              querySnapshot.forEach(doc => {
                estimates.push(doc.data());
              });
              return true;
            });
            return destEst;
          };

          const promiseOne = getEstimates();

          const userEstimates = [];
          const getUserEstimates = () => {
            const destEstQueryUsers = db
              .collection('destinations_active_users')
              .where('destinationStatus', 'in', ['Later', 'Estimate']);

            const destEstUsers = destEstQueryUsers.get().then(querySnapshot => {
              querySnapshot.forEach(doc => {
                userEstimates.push(doc.data());
              });
              return true;
            });
            return destEstUsers;
          };

          const promiseTwo = getUserEstimates();

          await promiseOne;
          await promiseTwo;

          this.estimates = {
            estimates,
            userEstimates
          };
          this.loader = false;
        };

        this.snapshotListener = db
          .collection('destinations_active')
          .where('destinationStatus', 'in', ['Later', 'Estimate'])
          .onSnapshot(() => {
            wrapperFunc();
          });
      }

      const dataObj = {
        type: 'destinationInputs',
        inputs: this.destinationInputs,
        sortBy: this.destinationSortBy,
        inputSelected: this.destinationInputSelected,
        status
      };

      this.$store.dispatch('setUserData', dataObj);
    },
    filterSubmitted() {
      this.$refs.destinationTable.setDestinations();
      this.setAction('list');
    },
    onEdit(destination) {
      destination.action = 'edit';
      this.destination = destination;

      if (destination.hauls.length) {
        this.setAction('editDestination');
      } else {
        this.setAction('editDestinationDelete');
      }
    },
    onManageProgrammables() {
      this.setAction('programmables');
    },
    onSelectCustomer(customer) {
      this.destination = {};
      this.destination.customer = customer.name;
      this.destination.customerId = customer.id;
      this.destination.customerNote = customer.note;
      this.destination.action = 'add';

      this.setAction('addDestination');
    },
    setAction(action) {
      if (action === 'list') {
        this.addMode = false;

        this.currentDisplay = 'list';

        const headerBtns = [
          {
            action: this.setAction,
            args: 'add',
            label: 'Add',
            color: 'primary',
            flat: true
          },
          {
            action: this.setAction,
            args: 'settings',
            label: 'Settings',
            color: 'primary',
            flat: true
          },
          {
            action: this.setAction,
            args: 'filter',
            label: 'Filter',
            color: 'primary',
            flat: false
          }
        ];

        this.$store.dispatch('setHeaderBtns', headerBtns);
      } else if (action === 'editDestination') {
        this.currentDisplay = 'editDestination';
        this.$nextTick(() => {
          const headerBtns = [
            {
              action: this.$refs.editDestination.cancel,
              args: '',
              label: 'Cancel',
              color: 'primary',
              flat: true
            }
          ];

          if (!this.addMode) {
            headerBtns.push({
              action: this.$refs.editDestination.completed,
              args: '',
              label: 'Completed',
              color: 'teal-9',
              flat: true
            });
          }

          headerBtns.push({
            action: this.$refs.editDestination.onVerifyFirst,
            args: '',
            label: 'Submit',
            color: 'primary',
            flat: false
          });

          this.$store.dispatch('setHeaderBtns', headerBtns);
        });
      } else if (action === 'addDestination') {
        this.addMode = true;
        this.setAction('editDestination');
      } else if (action === 'editDestinationDelete') {
        this.addMode = false;
        this.currentDisplay = 'editDestination';
        this.$nextTick(() => {
          const headerBtns = [
            {
              action: this.$refs.editDestination.cancel,
              args: '',
              label: 'Cancel',
              color: 'primary',
              flat: true
            },
            {
              action: this.deleteDest,
              args: '',
              label: 'Delete',
              color: 'custom-red',
              flat: true
            },
            {
              action: this.$refs.editDestination.onVerifyFirst,
              args: '',
              label: 'Submit',
              color: 'primary',
              flat: false
            }
          ];

          this.$store.dispatch('setHeaderBtns', headerBtns);
        });
      } else if (action === 'add') {
        this.destination.action = 'add';
        this.$refs.destinationTable.setUpdaterAction('add');
        this.addMode = true;

        const headerBtns = [
          {
            action: this.setAction,
            args: 'list',
            label: 'Cancel',
            color: 'primary',
            flat: false
          }
        ];

        this.$store.dispatch('setHeaderBtns', headerBtns);
        this.currentDisplay = 'add';
      } else if (action === 'filter') {
        this.currentDisplay = 'filter';
        this.$nextTick(() => {
          const headerBtns = [
            {
              action: this.setAction,
              args: 'list',
              label: 'Cancel',
              color: 'primary',
              flat: true
            },
            {
              action: this.$refs.filter.submitFilter,
              args: '',
              label: 'Sort & Filter',
              color: 'primary',
              flat: false
            }
          ];

          this.$store.dispatch('setHeaderBtns', headerBtns);
        });
      } else if (action === 'settings') {
        this.currentDisplay = 'settings';
        this.$nextTick(() => {
          const headerBtns = [
            {
              action: this.setAction,
              args: 'list',
              label: 'Cancel',
              color: 'primary',
              flat: true
            },
            {
              action: this.$refs.settings.editSettings,
              args: '',
              label: 'Submit',
              color: 'primary',
              flat: false
            }
          ];

          this.$store.dispatch('setHeaderBtns', headerBtns);
        });
      } else if (action === 'programmables') {
        const headerBtns = [
          {
            action: this.cancelProgrammables,
            args: '',
            label: 'Done',
            color: 'primary',
            flat: false
          }
        ];

        this.$store.dispatch('setHeaderBtns', headerBtns);
      } else if (action === 'layerTwoLocations') {
        this.$nextTick(() => {
          const headerBtns = [
            {
              action: this.cancelLayerTwoInputs,
              args: '',
              label: 'Cancel',
              color: 'primary',
              flat: false
            }
          ];

          this.$store.dispatch('setHeaderBtns', headerBtns);
        });
      } else if (action === 'layerTwoAttachments') {
        const headerBtns = [
          {
            action: this.cancelLayerTwoInputs,
            args: '',
            label: 'Done',
            color: 'primary',
            flat: false
          }
        ];

        this.$store.dispatch('setHeaderBtns', headerBtns);
      } else if (action === 'layerThreeAttachments') {
        const headerBtns = [
          {
            action: this.cancelLayerThreeAttachment,
            args: '',
            label: 'Done',
            color: 'primary',
            flat: false
          }
        ];

        this.$store.dispatch('setHeaderBtns', headerBtns);
      }
    },
    setCustomers() {
      const customers = JSON.parse(JSON.stringify(this.$store.state.customers));
      const colors = JSON.parse(JSON.stringify(this.$store.state.colors));

      this.customerList = [];
      const sortByCustomer = (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      };

      for (const custKey in customers) {
        const customer = customers[custKey];
        customer.id = custKey;

        if (customer.isActive) {
          this.customerList.push(customer);
        }
      }

      this.customerList.sort(sortByCustomer);

      let colorIndex = 0;
      for (let i = 0; i < this.customerList.length; i++) {
        this.customerList[i].color = colors[colorIndex];

        colorIndex++;
        if (colorIndex === 7) {
          colorIndex = 0;
        }
      }
    },
    setFilter() {
      // Should not be set to estimates on mount
      if (this.destinationStatusFilter !== 'openWaiting') {
        const dataObj = {
          type: 'destinationInputs',
          inputs: this.destinationInputs,
          sortBy: this.destinationSortBy,
          inputSelected: this.destinationInputSelected,
          status: 'openWaiting'
        };

        this.$store.dispatch('setUserData', dataObj);
      }
    }
  },
  computed: {
    destinationInputs() {
      return this.$store.state.filterLists.destinationInputs;
    },
    destinationInputSelected() {
      return this.$store.state.filterLists.destinationInputSelected;
    },
    destinationSortBy() {
      return this.$store.state.filterLists.destinationSortBy;
    },
    destinationStatusFilter() {
      return this.$store.state.filterLists.destinationStatusFilter;
    },
    settings() {
      return this.$store.state.destinationSettings;
    }
  },
  watch: {
    '$store.state.customers'() {
      this.setCustomers();
    },
    currentDisplay(newVal, oldVal) {
      if (newVal === 'list') {
        if (oldVal === 'editDestination') {
          this.$refs.destinationTable.highlightDest();
        }
      }
    }
  },
  beforeDestroy() {
    this.snapshotListener();
  }
};
</script>

<style scoped></style>
