import { render, staticRenderFns } from "./App.vue?vue&type=template&id=041c9c13&scoped=true&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "./App.vue?vue&type=style&index=0&id=041c9c13&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "041c9c13",
  null
  
)

export default component.exports
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QBar from 'quasar/src/components/bar/QBar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QList from 'quasar/src/components/item/QList.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QPage from 'quasar/src/components/page/QPage.js';
import qInstall from "../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QLayout,QHeader,QBar,QBtn,QSpace,QIcon,QToolbar,QDrawer,QItem,QItemSection,QSkeleton,QItemLabel,QList,QPageContainer,QLinearProgress,QPage});
