<template>
  <div>
    <div id="name" class="left">
      {{ user.name }}
    </div>
    <div class="full-width">
      <div v-for="week in weeks" :key="week.start">
        <table id="table" style="width: 100%" class="top">
          <tr class="top">
            <th colspan="3" class="left top">
              {{ week.startDate }} - {{ week.endDate }}
            </th>
            <th colspan="2" class="right top">Total: {{ week.total }}</th>
          </tr>
          <tr
            v-for="(timeChunk, index) in week.timeChunks"
            :key="timeChunk.day + index"
            class="q-mb-md"
          >
            <td class="left" style="width: 270px">{{ timeChunk.day }}</td>
            <td style="width: 200px">
              {{ timeChunk.clockInTime }} - {{ timeChunk.clockOutTime }}
            </td>
            <td style="width: 150px">{{ timeChunk.break }}</td>
            <td style="width: 150px">Time {{ timeChunk.diff }}</td>
            <td class="right" style="width: 150px">
              {{ timeChunk.workType }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrintTime',
  props: {
    weeks: {
      type: Array,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>
#name {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
}

#table {
  margin-bottom: 30px;
}

.right {
  text-align: right;
  padding-right: 10px;
}

.left {
  text-align: left;
  padding-left: 10px;
}

.top {
  border-top: none;
}

td .wider {
  width: 300px;
}

th {
  text-align: left;
  padding-top: 5px;
  padding-bottom: 5px;
}

table {
  table-layout: fixed;
}

table,
th,
td {
  border-bottom: 1px solid silver;
  border-top: 1px solid silver;
  border-collapse: collapse;
}
</style>
