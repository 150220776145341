<template>
  <div class="home">
    <HomePage />
  </div>
</template>

<script>
// @ is an alias to /src
import HomePage from '@/components/HomePage.vue';

export default {
  name: 'Home-Main',
  components: {
    HomePage
  },
  created() {
    this.$store.dispatch('setHeaderBtnsDisplay', false);
  }
};
</script>
