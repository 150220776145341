<template>
  <q-dialog persistent v-model="displayCommodities">
    <q-card>
      <div
        class="row bg-grey-3"
        :style="$q.screen.xs ? 'min-width: 280px;' : 'min-width: 350px;'"
      >
        <div class="row items-center">
          <q-icon name="grain" size="md" color="primary" class="q-ml-md" />
          <div
            class="q-py-md q-ml-sm text-black"
            :class="$q.screen.xs ? 'text-subtitle1' : 'text-h6'"
          >
            Select Commodity
          </div>
        </div>
        <q-space />
        <div class="row items-center q-mr-xs">
          <q-btn
            square
            unelevated
            color="grey-4"
            text-color="black"
            icon="clear"
            size="md"
            class="q-mr-sm q-ml-md"
            padding="sm"
            @click="cancel"
          />
        </div>
      </div>
      <q-card-section>
        <div
          v-if="noCommodityErr"
          class="row justify-center text-subtitle1 text-custom-red q-ma-sm"
        >
          Please Select A Commodity
        </div>
        <div class="row justify-center">
          <q-option-group
            v-model="commoditySelectedArr"
            type="checkbox"
            color="primary"
            size="lg"
            :options="commoditiesArr"
          />
        </div>
      </q-card-section>
      <q-card-section class="q-pa-none">
        <div class="row justify-end q-mr-md q-mb-lg">
          <q-btn
            color="primary"
            label="apply"
            icon="check"
            @click="applySelected"
          />
        </div>
        <div class="text-caption text-center text-custom-medium q-ma-md">
          Please Note: Selecting commodities with mismatching measurement units
          will result in skewed reports.
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'WorkTypeSettings',
  props: {
    commodities: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      commoditiesArr: [],
      commoditySelectedArr: [],
      displayCommodities: true,
      noCommodityErr: false
    };
  },
  mounted() {
    this.setCommoditiesArr();
    this.setCommodity();
  },
  methods: {
    applySelected() {
      if (!this.commoditySelectedArr.length) {
        this.noCommodityErr = true;
        return;
      }

      this.noCommodityErr = false;
      this.$emit('onSelectCommodity', this.commoditySelectedArr);
    },
    cancel() {
      this.$emit('onCancel');
    },
    setCommodity() {
      if (localStorage.commoditySelectedArr) {
        this.commoditySelectedArr = JSON.parse(
          localStorage.commoditySelectedArr
        );
      }
    },
    setCommoditiesArr() {
      this.commoditiesArr = [];

      for (const key in this.commodities) {
        this.commoditiesArr.push({
          label: this.commodities[key].name,
          value: this.commodities[key].name
        });
      }
    }
  }
};
</script>

<style scoped></style>
