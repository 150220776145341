import { render, staticRenderFns } from "./CustomerList.vue?vue&type=template&id=9b1fcdd4&scoped=true&"
import script from "./CustomerList.vue?vue&type=script&lang=js&"
export * from "./CustomerList.vue?vue&type=script&lang=js&"
import style0 from "./CustomerList.vue?vue&type=style&index=0&id=9b1fcdd4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b1fcdd4",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QVirtualScroll from 'quasar/src/components/virtual-scroll/QVirtualScroll.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QSpace,QInput,QIcon,QVirtualScroll,QItem,QItemSection,QAvatar});qInstall(component, 'directives', {Ripple});
