<template>
  <div class="row justify-center full-width">
    <ConfirmDialog
      v-if="confirmDelete"
      icon="location_on"
      iconStyle=""
      iconColor="custom-green"
      :btn="btn"
      :message="confirmMessage"
      :headerMessage="confirmHeaderMessage"
      @canceled="confirmDelete = false"
      @confirmed="deleteLocation"
    />

    <OkDialog
      v-if="okDialog"
      icon="location_on"
      iconColor="custom-red"
      iconStyle=""
      :message="okMessage"
      @okConfirmed="okDialog = false"
    />

    <ManageAttachments v-if="displayManageAttachments" :location="location" />

    <q-dialog persistent v-model="displayEdit">
      <q-card
        class="q-pa-lg"
        :style="$q.screen.xs ? 'width: 320px' : 'width: 400px'"
      >
        <div class="row full-width">
          <q-card
            flat
            class="row justify-center items-center full-width bg-grey-3 q-pa-sm"
          >
            <q-icon name="edit" color="custom-orange" size="md" />
            <div class="text-bold text-orange q-ml-sm">Edit Location</div>
          </q-card>
        </div>

        <q-form v-if="displayEdit" @submit="onSubmitEdit">
          <div class="q-my-lg">
            <q-input
              v-if="displayEdit"
              v-model="locationForEdit"
              outlined
              label="Edit Location Name"
              label-color="primary"
              color="primary"
              lazy-rules
              hide-bottom-space
              maxlength="25"
              :rules="[
                val => (val && val.trim().length > 0) || 'Please enter a name'
              ]"
            >
            </q-input>
          </div>
          <div align="right">
            <q-btn
              flat
              label="Cancel"
              icon="clear"
              padding="xs md"
              color="primary"
              class="q-mr-sm"
              @click="displayEdit = false"
            />
            <q-btn
              unelevated
              label="Update"
              icon="check"
              type="submit"
              padding="xs md"
              color="primary"
            />
          </div>
        </q-form>
      </q-card>
    </q-dialog>

    <div v-if="!displayManageAttachments" class="div-container-2">
      <div
        class="row justify-center items-center full-width rounded-borders text-bold text-body1 text-custom-light q-py-xs q-mb-lg q-mt-xl"
        style="background: radial-gradient(circle, #3336eb 0%, #0b0d6f 100%)"
      >
        <q-icon name="account_circle" size="sm" />
        <span class="text-body1 q-ml-sm">
          {{ customers[customerId].name }}
        </span>
      </div>

      <div v-if="editView" separator class="q-mt-lg">
        <div class="text-primary text-center" style="font-size: 27px">
          Add Location
        </div>
      </div>

      <q-card flat bordered class="q-pa-md q-mb-xs">
        <div class="row items-center">
          <q-input
            dense
            v-model="newLocation"
            label="New Location"
            color="primary"
            class="full-width"
          >
            <template v-slot:append>
              <q-btn
                round
                dense
                icon="add"
                color="custom-red"
                @click="addField"
              />
            </template>
          </q-input>
          <div v-if="addBlankErr" class="text-custom-red">
            Please enter a name!
          </div>
        </div>
      </q-card>

      <div v-if="editView && sourceLocations.length" separator class="q-mt-lg">
        <div class="text-primary text-center" style="font-size: 27px">
          Select Location
        </div>
      </div>

      <q-card v-if="sourceLocations.length" flat bordered class="q-mb-xl">
        <q-list separator>
          <q-item
            class="q-ma-none q-py-none q-px-md"
            v-for="(location, index) in sourceLocations"
            :key="index"
          >
            <q-item-section avatar>
              <q-btn
                v-if="location.isActive"
                label="Select"
                size="sm"
                padding="xs sm"
                color="custom-light"
              />
              <q-btn
                v-if="!location.isActive"
                label="Select"
                size="sm"
                padding="xs sm"
                color="primary"
                @click="selectSource(index)"
              />
            </q-item-section>
            <q-item-section>
              {{ location.sourceName }}
            </q-item-section>
            <q-item-section side>
              <div class="q-my-sm">
                <q-btn
                  outline
                  size="sm"
                  color="primary"
                  icon="place"
                  padding="xs"
                  class="q-mr-sm"
                  @click="managePin(index)"
                />
                <q-btn
                  outline
                  size="sm"
                  color="primary"
                  icon="image"
                  padding="xs"
                  @click="manageAttachments(index)"
                />
              </div>
              <div class="q-mb-sm">
                <q-btn
                  outline
                  size="sm"
                  padding="xs"
                  color="custom-orange"
                  icon="edit"
                  class="q-mr-sm"
                  @click="editLocation(index)"
                />
                <q-btn
                  v-if="!location.isActive"
                  outline
                  size="sm"
                  padding="xs"
                  color="custom-red"
                  icon="delete"
                  @click="deleteDialog(index)"
                />
                <q-btn
                  v-if="location.isActive"
                  outline
                  size="sm"
                  padding="xs"
                  color="custom-light"
                  icon="delete"
                />
              </div>
            </q-item-section>
          </q-item>
        </q-list>
      </q-card>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import OkDialog from '@/components/OkDialog.vue';
import ManageAttachments from '@/components/sources/ManageAttachments.vue';

const db = firebase.firestore();

export default {
  name: 'SourceLocations',
  components: {
    ConfirmDialog,
    OkDialog,
    ManageAttachments
  },
  props: {
    customerId: {
      type: String,
      required: true
    },
    sourceLocations: {
      type: Array,
      required: true
    },
    editView: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      addBlankErr: false,
      btn: {
        label: 'Delete',
        icon: 'delete',
        color: 'custom-red'
      },
      confirmDelete: false,
      confirmMessage: '',
      confirmHeaderMessage: '',
      displayEdit: false,
      displayManageAttachments: false,
      edit: false,
      location: {},
      locationForEdit: '',
      newLocation: '',
      okDialog: false,
      okMessage: '',
      selectedLocationIndex: null
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    addField() {
      let locationName = this.newLocation;
      locationName = locationName.trim();
      locationName = locationName.replaceAll('.', '');
      locationName = locationName.replace(/(^\w{1})|(\s+\w{1})/g, letter =>
        letter.toUpperCase()
      );

      if (locationName === '') {
        this.addBlankErr = true;
        return;
      }

      this.edit = false;
      this.addBlankErr = false;
      this.onActivateSource(locationName);
      this.newLocation = '';
    },
    cancelLayerThreeAttachment() {
      this.displayManageAttachments = false;
    },
    deleteDialog(index) {
      this.confirmDelete = true;
      this.confirmMessage = 'Are you sure you want to delete location?';
      this.confirmHeaderMessage = this.sourceLocations[index].sourceName;
      this.selectedLocationIndex = index;
    },
    deleteLocation() {
      this.confirmDelete = false;

      const location = this.sourceLocations[this.selectedLocationIndex];
      const attachmentId = location.attachmentId;

      const masterSplitSources = JSON.parse(
        JSON.stringify(this.$store.state.masterSplitSources)
      );

      let docRef;
      for (const key in masterSplitSources) {
        if (
          Object.prototype.hasOwnProperty.call(
            masterSplitSources[key],
            location.id
          )
        ) {
          docRef = key;
        }
      }

      const batch = db.batch();

      const masterSourceListRef = db.collection('master_locations').doc(docRef);

      if (attachmentId) {
        const attachmentRef = db
          .collection('master_attachments')
          .doc(attachmentId);
        batch.delete(attachmentRef);
      }

      batch.update(masterSourceListRef, {
        [location.id]: firebase.firestore.FieldValue.delete()
      });

      batch.commit();
      this.notify('Location Deleted', 'custom-red');
    },
    editLocation(index) {
      this.locationForEdit = this.sourceLocations[index].sourceName;
      this.selectedLocationIndex = index;
      this.displayEdit = true;
      this.edit = true;
    },
    manageAttachments(index) {
      this.location = this.sourceLocations[index];
      this.displayManageAttachments = true;
      this.$emit('layerThreeAttachments');
    },
    managePin(index) {
      const id = this.sourceLocations[index].id;

      const mapPin = {
        fromSourceLocations: true,
        type: 'source',
        id: id
      };

      this.$emit('managePin', mapPin);
    },
    notify(message, color) {
      this.$q.notify({
        color: color,
        textColor: 'white',
        icon: 'place',
        message: message
      });
    },
    async onActivateSource(locationName) {
      let sourceExists = false;

      for (let i = 0; i < this.sourceLocations.length; i++) {
        if (this.sourceLocations[i].sourceName === locationName) {
          sourceExists = true;
          this.okMessage = locationName + ' already exists!';
          this.okDialog = true;
          break;
        }
      }

      const masterSources = JSON.parse(
        JSON.stringify(this.$store.state.masterSources)
      );
      const masterSplitSources = JSON.parse(
        JSON.stringify(this.$store.state.masterSplitSources)
      );

      if (!sourceExists) {
        let docRef;
        if (!this.edit) {
          const numSources = Object.keys(masterSources).length;
          const numDocs = Math.floor(numSources / 1000);
          docRef = 'master_sources_' + numDocs;
        } else {
          const index = this.selectedLocationIndex;
          const location = this.sourceLocations[index];
          for (const key in masterSplitSources) {
            if (
              Object.prototype.hasOwnProperty.call(
                masterSplitSources[key],
                location.id
              )
            ) {
              docRef = key;
            }
          }
        }

        let docExists = true;
        const userDocRef = await db.collection('master_locations').doc(docRef);
        const doc = await userDocRef.get();
        if (!doc.exists) {
          docExists = false;
        }

        const batch = db.batch();

        const masterSourceListRef = db
          .collection('master_locations')
          .doc(docRef);

        let masterSourceRef;
        let masterSourceListObj;
        if (!this.edit) {
          masterSourceRef = db.collection('master_locations').doc();
          masterSourceListObj = {
            [masterSourceRef.id]: {
              customerId: this.customerId,
              sourceName: locationName,
              attachmentId: null,
              lat: null,
              lng: null
            }
          };
        } else {
          const index = this.selectedLocationIndex;
          const location = this.sourceLocations[index];

          masterSourceListObj = {
            [location.id]: {
              customerId: this.customerId,
              sourceName: locationName,
              attachmentId: location.attachmentId,
              lat: location.lat,
              lng: location.lng
            }
          };
        }

        if (docExists) {
          batch.update(masterSourceListRef, masterSourceListObj);
        } else {
          batch.set(masterSourceListRef, masterSourceListObj);
        }

        batch.commit().catch(err => {
          console.error(err);
        });

        if (this.edit) {
          this.notify('Location Updated', 'primary');
          this.displayEdit = false;
        } else {
          this.notify('Location Added', 'primary');
        }
      }
    },
    onSubmitEdit() {
      let locationName = this.locationForEdit;
      locationName = locationName.trim();
      locationName = locationName.replaceAll('.', '');
      locationName = locationName.replace(/(^\w{1})|(\s+\w{1})/g, letter =>
        letter.toUpperCase()
      );

      this.onActivateSource(locationName);
    },
    selectSource(index) {
      this.$emit('onSelectLocation', index);
    }
  },
  computed: {
    customers() {
      return JSON.parse(JSON.stringify(this.$store.state.customers));
    }
  }
};
</script>

<style scoped></style>
