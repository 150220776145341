<template>
  <div class="div-container q-mt-xl">
    <div class="gt-sm q-py-xl"></div>
    <ConfirmDialog
      v-if="confirmDelete"
      icon="warning"
      iconColor="custom-red"
      :btn="btn"
      :message="confirmMessage"
      :headerMessage="user.displayName"
      @canceled="confirmDelete = false"
      @confirmed="onDelete"
    />

    <q-card
      flat
      bordered
      class="row items-center justify-center text-custom-orange q-ma-sm q-mb-md"
    >
      <q-avatar icon="account_circle" font-size="35px" />
      <span class="text-body1 text-bold q-ml-xs">
        {{ user.displayName }}
      </span>
    </q-card>

    <q-form @submit="onSubmit">
      <q-card flat bordered class="q-mx-sm q-mb-md">
        <q-list bordered class="full-width">
          <q-item class="q-my-sm">
            <q-item-section avatar>
              <q-avatar color="primary" text-color="white" size="md">
                {{ user.displayName.charAt(0) }}
              </q-avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Display Name</q-item-label>
              <q-item-label caption lines="1">{{
                user.displayName
              }}</q-item-label>
            </q-item-section>
          </q-item>
          <q-separator />

          <q-item class="q-my-sm">
            <q-item-section avatar>
              <q-icon
                color="primary"
                name="email"
                text-color="white"
                size="md"
              />
            </q-item-section>
            <q-item-section>
              <q-item-label>Email</q-item-label>
              <q-item-label caption lines="1">{{ user.email }}</q-item-label>
            </q-item-section>
          </q-item>
          <q-separator />

          <q-item class="q-my-sm">
            <q-item-section>
              <q-select
                filled
                v-model="roleSelected"
                :options="userRoles"
                label="Role"
                label-color="primary"
                color="primary"
              />
            </q-item-section>
          </q-item>

          <q-separator
            v-if="
              roleSelected === 'Super Customer' || roleSelected === 'Customer'
            "
          />
          <q-item
            v-if="
              roleSelected === 'Super Customer' || roleSelected === 'Customer'
            "
            class="q-my-sm"
          >
            <q-item-section>
              <q-select
                filled
                v-model="customerSelected"
                :options="customers"
                label="Customer"
                label-color="primary"
                color="primary"
              />
              <span v-if="customerSelectedErr" class="text-custom-red">
                Select Customer
                <span v-if="extraErr" class="text-custom-red"> !!! </span>
              </span>
            </q-item-section>
          </q-item>
        </q-list>
      </q-card>
    </q-form>
  </div>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog.vue';

export default {
  name: 'EditUser',
  components: {
    ConfirmDialog
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      btn: {
        label: 'Delete',
        icon: 'delete',
        color: 'custom-red'
      },
      confirmDelete: false,
      confirmMessage: `Are you sure you want to delete user? <br><br>
        Deleting user will permanently remove user from app, including
        time clock information and driver label on hauls!`,
      customerSelected: '',
      extraErr: false,
      roleSelected: '',
      displayName: '',
      userRoles: [
        'Super Admin',
        'Admin',
        'Super User',
        'User',
        'Guest User',
        'Super Customer',
        'Customer',
        'Visitor'
      ]
    };
  },
  mounted() {
    this.displayName = this.user.displayName;

    const claims = this.user.customClaims;
    if (claims.superAdmin === true) {
      this.roleSelected = 'Super Admin';
    } else if (claims.admin === true) {
      this.roleSelected = 'Admin';
    } else if (claims.superUser === true) {
      this.roleSelected = 'Super User';
    } else if (claims.user === true) {
      this.roleSelected = 'User';
    } else if (claims.guestUser === true) {
      this.roleSelected = 'Guest User';
    } else if (claims.superCustomer === true) {
      this.roleSelected = 'Super Customer';
      const objKey = this.user.email.replace(/\./g, '');
      const customerId = this.guestCustomers[objKey];
      this.customerSelected = this.customerList[customerId].name;
    } else if (claims.customer === true) {
      this.roleSelected = 'Customer';
      const objKey = this.user.email.replace(/\./g, '');
      const customerId = this.guestCustomers[objKey];
      this.customerSelected = this.customerList[customerId].name;
    } else if (claims.visitor === true) {
      this.roleSelected = 'Visitor';
    }
  },
  methods: {
    onDelete() {
      this.$emit('onDeleteUser');
    },
    onSubmit() {
      const customer = {
        isCustomer: false,
        displayName: this.displayName,
        customerId: ''
      };

      if (
        this.roleSelected === 'Super Customer' ||
        this.roleSelected === 'Customer'
      ) {
        let customerWasSelected = false;
        for (const key in this.customerList) {
          if (this.customerList[key].name === this.customerSelected) {
            customerWasSelected = true;
            customer.customerId = key;
            console.log(this.customerSelected);
          }
        }

        if (!customerWasSelected) {
          this.extraErr = true;
          return;
        }

        console.log(customer);
      }
      this.$emit('onSubmitUser', this.roleSelected, customer);
    }
  },
  computed: {
    customerList() {
      return this.$store.state.customers;
    },
    customers() {
      const customerList = this.$store.state.customers;
      const customers = [];

      for (const key in customerList) {
        customers.push(customerList[key].name);
      }

      customers.sort();

      return customers;
    },
    customerSelectedErr() {
      return this.customerSelected.length < 1;
    },
    guestCustomers() {
      return this.$store.state.guestCustomerList.guestCustomers;
    }
  }
};
</script>

<style scoped></style>
