<template>
  <div class="div-container-2">
    <ConfirmDialog
      v-if="confirmDelete"
      icon="grain"
      iconColor="primary"
      :btn="btn"
      :message="confirmMessage"
      :headerMessage="confirmHeaderMessage"
      @canceled="confirmDelete = false"
      @confirmed="deleteCommodity"
    />

    <OkDialog
      v-if="okDialog"
      icon="grain"
      iconColor="primary"
      :message="okMessage"
      @okConfirmed="okDialog = false"
    />

    <div class="row q-pa-sm q-mt-xl">
      <q-space />
      <q-btn
        v-if="displayCreateNew && editing"
        outline
        icon-right="close"
        label="Cancel"
        color="primary"
        padding="xs md"
        @click="(displayCreateNew = false), (editing = false)"
      />
      <q-btn
        v-if="displayCreateNew && !editing"
        outline
        icon-right="list"
        label="List"
        color="primary"
        padding="xs md"
        @click="displayCreateNew = false"
      />
      <q-btn
        v-if="!displayCreateNew"
        outline
        icon-right="add"
        label="Add"
        color="primary"
        padding="xs md"
        @click="createNew"
      />
    </div>

    <div
      class="row items-center justify-center rounded-borders text-custom-light q-ma-sm q-mb-md"
      style="background: radial-gradient(circle, #3336eb 0%, #0b0d6f 100%)"
    >
      <q-avatar icon="grain" font-size="35px" />
      <span class="text-body1 q-ml-sm"> Commodities </span>
    </div>

    <q-card
      v-if="!displayCreateNew && !Object.keys(commodities).length"
      flat
      bordered
      class="q-ma-sm"
    >
      <div class="q-my-lg">
        <div
          class="text-grey-5 row justify-center col-12"
          style="font-size: 70px; margin-top: 50px; margin-bottom: 30px"
        >
          ( ^ _ ^ )
        </div>
        <div class="text-grey-5 text-center" style="font-size: 28px">
          No Commodities To Display
        </div>
      </div>
    </q-card>

    <q-card
      v-if="!displayCreateNew && Object.keys(commodities).length"
      flat
      bordered
      class="q-ma-sm q-mb-md"
    >
      <q-list v-if="!displayCreateNew" separator>
        <q-item
          v-for="(commodity, index) in commodities"
          :key="index"
          class="q-pa-md"
        >
          <q-item-section avatar>
            <q-avatar color="primary" size="md" text-color="white">
              {{ commodity.name.charAt(0) }}
            </q-avatar>
          </q-item-section>
          <q-item-section>
            <span
              >{{ commodity.name }} -
              <span class="text-grey-7">{{
                commodity.measurementUnit
              }}</span></span
            >
          </q-item-section>
          <q-item-section side>
            <div class="q-my-sm">
              <q-btn
                outline
                size="md"
                padding="xs"
                color="custom-orange"
                icon="edit"
                class="q-mr-sm"
                @click="edit(commodity)"
              />
              <q-btn
                outline
                size="md"
                padding="xs"
                color="custom-red"
                icon="delete"
                @click="deleteDialog(commodity)"
              />
            </div>
          </q-item-section>
        </q-item>
      </q-list>
    </q-card>

    <q-form v-if="displayCreateNew" @submit="onSubmit">
      <q-card flat bordered class="q-pa-md q-ma-sm q-mb-md">
        <q-input
          v-if="displayCreateNew"
          v-model="newCommodity"
          outlined
          label="Enter Commodity Name"
          label-color="primary"
          color="primary"
          lazy-rules
          hide-bottom-space
          :rules="[
            val => (val && val.trim().length > 0) || 'Please enter a name'
          ]"
        >
        </q-input>

        <div>
          <q-card flat bordered class="q-mt-md q-pa-sm">
            <span class="text-bold q-ml-sm">Measurement Unit</span>
            <q-option-group
              :options="measurementUnits"
              type="radio"
              size="xs"
              v-model="measurementUnitSelected"
              color="primary"
            />
          </q-card>
        </div>
      </q-card>

      <div align="right" class="q-mr-sm">
        <q-btn
          v-if="displayCreateNew && !editing"
          icon="add"
          label="Add"
          type="submit"
          color="primary"
          padding="xs md"
        />
        <q-btn
          v-if="displayCreateNew && editing"
          icon="edit"
          label="Edit"
          type="submit"
          color="primary"
          padding="xs md"
        />
      </div>
    </q-form>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import OkDialog from '@/components/OkDialog.vue';

const db = firebase.firestore();

export default {
  name: 'DestinationServices',
  components: {
    ConfirmDialog,
    OkDialog
  },
  props: {
    commodities: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      btn: {
        label: 'Delete',
        icon: 'delete',
        color: 'custom-red'
      },
      confirmDelete: false,
      confirmMessage: '',
      confirmHeaderMessage: '',
      commodity: {},
      commodityBeforeEdit: '',
      displayCreateNew: false,
      editing: false,
      measurementUnits: [
        { label: 'Tons', value: 'Tons' },
        { label: 'Bushels', value: 'Bushels' },
        { label: 'Lbs', value: 'Lbs' }
      ],
      measurementUnitSelected: 'Tons',
      newCommodity: '',
      okDialog: false,
      okMessage: '',
      selectedCommodityIndex: null
    };
  },
  methods: {
    back() {
      this.$emit('backToSettings');
    },
    createNew() {
      this.newCommodity = '';
      this.displayCreateNew = true;
    },
    deleteDialog(commodity) {
      this.confirmMessage = 'Are you sure you want to delete commodity?';
      this.confirmHeaderMessage = commodity.name;
      this.confirmDelete = true;
      this.commodity = commodity;
    },
    deleteCommodity() {
      this.confirmDelete = false;

      const batch = db.batch();

      const settingsDoc = db.collection('settings_users').doc('settings');

      batch.update(settingsDoc, {
        [`commodities.${this.commodity.name}`]:
          firebase.firestore.FieldValue.delete()
      });

      batch.commit().catch(err => {
        console.log(err);
      });

      this.notify('Commodity Deleted', 'custom-red');
    },
    edit(commodity) {
      this.newCommodity = commodity.name;
      this.commodityBeforeEdit = commodity.name;
      this.measurementUnitSelected = commodity.measurementUnit;
      this.displayCreateNew = true;
      this.editing = true;

      const headerBtns = [
        {
          action: this.back,
          args: '',
          label: 'Done',
          color: 'primary',
          flat: false
        }
      ];

      this.$store.dispatch('setHeaderBtns', headerBtns);
    },
    notify(message, color) {
      this.$q.notify({
        color: color,
        textColor: 'white',
        icon: 'grain',
        message: message
      });
    },
    onActivateCommodity(commodity) {
      for (const key in this.commodities) {
        if (this.commodities[key].name === commodity && !this.editing) {
          this.okMessage = `${commodity} already exists!`;
          this.okDialog = true;
          return;
        }
      }

      const batch = db.batch();

      const settingsDoc = db.collection('settings_users').doc('settings');
      const commodityObj = {
        name: commodity,
        measurementUnit: this.measurementUnitSelected
      };

      batch.update(settingsDoc, {
        [`commodities.${commodity}`]: commodityObj
      });

      if (this.editing) {
        if (this.commodity !== this.commodityBeforeEdit) {
          batch.update(settingsDoc, {
            [`commodities.${this.commodityBeforeEdit}`]:
              firebase.firestore.FieldValue.delete()
          });
        }
      }

      batch.commit().catch(err => {
        console.log(err);
      });

      if (this.editing) {
        this.notify('Commodity Updated', 'primary');
      } else {
        this.notify('Commodity Added', 'primary');
      }
      this.displayCreateNew = false;
      this.editing = false;
    },
    onSubmit() {
      let commodity = this.newCommodity;
      commodity = commodity.trim();
      commodity = commodity.replaceAll('.', '');
      commodity = commodity.replace(/(^\w{1})|(\s+\w{1})/g, letter =>
        letter.toUpperCase()
      );
      this.onActivateCommodity(commodity);
    }
  },
  setAction() {
    console.log('action');
  }
};
</script>

<style scoped></style>
