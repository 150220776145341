<template>
  <q-layout view="lHh Lpr lFf" id="main-container">
    <q-header id="main-header">
      <q-bar class="bg-primary">
        <q-btn
          flat
          icon="menu"
          size="md"
          padding="sm sm"
          label="Menu"
          @click="leftDrawerOpen = !leftDrawerOpen"
        />
        <q-space />

        <q-icon
          :color="breadCrumbColor()"
          size="sm"
          :name="breadCrumbIcon()"
          :style="breadCrumbStyle()"
        />
        <div class="q-ml-sm q-py-xl text-body1">{{ breadCrumb() }}</div>
        <q-space />

        <!--Center Page Title-->
        <div class="gt-xs" style="padding-left: 80px"></div>
        <!--Center Page Title-->
      </q-bar>

      <q-toolbar v-show="headerBtnsDisplay" class="bg-grey-3">
        <q-space />
        <q-btn
          v-for="btn in headerBtns"
          :key="btn.label"
          :color="btn.color"
          :label="btn.label"
          :outline="btn.flat"
          class="q-mr-sm"
          size="14px"
          :padding="$q.screen.lt.sm ? '1px md' : '1px lg'"
          @click="btn.action(btn.args)"
        />
      </q-toolbar>
    </q-header>

    <q-drawer
      v-model="leftDrawerOpen"
      show-if-above
      bordered
      right
      content-class="custom-class"
    >
      <div v-if="!authChecked">
        <div class="q-pt-md full-width">
          <div v-for="i in 7" :key="i">
            <q-item>
              <q-item-section avatar>
                <q-skeleton size="50px" />
              </q-item-section>
              <q-item-section>
                <q-item-label caption>
                  <q-skeleton width="150px" />
                </q-item-label>
              </q-item-section>
            </q-item>
          </div>
        </div>
      </div>

      <div v-if="authChecked">
        <q-list class="text-custom-medium">
          <div v-for="menuItem in menu" :key="menuItem.to">
            <q-item v-if="menuItem.show()" :to="menuItem.to" exact>
              <q-item-section avatar>
                <q-icon
                  :name="menuItem.icon()"
                  :color="menuItem.iconColor"
                  :style="menuItem.iconStyle()"
                />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ menuItem.label() }}</q-item-label>
              </q-item-section>
            </q-item>
          </div>
        </q-list>
      </div>
    </q-drawer>
    <template v-if="!authChecked">
      <q-page-container>
        <q-linear-progress query color="primary" />
      </q-page-container>
    </template>

    <div v-if="authChecked">
      <q-page-container>
        <q-page id="background">
          <router-view />
        </q-page>
      </q-page-container>
    </div>
  </q-layout>
</template>

<script>
import store from '@/store';
import { customRoutes, delRoutes } from '@/custom/Routes.js';
import { gFunctions, gDestroy } from '@/custom/GlobalFunctions.js';
import { mapState } from 'vuex';

export default {
  name: 'LayoutDefault',
  components: {},
  data() {
    return {
      appVersionSet: false,
      deferredInstall: null,
      leftDrawerOpen: false,
      menu: [],
      registration: null
    };
  },
  created() {
    this.setMenu();

    window.addEventListener('beforeinstallprompt', e => {
      e.preventDefault();
      this.deferredInstall = e;

      const pwa = JSON.parse(localStorage.getItem('pwa'));
      const now = +new Date();
      const fourWeeksAgo = now - 604800000;
      if (!pwa || (!pwa.isPwa && pwa.lastPwaConfirm < fourWeeksAgo)) {
        this.$q
          .dialog({
            title: 'Install App?',
            message:
              'Would you like to install this app on your device? App runs smoother if installed!',
            cancel: true,
            persistent: true
          })
          .onOk(() => {
            this.deferredInstall.prompt();
          })
          .onCancel(() => {
            const pwa = {
              isPwa: false,
              lastPwaConfirm: +new Date()
            };
            localStorage.setItem('pwa', JSON.stringify(pwa));
          });
      }
    });

    window.addEventListener('appinstalled', () => {
      const pwa = {
        isPwa: true,
        lastPwaConfirm: +new Date()
      };
      localStorage.setItem('pwa', JSON.stringify(pwa));
      this.deferredInstall = null;
    });

    document.addEventListener('swUpdated', this.updateAvailable, {
      once: true
    });

    navigator.serviceWorker.addEventListener('controllerchange', () => {
      // We'll also need to add 'refreshing' to our data originally set to false.
      console.log('%ccontroller change', 'color: red');
      if (this.refreshing) return;
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });
  },
  mounted() {
    const now = +new Date();
    if (
      !localStorage.menuForceOpenTs ||
      (localStorage.menuForceOpenTs &&
        JSON.parse(localStorage.menuForceOpenTs) < now - 6 * 60 * 60 * 1000)
    ) {
      localStorage.menuForceOpenTs = now;
      this.leftDrawerOpen = true;
    }

    // call custom Global Functions
    gFunctions();
  },
  methods: {
    breadCrumb() {
      const menuItem = this.menu.find(
        x => x.to === this.$router.currentRoute.path
      );

      if (menuItem) {
        return menuItem.label();
      }
      return '';
    },
    breadCrumbColor() {
      const menuItem = this.menu.find(
        x => x.to === this.$router.currentRoute.path
      );

      if (menuItem) {
        return menuItem.iconColor;
      }
      return '';
    },
    breadCrumbIcon() {
      const menuItem = this.menu.find(
        x => x.to === this.$router.currentRoute.path
      );

      if (menuItem) {
        return menuItem.icon();
      }
      return '';
    },
    breadCrumbStyle() {
      const menuItem = this.menu.find(
        x => x.to === this.$router.currentRoute.path
      );

      if (menuItem) {
        return menuItem.iconStyle();
      }
      return '';
    },
    headerBtnClick(action) {
      store.dispatch('setHeaderAction', action);
    },
    routeLabel() {
      let label = this.$router.currentRoute.name;

      if (label === 'Destinations') {
        label = this.destAs;
      }

      return label;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    scrollToBottom() {
      window.scrollTo(0, document.body.scrollHeight);
    },
    setAppVersion() {
      if (!this.appVersionSet) {
        const newVersion = this.$store.state.settingsUsers.appVersion;
        const oldVersion = localStorage.appVersion;

        if (!oldVersion) {
          localStorage.appVersion = newVersion;
          return;
        }

        if (!newVersion) {
          return;
        }

        const now = +new Date();
        if (
          localStorage.forceReloadTs &&
          JSON.parse(localStorage.forceReloadTs) > now - 30 * 1000
        ) {
          console.log('%cless than thirty seconds ago', 'color: red');
          return;
        }

        if (oldVersion !== newVersion) {
          this.$q
            .dialog({
              title: 'App Update & Reload!',
              message:
                'A new update is available. App will need to reload a couple times!'
            })
            .onDismiss(() => {
              // Dismiss will always be called
              console.log('%cforce reloading', 'color: red');
              localStorage.forceReloadTs = now;
              localStorage.appVersion = newVersion;
              location.reload();
              return;
            });
        }
      }
      this.appVersionSet = true;
    },
    setMenu() {
      const menu = [
        {
          to: '/',
          label: () => 'Home',
          show: () => true,
          icon: () => 'home',
          iconColor: '',
          iconStyle: () => ''
        },
        {
          to: '/account',
          label: () => 'Account',
          show: () => true,
          icon: () => 'person',
          iconColor: '',
          iconStyle: () => ''
        },
        {
          to: '/customers',
          label: () => 'Customers',
          show: () => this.admin,
          icon: () => 'account_box',
          iconColor: '',
          iconStyle: () => ''
        },
        {
          to: '/sources',
          label: () => 'Sources',
          show: () => this.user !== null,
          icon: () => 'outbound',
          iconColor: 'custom-green',
          iconStyle: () => ''
        },
        {
          to: '/destinations',
          label: () => this.destAs,
          show: () => this.user !== null,
          icon: () => (this.destAs === 'Work Orders' ? 'task' : 'outbound'),
          iconColor: 'custom-red',
          iconStyle: () => (this.isWorkOder ? '' : 'transform: rotate(180deg)')
        },
        {
          to: '/hauls',
          label: () => 'Hauls',
          show: () => this.user !== null && !this.guestCustomer,
          icon: () => 'local_shipping',
          iconColor: '',
          iconStyle: () => ''
        },
        {
          to: '/map',
          label: () => 'Map',
          show: () => this.user !== null,
          icon: () => 'map',
          iconColor: '',
          iconStyle: () => ''
        },
        {
          to: '/reports',
          label: () => 'Reports',
          show: () => this.user !== null,
          icon: () => 'trending_up',
          iconColor: '',
          iconStyle: () => ''
        },
        {
          to: '/time-clock',
          label: () => 'Time Clock',
          show: () => this.user !== null && !this.guestCustomer,
          icon: () => 'schedule',
          iconColor: '',
          iconStyle: () => ''
        },
        {
          to: '/settings',
          label: () => 'Settings',
          show: () => this.admin,
          icon: () => 'settings',
          iconColor: '',
          iconStyle: () => ''
        },
        {
          to: '/settlement',
          label: () => 'Settlement',
          show: () => this.admin,
          icon: () => 'calculate',
          iconColor: '',
          iconStyle: () => ''
        }
      ];

      for (let i = 0; i < delRoutes.length; i++) {
        const route = delRoutes[i];
        const index = menu.findIndex(x => x.to === route);
        if (index >= 0) {
          menu.splice(index, 1);
        }
      }

      for (let i = 0; i < customRoutes.length; i++) {
        const route = customRoutes[i];
        let show = () => false;
        if (!this.user) {
          break;
        }

        route.authFor.forEach(x => {
          if (this.user[x]) {
            show = () => true;
          }
        });

        const menuItem = {
          to: route.path,
          label: () => route.name,
          show: () => show(),
          icon: () => route.icon,
          iconColor: route.iconColor,
          iconStyle: () => route.iconStyle
        };
        menu.splice(route.menuLocation, 0, menuItem);
      }

      this.menu = menu;
    },
    updateAvailable(event) {
      console.log('%cupdateAvailable', 'color: red');
      this.registration = event.detail;

      this.$q
        .dialog({
          title: 'New Update Available',
          message: 'App needs to reload to update'
        })
        .onDismiss(() => {
          // Dismiss will always be called
          if (!this.registration || !this.registration.waiting) return;
          // Send message to SW to skip the waiting and activate the new SW
          //localStorage.forceReloadTs = now;
          console.log('%cforce reloading', 'color: violet');
          this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        });
    }
  },
  computed: {
    admin() {
      return this.user && (this.user.superAdmin || this.user.admin);
    },
    ...mapState({
      user: 'user',
      destAs: state => state.settingsUsers.destAs,
      headerBtns: 'headerBtns',
      headerBtnsDisplay: 'headerBtnsDisplay',
      authChecked: 'authChecked'
    }),
    isWorkOder() {
      return this.destAs === 'Work Orders';
    },
    guestCustomer() {
      return this.user && (this.user.superCustomer || this.user.customer);
    }
  },
  watch: {
    '$store.state.settingsUsers.appVersion'() {
      this.appVersionSet = false;
      this.setAppVersion();
    },
    user() {
      this.setMenu();
    }
  },
  beforeDestroy() {
    gDestroy();
  }
};
</script>

<style scoped>
.q-router-link--exact-active {
  background-color: #eeeeee;
  color: #dbe0e6;
}
</style>
