<template>
  <div class="row full-width justify-center">
    <ConfirmDialog
      v-if="confirm.display"
      icon="image"
      iconColor="primary"
      :btn="confirm.btn"
      :message="confirm.message"
      :headerMessage="attachment.name"
      @canceled="confirm.display = false"
      @confirmed="deleteImage"
    />

    <q-dialog v-model="edit.display" persistent>
      <q-card class="q-pa-md outer-card">
        <div
          class="row items-center bg-grey-3 q-ma-md q-pa-xs text-grey-9 row items-center rounded-borders"
        >
          <q-icon name="image" size="md" class="q-ml-sm" />
          <div class="q-ml-sm">
            {{ attachment.name }}
          </div>
        </div>
        <q-card-section class="row items-center">
          <div class="full-width">
            <q-btn
              outline
              :label="moveLabel"
              icon="arrow_upward"
              color="primary"
              class="full-width q-mb-lg"
              @click="moveToTop"
            />
          </div>
          <div class="full-width">
            <q-input
              outlined
              v-model="edit.imageName"
              label="Edit Image Name"
              class="q-mt-sm"
              color="custom-orange"
              label-color="custom-orange"
            />
          </div>
        </q-card-section>

        <q-card-actions align="right" class="q-mr-sm">
          <q-btn
            flat
            label="Cancel"
            color="primary"
            padding="xs md"
            @click="edit.display = false"
          />
          <q-btn
            unelevated
            label="Update Name"
            color="custom-orange"
            padding="xs md"
            @click="changeName"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <div class="div-container-3">
      <div v-if="!mapView" class="row q-mt-xl">
        <q-space />
        <div v-if="!modes.loading && (isSuper || isAdmin)">
          <q-btn
            v-if="!modes.edit && !modes.add && images.length + pdfs.length"
            outline
            label="edit"
            icon-right="image"
            color="custom-orange"
            padding="xs sm"
            @click="(modes.edit = true), (modes.add = false)"
          />
          <q-btn
            v-if="modes.edit && !modes.add"
            outline
            label="Cancel Edit"
            icon-right="image"
            color="custom-orange"
            padding="xs sm"
            @click="modes.edit = false"
          />
          <q-btn
            v-if="!modes.add && images.length + pdfs.length <= 9 && !modes.edit"
            outline
            label="add"
            icon-right="image"
            color="primary"
            padding="xs sm"
            class="q-ml-sm"
            @click="addMode"
          />
          <q-btn
            v-if="!modes.add && images.length + pdfs.length > 9 && !modes.edit"
            outline
            label="add"
            icon-right="image"
            color="custom-light"
            class="q-ml-sm"
            padding="xs sm"
          />
          <q-btn
            v-if="modes.add && !modes.edit"
            outline
            label="cancel"
            icon-right="image"
            color="primary"
            padding="xs sm"
            class="q-ml-sm"
            @click="modes.add = false"
          />
        </div>
      </div>

      <div
        class="row items-center rounded-borders q-pl-sm q-mt-sm"
        style="border: solid #0b0d6f 1px"
      >
        <q-icon
          name="photo_library"
          color="primary"
          size="lg"
          class="q-ma-sm"
          font-size="35px"
        />
        <span class="text-body1 text-primary">
          {{ masterDestinations[location.id].destinationName }}
        </span>
      </div>

      <div v-if="modes.loading" class="q-py-xs">
        <q-linear-progress indeterminate color="primary" size="sm" />
      </div>
      <div v-if="modes.add">
        <div class="q-mt-sm">
          <q-file
            v-model="image"
            outlined
            color="custom-orange"
            label-color="custom-orange"
            label="Select Image"
            accept=".jpg, image/*, .pdf"
            @rejected="onRejected"
          />
          <span v-if="selectImageErr" class="text-custom-red"
            >Please Select Image!</span
          >
          <q-input
            outlined
            v-model="imageName"
            label="Image Name (optional)"
            color="custom-orange"
            label-color="custom-orange"
            class="q-mt-sm"
          />
        </div>
        <div align="right" class="q-mt-sm">
          <q-btn
            label="Add"
            icon="add"
            color="primary"
            padding="xs sm"
            @click="uploadImage"
          />
        </div>
      </div>
    </div>

    <div
      v-if="!modes.add && !modes.loading"
      class="row full-width justify-center q-gutter-sm q-mt-xl"
    >
      <div v-if="!images.length && !pdfs.length" class="q-mt-lg">
        <div class="text-primary text-center" style="font-size: 27px">
          No Attachments
        </div>
      </div>

      <div v-if="pdfs.length">
        <div
          class="text-primary row justify-center col-12"
          style="font-size: 40px"
        >
          PDFs
        </div>
      </div>

      <div
        v-for="pdf in pdfs"
        :key="pdf.url"
        class="full-width row justify-center"
      >
        <div class="q-mb-lg div-container-3">
          <div align="right">
            <q-btn
              outline
              label="New Tab"
              icon="open_in_new"
              color="primary"
              padding="xs sm"
              class="q-mb-sm"
              size="sm"
              @click="openInNewTab(pdf.url)"
            />
          </div>

          <div
            class="rounded-borders text-h6 text-bold text-primary q-pl-sm q-mb-sm"
            style="border: solid #0b0d6f 1px"
          >
            <div v-if="pdf.name.length < 1" class="q-pa-md"></div>
            {{ pdf.name }}
          </div>

          <pdf
            v-for="i in pdf.pageCount"
            :key="i + 'page'"
            :src="pdf.url"
            :page="i"
          ></pdf>

          <div v-if="!modes.edit" class="q-pt-lg q-pb-md q-mt-xs"></div>
          <div v-if="modes.edit" align="right">
            <q-btn
              label="Edit"
              icon="edit"
              color="custom-orange"
              class="q-mt-sm q-mr-sm"
              padding="xs md"
              @click="editImage(pdf)"
            />
            <q-btn
              label="Delete"
              icon="delete"
              color="custom-red"
              class="q-mt-sm"
              padding="xs md"
              @click="confirmDelete(pdf)"
            />
          </div>
        </div>
      </div>

      <div v-if="images.length">
        <div
          class="text-primary row justify-center col-12"
          style="font-size: 35px"
        >
          Images
        </div>
      </div>

      <div
        v-for="image in images"
        :key="image.url"
        class="full-width row justify-center"
      >
        <div class="q-mb-lg div-container-3">
          <div align="right">
            <q-btn
              outline
              label="New Tab"
              icon="open_in_new"
              color="primary"
              padding="xs sm"
              class="q-mb-sm"
              size="sm"
              @click="openInNewTab(image.url)"
            />
          </div>
          <div
            class="rounded-borders text-h6 text-bold text-primary q-pl-sm q-mb-sm"
            style="border: solid #0b0d6f 1px"
          >
            <div v-if="image.name.length < 1" class="q-pa-md"></div>
            {{ image.name }}
          </div>

          <q-img
            :src="image.url"
            spinner-color="primary"
            class="rounded-borders"
          />

          <div v-if="!modes.edit" class="q-pt-lg q-pb-md q-mt-xs"></div>
          <div v-if="modes.edit" align="right">
            <q-btn
              label="Edit"
              icon="edit"
              color="custom-orange"
              class="q-mt-sm q-mr-sm"
              padding="xs md"
              @click="editImage(image)"
            />
            <q-btn
              label="Delete"
              icon="delete"
              color="custom-red"
              class="q-mt-sm"
              padding="xs md"
              @click="confirmDelete(image)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import pdf from 'vue-pdf';

const db = firebase.firestore();

export default {
  name: 'ManageAttachement',
  components: {
    ConfirmDialog,
    pdf
  },
  props: {
    location: {
      type: Object,
      required: true
    },
    mapView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      attachment: {},
      attachmentId: '',
      confirm: {
        btn: {
          label: 'delete',
          icon: 'delete',
          color: 'custom-red'
        },
        display: false,
        message: ''
      },
      edit: {
        display: false,
        imageName: ''
      },
      modes: {
        add: false,
        edit: false,
        loading: false
      },
      image: null,
      images: [],
      imageName: '',
      imgStyle: '',
      pdfs: [],
      showPDF: true,
      selectImageErr: false
    };
  },
  mounted() {
    this.attachmentId = this.location.attachmentId;
    this.getImages();
  },
  methods: {
    addMode() {
      this.image = null;
      this.imageName = '';
      this.modes.edit = false;
      this.modes.add = true;
    },
    changeName() {
      this.edit.display = false;
      this.modes.loading = true;
      const attachment = this.attachment;

      let imageName = this.edit.imageName;
      imageName = imageName.trim();
      imageName = imageName.replaceAll('.', '');
      imageName = imageName.charAt(0).toUpperCase() + imageName.slice(1);

      const attachmentRef = db
        .collection('master_attachments')
        .doc(this.attachmentId);

      const attachmentUpdateObj = {
        [`${attachment.key}.name`]: imageName
      };

      const batch = db.batch();
      batch.update(attachmentRef, attachmentUpdateObj);

      batch.commit().catch(err => {
        console.error(err);
      });
      this.modes.edit = false;
      this.getImages();
    },
    confirmDelete(attachment) {
      this.confirm.message = 'Are you sure you want to delete image?';
      this.attachment = attachment;
      this.confirm.display = true;
    },
    deleteImage() {
      const masterSplitDestinations = JSON.parse(
        JSON.stringify(this.$store.state.masterSplitDestinations)
      );

      this.confirm.display = false;
      this.modes.loading = true;
      const attachment = this.attachment;

      const imageDeleteComplete = () => {
        this.modes.edit = false;
        this.getImages();
      };

      let docRef;
      for (const key in masterSplitDestinations) {
        const splitDest = masterSplitDestinations[key];

        if (Object.prototype.hasOwnProperty.call(splitDest, this.location.id)) {
          docRef = key;
        }
      }

      const that = this;
      const deleteRef = firebase
        .storage()
        .ref()
        .child(`attachments/${attachment.key}`);
      deleteRef
        .delete()
        .then(() => {
          const masterDestListRef = db
            .collection('master_locations')
            .doc(docRef);

          const attachmentRef = db
            .collection('master_attachments')
            .doc(this.attachmentId);

          const attachmentUpdateObj = {
            [attachment.key]: firebase.firestore.FieldValue.delete()
          };

          const batch = db.batch();
          if (this.images.length + this.pdfs.length <= 1) {
            const locationUpdateObj = {
              [`${this.location.id}.attachmentId`]: null
            };
            batch.update(masterDestListRef, locationUpdateObj);
            batch.delete(attachmentRef);
            that.attachmentId = null;
          } else {
            batch.update(attachmentRef, attachmentUpdateObj);
          }

          batch.commit().catch(err => {
            console.error(err);
          });
          imageDeleteComplete();
        })
        .catch(err => {
          console.log(err);
        });
    },
    editImage(attachment) {
      this.attachment = attachment;
      this.edit.imageName = attachment.name;
      this.edit.display = true;
    },
    getImages() {
      this.modes.loading = true;
      if (this.attachmentId) {
        const urlDoc = db
          .collection('master_attachments')
          .doc(this.attachmentId);
        urlDoc
          .get()
          .then(doc => {
            if (doc.exists) {
              const unsortedUrls = doc.data();

              const urlArr = [];
              for (const key in unsortedUrls) {
                urlArr.push({ ...unsortedUrls[key], ...{ key: key } });
              }

              const sortByTs = (a, b) => {
                if (a.ts > b.ts) {
                  return -1;
                }
                if (a.ts < b.ts) {
                  return 1;
                }
                return 0;
              };
              urlArr.sort(sortByTs);

              this.images = [];
              this.pdfs = [];

              for (let i = 0; i < urlArr.length; i++) {
                if (!urlArr[i].isPDF) {
                  this.images.push(urlArr[i]);
                } else if (urlArr[i].isPDF) {
                  this.pdfs.push(urlArr[i]);
                }
              }
              this.modes.loading = false;
            }
          })
          .catch(error => {
            console.log('Error getting document:', error);
          });
      } else {
        this.images = [];
        this.pdfs = [];
        this.modes.loading = false;
      }
    },
    moveToTop() {
      this.edit.display = false;
      this.modes.loading = true;
      const attachment = this.attachment;

      const ts = +new Date();

      const attachmentRef = db
        .collection('master_attachments')
        .doc(this.attachmentId);

      const attachmentUpdateObj = {
        [`${attachment.key}.ts`]: ts
      };

      const batch = db.batch();
      batch.update(attachmentRef, attachmentUpdateObj);

      batch.commit().catch(err => {
        console.error(err);
      });
      this.modes.edit = false;
      this.getImages();
    },
    onRejected(err) {
      console.log(err);
    },
    openInNewTab(url) {
      window.open(url);
    },
    uploadImage() {
      const masterSplitDestinations = JSON.parse(
        JSON.stringify(this.$store.state.masterSplitDestinations)
      );

      this.modes.add = false;
      this.modes.loading = true;

      const img = this.image;
      if (this.img === null) {
        this.selectImageErr = true;
        return;
      }

      let pageCount = 0;
      let isPDF = false;
      if (img.type === 'application/pdf') {
        isPDF = true;
        const reader = new FileReader();
        if (img) {
          reader.readAsBinaryString(img);
          reader.onloadend = () => {
            const count = reader.result.match(/\/Type[\s]*\/Page[^s]/g).length;
            if (count) {
              pageCount = count;
            }
          };
        }
      }

      const ts = +new Date();

      let imageName = this.imageName;
      imageName = imageName.trim();
      imageName = imageName.replaceAll('.', '');
      imageName = imageName.charAt(0).toUpperCase() + imageName.slice(1);

      const imageUploadComplete = () => {
        if (this.images.length + this.pdfs.length > 0) {
          this.getImages();
        }
      };
      // Generate random id
      const imageRef = db.collection('master_attachments').doc();

      let docRef;
      for (const key in masterSplitDestinations) {
        const splitDest = masterSplitDestinations[key];

        if (Object.prototype.hasOwnProperty.call(splitDest, this.location.id)) {
          docRef = key;
        }
      }

      const masterDestListRef = db.collection('master_locations').doc(docRef);

      let attachmentId;
      if (this.attachmentId === null) {
        attachmentId = imageRef.id;
      } else {
        attachmentId = this.attachmentId;
      }

      const that = this;
      const attachmentRef = db
        .collection('master_attachments')
        .doc(attachmentId);

      const storageRef = firebase
        .storage()
        .ref()
        .child(`attachments/${imageRef.id}`);
      const uploadTask = storageRef.put(img);
      uploadTask.then(() => {
        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          const attachmentUpdateObj = {
            [imageRef.id]: {
              name: imageName,
              url: downloadURL,
              ts,
              isPDF,
              pageCount
            }
          };

          const batch = db.batch();
          if (this.attachmentId === null) {
            batch.set(attachmentRef, attachmentUpdateObj);
            const locationUpdateObj = {
              [`${this.location.id}.attachmentId`]: attachmentId
            };
            batch.update(masterDestListRef, locationUpdateObj);
            that.attachmentId = attachmentId;
          } else {
            batch.update(attachmentRef, attachmentUpdateObj);
          }

          batch.commit().catch(err => {
            console.error(err);
          });
          imageUploadComplete();
        });
      });
    }
  },
  computed: {
    isAdmin() {
      return this.$store.state.user.superAdmin || this.$store.state.user.admin;
    },
    isSuper() {
      return this.$store.state.user.superUser;
    },
    isUser() {
      return this.$store.state.user.user;
    },
    masterDestinations() {
      return JSON.parse(JSON.stringify(this.$store.state.masterDestinations));
    },
    moveLabel() {
      return this.attachment.isPDF
        ? 'Move to top of pdfs'
        : 'Move to top of images';
    }
  },
  watch: {
    '$store.state.masterDestinations'() {
      this.getImages();
    }
  }
};
</script>

<style scoped></style>
