import Vue from 'vue';
import Vuex from 'vuex';
import firebase from './../firebase';
import router from './../router';
import { lastWeekDates } from './../utils/UtilHelpers.js';
import { FilterLists, UserFilterLists } from './../utils/FilterLists.js';

Vue.use(Vuex);
const db = firebase.firestore();

export default new Vuex.Store({
  state: {
    authChecked: false,
    activeDestinations: [],
    activeDestinationsUsers: [],
    activeSources: [],
    activeSourcesUsers: [],
    archivedSourcesUsers: [],
    balOrHauled: 'haul_pro',
    colors: [
      'primary',
      'custom-orange',
      'custom-light-blue',
      'custom-red',
      'custom-medium',
      'custom-green',
      'custom-artichoke'
    ],
    clockinData: {
      clockinDocExists: false,
      currentClockins: {
        current: {},
        history: []
      }
    },
    customers: {},
    destinationSettings: {
      defaultChargeCommodity: true,
      defaultPricePerUnit: 0,
      defaultTrackMileage: false,
      defaultMileageRate: 0,
      services: [],
      haulTrackers: []
    },
    filterLists: {
      destinationInputs: {},
      destinationSortBy: {},
      sourceInputs: {},
      sourceSortBy: {}
    },
    guestCustomerList: {
      guestCustomers: {}
    },
    guestUserList: {
      guestUsers: [],
      guestUserIds: []
    },
    generalSettings: {
      sReportNumber: null,
      commodities: []
    },
    haulShortcut: null,
    headerBtns: [],
    headerBtnsDisplay: false,
    importantNoteIds: [],
    loaded: {
      destinations: false,
      sources: false
    },
    mapPin: {
      type: null,
      id: null
    },
    masterDestinations: {},
    masterSplitDestinations: {},
    masterSources: {},
    masterSplitSources: {},
    settingsUsers: {
      appVersion: null,
      calcNetOvertime: false,
      commodities: [],
      destAs: 'Destinations',
      driverReportsAuth: [],
      lastHauls: [],
      modules: {},
      destinationSettings: {
        projectedPlaceholder: null,
        projectedTolerance: null,
        customSelectors: []
      },
      sourceSettings: {
        projectedPlaceholder: null,
        projectedTolerance: null,
        customSelectors: []
      }
    },
    snapshots: {
      destinations: () => {},
      destinationsUsers: () => {},
      sources: () => {},
      sourcesUsers: () => {},
      sourcesArchivedUsers: () => {},
      settings: () => {},
      settingsUsers: () => {},
      settingsUsersWritable: () => {},
      masterLocations: () => {},
      currentClockins: () => {}
    },
    sourceSettings: {
      defaultPurchaseCommodity: true,
      defaultPricePerUnit: 0,
      defaultTrackMileage: false,
      defaultMileageRate: 0,
      services: [],
      haulTrackers: []
    },
    user: null,
    userList: null,
    workTypes: []
  },
  getters: {
    user(state) {
      return state.user;
    }
  },
  mutations: {
    SET_BAL_OR_HAULED(state, payload) {
      let balOrHauled;
      if (payload.init) {
        balOrHauled = JSON.parse(localStorage.getItem('balOrHauled'));
        if (!balOrHauled) {
          balOrHauled = 'haul_pro';
          localStorage.setItem('balOrHauled', JSON.stringify(balOrHauled));
        }
      } else {
        localStorage.setItem(
          'balOrHauled',
          JSON.stringify(payload.balOrHauled)
        );
        balOrHauled = payload.balOrHauled;
      }
      state.balOrHauled = balOrHauled;
    },
    SET_CURRENT_CLOCKIN(state, clockinObj) {
      state.clockinData = clockinObj;
    },
    SET_AUTH_CHECKED(state, checked) {
      state.authChecked = checked;
    },
    SET_ACTIVE_DESTINATIONS(state, activeDestinations) {
      state.activeDestinations = [];
      state.activeDestinations = activeDestinations;
    },
    SET_ACTIVE_DESTINATIONS_USERS(state, activeDestinationsUsers) {
      state.activeDestinationsUsers = [];
      state.activeDestinationsUsers = activeDestinationsUsers;
      state.loaded.destinations = true;
    },
    SET_ACTIVE_SOURCES(state, activeSources) {
      state.activeSources = [];
      state.activeSources = activeSources;
    },
    SET_ACTIVE_SOURCES_USERS(state, activeSourcesUsers) {
      state.activeSourcesUsers = [];
      state.activeSourcesUsers = activeSourcesUsers;
      state.loaded.sources = true;
    },
    SET_ARCHIVED_SOURCES_USERS(state, archivedSourcesUsers) {
      state.archivedSourcesUsers = [];
      state.archivedSourcesUsers = archivedSourcesUsers;
    },
    SET_HAUL_SHORTCUT(state, shortcut) {
      state.haulShortcut = shortcut;
    },
    SET_HEADER_BTNS(state, headerBtns) {
      state.headerBtns = headerBtns.slice();
    },
    SET_HEADER_BTNS_DISPLAY(state, headerBtnsDisplay) {
      state.headerBtnsDisplay = headerBtnsDisplay;
    },
    SET_IMPORTANT_NOTE_IDS(state, noteIdObj) {
      state.importantNoteIds = state.importantNoteIds.filter(obj => {
        // If older than five min, filter out
        const tsFiveMinAgo = +new Date() - 300000;
        return tsFiveMinAgo < obj.ts && noteIdObj.id !== obj.id;
      });
      state.importantNoteIds.push(noteIdObj);
    },
    SET_MAP_PIN(state, mapPin) {
      state.mapPin = mapPin;
    },
    SET_MASTER_LOCATIONS(state, locations) {
      state.masterSources = {};
      state.masterSources = locations.masterSources;
      state.masterSplitSources = {};
      state.masterSplitSources = locations.masterSplitSources;
      state.masterDestinations = {};
      state.masterDestinations = locations.masterDestinations;
      state.masterSplitDestinations = {};
      state.masterSplitDestinations = locations.masterSplitDestinations;
    },
    SET_SETTINGS(state, settings) {
      state.generalSettings = settings.settings.general;
      state.guestUserList = settings.guestUserList;
      state.guestCustomerList = settings.guestCustomerList;
      state.destinationSettings = settings.settings.destinationSettings;
      state.sourceSettings = settings.settings.sourceSettings;
      state.customers = {};
      state.customers = settings.customerList;
    },
    SET_SETTINGS_USERS(state, settingsUsers) {
      state.settingsUsers.appVersion = settingsUsers.settings.appVersion;
      state.settingsUsers.calcNetOvertime =
        settingsUsers.settings.calcNetOvertime;
      state.settingsUsers.commodities = settingsUsers.settings.commodities;
      state.settingsUsers.modules = settingsUsers.settings.modules;
      state.settingsUsers.destAs = settingsUsers.settings.destAs;
      state.workTypes = settingsUsers.settings.workTypes;
      state.settingsUsers.driverReportsAuth =
        settingsUsers.settings.driverReportsAuth;
      state.settingsUsers.destinationSettings =
        settingsUsers.settings.destinationSettings;
      state.settingsUsers.sourceSettings =
        settingsUsers.settings.sourceSettings;
      state.userList = settingsUsers.userList;
      state.currentClockins = settingsUsers.currentClockins;
      if (!settingsUsers.isAdmin) {
        state.customers = settingsUsers.customerList;
      }
    },
    SET_SETTINGS_USERS_WRITABLE(state, lastHauls) {
      state.settingsUsers.lastHauls = lastHauls;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_USER_DATA(state, data) {
      const setFilters = filterLists => {
        // Change this cache number to wipe everyone's cache
        filterLists.cacheNumber = 20;
        filterLists.user = state.user;
        localStorage.setItem('filterLists', JSON.stringify(filterLists));
      };

      const userCheck = (x, y) => {
        let same = true;
        for (const key in x) {
          if (x[key] !== y[key]) {
            same = false;
          }
        }
        return same;
      };

      if (data === null) {
        let filterLists;

        // If no filterLists or filterLists.userLevel does not match
        if (
          !localStorage.filterLists ||
          !userCheck(state.user, JSON.parse(localStorage.filterLists).user)
        ) {
          if (state.user.admin || state.user.superAdmin) {
            filterLists = JSON.parse(JSON.stringify(FilterLists));
            setFilters(filterLists);
          } else {
            filterLists = JSON.parse(JSON.stringify(UserFilterLists));
            setFilters(filterLists);
          }
        }

        filterLists = localStorage.getItem('filterLists');
        state.filterLists = JSON.parse(filterLists);

        // Change this cache number to wipe everyone's cache
        if (
          state.filterLists.cacheNumber !== 20 ||
          JSON.stringify(state.filterLists.user) !== JSON.stringify(state.user)
        ) {
          if (state.user.user || state.user.superUser) {
            filterLists = JSON.parse(JSON.stringify(UserFilterLists));
          } else {
            filterLists = JSON.parse(JSON.stringify(FilterLists));
          }
          setFilters(filterLists);
          state.filterLists = FilterLists;
        }
      } else if (data.type === 'destinationInputs') {
        let filterLists = localStorage.getItem('filterLists');
        filterLists = JSON.parse(filterLists);
        filterLists.destinationInputs = data.inputs;
        filterLists.destinationSortBy = data.sortBy;
        filterLists.destinationStatusFilter = data.status;
        filterLists.destinationInputSelected = data.inputSelected;
        setFilters(filterLists);
        state.filterLists = filterLists;
      } else if (data.type === 'sourceInputs') {
        let filterLists = localStorage.getItem('filterLists');
        filterLists = JSON.parse(filterLists);
        filterLists.sourceInputs = data.inputs;
        filterLists.sourceSortBy = data.sortBy;
        filterLists.sourceStatusFilter = data.status;
        filterLists.sourceInputSelected = data.inputSelected;
        setFilters(filterLists);
        state.filterLists = filterLists;
      }
    }
  },
  actions: {
    setBalOrHauled({ commit }, payload) {
      commit('SET_BAL_OR_HAULED', payload);
    },
    loadCurrentClockins({ commit }, userList) {
      const docId = userList.users[this.state.user.displayName];
      this.state.snapshots.currentClockins = db
        .collection('current_clockins')
        .doc(docId)
        .onSnapshot(doc => {
          const clockinObj = {
            clockinDocExists: false,
            currentClockins: {
              current: {},
              history: []
            }
          };

          if (doc.exists) {
            clockinObj.clockinDocExists = true;
            clockinObj.currentClockins = doc.data();
          }

          if (this.state.user) {
            commit('SET_CURRENT_CLOCKIN', clockinObj);
          }
        });
    },
    loadDestinations({ commit }) {
      try {
        this.state.snapshots.destinations = db
          .collection('destinations_active')
          .where('destinationStatus', 'in', ['Open', 'Waiting'])
          .onSnapshot(snapshot => {
            const activeDestinations = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));

            if (!activeDestinations.length) {
              if (localStorage.reloadedOnce !== 'true') {
                location.reload();
                localStorage.reloadedOnce = 'true';
              } else {
                localStorage.reloadedOnce = 'false';
              }
            } else {
              localStorage.reloadedOnce = 'false';
            }

            if (this.state.user) {
              commit('SET_ACTIVE_DESTINATIONS', activeDestinations);
            }
          });
      } catch (err) {
        const errRef = db.collection('errors').doc();
        errRef.set({
          err: JSON.stringify(err),
          user: this.state.user,
          codeBlock: 'loadDestinations'
        });
      }
    },
    loadDestinationsUsers({ commit }) {
      try {
        const isAdmin = this.state.user.admin || this.state.user.superAdmin;
        this.state.snapshots.destinationsUsers = db
          .collection('destinations_active_users')
          .where('destinationStatus', 'in', ['Open', 'Waiting'])
          .onSnapshot(snapshot => {
            const activeDestinationsUsers = snapshot.docs
              .map(doc => ({
                id: doc.id,
                ...doc.data()
              }))
              .filter(dest => {
                return dest.destinationStatus === 'Open' || isAdmin;
              });

            if (this.state.user) {
              commit('SET_ACTIVE_DESTINATIONS_USERS', activeDestinationsUsers);
            }
          });
      } catch (err) {
        const errRef = db.collection('errors').doc();
        errRef.set({
          err: JSON.stringify(err),
          user: this.state.user,
          codeBlock: 'loadDestinationsUsers'
        });
      }
    },
    loadDestinationsGuestCustomers({ commit }) {
      try {
        const guestCustomerId = this.state.user.guestCustomerId;

        this.state.snapshots.destinationsUsers = db
          .collection('destinations_active_users')
          .where('destinationStatus', 'in', ['Open'])
          .where('customerId', '==', guestCustomerId)
          .onSnapshot(snapshot => {
            const activeDestinationsUsers = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));
            if (this.state.user) {
              commit('SET_ACTIVE_DESTINATIONS_USERS', activeDestinationsUsers);
            }
          });
      } catch (err) {
        const errRef = db.collection('errors').doc();
        errRef.set({
          err: JSON.stringify(err),
          user: this.state.user,
          codeBlock: 'loadDestinationsGuestUsers'
        });
      }
    },
    loadDestinationsGuestUsers({ commit }) {
      try {
        const guestUserId = this.state.user.guestUserId;

        this.state.snapshots.destinationsUsers = db
          .collection('destinations_active_users')
          .where('destinationStatus', 'in', ['Open'])
          .where('allowGuestUsers', '==', true)
          .where('guestUsers', 'array-contains', guestUserId)
          .onSnapshot(snapshot => {
            const activeDestinationsUsers = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));
            if (this.state.user) {
              commit('SET_ACTIVE_DESTINATIONS_USERS', activeDestinationsUsers);
            }
          });
      } catch (err) {
        const errRef = db.collection('errors').doc();
        errRef.set({
          err: JSON.stringify(err),
          user: this.state.user,
          codeBlock: 'loadDestinationsGuestUsers'
        });
      }
    },
    loadSources({ commit }) {
      try {
        this.state.snapshots.sources = db
          .collection('sources_active')
          .where('sourceStatus', 'in', ['Open', 'Waiting'])
          .onSnapshot(snapshot => {
            const activeSources = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));

            if (this.state.user) {
              commit('SET_ACTIVE_SOURCES', activeSources);
            }
          });
      } catch (err) {
        const errRef = db.collection('errors').doc();
        errRef.set({
          err: JSON.stringify(err),
          user: this.state.user,
          codeBlock: 'loadSources'
        });
      }
    },
    loadSourcesUsers({ commit }) {
      try {
        const isAdmin = this.state.user.admin || this.state.user.superAdmin;
        this.state.snapshots.sourcesUsers = db
          .collection('sources_active_users')
          .where('sourceStatus', 'in', ['Open', 'Waiting'])
          .onSnapshot(snapshot => {
            const activeSourcesUsers = snapshot.docs
              .map(doc => ({
                id: doc.id,
                ...doc.data()
              }))
              .filter(source => {
                return source.sourceStatus === 'Open' || isAdmin;
              });

            if (this.state.user) {
              commit('SET_ACTIVE_SOURCES_USERS', activeSourcesUsers);
            }
          });

        const dates = lastWeekDates();

        this.state.snapshots.sourcesArchivedUsers = db
          .collection('sources_archived_users')
          .where('settlementDate', '>=', dates.start)
          .where('settlementDate', '<=', dates.end)
          .orderBy('settlementDate')
          .onSnapshot(snapshot => {
            const sourcesArchivedUsers = [];
            snapshot.forEach(doc => {
              sourcesArchivedUsers.push(doc.data());
            });
            if (this.state.user) {
              commit('SET_ARCHIVED_SOURCES_USERS', sourcesArchivedUsers);
            }
          });
      } catch (err) {
        const errRef = db.collection('errors').doc();
        errRef.set({
          err: JSON.stringify(err),
          user: this.state.user,
          codeBlock: 'loadSettings'
        });
      }
    },
    loadSourcesGuestCustomers({ commit }) {
      try {
        const guestCustomerId = this.state.user.guestCustomerId;

        this.state.snapshots.sourcesUsers = db
          .collection('sources_active_users')
          .where('sourceStatus', 'in', ['Open'])
          .where('customerId', '==', guestCustomerId)
          .onSnapshot(snapshot => {
            const activeSourcesUsers = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));
            if (this.state.user) {
              commit('SET_ACTIVE_SOURCES_USERS', activeSourcesUsers);
            }
          });

        const dates = lastWeekDates();

        this.state.snapshots.sourcesArchivedUsers = db
          .collection('sources_archived_users')
          .where('settlementDate', '>=', dates.start)
          .where('settlementDate', '<=', dates.end)
          .where('sourceStatus', 'in', ['Open'])
          .where('customerId', '==', guestCustomerId)
          .orderBy('settlementDate')
          .onSnapshot(snapshot => {
            const sourcesArchivedUsers = [];
            snapshot.forEach(doc => {
              sourcesArchivedUsers.push(doc.data());
            });
            if (this.state.user) {
              commit('SET_ARCHIVED_SOURCES_USERS', sourcesArchivedUsers);
            }
          });
      } catch (err) {
        const errRef = db.collection('errors').doc();
        errRef.set({
          err: JSON.stringify(err),
          user: this.state.user,
          codeBlock: 'loadGuestCustomers'
        });
      }
    },
    loadSourcesGuestUsers({ commit }) {
      try {
        const guestUserId = this.state.user.guestUserId;

        this.state.snapshots.sourcesUsers = db
          .collection('sources_active_users')
          .where('sourceStatus', 'in', ['Open'])
          .where('allowGuestUsers', '==', true)
          .where('guestUsers', 'array-contains', guestUserId)
          .onSnapshot(snapshot => {
            const activeSourcesUsers = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));
            if (this.state.user) {
              commit('SET_ACTIVE_SOURCES_USERS', activeSourcesUsers);
            }
          });

        const dates = lastWeekDates();

        this.state.snapshots.sourcesArchivedUsers = db
          .collection('sources_archived_users')
          .where('settlementDate', '>=', dates.start)
          .where('settlementDate', '<=', dates.end)
          .where('sourceStatus', 'in', ['Open'])
          .where('allowGuestUsers', '==', true)
          .where('guestUsers', 'array-contains', guestUserId)
          .orderBy('settlementDate')
          .onSnapshot(snapshot => {
            const sourcesArchivedUsers = [];
            snapshot.forEach(doc => {
              sourcesArchivedUsers.push(doc.data());
            });
            if (this.state.user) {
              commit('SET_ARCHIVED_SOURCES_USERS', sourcesArchivedUsers);
            }
          });
      } catch (err) {
        const errRef = db.collection('errors').doc();
        errRef.set({
          err: JSON.stringify(err),
          user: this.state.user,
          codeBlock: 'loadGuestUsers'
        });
      }
    },
    loadSettings({ commit }) {
      try {
        const settings = {};
        this.state.snapshots.settings = db
          .collection('settings')
          .onSnapshot(snapshot => {
            snapshot.forEach(doc => {
              if (doc.id === 'settings') {
                settings.settings = doc.data();
              } else if (doc.id === 'customer_list') {
                settings.customerList = doc.data();
              } else if (doc.id === 'guest_user_list') {
                settings.guestUserList = doc.data();
              } else if (doc.id === 'guest_customer_list') {
                settings.guestCustomerList = doc.data();
              }
            });

            if (this.state.user) {
              commit('SET_SETTINGS', settings);
            }
          });
      } catch (err) {
        const errRef = db.collection('errors').doc();
        errRef.set({
          err: JSON.stringify(err),
          user: this.state.user,
          codeBlock: 'loadSettings'
        });
      }
    },
    loadSettingsUsers({ commit, dispatch }, isAdmin) {
      try {
        this.state.snapshots.settingsUsers = db
          .collection('settings_users')
          .onSnapshot(snapshot => {
            let settings;
            let customerList;
            let userList;
            let currentClockins;
            for (let i = 0; i < snapshot.docs.length; i++) {
              if (snapshot.docs[i].id === 'settings') {
                settings = snapshot.docs[i].data();
              } else if (snapshot.docs[i].id === 'customer_list') {
                customerList = snapshot.docs[i].data();
              } else if (snapshot.docs[i].id === 'user_list') {
                userList = snapshot.docs[i].data();
              } else if (snapshot.docs[i].id === 'current_clockins') {
                currentClockins = snapshot.docs[i].data();
              }
            }

            const settingsUsers = {
              settings: settings,
              customerList: customerList,
              userList: userList,
              currentClockins: currentClockins,
              isAdmin: isAdmin
            };

            // Get current clockin with userId
            if (!this.state.user.superCustomer && !this.state.user.customer) {
              dispatch('loadCurrentClockins', settingsUsers.userList);
            }

            if (this.state.user) {
              commit('SET_SETTINGS_USERS', settingsUsers);
            }
          });

        this.state.snapshots.settingsUsersWritable = db
          .collection('settings_users_writable')
          .onSnapshot(snapshot => {
            let lastHauls;

            for (let i = 0; i < snapshot.docs.length; i++) {
              if (snapshot.docs[i].id === 'last_hauls') {
                lastHauls = snapshot.docs[i].data();
              }
            }

            if (this.state.user) {
              commit('SET_SETTINGS_USERS_WRITABLE', lastHauls);
            }
          });
      } catch (err) {
        console.log(err);

        const errRef = db.collection('errors').doc();
        errRef.set({
          err: JSON.stringify(err),
          user: this.state.user,
          codeBlock: 'loadSettingsUsers'
        });
      }
    },
    loadMasterLocations({ commit }) {
      try {
        const locations = {};
        this.state.snapshots.masterLocations = db
          .collection('master_locations')
          .onSnapshot(snapshot => {
            locations.masterSources = {};
            locations.masterSplitSources = {};
            locations.masterDestinations = {};
            locations.masterSplitDestinations = {};
            snapshot.forEach(doc => {
              if (doc.id.startsWith('master_sources')) {
                const locationObj = doc.data();
                locations.masterSplitSources[doc.id] = locationObj;
                locations.masterSources = {
                  ...locationObj,
                  ...locations.masterSources
                };
              } else if (doc.id.startsWith('master_destinations')) {
                const locationObj = doc.data();
                locations.masterSplitDestinations[doc.id] = locationObj;
                locations.masterDestinations = {
                  ...locationObj,
                  ...locations.masterDestinations
                };
              }
            });

            if (this.state.user) {
              commit('SET_MASTER_LOCATIONS', locations);
            }
          });
      } catch (err) {
        const errRef = db.collection('errors').doc();
        errRef.set({
          err: JSON.stringify(err),
          user: this.state.user,
          codeBlock: 'loadMasterLocations'
        });
      }
    },
    setHaulShortcut({ commit }, shortcut) {
      commit('SET_HAUL_SHORTCUT', shortcut);
    },
    setHeaderBtns({ commit }, headerBtns) {
      commit('SET_HEADER_BTNS', headerBtns);
    },
    setHeaderBtnsDisplay({ commit }, headerBtnsDisplay) {
      commit('SET_HEADER_BTNS_DISPLAY', headerBtnsDisplay);
    },
    setImportantNoteIds({ commit }, noteIdObj) {
      commit('SET_IMPORTANT_NOTE_IDS', noteIdObj);
    },
    setMapPin({ commit }, mapPin) {
      commit('SET_MAP_PIN', mapPin);
    },
    setUser({ commit, dispatch }) {
      try {
        const userObj = {};
        const user = firebase.auth().currentUser;

        if (user) {
          userObj.displayName = user.displayName;
          userObj.email = user.email;

          firebase
            .auth()
            .currentUser.getIdTokenResult()
            .then(result => {
              userObj.superAdmin = result.claims.superAdmin;
              userObj.admin = result.claims.admin;
              userObj.superUser = result.claims.superUser;
              userObj.user = result.claims.user;
              userObj.guestUser = result.claims.guestUser;
              // customer may be undefined
              userObj.superCustomer = result.claims.superCustomer || false;
              userObj.customer = result.claims.customer || false;
              userObj.visitor = result.claims.visitor;

              if (userObj.guestUser) {
                userObj.guestUserId = result.claims.guestUserId;
              }

              if (userObj.superCustomer || userObj.customer) {
                userObj.guestCustomerId = result.claims.guestCustomerId;
              }

              commit('SET_USER', userObj);
              commit('SET_AUTH_CHECKED', true);

              if (userObj.superAdmin || userObj.admin) {
                dispatch('loadSettings');
                dispatch('loadSettingsUsers', true);
                dispatch('loadMasterLocations');
                dispatch('setBalOrHauled', { balOrHauled: null, init: true });
                dispatch('loadDestinations');
                dispatch('loadSources');
                dispatch('loadSourcesUsers');
                dispatch('loadDestinationsUsers');
              } else {
                dispatch('loadSettingsUsers', false);
                dispatch('loadMasterLocations');
                dispatch('setBalOrHauled', { balOrHauled: null, init: true });
              }

              if (userObj.superUser || userObj.user) {
                dispatch('loadDestinationsUsers');
                dispatch('loadSourcesUsers');
              }

              if (userObj.guestUser) {
                dispatch('loadDestinationsGuestUsers');
                dispatch('loadSourcesGuestUsers');
              }

              if (userObj.superCustomer || userObj.customer) {
                dispatch('loadDestinationsGuestCustomers');
                dispatch('loadSourcesGuestCustomers');
              }

              dispatch('setUserData', null);
            });
        } else {
          if (router.currentRoute.name !== 'Account') {
            router.push({ name: 'Home' });
          }
          const settings = {
            customerList: [],
            masterSources: [],
            masterDestinations: [],
            settings: {
              destinationSettings: {
                defaultApplicationRate: 0,
                defaultApplicationType: 'variable',
                defaultChargeCommodity: true,
                defaultPricePerUnit: 0,
                defaultTrackMileage: false,
                defaultMileageRate: 0,
                projectedPlaceholder: 0,
                projectedTolerance: 0,
                services: [],
                haulTrackers: []
              },
              sourceSettings: {
                projectedPlaceholder: 0,
                projectedTolerance: 0,
                defaultPurchaseCommodity: true,
                defaultPricePerUnit: 0,
                services: [],
                haulTrackers: []
              },
              general: {
                destAs: 'Destinations',
                sReportNumber: null,
                commodities: []
              }
            },
            userList: {},
            userListIds: {}
          };

          const settingsUsers = {
            settings: {
              appVersion: null,
              commodites: [],
              destAs: 'Destinations',
              destinationSettings: {},
              sourceSettings: {},
              workTypes: {}
            },
            lastHauls: [],
            user_list: {},
            customerList: []
          };
          commit('SET_USER', null);
          commit('SET_AUTH_CHECKED', true);
          commit('SET_ACTIVE_DESTINATIONS', []);
          commit('SET_ACTIVE_DESTINATIONS_USERS', []);
          commit('SET_ACTIVE_SOURCES', []);
          commit('SET_ACTIVE_SOURCES_USERS', []);
          commit('SET_SETTINGS', settings);
          commit('SET_SETTINGS_USERS', settingsUsers);
        }
      } catch (err) {
        const errRef = db.collection('errors').doc();
        errRef.set({
          err: JSON.stringify(err),
          user: this.state.user,
          codeBlock: 'setUser'
        });
      }
    },
    setUserData({ commit }, data) {
      commit('SET_USER_DATA', data);
    },
    unsubscribe() {
      for (const key in this.state.snapshots) {
        this.state.snapshots[key]();
      }
    }
  },
  modules: {}
});
