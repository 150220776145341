<template>
  <div class="div-container-2">
    <ConfirmDialog
      v-if="confirmDelete"
      icon="analytics"
      iconColor="primary"
      :btn="btn"
      :message="confirmMessage"
      :headerMessage="confirmHeaderMessage"
      @canceled="confirmDelete = false"
      @confirmed="deleteTracker"
    />

    <OkDialog
      v-if="okDialog"
      icon="analytics"
      :message="okMessage"
      @okConfirmed="okDialog = false"
    />

    <div class="row q-pa-sm q-mt-xl">
      <q-space />
      <q-btn
        v-if="displayCreateNew"
        outline
        icon-right="list"
        label="List"
        color="primary"
        padding="xs md"
        @click="listTrackers"
      />
      <q-btn
        v-if="!displayCreateNew"
        outline
        icon-right="add"
        label="Add"
        color="primary"
        padding="xs md"
        @click="createNew"
      />
    </div>

    <div class="row q-mx-sm q-mt-xs">
      <div
        class="row justify-center items-center full-width rounded-borders text-custom-light q-mx-sm q-py-xs q-mb-md"
        style="background: radial-gradient(circle, #3336eb 0%, #0b0d6f 100%)"
      >
        <q-icon name="analytics" size="md" />
        <span class="text-body1 q-ml-sm"> Haul Trackers </span>
      </div>
    </div>

    <q-card v-if="!displayCreateNew" flat bordered class="q-ma-sm q-mb-md">
      <q-list separator>
        <q-item
          v-for="(tracker, index) in haulTrackers"
          :key="index"
          class="q-pa-md"
        >
          <q-item-section avatar>
            <q-avatar color="primary" size="md" text-color="white">
              {{ tracker.name.charAt(0) }}
            </q-avatar>
          </q-item-section>
          <q-item-section>
            {{ tracker.name }}
          </q-item-section>
          <q-item-section side class="cursor-pointer">
            <div>
              <q-btn
                outline
                size="md"
                padding="xs"
                color="custom-orange"
                icon="edit"
                class="q-mr-sm"
                @click="editTracker(index)"
              />
              <q-btn
                outline
                size="md"
                padding="xs"
                color="custom-red"
                icon="delete"
                @click="deleteDialog(index)"
              />
            </div>
          </q-item-section>
        </q-item>
      </q-list>
    </q-card>

    <q-form v-if="displayCreateNew" @submit="onSubmit">
      <q-card flat bordered class="q-pa-md q-ma-sm q-mb-md">
        <div class="row">
          <div class="row full-width">
            <q-checkbox
              v-model="isCalculator"
              label="Run Calculations"
              color="primary"
              size="md"
            />
          </div>
          <div v-if="isCalculator" class="row full-width">
            <q-checkbox
              v-model="requireNumInput"
              label="Require Driver Input (number)"
              color="primary"
              size="md"
            />
          </div>
          <div v-if="isCalculator" class="row full-width">
            <q-checkbox
              v-model="calcTotalAsHaulAmount"
              label="Calculated Total As Haul Amount"
              color="primary"
              size="md"
            />
          </div>
          <div v-if="!isCalculator" class="row full-width">
            <div class="row">
              <div class="row full-width">
                <q-checkbox
                  v-model="driverInput"
                  :label="checkboxLabel"
                  color="primary"
                  size="md"
                />
              </div>
              <div class="row full-width">
                <q-checkbox
                  v-if="driverInput"
                  v-model="numberInput"
                  label="Input as Number"
                  color="primary"
                  size="md"
                />
              </div>
            </div>
          </div>
        </div>
        <q-input
          v-model="newHaulTracker"
          outlined
          label="Enter Haul Tracker Name"
          label-color="primary"
          color="primary"
          lazy-rules
          hide-bottom-space
          :rules="[val => (val && val.trim().length > 0) || '']"
          class="q-mb-md"
        />
        <q-input
          v-if="isCalculator && requireNumInput && !calcTotalAsHaulAmount"
          v-model="calcAmountLabel"
          outlined
          label="Enter Label For Calculated Amount"
          label-color="primary"
          color="primary"
          lazy-rules
          hide-bottom-space
          class="q-mb-md"
          :rules="[val => (val && val.trim().length > 0) || '']"
        />
        <q-input
          v-if="isCalculator && calcTotalAsHaulAmount"
          v-model="haulAmountLabel"
          outlined
          label="Enter Label For Haul Amount"
          label-color="primary"
          color="primary"
          lazy-rules
          hide-bottom-space
          class="q-mb-md"
          :rules="[val => (val && val.trim().length > 0) || '']"
        />
        <q-input
          v-if="isCalculator"
          v-model="calcStr"
          outlined
          label="Enter Calculator String"
          label-color="primary"
          color="primary"
          lazy-rules
          hide-bottom-space
          class="q-mb-md"
          :rules="[val => (val && val.trim().length > 0) || '']"
        />
        <div v-if="isCalculator">
          <q-card
            v-if="!validateCalcStr"
            class="q-my-md q-pa-sm bg-custom-red text-white"
          >
            Invalid Calculator String
          </q-card>
          <q-btn
            v-if="!displayCalcInfo"
            label="Info"
            icon="info"
            color="white"
            class="text-black full-width"
            @click="displayCalcInfo = true"
          />
          <q-btn
            v-if="displayCalcInfo"
            label="Hide Info"
            icon="info"
            color="white"
            class="text-black full-width"
            @click="displayCalcInfo = false"
          />
          <div v-if="displayCalcInfo" class="q-mt-md">
            <div class="q-pt-md">
              <div class="row justify-center text-h5">
                Calculator String Info
              </div>
              <div
                class="text-primary text-center bg-grey-3 q-mt-lg q-py-sm q-px-sm"
              >
                Enter valid calculator symbols or numbers separated by
                whitespace. Symbols and Numbers must alternate.
              </div>
              <div class="q-py-md q-mt-md">
                <q-bar class="bg-grey-3"> Valid Caculator Symbols: </q-bar>
                <q-list bordered dense separator>
                  <q-item>
                    <q-item-section>
                      <q-item-label>+</q-item-label>
                      <q-item-label caption>(add)</q-item-label>
                    </q-item-section>
                  </q-item>

                  <q-item>
                    <q-item-section>
                      <q-item-label>-</q-item-label>
                      <q-item-label caption>(subtract)</q-item-label>
                    </q-item-section>
                  </q-item>

                  <q-item>
                    <q-item-section>
                      <q-item-label>*</q-item-label>
                      <q-item-label caption>(multiply)</q-item-label>
                    </q-item-section>
                  </q-item>

                  <q-item>
                    <q-item-section>
                      <q-item-label>/</q-item-label>
                      <q-item-label caption>(divide)</q-item-label>
                    </q-item-section>
                  </q-item>

                  <q-item>
                    <q-item-section>
                      <q-item-label>()</q-item-label>
                      <q-item-label caption
                        >(parentheses - items in parentheses will be calculated
                        first)
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </div>

              <div class="q-py-md">
                <q-list bordered dense separator>
                  <q-bar class="bg-grey-3"> Valid Caculator Numbers: </q-bar>
                  <q-item>
                    <q-item-section>
                      <q-item-label>Any Number</q-item-label>
                      <q-item-label caption
                        >(with or without decimel)</q-item-label
                      >
                    </q-item-section>
                  </q-item>

                  <q-item>
                    <q-item-section>
                      <q-item-label>Haul_Amount</q-item-label>
                      <q-item-label caption
                        >(placeholder for Haul Amount)
                        <br />
                        NOTE: This placeholder is required unless 'Require
                        Driver Input' is checked.
                        <br />
                        'Haul Amount' is the top input box in Enter Haul tab and
                        is used to calculate hauled percentages.</q-item-label
                      >
                    </q-item-section>
                  </q-item>

                  <q-item>
                    <q-item-section>
                      <q-item-label>Driver_Input</q-item-label>
                      <q-item-label caption
                        >(placeholder for Driver Input)
                        <br />
                        NOTE: This placeholder is required if 'Require Driver
                        Input' is checked.
                        <br />
                        'Driver Input' is referring to the driver input that
                        will be required if 'Require Driver Input' is
                        checked</q-item-label
                      >
                    </q-item-section>
                  </q-item>
                </q-list>
              </div>

              <div class="q-py-md">
                <q-list bordered dense separator>
                  <q-bar class="bg-grey-3"> Valid If Statement: </q-bar>
                  <q-item>
                    <q-item-section>
                      <q-item-label
                        >if ( Condition ) { Calculator String } else {
                        Calculator String }</q-item-label
                      >
                      <q-item-label caption
                        >(only supports greater than and less
                        than)</q-item-label
                      >
                    </q-item-section>
                  </q-item>
                </q-list>
              </div>

              <div class="q-pt-md">
                <q-bar class="bg-grey-3">Examples: </q-bar>
                <q-list bordered dense separator>
                  <q-item>
                    <q-item-section>
                      <q-item-label>Haul_Amount / 56</q-item-label>
                      <q-item-label caption
                        >(Lbs to bushels - corn)</q-item-label
                      >
                    </q-item-section>
                  </q-item>

                  <q-item>
                    <q-item-section>
                      <q-item-label
                        >Haul_Amount / 56 - ( ( Driver_Input - 15.5 ) * .015 * (
                        Haul_Amount / 56 ) )</q-item-label
                      >
                      <q-item-label caption>(shrinkage - corn)</q-item-label>
                    </q-item-section>
                  </q-item>

                  <q-item>
                    <q-item-section>
                      <q-item-label
                        >if ( Driver_Input > 15.5 ) { Haul_Amount / 56 - ( (
                        Driver_Input - 15.5 ) * .015 * ( Haul_Amount / 56 ) ) }
                        else { Haul_Amount / 56 }
                      </q-item-label>
                      <q-item-label caption
                        >(shrinkage - if greater than 15.5 - corn)</q-item-label
                      >
                    </q-item-section>
                  </q-item>
                </q-list>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!driverInput && !isCalculator">
          <q-input
            v-for="(option, index) in haulTrackerOptions"
            :key="index"
            v-model="option.option"
            outlined
            label="Enter Haul Tracker Option"
            label-color="primary"
            color="primary"
            lazy-rules
            hide-bottom-space
            class="q-pb-md"
            :rules="[val => (val && val.trim().length > 0) || '']"
          >
            <template v-slot:append>
              <q-icon
                name="clear"
                color="custom-red"
                class="cursor-pointer"
                @click="deleteOption(index)"
              >
              </q-icon>
            </template>
          </q-input>
        </div>
      </q-card>

      <div class="q-mt-sm q-mb-xl q-mr-sm" align="right">
        <q-btn
          v-if="!driverInput && !isCalculator"
          flat
          label="Option"
          icon="add"
          color="primary"
          padding="xs md"
          @click="addOption"
        />
        <q-btn
          label="Submit"
          icon="check"
          type="submit"
          color="primary"
          padding="xs md"
          class="q-ml-sm"
        />
      </div>
    </q-form>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import OkDialog from '@/components/OkDialog.vue';

const db = firebase.firestore();

export default {
  name: 'destinationLocations',
  components: {
    ConfirmDialog,
    OkDialog
  },
  props: {
    haulTrackers: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      addTracker: true,
      btn: {
        label: 'Delete',
        icon: 'delete',
        color: 'custom-red'
      },
      calcAmountLabel: '',
      calcTotalAsHaulAmount: false,
      calcStr: '',
      confirmDelete: false,
      confirmMessage: '',
      confirmHeaderMessage: '',
      displayCalcInfo: false,
      displayCreateNew: false,
      driverInput: false,
      haulAmountLabel: '',
      isCalculator: false,
      newHaulTracker: '',
      numberInput: false,
      haulTrackerOptions: [{ option: '' }],
      okDialog: false,
      okMessage: '',
      requireNumInput: false,
      selectedTrackerIndex: 0
    };
  },
  methods: {
    addOption() {
      this.haulTrackerOptions.push({ option: '' });
    },
    createNew() {
      this.newHaulTracker = '';
      this.haulAmountLabel = '';
      this.calcAmountLabel = '';
      this.calcStr = '';
      this.isCalculator = false;
      this.driverInput = false;
      this.numberInput = false;
      this.requireNumInput = false;
      this.calcTotalAsHaulAmount = false;
      this.displayCreateNew = true;
      this.addTracker = true;
    },
    deleteDialog(index) {
      this.confirmMessage = 'Are you sure you want to delete Haul Tracker?';
      this.confirmHeaderMessage = this.haulTrackers[index].name;
      this.confirmDelete = true;
      this.selectedTrackerIndex = index;
    },
    deleteOption(index) {
      this.haulTrackerOptions.splice(index, 1);
    },
    deleteTracker() {
      this.confirmDelete = false;
      const index = this.selectedTrackerIndex;

      const batch = db.batch();

      const settingsUsersDoc = db.collection('settings_users').doc('settings');

      batch.update(settingsUsersDoc, {
        'destinationSettings.haulTrackers':
          firebase.firestore.FieldValue.arrayRemove(this.haulTrackers[index])
      });

      batch.commit().catch(err => {
        console.log(err);
      });

      this.notify('Haul Tracker Deleted', 'custom-red');
      this.selectedTrackerIndex = 0;
    },
    editTracker(index) {
      this.selectedTrackerIndex = index;
      this.addTracker = false;
      this.newHaulTracker = this.haulTrackers[index].name;
      this.driverInput = this.haulTrackers[index].driverInput;
      this.numberInput = this.haulTrackers[index].numberInput;
      this.isCalculator = this.haulTrackers[index].calcInfo.runCalc;
      this.requireNumInput = this.haulTrackers[index].calcInfo.requireNumInput;
      this.calcTotalAsHaulAmount =
        this.haulTrackers[index].calcInfo.calcTotalAsHaulAmount;
      this.haulAmountLabel = this.haulTrackers[index].calcInfo.haulAmountLabel;
      this.calcAmountLabel = this.haulTrackers[index].calcInfo.calcAmountLabel;
      this.isCalculator = this.haulTrackers[index].calcInfo.runCalc;
      this.displayCreateNew = true;

      this.calcStr = this.haulTrackers[index].calcInfo.calcStr
        .split('|')
        .join(' ');

      this.haulTrackerOptions.length = 0;
      for (let i = 0; i < this.haulTrackers[index].options.length; i++) {
        this.haulTrackerOptions.push({
          option: this.haulTrackers[index].options[i]
        });
      }
    },
    listTrackers() {
      this.haulTrackerOptions = [{ option: '' }];
      this.displayCreateNew = false;
    },
    notify(message, color) {
      this.$q.notify({
        color: color,
        textColor: 'white',
        icon: 'analytics',
        message: message
      });
    },
    onActivateTracker(tracker, options) {
      for (let i = 0; i < this.haulTrackers.length; i++) {
        if (this.haulTrackers[i].name === tracker && this.addTracker === true) {
          this.okMessage = `${tracker} already exists!`;
          this.okDialog = true;
          return;
        }
      }

      if (!this.validateCalcStr) {
        this.okMessage = `Please enter a valid Calculator String`;
        this.okDialog = true;
        return;
      }

      if (options.length < 1 && !this.driverInput && !this.isCalculator) {
        this.okMessage = `Please enter an option`;
        this.okDialog = true;
        return;
      }

      const validatedOptions = this.driverInput ? [] : options;

      let calcStr = this.calcStr.trim().split(/\s+/).join('|');
      calcStr = this.isCalculator ? calcStr : '';
      this.driverInput = this.isCalculator ? false : this.driverInput;
      this.numberInput = !this.driverInput ? false : this.numberInput;

      if (!this.isCalculator) {
        this.haulAmountLabel = '';
        this.calcAmountLabel = '';
        this.calcStr = '';
        this.requireNumInput = false;
        this.calcTotalAsHaulAmount = false;
      }

      if (
        !(
          this.isCalculator &&
          this.requireNumInput &&
          !this.calcTotalAsHaulAmount
        )
      ) {
        this.calcAmountLabel = '';
      }

      if (!(this.isCalculator && this.calcTotalAsHaulAmount)) {
        this.haulAmountLabel = '';
      }

      const newHaulTracker = {
        name: tracker,
        driverInput: this.driverInput,
        numberInput: this.numberInput,
        defaultChecked: false,
        options: validatedOptions,
        calcInfo: {
          calcStr: calcStr,
          calcTotalAsHaulAmount: this.calcTotalAsHaulAmount,
          haulAmountLabel: this.haulAmountLabel,
          calcAmountLabel: this.calcAmountLabel,
          requireNumInput: this.requireNumInput,
          runCalc: this.isCalculator
        }
      };

      const batch = db.batch();

      const trackerUsersDoc = db.collection('settings_users').doc('settings');

      if (this.addTracker === false) {
        const index = this.selectedTrackerIndex;

        batch.update(trackerUsersDoc, {
          'destinationSettings.haulTrackers':
            firebase.firestore.FieldValue.arrayRemove(this.haulTrackers[index])
        });
      }

      batch.update(trackerUsersDoc, {
        'destinationSettings.haulTrackers':
          firebase.firestore.FieldValue.arrayUnion(newHaulTracker)
      });

      batch.commit().catch(err => {
        console.log(err);
      });

      this.listTrackers();
      this.notify('Haul Tracker Added', 'primary');
    },
    onSubmit() {
      let tracker = this.newHaulTracker;
      tracker = tracker.trim();
      tracker = tracker.replaceAll('.', '');
      tracker = tracker.replace(/(^\w{1})|(\s+\w{1})/g, letter =>
        letter.toUpperCase()
      );

      this.haulAmountLabel = this.haulAmountLabel.trim();
      this.haulAmountLabel = this.haulAmountLabel.replaceAll('.', '');
      this.haulAmountLabel = this.haulAmountLabel.replace(
        /(^\w{1})|(\s+\w{1})/g,
        letter => letter.toUpperCase()
      );

      this.calcAmountLabel = this.calcAmountLabel.trim();
      this.calcAmountLabel = this.calcAmountLabel.replaceAll('.', '');
      this.calcAmountLabel = this.calcAmountLabel.replace(
        /(^\w{1})|(\s+\w{1})/g,
        letter => letter.toUpperCase()
      );

      const options = [];
      for (let i = 0; i < this.haulTrackerOptions.length; i++) {
        let option = this.haulTrackerOptions[i].option;
        option = option.trim();
        option = option.replaceAll('.', '');
        option = option.replace(/(^\w{1})|(\s+\w{1})/g, letter =>
          letter.toUpperCase()
        );
        options.push(option);
      }
      this.onActivateTracker(tracker, options);
    }
  },
  computed: {
    checkboxLabel() {
      return this.numberInput
        ? 'Require Driver Input (number) (no calculations)'
        : 'Require Driver Input (text) (no calculations)';
    },
    validateCalcStr() {
      const checkValidNum = n => {
        // Attempt to convert to num
        const num = +n;

        const isValidNum =
          (Number(num) === num && num % 1 === 0) ||
          (Number(num) === num && num % 1 !== 0) ||
          n === 'Haul_Amount' ||
          n === 'Driver_Input';

        return isValidNum;
      };

      const checkValidSym = sym => {
        return (
          sym === '+' ||
          sym === '-' ||
          sym === '*' ||
          sym === '/' ||
          sym === '(' ||
          sym === ')'
        );
      };

      if (this.calcStr !== '') {
        const checkArr = (calcArr, requiresDriverInput = true) => {
          const validSymOrNum = calcArr.every(symOrNum => {
            return checkValidSym(symOrNum) || checkValidNum(symOrNum);
          });

          let firstParens = 0;
          let validParens = calcArr.every(symOrNum => {
            if (symOrNum === '(' && firstParens >= 0) {
              firstParens++;
              if (firstParens > 3) {
                return false;
              } else {
                return true;
              }
            } else if (symOrNum === ')' && firstParens <= 0) {
              firstParens--;
              return false;
            } else if (symOrNum === ')' && firstParens > 0) {
              firstParens--;
              return true;
            } else {
              return true;
            }
          });
          validParens = firstParens === 0 ? validParens : false;

          let isNum = false;
          let startCalc = true;
          let validAlternate = false;
          if (validParens && validSymOrNum) {
            validAlternate = calcArr.every(symOrNum => {
              if (symOrNum === '(' || symOrNum === ')') {
                return true;
              }
              if (startCalc) {
                if (checkValidNum(symOrNum)) {
                  startCalc = false;
                  isNum = true;
                  return true;
                } else {
                  return false;
                }
              } else {
                if (isNum) {
                  if (checkValidSym(symOrNum)) {
                    startCalc = false;
                    isNum = false;
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  if (checkValidNum(symOrNum)) {
                    startCalc = false;
                    isNum = true;
                    return true;
                  } else {
                    return false;
                  }
                }
              }
            });

            // Make sure last item is num
            validAlternate =
              checkValidNum(calcArr[calcArr.length - 1]) ||
              calcArr[calcArr.length - 1] === ')'
                ? validAlternate
                : false;
          }

          const hasHaulAmount = calcArr.find(symOrNum => {
            return symOrNum === 'Haul_Amount';
          });
          let hasDriverInput = calcArr.find(symOrNum => {
            return symOrNum === 'Driver_Input';
          });
          if (!requiresDriverInput) {
            hasDriverInput = true;
          }
          let hasNumToOperate = hasHaulAmount;
          if (this.requireNumInput) {
            hasNumToOperate = hasDriverInput;
          } else {
            hasNumToOperate = !hasDriverInput && hasHaulAmount;
          }

          return (
            validSymOrNum && validParens && validAlternate && hasNumToOperate
          );
        };

        const calcArr = this.calcStr.trim().split(/\s+/);

        if (calcArr[0] === 'if') {
          const endFirstCalcStr = calcArr.findIndex(el => el === '}');
          if (calcArr.length < 11 || calcArr.length < endFirstCalcStr + 5) {
            return false;
          } else {
            if (
              calcArr[1] !== '(' ||
              calcArr[5] !== ')' ||
              calcArr[6] !== '{'
            ) {
              return false;
            }

            if (!checkValidNum(calcArr[2]) || !checkValidNum(calcArr[4])) {
              return false;
            }

            if (!(calcArr[3] === '<' || calcArr[3] === '>')) {
              return false;
            }

            if (!endFirstCalcStr) {
              return false;
            } else {
              if (calcArr[endFirstCalcStr + 1] !== 'else') {
                return false;
              }

              if (calcArr[endFirstCalcStr + 2] !== '{') {
                return false;
              }

              const firstStr = calcArr.slice(7).slice(0, endFirstCalcStr - 7);
              if (!checkArr(firstStr)) {
                return false;
              }

              const secondStr = calcArr.slice(endFirstCalcStr + 3).slice(0, -1);

              if (!checkArr(secondStr, false)) {
                return false;
              }
            }

            return true;
          }
        } else {
          return checkArr(calcArr);
        }
      } else {
        return true;
      }
    }
  }
};
</script>

<style scoped></style>
