<template>
  <q-dialog v-model="dateRangeDisplay" persistent>
    <q-card class="q-pa-lg">
      <div class="row justify-center q-pa-sm">
        <div v-if="selectNotice" class="text-custom-red">
          Please Select Date Range
        </div>
        <div v-if="calDisplay">
          <q-date
            flat
            bordered
            text-color="white"
            v-model="dateRange"
            color="primary"
            range
            :landscape="$q.screen.gt.xs"
            @input="dateChange"
          />
        </div>

        <q-card
          flat
          bordered
          class="row justify-center full-width q-pa-sm q-mt-xl"
        >
          <div class="text-body1 text-bold">
            {{ defaultDatesHeader }}
          </div>
          <div>
            <q-radio
              v-model="defaultDatesVal"
              val="two"
              label="Current Week + Last Week"
              color="custom-red"
              class="full-width"
              @input="setDefaults"
            />
            <q-radio
              v-model="defaultDatesVal"
              val="one"
              label="Current Week"
              color="custom-red"
              class="full-width"
              @input="setDefaults"
            />
          </div>
        </q-card>

        <div class="row full-width q-mt-lg">
          <q-space />
          <q-btn
            flat
            icon="clear"
            label="Cancel"
            color="primary"
            class="q-mr-sm"
            padding="xs md"
            @click="cancel"
          />
          <q-btn
            icon="check"
            label="Set Dates"
            color="primary"
            padding="xs md"
            @click="selectDates()"
          />
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { date } from 'quasar';

export default {
  name: 'DateRange',
  props: {
    dates: {
      type: Object
    },
    datesProvided: {
      type: Boolean,
      required: true
    },
    defaultDates: {
      type: String,
      default: 'x'
    },
    defaultDatesHeader: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dateRange: {},
      dateRangeDisplay: true,
      defaultDatesVal: 'two',
      calDisplay: true,
      selectNotice: false
    };
  },
  mounted() {
    this.setInitDates();
    this.defaultDatesVal = this.defaultDates;
  },
  methods: {
    cancel() {
      this.$emit('onCancel');
    },
    dateChange() {
      this.defaultDatesVal = 'x';
    },
    selectDates() {
      const singleDate = date.isValid(this.dateRange);
      if (singleDate) {
        this.dateRange = {
          from: this.dateRange,
          to: this.dateRange
        };
      }

      if (!this.dateRange) {
        this.selectNotice = true;
        return;
      } else {
        const toIsValid = date.isValid(this.dateRange.to);
        const fromIsValid = date.isValid(this.dateRange.from);
        if (!toIsValid || !fromIsValid) {
          this.selectNotice = true;
          return;
        }
      }

      const from = parseInt(date.formatDate(this.dateRange.from, 'x'));
      // Set begginning of next day
      let to = date.addToDate(this.dateRange.to, { days: 1 });
      to = parseInt(date.formatDate(to, 'x'));

      this.$emit('onSelectDates', from, to, this.defaultDatesVal);
    },
    setDefaults() {
      let numDays = 14;
      if (this.defaultDatesVal === 'one') {
        numDays = 7;
      }

      const startToday = new Date().setHours(0, 0, 0, 0);
      let dayOfWeek = date.getDayOfWeek(startToday);
      // If Sunday
      dayOfWeek = dayOfWeek === 7 ? 0 : dayOfWeek;

      const from = date.subtractFromDate(startToday, {
        days: dayOfWeek + (numDays - 7)
      });
      const to = date.subtractFromDate(startToday, {
        days: dayOfWeek - 6
      });

      // This is necessary if user has unselected date range
      if (!this.dateRange) {
        this.dateRange = {};
      }

      this.dateRange.from = date.formatDate(from, 'YYYY/MM/DD');
      this.dateRange.to = date.formatDate(to, 'YYYY/MM/DD');

      // Seems to be necessary to reset dates
      this.calDisplay = false;
      this.$nextTick(() => {
        this.calDisplay = true;
      });
    },
    setInitDates() {
      if (this.datesProvided) {
        this.dateRange.from = this.dates.start;
        this.dateRange.from = date.formatDate(this.dates.start, 'YYYY/MM/DD');
        this.dateRange.to = date.formatDate(
          date.subtractFromDate(this.dates.end, { days: 1 }),
          'YYYY/MM/DD'
        );
      } else {
        const ts = Date.now();
        this.dateRange.to = date.formatDate(ts, 'YYYY/MM/DD');
        const monthAgo = date.subtractFromDate(ts, { month: 1 });
        this.dateRange.from = date.formatDate(monthAgo, 'YYYY/MM/DD');
      }
    }
  }
};
</script>

<style scoped></style>
