<template>
  <div class="div-container-2">
    <ConfirmDialog
      v-if="confirmDelete"
      icon="analytics"
      iconColor="primary"
      :btn="btn"
      :message="confirmMessage"
      :headerMessage="confirmHeaderMessage"
      @canceled="confirmDelete = false"
      @confirmed="deleteTracker"
    />

    <OkDialog
      v-if="okDialog"
      icon="analytics"
      :message="okMessage"
      @okConfirmed="okDialog = false"
    />

    <div class="row q-pa-sm q-mt-xl">
      <q-space />
      <q-btn
        v-if="displayCreateNew"
        outline
        icon-right="list"
        label="List"
        color="primary"
        padding="xs md"
        @click="listTrackers"
      />
      <q-btn
        v-if="!displayCreateNew"
        outline
        icon-right="add"
        label="Add"
        color="primary"
        padding="xs md"
        @click="createNew"
      />
    </div>

    <div class="row q-mx-sm q-mt-xs">
      <div
        class="row justify-center items-center full-width rounded-borders text-custom-light q-mx-sm q-py-xs q-mb-md"
        style="background: radial-gradient(circle, #3336eb 0%, #0b0d6f 100%)"
      >
        <q-icon name="analytics" size="md" />
        <span class="text-body1 q-ml-sm"> Haul Trackers </span>
      </div>
    </div>

    <q-card v-if="!displayCreateNew" flat bordered class="q-ma-sm q-mb-md">
      <q-list separator>
        <q-item
          v-for="(tracker, index) in haulTrackers"
          :key="index"
          class="q-pa-md"
        >
          <q-item-section avatar>
            <q-avatar color="primary" size="md" text-color="white">
              {{ tracker.name.charAt(0) }}
            </q-avatar>
          </q-item-section>
          <q-item-section>
            {{ tracker.name }}
          </q-item-section>
          <q-item-section side class="cursor-pointer">
            <div>
              <q-btn
                outline
                size="md"
                padding="xs"
                color="custom-orange"
                icon="edit"
                class="q-mr-sm"
                @click="editTracker(index)"
              />
              <q-btn
                outline
                size="md"
                padding="xs"
                color="custom-red"
                icon="delete"
                @click="deleteDialog(index)"
              />
            </div>
          </q-item-section>
        </q-item>
      </q-list>
    </q-card>

    <q-form v-if="displayCreateNew" @submit="onSubmit">
      <q-card flat bordered class="q-pa-md q-ma-sm q-mb-md">
        <div class="row">
          <div class="row full-width">
            <q-checkbox
              v-model="driverInput"
              :label="checkboxLabel"
              color="primary"
              size="md"
            />
          </div>
          <div class="row full-width">
            <q-checkbox
              v-if="driverInput"
              v-model="numberInput"
              label="Input as Number"
              color="primary"
              size="md"
            />
          </div>
        </div>
        <q-input
          v-model="newHaulTracker"
          outlined
          label="Enter Haul Tracker Name"
          label-color="primary"
          color="primary"
          lazy-rules
          hide-bottom-space
          :rules="[val => (val && val.trim().length > 0) || '']"
          class="q-mt-sm q-mb-md"
        />

        <div v-if="!driverInput">
          <q-input
            v-for="(option, index) in haulTrackerOptions"
            :key="index"
            v-model="option.option"
            outlined
            label="Enter Haul Tracker Option"
            label-color="primary"
            color="primary"
            lazy-rules
            hide-bottom-space
            class="q-pb-md"
            :rules="[val => (val && val.trim().length > 0) || '']"
          >
            <template v-slot:append>
              <q-icon
                name="clear"
                color="custom-red"
                class="cursor-pointer"
                @click="deleteOption(index)"
              >
              </q-icon>
            </template>
          </q-input>
        </div>
      </q-card>

      <div class="q-mt-sm q-mb-xl q-mr-sm" align="right">
        <q-btn
          v-if="!driverInput"
          flat
          label="Option"
          icon="add"
          color="primary"
          padding="xs md"
          @click="addOption"
        />
        <q-btn
          label="Submit"
          icon="check"
          type="submit"
          color="primary"
          padding="xs md"
          class="q-ml-sm"
        />
      </div>
    </q-form>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import OkDialog from '@/components/OkDialog.vue';

const db = firebase.firestore();

export default {
  name: 'sourceHaulTrackers',
  components: {
    ConfirmDialog,
    OkDialog
  },
  props: {
    haulTrackers: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      addTracker: true,
      btn: {
        label: 'Delete',
        icon: 'delete',
        color: 'custom-red'
      },
      confirmDelete: false,
      confirmMessage: '',
      displayCreateNew: false,
      driverInput: false,
      newHaulTracker: '',
      numberInput: false,
      haulTrackerOptions: [{ option: '' }],
      okDialog: false,
      okMessage: '',
      selectedTrackerIndex: 0
    };
  },
  methods: {
    addOption() {
      this.haulTrackerOptions.push({ option: '' });
    },
    createNew() {
      this.newHaulTracker = '';
      this.driverInput = false;
      this.numberInput = false;
      this.displayCreateNew = true;
      this.addTracker = true;
    },
    deleteDialog(index) {
      this.confirmMessage = 'Are you sure you want to delete Haul Tracker?';
      this.confirmHeaderMessage = this.haulTrackers[index].name;
      this.confirmDelete = true;
      this.selectedTrackerIndex = index;
    },
    deleteOption(index) {
      this.haulTrackerOptions.splice(index, 1);
    },
    deleteTracker() {
      this.confirmDelete = false;
      const index = this.selectedTrackerIndex;

      const batch = db.batch();

      const settingsUsersDoc = db.collection('settings_users').doc('settings');

      batch.update(settingsUsersDoc, {
        'sourceSettings.haulTrackers':
          firebase.firestore.FieldValue.arrayRemove(this.haulTrackers[index])
      });

      batch.commit().catch(err => {
        console.log(err);
      });

      this.notify('Haul Tracker Deleted', 'custom-red');
      this.selectedTrackerIndex = 0;
    },
    editTracker(index) {
      this.selectedTrackerIndex = index;
      this.addTracker = false;
      this.newHaulTracker = this.haulTrackers[index].name;
      this.driverInput = this.haulTrackers[index].driverInput;
      this.numberInput = this.haulTrackers[index].numberInput;
      this.displayCreateNew = true;

      this.haulTrackerOptions.length = 0;
      for (let i = 0; i < this.haulTrackers[index].options.length; i++) {
        this.haulTrackerOptions.push({
          option: this.haulTrackers[index].options[i]
        });
      }
    },
    listTrackers() {
      this.haulTrackerOptions = [{ option: '' }];
      this.displayCreateNew = false;
    },
    notify(message, color) {
      this.$q.notify({
        color: color,
        textColor: 'white',
        icon: 'analytics',
        message: message
      });
    },
    onActivateTracker(tracker, options) {
      for (let i = 0; i < this.haulTrackers.length; i++) {
        if (this.haulTrackers[i].name === tracker && this.addTracker === true) {
          this.okMessage = `${tracker} already exists!`;
          this.okDialog = true;
          return;
        }
      }

      if (options.length < 1 && !this.driverInput) {
        this.okMessage = `Please enter an option`;
        this.okDialog = true;
        return;
      }

      const validatedOptions = this.driverInput ? [] : options;
      this.numberInput = !this.driverInput ? false : this.numberInput;

      const newHaulTracker = {
        name: tracker,
        driverInput: this.driverInput,
        numberInput: this.numberInput,
        defaultChecked: false,
        options: validatedOptions
      };

      const batch = db.batch();

      const trackerUsersDoc = db.collection('settings_users').doc('settings');

      if (this.addTracker === false) {
        const index = this.selectedTrackerIndex;

        batch.update(trackerUsersDoc, {
          'sourceSettings.haulTrackers':
            firebase.firestore.FieldValue.arrayRemove(this.haulTrackers[index])
        });
      }

      batch.update(trackerUsersDoc, {
        'sourceSettings.haulTrackers':
          firebase.firestore.FieldValue.arrayUnion(newHaulTracker)
      });

      batch.commit().catch(err => {
        console.log(err);
      });

      this.listTrackers();
      this.notify('Haul Tracker Added', 'primary');
    },
    onSubmit() {
      let tracker = this.newHaulTracker;
      tracker = tracker.trim();
      tracker = tracker.replaceAll('.', '');
      tracker = tracker.replace(/(^\w{1})|(\s+\w{1})/g, letter =>
        letter.toUpperCase()
      );

      const options = [];
      for (let i = 0; i < this.haulTrackerOptions.length; i++) {
        let option = this.haulTrackerOptions[i].option;
        option = option.trim();
        option = option.replaceAll('.', '');
        option = option.replace(/(^\w{1})|(\s+\w{1})/g, letter =>
          letter.toUpperCase()
        );
        options.push(option);
      }
      this.onActivateTracker(tracker, options);
    }
  },
  computed: {
    checkboxLabel() {
      return this.numberInput
        ? 'Require Driver Input (number)'
        : 'Require Driver Input (text)';
    }
  }
};
</script>

<style scoped></style>
