<template>
  <div class="full-width">
    <div
      style="display: flex; width: 100%; margin-bottom: 35px; font-size: 15px"
    >
      <div style="flex: 1">
        <div
          style="
            font-weight: bold;
            color: #cc0003;
            margin-bottom: 75px;
            padding-right: 130px;
          "
        >
          <div>
            {{ generalSettings.businessName }}
          </div>
          <div>
            {{ generalSettings.businessAddress }}
          </div>
          <div>
            {{ generalSettings.businessCityStZip }}
          </div>
        </div>
        <div
          style="
            padding: 20px 80px 20px 30px;
            border: 1px solid #c0c0c0;
            border-radius: 5px;
          "
        >
          <div>
            {{ address.name }}
          </div>
          <div>
            {{ address.streetAddress }}
          </div>
          <div>
            {{ address.cityStZip }}
          </div>
        </div>
      </div>
      <div style="flex: 1; font-size: 14px">
        <div
          style="
            margin-left: auto;
            width: 160px;
            text-align: center;
            border: 1px solid #c0c0c0;
            border-radius: 5px;
          "
        >
          <div>
            <div style="padding: 7px; border-bottom: 1px solid #c0c0c0">
              Settlement Report #
            </div>
            <div style="padding: 7px">{{ sReportNumber }}</div>
          </div>
        </div>
        <div
          style="
            margin-left: auto;
            width: 160px;
            text-align: center;
            margin-top: 31px;
            border: 1px solid #c0c0c0;
            border-radius: 5px;
          "
        >
          <div>
            <div style="padding: 7px; border-bottom: 1px solid #c0c0c0">
              Date
            </div>
            <div style="padding: 7px">
              {{ date }}
            </div>
          </div>
        </div>
        <div
          style="
            margin-left: auto;
            width: 160px;
            text-align: center;
            margin-top: 31px;
            border: 1px solid #c0c0c0;
            border-radius: 5px;
          "
        >
          <div>
            <div v-if="grandTotalRaw >= 0" style="padding: 7px">
              Due On Reciept
            </div>
            <div v-if="grandTotalRaw < 0" style="padding: 7px">Credit</div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="filterByDate">
      <div style="width: 100%; text-align: center; font-weight: bold">
        Settlement For Dates
      </div>
      <div style="width: 100%; text-align: center">
        {{ haulDates.from }} -
        {{ haulDates.to }}
      </div>
    </div>

    <div v-if="sReportType === 'itemized'">
      <div
        v-for="option in selectedOptionsFormatted"
        :key="option.locationName"
        class="no-break"
        style="
          border: 1px solid #c0c0c0;
          border-radius: 5px;
          margin-top: 35px;
          padding: 20px 30px 20px 30px;
        "
      >
        <div
          style="
            font-size: 20px;
            font-weight: bold;
            text-align: center;
            padding-bottom: 3px;
            margin-bottom: 20px;
            border-bottom: 1px solid #c0c0c0;
          "
        >
          {{ locationId(option.locationId, option.type) }}
        </div>

        <table style="width: 100%; border-bottom: none" class="top">
          <tr class="top text-md">
            <th colspan="1" class="left top" style="width: 40%">Description</th>
            <th colspan="1" class="right top" style="width: 30%">
              <span>Qty</span>
            </th>
            <th colspan="1" class="right top" style="width: 15%">
              <span>Rate</span>
            </th>
            <th class="right top" style="width: 15%">Total</th>
          </tr>

          <tr v-if="checkIfCommodity(option)" class="text-sm pd-sm">
            <td class="left" style="width: 40%">
              {{ option.commodity }}
            </td>
            <td class="right" style="width: 30%">
              {{ hauled(option) }} {{ option.MU }}
            </td>
            <td class="right" style="width: 15%">
              {{ formatPrice(option.pricePerUnit) }}
            </td>
            <td class="right" style="width: 15%; font-weight: bold">
              {{ formatPrice(option.pricePerUnit * hauled(option)) }}
            </td>
          </tr>

          <tr
            v-for="(service, index) in option.servicesNotPaired"
            :key="index"
            class="text-sm pd-sm"
          >
            <td class="left" style="width: 40%">{{ service.name }}</td>
            <td class="right" style="width: 30%">
              {{ servicesHauled(option) }} {{ option.MU }}
            </td>
            <td class="right" style="width: 15%">
              {{ formatPrice(service.price) }}
            </td>
            <td class="right" style="width: 15%; font-weight: bold">
              <span v-if="option.type === 'source'">-</span>
              {{ formatPrice(service.price * servicesHauled(option)) }}
            </td>
          </tr>

          <tr
            v-for="(service, index) in option.servicesPaired"
            :key="index + 50"
            class="text-sm pd-sm"
          >
            <td class="left" style="width: 40%">{{ service.serviceName }}</td>
            <td class="right" style="width: 30%">
              {{ service.serviceVal }} {{ service.infoBoxMU }}
            </td>
            <td class="right" style="width: 15%">
              {{ formatPrice(service.servicePrice) }}
            </td>
            <td class="right" style="width: 15%; font-weight: bold">
              <span v-if="option.type === 'source'">-</span>
              {{ formatPrice(service.serviceTotal) }}
            </td>
          </tr>

          <tr
            v-for="(service, index) in option.customServices"
            :key="index + 50"
            class="text-sm pd-sm"
          >
            <td class="left" style="width: 40%">{{ service.name }}</td>
            <td class="right" style="width: 30%">Price Per Ton</td>
            <td class="right" style="width: 15%">
              {{ formatPrice(service.price) }}
            </td>
            <td class="right" style="width: 15%; font-weight: bold">
              <span v-if="option.type === 'source'">-</span>
              {{ formatPrice(service.price * option.hauled) }}
            </td>
          </tr>

          <tr
            v-if="option.showMileage && option.showMiles"
            class="text-sm pd-sm"
          >
            <td class="left" style="width: 40%">Mileage</td>
            <td class="right" style="width: 30%">
              {{ option.totalMileage }} Miles
            </td>
            <td class="right" style="width: 15%">
              {{ formatPrice(option.mileageRate) }}
            </td>
            <td class="right" style="width: 15%; font-weight: bold">
              <span v-if="option.type === 'source'">-</span>
              {{ formatPrice(option.mileageRate * option.totalMileage) }}
            </td>
          </tr>

          <tr
            v-for="(charge, index) in option.customCharges"
            :key="index + 100"
            class="text-sm pd-sm"
          >
            <td class="left" style="width: 40%"></td>
            <td class="right" style="width: 30%">{{ charge.name }}</td>
            <td class="right" style="width: 15%">
              {{ formatPrice(charge.price) }}
            </td>
            <td class="right" style="width: 15%; font-weight: bold">
              <span v-if="option.type === 'source'">-</span>
              {{ formatPrice(charge.price) }}
            </td>
          </tr>

          <tr
            v-for="(credit, index) in option.customCredits"
            :key="index + 150"
            class="text-sm pd-sm"
          >
            <td class="left" style="width: 40%"></td>
            <td class="right" style="width: 25%">
              {{ credit.name }}
            </td>
            <td class="right" style="width: 15%">
              {{ formatPrice(credit.price) }}
            </td>
            <td class="right" style="width: 20%; font-weight: bold">
              <span v-if="option.type === 'destination'">+</span>
              {{ formatPrice(credit.price) }}
            </td>
          </tr>

          <tr class="text-sm pd-sm">
            <td class="left" style="width: 40%; border-bottom: none"></td>
            <td class="right" style="width: 30%; border-bottom: none"></td>
            <td
              class="right"
              style="width: 15%; font-weight: bold; border-bottom: none"
            >
              <span
                v-if="option.totalRaw >= 0"
                class="bg-grey-3 text-primary q-px-sm rounded-borders"
              >
                Balance Due
              </span>
              <span
                v-if="option.totalRaw < 0"
                class="bg-grey-3 text-custom-red q-px-sm rounded-borders"
              >
                Credit
              </span>
            </td>
            <td
              class="right"
              style="width: 15%; font-weight: bold; border-bottom: none"
            >
              {{ option.totalPrice }}
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div v-if="sReportType === 'condensed'">
      <div
        v-for="option in selectedOptionsFormatted"
        :key="option.locationName"
        class="no-break"
        style="
          border: 1px solid #c0c0c0;
          border-radius: 5px;
          margin-top: 35px;
          padding: 20px 30px 20px 30px;
        "
      >
        <div
          style="
            font-size: 20px;
            font-weight: bold;
            text-align: center;
            padding-bottom: 3px;
            margin-bottom: 20px;
            border-bottom: 1px solid #c0c0c0;
          "
        >
          {{ locationId(option.locationId, option.type) }}
        </div>
        <table style="width: 100%; border-bottom: none" class="top no-break">
          <tr class="top text-md">
            <th colspan="1" class="left top" style="width: 40%">Description</th>
            <th colspan="1" class="right top" style="width: 30%">
              <span>Qty</span>
            </th>
            <th colspan="1" class="right top" style="width: 15%">
              <span>Rate</span>
            </th>
            <th class="right top" style="width: 15%">Total</th>
          </tr>

          <tr v-if="option.serviceStr !== ''" class="text-sm pd-sm">
            <td class="left" style="width: 40%">{{ option.serviceStr }}</td>
            <td class="right" style="width: 30%">
              {{ option.hauled }} {{ option.MU }}
            </td>
            <td class="right" style="width: 15%">
              {{ formatPrice(option.condensedPrice) }}
            </td>
            <td class="right" style="width: 15%; font-weight: bold">
              <span v-if="option.type === 'source'"></span>
              {{ formatPrice(option.condensedTotal) }}
            </td>
          </tr>

          <tr
            v-for="(service, index) in option.servicesPaired"
            :key="index"
            class="text-sm pd-sm"
          >
            <td class="left" style="width: 40%">{{ service.serviceName }}</td>
            <td class="right" style="width: 30%">
              {{ service.serviceVal }} {{ service.infoBoxMU }}
            </td>
            <td class="right" style="width: 15%">
              {{ formatPrice(service.servicePrice) }}
            </td>
            <td class="right" style="width: 15%; font-weight: bold">
              <span v-if="option.type === 'source'">-</span>
              {{ formatPrice(service.serviceTotal) }}
            </td>
          </tr>

          <tr
            v-for="(service, index) in option.customServices"
            :key="index + 50"
            class="text-sm pd-sm"
          >
            <td class="left" style="width: 40%">{{ service.name }}</td>
            <td class="right" style="width: 30%">Price Per Ton</td>
            <td class="right" style="width: 15%">
              {{ formatPrice(service.price) }}
            </td>
            <td class="right" style="width: 15%; font-weight: bold">
              <span v-if="option.type === 'source'"></span>
              {{ formatPrice(service.price * option.hauled) }}
            </td>
          </tr>

          <tr
            v-if="option.showMileage && option.showMiles"
            class="text-sm pd-sm"
          >
            <td class="left" style="width: 40%">Mileage</td>
            <td class="right" style="width: 30%">
              {{ option.totalMileage }} Miles
            </td>
            <td class="right" style="width: 15%">
              {{ formatPrice(option.mileageRate) }}
            </td>
            <td class="right" style="width: 15%; font-weight: bold">
              <span v-if="option.type === 'source'">-</span>
              {{ formatPrice(option.mileageRate * option.totalMileage) }}
            </td>
          </tr>

          <tr
            v-for="(charge, index) in option.customCharges"
            :key="index + 100"
            class="text-sm pd-sm"
          >
            <td class="left" style="width: 40%"></td>
            <td class="right" style="width: 30%">{{ charge.name }}</td>
            <td class="right" style="width: 15%">
              {{ formatPrice(charge.price) }}
            </td>
            <td class="right" style="width: 15%; font-weight: bold">
              <span v-if="option.type === 'source'">-</span>
              {{ formatPrice(charge.price) }}
            </td>
          </tr>

          <tr
            v-for="(credit, index) in option.customCredits"
            :key="index + 150"
            class="text-sm pd-sm"
          >
            <td class="left" style="width: 40%"></td>
            <td class="right" style="width: 25%">
              {{ credit.name }}
            </td>
            <td class="right" style="width: 15%">
              {{ formatPrice(credit.price) }}
            </td>
            <td class="right" style="width: 20%; font-weight: bold">
              <span v-if="option.type === 'destination'">+</span>
              {{ formatPrice(credit.price) }}
            </td>
          </tr>

          <tr class="text-sm pd-sm">
            <td
              class="left"
              style="width: 40%; border-bottom: none; padding-bottom: 15px"
            ></td>
            <td
              class="right"
              style="
                width: 30%;
                font-weight: bold;
                border-bottom: none;
                padding-bottom: 15px;
              "
            ></td>
            <td
              class="right"
              style="
                width: 15%;
                font-weight: bold;
                border-bottom: none;
                padding-bottom: 15px;
              "
            >
              <span
                v-if="option.totalRaw >= 0"
                class="bg-grey-3 text-primary q-px-sm rounded-borders"
              >
                Amount
              </span>
              <span
                v-if="option.totalRaw < 0"
                class="bg-grey-3 text-custom-red q-px-sm rounded-borders"
              >
                Credit
              </span>
            </td>
            <td
              class="right"
              style="
                width: 15%;
                font-weight: bold;
                border-bottom: none;
                padding-bottom: 15px;
              "
            >
              {{ option.totalPrice }}
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div>
      <table
        style="width: 100%; margin-top: 50px; margin-bottom: 10px"
        class="top"
      >
        <tr class="top text-md">
          <th
            v-if="grandTotalRaw >= 0"
            colspan="1"
            class="right top"
            style="width: 82%"
          >
            Total Balance Due:
          </th>
          <th
            v-if="grandTotalRaw < 0"
            colspan="1"
            class="right top"
            style="width: 82%"
          >
            Total Credit:
          </th>
          <th
            colspan="1"
            class="right top"
            style="width: 18%; padding-right: 20px"
          >
            {{ grandTotal }}
          </th>
        </tr>
      </table>

      <div style="padding-left: 10px; font-size: 14px; white-space: pre-line">
        {{ notes.sReportNote }}
      </div>
    </div>
  </div>
</template>

<script>
import { formatPrice } from '@/utils/UtilHelpers';
import { date } from 'quasar';

export default {
  name: 'PrintTime',
  props: {
    selectedOptions: {
      type: Array,
      required: true
    },
    address: {
      type: Object,
      required: true
    },
    sReportType: {
      type: String,
      required: true
    },
    notes: {
      type: Object,
      required: true
    },
    grandTotal: {
      type: String,
      required: true
    },
    grandTotalRaw: {
      type: Number,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    sReportNumber: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      formatPrice,
      selectedOptionsFormatted: []
    };
  },
  created() {
    this.selectedOptionsFormatted = [];
    this.selectedOptionsFormatted = JSON.parse(
      JSON.stringify(this.selectedOptions)
    );

    const filterByDate = this.selectedOptions[0].filterByDate;
    this.filterByDate = filterByDate;

    if (filterByDate) {
      const haulDates = this.selectedOptions[0].haulDates;
      const to = date.subtractFromDate(new Date(haulDates.toTS), {
        days: 1
      });

      this.haulDates = {
        from: new Date(haulDates.fromTS).toDateString(),
        to: to.toDateString(),
        fromTS: haulDates.fromTS,
        toTS: haulDates.toTS
      };
    } else {
      this.haulDates = {
        to: '',
        from: '',
        toTS: '',
        fromTS: ''
      };
    }

    for (let i = 0; i < this.selectedOptionsFormatted.length; i++) {
      const option = this.selectedOptionsFormatted[i];

      let condensedPrice = 0;
      let serviceStr = '';
      let condensedTotal = 0;
      const hauledKey = option.filterByDate ? 'hauledFiltered' : 'hauled';

      if (option.type === 'destination') {
        if (option.chargeCommodity && option.showPricePerUnit) {
          condensedPrice = option.pricePerUnit;
          condensedTotal = option[hauledKey] * option.pricePerUnit;
          serviceStr += option.commodity + ' - ';
        }
      } else if (option.type === 'source') {
        if (option.purchaseCommodity && option.showPricePerUnit) {
          condensedPrice = option.pricePerUnit;
          condensedTotal = option[hauledKey] * option.pricePerUnit;
          serviceStr += option.commodity + ' - ';
        }
      }

      const servicesHauledKey = option.filterServicesByDate
        ? 'hauledFiltered'
        : 'hauled';

      const servicesNotPaired = [];
      const servicesPaired = [];
      const services = option.services.slice();
      if (option.services.length) {
        const haulIndex = services.findIndex(x => x.name === 'Haul');

        // Magic splice to put 'Haul' before other services
        if (haulIndex) {
          services.splice(0, 0, services.splice(haulIndex, 1)[0]);
        }

        for (let j = 0; j < services.length; j++) {
          const service = services[j];

          let useInfoBox = false;
          if (Object.hasOwnProperty.call(service, 'useInfoBox')) {
            if (service.useInfoBox) {
              useInfoBox = true;

              const infoBoxPairedWith = service.infoBoxPairedWith;
              const infoBoxVal = option.infoBoxes.find(infoBox => {
                if (infoBox.name === infoBoxPairedWith) {
                  return true;
                }
              });

              let infoBoxMU = '';
              if (useInfoBox) {
                for (
                  let i = 0;
                  i < this.settings.destinationSettings.services.length;
                  i++
                ) {
                  const destService =
                    this.settings.destinationSettings.services[i];
                  if (service.name === destService.name) {
                    infoBoxMU = destService.infoBoxMU;
                  }
                }

                for (
                  let i = 0;
                  i < this.settings.sourceSettings.services.length;
                  i++
                ) {
                  const sourceService =
                    this.settings.sourceSettings.services[i];
                  if (service.name === sourceService.name) {
                    infoBoxMU = sourceService.infoBoxMU;
                  }
                }
              }

              const serviceVal = infoBoxVal ? infoBoxVal.model : 0;
              const serviceTotal = service.price * serviceVal;
              const serviceName = service.name;
              const servicePrice = service.price;
              servicesPaired.push({
                serviceName,
                serviceVal,
                servicePrice,
                serviceTotal,
                infoBoxPairedWith,
                infoBoxMU
              });
            }
          }

          if (!useInfoBox) {
            servicesNotPaired.push(service);
            serviceStr += service.name + ' - ';
            if (option.type === 'destination') {
              condensedTotal += service.price * option[servicesHauledKey];
              condensedPrice += service.price;
            } else if (option.type === 'source') {
              condensedTotal -= service.price * option[servicesHauledKey];
              condensedPrice -= service.price;
            }
          }
        }
      }

      // Remove last ' - '
      serviceStr = serviceStr.slice(0, -3);

      this.selectedOptionsFormatted[i].servicesPaired = servicesPaired;
      this.selectedOptionsFormatted[i].servicesNotPaired = servicesNotPaired;
      this.selectedOptionsFormatted[i].serviceStr = serviceStr;
      this.selectedOptionsFormatted[i].condensedPrice = condensedPrice;
      this.selectedOptionsFormatted[i].condensedTotal = condensedTotal;
    }
  },
  methods: {
    checkIfCommodity(option) {
      if (option.type === 'source') {
        return option.purchaseCommodity && option.showPricePerUnit
          ? true
          : false;
      } else {
        return option.chargeCommodity && option.showPricePerUnit ? true : false;
      }
    },
    hauled(option) {
      if (option.filterByDate) {
        return option.hauledFiltered;
      }

      return option.hauled;
    },
    locationId(id, type) {
      if (type === 'destination') {
        return this.masterDestinations[id].destinationName;
      } else {
        return this.masterSources[id].sourceName;
      }
    },
    pairedWithInfoBox(service) {
      if (Object.hasOwnProperty.call(service, 'useInfoBox')) {
        if (service.useInfoBox) {
          return true;
        }
      }

      return false;
    },
    servicesHauled(option) {
      if (option.filterServicesByDate) {
        return option.hauledFiltered;
      }

      return option.hauled;
    }
  },
  computed: {
    generalSettings() {
      return this.$store.state.generalSettings;
    },
    masterDestinations() {
      return this.$store.state.masterDestinations;
    },
    masterSources() {
      return this.$store.state.masterSources;
    },
    settings() {
      return {
        destinationSettings: this.$store.state.destinationSettings,
        sourceSettings: this.$store.state.sourceSettings
      };
    }
  }
};
</script>

<style scoped>
#name {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
}

#table {
  margin-bottom: 30px;
}

.right {
  text-align: right;
  padding-right: 10px;
}

.left {
  text-align: left;
  padding-left: 10px;
}

.top {
  border-top: none;
}

td .wider {
  width: 300px;
}

th {
  text-align: left;
  padding-top: 5px;
  padding-bottom: 5px;
}

table {
  table-layout: fixed;
}

table,
th,
td {
  border-bottom: 1px solid silver;
  border-top: 1px solid silver;
  border-collapse: collapse;
}
</style>
