<template>
  <div>
    <div class="text-h4 text-center q-py-xl">Documentation</div>
    <div class="q-pa-md" style="max-width: 550px">
      <q-list>
        <q-item>
          <q-item-section avatar>
            <q-icon color="primary" name="label" />
          </q-item-section>
          <q-item-section>
            <b>Destinations/Fields & Sources:</b> Data Filters are set per
            device. When you set a filter on a device, that filter will persist
            until you change it.
          </q-item-section>
        </q-item>

        <q-item>
          <q-item-section avatar>
            <q-icon color="primary" name="label" />
          </q-item-section>
          <q-item-section>
            <b>Destinations/Fields & Sources:</b> Deleting a source or
            destination/field is not available once a haul has been made. (We
            assume it was a real haul and it should always be accounted for in
            the system).
          </q-item-section>
        </q-item>

        <q-item>
          <q-item-section avatar>
            <q-icon color="primary" name="label" />
          </q-item-section>
          <q-item-section>
            <b>Destinations/Fields & Sources:</b> Changing the Location is not
            available once a haul has been made.
          </q-item-section>
        </q-item>

        <q-item>
          <q-item-section avatar>
            <q-icon color="primary" name="label" />
          </q-item-section>
          <q-item-section>
            <b>Destinations/Fields & Sources:</b> You will get a warning if you
            change the mileage tracking or a haul tracker after a haul has been
            made. The system will allow you to go ahead and make the change or
            to cancel. Pay attention to these warnings as it is very likely you
            will want to edit the haul or hauls to reflect whatever changes you
            made.
          </q-item-section>
        </q-item>

        <q-item>
          <q-item-section avatar>
            <q-icon color="primary" name="label" />
          </q-item-section>
          <q-item-section>
            <b>Destinations/Fields & Sources:</b> Changing or deleting a Haul
            Tracker or Service will not alter the Haul Trackers and Services
            already attached to Sources or Destinations/Fields - it only affects
            what will be available for future sources or destinations/fields.
            <br />
            One Exception: Haul Tracker options that have been edited will take
            affect immediately for all sources or destinations/fields which have
            the Haul Tracker applied.
          </q-item-section>
        </q-item>

        <q-item>
          <q-item-section avatar>
            <q-icon color="primary" name="label" />
          </q-item-section>
          <q-item-section>
            Look for Information buttons that will display tips on how the app
            works.
            <br />
            <div class="row justify-center">
              <q-btn
                round
                flat
                icon="info"
                color="primary"
                size="10px"
                padding="xs"
              />
            </div>
          </q-item-section>
        </q-item>
      </q-list>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoMain',
  created() {
    this.$store.dispatch('setHeaderBtnsDisplay', false);
  }
};
</script>

<style scoped></style>
