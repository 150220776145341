<template>
  <div class="outer-table-div">
    <ReportContainer
      v-if="currentDisplay === 'destReport'"
      :reportId="reportId"
      :reportType="reportType"
      @onCancel="onCancelView"
    />

    <q-linear-progress v-if="destRendering" query color="primary" />
    <div
      v-if="!allDestinations.length && !destRendering && !emptyListDelay"
      class="row justify-center q-pb-sm"
    >
      <div class="row justify-center full-width q-py-lg text-h4 text-primary">
        {{ destAs.slice(0, -1) }} List Is Empty
      </div>
    </div>

    <div
      v-if="allDestinations.length >= 36 && !destRendering"
      class="row full-width q-pt-md"
    >
      <q-space />
      <div class="q-mr-md" style="width: 270px">
        <q-input
          v-model="searchStr"
          outlined
          dense
          label="Search Customers"
          label-color="primary"
          @input="searchByStr"
        >
        </q-input>
      </div>
    </div>

    <div class="column q-ma-sm" :style="destHeight">
      <q-card
        v-for="(dest, index) in activeDestinations"
        :key="index"
        :id="dest.id + '-card'"
        flat
        bordered
        class="q-ma-sm"
        :style="`width: ${itemWidth + 198}px;`"
      >
        <div
          :id="dest.id"
          style="position: absolute; top: -100px; left: 0"
        ></div>

        <div class="row q-pa-sm">
          <div class="row justify-center items-center q-pr-sm">
            <div
              class="row justify-center items-center bg-custom-light rounded-borders text-h5"
              style="width: 44px; height: 44px; position: relative"
            >
              <q-badge
                v-if="dest.minServiceTs"
                floating
                rounded
                style="z-index: 7; border: 1px solid black"
                :color="dateTripColor(dest.minServiceTs).color"
              >
                <q-icon name="today" color="black" />
              </q-badge>
              <span style="z-index: 5">
                {{ dest.customer.charAt(0) }}
              </span>
              <div
                class="row items-center justify-center bg-custom-light-blue"
                :style="`position: absolute; bottom: 0; width: 44px;
                  border-bottom-left-radius: 4px;
                  border-bottom-right-radius: 4px;
                  border-top-right-radius: ${
                    dest.percentHauled === '100%' ? 4 : 0
                  }px;
                  border-top-left-radius: ${
                    dest.percentHauled === '100%' ? 4 : 0
                  }px;
                  border-bottom-top-radius: 4px;
                  height: ${dest.percentHauled}`"
              ></div>
            </div>
          </div>

          <div :style="`width: ${itemWidth}px`">
            <div style="height: 27px; padding-top: 0px" class="row ellipsis">
              <div class="ellipsis">
                {{ dest.customer }}
              </div>
            </div>
            <div
              style="height: 18px"
              class="row items-center text-caption text-custom-medium no-wrap ellipsis"
            >
              <q-icon
                v-if="
                  dest.isDispatched &&
                  modules.dispatch &&
                  !dest.servicesComplete
                "
                name="person"
                color="custom-orange"
                size="26px"
                class="q-mr-sm"
              />
              <q-avatar
                rounded
                size="xs"
                :color="dest.servicesComplete ? 'custom-red' : 'custom-light'"
                text-color="white"
                icon="done_outline"
                class="q-mr-xs"
              />
              {{ dest.destinationName }}
            </div>
          </div>

          <div
            class="row items-center justify-end q-pl-sm"
            style="width: 128px; height: 44px"
          >
            <div>
              <q-btn
                unelevated
                :icon="dest.location.lat ? 'place' : 'location_off'"
                color="primary"
                :text-color="dest.location.lat ? 'white' : 'custom-red'"
                size="sm"
                class="q-mx-xs"
                padding="xs sm"
                @click="mapPin(dest)"
              />
              <q-btn
                unelevated
                icon="view_list"
                color="primary"
                text-color="white"
                size="sm"
                class="q-mx-xs"
                padding="xs sm"
                @click="viewDest(index, dest)"
              />
              <q-btn
                v-if="!isGuestCustomer"
                unelevated
                icon="edit"
                color="primary"
                text-color="white"
                size="sm"
                class="q-ml-xs"
                padding="xs sm"
                @click="editDest(index, dest)"
              />
            </div>
            <div
              v-if="balOrHauled === 'haul_pro'"
              class="row full-width text-caption text-custom-medium q-pt-xs"
            >
              <q-space />
              <div class="ellipsis">
                {{ Math.round(dest.hauled) }}
                <q-icon
                  name="forward"
                  size="12px"
                  color="custom-green"
                  class="q-pb-xs"
                />
                {{ Math.round(dest.projected) || '--' }}
                {{ dest.measurementUnit }}
              </div>
            </div>
            <div
              v-if="balOrHauled === 'bal_pro'"
              class="row full-width text-caption text-custom-medium q-pt-xs"
            >
              <q-space />
              <div class="ellipsis">
                {{ Math.round(dest.balance) }}
                <q-icon
                  name="forward"
                  size="12px"
                  color="custom-red"
                  class="q-pb-xs"
                />
                {{ Math.round(dest.projected) || '--' }}
                {{ dest.measurementUnit }}
              </div>
            </div>
          </div>

          <div
            v-if="destinationInputSelected !== ''"
            class="full-width q-mt-sm"
          >
            <q-separator />

            <div
              v-if="destinationInputSelected === 'note'"
              class="row full-width text-custom-medium ellipsis no-wrap q-pt-xs"
            >
              Note
              <q-space />
              <span class="ellipsis q-ml-md">
                {{ dest.note }}
              </span>
            </div>

            <div
              v-if="destinationInputSelected === 'startDate'"
              class="row full-width text-custom-medium ellipsis no-wrap q-pt-xs"
            >
              Start Date
              <q-space />
              <span class="ellipsis q-ml-md">
                {{ dest.startDateFormatted }}
              </span>
            </div>

            <div
              v-if="destinationInputSelected === 'scheduled'"
              class="row full-width text-custom-medium ellipsis no-wrap q-pt-xs"
            >
              Scheduled
              <q-space />
              <span class="text-custom-red ellipsis q-ml-md">
                {{ dest.scheduledFormatted }}
              </span>
            </div>

            <div
              v-if="destinationInputSelected === 'dispatchedTo'"
              class="row full-width text-custom-medium ellipsis no-wrap q-pt-xs"
            >
              Dispatched To
              <q-space />
              <span class="text-custom-red ellipsis q-ml-md">
                {{ dest.dispatchedToStr }}
              </span>
            </div>

            <div
              v-if="destinationInputSelected === 'attachment'"
              class="row full-width text-custom-medium q-pt-xs"
            >
              Attachments
              <q-space />
              <q-icon
                v-if="dest.location.attachmentId"
                name="image"
                size="20px"
                color="custom-orange"
                text-color="white"
              />
            </div>

            <div
              v-if="destinationInputSelected === 'commodity'"
              class="row full-width text-custom-medium ellipsis no-wrap q-pt-xs"
            >
              Commodity
              <q-space />
              <span class="text-custom-red ellipsis q-ml-md">
                {{ dest.commodity }}
              </span>
            </div>

            <div
              v-if="destinationInputSelected === 'haulTrackers'"
              class="row full-width text-custom-medium ellipsis no-wrap q-pt-xs"
            >
              Haul Trackers
              <q-space />
              <span class="text-custom-red ellipsis q-ml-md">
                {{ dest.trackerStr }}
              </span>
            </div>

            <div
              v-if="destinationInputSelected === 'trackMileage'"
              class="row full-width text-custom-medium q-pt-xs"
            >
              Track Mileage
              <q-space />
              <q-icon
                v-if="dest.trackMileage"
                name="add_road"
                size="20px"
                color="custom-orange"
                text-color="white"
              />
            </div>

            <div
              v-if="destinationInputSelected === 'services'"
              class="row full-width text-custom-medium ellipsis no-wrap q-pt-xs"
            >
              Services
              <q-space />
              <span class="text-custom-red ellipsis q-ml-md">
                {{ dest.serviceStr }}
              </span>
            </div>

            <div
              v-for="(infoBox, index) in dest.infoBoxes"
              :key="index + infoBox.name"
            >
              <div
                v-if="destinationInputSelected === infoBox.name"
                class="row full-width text-custom-medium ellipsis no-wrap q-pt-xs"
              >
                {{ infoBox.name }}
                <q-space />
                <span class="text-custom-red ellipsis q-ml-md">
                  {{ infoBox.model }}
                </span>
              </div>
            </div>

            <div
              v-for="(customSelector, index) in dest.customSelectors"
              :key="index + customSelector.name"
            >
              <div
                v-if="destinationInputSelected === customSelector.name"
                class="row full-width text-custom-medium ellipsis no-wrap q-pt-xs"
              >
                {{ customSelector.name }}
                <q-space />
                <q-icon
                  name="radio_button_checked"
                  size="20px"
                  :color="customSelector.color"
                  text-color="white"
                />
                <span class="ellipsis q-ml-sm">
                  {{ customSelector.model }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </q-card>

      <!--layout fix when only 2 show-->
      <div
        v-if="
          activeDestinations.length === 2 || activeDestinations.length === 4
        "
        class="q-pa-md q-ma-sm"
        :style="`width: ${itemWidth + 196}px;`"
      ></div>
    </div>
  </div>
</template>

<script>
import ReportContainer from '@/components/ReportContainer.vue';
import { destinationReportUsers } from '@/utils/ReportHelpers';
import { dateTripColor } from '@/utils/UtilHelpers.js';
import { customRoutes } from '@/custom/Routes.js';

export default {
  name: 'destinationTableUsers',
  components: {
    ReportContainer
  },
  props: {
    destinationInputs: {
      type: Object,
      required: true
    },
    destinationInputSelected: {
      type: String,
      required: true
    },
    destinationSortBy: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activeDestinations: [],
      allDestinations: [],
      currentDisplay: 'overview',
      destRendering: true,
      emptyListDelay: true,
      headerWidth: 0,
      itemWidth: 200,
      reportRaw: {},
      reportType: 'destination',
      searchStr: '',
      updater: {
        index: 0,
        dest: {}
      }
    };
  },
  mounted() {
    window.scrollTo(0, 0);

    // For some reason this allows the page to load immediately with
    // rendering loader. nextTick does not work for this
    setTimeout(() => {
      this.setDestinations();
    }, 3);

    setTimeout(() => {
      this.emptyListDelay = false;
    }, 1000);

    this.$nextTick(() => {
      this.headerWidth = document.getElementById('main-header').offsetWidth;

      window.addEventListener('resize', this.setItemWidth);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setItemWidth);
  },
  methods: {
    addId(id) {
      this.updater.dest.id = id;
    },
    dateTripColor(serviceTs) {
      return dateTripColor(this.serviceTripDates, serviceTs);
    },
    highlightDest() {
      if (this.updater.action === 'add') {
        // Need to wait until this has updated in the DB
        setTimeout(() => {
          const index = this.activeDestinations.findIndex(dest => {
            return dest.id === this.updater.dest.id;
          });

          this.updater.index = index;
        }, 300);
      }

      setTimeout(() => {
        const activeDestinationsUsers = JSON.parse(
          JSON.stringify(this.$store.state.activeDestinationsUsers)
        );

        const dest = activeDestinationsUsers.find(dest => {
          return dest.id === this.updater.dest.id;
        });

        if (!dest || this.updater.action === 'delete') {
          return;
        }

        let highLightTime = 1000;
        const length = activeDestinationsUsers.length;

        if (this.$q.screen.lt.sm) {
          if (this.updater.index > 20) {
            highLightTime = 1900;
          }
        } else if (this.$q.screen.lt.lg) {
          const addOne = length % 2 > 0 ? 1 : 0;
          let sectionLength = Math.floor(length / 2) + addOne;
          if (this.updater.index % sectionLength > 20) {
            highLightTime = 1900;
          }
        } else {
          const addOne = length % 3 > 0 ? 1 : 0;
          let sectionLength = Math.floor(length / 2) + addOne;
          if (this.updater.index % sectionLength > 20) {
            highLightTime = 1900;
          }
        }

        this.activeDestinations[this.updater.index].destinationLocationId =
          dest.destinationLocationId;
        this.activeDestinations[this.updater.index].destinationName =
          this.masterDestinations[dest.destinationLocationId].destinationName;
        this.activeDestinations[this.updater.index].projected = dest.projected;

        // Don't scroll if action is view
        if (this.updater.action === 'edit') {
          document
            .getElementById(dest.id)
            .scrollIntoView({ behavior: 'smooth' });
        }

        const el = document.getElementById(dest.id + '-card');
        if (el) {
          el.style.background = '#8b9474';
        }

        setTimeout(() => {
          const el = document.getElementById(dest.id + '-card');
          if (el) {
            el.style.background = 'white';
          }
        }, highLightTime);
      }, 300);
    },
    searchByStr() {
      this.destinations = [];

      if (this.searchStr === '') {
        this.activeDestinations = this.allDestinations.slice();
      } else {
        this.activeDestinations = [];
        for (let i = 0; i < this.allDestinations.length; i++) {
          if (
            this.customers[this.allDestinations[i].customerId].name
              .toUpperCase()
              .includes(this.searchStr.toUpperCase())
          ) {
            this.activeDestinations.push(this.allDestinations[i]);
          }
        }
      }

      this.$nextTick(() => {
        this.setItemWidth();
      });
    },
    setDestinations() {
      const destinations = [];
      const activeDestinationsUsers = JSON.parse(
        JSON.stringify(this.$store.state.activeDestinationsUsers)
      );

      for (let i = 0; i < activeDestinationsUsers.length; i++) {
        const destInfo = destinationReportUsers(activeDestinationsUsers[i].id);

        destinations.push(destInfo);
      }

      this.sortDest(destinations);

      this.allDestinations = [];
      this.activeDestinations = [];
      this.activeDestinations = destinations.slice();
      this.allDestinations = destinations.slice();
      this.destRendering = false;

      this.$nextTick(() => {
        this.setItemWidth();
      });
    },
    setItemWidth() {
      const headerWidth = document.getElementById('main-header').offsetWidth;

      let cols = 3;
      let gutterAdjustment = 0;
      if (this.$q.screen.lt.sm) {
        cols = 1;
        gutterAdjustment = 8;
      } else if (this.$q.screen.lt.lg) {
        cols = 2;
        gutterAdjustment = 4;
      }

      this.itemWidth = headerWidth / cols - 219 - gutterAdjustment;
    },
    sortDest(destinations) {
      let sortA = -1;
      let sortB = 1;

      let sortBy = this.destinationSortBy.value;

      if (this.destinationSortBy.reverseSort && sortBy === 'alpha') {
        sortA = 1;
        sortB = -1;
      }

      const sortByCustomer = (a, b) => {
        const sortByA = this.customers[a.customerId].name + a.destinationName;
        const sortByB = this.customers[b.customerId].name + b.destinationName;
        if (sortByA < sortByB) {
          return sortA;
        }
        if (sortByA > sortByB) {
          return sortB;
        }
        return 0;
      };

      destinations.sort(sortByCustomer);

      sortA = this.destinationSortBy.reverseSort ? 1 : -1;
      sortB = this.destinationSortBy.reverseSort ? -1 : 1;

      const sortByInfoBoxes = (a, b) => {
        const indexA = a.infoBoxes.findIndex(x => x.name === sortBy);
        const indexB = b.infoBoxes.findIndex(x => x.name === sortBy);

        if (!a.infoBoxes[indexA].model && !b.infoBoxes[indexB].model) {
          return 0;
        } else if (!a.infoBoxes[indexA].model) {
          return sortA;
        } else if (!b.infoBoxes[indexB].model) {
          return sortB;
        } else if (a.infoBoxes[indexA].model < b.infoBoxes[indexB].model) {
          return sortA;
        } else if (a.infoBoxes[indexA].model > b.infoBoxes[indexB].model) {
          return sortB;
        }
        return 0;
      };

      const sortByCustomSelectors = (a, b) => {
        const indexA = a.customSelectors.findIndex(x => x.name === sortBy);
        const indexB = b.customSelectors.findIndex(x => x.name === sortBy);

        if (indexA < 0 && indexB < 0) {
          return 0;
        } else if (indexA < 0) {
          return sortB;
        } else if (indexB < 0) {
          return sortA;
        }

        if (!a.customSelectors[indexA].model) {
          return sortB;
        } else if (!b.customSelectors[indexB].model) {
          return sortA;
        } else if (
          a.customSelectors[indexA].model > b.customSelectors[indexB].model
        ) {
          return sortB;
        } else if (
          a.customSelectors[indexA].model < b.customSelectors[indexB].model
        ) {
          return sortA;
        }
        return 0;
      };

      const sortBySelectorOption = (a, b) => {
        const indexA = a.customSelectors.findIndex(x => x.model === sortBy);
        const indexB = b.customSelectors.findIndex(x => x.model === sortBy);

        if (indexA < 0 && indexB < 0) {
          return 0;
        } else if (indexA < 0) {
          return sortB;
        } else if (indexB < 0) {
          return sortA;
        }

        if (
          a.customSelectors[indexA].model === b.customSelectors[indexB].model
        ) {
          return 0;
        } else if (a.customSelectors[indexA].model === sortBy) {
          return sortA;
        }
        return 0;
      };

      const sortByNum = (a, b) => {
        // Have to deal with projected set to ''
        if (a[sortBy] === '' && b[sortBy] !== '') {
          return sortA;
        } else if (a[sortBy] !== '' && b[sortBy] === '') {
          return sortB;
        } else if (a[sortBy] < b[sortBy]) {
          return sortA;
        } else if (a[sortBy] > b[sortBy]) {
          return sortB;
        }
        return 0;
      };

      const sortByScheduled = (a, b) => {
        if (!a[sortBy] && !b[sortBy]) {
          return 0;
        }
        if (a[sortBy] && !b[sortBy]) {
          return sortA;
        }
        if (!a[sortBy] && b[sortBy]) {
          return sortB;
        }
        if (a[sortBy] > b[sortBy]) {
          return sortB;
        }
        if (a[sortBy] < b[sortBy]) {
          return sortA;
        }
        return 0;
      };

      const sortByBoolean = (a, b) => {
        if (a[sortBy] && !b[sortBy]) {
          return sortA;
        } else if (!a[sortBy] && b[sortBy]) {
          return sortB;
        }
        return 0;
      };

      if (this.destinationSortBy.type === 'number') {
        destinations.sort(sortByNum);
      } else if (this.destinationSortBy.type === 'boolean') {
        destinations.sort(sortByBoolean);
      } else if (this.destinationSortBy.type === 'scheduled') {
        destinations.sort(sortByScheduled);
      } else if (this.destinationSortBy.type === 'infoBox') {
        destinations.sort(sortByInfoBoxes);
      } else if (this.destinationSortBy.type.startsWith('customSelector')) {
        const selectorArr = this.destinationSortBy.type.split('###');

        const temp = sortBy;

        sortBy = selectorArr[1];

        // Sort by customSelector name
        destinations.sort(sortByCustomSelectors);
        sortBy = temp;

        // Then sort by customSelector option
        destinations.sort(sortBySelectorOption);
      }
    },
    editDest(index, dest) {
      this.updater = {
        index,
        dest,
        action: 'edit'
      };

      this.$emit('onEdit', dest);
    },
    mapPin(dest) {
      if (dest.location.lat) {
        const mapPin = {
          type: 'destination',
          id: dest.locationId
        };

        this.$store.dispatch('setMapPin', mapPin);

        const custMap = customRoutes.find(route => route.name === 'Map');
        if (custMap) {
          this.$router.push({ path: custMap.path });
        } else {
          this.$router.push({ path: 'map' });
        }
      }
    },
    onCancelView() {
      document.body.style.overflow = 'auto';
      document.body.style.height = '';

      this.currentDisplay = 'destinationTable';
      this.highlightDest();
    },
    viewDest(index, dest) {
      this.reportId = dest.id;
      this.currentDisplay = 'destReport';

      document.body.style.overflow = 'hidden';
      document.body.style.height = '100vh';

      this.updater = {
        index,
        dest,
        action: 'view'
      };
    }
  },
  computed: {
    balOrHauled() {
      return this.$store.state.balOrHauled;
    },
    customers() {
      return this.$store.state.customers;
    },
    destAs() {
      return this.$store.state.settingsUsers.destAs;
    },
    destHeight() {
      const destLength = this.activeDestinations.length;
      const cardHeight = this.destinationInputSelected === '' ? 80 : 113;
      if (this.$q.screen.lt.sm) {
        return `height: ${destLength * cardHeight}px`;
      } else if (this.$q.screen.lt.lg) {
        const addOne = destLength % 2 > 0 ? cardHeight : 0;
        return `height: ${Math.floor(destLength / 2) * cardHeight + addOne}px`;
      } else {
        const addOne = destLength % 3 > 0 ? cardHeight : 0;
        return `height: ${Math.floor(destLength / 3) * cardHeight + addOne}px`;
      }
    },
    isGuestCustomer() {
      return (
        this.$store.state.user.superCustomer || this.$store.state.user.customer
      );
    },
    masterDestinations() {
      return this.$store.state.masterDestinations;
    },
    modules() {
      return JSON.parse(
        JSON.stringify(
          this.$store.state.settingsUsers.destinationSettings.modules
        )
      );
    },
    serviceTripDates() {
      const tripDates = JSON.parse(
        JSON.stringify(
          this.$store.state.settingsUsers.destinationSettings.serviceTripDates
        )
      );

      const now = +new Date();
      const tripA = tripDates.tripDateA * 86400000;
      const tripB = tripDates.tripDateB * 86400000;

      return { now, tripA, tripB };
    },
    userDestinationSettings() {
      return JSON.parse(
        JSON.stringify(this.$store.state.settingsUsers.destinationSettings)
      );
    }
  },
  watch: {
    '$store.state.activeDestinationsUsers'() {
      this.setDestinations();
    }
  }
};
</script>

<style scoped></style>
