<template>
  <div>
    <canvas
      :id="chartId"
      :style="`height: ${height}px; max-height: ${chartData.options.maxHeight}px;`"
    >
    </canvas>
  </div>
</template>

<script>
import {
  Chart,
  ArcElement,
  BarElement,
  BarController,
  LineController,
  PieController,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

Chart.register(
  ArcElement,
  BarElement,
  BarController,
  LineController,
  PieController,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend
);

export default {
  name: 'Chart-Component',
  props: {
    chartData: {
      type: Object,
      required: true
    },
    chartId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      height: 300
    };
  },
  mounted() {
    const numDatasets = this.chartData.data.datasets.length;
    const additionalHeight = this.chartData.options.additionalHeight || 0;
    this.height = 180 + additionalHeight + 30 * Math.ceil(numDatasets / 3);

    const ctx = document.getElementById(this.chartId);
    new Chart(ctx, this.chartData);
  },
  methods: {},
  computed: {}
};
</script>

<style scoped></style>
