import { render, staticRenderFns } from "./SourceSettings.vue?vue&type=template&id=0649d366&scoped=true&"
import script from "./SourceSettings.vue?vue&type=script&lang=js&"
export * from "./SourceSettings.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0649d366",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QIcon,QSeparator,QInput,QCheckbox,QBtn,QOptionGroup});
