<template>
  <div>
    <TimeClock v-if="show" />
  </div>
</template>

<script>
import TimeClock from '@/components/timeclock/TimeClock.vue';

export default {
  name: 'TimeClockView',
  components: {
    TimeClock
  },
  data() {
    return {
      show: true
    };
  },
  created() {
    this.$store.dispatch('setHeaderBtnsDisplay', false);
  }
};
</script>

<style scoped></style>
