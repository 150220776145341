<template>
  <div>
    <q-linear-progress v-if="loading" indeterminate color="primary" />

    <OkDialog
      v-if="ok.display"
      :icon="ok.icon"
      :iconColor="ok.iconColor"
      :headerMessage="ok.headerMessage"
      :message="ok.message"
      @okConfirmed="ok.display = false"
    />

    <ConfirmDialog
      v-if="confirm.display"
      icon="warning"
      iconColor="custom-red"
      :btn="confirm.btn"
      :message="confirm.message"
      headerMessage="Important Notice"
      @canceled="confirm.display = false"
      @confirmed="onSubmitUserConfirmed"
    />

    <div class="flex flex-center custom-border">
      <RegisterForm
        v-if="currentDisplay === 'register'"
        ref="register"
        @registerMethod="registerMethod"
      />

      <LoginForm
        v-if="currentDisplay === 'login'"
        ref="login"
        @loginMethod="loginMethod"
        @onPasswordReset="onPasswordReset"
      />

      <LoggedIn
        v-if="currentDisplay === 'loggedIn'"
        ref="loggedIn"
        :user="user"
        @onLogout="onLogout"
      />

      <AccountSettings
        v-if="currentDisplay === 'settings'"
        :user="user"
        @onAccountSettingsUpdate="onAccountSettingsUpdate"
        @onPasswordReset="onPasswordReset"
      />

      <UserList
        v-if="currentDisplay === 'userList'"
        :users="users"
        :skeleton="skeleton"
        @editUser="editUser"
      />

      <AddUser v-if="currentDisplay === 'addUser'" @addUser="addUser" />

      <EditUser
        v-if="currentDisplay === 'editUser'"
        ref="editUser"
        :user="selectedUser"
        @onSubmitUser="onSubmitUser"
        @onDeleteUser="onDeleteUser"
      />
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import OkDialog from '@/components/OkDialog.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import UserList from '@/components/account/UserList.vue';
import AddUser from '@/components/account/AddUser.vue';
import EditUser from '@/components/account/EditUser.vue';
import RegisterForm from '@/components/account/Register.vue';
import AccountSettings from '@/components/account/AccountSettings.vue';
import LoggedIn from '@/components/account/LoggedIn.vue';
import LoginForm from '@/components/account/LoginForm.vue';
import firebase from 'firebase/compat/app';
import { mapGetters } from 'vuex';

const db = firebase.firestore();

export default {
  name: 'MyAccount',
  components: {
    AccountSettings,
    AddUser,
    EditUser,
    LoggedIn,
    LoginForm,
    OkDialog,
    ConfirmDialog,
    RegisterForm,
    UserList
  },
  data() {
    return {
      confirm: {
        btn: {
          label: 'Set To Visitor',
          icon: 'warning',
          color: 'custom-red'
        },
        display: false,
        message: `Are you sure you want to set user to visitor? <br><br>
          Setting user to visitor will permanently delete user
          from app, including time clock information and driver labels on hauls!`
      },
      currentDisplay: '',
      customer: null,
      displayInfo: true,
      displayEdit: false,
      loading: false,
      ok: {
        icon: '',
        message: '',
        display: false,
        iconColor: '',
        headerMessage: ''
      },
      selectedUser: {},
      showSignIn: true,
      skeleton: false,
      showRegister: false,
      userEdit: false,
      usersDisplay: false,
      users: []
    };
  },
  created() {
    this.$store.dispatch('setHeaderBtnsDisplay', true);
    this.setDisplay();
  },
  methods: {
    addUser(userObj) {
      this.loading = true;

      // trim whitespace & force upper on display name
      // Remove all periods
      userObj.email = userObj.email.trim();
      userObj.displayName = userObj.displayName.trim();
      userObj.displayName = userObj.displayName.replaceAll('.', '');
      userObj.displayName = userObj.displayName.replace(
        /(^\w{1})|(\s+\w{1})/g,
        letter => letter.toUpperCase()
      );

      const success = () => {
        this.ok.icon = 'person';
        this.ok.headerMessage = userObj.displayName;
        this.ok.iconColor = 'primary';
        this.ok.message = `Account with display name '${userObj.displayName}'
          has been successfully created.
          <br>
          <br>
          Email: ${userObj.email}
          <br>
          <br>
          Password: ${userObj.password}
          <br>
          <b><span style="color: #cc0003;">Save this!</span></b>
          <br>
          <br>
          Role has been set to visitor. Role can now be updated to fit 
          desired user permissions.
          `;
        this.ok.display = true;
      };

      const fail = errMessage => {
        this.ok.icon = 'person';
        this.ok.headerMessage = userObj.displayName;
        this.ok.iconColor = 'custom-red';
        this.ok.message = `Failed to create user.
          <br> <br>
          ${errMessage}`;
        this.ok.display = true;
      };

      const createNewUser = firebase.functions().httpsCallable('createNewUser');
      createNewUser(userObj).then(result => {
        if (result.data === 'Successfully created new user') {
          success();
          this.loading = false;
          this.getUsers();
        } else {
          fail(result.data.errorInfo.message);
          this.loading = false;
          this.getUsers();
        }
      });
    },
    confirmDeleteUser() {
      this.$refs.editUser.confirmDelete = true;
    },
    editUser(user) {
      this.selectedUser = user;
      this.setAction('editUser');
    },
    getUsers() {
      this.users = [];
      this.skeleton = true;
      this.setAction('userList');

      const fetchUsers = firebase.functions().httpsCallable('fetchUsers');
      fetchUsers({ text: 'fetch users' }).then(result => {
        // Read result of the Cloud Function.
        if (this.currentDisplay === 'userList') {
          // Make sure user has custom claims set, if not wait for cloud function to add
          for (let i = 0; i < result.data.length; i++) {
            if (!result.data[i].customClaims) {
              setTimeout(() => {
                this.getUsers();
              }, 1000);

              return false;
            }
          }

          this.users = result.data.slice();
          this.skeleton = false;
        }
      });
    },
    loginMethod(form) {
      firebase
        .auth()
        .signInWithEmailAndPassword(form.email, form.password)
        .then(() => {
          this.$store.dispatch('setUser');
        })
        .catch(() => {
          this.$refs.login.setError();
          // Do not display this info
          // console.log(err);
        });
    },
    onAccountSettingsUpdate(email, displayName) {
      // Trim whitespace & force upper on display name
      // Remove all periods
      email = email.trim();
      displayName = displayName.trim();
      displayName = displayName.replaceAll('.', '');
      displayName = displayName.replace(/(^\w{1})|(\s+\w{1})/g, letter =>
        letter.toUpperCase()
      );
      let emailChanged = false;
      if (email !== this.user.email) {
        emailChanged = true;
      }
      if (displayName !== this.user.displayName) {
        const userListRef = db.collection('settings_users').doc('user_list');

        const oldName = this.user.displayName;
        const newName = displayName;
        const id = this.userList.users[this.user.displayName];

        const deleteObj = {
          [`users.${oldName}`]: firebase.firestore.FieldValue.delete()
        };
        const deleteIdsObj = {
          [`userIds.${id}`]: firebase.firestore.FieldValue.delete()
        };
        const createObj = {
          [`users.${newName}`]: id
        };
        const createIdsObj = {
          [`userIds.${id}`]: newName
        };

        const batch = db.batch();
        batch.update(userListRef, deleteObj);
        batch.update(userListRef, deleteIdsObj);
        batch.update(userListRef, createObj);
        batch.update(userListRef, createIdsObj);

        batch.commit().catch(err => {
          console.error(err);
        });

        const user = firebase.auth().currentUser;
        user
          .updateProfile({
            displayName: displayName
          })
          .then(() => {
            if (emailChanged) {
              user.updateEmail(email).then(() => {
                this.$store.dispatch('setUser');
                this.setAction('loggedIn');
              });
            } else {
              this.$store.dispatch('setUser');
              this.setAction('loggedIn');
            }
          })
          .catch(err => {
            console.log(err);
          });
        // if only email changed
      } else if (emailChanged) {
        const user = firebase.auth().currentUser;
        user.updateEmail(email).then(() => {
          this.$store.dispatch('setUser');
          this.setAction('loggedIn');
        });
        // if nothing changed
      } else {
        this.setAction('loggedIn');
      }
    },
    onDeleteUser() {
      this.loading = true;
      this.users = [];
      this.skeleton = true;
      this.setAction('userList');

      const deleteUser = firebase.functions().httpsCallable('deleteUser');
      const userEmail = {
        email: this.selectedUser.email
      };

      deleteUser(userEmail).then(result => {
        // Read result of the Cloud Function.
        if (result.data === 'Successfull Delete') {
          this.ok.icon = 'person';
          this.ok.iconColor = 'custom-red';
          this.ok.headerMessage = this.selectedUser.displayName;
          this.ok.message = `${this.selectedUser.displayName} has been deleted.
            <br><br>
            It may take a few minutes to take affect.`;
          this.ok.display = true;

          // Need to wait to getUsers or user will still be in list
          setTimeout(() => {
            this.loading = false;
            this.getUsers();
          }, 1000);
        }
      });
    },
    onLogout() {
      this.$store.dispatch('unsubscribe');
      this.setAction('login');

      firebase.auth().signOut();
    },
    onPasswordReset(email) {
      const auth = firebase.auth();
      auth
        .sendPasswordResetEmail(email)
        .then(() => {
          console.log(this.currentDisplay);
          this.ok.icon = 'email';
          this.ok.iconColor = 'primary';
          this.ok.message = `Reset password link was sent to ${email}`;
          this.ok.display = true;
          if (this.currentDisplay === 'login') {
            this.$refs.login.passwordResetComplete();
          } else if (this.currentDisplay === 'settings') {
            this.setAction('loggedIn');
          }
          // Email sent.
        })
        .catch(err => {
          console.log(err);
        });
    },
    onSubmitUser(role, customer) {
      this.role = role;
      this.customer = customer;

      if (role === 'Visitor' && !this.selectedUser.customClaims.visitor) {
        this.confirm.display = true;
      } else {
        this.onSubmitUserConfirmed();
      }
    },
    onSubmitUserConfirmed() {
      this.confirm.display = false;
      const currentUser = firebase.auth().currentUser;
      const email = this.selectedUser.email;

      this.currentDisplay = 'userList';
      this.users = [];
      this.skeleton = true;
      const setUserCustomClaims = firebase
        .functions()
        .httpsCallable('setUserCustomClaims');
      const user = {
        email: this.selectedUser.email,
        claim: this.role,
        customer: this.customer
      };
      this.setAction('userList');
      setUserCustomClaims(user).then(() => {
        // Read result of the Cloud Function.
        if (currentUser.email === email) {
          location.reload();
        } else {
          this.ok.icon = 'person';
          this.ok.iconColor = 'primary';
          this.ok.headerMessage = this.selectedUser.displayName;
          this.ok.message = `The role for "${this.selectedUser.displayName}"
            has been updated. <br><br>
            It may take a few minutes to take affect.`;
          this.ok.display = true;
          this.getUsers();
        }
      });
    },
    registerMethod(form) {
      // trim whitespace & force upper on display name
      // Remove all periods
      form.registerEmail = form.registerEmail.trim();
      form.displayName = form.displayName.trim();
      form.displayName = form.displayName.replaceAll('.', '');
      form.displayName = form.displayName.replace(
        /(^\w{1})|(\s+\w{1})/g,
        letter => letter.toUpperCase()
      );

      const reload = () => {
        this.ok.icon = 'person';
        this.ok.iconColor = 'primary';
        this.ok.headerMessage = form.displayName;
        this.ok.message = `Account with display name '${form.displayName}'
          has been successfully created. <br><br>
          An authorized user will need to set your user 
          permissions before you have access to app data.`;
        this.ok.display = true;
      };

      firebase
        .auth()
        .createUserWithEmailAndPassword(
          form.registerEmail,
          form.registerPassword
        )
        .then(result => {
          return result.user
            .updateProfile({
              displayName: form.displayName
            })
            .then(() => {
              this.onLogout();
              reload();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
          if (
            err.message ===
            'The email address is already in use by another account.'
          ) {
            this.$q.dialog({
              title: 'Duplicate Email',
              message: err.message
            });
          }
        });
    },
    setAction(action) {
      if (action === 'login') {
        this.currentDisplay = 'login';

        const headerBtns = [
          {
            action: this.setAction,
            args: 'register',
            label: 'Register',
            color: 'primary',
            flat: true
          }
        ];

        this.$store.dispatch('setHeaderBtns', headerBtns);
      } else if (action === 'register') {
        this.currentDisplay = 'register';

        const headerBtns = [
          {
            action: this.setAction,
            args: 'login',
            label: 'Login',
            color: 'primary',
            flat: true
          }
        ];

        this.$store.dispatch('setHeaderBtns', headerBtns);
      } else if (action === 'loggedIn') {
        this.currentDisplay = 'loggedIn';

        const headerBtns = [
          {
            action: this.setAction,
            args: 'settings',
            label: 'Settings',
            color: 'primary',
            flat: false
          }
        ];
        if (this.isSuperAdmin) {
          headerBtns[0].flat = true;
          headerBtns.push({
            action: this.getUsers,
            args: '',
            label: 'Users',
            color: 'primary',
            flat: false
          });
        }

        this.$store.dispatch('setHeaderBtns', headerBtns);
      } else if (action === 'settings') {
        this.currentDisplay = 'settings';

        const headerBtns = [
          {
            action: this.setAction,
            args: 'loggedIn',
            label: 'Account Home',
            color: 'primary',
            flat: false
          }
        ];

        this.$store.dispatch('setHeaderBtns', headerBtns);
      } else if (action === 'userList') {
        this.currentDisplay = 'userList';

        const headerBtns = [
          {
            action: this.setAction,
            args: 'addUser',
            label: 'Add User',
            color: 'primary',
            flat: true
          },
          {
            action: this.setAction,
            args: 'loggedIn',
            label: 'Account Home',
            color: 'primary',
            flat: false
          }
        ];

        this.$store.dispatch('setHeaderBtns', headerBtns);
      } else if (action === 'addUser') {
        this.currentDisplay = 'addUser';

        this.$nextTick(() => {
          const headerBtns = [
            {
              action: this.setAction,
              args: 'userList',
              label: 'Cancel',
              color: 'primary',
              flat: true
            }
          ];

          this.$store.dispatch('setHeaderBtns', headerBtns);
        });
      } else if (action === 'editUser') {
        this.currentDisplay = 'editUser';
        this.$nextTick(() => {
          const headerBtns = [
            {
              action: this.setAction,
              args: 'userList',
              label: 'Cancel',
              color: 'primary',
              flat: true
            },
            {
              action: this.confirmDeleteUser,
              args: '',
              label: 'Delete',
              color: 'custom-red',
              flat: true
            },
            {
              action: this.$refs.editUser.onSubmit,
              args: '',
              label: 'Update',
              color: 'primary',
              flat: false
            }
          ];

          this.$store.dispatch('setHeaderBtns', headerBtns);
        });
      } else if (action === 'userDeleted') {
        this.currentDisplay = 'editUser';
        this.$nextTick(() => {
          const headerBtns = [
            {
              action: this.setAction,
              args: 'userList',
              label: 'Cancel',
              color: 'primary',
              flat: true
            }
          ];

          this.$store.dispatch('setHeaderBtns', headerBtns);
        });
      }
    },
    setDisplay() {
      const user = this.user;
      if (user) {
        this.setAction('loggedIn');
      } else {
        this.$nextTick(() => {
          // Need to check again
          if (this.user) {
            this.setAction('loggedIn');
          } else {
            this.setAction('login');
          }
        });
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    }),
    companyName() {
      return this.$store.state.companyName;
    },
    isLoggedIn() {
      if (this.user) {
        return true;
      } else {
        return false;
      }
    },
    isSuperAdmin() {
      if (this.user) {
        if (this.user.superAdmin === true) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    userList() {
      return this.$store.state.userList;
    }
  },
  watch: {
    isLoggedIn() {
      this.setDisplay();
    }
  }
};
</script>

<style scoped>
.q-card {
  min-width: 450px;
}
</style>
