<template>
  <div style="width: 100%">
    <div
      style="
        text-align: center;
        font-size: 25px;
        color: #cc0003;
        margin-bottom: 5px;
      "
    >
      {{ driver.driver }}
    </div>
    <div style="text-align: center; font-size: 18px; margin-bottom: 7px">
      {{ headerDateStart }} -
      {{ headerDateEnd }}
    </div>

    <table style="width: 100%">
      <tr>
        <th>Date</th>
        <th>Source</th>
        <th>{{ destAs.slice(0, -1) }}</th>
        <th
          v-for="(haulTracker, trackerIndex) in driver.haulTrackers"
          :key="trackerIndex + 150 + haulTracker"
        >
          {{ haulTracker }}
        </th>
        <th>Haul Amount</th>
        <th v-if="driverInsightsSettings.calcAsGross">Gross Haul Amount</th>
        <th>Rate</th>
        <th>Total</th>
      </tr>
      <tr
        v-for="(haul, haulIndex) in driver.driverHauls"
        :key="haul.dateTime + (haulIndex + 100)"
      >
        <td>{{ haul.formatedDate }}</td>
        <td>
          {{ customers[haul.sourceCustomerId].name }} -
          {{ masterSources[haul.sourceLocationId].sourceName }}
        </td>
        <td>
          {{ customers[haul.destinationCustomerId].name }} -
          {{ setDestination(haul) }}
        </td>
        <td
          v-for="(haulTracker, trackerIndex) in haul.tdHaulTrackers"
          :key="trackerIndex + 200 + haulTracker"
        >
          {{ haulTracker }}
        </td>
        <td>{{ haul.haulAmount }}</td>
        <td v-if="driverInsightsSettings.calcAsGross">
          {{ haul.grossHaulAmount }}
        </td>
        <td>{{ haul.driverRateFormatted }}</td>
        <td>{{ haul.driverTotal }}</td>
      </tr>
      <tr>
        <td
          :colspan="calcColspan(driver)"
          style="text-align: right; font-weight: bold; font-size: 16px"
        >
          <span>
            {{ driver.driverGrandTotal }}
          </span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'PrintTime',
  props: {
    driver: {
      type: Object,
      required: true
    },
    destAs: {
      type: String,
      required: true
    },
    headerDateStart: {
      type: String,
      required: true
    },
    headerDateEnd: {
      type: String,
      required: true
    },
    driverInsightsSettings: {
      type: Object,
      required: true
    },
    customers: {
      type: Object,
      required: true
    }
  },
  methods: {
    calcColspan(driver) {
      if (Object.keys(driver).length) {
        if (this.driverInsightsSettings.calcAsGross) {
          return driver.driverHauls[0].tdHaulTrackers.length + 7;
        } else {
          return driver.driverHauls[0].tdHaulTrackers.length + 6;
        }
      }
    },
    setDestination(haul) {
      // This is for hauls TO sources that track running balance
      if (!haul.sourceAsDestination) {
        return this.masterDestinations[haul.destinationLocationId]
          .destinationName;
      } else {
        return this.masterSources[haul.destinationLocationId].sourceName;
      }
    }
  },
  computed: {
    masterDestinations() {
      return JSON.parse(JSON.stringify(this.$store.state.masterDestinations));
    },
    masterSources() {
      return JSON.parse(JSON.stringify(this.$store.state.masterSources));
    }
  }
};
</script>

<style scoped></style>
