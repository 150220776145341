<template>
  <div class="form-container">
    <div class="q-pa-md q-mt-sm form">
      <div class="gt-xs q-py-md"></div>
      <q-form class="q-gutter-sm">
        <div
          class="row justify-center items-center rounded-borders text-custom-light q-pl-sm"
          style="background: radial-gradient(circle, #3336eb 0%, #0b0d6f 100%)"
        >
          <q-avatar icon="account_circle" font-size="35px" />
          <span class="text-body1">
            {{ customer.name }}
          </span>
        </div>
        <q-input
          filled
          v-model="customer.name"
          label="Customer Name *"
          label-color="primary"
          color="primary"
          maxlength="80"
          dense
          hide-bottom-space
          lazy-rules
          :rules="[val => (val && val.length > 0) || 'Please enter a name']"
        />
        <q-input
          filled
          v-model="customer.businessName"
          label="Business Name"
          label-color="primary"
          color="primary"
          maxlength="60"
          dense
        />
        <q-input
          filled
          v-model="customer.address"
          label="Address"
          label-color="primary"
          color="primary"
          maxlength="40"
          dense
        />
        <q-input
          filled
          v-model="customer.addressLine2"
          label="Address Line 2"
          label-color="primary"
          color="primary"
          maxlength="40"
          dense
        />
        <q-input
          filled
          v-model="customer.city"
          label="City"
          label-color="primary"
          color="primary"
          dense
        />
        <q-select
          filled
          v-model="customer.state"
          :options="stateAbbreviations"
          label="State"
          label-color="primary"
          color="primary"
          dense
        />
        <q-input
          filled
          v-model="customer.zipcode"
          label="Zipcode"
          label-color="primary"
          color="primary"
          mask="##### ####"
          dense
        />
        <q-input
          filled
          v-model="customer.phone"
          label="Phone"
          label-color="primary"
          color="primary"
          type="tel"
          mask="(###) ### - ####"
          dense
        />
        <q-input
          filled
          v-model="customer.email"
          type="email"
          label="Email"
          label-color="primary"
          color="primary"
          dense
          hide-bottom-space
          lazy-rules
          :rules="[isValidEmail]"
        />
        <div
          v-if="!isOpen"
          class="bg-grey-2 q-pt-xs q-pb-xs q-pl-sm rounded-borders"
        >
          <div class="q-pt-xs q-pb-xs q-pl-xs">
            <q-toggle
              v-model="customer.isActive"
              label="Is Active"
              color="primary"
              dense
            />
          </div>
          <div
            v-if="customer.isActive"
            class="q-ml-xs q-pt-sm text-caption text-primary"
          >
            Customer is available for Sources/{{ destAs }}
          </div>
          <div
            v-if="!customer.isActive"
            class="q-ml-xs q-pt-sm text-caption text-primary"
          >
            Customer is NOT available for Sources/{{ destAs }}
          </div>
        </div>
        <q-input
          filled
          v-model="customer.note"
          label="note"
          label-color="primary"
          color="primary"
          type="textarea"
          dense
        />
        <div class="lt-sm q-py-lg"></div>
      </q-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditCustomer',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    customerProp: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      customer: {},
      stateAbbreviations: [
        'AL',
        'AK',
        'AS',
        'AZ',
        'AR',
        'CA',
        'CO',
        'CT',
        'DE',
        'DC',
        'FM',
        'FL',
        'GA',
        'GU',
        'HI',
        'ID',
        'IL',
        'IN',
        'IA',
        'KS',
        'KY',
        'LA',
        'ME',
        'MH',
        'MD',
        'MA',
        'MI',
        'MN',
        'MS',
        'MO',
        'MT',
        'NE',
        'NV',
        'NH',
        'NJ',
        'NM',
        'NY',
        'NC',
        'ND',
        'MP',
        'OH',
        'OK',
        'OR',
        'PW',
        'PA',
        'PR',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VT',
        'VI',
        'VA',
        'WA',
        'WV',
        'WI',
        'WY'
      ]
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.setCustomer();
  },
  methods: {
    isValidEmail(val) {
      if (val === '' || val === null) {
        return true;
      } else {
        const emailPattern = /\S+@\S+\.\S+/;
        return emailPattern.test(val) || 'Invalid email';
      }
    },
    setCustomer() {
      this.customer = JSON.parse(JSON.stringify(this.customerProp));
    },
    submit() {
      this.$emit('onSubmit', this.customer);
    }
  },
  computed: {
    destAs() {
      return this.$store.state.settingsUsers.destAs;
    }
  },
  watch: {
    customerProp() {
      this.setCustomer();
    }
  }
};
</script>

<style scoped></style>
