<template>
  <div style="width: 100%" :id="driver.driver + '-container-div'">
    <q-scroll-area
      :style="`height: ${driver.tableRowHeight}px`"
      style="width: 500px"
      :id="driver.driver + '-scroll-area'"
    >
      <div
        class="row justify-center full-width text-bold text-body1 text-custom-red"
      >
        {{ driver.driver }}
      </div>
      <div class="row full-width justify-center q-py-sm">
        {{ headerDateStart }} -
        {{ headerDateEnd }}
      </div>

      <table
        style="width: 1500px; font-size: 13px"
        :id="driver.driver + '-table'"
      >
        <tr>
          <th>Date</th>
          <th>Source</th>
          <th>{{ destAs.slice(0, -1) }}</th>
          <th
            v-for="(haulTracker, trackerIndex) in driver.haulTrackers"
            :key="trackerIndex + 150 + haulTracker"
          >
            {{ haulTracker }}
          </th>
          <th>Haul Amount</th>
          <th v-if="driverInsightsSettings.calcAsGross">Gross Haul Amount</th>
          <th>Rate</th>
          <th>Total</th>
        </tr>
        <tr
          v-for="(haul, haulIndex) in driver.driverHauls"
          :key="haul.dateTime + (haulIndex + 100)"
          class="pd-sm"
        >
          <td>{{ haul.formatedDate }}</td>
          <td>
            {{ customers[haul.sourceCustomerId].name }} -
            {{ masterSources[haul.sourceLocationId].sourceName }}
          </td>
          <td>
            {{ customers[haul.destinationCustomerId].name }} -
            {{ setDestination(haul) }}
          </td>
          <td
            v-for="(haulTracker, trackerIndex) in haul.tdHaulTrackers"
            :key="trackerIndex + 200 + haulTracker"
          >
            {{ haulTracker }}
          </td>
          <td>{{ haul.haulAmount.toLocaleString('en-US') }}</td>
          <td v-if="driverInsightsSettings.calcAsGross">
            {{ haul.grossHaulAmount }}
          </td>
          <td>{{ haul.driverRateFormatted }}</td>
          <td>{{ haul.driverTotal }}</td>
        </tr>
        <tr
          v-for="commodity in driver.commodityTotals"
          :key="commodity.amount + 12345"
        >
          <td
            :colspan="calcColspan(driver)"
            style="text-align: right; font-size: 15px"
          >
            <span class="q-pr-sm"> {{ commodity.name }}: </span>
            <span class="q-pr-sm">
              {{ commodity.amount }}
              {{ commodity.MU }}
            </span>
          </td>
        </tr>
        <tr>
          <td
            :colspan="calcColspan(driver)"
            style="text-align: right; font-size: 15px"
            class="text-bold"
          >
            <span class="q-pr-sm">
              {{ driver.driverGrandTotal }}
            </span>
          </td>
        </tr>
      </table>
    </q-scroll-area>
  </div>
</template>

<script>
export default {
  name: 'DriverInsights',
  props: {
    driver: {
      type: Object,
      required: true
    },
    destAs: {
      type: String,
      required: true
    },
    headerDateStart: {
      type: String,
      required: true
    },
    headerDateEnd: {
      type: String,
      required: true
    },
    driverInsightsSettings: {
      type: Object,
      required: true
    },
    customers: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  mounted() {
    this.calcTableWidth();

    this.$nextTick(() => {
      window.addEventListener('resize', this.calcTableWidth);
    });
  },
  methods: {
    calcColspan(driver) {
      if (this.driverInsightsSettings.calcAsGross) {
        return driver.driverHauls[0].tdHaulTrackers.length + 7;
      } else {
        return driver.driverHauls[0].tdHaulTrackers.length + 6;
      }
    },
    calcTableWidth() {
      const driver = this.driver;

      let width = document.getElementById('main-header').offsetWidth;
      let driverTablesWidth =
        document.getElementById('driver-tables-main').offsetWidth;

      if (width - 16 === driverTablesWidth) {
        width += 16;
      }

      width -= 64;

      document.getElementById(driver.driver + '-table').style.width =
        width + 'px';

      setTimeout(() => {
        this.$nextTick(() => {
          let el = document.getElementById(driver.driver + '-table');
          if (el) {
            const height = el.offsetHeight + 70;

            document.getElementById(
              driver.driver + '-scroll-area'
            ).style.height = height + 'px';

            document.getElementById(
              driver.driver + '-scroll-area'
            ).style.width = width + 16 + 'px';
          }
        });
      }, 300);
    },
    setDestination(haul) {
      // This is for hauls TO sources that track running balance
      if (!haul.sourceAsDestination) {
        return this.masterDestinations[haul.destinationLocationId]
          .destinationName;
      } else {
        return this.masterSources[haul.destinationLocationId].sourceName;
      }
    }
  },
  computed: {
    masterDestinations() {
      return JSON.parse(JSON.stringify(this.$store.state.masterDestinations));
    },
    masterSources() {
      return JSON.parse(JSON.stringify(this.$store.state.masterSources));
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calcTableWidth);
  }
};
</script>

<style scoped>
table,
th,
td {
  padding: 3px;
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
}
</style>
