<template>
  <div>
    <div class="flex flex-center">
      <EditSourceUsers
        v-if="currentDisplay === 'editSource'"
        ref="editSource"
        :source="source"
        @onSubmit="setAction('list')"
        @layerTwoAttachments="setAction('layerTwoAttachments')"
      />

      <FilterData
        v-if="currentDisplay === 'filter'"
        ref="filter"
        :sourceInputs="sourceInputs"
        :sourceSortBy="sourceSortBy"
        :sourceInputSelected="sourceInputSelected"
        @onSubmit="setAction('list')"
      />

      <SourceTableUsers
        v-show="currentDisplay === 'list'"
        ref="sourceTableUsers"
        :sourceInputs="sourceInputs"
        :sourceSortBy="sourceSortBy"
        :sourceInputSelected="sourceInputSelected"
        @onEdit="onEdit"
      />
    </div>
  </div>
</template>

<script>
import EditSourceUsers from '@/components/sources/EditSourceUsers.vue';
import SourceTableUsers from '@/components/sources/SourceTableUsers.vue';
import FilterData from '@/components/sources/FilterData.vue';

export default {
  name: 'SourcesUsers',
  components: {
    EditSourceUsers,
    FilterData,
    SourceTableUsers
  },
  data() {
    return {
      currentDisplay: '',
      source: {}
    };
  },
  created() {
    this.$store.dispatch('setHeaderBtnsDisplay', true);
    this.setAction('list');
  },
  methods: {
    cancelLayerTwoInputs() {
      this.$refs.editSource.cancelLayerTwoInputs();
      this.setAction('editSource');
    },
    onEdit(source) {
      source.action = 'edit';
      this.source = source;
      this.setAction('editSource');
    },
    setAction(action) {
      if (action === 'list' || action === 'cancel') {
        this.currentDisplay = 'list';

        const headerBtns = [];
        if (!this.isGuestCustomer) {
          headerBtns.push({
            action: this.setAction,
            args: 'filter',
            label: 'Sort & Filter',
            color: 'primary',
            flat: false
          });
        }

        this.$store.dispatch('setHeaderBtns', headerBtns);
      } else if (action === 'editSource') {
        this.currentDisplay = 'editSource';
        this.$nextTick(() => {
          const headerBtns = [
            {
              action: this.$refs.editSource.cancel,
              args: '',
              label: 'Cancel',
              color: 'primary',
              flat: true
            },
            {
              action: this.$refs.editSource.onEnterSource,
              args: '',
              label: 'Submit',
              color: 'primary',
              flat: false
            }
          ];

          this.$store.dispatch('setHeaderBtns', headerBtns);
        });
      } else if (action === 'filter') {
        this.currentDisplay = 'filter';
        this.$nextTick(() => {
          const headerBtns = [
            {
              action: this.setAction,
              args: 'list',
              label: 'Cancel',
              color: 'primary',
              flat: true
            },
            {
              action: this.$refs.filter.submitFilter,
              args: '',
              label: 'Sort & Filter',
              color: 'primary',
              flat: false
            }
          ];

          this.$store.dispatch('setHeaderBtns', headerBtns);
        });
      } else if (action === 'layerTwoAttachments') {
        const headerBtns = [
          {
            action: this.cancelLayerTwoInputs,
            args: '',
            label: 'Done',
            color: 'primary',
            flat: false
          }
        ];

        this.$store.dispatch('setHeaderBtns', headerBtns);
      }
    }
  },
  computed: {
    isGuestCustomer() {
      return (
        this.$store.state.user.superCustomer || this.$store.state.user.customer
      );
    },
    sourceInputs() {
      return this.$store.state.filterLists.sourceInputs;
    },
    sourceInputSelected() {
      return this.$store.state.filterLists.sourceInputSelected;
    },
    sourceSortBy() {
      return this.$store.state.filterLists.sourceSortBy;
    }
  },
  watch: {
    currentDisplay(newVal) {
      if (newVal === 'list') {
        this.$refs.sourceTableUsers.highlightSource();
      }
    }
  }
};
</script>

<style scoped></style>
