<template>
  <div>
    <Destinations v-if="isAdmin" />

    <DestinationsUsers v-if="!isAdmin" />
  </div>
</template>

<script>
import Destinations from '@/components/destinations/Destinations.vue';
import DestinationsUsers from '@/components/destinations/DestinationsUsers.vue';

export default {
  name: 'MasterDestinations',
  components: {
    Destinations,
    DestinationsUsers
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    isAdmin() {
      const user = JSON.parse(JSON.stringify(this.$store.state.user));
      let isAdmin = false;
      if (user) {
        if (user.superAdmin === true || user.admin === true) {
          isAdmin = true;
        }
      }
      return isAdmin;
    }
  }
};
</script>

<style scoped></style>
