<template>
  <div>
    <Settings />
  </div>
</template>

<script>
import Settings from '@/components/settings/Settings.vue';

export default {
  name: 'MasterSettings',
  components: {
    Settings
  }
};
</script>

<style scoped></style>
