<template>
  <div class="row justify-center full-width q-pa-md">
    <PrintReport
      v-if="print"
      id="print"
      :destAs="destAs"
      :customers="customers"
      :reportData="reportData"
      :haulTrackers="haulTrackers"
      :haulTrackerHeaders="haulTrackerHeaders"
      :users="users"
      :isSource="isSource"
      :columnList="columnList"
    />

    <q-card
      class="row justify-center q-pa-md q-my-md"
      :class="$q.screen.gt.xs ? 'custom-width' : 'full-width'"
    >
      <div>
        <div
          class="row full-width justify-center text-bold text-body1 text-primary q-mb-sm"
        >
          Columns To Display
        </div>
        <div class="row full-width q-pb-xs">
          <q-option-group
            :options="columnOptions"
            dense
            color="custom-orange"
            type="checkbox"
            v-model="columnList"
            @input="columnListInput"
          />
        </div>
      </div>
    </q-card>

    <q-card class="row full-width q-my-md q-pa-md">
      <q-scroll-area
        horizontal
        :style="`width: 1250px; height: ${tableRowHeight}px`"
        :id="reportData.customerId + '-scroll-area'"
      >
        <div
          style="width: 1500px"
          :id="reportData.customerId + '-container-div'"
        >
          <div
            class="row justify-center full-width text-bold text-body1 text-primary"
          >
            {{ customers[reportData.customerId].name }}
          </div>
          <div
            v-if="!isSource"
            class="row justify-center full-width text-bold text-body1 text-custom-red q-mb-md"
          >
            {{ reportData.destinationName }}
          </div>
          <div
            v-if="isSource"
            class="row justify-center full-width text-bold text-body1 text-custom-red q-mb-md"
          >
            {{ reportData.sourceName }}
          </div>
          <div class="row justify-center full-width text-bold">Haul Report</div>

          <table
            style="width: 1500px; font-size: 13px"
            :id="reportData.customerId + '-table'"
          >
            <tr>
              <th v-if="showColumn('Date')">Date</th>
              <th v-if="showColumn('Driver')">Driver</th>
              <th v-if="showColumn('Source')">Source</th>
              <th v-if="showColumn(destAs.slice(0, -1))">
                {{ destAs.slice(0, -1) }}
              </th>
              <th
                v-for="(haulTracker, trackerIndex) in haulTrackerHeaders"
                :key="trackerIndex + 150 + haulTracker"
              >
                {{ haulTracker }}
              </th>
              <th v-if="showColumn('Haul Amount')">Haul Amount</th>
            </tr>
            <tr
              v-for="(haul, haulIndex) in reportData.hauls"
              :key="haul.dateTime + (haulIndex + 100)"
              class="pd-sm"
            >
              <td v-if="showColumn('Date')">
                {{ new Date(haul.dateTime).toDateString() }}
              </td>
              <td v-if="showColumn('Driver')">{{ users[haul.driver] }}</td>
              <td v-if="showColumn('Source')">
                {{ customers[haul.sourceCustomerId].name }} -
                {{ masterSources[haul.sourceLocationId].sourceName }}
              </td>
              <td v-if="showColumn(destAs.slice(0, -1))">
                {{ customers[haul.destinationCustomerId].name }} -
                {{ setDestination(haul) }}
              </td>
              <td
                v-for="(haulTracker, i) in haulTrackers[haulIndex]"
                :key="i + 250 + haulTracker"
              >
                {{ haulTracker }}
              </td>
              <td v-if="showColumn('Haul Amount')">{{ haul.haulAmount }}</td>
            </tr>
            <tr v-if="showColumn('Total')">
              <td
                :colspan="calcColspan()"
                style="text-align: right; font-size: 15px"
                class="text-bold"
              >
                <span class="q-pr-sm">
                  Total &nbsp; - &nbsp; {{ reportData.hauled }}
                  {{ reportData.measurementUnit }}
                </span>
              </td>
            </tr>
            <tr v-if="showColumn('Projected')">
              <td
                :colspan="calcColspan()"
                style="text-align: right; font-size: 15px"
                class="text-bold"
              >
                <span class="q-pr-sm">
                  Projected &nbsp; - &nbsp; {{ reportData.projected }}
                  {{ reportData.measurementUnit }}
                </span>
              </td>
            </tr>
            <tr v-if="showColumn('Balance')">
              <td
                :colspan="calcColspan()"
                style="text-align: right; font-size: 15px"
                class="text-bold"
              >
                <span class="q-pr-sm">
                  Balance &nbsp; - &nbsp; {{ reportData.balance }}
                  {{ reportData.measurementUnit }}
                </span>
              </td>
            </tr>
          </table>
        </div>
      </q-scroll-area>
      <div class="full-width q-pt-sm" align="right">
        <q-btn
          label="Print"
          icon="print"
          color="custom-light-blue"
          text-color="black"
          padding="sm lg"
          @click="printReport"
        />
      </div>
    </q-card>
  </div>
</template>

<script>
import PrintReport from '@/components/PrintReport.vue';
import { Printd } from 'printd';

export default {
  name: 'PrintableDestReport',
  components: {
    PrintReport
  },
  props: {
    reportData: {
      type: Object,
      required: true
    },
    isSource: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      columnList: [],
      columnOptions: [],
      tableDemCalculated: false,
      tableRowHeight: 150,
      print: false
    };
  },
  created() {
    this.calcTableRowHeight();
    this.createHaulTrackerList(false);
    this.setColumns();
  },
  mounted() {
    this.calcTableDeminsions();
    this.calcTableWidth();
    window.scrollTo(0, 0);
  },
  updated() {
    if (!this.tableDemCalculated) {
      this.calcTableDeminsions();
    }
  },
  methods: {
    calcColspan() {
      return this.haulTrackerHeaders.length + 5;
    },
    calcTableDeminsions() {
      this.tableDemCalculated = true;
      const id = this.reportData.customerId;

      let el = document.getElementById(id + '-table');
      if (el) {
        const height = el.offsetHeight + 95;
        document.getElementById(id + '-scroll-area').style.height =
          height + 'px';

        let width = document.getElementById('main-header').offsetWidth;

        document.getElementById(id + '-scroll-area').style.width = width + 'px';
      }
    },
    calcTableWidth() {
      this.$nextTick(() => {
        const id = this.reportData.customerId;

        let width = document.getElementById('main-header').offsetWidth;
        width = width < 1000 ? 1000 : width - 65;

        document.getElementById(id + '-container-div').style.width =
          width + 'px';
        document.getElementById(id + '-table').style.width = width + 'px';
      });
    },
    calcTableRowHeight() {
      this.calcTableRowHeight += this.reportData.hauls.length * 50;
    },
    columnListInput() {
      this.createHaulTrackerList(true);
    },
    createHaulTrackerList(filter) {
      this.haulTrackerHeaders = [];
      this.haulTrackers = [];

      const haulTrackerHeaders = [];

      for (let i = 0; i < this.reportData.hauls.length; i++) {
        const haul = this.reportData.hauls[i];

        for (let j = 0; j < haul.destinationHaulTrackers.length; j++) {
          haulTrackerHeaders.push(
            this.trackerLabel(haul.destinationHaulTrackers[j].type)
          );
        }

        for (let j = 0; j < haul.sourceHaulTrackers.length; j++) {
          haulTrackerHeaders.push(
            this.trackerLabel(haul.sourceHaulTrackers[j].type)
          );
        }
      }
      // Remove Duplicates
      this.haulTrackerHeaders = [...new Set(haulTrackerHeaders)];

      if (filter) {
        this.haulTrackerHeaders = this.haulTrackerHeaders.filter(header => {
          return this.columnList.includes(header);
        });
      }

      // Set up td Haul Trackers
      for (let i = 0; i < this.reportData.hauls.length; i++) {
        const tdHaulTrackers = [];
        for (let j = 0; j < this.haulTrackerHeaders.length; j++) {
          const haulTracker = this.haulTrackerHeaders[j];

          let val = '';
          this.reportData.hauls[i].destinationHaulTrackers.forEach(x => {
            if (this.trackerLabel(x.type) === haulTracker) {
              val = x.option;
            }
          });

          this.reportData.hauls[i].sourceHaulTrackers.forEach(x => {
            if (this.trackerLabel(x.type) === haulTracker) {
              val = x.option;
            }
          });

          tdHaulTrackers.push(val);
        }
        this.haulTrackers.push(tdHaulTrackers);
      }
    },
    printReport() {
      this.print = true;
      this.$nextTick(() => {
        const css = `
          table,
          th,
          td {
            border: 1px solid black;
            border-collapse: collapse;
            text-align: center;
            padding: 2px;
            font-size: 13px;
          }
          @page {
            size: landscape;
          }
        `;

        const printMe = document.getElementById('print');

        const d = new Printd();
        d.print(printMe, [css]);

        this.print = false;
      });
    },
    setColumns() {
      this.columnList = ['Date', 'Driver', 'Source', this.destAs.slice(0, -1)];
      this.columnOptions = [
        { label: 'Date', value: 'Date' },
        { label: 'Driver', value: 'Driver' },
        { label: 'Source', value: 'Source' },
        { label: this.destAs.slice(0, -1), value: this.destAs.slice(0, -1) }
      ];

      for (let i = 0; i < this.haulTrackerHeaders.length; i++) {
        this.columnList.push(this.haulTrackerHeaders[i]);
        this.columnOptions.push({
          label: this.haulTrackerHeaders[i],
          value: this.haulTrackerHeaders[i]
        });
      }

      this.columnList.push('Haul Amount', 'Total', 'Projected', 'Balance');
      this.columnOptions.push(
        { label: 'Haul Amount', value: 'Haul Amount' },
        { label: 'Total', value: 'Total' },
        { label: 'Projected', value: 'Projected' },
        { label: 'Balance', value: 'Balance' }
      );
    },
    setDestination(haul) {
      // This is for hauls TO sources that track running balance
      if (!haul.sourceAsDestination) {
        return this.masterDestinations[haul.destinationLocationId]
          .destinationName;
      } else {
        return this.masterSources[haul.destinationLocationId].sourceName;
      }
    },
    showColumn(col) {
      return this.columnList.includes(col);
    },
    trackerLabel(label) {
      if (label.startsWith('***&&&')) {
        return label.substring(9);
      }

      return label;
    }
  },
  computed: {
    customers() {
      return this.$store.state.customers;
    },
    destAs() {
      return this.$store.state.settingsUsers.destAs;
    },
    masterDestinations() {
      return JSON.parse(JSON.stringify(this.$store.state.masterDestinations));
    },
    masterSources() {
      return JSON.parse(JSON.stringify(this.$store.state.masterSources));
    },
    users() {
      return this.$store.state.userList.userIds;
    }
  }
};
</script>

<style scoped>
.custom-width {
  width: 500px;
}

table,
th,
td {
  padding: 3px;
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
}
</style>
