import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Account from '../views/Account.vue';
import Customers from '../views/Customers.vue';
import Sources from '../views/Sources.vue';
import Destinations from '../views/Destinations.vue';
import Hauls from '../views/Hauls.vue';
import Gmap from '../views/Map.vue';
import Reports from '../views/Reports.vue';
import TimeClock from '../views/TimeClock.vue';
import Settlement from '../views/Settlement.vue';
import Settings from '../views/Settings.vue';
import Info from '../views/Info.vue';

import { customRoutes } from '../custom/Routes.js';

(async () => {
  for (let i = 0; i < customRoutes.length; i++) {
    const path = customRoutes[i].componentPath;
    const { default: component } = await import(`../${path}`);

    router.addRoute({
      path: customRoutes[i].path,
      name: 'Custom' + customRoutes[i].name,
      component
    });
  }
})();

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/account',
    name: 'Account',
    component: Account
  },
  {
    path: '/customers',
    name: 'Customers',
    component: Customers
  },
  {
    path: '/sources',
    name: 'Sources',
    component: Sources
  },
  {
    path: '/destinations',
    name: 'Destinations',
    component: Destinations
  },
  {
    path: '/hauls',
    name: 'Hauls',
    component: Hauls
  },
  {
    path: '/map',
    name: 'Map',
    component: Gmap
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports
  },
  {
    path: '/time-clock',
    name: 'TimeClock',
    component: TimeClock
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings
  },
  {
    path: '/settlement',
    name: 'Settlement',
    component: Settlement
  },
  {
    path: '/info',
    name: 'Info',
    component: Info
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
