<template>
  <div id="popup-container" class="row items-center justify-center">
    <div id="opaque"></div>
    <div
      id="custom-popup"
      :style="
        $q.screen.xs
          ? `width: 100vw; height: ${scrollHeight + 104}px;`
          : 'width: 72vw; height: ${scrollHeight + 104}px;'
      "
    >
      <div class="bg-grey-3">
        <div
          class="row items-center full-width bg-grey-3 q-pl-lg q-pr-sm q-py-md"
        >
          <div>
            <q-btn
              v-if="
                (currentDisplay === 'destinationReport' ||
                  currentDisplay === 'sourceReport') &&
                !isGuestCustomer
              "
              color="primary"
              icon="image"
              padding="xs md"
              class="q-mr-sm"
              @click="onManageAttachments"
            />
            <q-btn
              v-if="
                (currentDisplay === 'destinationReport' ||
                  currentDisplay === 'sourceReport') &&
                !isCustomer
              "
              color="primary"
              icon="insights"
              padding="xs md"
              class="q-mr-sm"
              @click="showInsights"
            />
            <q-btn
              v-if="
                (currentDisplay === 'destinationReport' ||
                  currentDisplay === 'sourceReport') &&
                canEdit &&
                !isGuestCustomer
              "
              color="primary"
              icon="edit"
              padding="xs md"
              @click="edit"
            />
            <q-btn
              v-if="
                currentDisplay === 'manageAttachments' ||
                currentDisplay === 'destinationInsights' ||
                currentDisplay === 'editDestination' ||
                currentDisplay === 'sourceInsights' ||
                currentDisplay === 'editSource'
              "
              color="primary"
              icon="arrow_back"
              padding="xs md"
              class="q-mr-sm"
              @click="back"
            />
            <q-btn
              v-if="
                currentDisplay === 'editDestination' ||
                currentDisplay === 'editSource'
              "
              color="custom-red"
              label="submit"
              padding="xs md"
              @click="submitEdit"
            />
          </div>
          <q-space />
          <div>
            <q-btn
              unelevated
              icon="clear"
              color="grey-4"
              text-color="black"
              class="q-mr-md"
              padding="xs sm"
              @click="cancel"
            />
          </div>
        </div>
      </div>

      <div
        class="row justify-center items-center full-width text-body1 text-custom-light no-wrap q-pa-sm"
        style="background: radial-gradient(circle, #3336eb 0%, #0b0d6f 100%)"
      >
        <q-icon name="person" size="xs" color="custom-red" />
        <div class="text-body1 ellipsis q-ml-sm">
          {{ reportRaw.customer }}
        </div>
        <div class="q-mx-lg">|</div>
        <div v-if="reportType === 'source'" class="q-mr-sm ellipsis">
          {{ reportRaw.sourceName }}
        </div>
        <div v-if="reportType === 'destination'" class="q-mr-sm ellipsis">
          {{ reportRaw.destinationName }}
        </div>
        <q-icon name="place" size="xs" color="custom-red" />
      </div>

      <q-scroll-area
        :thumb-style="thumbStyle"
        :bar-style="barStyle"
        :style="scrollHeight"
        class="bg-white"
      >
        <div
          v-if="
            currentDisplay === 'manageAttachments' &&
            reportType === 'destination'
          "
          class="q-ma-sm"
        >
          <DestManageAttachments :location="locationObj" />
        </div>

        <div
          v-if="
            currentDisplay === 'manageAttachments' && reportType === 'source'
          "
          class="q-ma-sm"
        >
          <SourceManageAttachments :location="locationObj" />
        </div>

        <div
          v-if="currentDisplay === 'sourceInsights'"
          class="row justify-center full-width"
        >
          <SourceInsights :source="reportRaw" />
        </div>

        <div
          v-if="currentDisplay === 'destinationInsights'"
          class="row justify-center full-width"
        >
          <DestinationInsights :destination="reportRaw" />
        </div>

        <div
          v-if="currentDisplay === 'destinationReport'"
          class="q-mb-lg q-pa-md"
          :class="$q.screen.gt.xs ? 'q-ma-sm' : 'q-ma-sm'"
        >
          <DestinationReport
            v-if="reportType === 'destination' && isAdmin"
            :destination="reportRaw"
          />

          <DestinationReportUsers
            v-if="reportType === 'destination' && !isAdmin"
            :destination="reportRaw"
          />
        </div>

        <div
          v-if="currentDisplay === 'sourceReport'"
          class="q-mb-lg q-pa-md"
          :class="$q.screen.gt.xs ? 'q-ma-sm' : 'q-ma-sm'"
        >
          <SourceReport
            v-if="reportType === 'source' && isAdmin"
            :source="reportRaw"
          />

          <SourceReportUsers
            v-if="reportType === 'source' && !isAdmin"
            :source="reportRaw"
          />
        </div>

        <EditDestination
          v-if="currentDisplay === 'editDestination' && isAdmin"
          ref="editDestination"
          :settings="destSettings"
          :destination="reportRaw"
          :mapView="true"
          @onSubmit="() => {}"
          @setLocation="() => {}"
          @layerThreeLocations="() => {}"
        />

        <EditDestinationUsers
          v-if="currentDisplay === 'editDestination' && !isAdmin"
          ref="editDestinationUsers"
          :settings="destSettings"
          :destination="reportRaw"
          :mapView="true"
          @onSubmit="() => {}"
          @setLocation="() => {}"
          @layerThreeLocations="() => {}"
        />

        <EditSource
          v-if="currentDisplay === 'editSource' && isAdmin"
          ref="editSource"
          :settings="sourceSettings"
          :source="reportRaw"
          :mapView="true"
          @onSubmit="() => {}"
          @setLocation="() => {}"
          @layerThreeLocations="() => {}"
        />

        <EditSourceUsers
          v-if="currentDisplay === 'editSource' && !isAdmin"
          ref="editSourceUsers"
          :settings="sourceSettings"
          :source="reportRaw"
          :mapView="true"
          @onSubmit="() => {}"
          @setLocation="() => {}"
          @layerThreeLocations="() => {}"
        />
      </q-scroll-area>
    </div>
  </div>
</template>

<script>
import DestinationInsights from '@/components/destinations/DestinationInsights.vue';
import DestinationReport from '@/components/destinations/DestinationReport.vue';
import DestinationReportUsers from '@/components/destinations/DestinationReportUsers.vue';
import DestManageAttachments from '@/components/destinations/ManageAttachments.vue';
import EditDestination from '@/components/destinations/EditDestination.vue';
import EditDestinationUsers from '@/components/destinations/EditDestinationUsers.vue';
import EditSource from '@/components/sources/EditSource.vue';
import EditSourceUsers from '@/components/sources/EditSourceUsers.vue';
import SourceInsights from '@/components/sources/SourceInsights.vue';
import SourceManageAttachments from '@/components/sources/ManageAttachments.vue';
import SourceReport from '@/components/sources/SourceReport.vue';
import SourceReportUsers from '@/components/sources/SourceReportUsers.vue';

import {
  destinationReport,
  destinationReportUsers,
  sourceReport,
  sourceReportUsers
} from '@/utils/ReportHelpers';

export default {
  name: 'MapReport',
  components: {
    DestinationInsights,
    DestinationReport,
    DestinationReportUsers,
    DestManageAttachments,
    EditDestination,
    EditDestinationUsers,
    EditSource,
    EditSourceUsers,
    SourceInsights,
    SourceManageAttachments,
    SourceReport,
    SourceReportUsers
  },
  props: {
    archivedRaw: {
      type: Object,
      required: false
    },
    canEdit: {
      type: Boolean,
      default: false,
      required: false
    },
    estimates: {
      type: Object,
      required: false,
      default: () => {
        return {
          estimates: {
            estimates: {},
            userEstimates: {}
          }
        };
      }
    },
    isArchived: {
      type: Boolean,
      default: false,
      required: false
    },
    reportId: {
      type: String,
      required: true
    },
    reportType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      barStyle: {
        right: '0px',
        borderRadius: '3px',
        backgroundColor: '#bdbdbd',
        width: '6px',
        opacity: 0.2
      },
      currentDisplay: '',
      displayMapReport: true,
      locationObj: {},
      reportRaw: {},
      scrollHeight: 'height: 600px',
      thumbStyle: {
        right: '0px',
        borderRadius: '3px',
        backgroundColor: '#bdbdbd',
        width: '6px',
        opacity: 0.5
      }
    };
  },
  created() {
    this.setReportInfo();
  },
  mounted() {
    this.setScrollHeight();

    window.addEventListener('resize', this.setScrollHeight);
  },
  methods: {
    back() {
      if (this.reportType === 'source') {
        this.currentDisplay = 'sourceReport';
      } else {
        this.currentDisplay = 'destinationReport';
      }
    },
    cancel() {
      this.$emit('onCancel');
    },
    displayAttachments() {
      this.$emit('onDisplayAttachments');
    },
    edit() {
      if (this.reportType === 'source') {
        this.currentDisplay = 'editSource';
      } else {
        this.currentDisplay = 'editDestination';
      }
    },
    onManageAttachments() {
      this.locationObj = {
        id: this.reportRaw.locationId,
        ...this.reportRaw.location
      };
      this.currentDisplay = 'manageAttachments';
    },
    setReportInfo() {
      if (this.isArchived) {
        this.reportRaw = this.archivedRaw;
        if (this.reportType === 'source') {
          this.currentDisplay = 'sourceReport';
        } else {
          this.currentDisplay = 'destinationReport';
        }
        return;
      }

      if (this.isAdmin) {
        if (this.reportType === 'source') {
          this.reportRaw = sourceReport(this.reportId);
          this.currentDisplay = 'sourceReport';
        } else {
          if (this.destinationStatusFilter === 'openWaiting') {
            this.reportRaw = destinationReport(this.reportId);
          } else {
            const dest = this.estimates.estimates.find(dest => {
              return dest.id === this.reportId;
            });

            const destUsers = this.estimates.userEstimates.find(dest => {
              return dest.id === this.reportId;
            });

            this.reportRaw = destinationReport(
              this.reportId,
              true,
              dest,
              destUsers
            );
          }

          this.currentDisplay = 'destinationReport';
        }

        this.reportRaw.action = 'edit';
        return;
      }

      if (!this.isAdmin) {
        if (this.reportType === 'source') {
          this.reportRaw = sourceReportUsers(this.reportId);
          this.currentDisplay = 'sourceReport';
        } else {
          this.reportRaw = destinationReportUsers(this.reportId);
          this.currentDisplay = 'destinationReport';
        }

        this.reportRaw.action = 'edit';
      }
    },
    setScrollHeight() {
      this.$nextTick(() => {
        let windowHeight = window.innerHeight;
        this.scrollHeight = `height: ${windowHeight - 104}px`;
      });
    },
    showInsights() {
      if (this.reportType === 'source') {
        this.currentDisplay = 'sourceInsights';
      } else {
        this.currentDisplay = 'destinationInsights';
      }
    },
    submitEdit() {
      if (this.reportType === 'source') {
        if (this.isAdmin) {
          this.$refs.editSource.onVerifyFirst();
        } else {
          this.$refs.editSourceUsers.onEnterSource();
        }
        this.currentDisplay = 'sourceReport';
      } else {
        if (this.isAdmin) {
          this.$refs.editDestination.onVerifyFirst();
        } else {
          this.$refs.editDestinationUsers.onEnterDestination();
        }
        this.currentDisplay = 'destinationReport';
      }
    }
  },
  computed: {
    customerList() {
      return JSON.parse(JSON.stringify(this.$store.state.customers));
    },
    destSettings() {
      return this.$store.state.destinationSettings;
    },
    destinationStatusFilter() {
      return this.$store.state.filterLists.destinationStatusFilter;
    },
    isAdmin() {
      return this.$store.state.user.superAdmin || this.$store.state.user.admin;
    },
    isCustomer() {
      return this.$store.state.user.customer;
    },
    isGuestCustomer() {
      return (
        this.$store.state.user.superCustomer || this.$store.state.user.customer
      );
    },
    masterDestinations() {
      return JSON.parse(JSON.stringify(this.$store.state.masterDestinations));
    },
    masterSources() {
      return JSON.parse(JSON.stringify(this.$store.state.masterSources));
    },
    sourceSettings() {
      return this.$store.state.sourceSettings;
    },
    userList() {
      return JSON.parse(JSON.stringify(this.$store.state.userList));
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setScrollHeight);
  },
  watch: {
    '$store.state.activeDestinations'() {
      this.setReportInfo();
    },
    '$store.state.activeDestinationsUsers'() {
      this.setReportInfo();
    },
    '$store.state.activeSources'() {
      this.setReportInfo();
    },
    '$store.state.activeSourcesUsers'() {
      this.setReportInfo();
    }
  }
};
</script>

<style scoped>
#opaque {
  width: 100vw;
  height: 110vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #0b0d6f;
  opacity: 0.3;
  z-index: 2001;
}

#custom-popup {
  position: fixed;
  height: 100vh;
  z-index: 2102;
  top: 0;
}

#popup-container {
  width: 100vw;
  height: 110vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2000;
}
</style>
