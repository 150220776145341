<template>
  <div>
    <OkDialog
      v-if="okDialog"
      icon="person"
      :message="message"
      @okConfirmed="okDialog = false"
    />

    <ConfirmDialog
      v-if="confirm"
      icon="person"
      :btn="btn"
      message="Are you sure you want to delete customer?"
      :headerMessage="customer.name"
      @canceled="confirmDelete = false"
      @confirmed="onDelete"
    />

    <div class="flex flex-center">
      <CustomersDisplay
        v-if="currentDisplay === 'list'"
        :customerListFiltered="customerListFiltered"
        :customerList="customerList"
        @editCustomer="editCustomer"
        @onSetCustomers="onSetCustomers"
        @onSearchByStr="onSearchByStr"
      />

      <EditCustomer
        v-if="currentDisplay === 'editCustomer'"
        ref="editCustomer"
        :customerProp="customer"
        :isOpen="isOpen"
        @backToCustomers="backToCustomers"
        @onSubmit="onSubmit"
      />
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import CustomersDisplay from '@/components/CustomersDisplay.vue';
import EditCustomer from '@/components/EditCustomer.vue';
import OkDialog from '@/components/OkDialog.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';

const db = firebase.firestore();

export default {
  name: 'Customers-Main',
  components: {
    CustomersDisplay,
    EditCustomer,
    OkDialog,
    ConfirmDialog
  },
  data() {
    return {
      btn: {
        label: 'Ok',
        icon: 'check',
        color: 'primary'
      },
      isOpen: false,
      confirm: false,
      currentDisplay: 'list',
      custBeforeEdit: {
        name: '',
        note: ''
      },
      customer: {
        name: null,
        id: null,
        businessName: null,
        address: null,
        addressLine2: null,
        city: null,
        state: null,
        zipcode: null,
        phone: null,
        email: null,
        isActive: null,
        note: '',
        isNewCustomer: true
      },
      customerList: [],
      customerListFiltered: [],
      inActiveMode: false,
      listType: 'active',
      okDialog: false,
      isNewCustomer: false,
      search: '',
      selectedCustomer: null
    };
  },
  created() {
    this.$store.dispatch('setHeaderBtnsDisplay', true);
    this.setAction('activeList');
    this.setCustomers('active');
  },
  methods: {
    backToCustomers() {
      this.onReset();
      this.setAction('activeList');
    },
    confirmDelete() {
      this.confirm = true;
    },
    custIsOpen() {
      const destinations = JSON.parse(
        JSON.stringify(this.$store.state.activeDestinations)
      );
      const sources = JSON.parse(
        JSON.stringify(this.$store.state.activeSources)
      );

      let isOpen = false;

      for (let i = 0; i < destinations.length; i++) {
        if (destinations[i].customerId === this.customer.id) {
          isOpen = true;
          this.isOpen = false;
          break;
        }
      }

      for (let i = 0; i < sources.length; i++) {
        if (sources[i].customerId === this.customer.id) {
          isOpen = true;
          this.isOpen = false;
          break;
        }
      }

      return isOpen;
    },
    editCustomer(index) {
      this.customerListFiltered[index].spinner = true;

      const hideSpinner = () => {
        this.customerListFiltered[index].spinner = false;
      };

      const customerName = this.customerListFiltered[index].name;
      db.collection('customers')
        .where('name', '==', customerName)
        .get()
        .then(docs => {
          docs.forEach(doc => {
            if (doc.data().name === customerName) {
              this.customer = doc.data();
              this.customer.id = doc.id;
            }
          });

          this.custBeforeEdit = {
            name: this.customer.name,
            note: this.customer.note
          };

          this.customer.isNewCustomer = false;
          hideSpinner();
          this.setAction('editCustomer');
        });
      this.isNewCustomer = false;
    },
    onDelete() {
      this.confirm = false;
      this.customerList = [];
      this.customerListFiltered = [];

      const masterSplitDestinations = JSON.parse(
        JSON.stringify(this.$store.state.masterSplitDestinations)
      );
      const masterSplitSources = JSON.parse(
        JSON.stringify(this.$store.state.masterSplitSources)
      );

      const custRef = db.collection('customers').doc(this.customer.id);
      const custListRef = db.collection('settings').doc('customer_list');
      const usersCustListRef = db
        .collection('settings_users')
        .doc('customer_list');

      const batch = db.batch();

      batch.delete(custRef);
      batch.update(custListRef, {
        [this.customer.id]: firebase.firestore.FieldValue.delete()
      });
      batch.update(usersCustListRef, {
        [this.customer.id]: firebase.firestore.FieldValue.delete()
      });

      const delSourceArr = [];
      for (const key in masterSplitSources) {
        const splitSource = masterSplitSources[key];
        const delObj = {};
        // Does not work to do batch.update in this loop!
        for (const splitKey in splitSource) {
          if (this.customer.id === splitSource[splitKey].customerId) {
            delObj[splitKey] = firebase.firestore.FieldValue.delete();
          }
        }
        if (Object.keys(delObj).length) {
          delSourceArr.push({ doc: key, delObj: delObj });
        }
      }

      for (let i = 0; i < delSourceArr.length; i++) {
        const destLocationRef = db
          .collection('master_locations')
          .doc(delSourceArr[i].doc);

        const delObj = delSourceArr[i].delObj;
        batch.update(destLocationRef, delObj);
      }

      const delDestArr = [];
      const delAttachmentsArr = [];
      for (const key in masterSplitDestinations) {
        const splitDest = masterSplitDestinations[key];
        const delObj = {};
        // Does not work to do batch.update in this loop!
        for (const splitKey in splitDest) {
          if (this.customer.id === splitDest[splitKey].customerId) {
            delObj[splitKey] = firebase.firestore.FieldValue.delete();
            const attachmentId = splitDest[splitKey].attachmentId;
            delAttachmentsArr.push(attachmentId);
          }
        }
        if (Object.keys(delObj).length) {
          delDestArr.push({ doc: key, delObj: delObj });
        }
      }

      for (let i = 0; i < delAttachmentsArr.length; i++) {
        const attachmentId = delAttachmentsArr[i];

        if (attachmentId) {
          const attachmentRef = db
            .collection('master_attachments')
            .doc(attachmentId);

          batch.delete(attachmentRef);
        }
      }

      for (let i = 0; i < delDestArr.length; i++) {
        const destLocationRef = db
          .collection('master_locations')
          .doc(delDestArr[i].doc);

        const delObj = delDestArr[i].delObj;
        batch.update(destLocationRef, delObj);
      }

      batch.commit().catch(err => {
        console.error(err);
      });
      this.$q.notify({
        color: 'custom-red',
        textColor: 'white',
        icon: 'account_box',
        message: 'Customer Deleted'
      });

      this.onReset();
      if (this.inActiveMode) {
        this.setCustomers('inActive');
      } else {
        this.setCustomers('active');
      }
      this.setAction('activeList');
    },
    onReset() {
      this.customer = {
        name: null,
        id: null,
        businessName: null,
        address: null,
        addressLine2: null,
        city: null,
        state: null,
        zipcode: null,
        phone: null,
        email: null,
        isActive: true,
        note: '',
        isNewCustomer: true
      };

      this.searchStr = '';
      this.onSetCustomers();
    },
    onSearchByStr(searchStr) {
      this.customerListFiltered = [];

      if (searchStr === '') {
        this.onSetCustomers();
      } else {
        for (let i = 0; i < this.customerList.length; i++) {
          if (
            this.customerList[i].name
              .toUpperCase()
              .includes(searchStr.toUpperCase())
          ) {
            this.customerListFiltered.push(this.customerList[i]);
          }
        }
      }
    },
    onSetCustomers() {
      this.customerListFiltered = this.customerList.slice();
    },
    onSubmit(customer) {
      // Trim white-space & convert first char of ever word to upper
      const format = str => {
        str = str.trim();
        return str.replace(/(^\w{1})|(\s+\w{1})/g, str => str.toUpperCase());
      };
      customer.name = format(customer.name);
      customer.name = customer.name.replaceAll('.', '');

      const yesSubmit = isNew => {
        customer.businessName =
          customer.businessName === null ? null : format(customer.businessName);
        customer.address =
          customer.address === null ? null : format(customer.address);
        customer.addressLine2 =
          customer.addressLine2 === null ? null : format(customer.addressLine2);
        customer.city = customer.city === null ? null : format(customer.city);
        customer.email = customer.email === null ? null : customer.email.trim();
        customer.note = customer.note === '' ? '' : customer.note.trim();

        const custObj = {
          name: customer.name,
          businessName: customer.businessName,
          address: customer.address,
          addressLine2: customer.addressLine2,
          city: customer.city,
          state: customer.state,
          zipcode: customer.zipcode,
          phone: customer.phone,
          email: customer.email,
          isActive: customer.isActive,
          note: customer.note
        };

        const batch = db.batch();
        const custListRef = db.collection('settings').doc('customer_list');
        const superCustListRef = db
          .collection('settings_users')
          .doc('customer_list');

        let custRef;
        let custListObj;
        let superCustListObj;
        if (isNew) {
          custRef = db.collection('customers').doc();
          custListObj = {
            [custRef.id]: {
              name: customer.name,
              note: customer.note,
              isActive: customer.isActive
            }
          };
          superCustListObj = {
            [custRef.id]: {
              name: customer.name
            }
          };
        } else {
          custRef = db.collection('customers').doc(customer.id);
          custListObj = {
            [customer.id]: {
              name: customer.name,
              note: customer.note,
              isActive: customer.isActive
            }
          };
          if (customer.isActive) {
            superCustListObj = {
              [customer.id]: {
                name: customer.name
              }
            };
          } else {
            superCustListObj = {
              [customer.id]: firebase.firestore.FieldValue.delete()
            };
          }
        }

        batch.set(custRef, custObj);
        batch.update(custListRef, custListObj);
        batch.update(superCustListRef, superCustListObj);

        batch.commit().catch(err => {
          console.error(err);
        });

        this.customerList = [];
        this.customerListFiltered = [];

        this.onReset();
        if (this.inActiveMode) {
          this.setCustomers('inActive');
        } else {
          this.setCustomers('active');
        }
      };

      if (this.isNewCustomer === true) {
        const custList = JSON.parse(
          JSON.stringify(this.$store.state.customers)
        );

        let duplicate = false;
        for (let i = 0; i < custList.length; i++) {
          if (custList[i].name === customer.name) {
            duplicate = true;
            break;
          }
        }

        if (duplicate) {
          this.okDialog = true;
          this.message = `'${customer.name}' already exists!`;
          return false;
        } else {
          yesSubmit(true);
          this.$q.notify({
            color: 'primary',
            textColor: 'white',
            icon: 'account_box',
            message: 'Customer Submitted'
          });
        }
      } else {
        if (this.custBeforeEdit.name === customer.name) {
          yesSubmit(false);
          this.$q.notify({
            color: 'primary',
            textColor: 'white',
            icon: 'account_box',
            message: 'Customer Submitted'
          });
        } else {
          yesSubmit(false);
        }
      }
    },
    setAction(action) {
      if (action === 'activeList') {
        this.inActiveMode = false;
        this.currentDisplay = 'list';

        const headerBtns = [
          {
            action: this.setCustomers,
            args: 'inActive',
            label: 'Show Inactive',
            color: 'primary',
            flat: true
          },
          {
            action: this.setCustomer,
            args: '',
            label: 'Add',
            color: 'primary',
            flat: false
          }
        ];

        this.$store.dispatch('setHeaderBtns', headerBtns);
      } else if (action === 'inActiveList') {
        this.inActiveMode = true;
        this.currentDisplay = 'list';

        const headerBtns = [
          {
            action: this.setCustomers,
            args: 'active',
            label: 'Show Active',
            color: 'primary',
            flat: true
          },
          {
            action: this.setCustomer,
            args: '',
            label: 'Add',
            color: 'primary',
            flat: false
          }
        ];

        this.$store.dispatch('setHeaderBtns', headerBtns);
      } else if (action === 'editCustomer') {
        this.currentDisplay = 'editCustomer';

        const headerBtns = [
          {
            action: this.setList,
            args: '',
            label: 'Cancel',
            color: 'primary',
            flat: true
          }
        ];

        if (!this.custIsOpen() && !this.customer.isNewCustomer) {
          headerBtns.push({
            action: this.confirmDelete,
            args: '',
            label: 'Delete',
            color: 'custom-red',
            flat: true
          });
        }

        if (this.customer.isNewCustomer) {
          headerBtns.push({
            action: this.submitCustomerInit,
            args: '',
            label: 'Add',
            color: 'primary',
            flat: false
          });
        } else {
          headerBtns.push({
            action: this.submitCustomerInit,
            args: '',
            label: 'Update',
            color: 'primary',
            flat: false
          });
        }

        this.$store.dispatch('setHeaderBtns', headerBtns);
      }
    },
    setCustomer() {
      this.onReset();
      this.isNewCustomer = true;
      this.setAction('editCustomer');
    },
    setList() {
      this.onReset();
      if (this.inActiveMode) {
        this.setAction('inActiveList');
      } else {
        this.setAction('activeList');
      }
    },
    setCustomers(type) {
      this.customerList = [];
      this.customerListFiltered = [];

      const customersUnfiltered = JSON.parse(
        JSON.stringify(this.$store.state.customers)
      );
      const colors = JSON.parse(JSON.stringify(this.$store.state.colors));

      const customers = {};

      for (const key in customersUnfiltered) {
        if (type === 'active') {
          if (customersUnfiltered[key].isActive) {
            customers[key] = customersUnfiltered[key];
          }
          this.setAction('activeList');
          this.listType = 'active';
        } else if (type === 'inActive') {
          if (!customersUnfiltered[key].isActive) {
            customers[key] = customersUnfiltered[key];
          }
          this.setAction('inActiveList');
          this.listType = 'inActive';
        }
      }

      const sortByCustomer = (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      };

      for (const custKey in customers) {
        const customer = customers[custKey];
        customer.id = custKey;
        customer.spinner = false;

        this.customerList.push(customer);
      }

      this.customerList.sort(sortByCustomer);

      let colorIndex = 0;
      for (let i = 0; i < this.customerList.length; i++) {
        this.customerList[i].color = colors[colorIndex];

        colorIndex++;
        if (colorIndex === 7) {
          colorIndex = 0;
        }
      }

      this.customerListFiltered = this.customerList.slice();
    },
    submitCustomerInit() {
      this.$refs.editCustomer.submit();
    }
  },
  watch: {
    '$store.state.customers'() {
      this.setCustomers(this.listType);
    }
  }
};
</script>

<style scoped></style>
