<template>
  <div class="row justify-center">
    <CommodityTypes
      v-if="manageCommodities"
      :commodities="settings.commodities"
      @backToSettings="backToSettings"
    />

    <div v-if="!manageCommodities">
      <div class="q-pa-md q-mt-sm form">
        <div class="gt-xs q-py-md"></div>
        <q-form class="q-gutter-sm">
          <div
            class="row justify-center items-center rounded-borders text-custom-light q-pl-sm q-py-sm q-mb-md"
            style="
              background: radial-gradient(circle, #3336eb 0%, #0b0d6f 100%);
            "
          >
            <q-icon name="settings" size="md" class="q-mr-sm" />
            <span class="text-body1"> Settings </span>
          </div>

          <div class="bg-grey-2 q-pt-xs q-pb-xs rounded-borders">
            <div class="row items-center q-pt-xs q-pb-sm q-pl-md q-pr-md">
              <div class="text-bold">Commodities</div>
              <q-space />
              <div class="text-bold">Measurement Unit</div>
              <q-separator class="full-width" />
            </div>
            <div
              v-for="(commodity, index) in settings.commodities"
              :key="index"
              class="row q-mx-md q-mb-sm"
            >
              <div class="text-body1 text-bold text-grey-7">
                {{ commodity.name }}
              </div>
              <q-space />
              <div>{{ commodity.measurementUnit }}</div>
              <q-separator class="full-width" />
            </div>
            <div class="row items-center q-pt-sm q-pb-sm q-pl-md q-pr-md">
              <q-space />
              <q-btn
                v-if="!Object.keys(settings.commodities).length"
                outline
                label="Add"
                icon="add"
                color="primary"
                size="sm"
                @click="onManageCommodities"
              />
              <q-btn
                v-if="Object.keys(settings.commodities).length"
                outline
                label="Edit"
                icon="edit"
                color="primary"
                size="sm"
                @click="onManageCommodities"
              />
            </div>
          </div>
          <q-input
            filled
            v-model="settings.businessName"
            label="Business Name"
            label-color="primary"
            color="primary"
            maxlength="60"
            dense
          />
          <q-input
            filled
            v-model="settings.businessAddress"
            label="Business Address"
            label-color="primary"
            color="primary"
            maxlength="40"
            dense
          />
          <q-input
            filled
            v-model="settings.businessCityStZip"
            label="City, St Zip"
            label-color="primary"
            color="primary"
            dense
          />
          <q-input
            filled
            v-model="settings.businessEmail"
            type="email"
            label="Business Email"
            label-color="primary"
            color="primary"
            dense
            hide-bottom-space
            lazy-rules
            :rules="[isValidEmail]"
          />
          <q-input
            filled
            v-model="settings.defaultSReportNote"
            label="Default Settlement Report Note"
            label-color="primary"
            color="primary"
            dense
          />

          <div class="bg-grey-2 rounded-borders">
            <div class="text-bold q-pl-sm q-pt-sm">
              <span class="q-pl-xs"> Default Invoice Type </span>
            </div>
            <q-radio
              v-model="settings.defaultSReportType"
              color="primary"
              val="itemized"
              label="Itemized"
              style="padding-left: 5px"
              size="xs"
            />
            <q-radio
              v-model="settings.defaultSReportType"
              color="primary"
              val="condensed"
              label="Condensed"
              size="xs"
            />
          </div>

          <div class="bg-grey-2 rounded-borders">
            <div class="text-bold q-pl-sm q-pt-sm">
              <span class="q-pl-xs">Destination As</span>
            </div>
            <q-radio
              v-model="settings.destAs"
              color="primary"
              val="Destinations"
              label="Destinations"
              style="padding-left: 5px"
              size="xs"
            />
            <q-radio
              v-model="settings.destAs"
              color="primary"
              val="Fields"
              label="Fields"
              size="xs"
            />
            <q-radio
              v-model="settings.destAs"
              color="primary"
              val="Work Orders"
              label="Work Orders"
              size="xs"
            />
          </div>

          <!--<div v-if="superAdmin" class="row q-mb-xl" style="margin-top: 2000px">-->
          <!--<div class="text-custom-red q-mb-md">-->
          <!--This Button is for more convenient Developer Maintenence. In very-->
          <!--rare instances your app may load with this button included in the-->
          <!--build if your are a super user. PLEASE DO NOT PRESS THIS-->
          <!--BUTTON!!!!! DATA MAY BE CORRUPTED!!!!-->
          <!--</div>-->
          <!--<q-btn-->
          <!--label="Special Update Button - Do Not Push!!"-->
          <!--icon="warning"-->
          <!--color="custom-red"-->
          <!--@click="specialTwo"-->
          <!--/>-->
          <!--</div>-->

          <div class="lt-sm q-py-lg"></div>
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
//import { date } from 'quasar';
import CommodityTypes from '@/components/settings/CommodityTypes.vue';
import firebase from 'firebase/compat/app';

const db = firebase.firestore();

export default {
  name: 'Settings-Main',
  components: {
    CommodityTypes
  },
  data() {
    return {
      manageCommodities: false,
      settings: {
        commodities: [],
        businessName: '',
        businessAddress: '',
        businessCityStZip: '',
        businessEmail: '',
        defaultSReportNote: '',
        defaultSReportType: null,
        destAs: 'Destinations'
      }
    };
  },
  created() {
    this.$store.dispatch('setHeaderBtnsDisplay', true);
    const headerBtns = [
      {
        action: this.onSubmit,
        args: '',
        label: 'Update Settings',
        color: 'primary',
        flat: false
      }
    ];

    this.$store.dispatch('setHeaderBtns', headerBtns);
  },
  mounted() {
    window.scrollTo(0, 0);
    this.setSettings();
  },
  methods: {
    backToSettings() {
      this.manageCommodities = false;

      const headerBtns = [
        {
          action: this.onSubmit,
          args: '',
          label: 'Update Settings',
          color: 'primary',
          flat: false
        }
      ];

      this.$store.dispatch('setHeaderBtns', headerBtns);
    },
    onManageCommodities() {
      this.manageCommodities = true;

      const headerBtns = [
        {
          action: this.backToSettings,
          args: '',
          label: 'Done',
          color: 'primary',
          flat: false
        }
      ];

      this.$store.dispatch('setHeaderBtns', headerBtns);
    },
    async special() {
      console.log('special');
      //const batch = db.batch();

      ///////////////////////////////////////////////////////////////////////////

      // sReport updates

      ///////////////////////////////////////////////////////////////////////////

      //let customers;

      //await db
      //.collection('settings_users')
      //.doc('customer_list')
      //.get()
      //.then(doc => {
      //console.log(doc.data());
      //customers = doc.data();

      //for (const key in customers) {
      //delete customers[key].isActive;
      //}

      //return true;
      //});

      //console.log(customers);

      //const customerRef = db.collection('settings_users').doc('customer_list');

      //customerRef.set(customers);

      ///////////////////////////////////////////////////////////////////////////

      // sReport updates

      ///////////////////////////////////////////////////////////////////////////

      //const sReports = [];

      //await db
      //.collection('settlement_reports')
      //.get()
      //.then(querySnapshot => {
      //querySnapshot.forEach(doc => {
      //// doc.data() is never undefined for query doc snapshots
      //const sReport = doc.data();
      //sReport.id = doc.id;
      //sReports.push(sReport);
      //console.log(doc.id, ' => ', doc.data());
      //});
      //return true;
      //});

      //console.log(sReports);

      //for (let i = 0; i < sReports.length; i++) {
      //const sReportsDoc = db
      //.collection('settlement_reports')
      //.doc(sReports[i].id);

      //const selectedOptions = sReports[i].selectedOptions;

      //console.log(sReports[i].id);
      //for (let j = 0; j < selectedOptions.length; j++) {
      //selectedOptions[j].filterByDate = false;
      //selectedOptions[j].filterServicesByDate = false;
      //selectedOptions[j].hauledFiltered = 0;
      //selectedOptions[j].haulDates = {
      //fromTs: '',
      //toTs: ''
      //};
      //}

      ////const QB = {
      ////qbInvoice: false
      ////};
      //console.log(selectedOptions);
      ////console.log(QB);

      //batch.update(sReportsDoc, { selectedOptions });
      //}

      //batch.commit().catch(err => {
      //console.log(err);
      //});
    },
    async specialTwo() {
      console.log('specialTwo');
      //const batch = db.batch();

      ///////////////////////////////////////////////////////////////////////////

      // Add important note

      //const activeDest = [];
      //const archivedDest = [];
      //const activeDestUsers = [];
      //const archivedDestUsers = [];

      //await db
      //.collection('destinations_active')
      //.get()
      //.then(querySnapshot => {
      //querySnapshot.forEach(doc => {
      //// doc.data() is never undefined for query doc snapshots
      //const dest = doc.data();
      //dest.id = doc.id;
      //activeDest.push(dest);
      ////console.log(doc.id, ' => ', doc.data());
      //});
      //return true;
      //});

      //await db
      //.collection('destinations_archived')
      //.get()
      //.then(querySnapshot => {
      //querySnapshot.forEach(doc => {
      //// doc.data() is never undefined for query doc snapshots
      //const dest = doc.data();
      //dest.archivedId = doc.id;
      //archivedDest.push(dest);
      ////console.log(doc.id, ' => ', doc.data());
      //});
      //return true;
      //});

      //await db
      //.collection('destinations_active_users')
      //.get()
      //.then(querySnapshot => {
      //querySnapshot.forEach(doc => {
      //// doc.data() is never undefined for query doc snapshots
      //const dest = doc.data();
      //dest.id = doc.id;
      //activeDestUsers.push(dest);
      ////console.log(doc.id, ' => ', doc.data());
      //});
      //return true;
      //});

      //await db
      //.collection('destinations_archived_users')
      //.get()
      //.then(querySnapshot => {
      //querySnapshot.forEach(doc => {
      //// doc.data() is never undefined for query doc snapshots
      //const dest = doc.data();
      //dest.archivedId = doc.id;
      //archivedDestUsers.push(dest);
      ////console.log(doc.id, ' => ', doc.data());
      //});
      //return true;
      //});

      //console.log(activeDest);
      //console.log(archivedDest);
      //console.log(activeDestUsers);
      //console.log(archivedDestUsers);

      //for (let i = 0; i < activeDest.length; i++) {
      //const index = activeDestUsers.findIndex(x => x.id === activeDest[i].id);
      //console.log(index);
      //console.log(activeDestUsers[index].id);
      //console.log(activeDest[i].id);
      //const destDoc = db
      //.collection('destinations_active')
      //.doc(activeDest[i].id);

      //const destObj = {
      //startDate: activeDestUsers[index].startDate
      //};
      //if (index < 0) {
      //console.log('%cno match!!', 'color: red');
      //continue;
      //}

      //console.log(destObj);

      //console.log(destDoc);
      //batch.update(destDoc, destObj);
      //}

      //for (let i = 0; i < 489; i++) {
      //for (let i = 489; i < archivedDestUsers.length; i++) {
      //for (let i = 0; i < archivedDestUsers.length; i++) {
      //const index = archivedDestUsers.findIndex(
      //x => x.id === archivedDest[i].id
      //);
      //console.log(index);

      //if (index < 0) {
      //console.log('%cno match!!', 'color: red');
      //continue;
      //}

      //const archivedDestDoc = db
      //.collection('destinations_archived')
      //.doc(archivedDest[i].archivedId);

      //const destObj = {
      //startDate: archivedDestUsers[index].startDate
      //};

      //batch.update(archivedDestDoc, destObj);
      //}

      //for (let i = 0; i < activeDestUsers.length; i++) {
      //const destUsersDoc = db
      //.collection('destinations_active_users')
      //.doc(activeDestUsers[i].id);

      //const destObj = {
      //dispatchedTo: []
      //};

      //console.log('destUsers', destObj);

      //batch.update(destUsersDoc, destObj);
      //}

      //for (let i = 489; i < archivedDestUsers.length; i++) {
      //for (let i = 0; i < archivedDestUsers.length; i++) {
      //const archivedDestUsersDoc = db
      //.collection('destinations_archived_users')
      //.doc(archivedDestUsers[i].id);

      //const destObj = {
      //dispatchedTo: []
      //};

      //console.log('destUsers', destObj);

      //batch.update(archivedDestUsersDoc, destObj);
      //}

      //const activeSources = [];
      //const archivedSources = [];
      //const activeSourcesUsers = [];
      //const archivedSourcesUsers = [];

      //await db
      //.collection('sources_active')
      //.get()
      //.then(querySnapshot => {
      //querySnapshot.forEach(doc => {
      //// doc.data() is never undefined for query doc snapshots
      //const source = doc.data();
      //source.id = doc.id;
      //activeSources.push(source);
      ////console.log(doc.id, ' => ', doc.data());
      //});
      //return true;
      //});

      //await db
      //.collection('sources_archived')
      //.get()
      //.then(querySnapshot => {
      //querySnapshot.forEach(doc => {
      //// doc.data() is never undefined for query doc snapshots
      //const source = doc.data();
      //source.archivedId = doc.id;
      //archivedSources.push(source);
      ////console.log(doc.id, ' => ', doc.data());
      //});
      //return true;
      //});

      //await db
      //.collection('sources_active_users')
      //.get()
      //.then(querySnapshot => {
      //querySnapshot.forEach(doc => {
      //// doc.data() is never undefined for query doc snapshots
      //const source = doc.data();
      //source.id = doc.id;
      //activeSourcesUsers.push(source);
      ////console.log(doc.id, ' => ', doc.data());
      //});
      //return true;
      //});

      //await db
      //.collection('sources_archived_users')
      //.get()
      //.then(querySnapshot => {
      //querySnapshot.forEach(doc => {
      //// doc.data() is never undefined for query doc snapshots
      //const source = doc.data();
      //source.archivedId = doc.id;
      //archivedSourcesUsers.push(source);
      ////console.log(doc.id, ' => ', doc.data());
      //});
      //return true;
      //});

      //console.log(activeSources);
      //console.log(archivedSources);
      //console.log(activeSourcesUsers);
      //console.log(archivedSourcesUsers);

      //for (let i = 0; i < activeSources.length; i++) {
      //const index = activeSourcesUsers.findIndex(
      //x => x.id === activeSources[i].id
      //);
      //console.log(index);

      //if (index < 0) {
      //console.log('%cno match!!', 'color: red');
      //continue;
      //}

      //const sourceDoc = db
      //.collection('sources_active')
      //.doc(activeSources[i].id);

      //const sourceObj = {
      //startDate: activeSourcesUsers[index].startDate
      //};

      //batch.update(sourceDoc, sourceObj);
      //}

      //for (let i = 0; i < archivedSources.length; i++) {
      //console.log(archivedSources[i].id);
      //const index = archivedSourcesUsers.findIndex(x => {
      //console.log(x);
      //return x.id === archivedSources[i].id;
      //});
      //console.log(index);

      //if (index < 0) {
      //console.log('%cno match!!', 'color: red');
      //continue;
      //}

      //const archivedSourceDoc = db
      //.collection('sources_archived')
      //.doc(archivedSources[i].archivedId);

      //const sourceObj = {
      //startDate: archivedSourcesUsers[index].startDate
      //};

      //batch.update(archivedSourceDoc, sourceObj);
      //}

      //for (let i = 0; i < activeSourcesUsers.length; i++) {
      //const sourceUsersDoc = db
      //.collection('sources_active_users')
      //.doc(activeSourcesUsers[i].id);

      //const sourceObj = {
      //dispatchedTo: []
      //};

      //batch.update(sourceUsersDoc, sourceObj);
      //}

      //for (let i = 0; i < archivedSourcesUsers.length; i++) {
      //const archivedSourceUsersDoc = db
      //.collection('sources_archived_users')
      //.doc(archivedSourcesUsers[i].id);

      //const sourceObj = {
      //dispatchedTo: []
      //};

      //batch.update(archivedSourceUsersDoc, sourceObj);
      //}

      //batch.commit().catch(err => {
      //console.log(err);
      //});
    },
    isValidEmail(val) {
      if (val === '' || val === null) {
        return true;
      } else {
        const emailPattern = /\S+@\S+\.\S+/;
        return emailPattern.test(val) || 'Invalid email';
      }
    },
    onSubmit() {
      const batch = db.batch();
      const settingsDoc = db.collection('settings').doc('settings');
      const settingsUsersDoc = db.collection('settings_users').doc('settings');

      const settingsObj = {
        'general.businessName': this.settings.businessName,
        'general.businessAddress': this.settings.businessAddress,
        'general.businessCityStZip': this.settings.businessCityStZip,
        'general.businessEmail': this.settings.businessEmail,
        'general.defaultSReportNote': this.settings.defaultSReportNote,
        'general.defaultSReportType': this.settings.defaultSReportType
      };

      const settingsUsersObj = {
        destAs: this.settings.destAs,
        commodities: this.settings.commodities
      };

      batch.update(settingsDoc, settingsObj);
      batch.update(settingsUsersDoc, settingsUsersObj);

      batch.commit().catch(err => {
        console.log(err);
      });

      this.$q.notify({
        color: 'primary',
        textColor: 'white',
        icon: 'settings',
        message: 'Settings Updated'
      });
    },
    setSettings() {
      const generalSettings = this.$store.state.generalSettings;
      const settingsUsers = this.$store.state.settingsUsers;

      this.settings = {
        commodities: settingsUsers.commodities,
        businessName: generalSettings.businessName,
        businessAddress: generalSettings.businessAddress,
        businessCityStZip: generalSettings.businessCityStZip,
        businessEmail: generalSettings.businessEmail,
        defaultSReportNote: generalSettings.defaultSReportNote,
        defaultSReportType: generalSettings.defaultSReportType,
        destAs: settingsUsers.destAs
      };
    }
  },
  computed: {
    superAdmin() {
      return this.$store.state.user.superAdmin;
    }
  },
  watch: {
    '$store.state.generalSettings'() {
      this.setSettings();
    },
    '$store.state.settingsUsers.commodities'() {
      this.setSettings();
    }
  }
};
</script>

<style scoped></style>
