<template>
  <div class="map">
    <div
      v-show="
        currentDisplay !== 'destAttachments' &&
        currentDisplay !== 'sourceAttachments'
      "
      id="g_map"
      ref="g_map"
      class="map"
    ></div>

    <Locations
      v-if="currentDisplay === 'locationsList'"
      :markerFilter="markerFilter"
      :isAdmin="isAdmin"
      :isSuper="isSuper"
      :isUser="isUser"
      :customSelectors="customSelectors"
      @onBackToMap="onBackToMap"
      @onEditPin="onEditPin"
      @onGoTo="onGoTo"
      @onSetDestOrSource="onSetDestOrSource"
      @onDestWaiting="onDestWaiting"
      @onNotHauled="onNotHauled"
      @onHaulInProgress="onHaulInProgress"
      @onServicesNotComplete="onServicesNotComplete"
      @onServicesComplete="onServicesComplete"
      @onAllDestinations="onAllDestinations"
      @onSourceWaiting="onSourceWaiting"
      @onActiveSources="onActiveSources"
      @onAllSources="onAllSources"
      @onCurrentLocation="onCurrentLocation"
      @onToggleSelectorWindows="onToggleSelectorWindows"
    />

    <ReportContainer
      v-if="currentDisplay === 'mapReport'"
      :reportId="reports.id"
      :reportType="reports.reportType"
      :canEdit="true"
      @onCancel="currentDisplay = 'map'"
    />

    <div
      v-show="settingPin"
      id="map-footer"
      class="row justify-center items-center q-mb-lg"
    >
      <div
        class="row justify-center items-center bg-black text-h5 text-bold text-custom-orange"
      >
        <div class="full-width text-center">Set pin for</div>
        <div>
          {{ setPinMessage }}
        </div>
      </div>
    </div>

    <q-card
      v-show="betweenPins.message"
      id="between-pins"
      class="row items-center bg-white q-mb-md"
    >
      <div class="row full-width q-pa-md">
        <q-btn
          icon="clear"
          flat
          label="cancel"
          color="primary"
          padding="xs md"
          class="col q-mr-xs"
          @click="goBetweenCancel"
        />
        <q-btn
          v-if="betweenPins.startSet && betweenPins.endSet"
          icon-right="arrow_forward"
          label="go"
          color="custom-green"
          padding="xs md"
          class="col q-ml-xs"
          @click="goBetween"
        />
        <q-btn
          v-if="!betweenPins.startSet || !betweenPins.endSet"
          icon-right="arrow_forward"
          label="go"
          color="grey"
          class="col q-ml-xs"
          padding="xs md"
        />
      </div>
      <div class="full-width">
        <div class="row items-center q-mx-md q-mb-md q-pa-sm">
          <div class="row items-center justify-between full-width">
            <div class="text-custom-green text-body1 text-bold">Start</div>
            <div class="text-body2">
              {{ betweenPins.startMessage }}
            </div>
          </div>
          <div class="row items-center justify-between full-width">
            <div class="text-custom-red text-body1 text-bold">End</div>
            <div class="text-body2">
              {{ betweenPins.endMessage }}
            </div>
          </div>
        </div>
      </div>
    </q-card>

    <div
      v-show="settingPin"
      id="btns"
      class="column q-gutter-sm q-pt-sm q-pr-sm"
    >
      <q-btn
        icon="arrow_back"
        label="Cancel"
        color="custom-orange"
        padding="sm sm"
        class="q-ml-sm"
        @click="cancelSetPin"
      />
      <q-btn
        v-show="!nothingToDelete"
        icon="delete"
        label="Delete Pin"
        color="custom-red"
        padding="sm sm"
        class="q-ml-sm"
        @click="deletePin"
      />
    </div>

    <div
      v-show="
        !settingPin &&
        (currentDisplay === 'map' || currentDisplay === 'locationsList')
      "
      id="btns"
      class="column q-gutter-sm q-pt-sm q-pr-sm"
    >
      <q-btn
        icon="list"
        label="List"
        color="primary"
        padding="xs sm"
        @click="showLocationsList"
      />
      <q-btn
        icon="clear"
        label="Info"
        color="primary"
        padding="xs sm"
        @click="closeInfoWindows"
      />
    </div>
  </div>
</template>

<script>
//import firebase from 'firebase/compat/app';
import firebaseApp from '@/firebase.js';
import {
  doc,
  getFirestore,
  getDoc,
  getDocs,
  collection,
  writeBatch,
  where,
  query,
  orderBy,
  limit
} from 'firebase/firestore';
import { gMapsInit, center } from '@/utils/gmaps';
//import { destinationReport } from '@/utils/ReportHelpers';
import Locations from '@/components/maps/Locations.vue';
import ReportContainer from '@/components/ReportContainer.vue';

const db = getFirestore(firebaseApp);

export default {
  name: 'G-map',
  components: {
    ReportContainer,
    Locations
  },
  props: {
    isAdmin: {
      type: Boolean,
      required: true
    },
    isSuper: {
      type: Boolean,
      required: true
    },
    isUser: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      betweenPins: {
        message: false,
        startPosition: {
          lat: null,
          lng: null
        },
        startSet: false,
        endPosition: {
          lat: null,
          lng: null
        },
        endSet: false,
        startName: '',
        endName: ''
      },
      currentDisplay: 'map',
      customSelectors: {
        dest: false,
        source: false
      },
      reports: {
        activeDest: [],
        activeSources: [],
        inputRaw: {},
        reportType: ''
      },
      google: null,
      incomingPinChecked: false,
      listener: null,
      map: null,
      markerFilter: {
        destOrSource: 'destination',
        destWaiting: true,
        notHauled: true,
        haulInProgress: true,
        servicesNotComplete: true,
        servicesComplete: true,
        allDestinations: false,
        sourceWaiting: true,
        activeSources: true,
        allSources: false
      },
      markers: [],
      nothingToDelete: false,
      selected: {},
      setPinMessage: '',
      setPinStarted: false,
      settingPin: false,
      setSelectorNum: 0
    };
  },
  mounted() {
    this.$nextTick(async () => {
      try {
        // Lookup map type preference
        let mapType = '';
        if (!localStorage.mapType) {
          mapType = 'roadmap';
        } else {
          mapType = localStorage.mapType;
        }

        this.google = await gMapsInit();
        this.map = new this.google.maps.Map(this.$refs.g_map, {
          center: center,
          mapTypeId: mapType,
          zoom: 9,
          zoomControl: false,
          scaleControl: true,
          fullscreenControl: false,
          streetViewControl: false,
          options: {
            gestureHandling: 'greedy'
          }
        });

        this.map.addListener('maptypeid_changed', () => {
          const mapType = this.map.getMapTypeId();
          localStorage.mapType = mapType;
        });

        this.setPinStart();
        this.initMarkerFilter();
        window.destHaulShortcut = this.destHaulShortcut;
        window.destReport = this.destReport;
        window.sourceReport = this.sourceReport;
        window.startDirections = this.startDirections;
        window.endDirections = this.endDirections;
      } catch (error) {
        console.error(error);
      }
    });
  },
  methods: {
    addMarker() {
      this.listener = this.map.addListener('click', event => {
        placePin(event.latLng);
      });

      const google = this.google;
      const settingPinComplete = () => {
        this.$q.notify({
          color: 'primary',
          textColor: 'white',
          icon: 'place',
          message: `Pin Set for ${this.selected.location.locationName}`
        });
      };

      const placePin = eventLocation => {
        google.maps.event.removeListener(this.listener);
        this.settingPin = false;

        if (this.selected.type === 'destination') {
          setDestPin(eventLocation);
        } else if (this.selected.type === 'source') {
          setSourcePin(eventLocation);
        }
      };

      const setDestPin = eventLocation => {
        const masterSplitDestinations = JSON.parse(
          JSON.stringify(this.$store.state.masterSplitDestinations)
        );

        let docRef;
        for (const key in masterSplitDestinations) {
          const splitDest = masterSplitDestinations[key];

          if (
            Object.prototype.hasOwnProperty.call(
              splitDest,
              this.selected.location.id
            )
          ) {
            docRef = key;
          }
        }

        const masterDestListRef = doc(db, 'master_locations', docRef);

        const updateObj = {};
        updateObj[`${this.selected.location.id}.lat`] = eventLocation.lat();
        updateObj[`${this.selected.location.id}.lng`] = eventLocation.lng();

        const batch = writeBatch(db);

        batch.update(masterDestListRef, updateObj);

        batch.commit();
        settingPinComplete();
      };

      const setSourcePin = eventLocation => {
        const masterSplitSources = JSON.parse(
          JSON.stringify(this.$store.state.masterSplitSources)
        );

        let docRef;
        for (const key in masterSplitSources) {
          const splitSource = masterSplitSources[key];

          if (
            Object.prototype.hasOwnProperty.call(
              splitSource,
              this.selected.location.id
            )
          ) {
            docRef = key;
          }
        }

        const masterSourceListRef = doc(db, 'master_locations', docRef);

        const updateObj = {};
        updateObj[`${this.selected.location.id}.lat`] = eventLocation.lat();
        updateObj[`${this.selected.location.id}.lng`] = eventLocation.lng();

        const batch = writeBatch(db);

        batch.update(masterSourceListRef, updateObj);

        batch.commit();
        settingPinComplete();
      };
    },
    cancelSetPin() {
      this.setPinMessage = '';
      this.settingPin = false;
      this.setPins();
    },
    checkIncomingPin() {
      this.incomingPinChecked = true;
      let mapPin = this.$store.state.mapPin;

      if (!mapPin.type || !mapPin.id) {
        return false;
      } else {
        if (mapPin.type === 'destination') {
          let isActive = false;
          const location = this.masterDestinations[mapPin.id];
          location.id = mapPin.id;
          location.type = 'destination';

          const activeDestinations = JSON.parse(
            JSON.stringify(this.$store.state.activeDestinationsUsers)
          );

          for (let i = 0; i < activeDestinations.length; i++) {
            const destination = activeDestinations[i];

            if (destination.destinationLocationId === mapPin.id) {
              isActive = true;
              break;
            }
          }

          if (!isActive) {
            this.markerFilter.allDestinations = true;
            this.setPins();
          }

          if (location.lat) {
            this.onGoTo(location);
          } else {
            location.locationName = location.destinationName;
            this.onEditPin(location.type, location);
          }
        } else {
          this.markerFilter.destOrSource = 'source';

          let isActive = false;

          const location = this.masterSources[mapPin.id];
          location.id = mapPin.id;
          location.type = 'source';

          const activeSources = JSON.parse(
            JSON.stringify(this.$store.state.activeSourcesUsers)
          );

          for (let i = 0; i < activeSources.length; i++) {
            const source = activeSources[i];
            if (source.sourceLocationId === mapPin.id) {
              isActive = true;
              break;
            }
          }

          if (!isActive) {
            this.markerFilter.allSources = true;
            this.setPins();
          }

          if (location.lat) {
            this.onGoTo(location);
          } else {
            location.locationName = location.sourceName;
            this.onEditPin(location.type, location);
          }
        }
      }
      mapPin = {
        type: null,
        id: null
      };

      // Need to set mapPin to null in state
      this.$store.dispatch('setMapPin', mapPin);
    },
    clearMarkers() {
      this.setMapOnAll(null);
    },
    closeInfoWindows() {
      this.customSelectors.dest = false;
      this.customSelectors.source = false;

      for (let i = 0; i < this.markers.length; i++) {
        const marker = this.markers[i];
        marker.closeWindow();
        marker.closeSelectorWindow();
      }
    },
    deleteMarkers() {
      this.clearMarkers();
      this.markers = [];
    },
    deletePin() {
      this.google.maps.event.removeListener(this.listener);
      this.settingPin = false;

      const notify = () => {
        this.$q.notify({
          color: 'custom-red',
          textColor: 'white',
          icon: 'place',
          message: `${this.selected.location.locationName} Deleted`
        });
      };

      if (this.selected.type === 'destination') {
        const masterSplitDestinations = JSON.parse(
          JSON.stringify(this.$store.state.masterSplitDestinations)
        );

        let docRef;
        for (const key in masterSplitDestinations) {
          const splitDest = masterSplitDestinations[key];

          if (
            Object.prototype.hasOwnProperty.call(
              splitDest,
              this.selected.location.id
            )
          ) {
            docRef = key;
          }
        }

        const masterDestListRef = doc(db, 'master_locations', docRef);

        const updateObj = {};
        updateObj[`${this.selected.location.id}.lat`] = null;
        updateObj[`${this.selected.location.id}.lng`] = null;

        const batch = writeBatch(db);

        batch.update(masterDestListRef, updateObj);

        batch.commit();
        notify();
      } else if (this.selected.type === 'source') {
        const masterSplitSources = JSON.parse(
          JSON.stringify(this.$store.state.masterSplitSources)
        );

        let docRef;
        for (const key in masterSplitSources) {
          const splitSource = masterSplitSources[key];

          if (
            Object.prototype.hasOwnProperty.call(
              splitSource,
              this.selected.location.id
            )
          ) {
            docRef = key;
          }
        }

        const masterSourceListRef = doc(db, 'master_locations', docRef);

        const updateObj = {};
        updateObj[`${this.selected.location.id}.lat`] = null;
        updateObj[`${this.selected.location.id}.lng`] = null;

        const batch = writeBatch(db);

        batch.update(masterSourceListRef, updateObj);

        batch.commit();
        notify();
      }
    },
    destHaulShortcut(id) {
      const shortcut = {
        type: 'destination',
        locationId: id
      };

      this.$store.dispatch('setHaulShortcut', shortcut);
      this.$router.push({ path: 'hauls' });
    },
    destReport(locationId) {
      const dest = this.reports.activeDest.find(dest => {
        return locationId === dest.destinationLocationId;
      });

      this.reports.id = dest.id;
      this.reports.reportType = 'destination';
      this.currentDisplay = 'mapReport';
    },
    initMarkerFilter() {
      if (localStorage.markerFilter) {
        let markerFilter = localStorage.getItem('markerFilter');
        this.markerFilter = JSON.parse(markerFilter);

        // This can cause issues if these are true for any reason
        // and logged in as user level
        if (!(this.isAdmin || this.isSuper)) {
          this.markerFilter.allDestinations = false;
          this.markerFilter.allSources = false;
        }
      }
    },
    onActiveSources() {
      this.markerFilter.activeSources = !this.markerFilter.activeSources;
      this.setPins();
      this.onSetMarkerFilter();
    },
    onAllDestinations() {
      this.markerFilter.allDestinations = !this.markerFilter.allDestinations;
      this.setPins();
      this.onSetMarkerFilter();
    },
    onAllSources() {
      this.markerFilter.allSources = !this.markerFilter.allSources;
      this.setPins();
      this.onSetMarkerFilter();
    },
    onBackToMap() {
      this.currentDisplay = 'map';
    },
    onCurrentLocation() {
      this.currentDisplay = 'map';
      const google = this.google;
      const infoWindow = new google.maps.InfoWindow();
      const map = this.map;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            infoWindow.setPosition(pos);
            infoWindow.setContent('Current Location');
            infoWindow.open(map);
            map.setCenter(pos);
          },
          () => {
            console.warn('Error in getting location');
          }
        );
      } else {
        console.warn('Browser does not support Geolocation');
      }
    },
    onDestWaiting() {
      this.markerFilter.destWaiting = !this.markerFilter.destWaiting;
      this.setPins();
      this.onSetMarkerFilter();
    },
    onEditPin(type, location) {
      this.selected = {
        type: type,
        location: location
      };

      if (location.lat === null && location.lng === null) {
        this.nothingToDelete = true;
      } else {
        this.nothingToDelete = false;
      }

      this.setPinMessage = location.locationName;
      this.settingPin = true;
      this.currentDisplay = 'map';
      this.addMarker();
    },
    onGoTo(location) {
      this.currentDisplay = 'map';

      for (let i = 0; i < this.markers.length; i++) {
        if (
          this.markers[i].type === location.type &&
          this.markers[i].id === location.id
        ) {
          this.markers[i].openWindow();
        } else {
          this.markers[i].closeWindow();
          this.markers[i].closeSelectorWindow();
        }
      }
      const latLng = {
        lat: location.lat,
        lng: location.lng
      };
      this.map.setCenter(latLng);
    },
    onHaulInProgress() {
      this.markerFilter.haulInProgress = !this.markerFilter.haulInProgress;
      this.setPins();
      this.onSetMarkerFilter();
    },
    onNotHauled() {
      this.markerFilter.notHauled = !this.markerFilter.notHauled;
      this.setPins();
      this.onSetMarkerFilter();
    },
    onSetMarkerFilter() {
      localStorage.setItem('markerFilter', JSON.stringify(this.markerFilter));
    },
    onSetDestOrSource(type) {
      if (type === 'destination') {
        this.markerFilter.destOrSource = 'destination';
      } else {
        this.markerFilter.destOrSource = 'source';
      }
      this.onSetMarkerFilter();
    },
    onServicesComplete() {
      this.markerFilter.servicesComplete = !this.markerFilter.servicesComplete;
      this.setPins();
      this.onSetMarkerFilter();
    },
    onServicesNotComplete() {
      this.markerFilter.servicesNotComplete =
        !this.markerFilter.servicesNotComplete;
      this.setPins();
      this.onSetMarkerFilter();
    },
    onSourceWaiting() {
      this.markerFilter.sourceWaiting = !this.markerFilter.sourceWaiting;
      this.setPins();
      this.onSetMarkerFilter();
    },
    onToggleSelectorWindows(type) {
      if (type === 'dest') {
        this.customSelectors.dest = !this.customSelectors.dest;
      } else if (type === 'source') {
        this.customSelectors.source = !this.customSelectors.source;
      }

      this.setPins();
    },
    setPins() {
      // If watcher calls function before map init - return
      if (!this.google) {
        return false;
      }

      let returnNow = false;

      // If settingsUsers is null - return
      if (
        !this.$store.state.settingsUsers.destinationSettings
          .projectedPlaceholder ||
        !this.$store.state.settingsUsers.destinationSettings.projectedTolerance
      ) {
        return false;
      }

      this.deleteMarkers();
      this.reports.activeDest = [];
      this.reports.activeSources = [];

      const setPinsOnMap = () => {
        const activeDestinations = JSON.parse(
          JSON.stringify(this.$store.state.activeDestinationsUsers)
        );
        const activeSources = JSON.parse(
          JSON.stringify(this.$store.state.activeSourcesUsers)
        );

        let adminDestinations;
        let adminSources;
        if (this.isAdmin) {
          adminDestinations = JSON.parse(
            JSON.stringify(this.$store.state.activeDestinations)
          );
          adminSources = JSON.parse(
            JSON.stringify(this.$store.state.activeSources)
          );
        }

        const projectedPlaceholder = JSON.parse(
          JSON.stringify(
            this.$store.state.settingsUsers.destinationSettings
              .projectedPlaceholder
          )
        );
        const projectedTolerance = JSON.parse(
          JSON.stringify(
            this.$store.state.settingsUsers.destinationSettings
              .projectedTolerance
          )
        );

        const redIcon = '/shared/marker-red.svg';
        const orangeIcon = '/shared/marker-orange.svg';
        const blueIcon = '/shared/marker-blue.svg';
        const blackIcon = '/shared/marker-black.svg';
        const redMoreTime = '/shared/more-time-red.svg';
        const orangeMoreTime = '/shared/more-time-orange.svg';
        const blueMoreTime = '/shared/more-time-blue.svg';
        const blackMoreTime = '/shared/more-time-black.svg';
        const greenIcon = '/shared/marker-green.svg';
        const greenMoreTime = '/shared/more-time-green.svg';
        const artichokeIcon = '/shared/marker-artichoke.svg';
        const lightIcon = '/shared/marker-light.svg';

        const setSelectors = (selectors, type) => {
          let htmlStr = '';
          for (let i = 0; i < selectors.length; i++) {
            htmlStr += `
              <div class="q-pa-xs q-mb-xs rounded-borders text-bold text-white ellipsis bg-${selectors[i].optionColor}"
                style="max-width: 50px; font-size: 10px"
                >
                ${selectors[i].option}
              </div>`;
          }

          if (
            !selectors.length ||
            (type === 'dest' && this.customSelectors.dest === false) ||
            (type === 'source' && this.customSelectors.source === false)
          ) {
            htmlStr = null;
          }
          return htmlStr;
        };

        const setActiveDestPinInfo = (receiverArr, location) => {
          let infoWindowContent = `
            <div class="q-pr-xs q-pb-xs q-pt-xs q-pl-sm">
              <div class="text-subtitle2">
                ${location.customer}
              </div>
              <div class="text-weight-medium full-width text-grey-7 q-mt-xs q-pb-xs" style="border-bottom: 1px solid silver;">
                ${location.destinationName}
              </div>
              <div class="row justify-between q-mt-sm" style="min-width: 130px;">
                <div class="text-caption text-weight-medium q-mr-sm">
                  Projected
                </div>
                <div class="text-caption">
                  ${location.projectedStr}
                </div>
              </div>
              <div class="q-my-xs" style="width: 100%;">
                <div
                  class="bg-grey-3 rounded-borders"
                  style="width: 100%; height: 7px"
                >
                  <div
                    class="bg-primary rounded-borders"
                    style="width: ${location.percentHauled}; height: 7px;"
                  ></div>
                </div>
              </div>
              <div class="row justify-between" style="min-width: 130px;">
                <div class="text-caption text-weight-medium q-mr-sm">
                  Hauled
                </div>
                <div class="text-caption">
                  ${location.hauled.toLocaleString('en-US')} ${
            location.measurementUnit
          }
                </div>
              </div>
              <div class="row justify-between" style="min-width: 130px;">
                <div class="text-caption text-weight-medium q-mr-sm">
                  Balance
                </div>
                <div class="text-caption">
                  ${location.balance.toLocaleString('en-US')} ${
            location.measurementUnit
          }
                </div>
              </div>
              <br>`;
          if (!location.servicesComplete && !this.isGuestCustomer) {
            infoWindowContent += `
              <div
                class="row items-center text-primary cursor-pointer q-mt-sm q-mb-sm"
                onclick="destHaulShortcut('${location.id}')"
              >
                <span class="material-icons" style="font-size: 20px; margin-right: 5px;">
                  local_shipping
                </span>
                <span class="text-weight-medium">
                  Haul
                </span>
              </div>`;
          }
          infoWindowContent += `
              <div
                class="row items-center text-custom-orange cursor-pointer q-mt-sm q-mb-sm"
                onclick="destReport('${location.id}')"
              >
                <span class="material-icons" style="font-size: 20px; margin-right: 5px; transform: rotate(180deg)">
                  outbound
                </span>
                <span class="text-weight-medium">
                  Details
                </span>
              </div>
              <a class="text-black" style="text-decoration: none;"
                href ="https://www.google.com/maps/search/?api=1&query=
                ${location.lat},${location.lng}"
              >
                <div class="row items-center">
                  <span class="material-icons" style="font-size: 20px; margin-right: 5px;">
                    assistant_direction
                  </span>
                  <span class="text-weight-medium">
                    Directions
                  </span>
                </div>
              </a>
              <div
                class="row items-center text-custom-green cursor-pointer q-mt-sm q-mb-sm"
                onclick="startDirections('${location.destinationName}', '${location.lat}', '${location.lng}')"
              >
                <span class="material-icons" style="font-size: 20px; margin-right: 5px;">
                  directions_car
                </span>
                <span class="text-weight-medium">
                  Start Pin
                </span>
              </div>
              <div
                class="row items-center text-custom-red cursor-pointer"
                onclick="endDirections('${location.destinationName}', '${location.lat}', '${location.lng}')"
              >
                <span class="material-icons" style="font-size: 20px; margin-right: 5px;">
                  directions_car
                </span>
                <span class="text-weight-medium">
                  End Pin
                </span>
              </div>
            </div>`;
          receiverArr.push({
            position: {
              lat: location.lat,
              lng: location.lng
            },
            id: location.id,
            icon: location.icon,
            selectorContent: setSelectors(location.selectors, 'dest'),
            infoWindowContent
          });
        };

        const setAllDestPinInfo = (receiverArr, location) => {
          receiverArr.push({
            position: {
              lat: location.lat,
              lng: location.lng
            },
            id: location.id,
            icon: lightIcon,
            infoWindowContent: `
            <div class="q-pr-xs q-pb-xs q-pt-xs q-pl-sm">
              <div class="text-subtitle2">
                ${location.customer}
              </div>
              <div class="text-weight-medium full-width text-grey-7 q-mt-xs q-pb-xs" style="border-bottom: 1px solid silver;">
                ${location.destinationName}
              </div>
              <br>
              <a class="text-black" style="text-decoration: none;"
                href ="https://www.google.com/maps/search/?api=1&query=
                ${location.lat}, ${location.lng}"
              >
                <div class="row items-center">
                  <span class="material-icons" style="font-size: 20px; margin-right: 5px;">
                    assistant_direction
                  </span>
                  <span class="text-weight-medium">
                    Directions
                  </span>
                </div>
              </a>
              <div
                class="row items-center text-custom-green cursor-pointer q-mt-sm q-mb-sm"
                onclick="startDirections('${location.destinationName}', '${location.lat}', '${location.lng}')"
              >
                <span class="material-icons" style="font-size: 20px; margin-right: 5px;">
                  directions_car
                </span>
                <span class="text-weight-medium">
                  Start Pin
                </span>
              </div>
              <div
                class="row items-center text-custom-red cursor-pointer"
                onclick="endDirections('${location.destinationName}', '${location.lat}', '${location.lng}')"
              >
                <span class="material-icons" style="font-size: 20px; margin-right: 5px;">
                  directions_car
                </span>
                <span class="text-weight-medium">
                  End Pin
                </span>
              </div>
            </div>`
          });
        };

        const allDestLocations = [];
        if (this.markerFilter.allDestinations) {
          for (const key in this.masterDestinations) {
            const location = this.masterDestinations[key];
            const customerId = location.customerId;

            let isActive = false;
            for (let i = 0; i < activeDestinations.length; i++) {
              let activeDestination = activeDestinations[i];

              if (
                customerId === activeDestination.customerId &&
                key === activeDestination.destinationLocationId
              ) {
                isActive = true;
                if (location.lat) {
                  let hauled = 0;
                  for (const key in activeDestination.hauls) {
                    hauled += activeDestination.hauls[key].haulAmount;
                  }

                  let projectedForCalc =
                    activeDestination.projected || projectedPlaceholder;

                  let percentHauled = (hauled / projectedForCalc) * 100;

                  if (percentHauled > 100) {
                    percentHauled = 100;
                  }

                  projectedForCalc = activeDestination.projected || 0;

                  let icon = redIcon;
                  icon =
                    activeDestination.destinationStatus !== 'Open'
                      ? redMoreTime
                      : icon;

                  let balance = projectedForCalc - hauled;
                  if (balance < 0) {
                    balance = 0;
                  }

                  icon = hauled > 0 ? orangeIcon : icon;
                  icon =
                    activeDestination.destinationStatus !== 'Open' &&
                    icon === orangeIcon
                      ? orangeMoreTime
                      : icon;

                  const projected = activeDestination.projected;

                  if (projected !== '') {
                    if (
                      activeDestination.projected <=
                      hauled + projectedTolerance
                    ) {
                      icon = blueIcon;
                      icon =
                        activeDestination.destinationStatus !== 'Open'
                          ? blueMoreTime
                          : icon;
                      percentHauled = 100;
                    }
                  }
                  percentHauled = percentHauled + '%';

                  let servicesComplete = true;
                  for (let j = 0; j < activeDestination.services.length; j++) {
                    if (activeDestination.services[j].complete === false) {
                      servicesComplete = false;
                    }

                    if (this.isAdmin) {
                      adminDestinations[i].services[j] = {
                        ...activeDestination.services[j],
                        ...adminDestinations[i].services[j]
                      };
                    }
                  }
                  servicesComplete = !activeDestination.services.length
                    ? false
                    : servicesComplete;

                  icon = servicesComplete === true ? blackIcon : icon;
                  icon =
                    activeDestination.destinationStatus !== 'Open' &&
                    icon === blackIcon
                      ? blackMoreTime
                      : icon;

                  let measurementUnit = '';

                  for (const key in this.settingsUsers.commodities) {
                    if (key === activeDestination.commodity) {
                      measurementUnit =
                        this.settingsUsers.commodities[key].measurementUnit;
                    }
                  }

                  let projectedStr = `${activeDestination.projected.toLocaleString(
                    'en-US'
                  )} ${measurementUnit}`;

                  if (!projected) {
                    projectedStr =
                      '<span class="text-custom-red">Not Set</span>';
                    balance = 0;
                  }

                  hauled = Math.round((hauled + Number.EPSILON) * 100) / 100;
                  balance = Math.round((balance + Number.EPSILON) * 100) / 100;

                  const selectors = [];
                  try {
                    if (this.isAdmin) {
                      const adminDest = adminDestinations[i];
                      adminDest.customSelectors.forEach(x => {
                        for (
                          let k = 0;
                          k <
                          this.settingsUsers.destinationSettings.customSelectors
                            .length;
                          k++
                        ) {
                          const customSelector =
                            this.settingsUsers.destinationSettings
                              .customSelectors[k];

                          if (x.name === customSelector.name) {
                            let option = customSelector.options.find(z => {
                              return z.option === x.model;
                            });

                            selectors.push(option);
                          }
                        }
                      });
                    } else {
                      activeDestination.userCustomSelectors.forEach(x => {
                        for (
                          let k = 0;
                          k <
                          this.settingsUsers.destinationSettings.customSelectors
                            .length;
                          k++
                        ) {
                          const customSelector =
                            this.settingsUsers.destinationSettings
                              .customSelectors[k];

                          if (x.name === customSelector.name) {
                            let option = customSelector.options.find(z => {
                              return z.option === x.model;
                            });

                            selectors.push(option);
                          }
                        }
                      });
                    }
                  } catch (err) {
                    console.log(err);
                    this.setSelectorNum++;
                    if (this.setSelectorNum < 3) {
                      this.setPins();
                    }
                    returnNow = true;
                  }

                  if (returnNow) {
                    break;
                  }

                  if (this.isAdmin) {
                    activeDestination = {
                      ...activeDestination,
                      ...adminDestinations[i]
                    };
                  }
                  activeDestination.customer =
                    this.customers[activeDestination.customerId].name;

                  this.reports.activeDest.push(activeDestination);

                  const locationObj = {
                    id: activeDestination.destinationLocationId,
                    destinationName: location.destinationName,
                    lat: location.lat,
                    lng: location.lng,
                    customer: this.customers[activeDestination.customerId].name,
                    icon,
                    measurementUnit,
                    balance,
                    hauled,
                    projectedStr,
                    percentHauled,
                    selectors
                  };

                  setActiveDestPinInfo(allDestLocations, locationObj);
                }
              }
            }

            if (returnNow) {
              return;
            }

            if (!isActive) {
              if (location.lat) {
                const destObj = {
                  id: key,
                  customer: this.customers[customerId].name,
                  destinationName: location.destinationName,
                  lat: location.lat,
                  lng: location.lng
                };

                setAllDestPinInfo(allDestLocations, destObj);
              }
            }
          }
        }

        if (returnNow) {
          return;
        }

        const activeDestLocations = [];
        if (!this.markerFilter.allDestinations) {
          for (let i = 0; i < activeDestinations.length; i++) {
            let dest = activeDestinations[i];
            if (dest.endDate && (this.isUser || this.isSuper)) {
              continue;
            }

            const location =
              this.masterDestinations[dest.destinationLocationId];

            if (location.lat) {
              let hauled = 0;
              for (const key in dest.hauls) {
                hauled += dest.hauls[key].haulAmount;
              }

              let projectedForCalc = dest.projected || projectedPlaceholder;
              let percentHauled = (hauled / projectedForCalc) * 100;

              if (percentHauled > 100) {
                percentHauled = 100;
              }

              projectedForCalc = dest.projected || 0;

              let icon = redIcon;
              icon = dest.destinationStatus !== 'Open' ? redMoreTime : icon;

              let balance = projectedForCalc - hauled;
              if (balance < 0) {
                balance = 0;
              }

              icon = hauled > 0 ? orangeIcon : icon;
              icon =
                dest.destinationStatus !== 'Open' && icon === orangeIcon
                  ? orangeMoreTime
                  : icon;

              const projected = dest.projected;

              if (projected !== '') {
                if (dest.projected <= hauled + projectedTolerance) {
                  icon = blueIcon;
                  icon =
                    dest.destinationStatus !== 'Open' ? blueMoreTime : icon;
                  percentHauled = 100;
                }
              }
              percentHauled = percentHauled + '%';

              let servicesComplete = true;
              for (let j = 0; j < dest.services.length; j++) {
                if (dest.services[j].complete === false) {
                  servicesComplete = false;
                }

                if (this.isAdmin) {
                  adminDestinations[i].services[j] = {
                    ...dest.services[j],
                    ...adminDestinations[i].services[j]
                  };
                }
              }
              servicesComplete = !dest.services.length
                ? false
                : servicesComplete;

              icon = servicesComplete === true ? blackIcon : icon;
              icon =
                dest.destinationStatus !== 'Open' && icon === blackIcon
                  ? blackMoreTime
                  : icon;

              let measurementUnit = '';

              for (const key in this.settingsUsers.commodities) {
                if (key === dest.commodity) {
                  measurementUnit =
                    this.settingsUsers.commodities[key].measurementUnit;
                }
              }

              let projectedStr = `${dest.projected.toLocaleString(
                'en-US'
              )} ${measurementUnit}`;

              if (!projected) {
                projectedStr = '<span class="text-custom-red">Not Set</span>';
                balance = 0;
              }

              hauled = Math.round((hauled + Number.EPSILON) * 100) / 100;
              balance = Math.round((balance + Number.EPSILON) * 100) / 100;

              const selectors = [];
              try {
                if (this.isAdmin) {
                  const adminDest = adminDestinations[i];
                  adminDest.customSelectors.forEach(x => {
                    for (
                      let j = 0;
                      j <
                      this.settingsUsers.destinationSettings.customSelectors
                        .length;
                      j++
                    ) {
                      const customSelector =
                        this.settingsUsers.destinationSettings.customSelectors[
                          j
                        ];

                      if (x.name === customSelector.name) {
                        let option = customSelector.options.find(z => {
                          return z.option === x.model;
                        });

                        selectors.push(option);
                      }
                    }
                  });
                } else {
                  dest.userCustomSelectors.forEach(x => {
                    for (
                      let k = 0;
                      k <
                      this.settingsUsers.destinationSettings.customSelectors
                        .length;
                      k++
                    ) {
                      const customSelector =
                        this.settingsUsers.destinationSettings.customSelectors[
                          k
                        ];

                      if (x.name === customSelector.name) {
                        let option = customSelector.options.find(z => {
                          return z.option === x.model;
                        });

                        selectors.push(option);
                      }
                    }
                  });
                }
              } catch (err) {
                console.log(err);
                this.setSelectorNum++;
                if (this.setSelectorNum < 3) {
                  this.setPins();
                  returnNow = true;
                }
              }

              if (returnNow) {
                break;
              }

              if (this.isAdmin) {
                dest = {
                  ...dest,
                  ...adminDestinations[i]
                };
              }
              dest.customer = this.customers[dest.customerId].name;

              this.reports.activeDest.push(dest);

              const locationObj = {
                id: dest.destinationLocationId,
                destinationName: location.destinationName,
                lat: location.lat,
                lng: location.lng,
                customer: this.customers[dest.customerId].name,
                icon,
                measurementUnit,
                balance,
                hauled,
                projectedStr,
                percentHauled,
                selectors,
                servicesComplete
              };

              switch (icon) {
                case redIcon:
                  if (this.markerFilter.notHauled) {
                    setActiveDestPinInfo(activeDestLocations, locationObj);
                  }
                  break;
                case redMoreTime:
                  if (this.markerFilter.destWaiting) {
                    setActiveDestPinInfo(activeDestLocations, locationObj);
                  }
                  break;
                case orangeIcon:
                  if (this.markerFilter.haulInProgress) {
                    setActiveDestPinInfo(activeDestLocations, locationObj);
                  }
                  break;
                case orangeMoreTime:
                  if (this.markerFilter.destWaiting) {
                    setActiveDestPinInfo(activeDestLocations, locationObj);
                  }
                  break;
                case blueIcon:
                  if (this.markerFilter.servicesNotComplete) {
                    setActiveDestPinInfo(activeDestLocations, locationObj);
                  }
                  break;
                case blueMoreTime:
                  if (this.markerFilter.destWaiting) {
                    setActiveDestPinInfo(activeDestLocations, locationObj);
                  }
                  break;
                case blackIcon:
                  if (this.markerFilter.servicesComplete) {
                    setActiveDestPinInfo(activeDestLocations, locationObj);
                  }
                  break;
                case blackMoreTime:
                  if (this.markerFilter.destWaiting) {
                    setActiveDestPinInfo(activeDestLocations, locationObj);
                  }
                  break;
              }
            }
          }
        }

        if (returnNow) {
          return;
        }

        const setActiveSourcePinInfo = (receiverArr, location) => {
          receiverArr.push({
            position: {
              lat: location.lat,
              lng: location.lng
            },
            id: location.id,
            icon: location.icon,
            selectorContent: setSelectors(location.selectors, 'source'),
            infoWindowContent: `
            <div class="q-pr-xs q-pb-xs q-pt-xs q-pl-sm">
              <div class="text-subtitle2">
                ${location.customer}
              </div>
              <div class="text-weight-medium full-width text-grey-7 q-mt-xs q-pb-xs" style="border-bottom: 1px solid silver;">
                ${location.sourceName}
              </div>
              <div class="row justify-between q-mt-sm" style="min-width: 130px;">
                <div class="text-caption text-weight-medium q-mr-sm">
                  Hauled
                </div>
                <div class="text-caption">
                  ${location.hauled.toLocaleString('en-US')} ${
              location.measurementUnit
            }
                </div>
              </div>
              <br>
              <div
                class="row items-center text-custom-orange cursor-pointer q-mt-sm q-mb-sm"
                onclick="sourceReport('${location.id}')"
              >
                <span class="material-icons" style="font-size: 20px; margin-right: 5px; transform: rotate(180deg)">
                  outbound
                </span>
                <span class="text-weight-medium">
                  Details
                </span>
              </div>
              <a class="text-black" style="text-decoration: none;"
                href ="https://www.google.com/maps/search/?api=1&query=
                ${location.lat},${location.lng}"
              >
                <div class="row items-center">
                  <span class="material-icons" style="font-size: 20px; margin-right: 5px;">
                    assistant_direction
                  </span>
                  <span class="text-weight-medium">
                    Directions
                  </span>
                </div>
              </a>
              <div
                class="row items-center text-custom-green cursor-pointer q-mt-sm q-mb-sm"
                onclick="startDirections('${location.sourceName}', '${
              location.lat
            }', '${location.lng}')"
              >
                <span class="material-icons" style="font-size: 20px; margin-right: 5px;">
                  directions_car
                </span>
                <span class="text-weight-medium">
                  Start Pin
                </span>
              </div>
              <div
                class="row items-center text-custom-red cursor-pointer"
                onclick="endDirections('${location.sourceName}', '${
              location.lat
            }', '${location.lng}')"
              >
                <span class="material-icons" style="font-size: 20px; margin-right: 5px;">
                  directions_car
                </span>
                <span class="text-weight-medium">
                  End Pin
                </span>
              </div>
            </div>`
          });
        };

        const setAllSourcePinInfo = (receiverArr, location) => {
          receiverArr.push({
            position: {
              lat: location.lat,
              lng: location.lng
            },
            id: location.id,
            icon: artichokeIcon,
            infoWindowContent: `
            <div class="q-pr-xs q-pb-xs q-pt-xs q-pl-sm">
              <div class="text-subtitle2">
                ${location.customer}
              </div>
              <div class="text-weight-medium full-width text-grey-7 q-mt-xs q-pb-xs" style="border-bottom: 1px solid silver;">
                ${location.sourceName}
              </div>
              <br>
              <a class="text-black" style="text-decoration: none;"
                href ="https://www.google.com/maps/search/?api=1&query=
                ${location.lat},${location.lng}"
              >
                <div class="row items-center">
                  <span class="material-icons" style="font-size: 20px; margin-right: 5px;">
                    assistant_direction
                  </span>
                  <span class="text-weight-medium">
                    Directions
                  </span>
                </div>
              </a>
              <div
                class="row items-center text-custom-green cursor-pointer q-mt-sm q-mb-sm"
                onclick="startDirections('${location.sourceName}', '${location.lat}', '${location.lng}')"
              >
                <span class="material-icons" style="font-size: 20px; margin-right: 5px;">
                  directions_car
                </span>
                <span class="text-weight-medium">
                  Start Pin
                </span>
              </div>
              <div
                class="row items-center text-cutom-red cursor-pointer"
                onclick="endDirections('${location.sourceName}', '${location.lat}', '${location.lng}')"
              >
                <span class="material-icons" style="font-size: 20px; margin-right: 5px;">
                  directions_car
                </span>
                <span class="text-weight-medium">
                  End Pin
                </span>
              </div>
            </div>`
          });
        };

        const activeSourceLocations = [];
        if (this.markerFilter.activeSources && !this.markerFilter.allSources) {
          for (let i = 0; i < activeSources.length; i++) {
            let source = activeSources[i];
            const location = this.masterSources[source.sourceLocationId];

            if (source.endDate && (this.isUser || this.isSuper)) {
              continue;
            }

            let hauled = 0;
            for (const key in source.hauls) {
              hauled += source.hauls[key].haulAmount;
            }

            hauled = Math.round((hauled + Number.EPSILON) * 100) / 100;

            const selectors = [];
            try {
              if (this.isAdmin) {
                const adminSource = adminSources[i];
                adminSource.customSelectors.forEach(x => {
                  for (
                    let j = 0;
                    j <
                    this.settingsUsers.sourceSettings.customSelectors.length;
                    j++
                  ) {
                    const customSelector =
                      this.settingsUsers.sourceSettings.customSelectors[j];

                    if (x.name === customSelector.name) {
                      let option = customSelector.options.find(z => {
                        return z.option === x.model;
                      });

                      selectors.push(option);
                    }
                  }
                });
              } else {
                source.userCustomSelectors.forEach(x => {
                  for (
                    let j = 0;
                    j <
                    this.settingsUsers.sourceSettings.customSelectors.length;
                    j++
                  ) {
                    const customSelector =
                      this.settingsUsers.sourceSettings.customSelectors[j];

                    if (x.name === customSelector.name) {
                      let option = customSelector.options.find(z => {
                        return z.option === x.model;
                      });

                      selectors.push(option);
                    }
                  }
                });
              }
            } catch (err) {
              if (this.setSelectorNum < 3) {
                this.setSelectorNum++;
                console.log(err);
                this.setPins;
                this.returnNow = true;
              }
            }

            if (this.returnNow) {
              break;
            }

            if (
              source.sourceStatus !== 'Open' &&
              !this.markerFilter.sourceWaiting
            ) {
              continue;
            }

            const icon =
              source.sourceStatus !== 'Open' ? greenMoreTime : greenIcon;

            if (this.isAdmin) {
              // Properly combine service objects
              for (let j = 0; j < source.services.length; j++) {
                source.services[j] = {
                  ...adminSources[i].services[j],
                  ...source.services[j]
                };
              }

              source = {
                ...adminSources[i],
                ...source
              };
            }

            this.reports.activeSources.push(source);

            let measurementUnit = '';

            for (const key in this.settingsUsers.commodities) {
              if (key === source.commodity) {
                measurementUnit =
                  this.settingsUsers.commodities[key].measurementUnit;
              }
            }

            const locationObj = {
              id: source.sourceLocationId,
              customer: this.customers[source.customerId].name,
              sourceName: location.sourceName,
              lat: location.lat,
              lng: location.lng,
              icon,
              measurementUnit,
              hauled,
              selectors
            };

            if (location.lat) {
              setActiveSourcePinInfo(activeSourceLocations, locationObj);
            }
          }
        }

        if (this.returnNow) {
          return;
        }

        const allSourceLocations = [];
        if (this.markerFilter.allSources) {
          for (const key in this.masterSources) {
            const location = this.masterSources[key];
            const customerId = location.customerId;

            let isActive = false;
            for (let i = 0; i < activeSources.length; i++) {
              let activeSource = activeSources[i];

              if (
                customerId === activeSource.customerId &&
                key === activeSource.sourceLocationId
              ) {
                isActive = true;
                if (location.lat) {
                  let hauled = 0;
                  for (const key in activeSource.hauls) {
                    hauled += activeSource.hauls[key].haulAmount;
                  }

                  hauled = Math.round((hauled + Number.EPSILON) * 100) / 100;

                  const selectors = [];
                  try {
                    if (this.isAdmin) {
                      const adminSource = adminSources[i];
                      adminSource.customSelectors.forEach(x => {
                        for (
                          let k = 0;
                          k <
                          this.settingsUsers.sourceSettings.customSelectors
                            .length;
                          k++
                        ) {
                          const customSelector =
                            this.settingsUsers.sourceSettings.customSelectors[
                              k
                            ];

                          if (x.name === customSelector.name) {
                            let option = customSelector.options.find(z => {
                              return z.option === x.model;
                            });

                            selectors.push(option);
                          }
                        }
                      });
                    } else {
                      activeSource.userCustomSelectors.forEach(x => {
                        for (
                          let k = 0;
                          k <
                          this.settingsUsers.sourceSettings.customSelectors
                            .length;
                          k++
                        ) {
                          const customSelector =
                            this.settingsUsers.sourceSettings.customSelectors[
                              k
                            ];

                          if (x.name === customSelector.name) {
                            let option = customSelector.options.find(z => {
                              return z.option === x.model;
                            });

                            selectors.push(option);
                          }
                        }
                      });
                    }
                  } catch (err) {
                    if (this.setSelectorNum < 3) {
                      this.setSelectorNum++;
                      console.log(err);
                      this.setPins;
                      this.returnNow = true;
                    }
                  }

                  if (this.returnNow) {
                    break;
                  }

                  if (
                    (activeSource.sourceStatus !== 'Open' && !this.isAdmin) ||
                    (activeSource.sourceStatus !== 'Open' &&
                      !this.markerFilter.sourceWaiting)
                  ) {
                    continue;
                  }

                  const icon =
                    activeSource.sourceStatus !== 'Open'
                      ? greenMoreTime
                      : greenIcon;

                  if (this.isAdmin) {
                    // Properly combine service objects
                    for (let j = 0; j < activeSource.services.length; j++) {
                      activeSource.services[j] = {
                        ...adminSources[i].services[j],
                        ...activeSource.services[j]
                      };
                    }

                    activeSource = {
                      ...adminSources[i],
                      ...activeSource
                    };
                  }

                  this.reports.activeSources.push(activeSource);

                  let measurementUnit = '';

                  for (const key in this.settingsUsers.commodities) {
                    if (key === activeSource.commodity) {
                      measurementUnit =
                        this.settingsUsers.commodities[key].measurementUnit;
                    }
                  }

                  const locationObj = {
                    id: key,
                    customer: this.customers[customerId].name,
                    sourceName: location.sourceName,
                    lat: location.lat,
                    lng: location.lng,
                    icon,
                    measurementUnit,
                    hauled,
                    selectors
                  };

                  setActiveSourcePinInfo(allSourceLocations, locationObj);
                }
                break;
              }
            }
            if (!isActive) {
              if (!(this.isAdmin || this.isSuper)) {
                continue;
              }
              if (location.lat) {
                const locationObj = {
                  id: key,
                  customer: this.customers[customerId].name,
                  sourceName: location.sourceName,
                  lat: location.lat,
                  lng: location.lng
                };

                setAllSourcePinInfo(allSourceLocations, locationObj);
              }
            }
          }
        }

        if (returnNow) {
          return;
        }

        const map = this.map;
        const google = this.google;

        const setOnMap = (location, type) => {
          const infoWindow = new google.maps.InfoWindow({
            content: location.infoWindowContent
          });

          const marker = new google.maps.Marker({
            position: location.position,
            map,
            icon: location.icon
          });

          marker.defaultContent = location.infoWindowContent;
          marker.selectorContent = location.selectorContent;

          marker.addListener('click', () => {
            infoWindow.open(map, marker);
          });

          marker.type = type;
          marker.id = location.id;

          marker.openWindow = () => {
            infoWindow.open(map, marker);
          };

          marker.closeWindow = () => {
            infoWindow.close();
          };

          marker.closeSelectorWindow = () => {};

          this.markers.push(marker);
        };

        for (let i = 0; i < activeDestLocations.length; i++) {
          setOnMap(activeDestLocations[i], 'destination');
        }

        for (let i = 0; i < allDestLocations.length; i++) {
          setOnMap(allDestLocations[i], 'destination');
        }

        for (let i = 0; i < activeSourceLocations.length; i++) {
          setOnMap(activeSourceLocations[i], 'source');
        }

        for (let i = 0; i < allSourceLocations.length; i++) {
          setOnMap(allSourceLocations[i], 'source');
        }

        if (!this.incomingPinChecked) {
          this.checkIncomingPin();
        }
      };

      setPinsOnMap();
      this.setSelectorWindows();
    },
    setPinStart() {
      if (!this.setPinStarted) {
        this.setPinStarted = true;

        const onTimerEnd = () => {
          this.setPinStarted = false;
          this.setPins();
        };

        setTimeout(() => {
          onTimerEnd();
        }, 200);
      }
    },
    setMapOnAll(directive) {
      let map = this.map;
      if (!directive) {
        map = null;
      }

      for (let i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(map);
      }
    },
    setSelectorWindows() {
      for (let i = 0; i < this.markers.length; i++) {
        const marker = this.markers[i];

        if (marker.selectorContent) {
          const infoWindow = new this.google.maps.InfoWindow({
            content: marker.selectorContent
          });

          marker.openSelectorWindow = () => {
            infoWindow.open(this.map, marker);
          };

          marker.closeSelectorWindow = () => {
            infoWindow.close(this.map, marker);
          };

          marker.openSelectorWindow();
        }
      }
    },
    showLocationsList() {
      this.currentDisplay = 'locationsList';
    },
    sourceReport(locationId) {
      const source = this.reports.activeSources.find(source => {
        return locationId === source.sourceLocationId;
      });

      this.reports.id = source.id;
      this.reports.reportType = 'source';
      this.currentDisplay = 'mapReport';
    },
    goBetween() {
      window.location.href = `
        https://www.google.com/maps/dir/?api=1&origin=
        ${this.betweenPins.startPosition.lat},${this.betweenPins.startPosition.lng}
        &destination=${this.betweenPins.endPosition.lat},${this.betweenPins.endPosition.lng}
      `;
    },
    goBetweenCancel() {
      this.betweenPins = {
        message: false,
        startMessage: '',
        startSet: false,
        startPosition: {
          lat: null,
          lng: null
        },
        endMessage: '',
        endSet: false,
        endPosition: {
          lat: null,
          lng: null
        }
      };
    },
    startDirections(name, lat, lng) {
      // Override everything for reactivity
      this.betweenPins = {
        message: true,
        startMessage: name,
        startSet: true,
        startPosition: {
          lat: lat,
          lng: lng
        },
        endMessage: this.betweenPins.endMessage,
        endSet: this.betweenPins.endSet,
        endPosition: this.betweenPins.endPosition
      };
    },
    endDirections(name, lat, lng) {
      // Override everything for reactivity
      this.betweenPins = {
        message: true,
        endMessage: name,
        endSet: true,
        endPosition: {
          lat: lat,
          lng: lng
        },
        startMessage: this.betweenPins.startMessage,
        startSet: this.betweenPins.startSet,
        startPosition: this.betweenPins.startPosition
      };
    }
  },
  computed: {
    customers() {
      return JSON.parse(JSON.stringify(this.$store.state.customers));
    },
    destinationInputs() {
      return this.$store.state.filterLists.destinationInputs;
    },
    isGuestCustomer() {
      return (
        this.$store.state.user.superCustomer || this.$store.state.user.customer
      );
    },
    settingsUsers() {
      return JSON.parse(JSON.stringify(this.$store.state.settingsUsers));
    },
    masterDestinations() {
      return JSON.parse(JSON.stringify(this.$store.state.masterDestinations));
    },
    masterSources() {
      return JSON.parse(JSON.stringify(this.$store.state.masterSources));
    }
  },
  watch: {
    '$store.state.activeDestinationsUsers'() {
      this.setPinStart();
    },
    '$store.state.activeSourcesUsers'() {
      this.setPinStart();
    },
    // This also triggers for change in masterSources
    '$store.state.masterDestinations'() {
      this.setPinStart();
    },
    '$store.state.settingsUsers'() {
      this.setPinStart();
    }
  }
};
</script>

<style scoped>
#btns {
  position: absolute;
  top: 0;
  right: 0;
}

#between-pins {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
}

#loading-gif {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100px;
  height: 100px;
}

#map-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
}

.map {
  width: 100%;
  height: 90%;
  min-height: inherit;
}
</style>
