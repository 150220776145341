import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './firebase';
import './quasar';

// Importing the global css file
import '@/assets/global.css';

import './registerServiceWorker';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
