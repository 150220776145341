import store from './../store';

const round = num => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

const compare = (a, b) => {
  if (a.dateTime > b.dateTime) {
    return -1;
  }
  if (a.dateTime < b.dateTime) {
    return 1;
  }
  return 0;
};

const sourceReport = (
  sourceId,
  archived = false,
  source = {},
  sourceUsers = {}
) => {
  const activeSources = JSON.parse(JSON.stringify(store.state.activeSources));
  const activeSourcesUsers = JSON.parse(
    JSON.stringify(store.state.activeSourcesUsers)
  );

  if (!archived) {
    source = activeSources.find(source => {
      return source.id === sourceId;
    });

    sourceUsers = activeSourcesUsers.find(source => {
      return source.id === sourceId;
    });
  }

  for (let i = 0; i < sourceUsers.services.length; i++) {
    sourceUsers.services[i] = {
      ...sourceUsers.services[i],
      ...source.services[i]
    };
  }

  const sourceRaw = {
    ...source,
    ...sourceUsers
  };

  const sourceInputs = JSON.parse(
    JSON.stringify(store.state.filterLists.sourceInputs)
  );
  const guestUserList = JSON.parse(JSON.stringify(store.state.guestUserList));
  const customers = JSON.parse(JSON.stringify(store.state.customers));
  const userSettings = JSON.parse(JSON.stringify(store.state.settingsUsers));
  const masterSources = JSON.parse(JSON.stringify(store.state.masterSources));

  source.id = sourceRaw.id;
  source.updateId = sourceRaw.updateId;
  source.source = sourceRaw;
  source.customerId = sourceRaw.customerId;
  try {
    source.customer = customers[sourceRaw.customerId].name;
    source.customerNote = customers[sourceRaw.customerId].note;
  } catch {
    source.customer = sourceRaw.customer;
    source.customerNote = '';
  }

  try {
    source.location = masterSources[sourceRaw.sourceLocationId];
    source.locationId = sourceRaw.sourceLocationId;
    source.sourceName = source.location.sourceName;
  } catch {
    source.sourceName = sourceRaw.sourceName;
    source.location = {
      lat: null,
      lng: null,
      attachmentId: null
    };
  }
  source.settlementReports = sourceRaw.settlementReports;
  source.sourceStatus = sourceRaw.sourceStatus;
  source.trackMileage = sourceRaw.trackMileage;
  source.mileageRate = sourceRaw.mileageRate;
  source.alwaysAvailable = sourceRaw.alwaysAvailable;
  source.trackRunningBalance = sourceRaw.trackRunningBalance;
  source.runningBalance = sourceRaw.runningBalance;
  source.projected = sourceRaw.projected;

  source.hauls = sourceRaw.hauls;

  source.startDate = sourceRaw.startDate;
  source.startDateFormatted = new Date(sourceRaw.startDate).toDateString();
  source.settlementDate = sourceRaw.settlementDate;
  source.settlementDateFormatted = new Date(
    sourceRaw.settlementDate
  ).toDateString();

  source.endDate = sourceRaw.endDate;
  if (sourceRaw.endDate === null) {
    source.readyForSettlement = false;
  } else {
    source.readyForSettlement = true;
  }

  source.commodity = sourceRaw.commodity;
  source.purchaseCommodity = sourceRaw.purchaseCommodity;

  source.measurementUnit = '';
  for (const key in userSettings.commodities) {
    if (source.commodity === key) {
      source.measurementUnit = userSettings.commodities[key].measurementUnit;
    }
  }

  source.pricePerUnit = sourceRaw.pricePerUnit;
  source.note = sourceRaw.note;
  source.importantNote = sourceRaw.importantNote;
  source.adminNote = sourceRaw.adminNote;
  source.allowGuestUsers = sourceRaw.allowGuestUsers;
  source.guestUsers = sourceRaw.guestUsers;
  const guestUsers = sourceRaw.guestUsers.map(userId => {
    return guestUserList.guestUserIds[userId];
  });
  source.guestUserStr = guestUsers.join(', ');

  source.dispatchedTo = sourceRaw.dispatchedTo;
  const dispatchedTo = sourceRaw.dispatchedTo.map(userId => {
    return store.state.userList.userIds[userId];
  });
  source.isDispatched = dispatchedTo.includes(store.state.user.displayName);
  source.dispatchedToStr = dispatchedTo.join(', ');

  source.infoBoxes = [];
  for (let j = 0; j < sourceRaw.infoBoxes.length; j++) {
    const infoBox = sourceRaw.infoBoxes[j];
    if (!Object.prototype.hasOwnProperty.call(sourceInputs, infoBox.name)) {
      infoBox.filter = true;
    } else {
      infoBox.filter = sourceInputs[infoBox.name].model;
    }
    source.infoBoxes.push(infoBox);
  }

  source.customSelectors = [];
  for (let j = 0; j < sourceRaw.customSelectors.length; j++) {
    const customSelector = sourceRaw.customSelectors[j];
    if (
      !Object.prototype.hasOwnProperty.call(sourceInputs, customSelector.name)
    ) {
      customSelector.filter = true;
    } else {
      customSelector.filter = sourceInputs[customSelector.name].model;
    }

    for (
      let k = 0;
      k < userSettings.sourceSettings.customSelectors.length;
      k++
    ) {
      const selector = userSettings.sourceSettings.customSelectors[k];

      if (selector.name === customSelector.name) {
        for (let l = 0; l < selector.options.length; l++) {
          if (selector.options[l].option === customSelector.model) {
            customSelector.color = selector.options[l].optionColor;
          }
        }
      }
    }

    source.customSelectors.push(customSelector);
  }

  let servicesComplete = true;
  const services = [];
  let serviceStr = '';
  let minServiceTs = null;
  for (let j = 0; j < sourceRaw.services.length; j++) {
    serviceStr += sourceRaw.services[j].name + ', ';
    const serviceObj = {};
    serviceObj.name = sourceRaw.services[j].name;
    serviceObj.price = sourceRaw.services[j].price;
    serviceObj.complete = sourceRaw.services[j].complete;
    serviceObj.completeTs = sourceRaw.services[j].completeTs;
    serviceObj.completeTs = sourceRaw.services[j].completeTs;
    if (
      Object.prototype.hasOwnProperty.call(
        sourceRaw.services[j],
        'infoBoxPairedWith'
      )
    ) {
      serviceObj.useInfoBox = sourceRaw.services[j].useInfoBox;
      serviceObj.infoBoxPairedWith = sourceRaw.services[j].infoBoxPairedWith;
    }

    if (
      Object.prototype.hasOwnProperty.call(sourceRaw.services[j], 'hasDate')
    ) {
      const serviceTs = sourceRaw.services[j].serviceTs;
      const isComplete = sourceRaw.services[j].complete;

      serviceObj.hasDate = sourceRaw.services[j].hasDate;
      serviceObj.serviceTs = serviceTs;
      minServiceTs =
        !minServiceTs && !isComplete
          ? serviceTs
          : serviceTs < minServiceTs && !isComplete
          ? serviceTs
          : minServiceTs;
    }

    services.push(serviceObj);
    if (sourceRaw.services[j].complete === false) {
      servicesComplete = false;
    }
  }
  source.scheduledFormatted = !minServiceTs
    ? '---'
    : new Date(minServiceTs).toDateString();
  source.minServiceTs = minServiceTs;
  source.serviceStr = serviceStr.substring(0, serviceStr.length - 2);
  source.services = services;
  source.servicesComplete = servicesComplete;

  source.haulTrackerArr = sourceRaw.haulTrackers.slice();
  source.trackerStr = source.haulTrackerArr.join(', ');

  source.haulsObj = sourceRaw.hauls;
  source.hauls = [];
  source.destinations = {};
  source.haulTrackers = {};
  source.hauled = 0;
  source.totalMileage = 0;
  for (const key in sourceRaw.hauls) {
    const haul = sourceRaw.hauls[key];
    source.hauls.push(haul);
    source.hauled += haul.haulAmount;
    haul.mileage = haul.mileage === '' ? 0 : haul.mileage;
    if (source.trackMileage) {
      source.totalMileage += haul.mileage;
    }

    // add destination haulTracker totals
    for (let k = 0; k < haul.destinationHaulTrackers.length; k++) {
      const tracker = haul.destinationHaulTrackers[k];
      if (!(tracker.type in source.haulTrackers)) {
        source.haulTrackers[tracker.type] = {
          [tracker.option]: {
            haulAmount: haul.haulAmount,
            mileage: haul.mileage
          }
        };
      } else if (!(tracker.option in source.haulTrackers[tracker.type])) {
        source.haulTrackers[tracker.type][tracker.option] = {
          haulAmount: haul.haulAmount,
          mileage: haul.mileage
        };
      } else {
        source.haulTrackers[tracker.type][tracker.option] = {
          haulAmount:
            source.haulTrackers[tracker.type][tracker.option].haulAmount +
            haul.haulAmount,
          mileage:
            source.haulTrackers[tracker.type][tracker.option].mileage +
            haul.mileage
        };
      }
    }

    // add source haulTracker totals
    for (let k = 0; k < haul.sourceHaulTrackers.length; k++) {
      const tracker = haul.sourceHaulTrackers[k];
      if (!(tracker.type in source.haulTrackers)) {
        source.haulTrackers[tracker.type] = {
          [tracker.option]: {
            haulAmount: haul.haulAmount,
            mileage: haul.mileage
          }
        };
      } else if (!(tracker.option in source.haulTrackers[tracker.type])) {
        source.haulTrackers[tracker.type][tracker.option] = {
          haulAmount: haul.haulAmount,
          mileage: haul.mileage
        };
      } else {
        source.haulTrackers[tracker.type][tracker.option] = {
          haulAmount:
            source.haulTrackers[tracker.type][tracker.option].haulAmount +
            haul.haulAmount,
          mileage:
            source.haulTrackers[tracker.type][tracker.option].mileage +
            haul.mileage
        };
      }
    }

    for (const key in source.haulTrackers) {
      const tracker = source.haulTrackers[key];

      if (key.startsWith('***&&&***') || key.startsWith('***&&&^^^')) {
        let totalMU = 0;
        for (const option in tracker) {
          if (option !== 'Total') {
            totalMU += parseFloat(option);
          }
        }
        source.haulTrackers[key]['Total'] = {
          haulAmount: 0,
          totalMU: totalMU.toLocaleString('en-US') + ' ' + key.substring(9)
        };
      }

      for (const option in tracker) {
        source.haulTrackers[key][option].haulAmount = round(
          source.haulTrackers[key][option].haulAmount
        );
      }
    }

    // add destination totals
    if (!(haul.destinationCustomerId in source.destinations)) {
      source.destinations[haul.destinationCustomerId] = {
        [haul.destinationLocationId]: {
          haulAmount: haul.haulAmount,
          sourceAsDestination: haul.sourceAsDestination
        }
      };
    } else if (
      !(
        haul.destinationLocationId in
        source.destinations[haul.destinationCustomerId]
      )
    ) {
      source.destinations[haul.destinationCustomerId][
        haul.destinationLocationId
      ] = {
        haulAmount: haul.haulAmount,
        sourceAsDestination: haul.sourceAsDestination
      };
    } else {
      source.destinations[haul.destinationCustomerId][
        haul.destinationLocationId
      ].haulAmount += haul.haulAmount;
    }
  }

  source.hauls = source.hauls.sort(compare);

  for (const locationId in source.destinations) {
    for (const key in source.destinations[locationId]) {
      source.destinations[locationId][key].haulAmount = round(
        source.destinations[locationId][key].haulAmount
      );
    }
  }

  let projected = userSettings.sourceSettings.projectedPlaceholder;
  if (sourceRaw.projected) {
    projected = sourceRaw.projected;
    source.percentHauled = Math.round((source.hauled / projected) * 100);
    if (
      projected - source.hauled <=
      userSettings.sourceSettings.projectedTolerance
    ) {
      source.percentHauled = 100;
    }
  } else {
    source.percentHauled = Math.round((source.hauled / projected) * 100);
  }
  if (source.percentHauled > 100) {
    source.percentHauled = 100;
  }
  source.percentHauled = source.percentHauled + '%';
  source.hauled = round(source.hauled);
  source.balance = round(source.projected - source.hauled);
  source.balance = source.balance < 0 ? 0 : source.balance;

  source.avatarColor = sourceRaw.avatarColor;

  return source;
};

const sourceReportUsers = sourceId => {
  const activeSourcesUsers = JSON.parse(
    JSON.stringify(store.state.activeSourcesUsers)
  );

  const sourceRaw = activeSourcesUsers.find(source => {
    return source.id === sourceId;
  });

  const sourceInputs = JSON.parse(
    JSON.stringify(store.state.filterLists.sourceInputs)
  );
  const customers = JSON.parse(JSON.stringify(store.state.customers));
  const userSettings = JSON.parse(JSON.stringify(store.state.settingsUsers));
  const masterSources = JSON.parse(JSON.stringify(store.state.masterSources));

  const source = {};
  source.id = sourceRaw.id;
  source.updateId = sourceRaw.updateId;
  source.source = sourceRaw;
  source.customerId = sourceRaw.customerId;
  source.customer = customers[sourceRaw.customerId].name;

  source.location = masterSources[sourceRaw.sourceLocationId];
  source.locationId = sourceRaw.sourceLocationId;
  source.sourceName = source.location.sourceName;

  source.trackMileage = sourceRaw.trackMileage;
  source.alwaysAvailable = sourceRaw.alwaysAvailable;
  source.trackRunningBalance = sourceRaw.trackRunningBalance;
  source.runningBalance = sourceRaw.runningBalance;
  source.projected = sourceRaw.projected;

  source.hauls = sourceRaw.hauls;

  source.startDate = sourceRaw.startDate;
  source.startDateFormatted = new Date(sourceRaw.startDate).toDateString();

  source.commodity = sourceRaw.commodity;

  source.measurementUnit = '';
  for (const key in userSettings.commodities) {
    if (source.commodity === key) {
      source.measurementUnit = userSettings.commodities[key].measurementUnit;
    }
  }

  source.note = sourceRaw.note;
  source.importantNote = sourceRaw.importantNote;

  source.infoBoxes = [];
  for (let j = 0; j < sourceRaw.userInfoBoxes.length; j++) {
    const infoBox = sourceRaw.userInfoBoxes[j];
    if (!Object.prototype.hasOwnProperty.call(sourceInputs, infoBox.name)) {
      infoBox.filter = true;
    } else {
      infoBox.filter = sourceInputs[infoBox.name].model;
    }
    source.infoBoxes.push(infoBox);
  }

  source.customSelectors = [];
  for (let j = 0; j < sourceRaw.userCustomSelectors.length; j++) {
    const customSelector = sourceRaw.userCustomSelectors[j];
    if (
      !Object.prototype.hasOwnProperty.call(sourceInputs, customSelector.name)
    ) {
      customSelector.filter = true;
    } else {
      customSelector.filter = sourceInputs[customSelector.name].model;
    }

    for (
      let k = 0;
      k < userSettings.sourceSettings.customSelectors.length;
      k++
    ) {
      const selector = userSettings.sourceSettings.customSelectors[k];

      if (selector.name === customSelector.name) {
        for (let l = 0; l < selector.options.length; l++) {
          if (selector.options[l].option === customSelector.model) {
            customSelector.color = selector.options[l].optionColor;
          }
        }
      }
    }

    source.customSelectors.push(customSelector);
  }
  source.userCustomSelectors = source.customSelectors.slice();

  let servicesComplete = true;
  const services = [];
  let serviceStr = '';
  let minServiceTs = null;
  for (let j = 0; j < sourceRaw.services.length; j++) {
    serviceStr += sourceRaw.services[j].name + ', ';
    const serviceObj = {};
    serviceObj.name = sourceRaw.services[j].name;
    serviceObj.complete = sourceRaw.services[j].complete;
    serviceObj.completeTs = sourceRaw.services[j].completeTs;

    if (
      Object.prototype.hasOwnProperty.call(sourceRaw.services[j], 'hasDate')
    ) {
      const serviceTs = sourceRaw.services[j].serviceTs;
      const isComplete = sourceRaw.services[j].complete;

      serviceObj.hasDate = sourceRaw.services[j].hasDate;
      serviceObj.serviceTs = serviceTs;
      minServiceTs =
        !minServiceTs && !isComplete
          ? serviceTs
          : serviceTs < minServiceTs && !isComplete
          ? serviceTs
          : minServiceTs;
    }
    source.minServiceTs = minServiceTs;

    services.push(serviceObj);
    if (sourceRaw.services[j].complete === false) {
      servicesComplete = false;
    }
  }
  source.scheduledFormatted = !minServiceTs
    ? '---'
    : new Date(minServiceTs).toDateString();
  source.serviceStr = serviceStr.substring(0, serviceStr.length - 2);
  source.services = services;
  source.servicesComplete = servicesComplete;

  source.haulTrackerArr = sourceRaw.haulTrackers.slice();
  source.trackerStr = source.haulTrackerArr.join(', ');

  source.dispatchedTo = sourceRaw.dispatchedTo;
  const dispatchedTo = sourceRaw.dispatchedTo.map(userId => {
    return store.state.userList.userIds[userId];
  });
  source.isDispatched = dispatchedTo.includes(store.state.user.displayName);
  source.dispatchedToStr = dispatchedTo.join(', ');

  source.haulsObj = sourceRaw.hauls;
  source.hauls = [];
  source.destinations = {};
  source.haulTrackers = {};
  source.hauled = 0;
  source.totalMileage = 0;
  for (const key in sourceRaw.hauls) {
    const haul = sourceRaw.hauls[key];
    source.hauls.push(haul);
    source.hauled += haul.haulAmount;
    if (source.trackMileage) {
      source.totalMileage += haul.mileage;
    }

    // add destination haulTracker totals
    for (let k = 0; k < haul.destinationHaulTrackers.length; k++) {
      const tracker = haul.destinationHaulTrackers[k];
      if (!(tracker.type in source.haulTrackers)) {
        source.haulTrackers[tracker.type] = {
          [tracker.option]: {
            haulAmount: haul.haulAmount,
            mileage: haul.mileage
          }
        };
      } else if (!(tracker.option in source.haulTrackers[tracker.type])) {
        source.haulTrackers[tracker.type][tracker.option] = {
          haulAmount: haul.haulAmount,
          mileage: haul.mileage
        };
      } else {
        source.haulTrackers[tracker.type][tracker.option] = {
          haulAmount:
            source.haulTrackers[tracker.type][tracker.option].haulAmount +
            haul.haulAmount,
          mileage:
            source.haulTrackers[tracker.type][tracker.option].mileage +
            haul.mileage
        };
      }
    }

    // add source haulTracker totals
    for (let k = 0; k < haul.sourceHaulTrackers.length; k++) {
      const tracker = haul.sourceHaulTrackers[k];
      if (!(tracker.type in source.haulTrackers)) {
        source.haulTrackers[tracker.type] = {
          [tracker.option]: {
            haulAmount: haul.haulAmount,
            mileage: haul.mileage
          }
        };
      } else if (!(tracker.option in source.haulTrackers[tracker.type])) {
        source.haulTrackers[tracker.type][tracker.option] = {
          haulAmount: haul.haulAmount,
          mileage: haul.mileage
        };
      } else {
        source.haulTrackers[tracker.type][tracker.option] = {
          haulAmount:
            source.haulTrackers[tracker.type][tracker.option].haulAmount +
            haul.haulAmount,
          mileage:
            source.haulTrackers[tracker.type][tracker.option].mileage +
            haul.mileage
        };
      }
    }

    // add destination totals
    if (!(haul.destinationCustomerId in source.destinations)) {
      source.destinations[haul.destinationCustomerId] = {
        [haul.destinationLocationId]: {
          haulAmount: haul.haulAmount,
          sourceAsDestination: haul.sourceAsDestination
        }
      };
    } else if (
      !(
        haul.destinationLocationId in
        source.destinations[haul.destinationCustomerId]
      )
    ) {
      source.destinations[haul.destinationCustomerId][
        haul.destinationLocationId
      ] = {
        haulAmount: haul.haulAmount,
        sourceAsDestination: haul.sourceAsDestination
      };
    } else {
      source.destinations[haul.destinationCustomerId][
        haul.destinationLocationId
      ].haulAmount += haul.haulAmount;
    }
  }

  source.hauls = source.hauls.sort(compare);

  let projected = userSettings.sourceSettings.projectedPlaceholder;
  if (sourceRaw.projected) {
    projected = sourceRaw.projected;
    source.percentHauled = Math.round((source.hauled / projected) * 100);
    if (
      projected - source.hauled <=
      userSettings.sourceSettings.projectedTolerance
    ) {
      source.percentHauled = 100;
    }
  } else {
    source.percentHauled = Math.round((source.hauled / projected) * 100);
  }
  if (source.percentHauled > 100) {
    source.percentHauled = 100;
  }
  source.percentHauled = source.percentHauled + '%';
  source.hauled = round(source.hauled);
  source.balance = round(source.projected - source.hauled);
  source.balance = source.balance < 0 ? 0 : source.balance;

  source.avatarColor = sourceRaw.avatarColor;

  return source;
};

// destinationReport can also process archived, but the dest and destUsers
// must be passed in - otherwise an id is all that is needed
const destinationReport = (
  destinationId,
  archivedOrEst = false,
  dest = {},
  destUsers = {}
) => {
  const destinationInputs = JSON.parse(
    JSON.stringify(store.state.filterLists.destinationInputs)
  );

  const activeDestinations = JSON.parse(
    JSON.stringify(store.state.activeDestinations)
  );
  const activeDestinationsUsers = JSON.parse(
    JSON.stringify(store.state.activeDestinationsUsers)
  );

  if (!archivedOrEst) {
    dest = activeDestinations.find(dest => {
      return dest.id === destinationId;
    });

    destUsers = activeDestinationsUsers.find(dest => {
      return dest.id === destinationId;
    });
  }

  for (let i = 0; i < destUsers.services.length; i++) {
    destUsers.services[i] = {
      ...destUsers.services[i],
      ...dest.services[i]
    };
  }

  const destinationRaw = {
    ...dest,
    ...destUsers
  };

  const guestUserList = JSON.parse(JSON.stringify(store.state.guestUserList));
  const settings = JSON.parse(JSON.stringify(store.state.destinationSettings));
  const customers = JSON.parse(JSON.stringify(store.state.customers));
  const masterDestinations = JSON.parse(
    JSON.stringify(store.state.masterDestinations)
  );
  const userSettings = JSON.parse(JSON.stringify(store.state.settingsUsers));

  const destination = {};
  destination.id = destinationRaw.id;
  destination.updateId = destinationRaw.updateId;
  destination.destination = destinationRaw;
  destination.customerId = destinationRaw.customerId;
  // If customer or destination has been deleted
  try {
    destination.customer = customers[destinationRaw.customerId].name;
    destination.customerNote = customers[destinationRaw.customerId].note;
  } catch {
    destination.customer = destinationRaw.customer;
    destination.customerNote = '';
  }

  try {
    destination.location =
      masterDestinations[destinationRaw.destinationLocationId];
    destination.locationId = destinationRaw.destinationLocationId;
    destination.destinationName = destination.location.destinationName;
  } catch {
    destination.destinationName = destinationRaw.destinationName;
    destination.location = {
      lat: null,
      lng: null,
      attachmentId: null
    };
  }

  destination.startDate = destinationRaw.startDate;
  destination.startDateFormatted = new Date(
    destinationRaw.startDate
  ).toDateString();
  destination.settlementDate = destinationRaw.settlementDate;
  destination.settlementDateFormatted = new Date(
    destinationRaw.settlementDate
  ).toDateString();

  destination.endDate = destinationRaw.endDate;
  destination.trackMileage = destinationRaw.trackMileage;
  destination.mileageRate = destinationRaw.mileageRate;

  if (destinationRaw.endDate === null) {
    destination.readyForSettlement = false;
  } else {
    destination.readyForSettlement = true;
  }

  destination.commodity = destinationRaw.commodity;

  destination.measurementUnit = '';
  for (const key in userSettings.commodities) {
    if (destination.commodity === key) {
      destination.measurementUnit =
        userSettings.commodities[key].measurementUnit;
    }
  }

  destination.destinationStatus = destinationRaw.destinationStatus;
  destination.chargeCommodity = destinationRaw.chargeCommodity;
  destination.pricePerUnit = destinationRaw.pricePerUnit;
  destination.acreage = destinationRaw.acreage;
  destination.applicationType = destinationRaw.applicationType;
  destination.applicationRate = destinationRaw.applicationRate;
  destination.note = destinationRaw.note;
  destination.importantNote = destinationRaw.importantNote;
  destination.adminNote = destinationRaw.adminNote;
  destination.driverRate = destinationRaw.driverRate;
  destination.settlementReports = destinationRaw.settlementReports;
  destination.allowGuestUsers = destinationRaw.allowGuestUsers;
  destination.guestUsers = destinationRaw.guestUsers;
  destination.alwaysAvailable = destinationRaw.alwaysAvailable;
  const guestUsers = destinationRaw.guestUsers.map(userId => {
    return guestUserList.guestUserIds[userId];
  });
  destination.guestUserStr = guestUsers.join(', ');

  destination.dispatchedTo = destinationRaw.dispatchedTo.slice();
  const dispatchedTo = destinationRaw.dispatchedTo.map(userId => {
    return store.state.userList.userIds[userId];
  });
  destination.isDispatched = dispatchedTo.includes(
    store.state.user.displayName
  );
  destination.dispatchedToStr = dispatchedTo.join(', ');

  destination.infoBoxes = [];
  for (let j = 0; j < destinationRaw.infoBoxes.length; j++) {
    const infoBox = destinationRaw.infoBoxes[j];
    if (
      !Object.prototype.hasOwnProperty.call(destinationInputs, infoBox.name)
    ) {
      infoBox.filter = true;
    } else {
      infoBox.filter = destinationInputs[infoBox.name].model;
    }
    destination.infoBoxes.push(infoBox);
  }

  destination.customSelectors = [];
  for (let j = 0; j < destinationRaw.customSelectors.length; j++) {
    const customSelector = destinationRaw.customSelectors[j];
    if (
      !Object.prototype.hasOwnProperty.call(
        destinationInputs,
        customSelector.name
      )
    ) {
      customSelector.filter = true;
    } else {
      customSelector.filter = destinationInputs[customSelector.name].model;
    }

    for (
      let k = 0;
      k < userSettings.destinationSettings.customSelectors.length;
      k++
    ) {
      const selector = userSettings.destinationSettings.customSelectors[k];

      if (selector.name === customSelector.name) {
        for (let l = 0; l < selector.options.length; l++) {
          if (selector.options[l].option === customSelector.model) {
            customSelector.color = selector.options[l].optionColor;
          }
        }
      }
    }

    destination.customSelectors.push(customSelector);
  }

  let servicesComplete = true;
  const services = [];
  let serviceStr = '';
  let minServiceTs = null;
  for (let j = 0; j < destinationRaw.services.length; j++) {
    serviceStr += destinationRaw.services[j].name + ', ';
    const serviceObj = {};
    serviceObj.name = destinationRaw.services[j].name;
    serviceObj.price = destinationRaw.services[j].price;
    serviceObj.complete = destinationRaw.services[j].complete;
    serviceObj.completeTs = destinationRaw.services[j].completeTs;
    if (
      Object.prototype.hasOwnProperty.call(
        destinationRaw.services[j],
        'infoBoxPairedWith'
      )
    ) {
      serviceObj.useInfoBox = destinationRaw.services[j].useInfoBox;
      serviceObj.infoBoxPairedWith =
        destinationRaw.services[j].infoBoxPairedWith;
    }

    if (
      Object.prototype.hasOwnProperty.call(
        destinationRaw.services[j],
        'hasDate'
      )
    ) {
      const serviceTs = destinationRaw.services[j].serviceTs;
      const isComplete = destinationRaw.services[j].complete;

      serviceObj.hasDate = destinationRaw.services[j].hasDate;
      serviceObj.serviceTs = serviceTs;
      minServiceTs =
        !minServiceTs && !isComplete
          ? serviceTs
          : serviceTs < minServiceTs && !isComplete
          ? serviceTs
          : minServiceTs;
    }

    services.push(serviceObj);
    if (destinationRaw.services[j].complete === false) {
      servicesComplete = false;
    }
  }
  destination.scheduledFormatted = !minServiceTs
    ? '---'
    : new Date(minServiceTs).toDateString();
  destination.minServiceTs = minServiceTs;
  serviceStr = serviceStr.substring(0, serviceStr.length - 2);
  destination.serviceStr = serviceStr;
  destination.services = services;
  destination.servicesComplete = servicesComplete;

  destination.haulsObj = destinationRaw.hauls;
  destination.hauls = [];
  destination.sources = {};
  destination.haulTrackers = {};
  destination.hauled = 0;
  destination.totalMileage = 0;
  destination.haulTrackerArr = destinationRaw.haulTrackers.slice();
  destination.trackerStr = destination.haulTrackerArr.join(', ');

  for (const key in destinationRaw.hauls) {
    const haul = destinationRaw.hauls[key];
    destination.hauls.push(haul);
    destination.hauled += haul.haulAmount;
    haul.mileage = haul.mileage === '' ? 0 : haul.mileage;
    if (destination.trackMileage) {
      destination.totalMileage += haul.mileage;
    }

    // add destination haulTracker totals
    for (let k = 0; k < haul.destinationHaulTrackers.length; k++) {
      const tracker = haul.destinationHaulTrackers[k];
      if (!(tracker.type in destination.haulTrackers)) {
        destination.haulTrackers[tracker.type] = {
          [tracker.option]: {
            haulAmount: haul.haulAmount,
            mileage: haul.mileage
          }
        };
      } else if (!(tracker.option in destination.haulTrackers[tracker.type])) {
        destination.haulTrackers[tracker.type][tracker.option] = {
          haulAmount: haul.haulAmount,
          mileage: haul.mileage
        };
      } else {
        destination.haulTrackers[tracker.type][tracker.option] = {
          haulAmount:
            destination.haulTrackers[tracker.type][tracker.option].haulAmount +
            haul.haulAmount,
          mileage:
            destination.haulTrackers[tracker.type][tracker.option].mileage +
            haul.mileage
        };
      }
    }

    // add source haulTracker totals
    for (let k = 0; k < haul.sourceHaulTrackers.length; k++) {
      const tracker = haul.sourceHaulTrackers[k];
      if (!(tracker.type in destination.haulTrackers)) {
        destination.haulTrackers[tracker.type] = {
          [tracker.option]: {
            haulAmount: haul.haulAmount,
            mileage: haul.mileage
          }
        };
      } else if (!(tracker.option in destination.haulTrackers[tracker.type])) {
        destination.haulTrackers[tracker.type][tracker.option] = {
          haulAmount: haul.haulAmount,
          mileage: haul.mileage
        };
      } else {
        destination.haulTrackers[tracker.type][tracker.option] = {
          haulAmount:
            destination.haulTrackers[tracker.type][tracker.option].haulAmount +
            haul.haulAmount,
          mileage:
            destination.haulTrackers[tracker.type][tracker.option].mileage +
            haul.mileage
        };
      }
    }

    // add sources totals
    if (!(haul.sourceCustomerId in destination.sources)) {
      destination.sources[haul.sourceCustomerId] = {
        [haul.sourceLocationId]: haul.haulAmount
      };
    } else if (
      !(haul.sourceLocationId in destination.sources[haul.sourceCustomerId])
    ) {
      destination.sources[haul.sourceCustomerId][haul.sourceLocationId] =
        haul.haulAmount;
    } else {
      destination.sources[haul.sourceCustomerId][haul.sourceLocationId] +=
        haul.haulAmount;
    }
  }

  for (const locationId in destination.sources) {
    for (const key in destination.sources[locationId]) {
      destination.sources[locationId][key] = round(
        destination.sources[locationId][key]
      );
    }
  }

  for (const key in destination.haulTrackers) {
    const tracker = destination.haulTrackers[key];

    if (key.startsWith('***&&&***') || key.startsWith('***&&&^^^')) {
      let totalMU = 0;
      for (const option in tracker) {
        if (option !== 'Total') {
          totalMU += parseFloat(option);
        }
      }
      destination.haulTrackers[key]['Total'] = {
        haulAmount: 0,
        totalMU: totalMU.toLocaleString('en-US') + ' ' + key.substring(9)
      };
    }

    for (const option in tracker) {
      destination.haulTrackers[key][option].haulAmount = round(
        destination.haulTrackers[key][option].haulAmount
      );
    }
  }

  destination.hauls = destination.hauls.sort(compare);

  let projected = settings.projectedPlaceholder;
  destination.balance = 0;
  if (destinationRaw.projected !== '') {
    projected = destinationRaw.projected;
    destination.balance = round(destinationRaw.projected - destination.hauled);
    if (destination.balance < 0) {
      destination.balance = 0;
    }
    destination.percentHauled = Math.round(
      (destination.hauled / projected) * 100
    );
    if (destination.balance <= settings.projectedTolerance) {
      destination.percentHauled = 100;
    }
  } else {
    destination.percentHauled = Math.round(
      (destination.hauled / projected) * 100
    );
  }
  if (destination.percentHauled > 100) {
    destination.percentHauled = 100;
  }
  destination.hauled = round(destination.hauled);
  destination.percentHauled = destination.percentHauled + '%';

  destination.projected = destinationRaw.projected;
  destination.avatarColor = destinationRaw.avatarColor;

  return destination;
};

const destinationReportUsers = destinationId => {
  const activeDestinationsUsers = JSON.parse(
    JSON.stringify(store.state.activeDestinationsUsers)
  );

  const destinationRaw = activeDestinationsUsers.find(dest => {
    return dest.id === destinationId;
  });

  const destinationInputs = JSON.parse(
    JSON.stringify(store.state.filterLists.destinationInputs)
  );

  const settings = JSON.parse(
    JSON.stringify(store.state.settingsUsers.destinationSettings)
  );
  const customers = JSON.parse(JSON.stringify(store.state.customers));
  const masterDestinations = JSON.parse(
    JSON.stringify(store.state.masterDestinations)
  );
  const userSettings = JSON.parse(JSON.stringify(store.state.settingsUsers));

  const destination = {};
  destination.id = destinationRaw.id;
  destination.updateId = destinationRaw.updateId;
  destination.destination = destinationRaw;
  destination.customerId = destinationRaw.customerId;
  destination.customer = customers[destinationRaw.customerId].name;

  destination.location =
    masterDestinations[destinationRaw.destinationLocationId];
  destination.locationId = destinationRaw.destinationLocationId;
  destination.destinationName = destination.location.destinationName;

  destination.startDate = destinationRaw.startDate;
  destination.startDateFormatted = new Date(
    destinationRaw.startDate
  ).toDateString();

  destination.trackMileage = destinationRaw.trackMileage;

  destination.commodity = destinationRaw.commodity;
  destination.note = destinationRaw.note;
  destination.importantNote = destinationRaw.importantNote;
  destination.driverRate = destinationRaw.driverRate;
  destination.alwaysAvailable = destinationRaw.alwaysAvailable;

  destination.measurementUnit = '';
  for (const key in userSettings.commodities) {
    if (destination.commodity === key) {
      destination.measurementUnit =
        userSettings.commodities[key].measurementUnit;
    }
  }

  destination.infoBoxes = [];
  for (let j = 0; j < destinationRaw.userInfoBoxes.length; j++) {
    const infoBox = destinationRaw.userInfoBoxes[j];
    if (
      !Object.prototype.hasOwnProperty.call(destinationInputs, infoBox.name)
    ) {
      infoBox.filter = true;
    } else {
      infoBox.filter = destinationInputs[infoBox.name].model;
    }

    destination.infoBoxes.push(infoBox);
  }

  destination.customSelectors = [];
  for (let j = 0; j < destinationRaw.userCustomSelectors.length; j++) {
    const customSelector = destinationRaw.userCustomSelectors[j];
    if (
      !Object.prototype.hasOwnProperty.call(
        destinationInputs,
        customSelector.name
      )
    ) {
      customSelector.filter = true;
    } else {
      customSelector.filter = destinationInputs[customSelector.name].model;
    }

    for (
      let k = 0;
      k < userSettings.destinationSettings.customSelectors.length;
      k++
    ) {
      const selector = userSettings.destinationSettings.customSelectors[k];

      if (selector.name === customSelector.name) {
        for (let l = 0; l < selector.options.length; l++) {
          if (selector.options[l].option === customSelector.model) {
            customSelector.color = selector.options[l].optionColor;
          }
        }
      }
    }

    destination.customSelectors.push(customSelector);
  }
  destination.userCustomSelectors = destination.customSelectors.slice();

  let servicesComplete = true;
  const services = [];
  let serviceStr = '';
  let minServiceTs = null;
  for (let j = 0; j < destinationRaw.services.length; j++) {
    serviceStr += destinationRaw.services[j].name + ', ';
    const serviceObj = {};
    serviceObj.name = destinationRaw.services[j].name;
    serviceObj.complete = destinationRaw.services[j].complete;
    serviceObj.completeTs = destinationRaw.services[j].completeTs;

    if (
      Object.prototype.hasOwnProperty.call(
        destinationRaw.services[j],
        'hasDate'
      )
    ) {
      const serviceTs = destinationRaw.services[j].serviceTs;
      const isComplete = destinationRaw.services[j].complete;

      serviceObj.hasDate = destinationRaw.services[j].hasDate;
      serviceObj.serviceTs = serviceTs;
      minServiceTs =
        !minServiceTs && !isComplete
          ? serviceTs
          : serviceTs < minServiceTs && !isComplete
          ? serviceTs
          : minServiceTs;
    }
    destination.minServiceTs = minServiceTs;

    services.push(serviceObj);
    if (destinationRaw.services[j].complete === false) {
      servicesComplete = false;
    }
  }
  destination.scheduledFormatted = !minServiceTs
    ? '---'
    : new Date(minServiceTs).toDateString();
  serviceStr = serviceStr.substring(0, serviceStr.length - 2);
  destination.serviceStr = serviceStr;
  destination.services = services;
  destination.servicesComplete = servicesComplete;

  destination.haulsObj = destinationRaw.hauls;
  destination.hauls = [];
  destination.sources = {};
  destination.haulTrackers = {};
  destination.hauled = 0;
  destination.totalMileage = 0;
  destination.haulTrackerArr = destinationRaw.haulTrackers.slice();
  destination.trackerStr = destination.haulTrackerArr.join(', ');

  destination.dispatchedTo = destinationRaw.dispatchedTo;
  const dispatchedTo = destinationRaw.dispatchedTo.map(userId => {
    return store.state.userList.userIds[userId];
  });
  destination.isDispatched = dispatchedTo.includes(
    store.state.user.displayName
  );
  destination.dispatchedToStr = dispatchedTo.join(', ');

  for (const key in destinationRaw.hauls) {
    const haul = destinationRaw.hauls[key];
    destination.hauls.push(haul);
    destination.hauled += haul.haulAmount;
    haul.mileage = haul.mileage === '' ? 0 : haul.mileage;
    if (destination.trackMileage) {
      destination.totalMileage += haul.mileage;
    }

    // add destination haulTracker totals
    for (let k = 0; k < haul.destinationHaulTrackers.length; k++) {
      const tracker = haul.destinationHaulTrackers[k];
      if (!(tracker.type in destination.haulTrackers)) {
        destination.haulTrackers[tracker.type] = {
          [tracker.option]: {
            haulAmount: haul.haulAmount,
            mileage: haul.mileage
          }
        };
      } else if (!(tracker.option in destination.haulTrackers[tracker.type])) {
        destination.haulTrackers[tracker.type][tracker.option] = {
          haulAmount: haul.haulAmount,
          mileage: haul.mileage
        };
      } else {
        destination.haulTrackers[tracker.type][tracker.option] = {
          haulAmount:
            destination.haulTrackers[tracker.type][tracker.option].haulAmount +
            haul.haulAmount,
          mileage:
            destination.haulTrackers[tracker.type][tracker.option].mileage +
            haul.mileage
        };
      }
    }

    // add source haulTracker totals
    for (let k = 0; k < haul.sourceHaulTrackers.length; k++) {
      const tracker = haul.sourceHaulTrackers[k];
      if (!(tracker.type in destination.haulTrackers)) {
        destination.haulTrackers[tracker.type] = {
          [tracker.option]: {
            haulAmount: haul.haulAmount,
            mileage: haul.mileage
          }
        };
      } else if (!(tracker.option in destination.haulTrackers[tracker.type])) {
        destination.haulTrackers[tracker.type][tracker.option] = {
          haulAmount: haul.haulAmount,
          mileage: haul.mileage
        };
      } else {
        destination.haulTrackers[tracker.type][tracker.option] = {
          haulAmount:
            destination.haulTrackers[tracker.type][tracker.option].haulAmount +
            haul.haulAmount,
          mileage:
            destination.haulTrackers[tracker.type][tracker.option].mileage +
            haul.mileage
        };
      }
    }

    // add sources totals
    if (!(haul.sourceCustomerId in destination.sources)) {
      destination.sources[haul.sourceCustomerId] = {
        [haul.sourceLocationId]: haul.haulAmount
      };
    } else if (
      !(haul.sourceLocationId in destination.sources[haul.sourceCustomerId])
    ) {
      destination.sources[haul.sourceCustomerId][haul.sourceLocationId] =
        haul.haulAmount;
    } else {
      destination.sources[haul.sourceCustomerId][haul.sourceLocationId] +=
        haul.haulAmount;
    }
  }

  for (const locationId in destination.sources) {
    for (const key in destination.sources[locationId]) {
      destination.sources[locationId][key] = round(
        destination.sources[locationId][key]
      );
    }
  }

  for (const key in destination.haulTrackers) {
    const tracker = destination.haulTrackers[key];

    if (key.startsWith('***&&&***') || key.startsWith('***&&&^^^')) {
      let totalMU = 0;
      for (const option in tracker) {
        if (option !== 'Total') {
          totalMU += parseFloat(option);
        }
      }
      destination.haulTrackers[key]['Total'] = {
        haulAmount: 0,
        totalMU: totalMU.toLocaleString('en-US') + ' ' + key.substring(9)
      };
    }

    for (const option in tracker) {
      destination.haulTrackers[key][option].haulAmount = round(
        destination.haulTrackers[key][option].haulAmount
      );
    }
  }

  destination.hauls = destination.hauls.sort(compare);

  let projected = settings.projectedPlaceholder;
  destination.balance = 0;
  if (destinationRaw.projected !== '') {
    projected = destinationRaw.projected;
    destination.balance = round(destinationRaw.projected - destination.hauled);
    if (destination.balance < 0) {
      destination.balance = 0;
    }
    destination.percentHauled = Math.round(
      (destination.hauled / projected) * 100
    );
    if (destination.balance <= settings.projectedTolerance) {
      destination.percentHauled = 100;
    }
  } else {
    destination.percentHauled = Math.round(
      (destination.hauled / projected) * 100
    );
  }
  if (destination.percentHauled > 100) {
    destination.percentHauled = 100;
  }
  destination.hauled = round(destination.hauled);
  destination.percentHauled = destination.percentHauled + '%';
  destination.projected = destinationRaw.projected;
  destination.avatarColor = destinationRaw.avatarColor;

  return destination;
};

export {
  sourceReport,
  sourceReportUsers,
  destinationReport,
  destinationReportUsers
};
