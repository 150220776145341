<template>
  <div class="div-container-2">
    <ConfirmDialog
      v-if="confirmDelete"
      icon="home_repair_service"
      iconColor="primary"
      :btn="btn"
      :message="confirmMessage"
      :headerMessage="confirmHeaderMessage"
      @canceled="confirmDelete = false"
      @confirmed="deleteService"
    />

    <OkDialog
      v-if="okDialog"
      icon="home_repair_service"
      :message="okMessage"
      @okConfirmed="okDialog = false"
    />

    <div class="row q-pa-sm q-mt-xl">
      <q-space />
      <q-btn
        v-if="displayCreateNew"
        outline
        icon-right="list"
        label="List"
        padding="xs md"
        color="primary"
        @click="selectService"
      />
      <q-btn
        v-if="!displayCreateNew"
        outline
        icon-right="add"
        label="Add"
        padding="xs md"
        color="primary"
        @click="createNew"
      />
    </div>

    <div class="row q-mx-sm q-mt-xs">
      <div
        class="row justify-center items-center full-width rounded-borders text-custom-light q-mx-sm q-py-xs q-mb-md"
        style="background: radial-gradient(circle, #3336eb 0%, #0b0d6f 100%)"
      >
        <q-icon name="home_repair_service" size="md" />
        <span class="text-body1 q-ml-sm"> Services </span>
      </div>
    </div>

    <q-card v-if="!displayCreateNew" flat bordered class="q-ma-sm q-mb-md">
      <q-list separator>
        <q-item
          v-for="(service, index) in services"
          :key="index"
          class="q-pa-md"
        >
          <q-item-section avatar>
            <q-avatar color="primary" size="md" text-color="white">
              {{ service.name.charAt(0) }}
            </q-avatar>
          </q-item-section>
          <q-item-section>
            {{ service.name }}
          </q-item-section>
          <q-item-section side>
            <div>
              <q-btn
                outline
                size="md"
                padding="xs"
                color="custom-orange"
                icon="edit"
                class="q-mr-sm"
                @click="editService(index)"
              />
              <q-btn
                outline
                size="md"
                padding="xs"
                color="custom-red"
                icon="delete"
                @click="deleteDialog(index)"
              />
            </div>
          </q-item-section>
        </q-item>
      </q-list>
    </q-card>

    <q-form v-if="displayCreateNew" @submit="onSubmit">
      <q-card flat bordered class="q-pa-md q-ma-sm q-mb-md">
        <q-input
          v-model="newService"
          outlined
          label="Enter Service Name"
          label-color="primary"
          color="primary"
          lazy-rules
          hide-bottom-space
          :rules="[val => (val && val.trim().length > 0) || '']"
        />
        <div v-if="!addService" class="q-mx-sm text-caption">
          <span class="text-bold">Note:</span> Changing Service name will not
          affect Services already attached to a Destination.
        </div>
        <div class="row q-mt-md">
          <div class="row full-width q-mb-md">
            <q-checkbox
              v-model="priceXInfoBox"
              label="Use Info Box to calculate Service price"
              color="primary"
              size="md"
            />
          </div>
          <div v-if="priceXInfoBox" class="row full-width">
            <q-select
              v-model="infoBoxModel"
              outlined
              :options="infoBoxOptions"
              label="Select Info Box to pair with"
              label-color="primary"
              color="primary"
              class="full-width q-mb-md"
              size="md"
              hide-bottom-space
              :rules="[infoBoxOptionRule]"
            />
            <q-input
              v-model="infoBoxMU"
              outlined
              label="Info Box Measurement Unit (tons, acres, etc)"
              label-color="primary"
              color="primary"
              class="full-width"
              lazy-rules
              hide-bottom-space
              :rules="[val => (val && val.trim().length > 0) || '']"
            />
          </div>
        </div>
      </q-card>

      <div class="q-mt-sm q-mb-xl q-mr-sm" align="right">
        <q-btn
          v-if="addService"
          label="Add"
          icon="add"
          padding="xs md"
          color="primary"
          type="submit"
        />
        <q-btn
          v-if="!addService"
          label="Edit"
          icon="edit"
          padding="xs md"
          color="primary"
          type="submit"
        />
      </div>
    </q-form>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import OkDialog from '@/components/OkDialog.vue';

const db = firebase.firestore();

export default {
  name: 'DestinationServices',
  components: {
    ConfirmDialog,
    OkDialog
  },
  props: {
    services: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      addService: true,
      btn: {
        label: 'Delete',
        icon: 'delete',
        color: 'custom-red'
      },
      confirmDelete: false,
      confirmMessage: '',
      confirmHeaderMessage: '',
      displayCreateNew: false,
      infoBoxModel: '',
      infoBoxMU: '',
      infoBoxOptions: [],
      newService: '',
      priceXInfoBox: false,
      okDialog: false,
      okMessage: '',
      selectedService: null
    };
  },
  mounted() {
    this.setInfoBoxOptions();
  },
  methods: {
    createNew() {
      this.addService = true;
      this.newService = '';
      this.priceXInfoBox = false;
      this.infoBoxModel = '';
      this.infoBoxMU = '';
      this.displayCreateNew = true;
    },
    deleteDialog(index) {
      this.confirmMessage = 'Are you sure you want to delete service?';
      this.confirmHeaderMessage = this.services[index].name;
      this.confirmDelete = true;
      this.selectedService = index;
    },
    deleteService() {
      this.confirmDelete = false;
      const index = this.selectedService;

      const service = {
        name: this.services[index].name,
        defaultPrice: parseFloat(this.services[index].defaultPrice),
        defaultChecked: this.services[index].defaultChecked,
        priceXInfoBox: this.services[index].priceXInfoBox,
        infoBoxPairedWith: this.services[index].infoBoxPairedWith,
        infoBoxMU: this.services[index].infoBoxMU
      };
      const serviceUsers = {
        name: this.services[index].name
      };

      const batch = db.batch();

      const servicesDoc = db.collection('settings').doc('settings');
      const servicesUsersDoc = db.collection('settings_users').doc('settings');

      batch.update(servicesDoc, {
        'destinationSettings.services':
          firebase.firestore.FieldValue.arrayRemove(service)
      });

      batch.update(servicesUsersDoc, {
        'destinationSettings.services':
          firebase.firestore.FieldValue.arrayRemove(serviceUsers)
      });

      batch.commit().catch(err => {
        console.log(err);
      });

      this.notify('Service Deleted', 'custom-red');
    },
    editService(index) {
      this.selectedService = index;
      this.addService = false;

      this.newService = this.services[index].name;
      this.priceXInfoBox = this.services[index].priceXInfoBox;
      this.infoBoxModel = this.services[index].infoBoxPairedWith;
      this.infoBoxMU = this.services[index].infoBoxMU;
      this.displayCreateNew = true;
    },
    infoBoxOptionRule(val) {
      if (val === null || val === '') {
        return '';
      }
    },
    notify(message, color) {
      this.$q.notify({
        color: color,
        textColor: 'white',
        icon: 'home_repair_service',
        message: message
      });
    },
    onActivateService(service) {
      if (this.addService) {
        for (let i = 0; i < this.services.length; i++) {
          if (this.services[i].name === service) {
            this.okMessage = `${service} already exists!`;
            this.okDialog = true;
            return;
          }
        }
      }

      if (!this.priceXInfoBox) {
        this.infoBoxModel = '';
      }

      const services = this.services.slice();
      const servicesUsers = [];
      for (let i = 0; i < services.length; i++) {
        services[i].defaultPrice = parseFloat(services[i].defaultPrice);
        servicesUsers.push(services[i].name);
      }

      this.infoBoxMU = this.infoBoxMU.trim();
      this.infoBoxMU = this.infoBoxMU.replaceAll('.', '');
      this.infoBoxMU = this.infoBoxMU.replace(/(^\w{1})|(\s+\w{1})/g, letter =>
        letter.toUpperCase()
      );

      if (this.addService) {
        const newService = {
          name: service,
          defaultPrice: 0,
          defaultChecked: false,
          priceXInfoBox: this.priceXInfoBox,
          infoBoxPairedWith: this.infoBoxModel,
          infoBoxMU: this.infoBoxMU
        };
        services.push(newService);

        const newServiceUsers = {
          name: service
        };
        servicesUsers.push(newServiceUsers);
      } else {
        services[this.selectedService].name = service;
        services[this.selectedService].priceXInfoBox = this.priceXInfoBox;
        services[this.selectedService].infoBoxPairedWith = this.infoBoxModel;
        services[this.selectedService].infoBoxMU = this.infoBoxMU;

        servicesUsers[this.selectedService] = service;
      }

      const batch = db.batch();

      const serviceDoc = db.collection('settings').doc('settings');
      const serviceUsersDoc = db.collection('settings_users').doc('settings');

      batch.update(serviceDoc, {
        'destinationSettings.services': services
      });
      batch.update(serviceUsersDoc, {
        'destinationSettings.services': servicesUsers
      });

      batch.commit().catch(err => {
        console.log(err);
      });

      this.notify('Service Added', 'primary');
      this.selectService();
    },
    onSubmit() {
      let service = this.newService;
      service = service.trim();
      service = service.replaceAll('.', '');
      service = service.replace(/(^\w{1})|(\s+\w{1})/g, letter =>
        letter.toUpperCase()
      );
      this.onActivateService(service);
    },
    selectService() {
      this.displayCreateNew = false;
    },
    setInfoBoxOptions() {
      const infoBoxes =
        this.$store.state.settingsUsers.destinationSettings.infoBoxes;

      this.infoBoxOptions = [];
      for (let i = 0; i < infoBoxes.length; i++) {
        this.infoBoxOptions.push(infoBoxes[i].name);
      }
    }
  }
};
</script>

<style scoped></style>
