<template>
  <q-page class="flex flex-center">
    <Gmap :isAdmin="isAdmin" :isSuper="isSuper" :isUser="isUser" />
  </q-page>
</template>

<script>
import Gmap from '@/components/maps/Gmap.vue';

export default {
  name: 'MapMain',
  components: {
    Gmap
  },
  created() {
    this.$store.dispatch('setHeaderBtnsDisplay', false);
  },
  computed: {
    isAdmin() {
      return this.$store.state.user.superAdmin || this.$store.state.user.admin;
    },
    isSuper() {
      return this.$store.state.user.superUser;
    },
    isUser() {
      return this.$store.state.user.user || this.$store.state.user.guestUser;
    }
  }
};
</script>

<style scoped></style>
