import { render, staticRenderFns } from "./CreateSReport.vue?vue&type=template&id=1865ce78&scoped=true&"
import script from "./CreateSReport.vue?vue&type=script&lang=js&"
export * from "./CreateSReport.vue?vue&type=script&lang=js&"
import style0 from "./CreateSReport.vue?vue&type=style&index=0&id=1865ce78&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1865ce78",
  null
  
)

export default component.exports
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBar from 'quasar/src/components/bar/QBar.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QStepper from 'quasar/src/components/stepper/QStepper.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QStep from 'quasar/src/components/stepper/QStep.js';
import QStepperNavigation from 'quasar/src/components/stepper/QStepperNavigation.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QLayout,QHeader,QToolbar,QBtn,QSpace,QBar,QPageContainer,QPage,QStepper,QLinearProgress,QStep,QStepperNavigation,QCard,QIcon,QSeparator,QCheckbox,QInput,QPopupProxy,QDate,QRadio});qInstall(component, 'directives', {ClosePopup});
