<template>
  <div class="outer-table-div">
    <LoadingGif v-if="displayLoadingGif" />

    <ConfirmDialog
      v-if="confirm.display"
      :icon="confirm.icon"
      :iconColor="confirm.iconColor"
      :iconStyle="confirm.iconStyle"
      :btn="confirm.btn"
      :message="confirm.message"
      :headerMessage="confirm.headerMessage"
      @canceled="confirm.display = false"
      @confirmed="onReOpen"
    />

    <OkDialog
      v-if="ok.display"
      icon="outbound"
      :iconColor="ok.iconColor"
      :iconStyle="ok.iconStyle"
      :message="ok.message"
      :headerMessage="ok.headerMessage"
      @okConfirmed="ok.display = false"
    />

    <ReportContainer
      v-if="currentDisplay === 'archivedReport'"
      :reportId="reportRaw.id"
      :reportType="reportType"
      :archivedRaw="reportRaw"
      :isArchived="true"
      @onCancel="currentDisplay = 'archivedList'"
    />

    <ArchivedReport
      v-if="currentDisplay === 'archivedRepo'"
      :reportRaw="reportRaw"
      :reportType="reportType"
      @onDisplayAttachments="onDisplayAttachments"
      @onCancel="currentDisplay = 'archivedList'"
    />

    <div v-if="currentDisplay === 'destAttachments'" class="q-pt-xl">
      <DestManageAttachments
        ref="destManageAttachments"
        :location="reportRaw.location"
        :mapView="true"
        @cancel="currentDisplay = 'archivedReport'"
      />
    </div>

    <div v-if="currentDisplay === 'sourceAttachments'" class="q-pt-xl">
      <SourceManageAttachments
        ref="sourceManageAttachments"
        :location="reportRaw.location"
        :mapView="true"
        @cancel="currentDisplay = 'archivedReport'"
      />
    </div>

    <SettlementReports
      v-if="currentDisplay === 'settlementReports'"
      :recent="recent"
      :customer="customer"
      :SRLocation="SRLocation"
      :edit="false"
      :showAll="false"
      @onEditSReport="recent.doNothing"
      @onDeleteSReport="recent.doNothing"
      @onDeleteStart="recent.doNothing"
      @onCancel="currentDisplay = 'archivedList'"
    />

    <div
      v-if="
        currentDisplay === 'archivedList' ||
        currentDisplay === 'archivedReport' ||
        currentDisplay === 'settlementReports'
      "
      class="q-pa-md"
    >
      <div class="row justify-center full-width q-mb-lg">
        <q-card
          flat
          bordered
          :style="$q.screen.lt.sm ? 'width: 100%;' : 'width: 400px;'"
          class="q-pa-lg"
        >
          <div>
            <div class="q-gutter-sm q-mb-sm">
              <div class="text-h5 text-center q-mb-lg">Archived Lookup</div>
            </div>
            <div class="row items-center">
              <q-input
                dense
                v-model="customerLookup"
                label="Customer Lookup"
                color="primary"
                class="full-width"
              >
                <template v-slot:append>
                  <q-btn
                    round
                    dense
                    icon="search"
                    color="primary"
                    @click="lookUpBy('customer')"
                  />
                </template>
              </q-input>
            </div>
            <div class="row justify-end q-py-sm q-mb-lg"></div>

            <div class="row items-center">
              <q-input
                dense
                v-model="sReportNum"
                label="Settlement Report Lookup"
                color="primary"
                class="full-width"
              >
                <template v-slot:append>
                  <q-btn
                    round
                    dense
                    icon="search"
                    color="primary"
                    @click="lookUpBy('sReportNum')"
                  />
                </template>
              </q-input>
            </div>
          </div>
          <div class="row q-mt-xl">
            <q-btn
              outline
              dense
              label="Reset List"
              icon="restart_alt"
              color="primary"
              class="full-width"
              @click="lookUpBy('recent')"
            />
          </div>
        </q-card>
      </div>

      <q-card flat bordered ref="sourceCard" class="q-mb-md">
        <div
          class="row justify-center items-center bg-custom-light q-py-sm text-h6"
        >
          <q-icon
            name="outbound"
            color="custom-green"
            size="sm"
            class="q-mr-sm"
          />
          Archived Sources
        </div>

        <q-linear-progress v-if="sourceRendering" query color="primary" />

        <div
          v-if="!archivedSources.length && !sourceRendering"
          class="row justify-center q-pb-sm"
        >
          <div
            class="row justify-center full-width q-py-lg text-h4 text-grey-5"
          >
            Archived Source list is empty.
          </div>
        </div>

        <div class="column" :style="sourceHeight">
          <div
            v-for="(source, index) in archivedSources"
            :key="index"
            :id="source.id"
          >
            <div :style="`width: ${itemWidth + 204}px;`" class="row q-px-md">
              <div class="row justify-center items-center q-pr-md">
                <q-avatar
                  square
                  color="custom-green"
                  text-color="white"
                  size="sm"
                  class="text-bold"
                >
                  {{ source.customer.charAt(0) }}
                </q-avatar>
              </div>
              <div :style="`width: ${itemWidth}px`">
                <div style="height: 22px; padding-top: 4px" class="ellipsis">
                  {{ source.customer }}
                </div>
                <div
                  style="height: 18px"
                  class="text-caption text-custom-medium no-wrap ellipsis"
                >
                  {{ source.sourceName }}
                </div>
                <div
                  style="height: 20px"
                  class="text-caption text-custom-medium no-wrap ellipsis"
                >
                  {{ source.settlementDateFormatted }}
                </div>
              </div>

              <div
                class="row items-center q-pl-sm"
                style="width: 132px; height: 62px"
              >
                <div class="q-py-sm">
                  <q-btn
                    unelevated
                    icon="calculate"
                    color="custom-artichoke"
                    size="sm"
                    class="q-mx-xs"
                    padding="xs sm"
                    @click="showSReport(source, 'source')"
                  />
                  <q-btn
                    unelevated
                    icon="view_list"
                    color="primary"
                    size="sm"
                    class="q-mx-xs"
                    padding="xs sm"
                    @click="displayDetails(source, 'source')"
                  />
                  <q-btn
                    unelevated
                    icon="unarchive"
                    color="custom-medium"
                    size="sm"
                    class="q-mx-xs"
                    padding="xs sm"
                    @click="onConfirmReOpen(source, 'source')"
                  />
                </div>
              </div>
              <q-separator class="full-width" color="custom-light" />
            </div>
          </div>
        </div>
        <div v-if="showMoreSourcesBtn" class="row justify-center q-py-md">
          <q-btn
            unelevated
            color="custom-green"
            label="More"
            icon="keyboard_arrow_down"
            icon-right="keyboard_arrow_down"
            padding="xs lg"
            style="width: 300px"
            class="q-ml-sm cursor-pointer"
            @click="showMoreSources"
          />
        </div>
        <div
          v-if="!showMoreSourcesBtn && sourceRendering"
          class="row justify-center q-pb-xl q-mb-md"
        >
          <q-linear-progress v-if="sourceRendering" query color="primary" />
        </div>
      </q-card>

      <q-card flat bordered class="q-mb-xl">
        <div
          class="row justify-center items-center bg-custom-light q-py-sm text-h6"
        >
          <q-icon
            name="outbound"
            color="custom-red"
            size="sm"
            class="q-mr-sm"
            style="transform: rotate(180deg)"
          />
          Archived {{ destAs }}
        </div>

        <q-linear-progress v-if="destRendering" query color="primary" />

        <div
          v-if="!archivedDestinations.length && !sourceRendering"
          class="row justify-center q-pb-sm"
        >
          <div
            class="row justify-center full-width q-py-lg text-h4 text-grey-5"
          >
            Archived {{ destAs.slice(0, -1) }} list is empty.
          </div>
        </div>

        <div class="column" :style="destHeight">
          <div
            v-for="(dest, index) in archivedDestinations"
            :key="index"
            :id="dest.id"
          >
            <div :style="`width: ${itemWidth + 204}px;`" class="row q-px-md">
              <div class="row justify-center items-center q-pr-md">
                <q-avatar
                  square
                  color="custom-red"
                  text-color="white"
                  size="sm"
                  class="text-bold"
                >
                  {{ dest.customer.charAt(0) }}
                </q-avatar>
              </div>
              <div :style="`width: ${itemWidth}px`">
                <div style="height: 22px; padding-top: 4px" class="ellipsis">
                  {{ dest.customer }}
                </div>
                <div
                  style="height: 18px"
                  class="text-caption text-custom-medium no-wrap ellipsis"
                >
                  {{ dest.destinationName }}
                </div>
                <div
                  style="height: 20px"
                  class="text-caption text-custom-medium no-wrap ellipsis"
                >
                  {{ dest.settlementDateFormatted }}
                </div>
              </div>

              <div
                class="row items-center q-pl-sm"
                style="width: 132px; height: 62px"
              >
                <div class="q-py-sm">
                  <q-btn
                    unelevated
                    icon="calculate"
                    color="custom-artichoke"
                    size="sm"
                    class="q-mx-xs"
                    padding="xs sm"
                    @click="showSReport(dest, 'destination')"
                  />
                  <q-btn
                    unelevated
                    icon="view_list"
                    color="primary"
                    size="sm"
                    class="q-mx-xs"
                    padding="xs sm"
                    @click="displayDetails(dest, 'destination')"
                  />
                  <q-btn
                    unelevated
                    icon="unarchive"
                    color="custom-medium"
                    size="sm"
                    class="q-mx-xs"
                    padding="xs sm"
                    @click="onConfirmReOpen(dest, 'destination')"
                  />
                </div>
              </div>
              <q-separator class="full-width" color="custom-light" />
            </div>
          </div>
        </div>

        <div
          v-if="!showMoreDestBtn && destRendering"
          class="row justify-center q-pb-xl q-mb-md"
        >
          <q-linear-progress v-if="destRendering" query color="primary" />
        </div>

        <div v-if="showMoreDestBtn" class="row justify-center q-py-md">
          <q-btn
            unelevated
            color="custom-red"
            label="More"
            icon="keyboard_arrow_down"
            icon-right="keyboard_arrow_down"
            padding="xs lg"
            style="width: 300px"
            class="q-ml-sm cursor-pointer"
            @click="showMoreDest"
          />
        </div>
      </q-card>
    </div>
  </div>
</template>

<script>
import firebaseApp from '@/firebase.js';
import {
  doc,
  getFirestore,
  getDoc,
  getDocs,
  collection,
  writeBatch,
  where,
  query,
  orderBy,
  limit
} from 'firebase/firestore';
import { destinationReport, sourceReport } from '@/utils/ReportHelpers';
import SettlementReports from '@/components/settlement/ListSReports.vue';
import ArchivedReport from '@/components/settlement/ArchivedReport.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import OkDialog from '@/components/OkDialog.vue';
import LoadingGif from '@/components/LoadingGif.vue';
import DestManageAttachments from '@/components/destinations/ManageAttachments.vue';
import SourceManageAttachments from '@/components/sources/ManageAttachments.vue';
import ReportContainer from '@/components/ReportContainer.vue';

const db = getFirestore(firebaseApp);

export default {
  name: 'ArchivedDest',
  components: {
    ArchivedReport,
    ConfirmDialog,
    DestManageAttachments,
    LoadingGif,
    OkDialog,
    ReportContainer,
    SettlementReports,
    SourceManageAttachments
  },
  data() {
    return {
      archivedDestinations: [],
      archivedSources: [],
      confirm: {
        display: false,
        icon: '',
        iconColor: '',
        iconStyle: '',
        btn: {
          label: 'Re-Open',
          icon: 'unarchive',
          color: ''
        },
        message: '',
        headerMessage: ''
      },
      currentDisplay: 'archivedList',
      customer: '',
      customerLookup: '',
      dest: [],
      destUsers: [],
      destRendering: true,
      displayLoadingGif: false,
      itemWidth: 200,
      lookUpType: 'recent',
      numDest: 12,
      numSources: 12,
      ok: {
        display: false,
        message: '',
        headerMessage: '',
        iconColor: '',
        iconStyle: ''
      },
      recent: {
        customerSReports: [],
        doNothing() {}
      },
      reOpenType: '',
      showMoreDestBtn: false,
      showMoreSourcesBtn: false,
      sReportNum: '',
      sourceRendering: true,
      SRLocation: ''
    };
  },
  mounted() {
    this.lookUp();

    this.$nextTick(() => {
      window.addEventListener('resize', this.setItemWidth);

      //Init
      this.setItemWidth();
    });
  },
  methods: {
    isDeleted(destRaw) {
      let cust;
      let custDeleted;
      try {
        cust = this.customerList[destRaw.customerId].name;
        custDeleted = false;
      } catch {
        cust = destRaw.customer;
        custDeleted = true;
      }

      let dest;
      let destDeleted;
      try {
        dest =
          this.masterDestinations[destRaw.destinationLocationId]
            .destinationName;
        destDeleted = false;
      } catch {
        dest = destRaw.destinationName;
        destDeleted = true;
      }

      return {
        cust: cust,
        custDeleted: custDeleted,
        dest: dest,
        destDeleted: destDeleted
      };
    },
    cancelAttachments() {
      this.currentDisplay = 'archivedReport';
      this.$emit('setActionArchived');
    },
    displayDetails(data, type) {
      //// Gets passed into ReportHelpers again and this needs to be an array
      //data.haulTrackers = data.haulTrackerArr.slice();
      //data.avatarColor = 'black';
      this.reportRaw = data;
      this.reportType = type;
      this.currentDisplay = 'archivedReport';
    },
    async lookUp() {
      const destArchived = [];
      const destUsersArchived = [];
      const sourcesArchived = [];
      const sourcesUsersArchived = [];

      let destArchivedQ;
      let destUsersArchivedQ;
      let sourcesArchivedQ;
      let sourcesUsersArchivedQ;

      if (this.lookUpType === 'recent') {
        destArchivedQ = query(
          collection(db, 'destinations_archived'),
          orderBy('settlementDate', 'desc'),
          limit(this.numDest)
        );

        destUsersArchivedQ = query(
          collection(db, 'destinations_archived_users'),
          orderBy('settlementDate', 'desc'),
          limit(this.numDest)
        );

        sourcesArchivedQ = query(
          collection(db, 'sources_archived'),
          orderBy('settlementDate', 'desc'),
          limit(this.numSources)
        );

        sourcesUsersArchivedQ = query(
          collection(db, 'sources_archived_users'),
          orderBy('settlementDate', 'desc'),
          limit(this.numSources)
        );
      }

      if (this.lookUpType === 'customer') {
        let customerId = '';
        for (const key in this.customerList) {
          if (
            this.customerList[key].name.toLowerCase() ===
            this.customerLookup.trim().toLowerCase()
          ) {
            customerId = key;
          }
        }
        if (customerId === '') {
          this.setDestinations([], []);
          this.setSources([], []);
          return;
        }

        destArchivedQ = query(
          collection(db, 'destinations_archived'),
          where('customerId', '==', customerId),
          orderBy('settlementDate', 'desc'),
          limit(48)
        );

        destUsersArchivedQ = query(
          collection(db, 'destinations_archived_users'),
          where('customerId', '==', customerId),
          orderBy('settlementDate', 'desc'),
          limit(48)
        );

        sourcesArchivedQ = query(
          collection(db, 'sources_archived'),
          where('customerId', '==', customerId),
          orderBy('settlementDate', 'desc'),
          limit(48)
        );

        sourcesUsersArchivedQ = query(
          collection(db, 'sources_archived_users'),
          where('customerId', '==', customerId),
          orderBy('settlementDate', 'desc'),
          limit(48)
        );
      }

      if (this.lookUpType === 'sReportNum') {
        const reportNum = parseInt(this.sReportNum);
        const sReportIds = [];

        const sReportQ = query(
          collection(db, 'settlement_reports'),
          where('sReportNumber', '==', reportNum),
          limit(10)
        );

        const sReportR = await getDocs(sReportQ);

        if (sReportR.empty) {
          this.setDestinations([], []);
          this.setSources([], []);
          return;
        }

        sReportR.forEach(doc => {
          sReportIds.push(doc.id);
        });

        const destHasSettlementQ = query(
          collection(db, 'destinations_archived'),
          where('settlementReports', 'array-contains-any', sReportIds),
          orderBy('settlementDate', 'desc')
        );

        const sourceHasSettlementQ = query(
          collection(db, 'sources_archived'),
          where('settlementReports', 'array-contains-any', sReportIds),
          orderBy('settlementDate', 'desc')
        );

        const destHasSettlementR = await getDocs(destHasSettlementQ);
        const sourceHasSettlementR = await getDocs(sourceHasSettlementQ);

        const destIds = [];
        const sourceIds = [];

        destHasSettlementR.forEach(doc => {
          destIds.push(doc.data().id);
        });
        sourceHasSettlementR.forEach(doc => {
          sourceIds.push(doc.data().id);
        });

        // Array cannot be empty for query - x will never return result
        if (!destIds.length) {
          destIds.push('x');
        }

        if (!sourceIds.length) {
          sourceIds.push('x');
        }

        destArchivedQ = query(
          collection(db, 'destinations_archived'),
          where('id', 'in', destIds)
        );

        destUsersArchivedQ = query(
          collection(db, 'destinations_archived_users'),
          where('id', 'in', destIds)
        );

        sourcesArchivedQ = query(
          collection(db, 'sources_archived'),
          where('id', 'in', sourceIds)
        );

        sourcesUsersArchivedQ = query(
          collection(db, 'sources_archived_users'),
          where('id', 'in', sourceIds)
        );
      }

      let [
        destArchivedR,
        destUsersArchivedR,
        sourcesArchivedR,
        sourcesUsersArchivedR
      ] = await Promise.all([
        getDocs(destArchivedQ),
        getDocs(destUsersArchivedQ),
        getDocs(sourcesArchivedQ),
        getDocs(sourcesUsersArchivedQ)
      ]);

      destArchivedR.forEach(doc => {
        destArchived.push(doc.data());
      });

      destUsersArchivedR.forEach(doc => {
        destUsersArchived.push(doc.data());
      });

      sourcesArchivedR.forEach(doc => {
        sourcesArchived.push(doc.data());
      });

      sourcesUsersArchivedR.forEach(doc => {
        sourcesUsersArchived.push(doc.data());
      });

      this.setDestinations(destArchived, destUsersArchived);
      this.setSources(sourcesArchived, sourcesUsersArchived);
    },
    lookUpBy(lookUpType) {
      if (lookUpType === 'recent') {
        this.numSources = 12;
        this.numDest = 12;
        this.customerLookup = '';
        this.sReportNum = '';
      } else {
        this.showMoreDestBtn = false;
        this.showMoreSourcesBtn = false;
      }

      this.lookUpType = lookUpType;
      this.lookUp();
    },
    onConfirmReOpen(data, type) {
      if (type === 'destination') {
        this.dest = data.destOriginal;
        this.destUsers = data.destUsersOriginal;
        this.reOpenType = type;
        this.confirm.iconColor = 'custom-red';
        this.confirm.iconStyle = 'transform: rotate(180deg)';
        this.confirm.btn.color = 'custom-medium';
        this.confirm.message = `Re-Open ${data.destinationName}?`;
        this.confirm.headerMessage = data.customer;
      } else if (type === 'source') {
        this.source = data.sourceOriginal;
        this.sourceUsers = data.sourceUsersOriginal;
        this.reOpenType = type;
        this.confirm.iconColor = 'custom-green';
        this.confirm.iconStyle = '';
        this.confirm.btn.color = 'custom-medium';
        this.confirm.message = `Re-Open ${data.sourceName}?`;
        this.confirm.headerMessage = data.customer;
      }
      this.confirm.display = true;
      this.confirm.icon = 'outbound';
    },
    onDisplayAttachments() {
      if (this.reportType === 'destination') {
        this.reportRaw.location.id =
          this.reportRaw.destOriginal.destinationLocationId;
        this.currentDisplay = 'destAttachments';
      } else if (this.reportType === 'source') {
        this.reportRaw.location.id =
          this.reportRaw.sourceOriginal.sourceLocationId;
        this.currentDisplay = 'sourceAttachments';
      }

      const headerBtns = [
        {
          action: this.cancelAttachments,
          args: '',
          label: 'Done',
          color: 'primary',
          flat: false
        }
      ];

      this.$store.dispatch('setHeaderBtns', headerBtns);
    },
    async onReOpen() {
      this.confirm.display = false;

      if (this.reOpenType === 'destination') {
        const dest = this.dest;
        const destUsers = this.destUsers;
        const activeDestinations = JSON.parse(
          JSON.stringify(this.$store.state.activeDestinations)
        );

        for (let i = 0; i < activeDestinations.length; i++) {
          if (
            activeDestinations[i].destinationLocationId ===
            this.dest.destinationLocationId
          ) {
            this.ok.message = `${dest.destinationName} is already open. Cannot open two instances.`;
            this.ok.headerMessage = this.customerList[dest.customerId].name;
            this.ok.iconColor = 'custom-red';
            this.ok.iconStyle = 'transform: rotate(180deg)';
            this.ok.display = true;
            return;
          }
        }

        this.displayLoadingGif = true;
        const settlementDate = null;

        const destination = {
          id: dest.id,
          customerId: dest.customerId,
          updateId: dest.updateId,
          destinationLocationId: dest.destinationLocationId,
          startDate: destUsers.startDate,
          endDate: destUsers.endDate,
          settlementDate: settlementDate,
          chargeCommodity: dest.chargeCommodity,
          pricePerUnit: dest.pricePerUnit,
          mileageRate: dest.mileageRate,
          services: dest.services,
          haulTrackers: dest.haulTrackers,
          adminNote: dest.adminNote,
          settlementReports: dest.settlementReports,
          infoBoxes: dest.infoBoxes,
          customSelectors: dest.customSelectors,
          destinationStatus: dest.destinationStatus
        };

        const destinationUsers = {
          id: destUsers.id,
          customerId: destUsers.customerId,
          updateId: dest.updateId,
          destinationLocationId: destUsers.destinationLocationId,
          startDate: destUsers.startDate,
          endDate: destUsers.endDate,
          commodity: destUsers.commodity,
          projected: destUsers.projected,
          trackMileage: destUsers.trackMileage,
          services: destUsers.services,
          haulTrackers: destUsers.haulTrackers,
          note: destUsers.note,
          hauls: destUsers.hauls,
          settlementDate: settlementDate,
          userInfoBoxes: destUsers.userInfoBoxes,
          userCustomSelectors: destUsers.userCustomSelectors,
          destinationStatus: dest.destinationStatus,
          allowGuestUsers: destUsers.allowGuestUsers,
          guestUsers: destUsers.guestUsers,
          driverRate: destUsers.driverRate,
          alwaysAvailable: destUsers.alwaysAvailable,
          importantNote: destUsers.importantNote,
          dispatchedTo: destUsers.dispatchedTo
        };

        const batch = writeBatch(db);

        const destinationsRef = doc(db, 'destinations_active', dest.id);
        const destinationsUsersRef = doc(
          db,
          'destinations_active_users',
          destUsers.id
        );
        const destinationsArchivedQ = query(
          collection(db, 'destinations_archived'),
          where('settlementDate', '==', dest.settlementDate)
        );
        const destinationsArchivedUsersQ = query(
          collection(db, 'destinations_archived_users'),
          where('settlementDate', '==', dest.settlementDate)
        );

        const destArchivedDocs = await getDocs(destinationsArchivedQ);
        destArchivedDocs.forEach(doc => {
          batch.delete(doc.ref);
        });

        const destArchivedUsersDocs = await getDocs(destinationsArchivedUsersQ);
        destArchivedUsersDocs.forEach(doc => {
          batch.delete(doc.ref);
        });

        batch.set(destinationsRef, destination);
        batch.set(destinationsUsersRef, destinationUsers);

        await batch.commit();

        this.displayLoadingGif = false;

        this.$q.notify({
          color: 'primary',
          textColor: 'white',
          message: this.dest.destinationName + ' Re-Opened'
        });

        this.lookUp();
      } else if (this.reOpenType === 'source') {
        const source = this.source;
        const sourceUsers = this.sourceUsers;
        const activeSources = JSON.parse(
          JSON.stringify(this.$store.state.activeSources)
        );

        for (let i = 0; i < activeSources.length; i++) {
          if (
            activeSources[i].sourceLocationId === this.source.sourceLocationId
          ) {
            this.confirm.display = false;
            this.ok.message = `${this.source.sourceName} is already open. Cannot open two instances of a source.`;
            this.ok.headerMessage = this.customerList[source.customerId].name;
            this.ok.iconColor = 'custom-green';
            this.ok.iconStyle = '';
            this.ok.display = true;
            return;
          }
        }

        this.displayLoadingGif = true;
        const settlementDate = null;

        const sourceArchive = {
          id: source.id,
          customerId: source.customerId,
          updateId: source.updateId,
          sourceLocationId: source.sourceLocationId,
          startDate: sourceUsers.startDate,
          endDate: sourceUsers.endDate,
          settlementDate: settlementDate,
          purchaseCommodity: source.purchaseCommodity,
          pricePerUnit: source.pricePerUnit,
          mileageRate: source.mileageRate,
          services: source.services,
          haulTrackers: source.haulTrackers,
          adminNote: source.adminNote,
          settlementReports: source.settlementReports,
          infoBoxes: source.infoBoxes,
          customSelectors: source.customSelectors,
          sourceStatus: source.sourceStatus
        };

        const sourceArchiveUsers = {
          id: sourceUsers.id,
          customerId: sourceUsers.customerId,
          updateId: source.updateId,
          sourceLocationId: sourceUsers.sourceLocationId,
          startDate: sourceUsers.startDate,
          endDate: sourceUsers.endDate,
          commodity: sourceUsers.commodity,
          settlementDate: settlementDate,
          trackMileage: sourceUsers.trackMileage,
          alwaysAvailable: sourceUsers.alwaysAvailable,
          trackRunningBalance: sourceUsers.trackRunningBalance,
          runningBalance: sourceUsers.runningBalance,
          services: sourceUsers.services,
          haulTrackers: sourceUsers.haulTrackers,
          note: sourceUsers.note,
          hauls: sourceUsers.hauls,
          userInfoBoxes: sourceUsers.userInfoBoxes,
          userCustomSelectors: sourceUsers.userCustomSelectors,
          sourceStatus: source.sourceStatus,
          projected: sourceUsers.projected,
          allowGuestUsers: sourceUsers.allowGuestUsers,
          guestUsers: sourceUsers.guestUsers,
          importantNote: sourceUsers.importantNote,
          dispatchedTo: sourceUsers.dispatchedTo
        };

        const batch = writeBatch(db);

        const sourcesRef = doc(db, 'sources_active', source.id);
        const sourcesUsersRef = doc(db, 'sources_active_users', sourceUsers.id);
        const sourcesArchivedQ = query(
          collection(db, 'sources_archived'),
          where('settlementDate', '==', source.settlementDate)
        );
        const sourcesArchivedUsersQ = query(
          collection(db, 'sources_archived_users'),
          where('settlementDate', '==', source.settlementDate)
        );

        const sourceArchivedDocs = await getDocs(sourcesArchivedQ);
        sourceArchivedDocs.forEach(doc => {
          batch.delete(doc.ref);
        });

        const sourceArchivedUsersDocs = await getDocs(sourcesArchivedUsersQ);
        sourceArchivedUsersDocs.forEach(doc => {
          batch.delete(doc.ref);
        });

        batch.set(sourcesRef, sourceArchive);
        batch.set(sourcesUsersRef, sourceArchiveUsers);

        await batch.commit();

        this.displayLoadingGif = false;

        this.$q.notify({
          color: 'primary',
          textColor: 'white',
          icon: 'outbound',
          message: this.source.sourceName + ' Re-Opened'
        });

        this.lookUp();
      }
    },
    setDestinations(archivedDestinations, archivedDestinationsUsers) {
      const destinations = [];

      const parseDestinations = (dest, destUsers) => {
        for (let i = 0; i < destUsers.length; i++) {
          // reject merge of dest & destUsers if..
          if (
            typeof dest[i] === 'undefined' ||
            typeof destUsers[i] === 'undefined'
          ) {
            return;
          }

          if (
            dest[i].updateId !== destUsers[i].updateId ||
            dest[i].id !== destUsers[i].id
          ) {
            return;
          }

          const destOriginal = JSON.parse(JSON.stringify(dest[i]));
          const destUsersOriginal = JSON.parse(JSON.stringify(destUsers[i]));

          const destInfo = destinationReport(
            dest[i].id,
            true,
            dest[i],
            destUsers[i]
          );

          destinations.push({
            ...destInfo,
            ...{ destOriginal: destOriginal },
            ...{ destUsersOriginal: destUsersOriginal }
          });
        }
      };

      parseDestinations(archivedDestinations, archivedDestinationsUsers);

      if (destinations.length > 12) {
        this.archivedDestinations = destinations.slice();
        setTimeout(() => {
          this.$nextTick(() => {
            this.destRendering = false;

            if (destinations.length === this.numDest) {
              this.showMoreDestBtn = true;
            }
          });
        }, 300);
      } else {
        this.archivedDestinations = destinations.slice();
        this.destRendering = false;

        if (destinations.length === this.numDest) {
          this.showMoreDestBtn = true;
        }
      }
    },
    setItemWidth() {
      const cardWidth = this.$refs.sourceCard.$el.clientWidth;

      let cols = 3;
      if (this.$q.screen.lt.sm) {
        cols = 1;
      } else if (this.$q.screen.lt.lg) {
        cols = 2;
      }

      // 214 is fixed amount (padding, buttons, etc)
      this.itemWidth = cardWidth / cols - 204;
    },
    setSources(archivedSources, archivedSourcesUsers) {
      const sourcesArr = [];

      const parseSources = (sources, sourcesUsers) => {
        for (let i = 0; i < sourcesUsers.length; i++) {
          // Reject merge of source & sourceUsers if
          if (
            typeof sources[i] === 'undefined' ||
            typeof sourcesUsers[i] === 'undefined'
          ) {
            return [];
          }

          if (
            sources[i].updateId !== sourcesUsers[i].updateId ||
            sources[i].id !== sourcesUsers[i].id
          ) {
            return [];
          }

          const sourceOriginal = JSON.parse(JSON.stringify(sources[i]));
          const sourceUsersOriginal = JSON.parse(
            JSON.stringify(sourcesUsers[i])
          );

          const sourceInfo = sourceReport(
            sources[i].id,
            true,
            sources[i],
            sourcesUsers[i]
          );

          sourcesArr.push({
            ...sourceInfo,
            ...{ sourceOriginal: sourceOriginal },
            ...{ sourceUsersOriginal: sourceUsersOriginal }
          });
        }
      };

      parseSources(archivedSources, archivedSourcesUsers);

      if (sourcesArr.length > 12) {
        this.archivedSources = sourcesArr.slice();
        setTimeout(() => {
          this.$nextTick(() => {
            this.sourceRendering = false;

            if (sourcesArr.length === this.numSources) {
              this.showMoreSourcesBtn = true;
            }
          });
        }, 300);
      } else {
        this.archivedSources = sourcesArr.slice();
        this.sourceRendering = false;

        if (sourcesArr.length === this.numSources) {
          this.showMoreSourcesBtn = true;
        }
      }
    },
    showMoreDest() {
      this.showMoreDestBtn = false;
      this.lookUpType = 'recent';
      this.destRendering = true;
      this.numDest += 12;
      this.lookUp();
    },
    showMoreSources() {
      this.showMoreSourcesBtn = false;
      this.lookUpType = 'recent';
      this.sourceRendering = true;
      this.numSources += 12;
      this.lookUp();
    },
    async showSReport(data, type) {
      const sortByNumber = (a, b) => {
        // if customer or destination is deleted
        if (a.sReportNumber > b.sReportNumber) {
          return -1;
        }
        if (a.sReportNumber < b.sReportNumber) {
          return 1;
        }
        return 0;
      };

      if (type === 'destination') {
        const sReportIds = data.settlementReports;
        const sReports = [];

        // Retrieve all saved invoices
        for (let i = 0; i < sReportIds.length; i++) {
          const id = sReportIds[i];
          const docSnap = await getDoc(doc(db, 'settlement_reports', id));
          if (docSnap.exists()) {
            sReports.push(docSnap.data());
          }
        }

        sReports.sort(sortByNumber);

        this.recent.customerSReports = sReports;
        this.customer = this.isDeleted(data).cust;
        this.SRLocation = data.destinationName;
        this.currentDisplay = 'settlementReports';
      } else if (type === 'source') {
        const sReportIds = data.settlementReports;
        const sReports = [];

        // Retrieve all saved invoices
        for (let i = 0; i < sReportIds.length; i++) {
          const id = sReportIds[i];
          const docSnap = await getDoc(doc(db, 'settlement_reports', id));
          if (docSnap.exists()) {
            sReports.push(docSnap.data());
          }
        }

        sReports.sort(sortByNumber);

        this.recent.customerSReports = sReports;
        this.customer = this.isDeleted(data).cust;
        this.SRLocation = data.sourceName;
        this.currentDisplay = 'settlementReports';
      }
    },
    resetSearch() {
      this.$emit('onResetSearch');
    }
  },
  computed: {
    customerList() {
      return JSON.parse(JSON.stringify(this.$store.state.customers));
    },
    destAs() {
      return this.$store.state.settingsUsers.destAs;
    },
    destHeight() {
      const destLength = this.archivedDestinations.length;
      if (this.$q.screen.lt.sm) {
        return `height: ${destLength * 63 + 5}px`;
      } else if (this.$q.screen.lt.lg) {
        const addOne = destLength % 2 > 0 ? 63 : 0;
        return `height: ${Math.floor(destLength / 2) * 63 + addOne + 5}px`;
      } else {
        const addOne = destLength % 3 > 0 ? 63 : 0;
        return `height: ${Math.floor(destLength / 3) * 63 + addOne + 5}px`;
      }
    },
    masterDestinations() {
      return JSON.parse(JSON.stringify(this.$store.state.masterDestinations));
    },
    masterSources() {
      return JSON.parse(JSON.stringify(this.$store.state.masterSources));
    },
    sourceHeight() {
      const sourceLength = this.archivedSources.length;

      if (this.$q.screen.lt.sm) {
        return `height: ${sourceLength * 63 + 5}px`;
      } else if (this.$q.screen.lt.lg) {
        const addOne = sourceLength % 2 > 0 ? 63 : 0;
        return `height: ${Math.floor(sourceLength / 2) * 63 + addOne + 5}px`;
      } else {
        const addOne = sourceLength % 3 > 0 ? 63 : 0;
        return `height: ${Math.floor(sourceLength / 3) * 63 + addOne + 5}px`;
      }
    },
    sourceInputs() {
      return this.$store.state.filterLists.sourceInputs;
    },
    userList() {
      return JSON.parse(JSON.stringify(this.$store.state.userList));
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setItemWidth);
  }
};
</script>

<style scoped></style>
