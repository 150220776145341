<template>
  <div class="flex flex-center">
    <CustomerList
      v-if="currentDisplay === 'add'"
      :customers="customerList"
      @onSelectCustomer="onSelectCustomer"
    />

    <EditSource
      v-if="currentDisplay === 'editSource'"
      ref="editSource"
      :source="source"
      :settings="settings"
      @onSubmit="setAction('list')"
      @onAddSubmit="addSource"
      @layerTwoLocations="setAction('layerTwoLocations')"
      @layerTwoAttachments="setAction('layerTwoAttachments')"
      @layerThreeAttachments="setAction('layerThreeAttachments')"
      @setLocation="setAction('editSource')"
      @fetchEstimates="fetchEstimates"
    />

    <FilterData
      v-if="currentDisplay === 'filter'"
      ref="filter"
      :sourceInputs="sourceInputs"
      :sourceSortBy="sourceSortBy"
      :sourceStatusFilter="sourceStatusFilter"
      :sourceInputSelected="sourceInputSelected"
      @onSubmit="filterSubmitted"
      @fetchEstimates="fetchEstimates"
    />

    <SourceSettings
      v-if="currentDisplay === 'settings'"
      ref="settings"
      :settings="settings"
      @onManageProgrammables="onManageProgrammables"
      @onSubmit="setAction('list')"
    />

    <SourceTable
      v-show="currentDisplay === 'list'"
      ref="sourceTable"
      key="2323424sourceTable"
      :sourceInputs="sourceInputs"
      :sourceSortBy="sourceSortBy"
      :sourceStatusFilter="sourceStatusFilter"
      :sourceInputSelected="sourceInputSelected"
      :settings="settings"
      :estimates="estimates"
      :loader="loader"
      @onEdit="onEdit"
    />
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
const db = firebase.firestore();

import CustomerList from '@/components/CustomerList.vue';
import EditSource from '@/components/sources/EditSource.vue';
import SourceSettings from '@/components/sources/SourceSettings.vue';
import SourceTable from '@/components/sources/SourceTable.vue';
import FilterData from '@/components/sources/FilterData.vue';

export default {
  name: 'Sources-Main',
  components: {
    CustomerList,
    EditSource,
    FilterData,
    SourceSettings,
    SourceTable
  },
  data() {
    return {
      addMode: false,
      activeSources: [],
      currentDisplay: 'list',
      customerList: [],
      estimates: {
        estimates: [],
        userEstimates: []
      },
      loader: false,
      snapshotListener: () => {},
      source: {}
    };
  },
  created() {
    this.$store.dispatch('setHeaderBtnsDisplay', true);

    this.setFilter();
    this.setCustomers();
    this.setAction('list');
  },
  methods: {
    addSource(source) {
      this.$refs.sourceTable.addId(source.id);
      this.setAction('list');
    },
    cancelLayerThreeAttachment() {
      this.$refs.editSource.cancelLayerThreeAttachment();
      this.setAction('layerTwoLocations');
    },
    cancelLayerTwoInputs() {
      this.$refs.editSource.cancelLayerTwoInputs();

      if (!this.source.hauls) {
        this.setAction('editSource');
        return;
      }

      if (this.source.hauls.length) {
        this.setAction('editSource');
      } else {
        this.setAction('editSourceDelete');
      }
    },
    cancelProgrammables() {
      this.$refs.settings.cancelProgrammables();
      this.setAction('settings');
    },
    deleteSource() {
      this.$refs.sourceTable.setUpdaterAction('delete');
      this.$refs.editSource.confirmAction('delete');
    },
    fetchEstimates(status) {
      if (!this.estimates.estimates.length && status === 'estimate') {
        const wrapperFunc = async () => {
          this.loader = true;
          const estimates = [];
          const getEstimates = () => {
            const sourceEstQuery = db
              .collection('sources_active')
              .where('sourceStatus', 'in', ['Later', 'Estimate']);

            const sourceEst = sourceEstQuery.get().then(querySnapshot => {
              querySnapshot.forEach(doc => {
                estimates.push(doc.data());
              });
              return true;
            });
            return sourceEst;
          };

          const promiseOne = getEstimates();

          const userEstimates = [];
          const getUserEstimates = () => {
            const sourceEstQueryUsers = db
              .collection('sources_active_users')
              .where('sourceStatus', 'in', ['Later', 'Estimate']);

            const sourceEstUsers = sourceEstQueryUsers
              .get()
              .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                  userEstimates.push(doc.data());
                });
                return true;
              });
            return sourceEstUsers;
          };

          const promiseTwo = getUserEstimates();

          await promiseOne;
          await promiseTwo;

          this.estimates = {
            estimates,
            userEstimates
          };
          this.loader = false;
        };

        this.snapshotListener = db
          .collection('sources_active')
          .where('sourceStatus', 'in', ['Later', 'Estimate'])
          .onSnapshot(() => {
            wrapperFunc();
          });
      }

      const dataObj = {
        type: 'sourceInputs',
        inputs: this.sourceInputs,
        sortBy: this.sourceSortBy,
        inputSelected: this.sourceInputSelected,
        status
      };

      this.$store.dispatch('setUserData', dataObj);
    },
    filterSubmitted() {
      this.$refs.sourceTable.setSources();
      this.setAction('list');
    },
    onEdit(source) {
      source.action = 'edit';
      this.source = source;

      if (source.hauls.length) {
        this.setAction('editSource');
      } else {
        this.setAction('editSourceDelete');
      }
    },
    onManageProgrammables() {
      this.setAction('programmables');
    },
    onSelectCustomer(customer) {
      this.source = {};
      this.source.customer = customer.name;
      this.source.customerId = customer.id;
      this.source.customerNote = customer.note;
      this.source.action = 'add';

      this.setAction('addSource');
    },
    setAction(action) {
      if (action === 'list') {
        this.addMode = false;

        this.currentDisplay = 'list';

        const headerBtns = [
          {
            action: this.setAction,
            args: 'add',
            label: 'Add',
            color: 'primary',
            flat: true
          },
          {
            action: this.setAction,
            args: 'settings',
            label: 'Settings',
            color: 'primary',
            flat: true
          },
          {
            action: this.setAction,
            args: 'filter',
            label: 'Filter',
            color: 'primary',
            flat: false
          }
        ];

        this.$store.dispatch('setHeaderBtns', headerBtns);
      } else if (action === 'editSource') {
        this.currentDisplay = 'editSource';
        this.$nextTick(() => {
          const headerBtns = [
            {
              action: this.$refs.editSource.cancel,
              args: '',
              label: 'Cancel',
              color: 'primary',
              flat: true
            }
          ];

          if (!this.addMode) {
            headerBtns.push({
              action: this.$refs.editSource.completed,
              args: '',
              label: 'Completed',
              color: 'teal-9',
              flat: true
            });
          }

          headerBtns.push({
            action: this.$refs.editSource.onVerifyFirst,
            args: '',
            label: 'Submit',
            color: 'primary',
            flat: false
          });

          this.$store.dispatch('setHeaderBtns', headerBtns);
        });
      } else if (action === 'addSource') {
        this.addMode = true;
        this.setAction('editSource');
      } else if (action === 'editSourceDelete') {
        this.addMode = false;
        this.currentDisplay = 'editSource';
        this.$nextTick(() => {
          const headerBtns = [
            {
              action: this.$refs.editSource.cancel,
              args: '',
              label: 'Cancel',
              color: 'primary',
              flat: true
            },
            {
              action: this.deleteSource,
              args: '',
              label: 'Delete',
              color: 'custom-red',
              flat: true
            },
            {
              action: this.$refs.editSource.onVerifyFirst,
              args: '',
              label: 'Submit',
              color: 'primary',
              flat: false
            }
          ];

          this.$store.dispatch('setHeaderBtns', headerBtns);
        });
      } else if (action === 'add') {
        this.source.action = 'add';
        this.$refs.sourceTable.setUpdaterAction('add');
        this.addMode = true;

        const headerBtns = [
          {
            action: this.setAction,
            args: 'list',
            label: 'Cancel',
            color: 'primary',
            flat: false
          }
        ];

        this.$store.dispatch('setHeaderBtns', headerBtns);
        this.currentDisplay = 'add';
      } else if (action === 'filter') {
        this.currentDisplay = 'filter';
        this.$nextTick(() => {
          const headerBtns = [
            {
              action: this.setAction,
              args: 'list',
              label: 'Cancel',
              color: 'primary',
              flat: true
            },
            {
              action: this.$refs.filter.submitFilter,
              args: '',
              label: 'Sort & Filter',
              color: 'primary',
              flat: false
            }
          ];

          this.$store.dispatch('setHeaderBtns', headerBtns);
        });
      } else if (action === 'settings') {
        this.currentDisplay = 'settings';
        this.$nextTick(() => {
          const headerBtns = [
            {
              action: this.setAction,
              args: 'list',
              label: 'Cancel',
              color: 'primary',
              flat: true
            },
            {
              action: this.$refs.settings.editSettings,
              args: '',
              label: 'Submit',
              color: 'primary',
              flat: false
            }
          ];

          this.$store.dispatch('setHeaderBtns', headerBtns);
        });
      } else if (action === 'programmables') {
        const headerBtns = [
          {
            action: this.cancelProgrammables,
            args: '',
            label: 'Done',
            color: 'primary',
            flat: false
          }
        ];

        this.$store.dispatch('setHeaderBtns', headerBtns);
      } else if (action === 'layerTwoLocations') {
        this.$nextTick(() => {
          const headerBtns = [
            {
              action: this.cancelLayerTwoInputs,
              args: '',
              label: 'Cancel',
              color: 'primary',
              flat: false
            }
          ];

          this.$store.dispatch('setHeaderBtns', headerBtns);
        });
      } else if (action === 'layerTwoAttachments') {
        const headerBtns = [
          {
            action: this.cancelLayerTwoInputs,
            args: '',
            label: 'Done',
            color: 'primary',
            flat: false
          }
        ];

        this.$store.dispatch('setHeaderBtns', headerBtns);
      } else if (action === 'layerThreeAttachments') {
        const headerBtns = [
          {
            action: this.cancelLayerThreeAttachment,
            args: '',
            label: 'Done',
            color: 'primary',
            flat: false
          }
        ];

        this.$store.dispatch('setHeaderBtns', headerBtns);
      }
    },
    setCustomers() {
      const customers = JSON.parse(JSON.stringify(this.$store.state.customers));
      const colors = JSON.parse(JSON.stringify(this.$store.state.colors));

      this.customerList = [];
      const sortByCustomer = (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      };

      for (const custKey in customers) {
        const customer = customers[custKey];
        customer.id = custKey;

        if (customer.isActive) {
          this.customerList.push(customer);
        }
      }

      this.customerList.sort(sortByCustomer);

      let colorIndex = 0;
      for (let i = 0; i < this.customerList.length; i++) {
        this.customerList[i].color = colors[colorIndex];

        colorIndex++;
        if (colorIndex === 7) {
          colorIndex = 0;
        }
      }
    },
    setFilter() {
      // Should not be set to estimates on mount
      if (this.sourceStatusFilter !== 'openWaiting') {
        const dataObj = {
          type: 'sourceInputs',
          inputs: this.sourceInputs,
          sortBy: this.sourceSortBy,
          inputSelected: this.sourceInputSelected,
          status: 'openWaiting'
        };

        this.$store.dispatch('setUserData', dataObj);
      }
    }
  },
  computed: {
    settings() {
      return this.$store.state.sourceSettings;
    },
    sourceInputs() {
      return this.$store.state.filterLists.sourceInputs;
    },
    sourceInputSelected() {
      return this.$store.state.filterLists.sourceInputSelected;
    },
    sourceSortBy() {
      return this.$store.state.filterLists.sourceSortBy;
    },
    sourceStatusFilter() {
      return this.$store.state.filterLists.sourceStatusFilter;
    }
  },
  watch: {
    '$store.state.customers'() {
      this.setCustomers();
    },
    currentDisplay(newVal, oldVal) {
      if (newVal === 'list') {
        if (oldVal === 'editSource') {
          this.$refs.sourceTable.highlightSource();
        }
      }
    }
  },
  beforeDestroy() {
    this.snapshotListener();
  }
};
</script>

<style scoped></style>
