<template>
  <div class="outer-table-div">
    <div class="row justify-center items-center full-width q-mt-lg">
      <div class="customer-row">
        <q-card flat bordered>
          <div class="row full-width q-pa-md">
            <q-space />
            <div class="q-pa-xs">
              <q-input
                v-model="searchStr"
                outlined
                dense
                style="width: 300px"
                @input="searchByStr"
              >
                <template v-slot:append>
                  <q-icon name="search" />
                </template>
              </q-input>
            </div>
          </div>
          <q-separator />

          <div
            v-if="customerListFiltered.length < 1"
            class="row justify-center items-center full-width"
          >
            <div
              class="text-grey-4 row justify-center col-12"
              style="font-size: 50px; margin-top: 50px"
            >
              ( ^ _ ^ )
            </div>
            <div
              class="text-center text-grey-5 q-pa-lg"
              style="font-size: 30px; margin-top: 20px; margin-bottom: 50px"
            >
              No customers match your search
            </div>
          </div>

          <div
            v-if="customerList.length < 1"
            class="row justify-center items-center full-width"
          >
            <div
              class="text-grey-4 row justify-center col-12"
              style="font-size: 50px; margin-top: 50px"
            >
              ( ^ _ ^ )
            </div>
            <div
              class="text-center text-grey-5 q-pa-lg"
              style="font-size: 30px; margin-top: 20px; margin-bottom: 50px"
            >
              No customers to display
            </div>
          </div>

          <q-virtual-scroll
            class="q-pb-lg"
            :style="containerHeight"
            :items="customerListFiltered"
            separator
          >
            <template v-slot="{ item, index }">
              <q-item
                :key="index"
                dense
                clickable
                v-ripple
                @click="editCustomer(index)"
              >
                <q-item-section avatar>
                  <q-avatar
                    :color="item.color"
                    size="sm"
                    text-color="white text-bold"
                  >
                    {{ item.name.charAt(0) }}
                  </q-avatar>
                </q-item-section>

                <q-item-section>
                  {{ item.name }}
                </q-item-section>

                <q-item-section side>
                  <q-spinner v-if="item.spinner" color="primary" size="2em" />
                </q-item-section>

                <q-item-section side>
                  <q-icon name="edit" color="grey-5" class="edit-icon">
                  </q-icon>
                </q-item-section>
              </q-item>
            </template>
          </q-virtual-scroll>
        </q-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomersDisplay',
  props: {
    customerList: {
      type: Array,
      required: true
    },
    customerListFiltered: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      containerHeight: 'max-height: 300px',
      searchStr: ''
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.setContainerHeight();
  },
  methods: {
    async setContainerHeight() {
      await this.$nextTick();

      let height =
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 200;

      this.containerHeight = `max-height: ${height}px`;
    },
    editCustomer(index) {
      this.$emit('editCustomer', index);
    },
    searchByStr() {
      this.$emit('onSearchByStr', this.searchStr);
    },
    setCustomers() {
      this.searchStr = '';
      this.$emit('onSetCustomers');
    }
  }
};
</script>

<style scoped>
.customer-row {
  width: 470px;
}

.edit-icon {
  font-size: 1.5em;
}

@media all and (max-width: 1405px) {
  .customer-row {
    width: 370px;
  }
}

@media all and (max-width: 410px) {
  .customer-row {
    width: 97%;
  }
}
</style>
