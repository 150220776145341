<template>
  <q-dialog persistent v-model="displayInsights">
    <q-layout
      view="Lhh lpr fff"
      container
      :style="contentHeight"
      class="bg-white outer"
    >
      <q-header class="bg-grey-3">
        <q-toolbar class="q-pl-lg q-py-md">
          <q-icon name="insights" size="md" color="primary" />
          <div class="q-ml-md text-h5 text-primary">Insights</div>
          <q-space />
          <q-btn
            flat
            round
            icon="clear"
            color="primary"
            class="q-mr-sm"
            @click="cancel"
          />
        </q-toolbar>
      </q-header>

      <div id="insights" class="q-ma-lg q-mb-lg" style="margin-top: 120px">
        <q-card v-if="insights.show" flat bordered class="q-my-xl">
          <div
            class="row justify-center items-center text-subtitle1 text-custom-light q-py-xs q-px-sm"
            style="
              background: radial-gradient(circle, #3336eb 0%, #0b0d6f 100%);
            "
          >
            <q-icon name="date_range" size="sm" />
            <span class="q-ml-md">
              {{ insights.startDate }} - {{ insights.endDate }}
            </span>
          </div>
          <q-separator />

          <div v-if="isSuperAdmin" class="q-mb-md">
            <div class="row items-center bg-grey-1 q-py-sm q-px-sm">
              <span class="text-body1 text-bold text-black"> All Users </span>
            </div>
            <q-separator class="q-mb-md" />
            <div
              v-for="workType in insights.superInsights.workTypes"
              :key="workType.index"
              class="row items-center"
            >
              <div class="full-width">
                <q-separator v-if="workType.index !== 0" />
                <div class="row q-px-sm q-my-xs q-py-xs">
                  <span class="text-body1 text-custom-medium">
                    {{ workType.name }}:
                  </span>
                  <q-space />
                  <span class="text-body1 text-bold q-ml-sm">
                    {{ workType.time }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <q-separator v-if="isSuperAdmin" class="q-mt-md" />
          <div class="row items-center bg-grey-1 q-py-sm q-px-sm">
            <span class="text-body1 text-bold text-black">
              {{ user.name }}
            </span>
          </div>

          <q-separator class="q-mb-md" />
          <div class="row items-center q-my-xs q-py-xs q-px-sm">
            <span class="text-body1 text-custom-medium"> Total: </span>
            <q-space />
            <span class="text-body1 text-bold q-ml-sm">
              {{ insights.totalHours }}
            </span>
          </div>

          <q-separator />
          <div class="row items-center q-my-xs q-py-xs q-px-sm">
            <span class="text-body1 text-custom-medium"> Total Overtime: </span>
            <q-space />
            <span class="text-body1 text-bold q-ml-sm">
              {{ insights.totalOvertime }}
            </span>
          </div>

          <q-separator v-if="calcNetOvertime" />
          <div
            v-if="calcNetOvertime"
            class="row items-center q-my-xs q-py-xs q-px-sm"
          >
            <span class="text-body1 text-custom-medium"> Net Overtime: </span>
            <q-space />
            <span class="text-body1 text-bold q-ml-sm">
              {{ insights.netOvertime }}
            </span>
          </div>

          <div
            v-for="workType in insights.workTypes"
            :key="workType.name"
            class="row items-center"
          >
            <div class="full-width">
              <q-separator />
              <div class="row q-px-sm q-my-xs q-py-xs">
                <span class="text-body1 text-custom-medium">
                  {{ workType.name }}:
                </span>
                <q-space />
                <span class="text-body1 text-bold q-ml-sm">
                  {{ workType.time }}
                </span>
              </div>
            </div>
          </div>
        </q-card>
      </div>
    </q-layout>
  </q-dialog>
</template>

<script>
export default {
  name: 'TimeClockInsights',
  props: {
    insights: {
      type: Object,
      required: true
    },
    isSuperAdmin: {
      type: Boolean,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      contentHeight: 'height: 600px;',
      displayInsights: true,
      workTypesArr: []
    };
  },
  mounted() {
    this.calcContentHeight();
  },
  methods: {
    cancel() {
      this.$emit('onCancel');
    },
    calcContentHeight() {
      this.$nextTick(() => {
        const insightsHeight = document.getElementById('insights').clientHeight;
        this.contentHeight = `height: ${insightsHeight + 170}px`;
      });
    }
  },
  computed: {
    calcNetOvertime() {
      return this.$store.state.settingsUsers.calcNetOvertime;
    }
  }
};
</script>

<style scoped>
.outer {
  min-width: 540px;
  max-width: 600px;
}

@media all and (max-width: 540px) {
  .outer {
    min-width: 310px;
    max-width: 500px;
  }
}

@media all and (max-width: 350px) {
  .outer {
    min-width: 310px;
    max-width: 340px;
  }
}
</style>
