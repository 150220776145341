<template>
  <q-dialog
    persistent
    v-model="showLoader"
    class="row items-center justify-center"
  >
    <q-spinner-gears color="primary" size="250px" />
  </q-dialog>
</template>

<script>
export default {
  name: 'LoadingGif',
  data() {
    return {
      showLoader: true
    };
  }
};
</script>

<style scoped></style>
