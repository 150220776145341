<template>
  <q-dialog persistent v-model="displayWTSettings">
    <q-layout
      view="Lhh lpr fff"
      container
      :style="wTHeight"
      class="bg-white outer"
    >
      <q-header class="bg-grey-3">
        <q-toolbar class="q-pl-lg q-py-md">
          <q-icon name="handyman" size="md" color="primary" />
          <div class="q-ml-md text-h5 text-primary">Work Types</div>
          <q-space />
          <q-btn
            flat
            round
            icon="clear"
            color="primary"
            class="q-mr-sm"
            @click="cancel"
          />
        </q-toolbar>
      </q-header>
      <div class="q-ma-lg" style="margin-top: 150px">
        <q-input
          v-for="(option, index) in workTypesArr"
          :key="index"
          v-model="option.workType"
          filled
          label="Enter Work Type"
          label-color="primary"
          color="primary"
          lazy-rules
          hide-bottom-space
          class="q-mt-lg"
          :rules="[
            val => (val && val.trim().length > 0) || 'Please enter a work Type'
          ]"
        >
          <template v-slot:append>
            <q-icon
              name="clear"
              color="custom-red"
              class="cursor-pointer"
              @click="workTypesArr.splice(index, 1)"
            >
            </q-icon>
          </template>
        </q-input>
        <div class="row q-mt-xl">
          <q-space />
          <q-btn
            flat
            label="Add"
            icon="add"
            color="primary"
            class="q-mr-sm"
            padding="xs md"
            style="font-size: 14px"
            @click="workTypesArr.push({ workType: '' })"
          />
          <q-btn
            label="Submit"
            icon="check"
            color="primary"
            padding="xs md"
            style="font-size: 14px"
            @click="submitWorkTypes"
          />
        </div>
      </div>
    </q-layout>
  </q-dialog>
</template>

<script>
import firebase from 'firebase/compat/app';

const db = firebase.firestore();

export default {
  name: 'WorkTypeSettings',
  props: {
    workTypes: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      displayWTSettings: true,
      workTypesArr: [],
      wTHeight: 'height: 600px;'
    };
  },
  mounted() {
    this.setWorkTypes();
    this.setHeight();
  },
  methods: {
    cancel() {
      this.$emit('onCancel');
    },
    setHeight() {
      const height = window.innerHeight - 300;
      this.wTHeight = `height: ${height}px;`;
    },
    setWorkTypes() {
      for (let i = 0; i < this.workTypes.length; i++) {
        const workType = this.workTypes[i];

        this.workTypesArr.push({ workType: workType });
      }

      if (this.workTypes.length < 1) {
        this.workTypesArr.push({ workType: '' });
      }
    },
    submitWorkTypes() {
      const settingsDoc = db.collection('settings_users').doc('settings');

      const workTypes = [];
      for (let i = 0; i < this.workTypesArr.length; i++) {
        let wt = this.workTypesArr[i].workType;

        wt = wt.trim();
        wt = wt.replaceAll('.', '');
        wt = wt.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());

        workTypes.push(wt);
      }

      const workTypesObj = {
        workTypes: workTypes
      };

      const batch = db.batch();

      batch.update(settingsDoc, workTypesObj);

      batch.commit().catch(err => {
        console.log(err);
      });

      this.$emit('onCancel');
    }
  }
};
</script>

<style scoped></style>
