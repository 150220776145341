<template>
  <div class="div-container q-mt-xl">
    <div class="gt-sm q-py-xl"></div>

    <div
      class="row items-center justify-center rounded-borders text-custom-light q-ma-sm q-mb-md"
      style="background: radial-gradient(circle, #3336eb 0%, #0b0d6f 100%)"
    >
      <q-avatar icon="account_circle" font-size="35px" />
      <span class="text-body1 text-bold q-ml-xs">
        {{ displayName }}
      </span>
    </div>

    <q-form @submit="onAddUser">
      <q-card flat bordered class="q-mx-sm q-mb-md">
        <div class="q-ma-md">
          <q-input
            square
            filled
            clearable
            v-model="displayName"
            label="New User Display Name"
            label-color="primary"
            color="primary"
            maxlength="80"
            hide-bottom-space
            lazy-rules
            :rules="[
              val =>
                (val && val.trim().length > 0) || 'Please Enter Display Name'
            ]"
          />
        </div>

        <div class="q-ma-md">
          <q-input
            square
            filled
            clearable
            v-model="email"
            label="New User Email"
            label-color="primary"
            color="primary"
            maxlength="80"
            hide-bottom-space
            lazy-rules
            :rules="[checkEmail]"
          />
          <div
            v-if="invalidEmail"
            class="text-custom-red"
            style="margin-left: 12px"
          >
            Please Enter A Valid Email Address
          </div>
        </div>

        <div class="q-ma-md">
          <q-input
            square
            filled
            clearable
            v-model="pw.password"
            label="Password"
            label-color="primary"
            color="primary"
            maxlength="80"
            type="password"
            hide-bottom-space
            lazy-rules
            :rules="[checkPassword]"
          />
        </div>

        <div class="q-ma-md">
          <q-input
            square
            filled
            clearable
            v-model="pw.verifyPassword"
            label="Verify Password"
            label-color="primary"
            color="primary"
            maxlength="80"
            type="password"
            hide-bottom-space
            lazy-rules
            :rules="[verifyPasswordIsCorrect]"
          />
        </div>

        <div class="q-ml-md q-pa-none">
          <p class="text-grey-7 q-pa-none">
            <span v-if="pw.pwMissmatch" class="text-custom-red">
              Passwords do not match
            </span>
            <br v-if="pw.pwMissmatch" />
            <span :class="pw.pwLengthClass">
              Password must be at least 8 Characters
            </span>
            <br />
            <span :class="pw.pwLowercaseClass">
              Password must contain at least one Lowercase letter
            </span>
            <br />
            <span :class="pw.pwUppercaseClass">
              Password must contain at least one Uppercase letter
            </span>
            <br />
            <span :class="pw.pwNumberClass">
              Password must contain at least one Number
            </span>
          </p>
        </div>
      </q-card>
      <div v-if="submitBtn" align="right" class="q-mr-sm">
        <q-btn
          color="primary"
          label="Add User"
          class="q-ml-md"
          padding="xs md"
          type="submit"
        />
      </div>
    </q-form>
  </div>
</template>

<script>
export default {
  name: 'AddUser',
  data() {
    return {
      customerSelected: '',
      displayName: '',
      email: '',
      invalidEmail: false,
      pw: {
        password: '',
        pwLengthClass: 'text-grey-6',
        pwLowercaseClass: 'text-grey-6',
        pwUppercaseClass: 'text-grey-6',
        pwNumberClass: 'text-grey-6',
        pwMissmatch: false,
        verifyPassword: ''
      },
      submitBtn: true
    };
  },
  methods: {
    onAddUser() {
      const userObj = {
        displayName: this.displayName,
        email: this.email,
        password: this.pw.password
      };

      this.submitBtn = false;
      this.$emit('addUser', userObj);
    },
    checkEmail(email) {
      if (email) {
        if (email.trim() === '') {
          return 'Please Enter Email';
        } else if (!this.validateEmail(email)) {
          return 'Please Enter A Valid Email Address';
        }
      }
    },
    checkPassword(pw) {
      let pwIsValid = true;

      if (pw.length < 8) {
        this.pw.pwLengthClass = 'text-custom-red';
        pwIsValid = false;
      } else {
        this.pw.pwLengthClass = 'text-grey-6';
      }

      if (pw.search(/[a-z]/) < 0) {
        this.pw.pwLowercaseClass = 'text-custom-red';
        pwIsValid = false;
      } else {
        this.pw.pwLowercaseClass = 'text-grey-6';
      }

      if (pw.search(/[A-Z]/) < 0) {
        this.pw.pwUppercaseClass = 'text-custom-red';
        pwIsValid = false;
      } else {
        this.pw.pwUppercaseClass = 'text-grey-6';
      }

      if (pw.search(/[0-9]/) < 0) {
        this.pw.pwNumberClass = 'text-custom-red';
        pwIsValid = false;
      } else {
        this.pw.pwNumberClass = 'text-grey-6';
      }

      return pwIsValid;
    },
    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    verifyPasswordIsCorrect(pw) {
      if (pw !== this.pw.password) {
        this.pw.pwMissmatch = true;
        return false;
      } else {
        this.pw.pwMissmatch = false;
      }

      return true;
    }
  },
  computed: {
    customerList() {
      return this.$store.state.customers;
    },
    customers() {
      const customerList = this.$store.state.customers;
      const customers = [];

      for (const key in customerList) {
        customers.push(customerList[key].name);
      }

      customers.sort();

      return customers;
    },
    guestCustomers() {
      return this.$store.state.guestCustomerList.guestCustomers;
    }
  }
};
</script>

<style scoped></style>
