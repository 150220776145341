<template>
  <div class="row justify-center" id="outer-report-container">
    <SelectCommodity
      v-if="displayCommoditySelection"
      :commodities="commodities"
      @onSelectCommodity="onSelectCommodity"
      @onCancel="displayCommoditySelection = false"
    />

    <SortCharts
      v-if="displaySortCharts"
      :chartOrder="chartOrder"
      @onSortCharts="onSortCharts"
      @onCancel="displaySortCharts = false"
    />

    <DateRange
      v-if="displayDateRange"
      :datesProvided="datesProvided"
      :dates="dates"
      :defaultDates="defaultDates"
      :defaultDatesHeader="'Default Report Dates'"
      @onSelectDates="lookup"
      @onCancel="displayDateRange = false"
    />

    <PrintInsights
      v-if="print"
      id="print"
      :driver="driver"
      :destAs="destAs"
      :headerDateStart="headerDateStart"
      :headerDateEnd="headerDateEnd"
      :driverInsightsSettings="driverInsightsSettings"
      :customers="customers"
    />

    <q-layout view="lhh LpR lff" container style="height: calc(100vh - 32px)">
      <q-header :reveal="false" class="bg-grey-3">
        <q-toolbar>
          <q-btn
            square
            unelevated
            size="md"
            icon="date_range"
            text-color="black"
            color="custom-orange"
            padding="sm"
            class="q-ml-xs"
            @click="selectDates"
          />
          <div class="row text-body1 text-black text-bold q-ml-md">
            {{ headerDateStart }} -
            {{ headerDateEnd }}
          </div>
          <q-space />

          <div
            class="gt-xs text-h6 text-black text-bold text-no-wrap ellipsis q-mx-sm"
          >
            <div v-if="!displayDriverTables" class="ellipsis">
              <span
                v-for="(commodity, index) in commoditySelectedArr"
                :key="randomNum + index"
              >
                {{ commodity }}
                <span v-if="index !== commoditySelectedArr.length - 1">
                  -
                </span>
              </span>
              Report
            </div>
            <div v-if="displayDriverTables" class="ellipsis">
              Driver Tables - All Commodities
            </div>
          </div>
          <q-space />

          <!--Center Report Type Title-->
          <div
            v-if="displayDriverTables"
            class="gt-sm"
            style="padding-left: 200px"
          ></div>
          <div
            v-if="!displayDriverTables && !(driverIsAuthorized || isAdmin)"
            class="gt-sm"
            style="padding-left: 200px"
          ></div>
          <div
            v-if="!displayDriverTables && (driverIsAuthorized || isAdmin)"
            class="gt-sm"
            style="padding-left: 170px"
          ></div>
          <!--Center Report Type Title-->

          <q-btn
            v-if="!displayDriverTables"
            square
            unelevated
            size="md"
            color="custom-red"
            icon="grain"
            text-color="black"
            padding="sm"
            class="gt-xs q-mr-xs"
            @click="displayCommoditySelection = true"
          />
          <q-btn
            v-if="!displayDriverTables && (driverIsAuthorized || isAdmin)"
            unelevated
            size="md"
            color="custom-light-blue"
            icon="table_chart"
            text-color="black"
            padding="sm"
            class="gt-xs q-ml-xs q-mr-xs"
            @click="(displayDriverTables = true), calcReports()"
          />

          <q-btn
            v-if="displayDriverTables"
            unelevated
            size="md"
            color="custom-light-blue"
            icon="trending_up"
            text-color="black"
            padding="sm"
            class="gt-xs q-mr-xs"
            @click="(displayDriverTables = false), calcReports()"
          />
        </q-toolbar>

        <q-toolbar class="lt-sm">
          <div class="text-h6 text-black text-bold text-no-wrap ellipsis">
            <div class="ellipsis">
              <span
                v-for="(commodity, index) in commoditySelectedArr"
                :key="randomNum + index"
              >
                {{ commodity }}
                <span v-if="index !== commoditySelectedArr.length - 1">
                  -
                </span>
              </span>
              Report
            </div>
          </div>
          <q-space />

          <q-btn
            v-if="!displayDriverTables"
            square
            unelevated
            size="md"
            color="custom-red"
            icon="grain"
            text-color="black"
            padding="sm"
            class="q-mr-sm"
            @click="displayCommoditySelection = true"
          />
          <q-btn
            v-if="!displayDriverTables"
            unelevated
            size="md"
            color="custom-light-blue"
            icon="table_chart"
            text-color="black"
            padding="sm"
            class="q-mr-xs"
            @click="(displayDriverTables = true), calcReports()"
          />

          <q-btn
            v-if="displayDriverTables"
            unelevated
            size="md"
            color="custom-light-blue"
            icon="trending_up"
            text-color="black"
            padding="sm"
            class="q-mr-xs"
            @click="(displayDriverTables = false), calcReports()"
          />
        </q-toolbar>
      </q-header>

      <q-page-container>
        <q-page class="q-pb-xl">
          <q-linear-progress v-if="!reportsCalculated" query color="primary" />

          <div
            v-if="noCommodityErr"
            class="row justify-center q-pt-md text-custom-red text-bold text-subtitle1"
          >
            Select Commodity To Create Reports
          </div>

          <div
            v-if="reportsCalculated && !displayDriverTables"
            class="row full-width q-pa-md q-pb-xl"
          >
            <div v-if="$q.screen.xs" class="half-width half-width-mr">
              <div
                v-for="(report, index) in reportListSorted"
                :key="randomNum + index"
              >
                <q-card
                  v-if="!report[0].options.halfWidth"
                  class="q-px-md q-pb-md q-pt-xs q-mb-md"
                >
                  <Chart
                    :chartData="report[0]"
                    :chartId="report[0].options.chartID"
                  />

                  <div
                    v-if="report[0].options.showTotal"
                    align="right"
                    class="text-bold q-mt-sm"
                  >
                    Total:
                    {{ totalHauled.toLocaleString('en-US') }}
                    <span> {{ measurementUnit }}</span>
                  </div>

                  <div
                    v-if="report[0].options.showTotalArr"
                    class="row items-center q-mt-sm"
                  >
                    <q-space />
                    <div class="text-bold">Totals:</div>
                    <div
                      v-for="(service, index) in report[0].options
                        .serviceTotalsArr"
                      :key="service.name + index"
                      class="row items-center q-pl-md text-caption text-grey-8"
                    >
                      <div
                        style="height: 12px; width: 40px"
                        :style="`background-color: ${service.color};`"
                      ></div>
                      <div style="padding: 1px 0 0 5px">
                        {{ service.info }}
                      </div>
                    </div>
                  </div>
                </q-card>

                <div v-if="report[0].options.halfWidth" class="row">
                  <div class="q-mb-md half-width half-width-mr">
                    <q-card
                      class="q-px-md q-pb-md q-pt-xs"
                      style="height: 320px"
                    >
                      <Chart
                        :chartData="report[0]"
                        :chartId="report[0].options.chartID"
                      />
                    </q-card>
                  </div>

                  <div class="q-mb-md half-width">
                    <q-card
                      class="q-px-md q-pb-md q-pt-xs"
                      style="height: 320px"
                    >
                      <Chart
                        :chartData="report[1]"
                        :chartId="report[1].options.chartID"
                      />
                    </q-card>
                  </div>
                </div>
              </div>
              <div class="full-width q-pt-sm" align="right">
                <q-btn
                  label="Sort Charts"
                  icon="sort"
                  color="frappe-orange"
                  text-color="black"
                  padding="sm lg"
                  @click="displaySortCharts = true"
                />
              </div>
            </div>

            <div v-if="!$q.screen.xs" class="row full-width">
              <div
                v-for="(report, i) in reportListSplit"
                :key="randomNum + i"
                class="half-width"
                :class="i === 0 && 'half-width-mr'"
              >
                <div
                  v-for="(report, j) in reportListSplit[i]"
                  :key="randomNum + j"
                >
                  <q-card
                    v-if="!report[0].options.halfWidth"
                    class="q-px-md q-pb-md q-pt-xs q-mb-md"
                  >
                    <Chart
                      :chartData="report[0]"
                      :chartId="report[0].options.chartID"
                    />

                    <div
                      v-if="report[0].options.showTotal"
                      align="right"
                      class="text-bold q-mt-sm"
                    >
                      Total:
                      {{ totalHauled.toLocaleString('en-US') }}
                      <span> {{ measurementUnit }}</span>
                    </div>

                    <div
                      v-if="report[0].options.showTotalArr"
                      class="row items-center q-mt-sm"
                    >
                      <q-space />
                      <div class="text-bold">Totals:</div>
                      <div
                        v-for="(service, index) in report[0].options
                          .serviceTotalsArr"
                        :key="service.name + index"
                        class="row items-center q-pl-md text-caption text-grey-8"
                      >
                        <div
                          style="height: 12px; width: 40px"
                          :style="`background-color: ${service.color};`"
                        ></div>
                        <div style="padding: 1px 0 0 5px">
                          {{ service.info }}
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="report[0].options.showGrandTotal"
                      align="right"
                      class="text-bold q-mt-sm"
                    >
                      Grand Total:
                      <span class="q-pl-sm">
                        {{ report[0].options.grandTotal }}
                      </span>
                    </div>
                  </q-card>

                  <!--show driver values only to admins-->
                  <div v-if="report[0].options.halfWidth" class="row">
                    <div
                      class="q-mb-md"
                      :class="
                        isAdmin ? 'half-width half-width-mr' : 'full-width'
                      "
                    >
                      <q-card
                        class="q-px-md q-pb-md q-pt-xs"
                        style="height: 320px"
                      >
                        <Chart
                          :chartData="report[0]"
                          :chartId="report[0].options.chartID"
                        />
                      </q-card>
                    </div>

                    <div v-if="isAdmin" class="q-mb-md half-width">
                      <q-card
                        class="q-px-md q-pb-md q-pt-xs"
                        style="height: 320px"
                      >
                        <Chart
                          :chartData="report[1]"
                          :chartId="report[1].options.chartID"
                        />
                      </q-card>
                    </div>
                  </div>
                </div>
                <div v-if="i === 1" class="full-width q-pt-sm" align="right">
                  <q-btn
                    label="Sort Charts"
                    icon="sort"
                    color="frappe-orange"
                    text-color="black"
                    padding="sm lg"
                    @click="displaySortCharts = true"
                  />
                </div>
              </div>
            </div>
          </div>

          <!--Start Driver Tables-->
          <div
            v-if="displayDriverTables && driverIsAuthorized"
            class="row full-width justify-center q-px-sm q-pb-lg"
          >
            <div id="driver-tables-main" class="row justify-center full-width">
              <q-card
                class="row justify-center q-pa-md q-my-md"
                :class="$q.screen.gt.xs ? 'custom-width' : 'full-width'"
              >
                <div>
                  <div
                    class="row full-width justify-center text-bold text-body1 text-primary q-mb-sm"
                  >
                    Driver Table Settings
                  </div>
                  <div class="row full-width q-pb-xs">
                    <q-checkbox
                      v-model="driverInsightsSettings.calcAsGross"
                      dense
                      label="Calculate Using Gross Haul Amount (if applicable)"
                      color="primary"
                      @input="setReportOptions(reportType)"
                    />
                  </div>
                  <div class="row full-width q-pb-xs">
                    <q-checkbox
                      dense
                      v-model="driverInsightsSettings.convertToTons"
                      label="Convert Gross To Tons (if applicable)"
                      color="primary"
                      @input="setReportOptions(reportType)"
                    />
                  </div>
                  <div class="row full-width">
                    <q-checkbox
                      dense
                      v-model="driverInsightsSettings.convertToBushels"
                      label="Convert Gross To Bushels (if applicable)"
                      color="primary"
                      @input="setReportOptions(reportType)"
                    />
                  </div>
                </div>
              </q-card>
            </div>

            <div class="row justify-center full-width">
              <q-card
                v-if="isAdmin"
                class="row justify-center q-pa-md q-my-md"
                :class="$q.screen.gt.xs ? 'custom-width' : 'full-width'"
              >
                <div>
                  <div
                    class="row full-width justify-center text-bold text-body1 text-custom-orange q-mb-sm q-pr-sm"
                  >
                    Drivers Authorized To View Driver Tables
                  </div>
                  <div class="row full-width q-pb-xs">
                    <q-option-group
                      :options="driverList"
                      dense
                      type="checkbox"
                      color="custom-orange"
                      v-model="driverAuthList"
                      @input="driverAuthInput"
                    />
                  </div>
                </div>
                <div v-if="driverAuthChanged" class="full-width" align="right">
                  <q-btn
                    label="Set Authorized Drivers"
                    no-caps
                    color="custom-orange"
                    text-color="black"
                    padding="1px lg"
                    @click="setDriverAuth"
                  />
                </div>
              </q-card>
            </div>

            <div
              v-if="!driversAuth.length"
              class="text-h5 text-primary q-mt-xl"
            >
              No Hauls for
              {{ headerDateStart }} -
              {{ headerDateEnd }}
            </div>

            <q-card
              v-for="(driver, index) in driversAuth"
              :key="driver.driver + index"
              class="row full-width q-my-md q-pa-md"
            >
              <DriverTable
                :driver="driver"
                :destAs="destAs"
                :headerDateStart="headerDateStart"
                :headerDateEnd="headerDateEnd"
                :driverInsightsSettings="driverInsightsSettings"
                :customers="customers"
              />
              <div class="full-width q-pt-sm" align="right">
                <q-btn
                  label="Print"
                  icon="print"
                  color="custom-light-blue"
                  text-color="black"
                  padding="sm lg"
                  @click="printInsights(driver)"
                />
              </div>
            </q-card>
          </div>
          <!--End Driver Tables-->
        </q-page>
      </q-page-container>
    </q-layout>
  </div>
</template>

<script>
import SelectCommodity from '@/components/reports/SelectCommodity.vue';
import SortCharts from '@/components/reports/SortCharts.vue';
import Chart from '@/components/reports/Chartjs.vue';
import DriverTable from '@/components/reports/DriverTables.vue';
import DateRange from '@/components/DateRange.vue';
import PrintInsights from '@/components/reports/PrintInsights.vue';
import { round2Dec } from '@/utils/UtilHelpers.js';

import firebase from 'firebase/compat/app';
import { date } from 'quasar';
import { Printd } from 'printd';

// firebase modular!!
import { doc, updateDoc } from 'firebase/firestore';

const db = firebase.firestore();

export default {
  name: 'Reports-Main',
  components: {
    Chart,
    DateRange,
    DriverTable,
    PrintInsights,
    SelectCommodity,
    SortCharts
  },
  data() {
    return {
      archivedDestinationsUsers: [],
      archivedDestinations: [],
      archivedSources: [],
      archivedSourcesUsers: [],
      barStyle: {
        right: '0px',
        borderRadius: '3px',
        backgroundColor: '#bdbdbd',
        width: '3px',
        opacity: 0.2
      },
      chartOrder: [],
      commoditySelectedArr: [],
      currentlyBooked: null,
      dates: {
        start: null,
        end: null
      },
      datesProvided: true,
      defaultDates: 'two',
      //destHaulTotals: [],
      destinationData: null,
      destinationValReport: null,
      displayDateRange: false,
      displayDriverTables: false,
      displayCommoditySelection: false,
      displaySortCharts: false,
      driver: {},
      driverAuthList: [],
      driverAuthChanged: false,
      driverData: null,
      driverInsightsSettings: {
        calcAsGross: false,
        convertToTons: false,
        convertToBushels: false
      },
      driverIsAuthorized: false,
      driverList: [],
      driverReports: [],
      drivers: [],
      driversAuth: [],
      drivervalData: null,
      haulData: null,
      haulDestData: null,
      haulSourceData: null,
      haulTrackerData: null,
      haulValData: null,
      haulValueReport: null,
      labels: [],
      noCommodityErr: false,
      //noData: true,
      print: false,
      reportsCalculated: false,
      reportType: 'Overview',
      reportList: [],
      reportListSorted: [],
      reportListSplit: [],
      serviceData: null,
      serviceValData: null,
      serviceReports: [],
      //serviceTotals: [],
      sourceData: null,
      thumbStyle: {
        right: '0px',
        borderRadius: '3px',
        backgroundColor: '#9e9e9e',
        width: '3px',
        opacity: 0.75
      },
      totalHauled: 0
    };
  },
  created() {
    if (localStorage.reportOptions) {
      const reportOptions = JSON.parse(localStorage.getItem('reportOptions'));
      this.reportType = reportOptions.reportType;
      this.driverInsightsSettings = reportOptions.driverInsightsSettings;
      this.defaultDates = reportOptions.defaultDates;
    } else {
      localStorage.reportOptions = JSON.stringify({
        reportType: this.reportType,
        driverInsightsSettings: this.driverInsightsSettings,
        defaultDates: 'two'
      });
    }

    this.setDriverAuthList();
    this.setCommodity();
  },
  mounted() {
    window.scrollTo(1, 0);

    this.$store.dispatch('setHeaderBtnsDisplay', false);
  },
  methods: {
    calcReports() {
      if (!this.userList) {
        return;
      }

      this.reportsCalculated = false;
      const reportList = [];

      const haulList = this.haulList();
      // Add startTS of next day after end of date range to compare against
      const numDays = date.getDateDiff(this.dates.end, this.dates.start) + 1;

      const timeChunks = [];
      const labels = [];
      if (numDays - 1 > 365) {
        timeChunks.push(this.dates.start);

        const daysInYear = year => {
          return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0
            ? 366
            : 365;
        };

        let addDays = parseInt(date.formatDate(this.dates.start, 'YYYY'));
        addDays = daysInYear(addDays);
        const dayOfYear = date.getDayOfYear(new Date(this.dates.start));
        const nextYearStartTs = parseInt(
          date.formatDate(
            date.addToDate(this.dates.start, { days: addDays - dayOfYear + 1 }),
            'x'
          )
        );

        timeChunks.push(nextYearStartTs);

        let startTs = nextYearStartTs;
        addDays = 0;
        for (let i = 0; i < numDays; i++) {
          addDays += daysInYear(parseInt(date.formatDate(startTs, 'YYYY')));

          startTs = parseInt(
            date.formatDate(
              date.addToDate(nextYearStartTs, { days: addDays }),
              'x'
            )
          );

          if (startTs < this.dates.end) {
            timeChunks.push(startTs);
          } else {
            timeChunks.push(this.dates.end);
            break;
          }
        }

        for (let i = 0; i < timeChunks.length - 1; i++) {
          const label = date.formatDate(timeChunks[i], 'YYYY');
          labels.push(label);
        }
      } else if (numDays - 1 > 42) {
        const daysInMo = date.daysInMonth(new Date(this.dates.start));
        const dayOfMo = parseInt(date.formatDate(this.dates.start, 'D'));
        timeChunks.push(this.dates.start);

        const nextMoStartTs = parseInt(
          date.formatDate(
            date.addToDate(this.dates.start, { days: daysInMo - dayOfMo + 1 }),
            'x'
          )
        );

        timeChunks.push(nextMoStartTs);

        let startTs = nextMoStartTs;
        let addDays = 0;
        for (let i = 0; i < numDays; i++) {
          addDays += parseInt(date.daysInMonth(new Date(startTs)));

          startTs = parseInt(
            date.formatDate(
              date.addToDate(nextMoStartTs, { days: addDays }),
              'x'
            )
          );

          if (startTs < this.dates.end) {
            timeChunks.push(startTs);
          } else {
            timeChunks.push(this.dates.end);
            break;
          }
        }

        for (let i = 0; i < timeChunks.length - 1; i++) {
          const label = date.formatDate(timeChunks[i], 'MMM');
          labels.push(label);
        }
      } else if (numDays - 1 >= 21) {
        let dayOfWeek = date.getDayOfWeek(this.dates.start);

        // If Sunday
        dayOfWeek = dayOfWeek === 7 ? 0 : dayOfWeek;
        const numToEnd = 7 - dayOfWeek;

        timeChunks.push(this.dates.start);

        const nextWeekStartTs = parseInt(
          date.formatDate(
            date.addToDate(this.dates.start, { days: numToEnd }),
            'x'
          )
        );

        timeChunks.push(nextWeekStartTs);

        for (let i = 0; i < numDays; i++) {
          const addDays = (i + 1) * 7;
          const startTs = parseInt(
            date.formatDate(
              date.addToDate(nextWeekStartTs, { days: addDays }),
              'x'
            )
          );

          if (startTs < this.dates.end) {
            timeChunks.push(startTs);
          } else {
            timeChunks.push(this.dates.end);
            break;
          }
        }

        for (let i = 0; i < timeChunks.length - 1; i++) {
          const startLabel = date.formatDate(timeChunks[i], 'M/DD');
          const endLabel = date.formatDate(
            date.subtractFromDate(timeChunks[i + 1], { days: 1 }),
            'M/DD'
          );
          labels.push(startLabel + '-' + endLabel);
        }
      } else {
        for (let i = 0; i < numDays; i++) {
          let startTs = date.addToDate(this.dates.start, {
            days: i
          });

          startTs = parseInt(date.formatDate(startTs, 'x'));
          const day = date.formatDate(startTs, 'ddd');
          labels.push(day);
          timeChunks.push(startTs);
        }
        labels.pop();
      }

      const colors = [
        '#0b0d6f', // blue
        '#fe9e20', // orange
        '#2176ff', // light-blue
        '#cc0003', // red
        '#8b9474', // artichoke
        '#564e58', // medium
        '#057632', // green
        '#22181c', // dark-grey
        '#f7f052', // yellow
        '#dbe0e6' // light-grey
      ];
      for (let i = 0; i < 20; i++) {
        for (let j = 0; j < 10; j++) {
          colors.push(colors[j]);
        }
      }

      const newDataSet = new Array(timeChunks.length - 1).fill(0);

      let totalHauled = 0;
      let numHaulTrackers = 0;

      const haulTrackers = [];
      const drivers = [];

      const destinations = [];
      const destAmount = [];
      const destColors = [];

      const sources = [];
      const sourceAmount = [];
      const sourceColors = [];

      const destPrices = [];
      const destHaulPrices = [];
      let haulSourceDataSets = [];
      let haulDestDataSets = [];

      const amountPerTimeChunk = newDataSet.slice();
      const destCommodityPrices = newDataSet.slice();
      const sourceCommodityPrices = newDataSet.slice();
      const commodityProfitPrices = newDataSet.slice();

      const haulDataSet = {
        backgroundColor: colors[3],
        label: this.measurementUnit,
        data: newDataSet.slice()
      };

      for (let i = 0; i < haulList.length; i++) {
        const haul = haulList[i];

        for (let j = 0; j < timeChunks.length - 1; j++) {
          const startTs = timeChunks[j];
          const endTs = timeChunks[j + 1];
          if (haul.dateTime >= startTs && haul.dateTime < endTs) {
            amountPerTimeChunk[j] += haul.haulAmount;

            destCommodityPrices[j] += haul.haulAmount * haul.destCommodityPrice;
            sourceCommodityPrices[j] +=
              haul.haulAmount * haul.sourceCommodityPrice;
            commodityProfitPrices[j] +=
              haul.haulAmount * haul.destCommodityPrice -
              haul.haulAmount * haul.sourceCommodityPrice;

            haulDataSet.data[j] += haul.haulAmount;

            if (!this.isGuestCustomer) {
              const sourceCustIdIndex = haulSourceDataSets.findIndex(
                x => x.sourceCustomerId === haul.sourceCustomerId
              );

              const destCustIdIndex = haulDestDataSets.findIndex(
                x => x.destCustomerId === haul.destinationCustomerId
              );

              if (sourceCustIdIndex >= 0) {
                haulSourceDataSets[sourceCustIdIndex].data[j] +=
                  haul.haulAmount;
              } else {
                const haulVals = newDataSet.slice();
                haulVals[j] = haul.haulAmount;

                const colorIndex = haulSourceDataSets.length + 5;
                haulSourceDataSets.push({
                  backgroundColor: colors[colorIndex],
                  sourceCustomerId: haul.sourceCustomerId,
                  label: this.customers[haul.sourceCustomerId].name,
                  data: haulVals
                });
              }

              if (destCustIdIndex >= 0) {
                haulDestDataSets[destCustIdIndex].data[j] += haul.haulAmount;
              } else {
                const haulVals = newDataSet.slice();
                haulVals[j] = haul.haulAmount;

                const colorIndex = haulDestDataSets.length + 2;
                haulDestDataSets.push({
                  backgroundColor: colors[colorIndex],
                  destCustomerId: haul.destinationCustomerId,
                  label: this.customers[haul.destinationCustomerId].name,
                  data: haulVals
                });
              }
            } else if (this.isGuestCustomer) {
              const sourceLocationIdIndex = haulSourceDataSets.findIndex(
                x => x.sourceLocationId === haul.sourceLocationId
              );

              const destLocationIdIndex = haulDestDataSets.findIndex(
                x => x.destinationLocationId === haul.destinationLocationId
              );

              if (
                haul.sourceCustomerId === this.guestCustomerId ||
                this.isSuperCustomer
              ) {
                if (sourceLocationIdIndex >= 0) {
                  haulSourceDataSets[sourceLocationIdIndex].data[j] +=
                    haul.haulAmount;
                } else {
                  const haulVals = newDataSet.slice();
                  haulVals[j] = haul.haulAmount;

                  const colorIndex = haulSourceDataSets.length + 5;
                  haulSourceDataSets.push({
                    backgroundColor: colors[colorIndex],
                    sourceLocationId: haul.sourceLocationId,
                    label: this.masterSources[haul.sourceLocationId].sourceName,
                    data: haulVals
                  });
                }
              }

              if (
                haul.destinationCustomerId === this.guestCustomerId ||
                this.isSuperCustomer
              ) {
                if (destLocationIdIndex >= 0) {
                  haulDestDataSets[destLocationIdIndex].data[j] +=
                    haul.haulAmount;
                } else {
                  const haulVals = newDataSet.slice();
                  haulVals[j] = haul.haulAmount;

                  const colorIndex = haulDestDataSets.length + 2;
                  haulDestDataSets.push({
                    backgroundColor: colors[colorIndex],
                    destinationLocationId: haul.destinationLocationId,
                    label:
                      this.masterDestinations[haul.destinationLocationId]
                        .destinationName,
                    data: haulVals
                  });
                }
              }
            }

            const driverIndex = drivers.findIndex(
              x =>
                x.driver === (this.userList.userIds[haul.driver] || '(Deleted)')
            );

            if (driverIndex >= 0) {
              drivers[driverIndex].driverAmount[j] += haul.haulAmount;
              drivers[driverIndex].driverPrices[j] +=
                haul.haulAmount * haul.pricePerMu;
              drivers[driverIndex].driverHaulPrices[j] +=
                haul.haulAmount * haul.haulPrice;
              drivers[driverIndex].driverHauls.push(haul);
            } else {
              const driverAmount = [];
              const driverPrices = [];
              const driverHaulPrices = [];
              const driverHauls = [];
              driverHauls.push(haul);

              for (let k = 0; k < labels.length; k++) {
                driverAmount.push(0);
                driverPrices.push(0);
                driverHaulPrices.push(0);
              }
              driverAmount[j] += haul.haulAmount;
              driverPrices[j] += haul.haulAmount * haul.pricePerMu;
              driverHaulPrices[j] += haul.haulAmount * haul.haulPrice;

              drivers.push({
                driver: this.userList.userIds[haul.driver] || '(Deleted)',
                driverAmount,
                driverPrices,
                driverHaulPrices,
                driverHauls
              });
            }
          }
        }

        totalHauled += haul.haulAmount;

        const sourceIndex = sources.findIndex(
          x => x === (this.customers[haul.sourceCustomerId].name || '(Deleted)')
        );

        if (sourceIndex >= 0) {
          sourceAmount[sourceIndex] += haul.haulAmount;
        } else {
          sources.push(
            this.customers[haul.sourceCustomerId].name || '(Deleted)'
          );
          sourceAmount.push(haul.haulAmount);
        }

        const destIndex = destinations.findIndex(
          x =>
            x ===
            (this.customers[haul.destinationCustomerId].name || '(Deleted)')
        );

        if (destIndex >= 0) {
          destAmount[destIndex] += haul.haulAmount;
        } else {
          destinations.push(
            this.customers[haul.destinationCustomerId].name || '(Deleted)'
          );
          destAmount.push(haul.haulAmount);
        }

        // Calculate Haul Tracker Totals
        for (let k = 0; k < haul.destinationHaulTrackers.length; k++) {
          if (
            !this.noTrackHaulTrackers.includes(
              this.trackerLabel(haul.destinationHaulTrackers[k].type)
            ) &&
            !haul.destinationHaulTrackers[k].type.startsWith('***&&&')
          ) {
            let trackerIndex = haulTrackers.findIndex(
              x => x.type === haul.destinationHaulTrackers[k].type
            );

            if (trackerIndex >= 0) {
              let optionIndex = haulTrackers[trackerIndex].options.findIndex(
                x => x.name === haul.destinationHaulTrackers[k].option
              );

              if (optionIndex >= 0) {
                haulTrackers[trackerIndex].options[optionIndex].mUnits +=
                  haul.haulAmount;
              } else {
                numHaulTrackers++;
                haulTrackers[trackerIndex].options.push({
                  name: haul.destinationHaulTrackers[k].option,
                  mUnits: haul.haulAmount
                });
              }
            } else {
              numHaulTrackers++;
              haulTrackers.push({
                type: haul.destinationHaulTrackers[k].type,
                options: [
                  {
                    name: haul.destinationHaulTrackers[k].option,
                    mUnits: haul.haulAmount
                  }
                ]
              });
            }
          }
          //}

          for (let k = 0; k < haul.sourceHaulTrackers.length; k++) {
            if (
              !this.noTrackHaulTrackers.includes(
                haul.sourceHaulTrackers[k].type
              )
            ) {
              let trackerIndex = haulTrackers.findIndex(
                x => x.type === haul.sourceHaulTrackers[k].type
              );

              if (trackerIndex >= 0) {
                let optionIndex = haulTrackers[trackerIndex].options.findIndex(
                  x => x.name === haul.sourceHaulTrackers[k].option
                );

                if (optionIndex >= 0) {
                  haulTrackers[trackerIndex].options[optionIndex].mUnits +=
                    haul.haulAmount;
                } else {
                  numHaulTrackers++;
                  haulTrackers[trackerIndex].options.push({
                    name: haul.sourceHaulTrackers[k].option,
                    mUnits: haul.haulAmount
                  });
                }
              } else {
                numHaulTrackers++;
                haulTrackers.push({
                  type: haul.sourceHaulTrackers[k].type,
                  options: [
                    {
                      name: haul.sourceHaulTrackers[k].option,
                      mUnits: haul.haulAmount
                    }
                  ]
                });
              }
            }
          }
        }
      }

      this.labels = labels;

      for (let i = 0; i < amountPerTimeChunk.length; i++) {
        amountPerTimeChunk[i] = round2Dec(amountPerTimeChunk[i]);
        destCommodityPrices[i] = round2Dec(destCommodityPrices[i]);
        sourceCommodityPrices[i] = round2Dec(sourceCommodityPrices[i]);
        commodityProfitPrices[i] = round2Dec(commodityProfitPrices[i]);
      }

      for (let i = 0; i < sourceAmount.length; i++) {
        sourceAmount[i] = round2Dec(sourceAmount[i]);
        sourceColors.push(colors[1]);
      }

      for (let i = 0; i < destAmount.length; i++) {
        destAmount[i] = round2Dec(destAmount[i]);
        destPrices[i] = round2Dec(destPrices[i]);
        destHaulPrices[i] = round2Dec(destHaulPrices[i]);
        destColors.push(colors[0]);
      }

      const sortByDate = (a, b) => {
        if (a.dateTime > b.dateTime) {
          return 1;
        }
        if (a.dateTime < b.dateTime) {
          return -1;
        }
        return 0;
      };

      for (let i = 0; i < drivers.length; i++) {
        // To Create Driver Insights
        drivers[i].driverHauls.sort(sortByDate);

        const commodityTotals = [];
        const haulTrackers = [];
        let driverGrandTotal = 0;
        let driverTotalRaw = 0;
        for (let j = 0; j < drivers[i].driverHauls.length; j++) {
          const haul = drivers[i].driverHauls[j];

          let grossHaulAmount = haul.haulAmount;
          driverTotalRaw += haul.haulAmount;
          for (let k = 0; k < haul.destinationHaulTrackers.length; k++) {
            let haulTracker = haul.destinationHaulTrackers[k];

            if (this.driverInsightsSettings.calcAsGross) {
              if (haulTracker.type.startsWith('***&&&^^^')) {
                grossHaulAmount = haulTracker.option;
              }
            }

            const haulTrackerLabel = this.trackerLabel(haulTracker.type);

            const index = haulTrackers.findIndex(x => x === haulTrackerLabel);

            if (index < 0) {
              haulTrackers.push(haulTrackerLabel);
            }
          }

          for (let k = 0; k < haul.sourceHaulTrackers.length; k++) {
            const haulTracker = this.trackerLabel(
              haul.sourceHaulTrackers[k].type
            );

            const index = haulTrackers.findIndex(x => x === haulTracker);

            if (index < 0) {
              haulTrackers.push(haulTracker);
            }
          }

          if (
            this.driverInsightsSettings.convertToTons &&
            haul.measurementUnit === 'Tons'
          ) {
            grossHaulAmount = grossHaulAmount / 2000;
          } else if (
            this.driverInsightsSettings.convertToBushels &&
            haul.measurementUnit === 'Bushels'
          ) {
            grossHaulAmount = grossHaulAmount / 56;
          }

          drivers[i].driverHauls[j].grossHaulAmount =
            grossHaulAmount.toFixed(2);
          if (haul.driverTotal !== 'N/A') {
            drivers[i].driverHauls[j].driverTotal = (
              grossHaulAmount * haul.driverRate
            ).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD'
            });
            driverGrandTotal += grossHaulAmount * haul.driverRate;
          }

          // calulate commodity totals per driver
          const comIndex = commodityTotals.findIndex(com => {
            return com.name === haul.commodity;
          });

          if (comIndex < 0) {
            const commodityObj = {
              amount: grossHaulAmount,
              name: haul.commodity,
              MU: haul.measurementUnit
            };
            commodityTotals.push(commodityObj);
          } else {
            commodityTotals[comIndex].amount += grossHaulAmount;
          }
        }

        commodityTotals.map(com => {
          com.amount = round2Dec(com.amount);
        });

        drivers[i].commodityTotals = commodityTotals;
        drivers[i].driverTotalRaw = driverTotalRaw;
        drivers[i].driverGrandTotalRaw = driverGrandTotal;
        drivers[i].driverGrandTotal = driverGrandTotal.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD'
        });
        drivers[i].haulTrackers = haulTrackers;

        // After we've made the full list of Haul Trackers for
        // this driver we go back and create the data for each td

        for (let j = 0; j < drivers[i].driverHauls.length; j++) {
          let tdHaulTrackers = [];

          for (let k = 0; k < haulTrackers.length; k++) {
            const haulTracker = haulTrackers[k];

            let val = '';
            drivers[i].driverHauls[j].destinationHaulTrackers.forEach(x => {
              if (this.trackerLabel(x.type) === haulTracker) {
                val = x.option;
              }
            });

            drivers[i].driverHauls[j].sourceHaulTrackers.forEach(x => {
              if (this.trackerLabel(x.type) === haulTracker) {
                val = x.option;
              }
            });

            tdHaulTrackers.push(val);
          }

          tdHaulTrackers = tdHaulTrackers.map(x => {
            return x.toLocaleString('en-US');
          });

          drivers[i].driverHauls[j].tdHaulTrackers = tdHaulTrackers;
        }

        for (let j = 0; j < drivers[i].driverAmount.length; j++) {
          drivers[i].driverAmount[j] = round2Dec(drivers[i].driverAmount[j]);
          drivers[i].driverPrices[j] = round2Dec(
            drivers[i].driverPrices[j] / 10
          );
          drivers[i].driverHaulPrices[j] = round2Dec(
            drivers[i].driverHaulPrices[j] / 10
          );
        }
      }

      // To Create Driver Insights
      this.drivers = [];
      this.drivers = drivers;

      this.totalHauled = round2Dec(totalHauled);

      const services = [];
      this.calcServices(
        services,
        this.activeDestinationsUsers,
        this.activeDestinations,
        timeChunks
      );
      this.calcServices(
        services,
        this.archivedDestinationsUsers,
        this.archivedDestinations,
        timeChunks
      );
      this.calcServices(
        services,
        this.activeSourcesUsers,
        this.activeSources,
        timeChunks
      );
      this.calcServices(
        services,
        this.archivedSourcesUsers,
        this.archivedSources,
        timeChunks
      );

      const servicesFiltered = services.filter(service => {
        let hasVal = false;
        for (let i = 0; i < service.serviceAmountPerTimeChunk.length; i++) {
          if (service.serviceAmountPerTimeChunk[i] !== 0) {
            service.serviceAmountPerTimeChunk[i] = round2Dec(
              service.serviceAmountPerTimeChunk[i]
            );
            hasVal = true;
          }
        }
        return hasVal;
      });

      const serviceTotals = [];
      const serviceVals = [];

      const serviceDataSets = [];
      const serviceValDataSets = [];

      for (let i = 0; i < servicesFiltered.length; i++) {
        servicesFiltered[i].serviceVals = servicesFiltered[i].serviceVals.map(
          val => {
            return round2Dec(val);
          }
        );

        const sName = servicesFiltered[i].name;
        const infoBPW = servicesFiltered[i].infoBoxPairedWith;
        const infoBoxMU = servicesFiltered[i].infoBoxMU;
        const mU = infoBPW.length ? infoBoxMU : this.measurementUnit;
        const sTotal = servicesFiltered[i].serviceAmountPerTimeChunk.reduce(
          (a, b) => a + b,
          0
        );

        serviceTotals.push({
          name: sName,
          mU,
          raw: parseFloat(sTotal),
          info: sTotal.toLocaleString() + ' ' + mU,
          color: colors[i]
        });

        serviceVals.push({
          name: sName,
          color: colors[i],
          raw: servicesFiltered[i].serviceVals.reduce((a, b) => a + b, 0),
          info: this.toDollars(
            servicesFiltered[i].serviceVals.reduce((a, b) => a + b, 0)
          )
        });

        if (servicesFiltered[i].serviceAmountPerTimeChunk.length === 1) {
          servicesFiltered[i].serviceAmountPerTimeChunk.unshift(0);
          servicesFiltered[i].serviceVals.unshift(0);

          servicesFiltered[i].serviceAmountPerTimeChunk.push(0);
          servicesFiltered[i].serviceVals.push(0);
        }

        serviceDataSets.push({
          infoBoxMU: servicesFiltered[i].infoBoxMU,
          label: servicesFiltered[i].name,
          data: servicesFiltered[i].serviceAmountPerTimeChunk,
          backgroundColor: colors[i]
        });

        serviceValDataSets.push({
          label: servicesFiltered[i].name,
          data: servicesFiltered[i].serviceVals,
          backgroundColor: colors[i],
          borderColor: colors[i],
          borderWidth: 2
        });
      }

      const labelToMu = context => {
        return context.parsed.y.toLocaleString() + ' ' + this.measurementUnit;
      };

      const labelToDollars = context => {
        return context.dataset.label + ': ' + this.toDollars(context.parsed.y);
      };

      const labelToMuOrInfoBox = context => {
        if (context.dataset.infoBoxMU.length) {
          return (
            context.dataset.label +
            ': ' +
            context.parsed.y.toLocaleString() +
            ' ' +
            context.dataset.infoBoxMU
          );
        } else {
          return (
            context.dataset.label +
            ': ' +
            context.parsed.y.toLocaleString() +
            ' ' +
            this.measurementUnit
          );
        }
      };

      let serviceTotal = 0;

      for (let i = 0; i < serviceTotals.length; i++) {
        if (serviceTotals[i].mU === this.measurementUnit) {
          serviceTotal += serviceTotals[i].raw;
        }
      }

      serviceTotal =
        round2Dec(serviceTotal).toLocaleString('en-US') +
        ' ' +
        this.measurementUnit;

      const serviceData = {
        type: 'bar',
        data: {
          labels,
          datasets: serviceDataSets
        },
        options: {
          chartID: 'services-chart',
          showTotal: false,
          showTotalArr: true,
          serviceTotalsArr: serviceTotals,
          showGrandTotal: true,
          grandTotal: serviceTotal,
          halfWidth: false,
          additionalHeight: serviceDataSets.length > 5 ? 100 : 0,
          interaction: {
            intersect: false,
            mode: 'index'
          },
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: 'Services'
            },
            tooltip: {
              backgroundColor: 'rgba(0, 0, 0, 0.9)',
              callbacks: {
                label: labelToMuOrInfoBox
              }
            }
          },
          scales: {
            x: {
              stacked: true
            },
            y: {
              beginAtZero: true,
              stacked: true
            }
          }
        }
      };

      reportList.push([serviceData]);

      if (this.isAdmin) {
        let serviceValsTotal = 0;

        for (let i = 0; i < serviceVals.length; i++) {
          serviceValsTotal += serviceVals[i].raw;
        }

        const serviceValData = {
          type: 'line',
          data: {
            labels,
            datasets: serviceValDataSets
          },
          options: {
            chartID: 'service-vals-chart',
            showTotalArr: true,
            serviceTotalsArr: serviceVals,
            showGrandTotal: true,
            grandTotal: this.toDollars(serviceValsTotal),
            halfWidth: false,
            additionalHeight: serviceValDataSets.length > 5 ? 100 : 0,
            interaction: {
              intersect: false,
              mode: 'index'
            },
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: true,
                text: 'Service Values'
              },
              tooltip: {
                backgroundColor: 'rgba(0, 0, 0, 0.9)',
                callbacks: {
                  label: labelToDollars
                }
              }
            },
            scales: {
              y: {
                beginAtZero: true
              }
            }
          }
        };

        reportList.push([serviceValData]);
      }

      const haulLabels = context => {
        return context.raw === 0
          ? ''
          : context.dataset.label +
              ': ' +
              round2Dec(context.raw).toLocaleString() +
              ' ' +
              this.measurementUnit;
      };

      const haulDataFooter = datasets => {
        let sum = 0;
        datasets.forEach(data => {
          sum += data.raw;
        });

        return (
          'Total: ' +
          round2Dec(sum).toLocaleString() +
          ' ' +
          this.measurementUnit
        );
      };

      const haulData = {
        type: 'bar',
        data: {
          labels,
          datasets: [haulDataSet]
        },
        options: {
          chartID: 'haul-data',
          showTotal: true,
          showTotalArr: false,
          showGrandTotal: false,
          interaction: {
            intersect: false,
            mode: 'index'
          },
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: 'Hauls'
            },
            tooltip: {
              backgroundColor: 'rgba(0, 0, 0, 0.9)',
              callbacks: {
                label: labelToMu
              }
            }
          },
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      };

      reportList.push([haulData]);

      const haulSourceData = {
        type: 'bar',
        data: {
          labels,
          datasets: haulSourceDataSets
        },
        options: {
          chartID: 'haul-source-data',
          showTotal: true,
          showTotalArr: false,
          showGrandTotal: false,
          interaction: {
            intersect: false,
            mode: 'index'
          },
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: 'Hauls / Sources'
            },
            tooltip: {
              backgroundColor: 'rgba(0, 0, 0, 0.9)',
              callbacks: {
                label: haulLabels,
                footer: haulDataFooter
              }
            }
          },
          scales: {
            x: {
              stacked: true
            },
            y: {
              beginAtZero: true,
              stacked: true
            }
          }
        }
      };

      reportList.push([haulSourceData]);

      const haulDestData = {
        type: 'bar',
        data: {
          labels,
          datasets: haulDestDataSets
        },
        options: {
          chartID: 'haul-dest-data',
          showTotal: true,
          showTotalArr: false,
          showGrandTotal: false,
          interaction: {
            intersect: false,
            mode: 'index'
          },
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: 'Hauls / ' + this.destAs
            },
            tooltip: {
              backgroundColor: 'rgba(0, 0, 0, 0.9)',
              callbacks: {
                label: haulLabels,
                footer: haulDataFooter
              }
            }
          },
          scales: {
            x: {
              stacked: true
            },
            y: {
              beginAtZero: true,
              stacked: true
            }
          }
        }
      };

      reportList.push([haulDestData]);

      const newHTDataSet = new Array(numHaulTrackers).fill(0);

      const haulTrackerLabels = [];
      const haulTrackerDataSets = [];
      for (let i = 0; i < haulTrackers.length; i++) {
        const haulTracker = haulTrackers[i];
        haulTrackerLabels.push(haulTracker.type);
        for (let j = 0; j < haulTracker.options.length; j++) {
          const option = haulTracker.options[j];
          const data = newHTDataSet.slice();
          data[i] = option.mUnits;

          haulTrackerDataSets.push({
            backgroundColor: colors[j],
            data,
            label: option.name,
            maxBarThickness: 100
          });
        }
      }

      const haulTrackerData = {
        type: 'bar',
        data: {
          labels: haulTrackerLabels,
          datasets: haulTrackerDataSets
        },
        options: {
          chartID: 'haul-tracker-chart',
          showTotal: false,
          showTotalArr: false,
          showGrandTotal: false,
          interaction: {
            intersect: false,
            mode: 'index'
          },
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: 'Haul Trackers'
            },
            tooltip: {
              backgroundColor: 'rgba(0, 0, 0, 0.9)',
              callbacks: {
                label: haulLabels,
                footer: haulDataFooter
              }
            }
          },
          scales: {
            x: {
              stacked: true
            },
            y: {
              beginAtZero: true,
              stacked: true
            }
          }
        }
      };
      reportList.push([haulTrackerData]);

      if (this.isAdmin) {
        const haulValDataSets = [
          {
            label: this.destAs,
            data: destCommodityPrices,
            backgroundColor: colors[3],
            borderColor: colors[3],
            borderWidth: 2
          },
          {
            label: 'Sources',
            data: sourceCommodityPrices,
            backgroundColor: colors[6],
            borderColor: colors[6],
            borderWidth: 2
          },
          {
            label: 'Profit',
            data: commodityProfitPrices,
            backgroundColor: colors[2],
            borderColor: colors[2],
            borderWidth: 2
          }
        ];

        const haulValData = {
          type: 'line',
          data: {
            labels,
            datasets: haulValDataSets
          },
          options: {
            chartID: 'haul-vals',
            showTotal: false,
            showTotalArr: false,
            showGrandTotal: false,
            halfWidth: false,
            interaction: {
              intersect: false,
              mode: 'index'
            },
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: true,
                text: 'Haul Values - Commodity Price'
              },
              tooltip: {
                backgroundColor: 'rgba(0, 0, 0, 0.9)',
                callbacks: {
                  label: labelToDollars
                }
              }
            },
            scales: {
              y: {
                beginAtZero: true
              }
            }
          }
        };
        reportList.push([haulValData]);
      }

      const bookedLabels = ['Haul'];
      const bookedColors = [colors[9]];
      const bookedDataSet = {
        backgroundColor: bookedColors,
        label: this.measurementUnit,
        data: [this.calcBooked.toHaul],
        maxBarThickness: 100
      };

      for (let i = 0; i < this.calcBooked.services.length; i++) {
        const service = this.calcBooked.services[i];
        bookedLabels.push(service.name);
        bookedDataSet.backgroundColor.push(colors[9]);
        bookedDataSet.data.push(service.amount);
      }

      let bookedTotal = 0;
      for (let i = 0; i < bookedDataSet.data.length; i++) {
        bookedTotal += bookedDataSet.data[i];
      }

      const currentlyBooked = {
        type: 'bar',
        data: {
          labels: bookedLabels,
          datasets: [bookedDataSet]
        },
        options: {
          chartID: 'currently-booked',
          showTotal: false,
          showTotalArr: false,
          showGrandTotal: true,
          grandTotal:
            round2Dec(bookedTotal).toLocaleString('en-US') +
            ' ' +
            this.measurementUnit,
          halfWidth: false,
          interaction: {
            intersect: false,
            mode: 'index'
          },
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            },
            title: {
              display: true,
              text: 'Currently Booked - ' + this.destAs
            },
            tooltip: {
              backgroundColor: 'rgba(0, 0, 0, 0.9)',
              callbacks: {
                label: labelToMu
              }
            }
          },
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      };
      reportList.push([currentlyBooked]);

      const bookedValLabels = [];
      const bookedValColors = [];
      const bookedValDataSet = {
        backgroundColor: bookedValColors,
        label: this.measurementUnit,
        data: [],
        maxBarThickness: 100
      };

      for (let i = 0; i < this.calcBooked.services.length; i++) {
        const service = this.calcBooked.services[i];
        bookedValLabels.push(service.name);
        bookedValDataSet.backgroundColor.push(colors[4]);
        bookedValDataSet.data.push(service.price);
      }

      if (this.isAdmin) {
        let bookedValTotal = 0;
        for (let i = 0; i < bookedValDataSet.data.length; i++) {
          bookedValTotal += bookedValDataSet.data[i];
        }

        const currentlyBookedVals = {
          type: 'bar',
          data: {
            labels: bookedValLabels,
            datasets: [bookedValDataSet]
          },
          options: {
            chartID: 'currently-booked-vals',
            showTotal: false,
            showTotalArr: false,
            showGrandTotal: true,
            grandTotal: this.toDollars(bookedValTotal),
            halfWidth: false,
            interaction: {
              intersect: false,
              mode: 'index'
            },
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false
              },
              title: {
                display: true,
                text: 'Currently Booked Service Values - ' + this.destAs
              },
              tooltip: {
                backgroundColor: 'rgba(0, 0, 0, 0.9)',
                callbacks: {
                  label: labelToDollars
                }
              }
            },
            scales: {
              y: {
                beginAtZero: true
              }
            }
          }
        };
        reportList.push([currentlyBookedVals]);
      }

      const sourceDataSets = [
        {
          data: sourceAmount,
          backgroundColor: sourceColors,
          maxBarThickness: 100
        }
      ];

      const sourceData = {
        type: 'bar',
        data: {
          labels: sources,
          datasets: sourceDataSets
        },
        options: {
          chartID: 'sources-list',
          showTotal: true,
          showTotalArr: false,
          showGrandTotal: false,
          additionalHeight: sources.length > 5 ? 70 : 0,
          interaction: {
            intersect: false,
            mode: 'index'
          },
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            },
            title: {
              display: true,
              text: 'Sources - Haul Amounts'
            },
            tooltip: {
              backgroundColor: 'rgba(0, 0, 0, 0.9)',
              callbacks: {
                label: labelToMu
              }
            }
          },
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      };

      if (!this.isCustomer) {
        reportList.push([sourceData]);
      }

      const destDataSets = [
        {
          data: destAmount,
          backgroundColor: destColors,
          maxBarThickness: 100
        }
      ];

      const destinationData = {
        type: 'bar',
        data: {
          labels: destinations,
          datasets: destDataSets
        },
        options: {
          chartID: 'dest-list',
          showTotal: true,
          showTotalArr: false,
          showGrandTotal: false,
          additionalHeight: destinations.length > 5 ? 50 : 0,
          interaction: {
            intersect: false,
            mode: 'index'
          },
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            },
            title: {
              display: true,
              text: `${this.destAs} - Haul Amounts`
            },
            tooltip: {
              backgroundColor: 'rgba(0, 0, 0, 0.9)',
              callbacks: {
                label: labelToMu
              }
            }
          },
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      };

      if (!this.isCustomer) {
        reportList.push([destinationData]);
      }

      this.driversAuth = [];
      const driverLabels = [];
      const driverHaulAmounts = [];
      const driverHaulValAmounts = [];
      for (let i = 0; i < drivers.length; i++) {
        driverLabels.push(drivers[i].driver);
        driverHaulAmounts.push(drivers[i].driverTotalRaw || 0.0001);
        driverHaulValAmounts.push(drivers[i].driverGrandTotalRaw || 0.0001);

        let tableRowHeight = 0;
        tableRowHeight += drivers[i].driverHauls.length * 50;
        if (drivers[i].driver === this.user.displayName || this.isAdmin) {
          this.driversAuth.push({
            ...drivers[i],
            tableRowHeight: tableRowHeight
          });
        }
      }

      const driverDataSets = [
        {
          label: driverLabels,
          data: driverHaulAmounts,
          backgroundColor: colors.slice(4)
        }
      ];

      const driverLabelToMu = context => {
        return (
          context.label +
          ': ' +
          round2Dec(context.parsed) +
          ' ' +
          this.measurementUnit
        );
      };

      const driverData = {
        type: 'pie',
        data: {
          labels: driverLabels,
          datasets: driverDataSets
        },
        options: {
          chartID: 'drivers-chart',
          showTotal: false,
          showTotalArr: false,
          showGrandTotal: false,
          halfWidth: true,
          maxHeight: 300,
          aspectRatio: 0.7,
          plugins: {
            legend: {
              display: true
            },
            title: {
              display: true,
              text: 'Drivers'
            },
            tooltip: {
              backgroundColor: 'rgba(0, 0, 0, 0.9)',
              callbacks: {
                label: driverLabelToMu
              }
            }
          },
          scales: {
            x: {
              display: false
            },
            y: {
              display: false,
              beginAtZero: true
            }
          }
        }
      };

      const driverValDataSets = [
        {
          label: driverLabels,
          data: driverHaulValAmounts,
          backgroundColor: colors.slice(4)
        }
      ];

      const driverLabelToDollars = context => {
        return context.label + ': ' + this.toDollars(context.parsed);
      };

      const driverValData = {
        type: 'pie',
        data: {
          labels: driverLabels,
          datasets: driverValDataSets
        },
        options: {
          maxHeight: 300,
          chartID: 'driver-vals-chart',
          showTotal: false,
          showTotalArr: false,
          showGrandTotal: false,
          halfWidth: true,
          aspectRatio: 0.7,
          responsive: true,
          plugins: {
            legend: {
              display: true
            },
            title: {
              display: true,
              text: 'Driver Haul Values (Driver Rate)'
            },
            tooltip: {
              backgroundColor: 'rgba(0, 0, 0, 0.9)',
              callbacks: {
                label: driverLabelToDollars
              }
            }
          },
          scales: {
            x: {
              display: false
            },
            y: {
              display: false,
              beginAtZero: true
            }
          }
        }
      };

      if (!this.isCustomer) {
        reportList.push([driverData, driverValData]);
      }

      this.reportList = reportList.slice();

      this.onSortCharts(true);

      // Makes charts reactive
      this.$nextTick(() => {
        this.reportsCalculated = true;
      });
    },
    calcServices(services, arr, adminArr, timeChunks) {
      for (let i = 0; i < arr.length; i++) {
        const arrItem = arr[i];

        // Need to filter - match commodity
        if (
          !this.displayDriverTables &&
          !this.commoditySelectedArr.includes(arrItem.commodity)
        ) {
          continue;
        }

        let hauled = 0;
        for (const key in arrItem.hauls) {
          const haul = arrItem.hauls[key];
          hauled += haul.haulAmount;
        }
        hauled = round2Dec(hauled);

        const combinedServices = arrItem.services.map((service, index) => {
          if (this.isAdmin) {
            const returnObj = {
              ...service,
              ...adminArr[i].services[index],
              useInfoBox: adminArr[i].services[index].useInfoBox || false,
              origName: service.name
            };

            if (adminArr[i].services[index].useInfoBox) {
              const infoBoxPairedWith =
                adminArr[i].services[index].infoBoxPairedWith;

              returnObj.name = service.name + ' @ ' + infoBoxPairedWith;

              const infoBoxVal = adminArr[i].infoBoxes.find(infoBox => {
                if (infoBox.name === infoBoxPairedWith) {
                  return true;
                }
              });
              // If InfoBox has been deleted
              returnObj.infoBoxVal = infoBoxVal ? infoBoxVal.model : 0;
            }
            return returnObj;
          } else {
            return {
              ...service,
              price: 0,
              useInfoBox: this.isAdmin
                ? adminArr[i].services[index].useInfoBox || false
                : false
            };
          }
        });

        for (let i = 0; i < combinedServices.length; i++) {
          const service = combinedServices[i];

          //if (service.name !== 'Haul' && service.name !== 'Hauls') {
          const index = services.findIndex(x => x.name === service.name);

          if (index >= 0) {
            for (let k = 0; k < timeChunks.length - 1; k++) {
              const startTs = timeChunks[k];
              const endTs = timeChunks[k + 1];
              if (
                service.completeTs &&
                service.completeTs > startTs &&
                service.completeTs < endTs
              ) {
                if (service.useInfoBox) {
                  services[index].serviceAmountPerTimeChunk[k] +=
                    service.infoBoxVal;
                  services[index].serviceVals[k] +=
                    service.infoBoxVal * service.price;
                } else {
                  services[index].serviceAmountPerTimeChunk[k] += hauled;
                  services[index].serviceVals[k] += hauled * service.price;
                }
              }
            }
          } else {
            const serviceAmountPerTimeChunk = [];
            const serviceVals = [];
            for (let k = 0; k < timeChunks.length - 1; k++) {
              const startTs = timeChunks[k];
              const endTs = timeChunks[k + 1];
              if (
                service.completeTs &&
                service.completeTs > startTs &&
                service.completeTs < endTs
              ) {
                if (service.useInfoBox) {
                  serviceAmountPerTimeChunk.push(service.infoBoxVal);
                  serviceVals.push(service.infoBoxVal * service.price);
                } else {
                  serviceAmountPerTimeChunk.push(hauled);
                  serviceVals.push(hauled * service.price);
                }
              } else {
                serviceAmountPerTimeChunk.push(0);
                serviceVals.push(0);
              }
            }

            let infoBoxMU = '';
            if (service.useInfoBox) {
              for (
                let i = 0;
                i < this.settings.destinationSettings.services.length;
                i++
              ) {
                const destService =
                  this.settings.destinationSettings.services[i];
                if (service.origName === destService.name) {
                  infoBoxMU = destService.infoBoxMU;
                }
              }

              for (
                let i = 0;
                i < this.settings.sourceSettings.services.length;
                i++
              ) {
                const sourceService = this.settings.sourceSettings.services[i];
                if (service.origName === sourceService.name) {
                  infoBoxMU = sourceService.infoBoxMU;
                }
              }
            }

            services.push({
              name: service.name,
              infoBoxPairedWith: service.useInfoBox
                ? service.infoBoxPairedWith
                : '',
              infoBoxMU,
              serviceAmountPerTimeChunk,
              serviceVals
            });
          }
          //}
        }
      }
      return services;
    },
    driverAuthInput() {
      if (!this.driverAuthChanged) {
        this.driverAuthChanged = true;
      }
    },
    haulList() {
      const hauls = [];
      const sourceHauls = [];
      for (let i = 0; i < this.activeSourcesUsers.length; i++) {
        for (const key in this.activeSourcesUsers[i].hauls) {
          const haul = this.activeSourcesUsers[i].hauls[key];
          if (
            haul.dateTime >= this.dates.start &&
            haul.dateTime < this.dates.end
          ) {
            const tempObj = {};

            if (
              this.activeSourcesUsers[i].trackRunningBalance &&
              this.activeSourcesUsers[i].alwaysAvailable
            ) {
              tempObj.isDest = true;
              if (this.isAdmin) {
                tempObj.destCommodityPrice = this.activeSources[i].pricePerUnit;
              }
            }

            if (this.isAdmin) {
              tempObj.sourceCommodityPrice = this.activeSources[i].pricePerUnit;
            }

            // This is used by Driver Tables
            let measurementUnit = '';
            for (const key in this.settingsUsers.commodities) {
              if (key === this.activeSourcesUsers[i].commodity) {
                measurementUnit =
                  this.settingsUsers.commodities[key].measurementUnit;
              }
            }

            sourceHauls.push({
              ...haul,
              ...tempObj,
              measurementUnit,
              commodity: this.activeSourcesUsers[i].commodity
            });
          }
        }
      }

      // Sources and sourcesUsers are ordered by settlement date
      for (let i = 0; i < this.archivedSourcesUsers.length; i++) {
        // Need to filter archived - match commodity
        if (
          !this.displayDriverTables &&
          !this.commoditySelectedArr.includes(
            this.archivedSourcesUsers[i].commodity
          )
        ) {
          continue;
        }

        for (const key in this.archivedSourcesUsers[i].hauls) {
          const haul = this.archivedSourcesUsers[i].hauls[key];

          if (
            haul.dateTime >= this.dates.start &&
            haul.dateTime < this.dates.end
          ) {
            const tempObj = {};

            if (
              this.archivedSourcesUsers[i].trackRunningBalance &&
              this.archivedSourcesUsers[i].alwaysAvailable
            ) {
              tempObj.isDest = true;

              if (this.isAdmin) {
                tempObj.destCommodityPrice =
                  this.archivedSources[i].pricePerUnit;
              }
            }

            if (this.isAdmin) {
              tempObj.sourceCommodityPrice =
                this.archivedSources[i].pricePerUnit;
            }

            // This is used by Driver Tables
            let measurementUnit = '';
            for (const key in this.settingsUsers.commodities) {
              if (key === this.archivedSourcesUsers[i].commodity) {
                measurementUnit =
                  this.settingsUsers.commodities[key].measurementUnit;
              }
            }

            sourceHauls.push({
              ...haul,
              ...tempObj,
              measurementUnit,
              commodity: this.archivedSourcesUsers[i].commodity
            });
          }
        }
      }

      for (let i = 0; i < this.activeDestinationsUsers.length; i++) {
        for (const key in this.activeDestinationsUsers[i].hauls) {
          const haul = this.activeDestinationsUsers[i].hauls[key];

          if (
            haul.dateTime >= this.dates.start &&
            haul.dateTime < this.dates.end
          ) {
            if (this.activeDestinationsUsers[i].sourceAsDest) {
              if (!haul.sourceAsDestination) {
                continue;
              }
            }

            const driverRate =
              typeof this.activeDestinationsUsers[i].driverRate !== 'undefined'
                ? this.activeDestinationsUsers[i].driverRate
                : 'N/A';

            const driverRateFormatted =
              typeof this.activeDestinationsUsers[i].driverRate !== 'undefined'
                ? this.activeDestinationsUsers[i].driverRate.toLocaleString(
                    'en-US',
                    {
                      style: 'currency',
                      currency: 'USD'
                    }
                  )
                : 'N/A';

            // Driver Total will be recalculated later if applicable
            const driverTotal =
              typeof this.activeDestinationsUsers[i].driverRate !== 'undefined'
                ? this.activeDestinationsUsers[i].driverRate * haul.haulAmount
                : 'N/A';

            // This is used by Driver Tables
            let measurementUnit = '';
            for (const key in this.settingsUsers.commodities) {
              if (key === this.activeDestinationsUsers[i].commodity) {
                measurementUnit =
                  this.settingsUsers.commodities[key].measurementUnit;
              }
            }

            const sHaulIndex = sourceHauls.findIndex(sHaul => {
              return sHaul.dateTime === haul.dateTime;
            });

            if (this.isAdmin) {
              hauls.push({
                ...haul,
                formatedDate: date.formatDate(haul.dateTime, 'MM/DD/YY'),
                driverRate,
                driverRateFormatted,
                driverTotal,
                measurementUnit,
                commodity: this.activeDestinationsUsers[i].commodity,
                destCommodityPrice: this.activeDestinations[i].pricePerUnit,
                sourceCommodityPrice:
                  sourceHauls[sHaulIndex].sourceCommodityPrice
              });
            } else {
              hauls.push({
                ...haul,
                formatedDate: date.formatDate(haul.dateTime, 'MM/DD/YY'),
                driverRate,
                driverRateFormatted,
                driverTotal,
                commodity: this.activeDestinationsUsers[i].commodity,
                measurementUnit
              });
            }
          }
        }
      }

      for (let i = 0; i < this.archivedDestinationsUsers.length; i++) {
        // Need to filter archived - match commodity
        if (
          !this.displayDriverTables &&
          !this.commoditySelectedArr.includes(
            this.archivedDestinationsUsers[i].commodity
          )
        ) {
          continue;
        }

        for (const key in this.archivedDestinationsUsers[i].hauls) {
          const haul = this.archivedDestinationsUsers[i].hauls[key];

          if (
            haul.dateTime >= this.dates.start &&
            haul.dateTime < this.dates.end
          ) {
            if (this.archivedDestinationsUsers[i].sourceAsDest) {
              if (!haul.sourceAsDestination) {
                continue;
              }
            }
            const driverRate =
              typeof this.archivedDestinationsUsers[i].driverRate !==
              'undefined'
                ? this.archivedDestinationsUsers[i].driverRate.toFixed(2)
                : 'N/A';

            const driverRateFormatted =
              typeof this.archivedDestinationsUsers[i].driverRate !==
              'undefined'
                ? this.archivedDestinationsUsers[i].driverRate.toLocaleString(
                    'en-US',
                    {
                      style: 'currency',
                      currency: 'USD'
                    }
                  )
                : 'N/A';

            const driverTotal =
              typeof this.archivedDestinationsUsers[i].driverRate !==
              'undefined'
                ? this.archivedDestinationsUsers[i].driverRate * haul.haulAmount
                : 'N/A';

            // This is used by Driver Tables
            let measurementUnit = '';
            for (const key in this.settingsUsers.commodities) {
              if (key === this.archivedDestinationsUsers[i].commodity) {
                measurementUnit =
                  this.settingsUsers.commodities[key].measurementUnit;
              }
            }

            const sHaulIndex = sourceHauls.findIndex(sHaul => {
              return sHaul.dateTime === haul.dateTime;
            });

            if (this.isAdmin) {
              hauls.push({
                ...haul,
                driverRate,
                driverRateFormatted,
                driverTotal,
                commodity: this.archivedDestinationsUsers[i].commodity,
                measurementUnit,
                formatedDate: date.formatDate(haul.dateTime, 'MM/DD/YY'),
                destCommodityPrice: this.archivedDestinations[i].pricePerUnit,
                // Seems like there is some old data where the haul.dateTimes do not match between source/dest
                sourceCommodityPrice:
                  sHaulIndex < 0
                    ? 0
                    : sourceHauls[sHaulIndex].sourceCommodityPrice
              });
            } else {
              hauls.push({
                ...haul,
                pricePerMu: 0,
                haulPrice: null,
                commodity: this.archivedDestinationsUsers[i].commodity,
                measurementUnit
              });
            }
          }
        }
      }

      const sourceAsDestHauls = [];
      for (let i = 0; i < sourceHauls.length; i++) {
        const haul = sourceHauls[i];
        if (haul.sourceAsDestination) {
          if (!haul.isDest) {
            if (this.isAdmin) {
              const index = sourceHauls.findIndex(x => {
                return x.dateTime === haul.dateTime && x.isDest;
              });

              sourceAsDestHauls.push({
                ...haul,
                driverRate: 0,
                driverRateFormatted: '$0.00',
                driverTotal: 0,
                formatedDate: date.formatDate(haul.dateTime, 'MM/DD/YY'),
                // Seems like there is some old data where the haul.dateTimes do not match between source/dest
                destCommodityPrice:
                  index < 0 ? 0 : sourceHauls[index].destCommodityPrice,
                sourceCommodityPrice: haul.sourceCommodityPrice
              });
            } else {
              hauls.push({
                ...haul,
                pricePerMu: 0,
                haulPrice: null
              });
            }
          }
        }
      }

      if (this.isGuestCustomer) {
        for (let i = 0; i < sourceHauls.length; i++) {
          const haul = sourceHauls[i];
          const hasHaul = hauls.find(x => {
            return x.dateTime === haul.dateTime;
          });

          if (
            ((this.isCustomer &&
              haul.sourceCustomerId === this.guestCustomerId) ||
              this.isSuperCustomer) &&
            !hasHaul
          ) {
            hauls.push({
              ...haul,
              pricePerMu: 0,
              haulPrice: null
            });
          }
        }
      }

      return [...hauls, ...sourceAsDestHauls];
    },
    async lookup(start = null, end = null, defaultDates = 'no_change') {
      this.reportsCalculated = false;
      this.displayDateRange = false;

      if (defaultDates !== 'no_change') {
        localStorage.reportOptions = JSON.stringify({
          reportType: this.reportType,
          driverInsightsSettings: this.driverInsightsSettings,
          defaultDates: defaultDates
        });
        this.defaultDates = defaultDates;
      }

      let numDays = 14;
      if (this.defaultDates === 'one') {
        numDays = 7;
      }

      const setDefaultDateArr = () => {
        const startToday = new Date().setHours(0, 0, 0, 0);
        let dayOfWeek = date.getDayOfWeek(startToday);
        // If Sunday
        dayOfWeek = dayOfWeek === 7 ? 0 : dayOfWeek;

        const daysOfWeek = [];
        for (let i = 0; i < numDays + 1; i++) {
          // Actually add days if day is later than today - subtracts negative num
          // + 7 goes back two weeks
          let startTs = date.subtractFromDate(startToday, {
            days: dayOfWeek - i + (numDays - 7)
          });

          startTs = parseInt(date.formatDate(startTs, 'x'));

          daysOfWeek.push(startTs);
        }
        return daysOfWeek;
      };

      if (!start || !end) {
        const dates = setDefaultDateArr();
        this.dates.start = dates[0];
        this.dates.end = dates[numDays];
      } else {
        this.dates.start = start;
        this.dates.end = end;
      }

      this.archivedSourcesUsers = [];
      const lookupArchivedSourcesUsers = () => {
        if (this.isGuestUser) {
          const guestUserId = this.$store.state.user.guestUserId;

          const lookup = db
            .collection('sources_archived_users')
            .where('settlementDate', '>=', this.dates.start)
            .where('sourceStatus', 'in', ['Open'])
            .where('allowGuestUsers', '==', true)
            .where('guestUsers', 'array-contains', guestUserId)
            .orderBy('settlementDate')
            .get()
            .then(result => {
              result.forEach(doc => {
                const data = doc.data();
                this.archivedSourcesUsers.push(data);
              });
              return true;
            });
          return lookup;
        } else if (this.isGuestCustomer) {
          const lookup = db
            .collection('sources_archived_users')
            .where('settlementDate', '>=', this.dates.start)
            .where('sourceStatus', 'in', ['Open'])
            .where('customerId', '==', this.guestCustomerId)
            .orderBy('settlementDate')
            .get()
            .then(result => {
              result.forEach(doc => {
                const data = doc.data();
                this.archivedSourcesUsers.push(data);
              });
              return true;
            });
          return lookup;
        } else {
          const lookup = db
            .collection('sources_archived_users')
            .where('settlementDate', '>=', this.dates.start)
            .orderBy('settlementDate')
            .get()
            .then(result => {
              result.forEach(doc => {
                const data = doc.data();
                this.archivedSourcesUsers.push(data);
              });
              return true;
            });
          return lookup;
        }
      };

      this.archivedSources = [];
      const lookupArchivedSources = () => {
        if (this.isAdmin) {
          const tempArr = [];
          const lookup = db
            .collection('sources_archived')
            .where('settlementDate', '>=', this.dates.start)
            .orderBy('settlementDate')
            .get()
            .then(result => {
              result.forEach(doc => {
                const data = doc.data();
                tempArr.push(data);
              });

              for (let i = 0; i < this.archivedSourcesUsers.length; i++) {
                const index = tempArr.findIndex(
                  x => x.id === this.archivedSourcesUsers[i].id
                );
                this.archivedSources.push(tempArr[index]);
              }

              return true;
            });
          return lookup;
        }
      };

      this.archivedDestinationsUsers = [];
      const lookupArchivedDestUsers = () => {
        if (this.isGuestUser) {
          const guestUserId = this.$store.state.user.guestUserId;
          // Indexed query
          const lookup = db
            .collection('destinations_archived_users')
            .where('settlementDate', '>=', this.dates.start)
            .where('destinationStatus', 'in', ['Open'])
            .where('allowGuestUsers', '==', true)
            .where('guestUsers', 'array-contains', guestUserId)
            .orderBy('settlementDate')
            .get()
            .then(result => {
              result.forEach(doc => {
                this.archivedDestinationsUsers.push({
                  ...doc.data(),
                  sourceAsDest: false
                });
              });
              return true;
            });
          return lookup;
        } else if (this.isGuestCustomer) {
          // Indexed query
          const lookup = db
            .collection('destinations_archived_users')
            .where('settlementDate', '>=', this.dates.start)
            .where('destinationStatus', 'in', ['Open'])
            .where('customerId', '==', this.guestCustomerId)
            .orderBy('settlementDate')
            .get()
            .then(result => {
              result.forEach(doc => {
                this.archivedDestinationsUsers.push({
                  ...doc.data(),
                  sourceAsDest: false
                });
              });
              return true;
            });
          return lookup;
        } else {
          // Indexed query
          const lookup = db
            .collection('destinations_archived_users')
            .where('settlementDate', '>=', this.dates.start)
            .orderBy('settlementDate')
            .get()
            .then(result => {
              result.forEach(doc => {
                this.archivedDestinationsUsers.push({
                  ...doc.data(),
                  sourceAsDest: false
                });
              });
              return true;
            });
          return lookup;
        }
      };

      this.archivedDestinations = [];
      const lookupArchivedDest = () => {
        if (this.isAdmin) {
          const tempArr = [];
          const lookup = db
            .collection('destinations_archived')
            .where('settlementDate', '>=', this.dates.start)
            .orderBy('settlementDate')
            .get()
            .then(result => {
              result.forEach(doc => {
                tempArr.push({
                  ...doc.data(),
                  sourceAsDest: false
                });
              });

              for (let i = 0; i < this.archivedDestinationsUsers.length; i++) {
                const index = tempArr.findIndex(
                  x => x.id === this.archivedDestinationsUsers[i].id
                );
                this.archivedDestinations.push(tempArr[index]);
              }

              return true;
            });
          return lookup;
        }
      };

      // We want to wait on users before we move on
      const archivedSourcesLookupUsers = lookupArchivedSourcesUsers();
      await archivedSourcesLookupUsers;

      const archivedDestUsersLookup = lookupArchivedDestUsers();
      await archivedDestUsersLookup;

      const archivedSourcesLookup = lookupArchivedSources();
      const archivedDestLookup = lookupArchivedDest();

      await archivedSourcesLookup;
      await archivedDestLookup;

      this.calcReports();
    },
    onSelectCommodity(commodityArr) {
      this.displayCommoditySelection = false;

      this.reportsCalculated = false;
      this.commoditySelectedArr = commodityArr.slice();

      localStorage.commoditySelectedArr = JSON.stringify(commodityArr);

      this.noCommodityErr = false;
      this.lookup(this.dates.start, this.dates.end);
    },
    onSortCharts(useDefault, updatedChartOrder = []) {
      this.displaySortCharts = false;
      // makes chart order reactive
      this.reportsCalculated = false;

      let chartOrder = [
        'haul-data',
        'haul-source-data',
        'haul-dest-data',
        'haul-vals',
        'sources-list',
        'dest-list',
        'services-chart',
        'service-vals-chart',
        'currently-booked',
        'currently-booked-vals',
        'haul-tracker-chart',
        'drivers-chart'
      ];

      // Make sure this list is correct, even if admin logs in as user or customer
      // and then logs back in as admin
      const chartOrderCopy = chartOrder.slice();
      if (useDefault) {
        if (localStorage.getItem('chartOrder') !== null) {
          chartOrder = JSON.parse(localStorage.getItem('chartOrder'));
          if (
            (this.isAdmin && chartOrder.length < 12) ||
            (!this.isCustomer && chartOrder.length < 9)
          ) {
            chartOrder = chartOrderCopy.slice();
          }
        }
      } else {
        chartOrder = updatedChartOrder.slice();
      }

      let noShow = ['haul-vals', 'service-vals-chart', 'currently-booked-vals'];
      if (!this.isAdmin) {
        chartOrder = chartOrder.filter(x => {
          return !noShow.includes(x);
        });
      }

      let noCustShow = ['sources-list', 'dest-list', 'drivers-chart'];
      if (this.isCustomer) {
        chartOrder = chartOrder.filter(x => {
          return !noCustShow.includes(x);
        });
      }

      localStorage.setItem('chartOrder', JSON.stringify(chartOrder));
      this.chartOrder = chartOrder.slice();

      this.reportListSorted = [];
      this.reportListSplit = [];

      this.reportListSorted = this.reportList
        .slice()
        .sort(
          (a, b) =>
            chartOrder.indexOf(a[0].options.chartID) -
            chartOrder.indexOf(b[0].options.chartID)
        );

      let splitNum = Math.ceil(this.reportList.length / 2);

      this.reportListSplit = [];
      this.reportListSplit = [
        this.reportListSorted.slice(0, splitNum),
        this.reportListSorted.slice(splitNum)
      ];

      // Resets charts order
      this.$nextTick(() => {
        this.reportsCalculated = true;
      });
    },
    randomNum() {
      return Math.floor(Math.random() * 1000000);
    },
    printInsights(driver) {
      this.driver = driver;
      this.print = true;
      this.$nextTick(() => {
        const css = `
          table,
          th,
          td {
            border: 1px solid black;
            border-collapse: collapse;
            text-align: center;
            padding: 2px;
            font-size: 13px;
          }
          @page {
            size: landscape;
          }
        `;

        const printMe = document.getElementById('print');

        const d = new Printd();
        d.print(printMe, [css]);

        this.print = false;
      });
    },
    selectDates() {
      this.displayDateRange = true;
    },
    setCommodity() {
      if (localStorage.commoditySelectedArr) {
        this.commoditySelectedArr = JSON.parse(
          localStorage.commoditySelectedArr
        );
        this.lookup();
      } else {
        this.noCommodityErr = true;
      }
    },
    async setDriverAuth() {
      const reportsAuthDoc = doc(db, 'settings_users', 'settings');

      this.$q.notify({
        color: 'custom-orange',
        textColor: 'black',
        message: 'Driver Auth Submitted'
      });

      await updateDoc(reportsAuthDoc, {
        driverReportsAuth: this.driverAuthList.slice()
      });
    },
    setDriverAuthList() {
      if (this.userList) {
        this.driverList = [];
        for (let [key] of Object.entries(this.userList.users)) {
          let isGuestCust = false;

          if (!isGuestCust) {
            this.driverList.push({
              label: key,
              value: key,
              color: 'custom-orange'
            });
          }
        }

        this.driverAuthList =
          this.$store.state.settingsUsers.driverReportsAuth.slice();

        if (
          (this.driverAuthList.includes(this.user.displayName) ||
            this.isAdmin) &&
          !this.isGuestCustomer
        ) {
          this.driverIsAuthorized = true;
        } else {
          this.driverIsAuthorized = false;
        }
      }
    },
    setReportOptions(reportType) {
      this.reportsCalculated = false;
      this.reportType = reportType;
      localStorage.reportOptions = JSON.stringify({
        reportType: reportType,
        driverInsightsSettings: this.driverInsightsSettings,
        defaultDates: this.defaultDates
      });

      this.lookup(this.dates.start, this.dates.end);
    },
    toDollars(amount) {
      // Create our number formatter.
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(amount);
    },
    trackerLabel(label) {
      if (label.startsWith('***&&&')) {
        return label.substring(9);
      }

      return label;
    }
  },
  computed: {
    activeDestinations() {
      let activeDestinations = [];
      if (this.isAdmin) {
        activeDestinations = this.$store.state.activeDestinations.filter(
          dest => {
            return this.activeDestinationsUsers.find(destUsers => {
              return destUsers.id === dest.id;
            });
          }
        );
      }

      activeDestinations = activeDestinations.map(dest => {
        return { ...dest, sourceAsDest: false };
      });

      return activeDestinations;
    },
    activeDestinationsUsers() {
      let activeDestinationsUsers;

      activeDestinationsUsers =
        this.$store.state.activeDestinationsUsers.filter(dest => {
          if (this.displayDriverTables) {
            return true;
          } else {
            return this.commoditySelectedArr.includes(dest.commodity);
          }
        });

      activeDestinationsUsers = activeDestinationsUsers.map(dest => {
        return { ...dest, sourceAsDest: false };
      });

      return activeDestinationsUsers;
    },
    activeSources() {
      let activeSources = [];
      if (this.isAdmin) {
        activeSources = this.$store.state.activeSources.filter(source => {
          return this.activeSourcesUsers.find(sourceUsers => {
            return sourceUsers.id === source.id;
          });
        });
      }

      return activeSources;
    },
    activeSourcesUsers() {
      return this.$store.state.activeSourcesUsers.filter(source => {
        if (this.displayDriverTables) {
          return true;
        } else {
          return this.commoditySelectedArr.includes(source.commodity);
        }
      });
    },
    cardStyleOne() {
      return this.$q.screen.width > 599 ? 'height: 265px' : '';
    },
    cardStyleTwo() {
      if (!this.isGuestUser) {
        return this.$q.screen.width > 1439 ? 'height: 355px' : 'height: 265px';
      } else {
        if (this.$q.screen.width > 1439) {
          return 'height: 265px';
        } else if (this.$q.screen.width > 599) {
          return 'height: 355px';
        } else {
          return 'height: 265px';
        }
      }
    },
    calcBooked() {
      let totalHaulProjected = 0;
      const services = [];
      for (let i = 0; i < this.activeDestinationsUsers.length; i++) {
        const destUsers = this.activeDestinationsUsers[i];
        const dest = this.activeDestinations[i];

        let hauled = 0;
        for (const key in destUsers.hauls) {
          const haul = destUsers.hauls[key];
          hauled += haul.haulAmount;
        }
        hauled = round2Dec(hauled);

        const tolerance =
          this.settingsUsers.destinationSettings.projectedTolerance;

        let haulProjected;
        const projected = destUsers.projected;
        if (projected === '') {
          haulProjected = 0;
        } else {
          haulProjected = projected - hauled;
          haulProjected = haulProjected < 0 ? 0 : haulProjected;
          haulProjected = haulProjected <= tolerance ? 0 : haulProjected;
        }

        totalHaulProjected += haulProjected;
        let serviceProjected = haulProjected;

        // Calculate booked services
        if (this.isAdmin) {
          for (let j = 0; j < destUsers.services.length; j++) {
            const service = destUsers.services[j];
            let servicePrice = dest.services[j].price;
            if (service.name !== 'Haul' && service.name !== 'Hauls') {
              if (haulProjected === 0 && !service.complete) {
                serviceProjected = hauled;
                servicePrice += hauled * servicePrice;
              } else if (haulProjected !== 0 && !service.complete) {
                serviceProjected = destUsers.projected;
                servicePrice += destUsers.projected * servicePrice;
              } else if (service.complete) {
                serviceProjected = 0;
              }

              const x = services.findIndex(y => y.name === service.name);

              if (x >= 0) {
                services[x].amount += serviceProjected;
                services[x].price += servicePrice;
              } else {
                services.push({
                  name: service.name,
                  amount: serviceProjected,
                  price: servicePrice
                });
              }
            }
          }
        }
      }

      // What was this for?
      //for (let i = 0; i < this.archivedDestinationsUsers.length; i++) {
      //const destUsers = this.archivedDestinationsUsers[i];

      //let hauled = 0;
      //for (const key in destUsers.hauls) {
      //const haul = destUsers.hauls[key];
      //hauled += haul.haulAmount;
      //}

      //hauled = round2Dec(hauled);
      //const tolerance =
      //this.settingsUsers.destinationSettings.projectedTolerance;

      //let haulProjected;
      //const projected = destUsers.projected;
      //if (projected === '') {
      //haulProjected = 0;
      //} else {
      //haulProjected = projected - hauled;
      //haulProjected = haulProjected < 0 ? 0 : haulProjected;
      //haulProjected = haulProjected <= tolerance ? 0 : haulProjected;
      //}

      //totalHaulProjected += haulProjected;
      //}

      return { toHaul: totalHaulProjected, services };
    },
    commodities() {
      return this.$store.state.settingsUsers.commodities;
    },
    customers() {
      return this.$store.state.customers;
    },
    destAs() {
      return this.$store.state.settingsUsers.destAs;
    },
    guestCustomerId() {
      return this.$store.state.user.guestCustomerId;
    },
    headerDateStart() {
      return date.formatDate(this.dates.start, 'MMM D, YYYY');
    },
    headerDateEnd() {
      return date.formatDate(
        date.subtractFromDate(this.dates.end, { days: 1 }),
        'MMM D, YYYY'
      );
    },
    isAdmin() {
      return this.$store.state.user.superAdmin || this.$store.state.user.admin;
    },
    isCustomer() {
      return this.$store.state.user.customer;
    },
    isGuestCustomer() {
      return (
        this.$store.state.user.superCustomer || this.$store.state.user.customer
      );
    },
    isGuestUser() {
      return this.$store.state.user.guestUser;
    },
    isSuperCustomer() {
      return this.$store.state.user.superCustomer;
    },
    masterDestinations() {
      return JSON.parse(JSON.stringify(this.$store.state.masterDestinations));
    },
    masterSources() {
      return JSON.parse(JSON.stringify(this.$store.state.masterSources));
    },
    measurementUnit() {
      for (const key in this.commodities) {
        if (this.commodities[key].name === this.commoditySelectedArr[0]) {
          return this.commodities[key].measurementUnit;
        }
      }
      return '';
    },
    noTrackHaulTrackers() {
      const noTrackHT = [];

      for (
        let i = 0;
        i < this.settingsUsers.sourceSettings.haulTrackers.length;
        i++
      ) {
        const haulTracker = this.settingsUsers.sourceSettings.haulTrackers[i];
        if (haulTracker.driverInput) {
          noTrackHT.push(haulTracker.name);
        }
      }

      for (
        let i = 0;
        i < this.settingsUsers.destinationSettings.haulTrackers.length;
        i++
      ) {
        const haulTracker =
          this.settingsUsers.destinationSettings.haulTrackers[i];
        if (haulTracker.driverInput || haulTracker.calcInfo.runCalc) {
          noTrackHT.push(haulTracker.name);
        }
      }

      return noTrackHT;
    },
    reportBtnPad() {
      let padding = '5px 110px';
      if (this.$q.screen.lt.sm) {
        padding = '5px 78px';
      }

      return padding;
    },
    settings() {
      return {
        destinationSettings: this.$store.state.destinationSettings,
        sourceSettings: this.$store.state.sourceSettings
      };
    },
    settingsUsers() {
      return this.$store.state.settingsUsers;
    },
    user() {
      return JSON.parse(JSON.stringify(this.$store.state.user));
    },
    userList() {
      return JSON.parse(JSON.stringify(this.$store.state.userList));
    }
  },
  watch: {
    commoditySelectedArr() {
      if (this.reportsCalculated) {
        this.calcReports();
      }
    },
    userList() {
      this.setDriverAuthList();
    }
  }
};
</script>

<style>
/* This cannot be scoped */
#service_val_report .chart-legend {
  display: none;
}
</style>

<style scoped>
.custom-width {
  width: 500px;
}

.report-type-btn {
  padding: 3px 125px 3px 125px;
}

@media all and (max-width: 1405px) {
  .report-type-btn {
    padding: 3px 111px 3px 111px;
  }
}

@media all and (max-width: 355px) {
  .repport-type-btn {
    padding: 3px 92px 3px 92px;
  }
}
</style>
