<template>
  <div style="width: 100%">
    <div
      style="
        text-align: center;
        font-size: 25px;
        color: #0b0d6f;
        margin-bottom: 5px;
      "
    >
      {{ customers[reportData.customerId].name }}
    </div>
    <div
      v-if="!isSource"
      style="
        text-align: center;
        font-size: 25px;
        color: #cc0003;
        margin-bottom: 20px;
      "
    >
      {{ reportData.destinationName }}
    </div>
    <div
      v-if="isSource"
      style="
        text-align: center;
        font-size: 25px;
        color: #cc0003;
        margin-bottom: 20px;
      "
    >
      {{ reportData.sourceName }}
    </div>
    <div
      style="
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 5px;
      "
    >
      Haul Report
    </div>

    <table style="width: 100%">
      <tr>
        <th v-if="showColumn('Date')">Date</th>
        <th v-if="showColumn('Driver')">Driver</th>
        <th v-if="showColumn('Source')">Source</th>
        <th v-if="showColumn(destAs.slice(0, -1))">
          {{ destAs.slice(0, -1) }}
        </th>
        <th
          v-for="(haulTracker, trackerIndex) in haulTrackerHeaders"
          :key="trackerIndex + 150 + haulTracker"
        >
          {{ haulTracker }}
        </th>
        <th v-if="showColumn('Haul Amount')">Haul Amount</th>
      </tr>
      <tr
        v-for="(haul, haulIndex) in reportData.hauls"
        :key="haul.dateTime + (haulIndex + 100)"
      >
        <td v-if="showColumn('Date')">
          {{ new Date(haul.dateTime).toDateString() }}
        </td>
        <td v-if="showColumn('Driver')">{{ users[haul.driver] }}</td>
        <td v-if="showColumn('Source')">
          {{ customers[haul.sourceCustomerId].name }} -
          {{ masterSources[haul.sourceLocationId].sourceName }}
        </td>
        <td v-if="showColumn(destAs.slice(0, -1))">
          {{ customers[haul.destinationCustomerId].name }} -
          {{ masterDestinations[haul.destinationLocationId].destinationName }}
        </td>
        <td
          v-for="(haulTracker, trackerIndex) in haulTrackers[haulIndex]"
          :key="trackerIndex + 200 + haulTracker"
        >
          {{ haulTracker }}
        </td>
        <td v-if="showColumn('Haul Amount')">{{ haul.haulAmount }}</td>
      </tr>
      <tr v-if="showColumn('Total')">
        <td
          :colspan="calcColspan()"
          style="text-align: right; font-weight: bold; font-size: 16px"
        >
          <span class="q-pr-sm">
            Total &nbsp; - &nbsp; {{ reportData.hauled }}
            {{ reportData.measurementUnit }}
          </span>
        </td>
      </tr>
      <tr v-if="showColumn('Projected')">
        <td
          :colspan="calcColspan()"
          style="text-align: right; font-weight: bold; font-size: 16px"
        >
          <span class="q-pr-sm">
            Projected &nbsp; - &nbsp; {{ reportData.projected }}
            {{ reportData.measurementUnit }}
          </span>
        </td>
      </tr>
      <tr v-if="showColumn('Balance')">
        <td
          :colspan="calcColspan()"
          style="text-align: right; font-weight: bold; font-size: 16px"
        >
          <span class="q-pr-sm">
            Balance &nbsp; - &nbsp; {{ reportData.balance }}
            {{ reportData.measurementUnit }}
          </span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'PrintReport',
  props: {
    columnList: {
      type: Array,
      required: true
    },
    customers: {
      type: Object,
      required: true
    },
    destAs: {
      type: String,
      required: true
    },
    haulTrackers: {
      type: Array,
      required: true
    },
    haulTrackerHeaders: {
      type: Array,
      required: true
    },
    isSource: {
      type: Boolean,
      required: true
    },
    reportData: {
      type: Object,
      required: true
    },
    users: {
      type: Object,
      required: true
    }
  },
  methods: {
    calcColspan() {
      return this.haulTrackerHeaders.length + 5;
    },
    showColumn(col) {
      return this.columnList.includes(col);
    }
  },
  computed: {
    masterDestinations() {
      return JSON.parse(JSON.stringify(this.$store.state.masterDestinations));
    },
    masterSources() {
      return JSON.parse(JSON.stringify(this.$store.state.masterSources));
    }
  }
};
</script>

<style scoped></style>
