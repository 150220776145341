<template>
  <div class="div-container q-pt-xl">
    <div class="gt-sm q-py-xl"></div>
    <q-card v-if="loginDisplay" flat bordered class="card-padding shadow-1">
      <q-card-section>
        <q-form>
          <div>
            <q-linear-progress v-if="waiting" query color="primary" />
          </div>
          <q-input
            square
            filled
            clearable
            v-model="form.email"
            type="email"
            label="email"
            label-color="primary"
            color="primary"
            class="q-mb-md"
          />
          <q-input
            square
            filled
            clearable
            v-model="form.password"
            type="password"
            label="password"
            label-color="primary"
            color="primary"
          />
        </q-form>
      </q-card-section>
      <q-card-section
        v-if="form.loginError"
        class="bg-grey-3 q-pa-none q-mx-md"
      >
        <p class="text-center text-custom-red q-pa-xs">
          Email or password is incorrect
        </p>
      </q-card-section>
      <div align="right" class="q-ma-md">
        <q-btn
          color="primary"
          icon="login"
          label="Login"
          class="full-width q-px-md"
          @click="login"
        />
      </div>
      <p class="text-center text-grey-6 q-ma-none">
        Forgot Password?
        <span
          class="text-primary cursor-pointer c-span"
          @click="resetPasswordClick"
          >Reset Password</span
        >
      </p>
    </q-card>

    <q-card
      v-if="!loginDisplay"
      flat
      bordered
      class="card-padding q-pa-lg shadow-1"
    >
      <q-card-section>
        <q-linear-progress
          v-if="waiting"
          query
          color="primary"
          class="q-mt-sm"
        />
        <q-form class="q-gutter-md">
          <q-input
            square
            filled
            clearable
            v-model="form.email"
            type="email"
            label="email"
            label-color="primary"
            color="primary"
          />
        </q-form>
      </q-card-section>
      <q-card-section
        v-if="form.passwordResetError"
        class="bg-grey-3 q-pa-none q-mx-md"
      >
        <p class="text-center text-custom-red q-pa-xs">
          Please enter a valid email
        </p>
      </q-card-section>
      <q-card-section class="q-pa-sm">
        <div class="q-pb-xs">
          <div align="right">
            <q-btn
              outline
              label="Cancel"
              color="primary"
              @click="cancel"
              padding="xs md"
              class="q-mr-md"
            />
            <q-btn
              label="Reset Password"
              color="primary"
              @click="resetPassword"
              padding="xs md"
              class="q-mr-sm"
            />
          </div>
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
export default {
  name: 'LoginForm',
  data() {
    return {
      form: {
        email: '',
        password: '',
        passwordResetError: false,
        loginError: false
      },
      loginDisplay: true,
      waiting: false
    };
  },
  mounted() {
    this.clearLoginForm();
  },
  methods: {
    cancel() {
      this.loginDisplay = true;
    },
    clearLoginForm() {
      this.form.email = '';
      this.form.password = '';
      this.form.loginError = false;
    },
    login() {
      if (
        this.validateEmail(this.form.email) &&
        this.form.password.length >= 6
      ) {
        this.waiting = true;
        this.$emit('loginMethod', this.form);
      } else {
        this.form.loginError = true;
      }
    },
    passwordResetComplete() {
      this.clearLoginForm();
      this.loginDisplay = true;
      this.waiting = false;
    },
    resetPasswordClick() {
      this.loginDisplay = false;
    },
    resetPassword() {
      this.waiting = true;
      if (this.validateEmail(this.form.email)) {
        this.$emit('onPasswordReset', this.form.email);
      } else {
        this.form.passwordResetError = true;
      }
    },
    setError() {
      this.form.loginError = true;
      this.waiting = false;
    },
    validateEmail(email) {
      const emailPattern = /\S+@\S+\.\S+/;
      return emailPattern.test(email);
    }
  }
};
</script>

<style scoped>
/* some global css is applied */

@media all and (max-width: 550px) {
  .card-padding {
    padding: 5px;
  }
}
</style>
