<template>
  <div>
    <Reports />
  </div>
</template>

<script>
import Reports from '@/components/reports/Reports.vue';

export default {
  name: 'MasterReports',
  components: {
    Reports
  },
  created() {
    this.$store.dispatch('setHeaderBtnsDisplay', true);
  }
};
</script>

<style scoped></style>
