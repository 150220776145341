<template>
  <q-dialog v-model="confirmDisplay" persistent>
    <q-card class="q-pa-lg outer-card">
      <div class="q-pa-sm">
        <q-card
          flat
          bordered
          class="q-pa-md text-custom-medium row items-center rounded-borders"
          style="min-width: 250px"
        >
          <div class="row full-width">
            <q-card
              flat
              class="row justify-center items-center full-width bg-grey-3 q-pa-sm"
            >
              <q-icon
                :name="icon"
                :color="iconColor"
                :style="iconStyle"
                size="md"
              />
              <div class="text-bold text-primary q-ml-sm">
                {{ headerMessage }}
              </div>
            </q-card>
          </div>
          <div class="q-py-md full-width text-center">
            <div v-html="message" />
          </div>
        </q-card>
        <div align="right" class="q-mt-md">
          <q-btn
            flat
            label="Cancel"
            icon="clear"
            color="primary"
            padding="xs md"
            class="q-mr-sm"
            @click="cancelClicked"
          />
          <q-btn
            :label="btn.label"
            :icon="btn.icon"
            :color="btn.color"
            padding="xs md"
            @click="confirmClicked"
          />
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  props: {
    headerMessage: {
      type: String,
      required: true
    },
    btn: {
      type: Object,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    iconColor: {
      type: String,
      default: ''
    },
    iconStyle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      confirmDisplay: true,
      confirmUpdate: false,
      displayName: '',
      email: ''
    };
  },
  methods: {
    cancelClicked() {
      this.$emit('canceled');
    },
    confirmClicked() {
      this.$emit('confirmed');
    }
  }
};
</script>

<style scoped>
.outer-card {
  min-width: 280px;
}
</style>
