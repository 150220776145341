<template>
  <div class="row justify-center full-width">
    <ConfirmDialog
      v-if="confirm.display"
      icon="outbound"
      iconColor="custom-red"
      iconStyle="transform: rotate(180deg)"
      :btn="confirm.btn"
      :message="confirm.message"
      :headerMessage="confirm.headerMessage"
      @canceled="confirm.display = false"
      @confirmed="confirmed"
    />

    <OkDialog
      v-if="ok.display"
      :icon="ok.icon"
      :iconColor="ok.iconColor"
      :iconStyle="ok.iconStyle"
      :headerMessage="ok.headerMessage"
      :message="ok.message"
      @okConfirmed="okCancel"
    />

    <DestinationLocations
      v-if="manageLocations"
      ref="destinationLocations"
      :customerId="destination.customerId"
      :destinationLocations="destinationLocations"
      :editView="true"
      @managePin="managePin"
      @reloadLocations="setDestinationLocations"
      @onSelectLocation="onSelectLocation"
      @layerThreeAttachments="layerThreeAttachments"
    />

    <PrintableHaulReport
      v-if="displayPrintable"
      :reportData="destination"
      :isSource="isSource"
      @layerThreeAttachments="layerThreeAttachments"
    />

    <div
      v-show="!manageLocations && !displayPrintable"
      class="row full-width q-pa-md"
    >
      <div class="half-width half-width-mr">
        <q-card flat bordered class="q-mb-md">
          <div class="row justify-center items-center q-py-xs">
            <q-icon name="account_circle" color="primary" size="md" />
            <span class="text-body1 text-primary word-wrap q-ml-sm">
              {{ destination.customer }}
            </span>
          </div>
          <q-separator class="full-width" />

          <div class="q-px-md q-py-md text-body1 text-primary">
            <div class="row items-center">
              <q-icon
                name="location_on"
                color="primary"
                size="md"
                class="q-mr-xs"
              />

              <div v-if="location.customerId">
                {{ location.destinationName }}
              </div>
              <q-space />
              <div class="q-py-xs">
                <q-btn
                  v-if="!location.customerId"
                  label="Set Location"
                  no-caps
                  color="custom-red"
                  padding="2px md"
                  @click="manageDestinations"
                />
                <q-btn
                  v-if="location.customerId && !hasHauls && !mapView"
                  label="Edit Location"
                  no-caps
                  color="custom-orange"
                  padding="2px md"
                  @click="manageDestinations"
                />
                <q-btn
                  v-if="location.customerId && hasHauls"
                  label="Edit Location"
                  no-caps
                  color="custom-light"
                  padding="2px md"
                />
              </div>
            </div>
          </div>
          <q-separator />

          <div class="row items-center q-pa-md">
            <div
              class="row"
              :style="
                $q.screen.lt.sm ? 'width: 100%' : 'width: 49%; margin-right: 2%'
              "
            >
              <div class="row items-center full-width no-wrap ellipsis">
                <div
                  class="row justify-center items-center bg-custom-light rounded-borders text-h5"
                  style="width: 44px; height: 44px; position: relative"
                >
                  <span style="z-index: 2">
                    {{ destination.customer.charAt(0) }}
                  </span>
                  <div
                    class="row items-center justify-center bg-custom-light-blue"
                    :style="`position: absolute; bottom: 0; width: 44px;
                  border-bottom-left-radius: 4px;
                  border-bottom-right-radius: 4px;
                  border-top-right-radius: ${
                    percentHauled === '100%' ? 4 : 0
                  }px;
                  border-top-left-radius: ${percentHauled === '100%' ? 4 : 0}px;
                  border-bottom-top-radius: 4px;
                  height: ${percentHauled}`"
                  ></div>
                </div>

                <div class="ellipsis q-ml-md">
                  {{ Math.round(destination.hauled) || 0 }} /
                  {{ Math.round(projected) || '--' }}
                  {{ destination.measurementUnit }}
                </div>
              </div>
            </div>

            <div
              class="row"
              :class="$q.screen.lt.sm ? 'q-mt-md' : ''"
              :style="$q.screen.lt.sm ? 'width: 100%' : 'width: 49%;'"
            >
              <div class="full-width">
                <q-input
                  outlined
                  v-model.number="projected"
                  label="projected"
                  label-color="custom-light-blue"
                  color="custom-light-blue"
                  type="number"
                  step="any"
                  dense
                />
              </div>
            </div>
          </div>
          <q-separator />

          <div class="row items-center q-pa-md">
            <div
              class="row"
              :class="$q.screen.lt.sm ? 'justify-center' : ''"
              :style="
                $q.screen.lt.sm ? 'width: 100%' : 'width: 49%; margin-right: 2%'
              "
            >
              <q-checkbox
                v-model="chargeCommodity"
                dense
                label="Charge Commodity"
                color="custom-green"
                size="lg"
              />
            </div>
            <div
              class="row half-width"
              :class="$q.screen.lt.sm ? 'q-mt-md' : ''"
              :style="$q.screen.lt.sm ? 'width: 100%' : 'width: 49%;'"
            >
              <div class="full-width">
                <q-input
                  v-model.number="pricePerUnit"
                  outlined
                  dense
                  :label="'Price Per ' + measurementUnit.slice(0, -1)"
                  color="custom-green"
                  label-color="custom-green"
                  mask="#.##"
                  fill-mask="0"
                  reverse-fill-mask
                  type="tel"
                  prefix="$"
                  maxlength="20"
                />
              </div>
            </div>
          </div>
        </q-card>

        <q-card flat class="q-mb-md" style="border: 2px solid #fe9e20">
          <div class="row items-center q-pa-md">
            <div
              class="row justify-center"
              style="width: 49%; margin-right: 2%"
            >
              <div
                class="row justify-center items-center full-width text-bold text-body1 text-custom-orange"
              >
                Notes
                <q-icon
                  v-if="!importantNote"
                  name="edit_note"
                  size="28px"
                  class="q-ml-sm"
                />
                <q-icon
                  v-if="importantNote"
                  name="announcement"
                  size="28px"
                  color="custom-red"
                  class="q-ml-sm"
                />
              </div>
            </div>
            <div class="row" style="width: 49%">
              <div class="row justify-center full-width">
                <q-checkbox
                  v-model="importantNote"
                  dense
                  label="Important Note"
                  color="custom-red"
                  size="lg"
                />
              </div>
            </div>
          </div>

          <div class="row q-px-md">
            <div class="full-width q-mb-md">
              <q-input
                outlined
                v-model="note"
                label="Note"
                label-color="custom-orange"
                color="custom-orange"
                autogrow
                dense
              />
            </div>
            <div class="full-width q-mb-md">
              <q-input
                outlined
                v-model="adminNote"
                label="Admin Note"
                label-color="custom-orange"
                color="custom-orange"
                autogrow
                dense
              />
            </div>
            <div class="row items-center full-width q-mb-sm">
              <q-icon
                name="account_circle"
                color="custom-orange"
                size="sm"
                class="q-mr-xs"
              />
              <span class="text-bold text-custom-orange q-mr-sm">
                Customer Note:
              </span>
              {{ customerNote }}
              <q-separator class="full-width" />
            </div>
            <div class="q-px-sm q-py-xs"></div>
          </div>
        </q-card>

        <q-card flat bordered class="q-mb-md">
          <div class="row justify-center text-body1 q-pa-md q-my-sm">
            <q-toggle
              v-model="readyForSettlement"
              dense
              label="Ready For Settlement"
              color="custom-green"
              size="xl"
              icon="task_alt"
            />
            <q-btn
              round
              flat
              icon="info"
              no-caps
              color="primary"
              size="10px"
              padding="xs"
              class="q-mb-xs q-ml-xs"
              @click="readyForSettlementInfo"
            />
          </div>
          <q-separator />

          <div class="row full-width">
            <div class="half-width-b text-subtitle1">
              <div class="text-center q-pt-sm">
                Status
                <q-btn
                  round
                  flat
                  icon="info"
                  no-caps
                  color="primary"
                  size="10px"
                  padding="xs"
                  class="q-mb-xs q-ml-xs"
                  @click="statusInfo"
                />
              </div>
              <div class="q-px-sm">
                <q-option-group
                  size="sm"
                  :options="destStatus.options"
                  type="radio"
                  v-model="destStatus.model"
                />
              </div>
            </div>
            <div class="half-width-c text-subtitle1">
              <div class="text-center q-pt-sm">Commodity</div>
              <div class="q-px-sm">
                <q-option-group
                  size="sm"
                  :options="commodities"
                  type="radio"
                  v-model="commoditySelected"
                  color="custom-light-blue"
                />
              </div>
            </div>
          </div>
        </q-card>

        <!--this needs to be if so inputs work correctly-->
        <div v-if="topModule" class="row half-width-container">
          <q-card
            flat
            bordered
            v-if="modules.dispatch"
            class="half-width-qc q-mb-sm"
          >
            <div class="row items-center q-py-sm">
              <div class="row full-width justify-center text-subtitle1 q-mb-xs">
                Dispatched To
              </div>
              <div class="q-pl-md">
                <q-option-group
                  dense
                  :options="dispatchedToOptions"
                  v-model="dispatchedTo"
                  type="checkbox"
                  color="custom-light-blue"
                  size="md"
                />
              </div>
            </div>
          </q-card>

          <q-card
            flat
            bordered
            v-if="modules.guestUsers"
            class="half-width-qc q-mb-sm"
          >
            <div class="row items-center q-py-sm">
              <div class="row full-width justify-center text-subtitle1 q-mb-xs">
                <q-checkbox
                  v-model="allowGuestUsers"
                  dense
                  color="primary"
                  label="Allow Guest Users"
                  size="md"
                />
              </div>
              <div class="q-pl-md">
                <q-option-group
                  v-if="allowGuestUsers"
                  dense
                  :options="guestUserOptions"
                  v-model="guestUsersAllowed"
                  type="checkbox"
                  color="custom-red"
                  size="md"
                />
              </div>
            </div>
          </q-card>

          <q-card
            flat
            bordered
            v-if="modules.driverRate"
            class="half-width-qc q-mb-sm"
          >
            <div class="row justify-center items-center q-pt-sm q-pb-md">
              <div class="row full-width justify-center text-subtitle1 q-mb-sm">
                Driver Rate
              </div>
              <div class="full-width q-px-md">
                <q-input
                  v-model.number="driverRate"
                  mask="#.##"
                  fill-mask="0"
                  reverse-fill-mask
                  type="tel"
                  prefix="$"
                  maxlength="20"
                  outlined
                  dense
                  color="primary"
                />
              </div>
            </div>
          </q-card>

          <q-card
            flat
            bordered
            v-if="modules.trackMileage"
            class="half-width-qc q-mb-sm"
          >
            <div class="row justify-center items-center q-pt-xs q-pb-md">
              <div class="row full-width justify-center text-subtitle1">
                <q-checkbox
                  v-model="trackMileage"
                  label="Track Mileage"
                  color="primary"
                  size="md"
                />
              </div>
              <div class="full-width q-px-md">
                <q-input
                  v-model.number="mileageRate"
                  outlined
                  dense
                  label="Rate Per Mile"
                  mask="#.##"
                  fill-mask="0"
                  reverse-fill-mask
                  type="tel"
                  prefix="$"
                  maxlength="20"
                  color="primary"
                />
              </div>
            </div>
          </q-card>
        </div>
      </div>

      <div class="half-width">
        <div class="row half-width-container">
          <q-card
            flat
            bordered
            class="half-width-qc q-px-md q-pt-md q-pb-xs q-mb-md"
          >
            <div>
              <div
                class="row justify-center full-width rounded-borders text-bold text-body1 text-custom-light q-py-xs q-mb-lg"
                style="
                  background: radial-gradient(circle, #3336eb 0%, #0b0d6f 100%);
                "
              >
                Haul Trackers
              </div>

              <div class="row justify-center full-width q-mb-md">
                <div>
                  <div
                    v-for="(tracker, index) in haulTrackers"
                    :key="index + 150"
                  >
                    <q-checkbox
                      v-model="trackerCheckboxes[index]"
                      dense
                      :label="tracker"
                      color="custom-orange"
                      size="lg"
                      class="q-pt-xs"
                    />
                  </div>
                </div>
              </div>
            </div>
          </q-card>

          <q-card flat bordered class="half-width-qc q-pa-md q-mb-md">
            <div>
              <div
                class="row justify-center full-width rounded-borders text-bold text-body1 text-custom-light q-py-xs q-mb-lg"
                style="
                  background: radial-gradient(circle, #3336eb 0%, #0b0d6f 100%);
                "
              >
                Info Boxes
              </div>

              <div
                v-for="(infoBox, index) in infoBoxes"
                :key="index"
                class="q-mb-md"
              >
                <q-input
                  outlined
                  v-model.number="infoBox.model"
                  type="number"
                  step="any"
                  :label="infoBox.name"
                  dense
                  color="primary"
                />
              </div>
            </div>
          </q-card>
        </div>

        <div class="row half-width-container">
          <q-card flat bordered class="half-width-qc q-pa-md q-mb-md">
            <div>
              <div
                class="row justify-center full-width rounded-borders text-bold text-body1 text-custom-light q-py-xs q-mb-lg"
                style="
                  background: radial-gradient(circle, #3336eb 0%, #0b0d6f 100%);
                "
              >
                Services
              </div>
              <div
                v-for="(service, index) in services"
                :key="index + 100"
                class="row justify-center full-width"
              >
                <div class="row items-center full-width q-pb-xs">
                  <q-checkbox
                    v-model="serviceCheckboxes[index]"
                    dense
                    :label="service.name"
                    color="primary"
                    size="lg"
                  />
                </div>

                <div
                  v-if="serviceCheckboxes[index]"
                  class="row items-center full-width q-py-xs"
                >
                  <q-checkbox
                    v-model="services[index].complete"
                    dense
                    :label="services[index].name + ' Complete'"
                    color="custom-red"
                    size="lg"
                    @input="serviceDateChange(services[index], index)"
                  />
                </div>

                <div
                  v-if="
                    serviceCheckboxes[index] && services[index].priceXInfoBox
                  "
                  class="row items-center full-width q-py-xs"
                >
                  <q-checkbox
                    v-model="services[index].useInfoBox"
                    dense
                    :label="
                      'Price Multiplied By - ' +
                      services[index].infoBoxPairedWith
                    "
                    color="custom-orange"
                    size="lg"
                  />
                </div>

                <div v-if="serviceCheckboxes[index]" class="full-width q-mt-xs">
                  <q-input
                    square
                    outlined
                    dense
                    v-model.number="services[index].price"
                    :label="
                      !services[index].priceXInfoBox ||
                      !services[index].useInfoBox
                        ? services[index].name +
                          ' Price Per ' +
                          measurementUnit.slice(0, -1)
                        : services[index].name +
                          ' Price - Multiplied By - ' +
                          services[index].infoBoxPairedWith
                    "
                    mask="#.##"
                    fill-mask="0"
                    reverse-fill-mask
                    type="tel"
                    prefix="$"
                    maxlength="20"
                    color="primary"
                  />
                </div>
                <div v-if="serviceCheckboxes[index]" class="full-width q-mt-sm">
                  <div class="row items-center">
                    <div style="width: 35%">
                      <q-checkbox
                        v-model="services[index].hasDate"
                        dense
                        label="Date"
                        color="custom-light-blue"
                        size="lg"
                      />
                    </div>
                    <div v-if="services[index].hasDate" style="width: 65%">
                      <q-input
                        square
                        outlined
                        dense
                        v-model="services[index].serviceDate"
                        mask="date"
                        :rules="['date']"
                        class="q-ma-none q-pa-none"
                        :class="'bg-' + services[index].dateColor"
                        color="custom-light-blue"
                      >
                        <template v-slot:append>
                          <q-icon
                            name="event"
                            class="cursor-pointer"
                            color="custom-light-blue"
                          >
                            <q-popup-proxy
                              ref="qDateProxy"
                              transition-show="scale"
                              transition-hide="scale"
                            >
                              <q-date
                                v-model="services[index].serviceDate"
                                color="custom-light-blue"
                                @input="
                                  serviceDateChange(services[index], index)
                                "
                              >
                                <div class="row items-center justify-end">
                                  <q-btn
                                    v-close-popup
                                    label="Close"
                                    color="custom-light-blue"
                                    flat
                                  />
                                </div>
                              </q-date>
                            </q-popup-proxy>
                          </q-icon>
                        </template>
                      </q-input>
                    </div>
                  </div>
                </div>
                <q-separator
                  v-if="index !== serviceCheckboxes.length - 1"
                  color="primary"
                  class="q-my-md full-width"
                />
              </div>
            </div>
          </q-card>

          <q-card flat bordered class="half-width-qc q-pa-md q-mb-md">
            <div>
              <div
                class="row justify-center full-width rounded-borders text-bold text-body1 text-custom-light q-py-xs q-mb-md"
                style="
                  background: radial-gradient(circle, #3336eb 0%, #0b0d6f 100%);
                "
              >
                Custom Selectors
              </div>

              <div
                v-for="(customSelector, index) in customSelectors"
                :key="index + 50"
                class="q-pb-xs"
              >
                <div class="row justify-center text-subtitle1 q-pb-xs">
                  {{ customSelector.name }}
                </div>
                <q-option-group
                  size="sm"
                  :options="customSelector.options"
                  type="radio"
                  v-model="customSelector.model"
                  style="padding-left: 9px"
                />
              </div>
            </div>
          </q-card>
        </div>

        <!--this needs to be if so inputs work correctly-->
        <div v-if="!topModule" class="row half-width-container">
          <q-card
            flat
            bordered
            v-if="modules.dispatch"
            class="half-width-qc q-mb-sm"
          >
            <div class="row items-center q-py-sm">
              <div class="row full-width justify-center text-subtitle1 q-mb-xs">
                Dispatched To
              </div>
              <div class="q-pl-md">
                <q-option-group
                  dense
                  :options="dispatchedToOptions"
                  v-model="dispatchedTo"
                  type="checkbox"
                  color="custom-light-blue"
                  size="md"
                />
              </div>
            </div>
          </q-card>

          <q-card
            flat
            bordered
            v-if="modules.guestUsers"
            class="half-width-qc q-mb-sm"
          >
            <div class="row items-center q-py-sm">
              <div class="row full-width justify-center text-subtitle1 q-mb-xs">
                <q-checkbox
                  v-model="allowGuestUsers"
                  dense
                  color="primary"
                  label="Allow Guest Users"
                  size="md"
                />
              </div>
              <div class="q-pl-md">
                <q-option-group
                  v-if="allowGuestUsers"
                  dense
                  :options="guestUserOptions"
                  v-model="guestUsersAllowed"
                  type="checkbox"
                  color="custom-red"
                  size="md"
                />
              </div>
            </div>
          </q-card>

          <q-card
            flat
            bordered
            v-if="modules.driverRate"
            class="half-width-qc q-mb-sm"
          >
            <div class="row justify-center items-center q-pt-sm q-pb-md">
              <div class="row full-width justify-center text-subtitle1 q-mb-sm">
                Driver Rate
              </div>
              <div class="full-width q-px-md">
                <q-input
                  v-model.number="driverRate"
                  mask="#.##"
                  fill-mask="0"
                  reverse-fill-mask
                  type="tel"
                  prefix="$"
                  maxlength="20"
                  outlined
                  dense
                  color="primary"
                />
              </div>
            </div>
          </q-card>

          <q-card
            flat
            bordered
            v-if="modules.trackMileage"
            class="half-width-qc q-mb-sm"
          >
            <div class="row justify-center items-center q-pt-xs q-pb-md">
              <div class="row full-width justify-center text-subtitle1">
                <q-checkbox
                  v-model="trackMileage"
                  label="Track Mileage"
                  color="primary"
                  size="md"
                />
              </div>
              <div class="full-width q-px-md">
                <q-input
                  v-model.number="mileageRate"
                  outlined
                  dense
                  label="Rate Per Mile"
                  mask="#.##"
                  fill-mask="0"
                  reverse-fill-mask
                  type="tel"
                  prefix="$"
                  maxlength="20"
                  color="primary"
                />
              </div>
            </div>
          </q-card>
        </div>

        <q-card flat bordered class="q-pa-md q-py-xs q-mb-md">
          <div
            class="row q-py-xs"
            :class="$q.screen.lt.sm ? 'row justify-center full-width' : ''"
          >
            <q-checkbox
              v-model="alwaysAvailable"
              dense
              color="custom-red"
              :label="`Long Running ${destAs.slice(0, -1)}`"
              class="text-bold q-mb-xs q-mt-xs"
              size="lg"
            />
            <q-btn
              round
              flat
              icon="info"
              no-caps
              color="primary"
              size="12px"
              padding="xs"
              class="q-mb-xs"
              @click="alwaysOpenMessage"
            />
          </div>
          <q-separator />

          <div align="right" class="text-bold q-py-sm">
            <span class="q-mr-lg">Total Charge</span>
            {{ formatPrice(Math.abs(totalPrice)) }}
          </div>
          <q-separator />

          <div v-if="!mapView" class="row q-mt-md">
            <q-space />
            <q-btn
              v-if="location.customerId && destination.hauls"
              label="Printable Report"
              no-caps
              color="primary"
              padding="2px md"
              @click="onManagePrintable"
            />
            <q-btn
              v-if="!location.customerId || !destination.hauls"
              label="Printable Report"
              no-caps
              color="grey-5"
              padding="2px md"
            />
          </div>
        </q-card>
      </div>
    </div>
  </div>
</template>

<script>
import { date } from 'quasar';
import { formatPrice } from '@/utils/UtilHelpers';
import firebase from 'firebase/compat/app';
import DestinationLocations from '@/components/destinations/DestinationLocations.vue';
import PrintableHaulReport from '@/components/PrintableHaulReport.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import OkDialog from '@/components/OkDialog.vue';
import { round2Dec, dateTripColor } from '@/utils/UtilHelpers.js';

const db = firebase.firestore();

export default {
  name: 'EditDestination',
  components: {
    DestinationLocations,
    PrintableHaulReport,
    ConfirmDialog,
    OkDialog
  },
  props: {
    destination: {
      type: Object,
      required: true
    },
    mapView: {
      type: Boolean,
      default: false,
      required: false
    },
    settings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      allowGuestUsers: false,
      actionBtnLabel: 'Add',
      actionBtnIcon: 'add',
      adminNote: '',
      alwaysAvailable: false,
      chargeCommodity: true,
      commoditySelected: null,
      confirm: {
        action: '',
        btn: {
          label: '',
          icon: '',
          color: ''
        },
        display: false,
        message: '',
        headerMessage: ''
      },
      customer: '',
      customerNote: '',
      customSelectors: [],
      dataOnMount: {},
      date: date,
      destinationLocations: [],
      destinationLocationsIndex: 0,
      destLocationsMapPin: {},
      destStatus: {
        options: [],
        model: 'Open'
      },
      dispatchedTo: [],
      dispatchedToOptions: [],
      displayPrintable: false,
      driverRate: '0.00',
      edit: false,
      formatPrice,
      guestUserOptions: [],
      guestUsersAllowed: [],
      hasHauls: true,
      hasPin: false,
      hauled: 0,
      importantNote: false,
      infoBoxes: [],
      isSource: false,
      location: {
        customerId: null,
        lat: null,
        lng: null
      },
      manageLocations: false,
      mileageRate: 0,
      note: '',
      ok: {
        display: false,
        icon: 'outbound',
        iconColor: 'custom-red',
        iconStyle: 'transform: rotate(180deg)',
        headerMessage: '',
        message: ''
      },
      pricePerUnit: 0,
      projected: '',
      readyForSettlement: false,
      readyForSettlementOnMount: false,
      services: [],
      serviceCheckboxes: [],
      setPinAfterSave: false,
      startDate: null,
      statusOnMount: 'Later',
      haulTrackers: [],
      trackerCheckboxes: [],
      trackMileage: false,
      topModule: true,
      settlementReports: [],
      sortedHaulTrackers: [],
      updateId: 0,
      useDestLocationsMapPin: false
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.setDestinationLocations();
    this.setDestinationSettings();
    this.importantMessage();
    this.setModulePosition();

    if (!this.location.customerId) {
      this.manageDestinations();
    }

    this.$nextTick(() => {
      window.addEventListener('resize', this.setModulePosition);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setModulePosition);
  },
  methods: {
    alwaysOpenMessage() {
      this.ok = {
        display: true,
        icon: 'info',
        iconColor: 'primary',
        iconStyle: '',
        headerMessage: 'Long Running Information',
        message: `<li>This setting is recommended for ${
          this.destAs
        } that will be left open
          for months, especially if many hauls will be hauled
          from this <span class="text-lowercase">${this.destAs.slice(0, -1)}.
          </span> </li><br>
          <li>${this.destAs} set to this setting will be automatically
          archived at the end of the month,
          preserving all of the hauls.
          A new matching <span class="text-lowercase">
          ${this.destAs.slice(0, -1)} </span> will be opened with
          a new Start Date and no hauls recorded. </li><br>
          <li>A <span class="text-lowercase">${this.destAs.slice(0, -1)}
          </span>could eventually run out
          of space to hold more hauls. This will prevent
          any issues with running out of cloud space.
          This setting will also keep your haul list shorter on the hauls page.</li>`
      };
    },
    cancel() {
      this.$emit('onSubmit');
    },
    cancelLayerThreeAttachment() {
      this.$refs.destinationLocations.cancelLayerThreeAttachment();
    },
    cancelLayerTwoInputs() {
      if (this.destination.action === 'edit') {
        this.location = this.masterDestinations[this.destination.locationId];
      }
      this.manageLocations = false;
      this.displayPrintable = false;
    },
    completed() {
      this.readyForSettlement = true;

      for (let i = 0; i < this.services.length; i++) {
        this.services[i].complete = true;
      }

      this.$q.notify({
        color: 'custom-green',
        textColor: 'white',
        message: 'Ready For Settlement Checked',
        caption: 'All Services Marked Complete'
      });
    },
    confirmAction(action) {
      if (action === 'delete') {
        this.confirm.action = 'delete';
        this.confirm.btn = {
          label: 'Delete',
          icon: 'delete',
          color: 'custom-red'
        };
        this.confirm.message = `Delete ${this.destination.destinationName}?`;
        this.confirm.headerMessage = this.destination.customer;
        this.confirm.display = true;
      } else if (action === 'haulTrackersChanged') {
        this.confirm.action = 'haulTrackersChanged';
        this.confirm.btn = {
          label: 'Ok',
          icon: 'check',
          color: 'primary'
        };
        this.confirm.message = `Haul Tracker change after haul entered!
          This could affect your Haul Tracker totals
          for this ${this.destAs.slice(0, -1)}.`;
        this.confirm.headerMessage = this.destination.customer;
        this.confirm.display = true;
      } else if (action === 'trackMileageChanged') {
        this.confirm.action = 'trackMileageChanged';
        this.confirm.btn = {
          label: 'Ok',
          icon: 'check',
          color: 'primary'
        };
        this.confirm.message = `Mileage change after haul entered!
          This could affect your Mileage totals for
          this ${this.destAs.slice(0, -1)}.`;
        this.confirm.headerMessage = this.destination.customer;
        this.confirm.display = true;
      } else if (action === 'laterHasHauls') {
        this.confirm.action = 'laterHasHauls';
        this.confirm.btn = {
          label: 'Set To Later',
          icon: 'check',
          color: 'primary'
        };
        this.confirm.message = `This source already has hauls! Setting Status to Later will prevent
          hauls from showing up in haul lists or reports!`;
        this.confirm.headerMessage = this.destination.customer;
        this.confirm.display = true;
      } else if (action === 'saveBeforeSettingPin') {
        this.confirm.action = 'saveBeforeSettingPin';
        this.confirm.btn = {
          label: 'Save',
          icon: 'check',
          color: 'primary'
        };
        this.confirm.message = `Save changes before transfer to map page?`;
        this.confirm.headerMessage = this.destination.customer;
        this.confirm.display = true;
      }
    },
    confirmed() {
      if (this.confirm.action === 'delete') {
        this.onDelete();
      } else if (this.confirm.action === 'haulTrackersChanged') {
        this.onVerifySecond();
      } else if (this.confirm.action === 'trackMileageChanged') {
        this.onEnterDestination();
      } else if (this.confirm.action === 'laterHasHauls') {
        this.onEnterSource();
      } else if (this.confirm.action === 'saveBeforeSettingPin') {
        this.setPinAfterSave = true;
        this.onVerifyFirst();
      }
    },
    importantMessage() {
      if (this.importantNote) {
        const importantNoteIds = this.$store.state.importantNoteIds;
        let spamUser = true;
        for (let i = 0; i < importantNoteIds.length; i++) {
          if (importantNoteIds[i].id === this.destination.id) {
            const tsFiveMinAgo = +new Date() - 300000;
            if (tsFiveMinAgo < importantNoteIds[i].ts) {
              spamUser = false;
            }
          }
        }
        if (spamUser && this.destination.note.length !== 0) {
          this.$store.dispatch('setImportantNoteIds', {
            id: this.destination.id,
            ts: +new Date()
          });

          this.ok = {
            display: true,
            icon: 'announcement',
            iconColor: 'custom-red',
            iconStyle: '',
            headerMessage: 'Important Note',
            message: this.note
          };
        }
      }

      // this is the last onMount function so we need to set dataOnMount
      this.$nextTick(() => {
        this.dataOnMount = JSON.parse(JSON.stringify(this.$data));
      });
    },
    layerThreeAttachments() {
      this.$emit('layerThreeAttachments');
    },
    managePin(mapPin = {}) {
      if (
        !this.destination.hauls &&
        !this.setPinAfterSave &&
        !mapPin.fromDestLocations
      ) {
        this.confirmAction('saveBeforeSettingPin');
        return;
      } else {
        for (const key in this.dataOnMount) {
          if (
            key !== 'dataOnMount' &&
            key !== 'ok' &&
            key !== 'confirm' &&
            key !== 'manageLocations'
          ) {
            if (
              JSON.stringify(this.dataOnMount[key]) !==
              JSON.stringify(this.$data[key])
            ) {
              if (mapPin.fromDestLocations) {
                this.useDestLocationsMapPin = true;
                this.destLocationsMapPin = mapPin;
              }

              this.confirmAction('saveBeforeSettingPin');

              return;
            }
          }
        }
      }

      const id = this.location.id;

      if (!mapPin.fromDestLocations) {
        mapPin = {
          type: 'destination',
          id: id
        };
      }

      if (this.useDestLocationsMapPin) {
        mapPin = this.destLocationsMapPin;
      }

      this.$store.dispatch('setMapPin', mapPin);
      this.$router.push({ path: 'map' });
    },
    manageDestinations() {
      this.manageLocations = true;
      if (this.mapView) {
        this.$emit('layerThreeLocations', true);
      } else {
        this.$emit('layerTwoLocations');
      }
    },
    okCancel() {
      this.ok = {
        display: false,
        icon: 'outbound',
        iconColor: 'custom-red',
        iconStyle: 'transform: rotate(180deg)',
        headerMessage: '',
        message: ''
      };
    },
    onDelete() {
      const id = this.destination.id;

      const batch = db.batch();

      const destinationDoc = db.collection('destinations_active').doc(id);
      const destinationUsersDoc = db
        .collection('destinations_active_users')
        .doc(id);

      batch.delete(destinationDoc);
      batch.delete(destinationUsersDoc);

      batch.commit().catch(err => {
        console.log(err);
      });

      this.confirm.display = false;
      this.$q.notify({
        color: 'custom-red',
        textColor: 'white',
        message: this.destination.destinationName + ' Deleted'
      });

      this.$emit('onSubmit');
    },
    onEnterDestination() {
      if (!this.location.customerId) {
        this.ok.message = 'Please Set Location!';
        this.ok.display = true;
        return;
      }

      if (
        (this.destStatus.model === 'Open' ||
          this.destStatus.model === 'Waiting') &&
        !this.mapView &&
        this.statusOnMount === 'Estimate'
      ) {
        for (let i = 0; i < this.activeDestinations.length; i++) {
          const destination = this.activeDestinations[i];
          if (
            destination.customerId === this.location.customerId &&
            destination.destinationLocationId === this.location.id
          ) {
            this.ok.message = `${this.location.destinationName} is already open!
              Cannot Open two instances of the same ${this.destAs.slice(
                0,
                -1
              )}. (multiple instances can be opened if status is set to Later or Estimate)`;
            this.ok.display = true;
            return;
          }
        }
      }

      if (this.commoditySelected === 'None') {
        this.ok.message = 'Please Select Commodity';
        this.ok.display = true;
        return false;
      }

      let hauls = {};

      if (this.destination.action === 'edit') {
        hauls = this.destination.haulsObj;
      }

      const userSettings = this.userDestinationSettings;
      let totalAsHaulAmount = false;
      for (let i = 0; i < userSettings.haulTrackers.length; i++) {
        const tracker = userSettings.haulTrackers[i];
        for (let j = 0; j < this.sortedHaulTrackers.length; j++) {
          if (tracker.name === this.sortedHaulTrackers[j]) {
            if (totalAsHaulAmount && tracker.calcInfo.calcTotalAsHaulAmount) {
              this.ok.message =
                'Cannot apply more than one Haul Tracker that applies calculated total as haul amount!';
              this.ok.display = true;
              return false;
            }
            if (tracker.calcInfo.calcTotalAsHaulAmount) {
              totalAsHaulAmount = true;
            }
          }
        }
      }

      // set end date if haul complete checked on this edit
      let endDate = null;
      if (
        this.destination.action === 'edit' &&
        this.readyForSettlementOnMount === false &&
        this.readyForSettlement === true
      ) {
        endDate = +new Date();
      } else if (
        this.destination.action === 'edit' &&
        this.readyForSettlementOnMount === true &&
        this.readyForSettlement === true
      ) {
        endDate = this.destination.endDate;
      }

      let updateId = 0;
      if (this.destination.action === 'edit') {
        updateId = this.updateId + 1;
      }

      const userInfoBoxes = [];
      for (let i = 0; i < this.infoBoxes.length; i++) {
        const infoBox = this.infoBoxes[i];
        if (infoBox.userAuth) {
          userInfoBoxes.push(infoBox);
        }
      }

      const userCustomSelectors = [];
      const customSelectors = [];
      for (let i = 0; i < this.customSelectors.length; i++) {
        const customSelector = this.customSelectors[i];

        customSelectors.push({
          name: customSelector.name,
          model: customSelector.model
        });

        if (customSelector.userAuth) {
          userCustomSelectors.push({
            name: customSelector.name,
            model: customSelector.model
          });
        }
      }

      const services = [];
      const userServices = [];
      for (let i = 0; i < this.services.length; i++) {
        if (this.serviceCheckboxes[i] === true) {
          const serviceObj = {
            name: this.services[i].name,
            price: parseFloat(this.services[i].price)
          };
          if (this.services[i].priceXInfoBox) {
            serviceObj.infoBoxPairedWith = this.services[i].infoBoxPairedWith;
            serviceObj.useInfoBox = this.services[i].useInfoBox;
          }
          services.push(serviceObj);

          let completeTs = null;
          if (this.services[i].completeOnMount && this.services[i].complete) {
            completeTs = this.services[i].completeTs;
          } else if (
            !this.services[i].completeOnMount &&
            this.services[i].complete
          ) {
            completeTs = +new Date();
          } else if (!this.services[i].complete) {
            completeTs = null;
          }

          const userServiceObj = {
            name: this.services[i].name,
            complete: this.services[i].complete,
            completeTs: completeTs
          };
          if (this.services[i].hasDate) {
            if (!/^\d{4}\/\d{2}\/\d{2}$/.test(this.services[i].serviceDate)) {
              this.ok.message = 'Invalid Service Date';
              this.ok.icon = 'today';
              this.ok.iconStyle = '';
              this.ok.display = true;
              return false;
            }

            userServiceObj.hasDate = this.services[i].hasDate;
            userServiceObj.serviceTs = +new Date(this.services[i].serviceDate);
          }
          userServices.push(userServiceObj);
        }
      }

      const guestUsers = [];
      for (let i = 0; i < this.guestUsersAllowed.length; i++) {
        guestUsers.push(
          this.guestUserList.guestUsers[this.guestUsersAllowed[i]]
        );
      }

      let destinationDoc;
      if (this.destination.action === 'edit') {
        destinationDoc = db
          .collection('destinations_active')
          .doc(this.destination.id);
      } else {
        destinationDoc = db.collection('destinations_active').doc();
      }

      const destinationUsersDoc = db
        .collection('destinations_active_users')
        .doc(destinationDoc.id);

      const destination = {
        id: destinationDoc.id,
        customerId: this.destination.customerId,
        updateId,
        destinationLocationId: this.location.id,
        startDate: this.startDate,
        endDate,
        settlementDate: null,
        chargeCommodity: this.chargeCommodity,
        pricePerUnit: parseFloat(this.pricePerUnit),
        mileageRate: parseFloat(this.mileageRate),
        services,
        haulTrackers: this.sortedHaulTrackers,
        adminNote: this.adminNote,
        settlementReports: this.settlementReports,
        infoBoxes: this.infoBoxes,
        customSelectors,
        destinationStatus: this.destStatus.model
      };

      const destinationUsers = {
        id: destinationDoc.id,
        updateId,
        customerId: this.destination.customerId,
        destinationLocationId: this.location.id,
        startDate: this.startDate,
        endDate,
        commodity: this.commoditySelected,
        projected: this.projected,
        trackMileage: this.trackMileage,
        services: userServices,
        haulTrackers: this.sortedHaulTrackers,
        note: this.note,
        hauls,
        settlementDate: null,
        userInfoBoxes,
        userCustomSelectors,
        destinationStatus: this.destStatus.model,
        allowGuestUsers: this.allowGuestUsers,
        guestUsers,
        driverRate: parseFloat(this.driverRate),
        alwaysAvailable: this.alwaysAvailable,
        importantNote: this.importantNote,
        dispatchedTo: this.dispatchedTo
      };

      const notify = message => {
        this.$q.notify({
          color: 'primary',
          textColor: 'white',
          message: message
        });
      };

      const batch = db.batch();

      batch.set(destinationDoc, destination);
      batch.set(destinationUsersDoc, destinationUsers);

      batch.commit().catch(err => {
        console.log(err);
      });

      if (this.destination.action === 'edit') {
        notify(`${this.location.destinationName} Updated`);
      } else {
        notify(`${this.location.destinationName} Added`);
      }

      if (this.setPinAfterSave) {
        this.dataOnMount = JSON.parse(JSON.stringify(this.$data));
        this.managePin();
      } else {
        if (this.destination.action === 'add') {
          this.$emit('onAddSubmit', { ...destination, ...destinationUsers });
        } else {
          this.$emit('onSubmit');
        }
      }
    },
    onManagePrintable() {
      this.displayPrintable = true;
      this.$emit('layerTwoAttachments');
    },
    onSelectLocation(index) {
      this.location = this.destinationLocations[index];
      this.manageLocations = false;
      this.$emit('setLocation');
    },
    onVerifyFirst() {
      // Do this regardless
      const haulTrackers = [];
      for (let i = 0; i < this.haulTrackers.length; i++) {
        if (this.trackerCheckboxes[i] === true) {
          haulTrackers.push(this.haulTrackers[i]);
        }
      }
      haulTrackers.sort();
      this.sortedHaulTrackers = haulTrackers;

      if (this.destination.action === 'add') {
        this.onEnterDestination();
        return;
      }

      // If edit do the checks
      if (this.destination.hauls.length && this.destStatus.model === 'Later') {
        this.confirmAction('laterHasHauls');
        return;
      }

      const beforeEditTrackers = JSON.parse(
        JSON.stringify(this.destination.haulTrackerArr)
      );

      if (this.destination.hauls.length > 0) {
        beforeEditTrackers.sort();

        let haulTrackersChanged = false;
        if (haulTrackers.length !== beforeEditTrackers.length) {
          haulTrackersChanged = true;
        } else {
          for (let i = 0; i < haulTrackers.length; i++) {
            if (haulTrackers[i] !== beforeEditTrackers[i]) {
              haulTrackersChanged = true;
              break;
            }
          }
        }
        if (haulTrackersChanged) {
          this.confirmAction('haulTrackersChanged');
        } else {
          this.onVerifySecond();
        }
      } else {
        this.onEnterDestination();
      }
    },
    onVerifySecond() {
      if (this.trackMileage !== this.destination.trackMileage) {
        this.confirmAction('trackMileageChanged');
      } else {
        this.onEnterDestination();
      }
    },
    readyForSettlementInfo() {
      this.ok = {
        display: true,
        icon: 'info',
        iconColor: 'primary',
        iconStyle: '',
        headerMessage: 'Ready For Settlement Info',
        message: `<li>This optional setting is recommended for
          ${this.destAs} that are
          complete and ready to invoice. </li><br>
          <li>${this.destAs} marked Ready For Settlement
          will be removed from the Hauls Page
          / Enter Haul options.  </li><br>
          <li><b>Hauls cannot be edited when the
          ${this.destAs.slice(0, -1).toLowerCase()} is marked
          Ready For Settlement!</b> To edit a haul turn this setting off.  </li>`
      };
    },
    round(num) {
      return round2Dec(num);
    },
    serviceDateChange(service, index) {
      const serviceTs = +new Date(service.serviceDate);
      const newVal = JSON.parse(JSON.stringify(this.services[index]));
      newVal.dateColor = service.complete ? '' : this.setDateColor(serviceTs);

      this.services.splice(index, 1, newVal);
    },
    setDateColor(serviceTs) {
      return !serviceTs
        ? ''
        : dateTripColor(this.serviceTripDates, serviceTs).color;
    },
    setDestinationLocations() {
      this.destinationLocations = [];

      for (const key in this.masterDestinations) {
        if (
          this.masterDestinations[key].customerId ===
          this.destination.customerId
        ) {
          let isActive = false;
          for (let i = 0; i < this.activeDestinations.length; i++) {
            const dest = this.activeDestinations[i];
            if (
              dest.customerId === this.destination.customerId &&
              dest.destinationLocationId === key
            ) {
              isActive = true;
              break;
            }
          }

          this.destinationLocations.push({
            ...this.masterDestinations[key],
            id: key,
            isActive: isActive
          });
        }
      }

      const sortByAlpha = (a, b) => {
        if (a.destinationName < b.destinationName) {
          return -1;
        }
        if (a.destinationName > b.destinationName) {
          return 1;
        }
        return 0;
      };
      this.destinationLocations.sort(sortByAlpha);
    },
    setDestinationSettings() {
      if (!this.destination) {
        return;
      }
      const destination = JSON.parse(JSON.stringify(this.destination));
      const settings = JSON.parse(JSON.stringify(this.settings));
      const userSettings = this.userDestinationSettings;

      const userList = JSON.parse(JSON.stringify(this.$store.state.userList));

      for (let [key, value] of Object.entries(userList.users)) {
        this.dispatchedToOptions.push({ label: key, value });
      }

      for (const user in this.guestUserList.guestUsers) {
        this.guestUserOptions.push({ label: user, value: user });
      }

      this.destStatus.options = [];
      this.destStatus.options.push(
        { label: 'Open', value: 'Open', color: 'custom-green' },
        { label: 'Waiting', value: 'Waiting', color: 'custom-red' }
      );

      if (destination.action === 'edit') {
        if (!this.destination.hauls.length) {
          this.destStatus.options.push({
            label: 'Estimate',
            value: 'Estimate',
            color: 'custom-light-blue'
          });
        }

        this.location = this.masterDestinations[destination.locationId];
        this.location.id = destination.locationId;
        this.updateId = destination.updateId;
        this.projected = destination.projected;
        this.startDate = destination.startDate;
        this.commoditySelected = destination.commodity;
        this.destStatus.model = destination.destinationStatus;
        this.chargeCommodity = destination.chargeCommodity;
        this.trackMileage = destination.trackMileage;
        this.mileageRate = destination.mileageRate.toFixed(2);
        this.pricePerUnit = destination.pricePerUnit.toFixed(2);
        this.note = destination.note;
        this.adminNote = destination.adminNote;
        this.driverRate = destination.driverRate.toFixed(2);
        this.settlementReports = destination.settlementReports;
        this.customerNote = destination.customerNote;
        this.edit = true;
        this.actionBtnLabel = 'Update';
        this.actionBtnIcon = 'check';
        this.readyForSettlement = destination.readyForSettlement;
        this.readyForSettlementOnMount = destination.readyForSettlement;
        this.allowGuestUsers = destination.allowGuestUsers;
        this.alwaysAvailable = destination.alwaysAvailable;
        this.importantNote = destination.importantNote;
        this.dispatchedTo = destination.dispatchedTo;
        this.hauled = destination.hauled;

        this.statusOnMount = destination.destinationStatus;

        if (!destination.hauls.length > 0) {
          this.hasHauls = false;
        }

        this.infoBoxes = [];
        for (let i = 0; i < userSettings.infoBoxes.length; i++) {
          const infoBox = userSettings.infoBoxes[i];

          let model = null;
          for (let j = 0; j < destination.infoBoxes.length; j++) {
            const destInfoBox = destination.infoBoxes[j];

            if (infoBox.name === destInfoBox.name) {
              model = destInfoBox.model;
            }
          }

          this.infoBoxes.push({
            name: infoBox.name,
            model: model,
            userAuth: infoBox.userAuth
          });
        }

        this.customSelectors = [];
        for (let i = 0; i < userSettings.customSelectors.length; i++) {
          const customSelector = userSettings.customSelectors[i];
          const options = [];

          for (let j = 0; j < customSelector.options.length; j++) {
            const option = customSelector.options[j];

            options.push({
              label: option.option,
              value: option.option,
              color: option.optionColor
            });
          }

          let model = customSelector.options[0].option;

          for (let j = 0; j < destination.customSelectors.length; j++) {
            const selector = destination.customSelectors[j];
            if (selector.name === customSelector.name) {
              model = selector.model;
            }
          }

          this.customSelectors.push({
            name: customSelector.name,
            options: options,
            model: model,
            userAuth: customSelector.userAuth
          });
        }

        this.services = [];
        for (let i = 0; i < settings.services.length; i++) {
          let serviceIncluded = false;
          for (let j = 0; j < destination.services.length; j++) {
            if (settings.services[i].name === destination.services[j].name) {
              serviceIncluded = true;

              let priceXInfoBox = false;
              let useInfoBox = false;
              let infoBoxPairedWith = '';
              let hasDate = false;
              let serviceTs = null;
              let serviceDate = null;

              // If pair with info box
              if (
                Object.prototype.hasOwnProperty.call(
                  destination.services[j],
                  'infoBoxPairedWith'
                )
              ) {
                priceXInfoBox = true;
                useInfoBox = destination.services[j].useInfoBox;
                infoBoxPairedWith = destination.services[j].infoBoxPairedWith;
              }

              if (
                Object.prototype.hasOwnProperty.call(
                  destination.services[j],
                  'hasDate'
                )
              ) {
                hasDate = true;
                serviceTs = destination.services[j].serviceTs;
                serviceDate = date.formatDate(
                  destination.services[j].serviceTs,
                  'YYYY/MM/DD'
                );
              }

              // If Settings have been updated and pair with info box has been added
              // the option to pair with info box needs to be added here
              if (!priceXInfoBox && settings.services[i].priceXInfoBox) {
                priceXInfoBox = true;
                useInfoBox = false;
                infoBoxPairedWith = settings.services[i].infoBoxPairedWith;
              }

              this.serviceCheckboxes.push(true);
              this.services.push({
                name: destination.services[j].name,
                price: destination.services[j].price.toFixed(2),
                complete: destination.services[j].complete,
                completeOnMount: destination.services[j].complete,
                completeTs: destination.services[j].completeTs,
                priceXInfoBox,
                useInfoBox,
                infoBoxPairedWith,
                hasDate,
                serviceTs,
                serviceDate,
                dateColor: this.setDateColor(serviceTs)
              });
            }
          }
          if (serviceIncluded === false) {
            this.serviceCheckboxes.push(false);
            this.services.push({
              name: settings.services[i].name,
              price: settings.services[i].defaultPrice.toFixed(2),
              complete: false,
              completeOnMount: false,
              completeTs: null,
              priceXInfoBox: settings.services[i].priceXInfoBox,
              useInfoBox: false,
              infoBoxPairedWith: settings.services[i].infoBoxPairedWith,
              hasDate: false,
              serviceTs: null,
              serviceDate: null,
              dateColor: ''
            });
          }
        }

        // If service has been deleted from settings, we still want to add it here
        for (let i = 0; i < destination.services.length; i++) {
          let match = false;
          for (let j = 0; j < this.services.length; j++) {
            if (destination.services[i].name === this.services[j].name) {
              match = true;
            }
          }
          if (!match) {
            let priceXInfoBox = false;
            let useInfoBox = false;
            let infoBoxPairedWith = '';

            let hasDate = false;
            let serviceTs = null;
            let serviceDate = null;

            // If pair with info box
            if (
              Object.prototype.hasOwnProperty.call(
                destination.services[i],
                'infoBoxPairedWith'
              )
            ) {
              priceXInfoBox = true;
              useInfoBox = destination.services[i].useInfoBox;
              infoBoxPairedWith = destination.services[i].infoBoxPairedWith;
            }

            if (
              Object.prototype.hasOwnProperty.call(
                destination.services[i],
                'hasDate'
              )
            ) {
              hasDate = true;
              serviceTs = destination.services[i].serviceTs;
              serviceDate = date.formatDate(
                destination.services[i].serviceTs,
                'YYYY/MM/DD'
              );
            }

            this.serviceCheckboxes.push(true);
            this.services.push({
              name: destination.services[i].name,
              price: destination.services[i].price.toFixed(2),
              complete: destination.services[i].complete,
              completeOnMount: destination.services[i].complete,
              completeTs: destination.services[i].completeTs,
              priceXInfoBox,
              useInfoBox,
              infoBoxPairedWith,
              hasDate,
              serviceTs,
              serviceDate,
              dateColor: this.setDateColor(serviceTs)
            });
          }
        }

        this.haulTrackers = [];
        for (let i = 0; i < userSettings.haulTrackers.length; i++) {
          let trackerIncluded = false;
          for (let j = 0; j < destination.haulTrackerArr.length; j++) {
            if (
              userSettings.haulTrackers[i].name ===
              destination.haulTrackerArr[j]
            ) {
              trackerIncluded = true;
              this.trackerCheckboxes.push(true);
              this.haulTrackers.push(userSettings.haulTrackers[i].name);
            }
          }
          if (trackerIncluded === false) {
            this.trackerCheckboxes.push(false);
            this.haulTrackers.push(userSettings.haulTrackers[i].name);
          }
        }

        for (let i = 0; i < destination.guestUsers.length; i++) {
          if (
            Object.prototype.hasOwnProperty.call(
              this.guestUserList.guestUserIds,
              destination.guestUsers[i]
            )
          ) {
            this.guestUsersAllowed.push(
              this.guestUserList.guestUserIds[destination.guestUsers[i]]
            );
          }
        }
      } else if (destination.action === 'add') {
        this.destStatus.options.push({
          label: 'Estimate',
          value: 'Estimate',
          color: 'purple-13'
        });

        this.hasHauls = false;
        this.pricePerUnit = settings.defaultPricePerUnit.toFixed(2);
        this.commoditySelected = settings.defaultCommodity;
        this.chargeCommodity = settings.defaultChargeCommodity;
        this.trackMileage = settings.defaultTrackMileage;
        this.mileageRate = settings.defaultMileageRate.toFixed(2);

        this.startDate = +new Date(new Date().setHours(0, 0, 0, 0));

        this.infoBoxes = [];

        for (
          let i = 0;
          i < this.userDestinationSettings.infoBoxes.length;
          i++
        ) {
          const infoBox = this.userDestinationSettings.infoBoxes[i];
          this.infoBoxes.push({
            name: infoBox.name,
            model: null,
            userAuth: infoBox.userAuth
          });
        }

        this.customSelectors = [];
        // Custom selector templates are stored in userDestinationSettings
        for (
          let i = 0;
          i < this.userDestinationSettings.customSelectors.length;
          i++
        ) {
          const customSelector =
            this.userDestinationSettings.customSelectors[i];
          const options = [];

          for (let j = 0; j < customSelector.options.length; j++) {
            const option = customSelector.options[j];

            options.push({
              label: option.option,
              value: option.option,
              color: option.optionColor
            });
          }

          this.customSelectors.push({
            name: customSelector.name,
            options: options,
            model: customSelector.options[0].option,
            userAuth: customSelector.userAuth
          });
        }

        this.services = [];
        for (let i = 0; i < settings.services.length; i++) {
          if (settings.services[i].defaultChecked === true) {
            this.serviceCheckboxes.push(true);
          } else {
            this.serviceCheckboxes.push(false);
          }

          this.services.push({
            name: settings.services[i].name,
            price: settings.services[i].defaultPrice.toFixed(2),
            complete: false,
            completeOnMount: false,
            completeTs: null,
            priceXInfoBox: settings.services[i].priceXInfoBox,
            useInfoBox: false,
            infoBoxPairedWith: settings.services[i].infoBoxPairedWith,
            hasDate: false,
            serviceTs: null,
            serviceDate: null
          });
        }

        this.haulTrackers = [];
        for (let i = 0; i < userSettings.haulTrackers.length; i++) {
          if (userSettings.haulTrackers[i].defaultChecked === true) {
            this.trackerCheckboxes.push(true);
          } else {
            this.trackerCheckboxes.push(false);
          }
          this.haulTrackers.push(userSettings.haulTrackers[i].name);
        }
      }
    },
    setModulePosition() {
      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      const topModule =
        width > 1260 ? true : width > 1008 ? false : width > 964 ? true : false;
      this.topModule = topModule;
    },
    statusInfo() {
      this.ok = {
        display: true,
        icon: 'info',
        iconColor: 'primary',
        iconStyle: '',
        headerMessage: 'Status Information',
        message: `<li>Open - ${this.destAs.slice(0, -1)}
          is currently active. </li><br>
          <li>Waiting - ${this.destAs.slice(0, -1)} is waiting to
          become active. A good way to keep the <span class="text-lowercase">
          ${this.destAs.slice(0, -1)} </span> out of the
          Hauls Page / Enter Haul options. Will not show on map
          page (or anywhere else) for any of the User level users.</li><br>
          <li>Estimate - Create an estimate that can be set to active later.</li>
          `
      };
    }
  },
  computed: {
    activeDestinations() {
      return JSON.parse(JSON.stringify(this.$store.state.activeDestinations));
    },
    commodities() {
      const commodities = this.$store.state.settingsUsers.commodities;
      const objArr = [];
      for (const key in commodities) {
        objArr.push({
          label: commodities[key].name,
          value: commodities[key].name
        });
      }

      return objArr;
    },
    destAs() {
      return this.$store.state.settingsUsers.destAs;
    },
    filterLists() {
      return this.$store.state.filterLists;
    },
    guestUserList() {
      return JSON.parse(JSON.stringify(this.$store.state.guestUserList));
    },
    masterDestinations() {
      return JSON.parse(JSON.stringify(this.$store.state.masterDestinations));
    },
    measurementUnit() {
      let mU = '';
      const commodities = this.$store.state.settingsUsers.commodities;
      for (const key in commodities) {
        if (this.commoditySelected === commodities[key].name) {
          mU = commodities[key].measurementUnit;
        }
      }
      return mU;
    },
    modules() {
      return JSON.parse(
        JSON.stringify(
          this.$store.state.settingsUsers.destinationSettings.modules
        )
      );
    },
    percentHauled() {
      const pH = !this.projected
        ? 0
        : this.round(this.destination.hauled / this.projected) * 100;
      return pH > 100 ? '100%' : pH + '%';
    },
    totalPrice() {
      let total = 0;

      if (this.chargeCommodity) {
        total += this.pricePerUnit * this.hauled;
      }

      for (let i = 0; i < this.services.length; i++) {
        const service = this.services[i];
        if (!this.serviceCheckboxes[i]) {
          continue;
        }

        if (service.useInfoBox) {
          const infoBoxPairedWith = service.infoBoxPairedWith;
          const infoBoxVal = this.infoBoxes.find(infoBox => {
            if (infoBox.name === infoBoxPairedWith) {
              return true;
            }
          });

          service.infoBoxVal = infoBoxVal ? infoBoxVal.model : 0;
          total += service.price * service.infoBoxVal;
        } else {
          total += service.price * this.destination.hauled;
        }
      }

      return total;
    },
    serviceTripDates() {
      const tripDates = JSON.parse(
        JSON.stringify(
          this.$store.state.settingsUsers.destinationSettings.serviceTripDates
        )
      );

      const now = +new Date();
      const tripA = tripDates.tripDateA * 86400000;
      const tripB = tripDates.tripDateB * 86400000;

      return { now, tripA, tripB };
    },
    userDestinationSettings() {
      return JSON.parse(
        JSON.stringify(this.$store.state.settingsUsers.destinationSettings)
      );
    }
  },
  watch: {
    '$store.state.masterDestinations'() {
      this.setDestinationLocations();
      this.setDestinationSettings();
    },
    destination() {
      this.setDestinationSettings();
    }
  }
};
</script>

<style scoped></style>
