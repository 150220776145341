<template>
  <div>
    <Sources v-if="isAdmin" />
    <SourcesUsers v-if="!isAdmin" />
  </div>
</template>

<script>
import Sources from '@/components/sources/Sources.vue';
import SourcesUsers from '@/components/sources/SourcesUsers.vue';

export default {
  name: 'MasterSources',
  components: {
    Sources,
    SourcesUsers
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    isAdmin() {
      let isAdmin = false;
      if (
        this.$store.state.user.superAdmin === true ||
        this.$store.state.user.admin === true
      ) {
        isAdmin = true;
      }
      return isAdmin;
    }
  }
};
</script>

<style scoped></style>
