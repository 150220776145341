<template>
  <div>
    <div class="flex flex-center">
      <CustomerList
        v-if="currentDisplay === 'add'"
        :customers="customerList"
        @onSelectCustomer="onSelectCustomer"
      />

      <EditDestinationUsers
        v-if="currentDisplay === 'editDestination'"
        ref="editDestination"
        :destination="destination"
        @onSubmit="setAction('list')"
        @layerTwoLocations="setAction('layerTwoLocations')"
        @layerTwoAttachments="setAction('layerTwoAttachments')"
        @layerThreeAttachments="setAction('layerThreeAttachments')"
        @setLocation="setAction('editDestination')"
      />

      <FilterData
        v-if="currentDisplay === 'filter'"
        ref="filter"
        :destinationInputs="destinationInputs"
        :destinationSortBy="destinationSortBy"
        :destinationInputSelected="destinationInputSelected"
        @onSubmit="setAction('list')"
      />

      <DestinationTableUsers
        v-show="currentDisplay === 'list'"
        ref="destinationTableUsers"
        :destinationInputs="destinationInputs"
        :destinationSortBy="destinationSortBy"
        :destinationInputSelected="destinationInputSelected"
        @onEdit="onEdit"
      />
    </div>
  </div>
</template>

<script>
import CustomerList from '@/components/CustomerList.vue';
import EditDestinationUsers from '@/components/destinations/EditDestinationUsers.vue';
import DestinationTableUsers from '@/components/destinations/DestinationTableUsers.vue';
import FilterData from '@/components/destinations/FilterData.vue';

export default {
  name: 'DestinationsUsers',
  components: {
    CustomerList,
    EditDestinationUsers,
    FilterData,
    DestinationTableUsers
  },
  data() {
    return {
      currentDisplay: '',
      customer: '',
      customerList: [],
      destination: {}
    };
  },
  created() {
    this.$store.dispatch('setHeaderBtnsDisplay', true);
    this.setAction('list');
    this.setCustomers();
  },
  methods: {
    cancelLayerTwoInputs() {
      this.$refs.editDestination.cancelLayerTwoInputs();
      this.setAction('editDestination');
    },
    cancelLayerThreeAttachment() {
      this.$refs.editDestination.cancelLayerThreeAttachment();
      this.setAction('layerTwoLocations');
    },
    onEdit(destination) {
      destination.action = 'edit';
      this.destination = destination;
      this.destination.location =
        this.masterDestinations[destination.locationId];
      this.destination.location.id = destination.locationId;

      this.setAction('editDestination');
    },
    onSelectCustomer(customer) {
      this.destination = {};
      this.destination.customer = customer.name;
      this.destination.customerId = customer.id;
      this.destination.customerNote = customer.note;
      this.destination.location = {
        customerId: null,
        lat: null,
        lng: null
      };
      this.destination.services = [];
      this.destination.hauls = [];
      this.destination.action = 'add';
      this.currentDisplay = 'editDestination';
    },
    setAction(action) {
      if (action === 'list' || action === 'cancel') {
        this.currentDisplay = 'list';

        const headerBtns = [];
        if (this.isSuper) {
          headerBtns.push({
            action: this.setAction,
            args: 'add',
            label: 'Add',
            color: 'primary',
            flat: true
          });
        }
        if (!this.isGuestCustomer) {
          headerBtns.push({
            action: this.setAction,
            args: 'filter',
            label: 'Sort & Filter',
            color: 'primary',
            flat: false
          });
        }

        this.$store.dispatch('setHeaderBtns', headerBtns);
      } else if (action === 'editDestination') {
        this.currentDisplay = 'editDestination';
        this.$nextTick(() => {
          const headerBtns = [
            {
              action: this.$refs.editDestination.cancel,
              args: '',
              label: 'Cancel',
              color: 'primary',
              flat: true
            },
            {
              action: this.$refs.editDestination.onEnterDestination,
              args: '',
              label: 'Submit',
              color: 'primary',
              flat: false
            }
          ];

          this.$store.dispatch('setHeaderBtns', headerBtns);
        });
      } else if (action === 'editDestinationDelete') {
        this.currentDisplay = 'editDestination';
        this.$nextTick(() => {
          const headerBtns = [
            {
              action: this.$refs.editDestination.cancel,
              args: '',
              label: 'Cancel',
              color: 'primary',
              flat: true
            },
            {
              action: this.$refs.editDestination.confirmAction,
              args: 'delete',
              label: 'Delete',
              color: 'custom-red',
              flat: true
            },
            {
              action: this.$refs.editDestination.onEnterDestination,
              args: '',
              label: 'Submit',
              color: 'primary',
              flat: false
            }
          ];

          this.$store.dispatch('setHeaderBtns', headerBtns);
        });
      } else if (action === 'add') {
        const headerBtns = [
          {
            action: this.setAction,
            args: 'cancel',
            label: 'Cancel',
            color: 'primary',
            flat: false
          }
        ];

        this.$store.dispatch('setHeaderBtns', headerBtns);
        this.currentDisplay = 'add';
      } else if (action === 'filter') {
        this.currentDisplay = 'filter';
        this.$nextTick(() => {
          const headerBtns = [
            {
              action: this.setAction,
              args: 'list',
              label: 'Cancel',
              color: 'primary',
              flat: true
            },
            {
              action: this.$refs.filter.submitFilter,
              args: '',
              label: 'Sort & Filter',
              color: 'primary',
              flat: false
            }
          ];

          this.$store.dispatch('setHeaderBtns', headerBtns);
        });
      } else if (action === 'layerTwoLocations') {
        const headerBtns = [
          {
            action: this.cancelLayerTwoInputs,
            args: '',
            label: 'Cancel',
            color: 'primary',
            flat: false
          }
        ];

        this.$store.dispatch('setHeaderBtns', headerBtns);
      } else if (action === 'layerTwoAttachments') {
        const headerBtns = [
          {
            action: this.cancelLayerTwoInputs,
            args: '',
            label: 'Done',
            color: 'primary',
            flat: false
          }
        ];

        this.$store.dispatch('setHeaderBtns', headerBtns);
      } else if (action === 'layerThreeAttachments') {
        const headerBtns = [
          {
            action: this.cancelLayerThreeAttachment,
            args: '',
            label: 'Done',
            color: 'primary',
            flat: false
          }
        ];

        this.$store.dispatch('setHeaderBtns', headerBtns);
      }
    },
    setCustomers() {
      const customers = JSON.parse(JSON.stringify(this.$store.state.customers));
      const colors = JSON.parse(JSON.stringify(this.$store.state.colors));

      this.customerList = [];
      const sortByCustomer = (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      };

      for (const key in customers) {
        const customer = customers[key];
        customer.id = key;

        this.customerList.push(customers[key]);
      }

      this.customerList.sort(sortByCustomer);

      let colorIndex = 0;
      for (let i = 0; i < this.customerList.length; i++) {
        this.customerList[i].color = colors[colorIndex];

        colorIndex++;
        if (colorIndex === 8) {
          colorIndex = 0;
        }
      }
    }
  },
  computed: {
    destinationInputs() {
      return this.$store.state.filterLists.destinationInputs;
    },
    destinationSortBy() {
      return this.$store.state.filterLists.destinationSortBy;
    },
    destinationInputSelected() {
      return this.$store.state.filterLists.destinationInputSelected;
    },
    isGuestCustomer() {
      return (
        this.$store.state.user.superCustomer || this.$store.state.user.customer
      );
    },
    isSuper() {
      return this.$store.state.user.superUser;
    },
    masterDestinations() {
      return JSON.parse(JSON.stringify(this.$store.state.masterDestinations));
    }
  },
  watch: {
    '$store.state.customers'() {
      this.setCustomers();
    },
    currentDisplay(newVal) {
      if (newVal === 'list') {
        this.$refs.destinationTableUsers.highlightDest();
      }
    }
  }
};
</script>

<style scoped></style>
