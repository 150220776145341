const destinationInputs = {
  attachment: {
    label: 'Attachments'
  },
  startDate: {
    label: 'Start Date'
  },
  commodity: {
    label: 'Commodity'
  },
  chargeCommodity: {
    label: 'Charge Commodity'
  },
  pricePerUnit: {
    label: 'Commodity Price'
  },
  trackMileage: {
    label: 'Track Mileage'
  },
  mileageRate: {
    label: 'Mileage Rate'
  },
  guestUsers: {
    label: 'Guest Users'
  },
  note: {
    label: 'Note'
  },
  adminNote: {
    label: 'Admin Note'
  },
  customerNote: {
    label: 'Customer Note'
  },
  driverRate: {
    label: 'Driver Rate'
  },
  services: {
    label: 'Services'
  },
  haulTrackers: {
    label: 'Haul Trackers'
  },
  dispatchedTo: {
    label: 'Dispatched To'
  },
  scheduled: {
    label: 'Scheduled'
  }
};

const userDestinationInputs = {
  attachment: {
    label: 'Attachments'
  },
  startDate: {
    label: 'Start Date'
  },
  commodity: {
    label: 'Commodity'
  },
  trackMileage: {
    label: 'Track Mileage'
  },
  note: {
    label: 'Note'
  },
  services: {
    label: 'Services'
  },
  haulTrackers: {
    label: 'Haul Trackers'
  },
  dispatchedTo: {
    label: 'Dispatched To'
  },
  scheduled: {
    label: 'Scheduled'
  }
};

const sourceInputs = {
  attachment: {
    label: 'Attachments'
  },
  startDate: {
    label: 'Start Date'
  },
  commodity: {
    label: 'Commodity'
  },
  purchaseCommodity: {
    label: 'Purchase Commodity'
  },
  pricePerUnit: {
    label: 'Price'
  },
  trackMileage: {
    label: 'Track Mileage'
  },
  mileageRate: {
    label: 'Mileage Rate'
  },
  guestUsers: {
    label: 'Guest Users'
  },
  note: {
    label: 'Note'
  },
  adminNote: {
    label: 'Admin Note'
  },
  customerNote: {
    label: 'Customer Note'
  },
  services: {
    label: 'Services'
  },
  haulTrackers: {
    label: 'Haul Trackers'
  },
  dispatchedTo: {
    label: 'Dispatched To'
  },
  scheduled: {
    label: 'Scheduled'
  }
};

const userSourceInputs = {
  attachment: {
    label: 'Attachments'
  },
  startDate: {
    label: 'Start Date'
  },
  commodity: {
    label: 'Commodity'
  },
  trackMileage: {
    label: 'Track Mileage'
  },
  note: {
    label: 'Note'
  },
  services: {
    label: 'Services'
  },
  haulTrackers: {
    label: 'Haul Trackers'
  },
  dispatchedTo: {
    label: 'Dispatched To'
  },
  scheduled: {
    label: 'Scheduled'
  }
};

const destinationStatusFilter = {
  openWaiting: true,
  estimate: false
};

const sourceStatusFilter = {
  openWaiting: true,
  estimate: false
};

const destinationSortBy = {
  model: 'alpha&&&alpha',
  reverseSort: false,
  type: 'alpha',
  value: 'alpha'
};

const sourceSortBy = {
  model: 'alpha&&&alpha',
  reverseSort: false,
  type: 'alpha',
  value: 'alpha'
};

const FilterLists = {
  destinationInputs,
  destinationSortBy,
  destinationStatusFilter,
  destinationInputSelected: '',
  sourceInputs,
  sourceSortBy,
  sourceStatusFilter,
  sourceInputSelected: ''
};

const UserFilterLists = {
  destinationInputs: userDestinationInputs,
  destinationSortBy,
  destinationStatusFilter,
  destinationInputSelected: '',
  sourceInputs: userSourceInputs,
  sourceSortBy,
  sourceStatusFilter,
  sourceInputSelected: ''
};

export { FilterLists, UserFilterLists };
