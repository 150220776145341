import { render, staticRenderFns } from "./DestinationReportUsers.vue?vue&type=template&id=11881062&scoped=true&"
import script from "./DestinationReportUsers.vue?vue&type=script&lang=js&"
export * from "./DestinationReportUsers.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11881062",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QSpace,QSeparator,QAvatar,QIcon,QChip});
